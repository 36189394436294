import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  button: {
    maxWidth: 312
  },
  load: {
    marginTop: 16
  },
  container: {
    padding: '24px 16px'
  },
  table: {
    marginTop: 24
  },
  titleContainer: {
    marginTop: 24
  }
})
