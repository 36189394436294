import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    height: 56,
    marginLeft: 24,
    width: 204
  },
  success: {
    width: 168,
    height: 40,
    marginLeft: 24
  },
  field: {
    marginTop: 16
  },
  bottom: {
    padding: '24px 0px'
  },
  dropdown: {
    overflowY: 'scroll',
    border: `1px solid ${theme.colors.inactive}`,
    borderRadius: '4px !important',
    '& > div': {
      minWidth: '100%'
    },
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.colors.blue}`,
      height: 120
    }
  }
}))
