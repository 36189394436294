import { useMemo } from 'react'

export type UseDefaultValueProps<T> = {
  outerValue?: T
  value: T
}

export const useDefaultValue = <T>({
  outerValue,
  value
}: UseDefaultValueProps<T>) => {
  const Value = useMemo(() => {
    if (typeof outerValue !== 'undefined') {
      return outerValue
    }

    return value
  }, [outerValue, value])

  return Value
}
