import * as React from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { Content } from '../content'
import { Row } from '../row'
import { LightTheme } from '../Theme'
import Nav from './nav'

const useStyle = createUseStyles((theme: LightTheme) => ({
  footer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    background: theme.colors.white
  }
}))

export const Footer: React.FC = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  return (
    <Row fullWidth className={classes.footer} id="footer">
      <Content>
        <Nav />
      </Content>
    </Row>
  )
}
