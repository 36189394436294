import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    width: '100%',
    padding: '24px 0',
    alignSelf: 'center',
    zIndex: 2
  },
  paypal: {
    display: 'flex',
    justifyContent: 'center',
    width: 400
  },
  title: {
    textAlign: 'center'
  },

  bottomText: {
    marginLeft: 16,
    textAlign: 'center'
  },
  error: {
    marginBottom: 16
  },
  errorText: {
    marginLeft: 16
  }
})
