import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Row } from '../../row'
import { LightTheme } from '../../Theme'

import { useStyle } from './dot.styles'
import { DotProps } from './dot.types'

export const Dot: FC<DotProps> = ({ active, className = '', onClick }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ active, theme })

  return (
    <Row className={`${className} ${classes.container}`} onClick={onClick}>
      <div className={classes.active} />
    </Row>
  )
}
