import React, { FC } from 'react'

import { Column, Row, Button, Text } from '../../../../components'
import background from '../../../../assets/images/background-delete-account.png'

import { ChangeEmailAcceptProps } from './change-email-accept.types'
import { useStyle } from './change-email-accept.styles'

export const ChangeEmailAccept: FC<ChangeEmailAcceptProps> = ({
  email,
  onChangeStep,
  onSubmit
}) => {
  const classes = useStyle()

  const handleClickCancel = () => {
    onChangeStep('form')
  }

  return (
    <>
      <Column fullWidth className={classes.bottomContainer}>
        <img width="100%" src={background} />
        <Text
          className={classes.description}
          color="black"
          preset="h4"
          text="Do you accept E-mail changing?"
          tx="change.email.modal.secondaryDescription"
        />
        <Text
          className={classes.secondaryDesctiption}
          color="inactive"
          preset="body"
          text={`Your current E-mail will be changed with a ${email}`}
        />
      </Column>
      <Row fullWidth justifyContent="flex-end" className={classes.buttonsRow}>
        <Button
          className={classes.button}
          color="yellow"
          preset="secondaryBorder"
          textColor="yellow"
          textPreset="maxButton"
          text="Cancel"
          onClick={handleClickCancel}
        />
        <Button
          className={classes.button}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="maxButton"
          text="Accept"
          onClick={onSubmit}
        />
      </Row>
    </>
  )
}
