import React, { FC } from 'react'

import { Button } from '../button'
import { Column } from '../column'
import { Loader } from '../loader'

import { LoadMoreButtonProps } from './load-more-button.types'

export const LoadMoreButton: FC<LoadMoreButtonProps> = ({
  className,
  loading,
  text = 'Load More',
  tx = 'button.loadMore',
  onLoadNext
}) => {
  if (loading) {
    return <Loader />
  }

  return (
    <Column fullWidth>
      <Button
        className={className}
        color="yellow"
        preset="button3"
        textColor="white"
        textPreset="h5"
        text={text}
        tx={tx}
        onClick={onLoadNext}
      />
    </Column>
  )
}
