import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  image: {
    marginLeft: 16,
    objectFit: 'cover'
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: 12,
    minWidth: 156
  },
  tableColumnBig: {
    height: '100%',
    padding: 12,
    minWidth: 210
  },
  tableRow: {
    cursor: 'pointer',
    '&>div': {
      borderBottom: `1px solid ${theme.colors.shadow}`
    },
    '&:hover > div': {
      background: theme.colors.lightBlue
    }
  },
  statusText: {
    marginLeft: 8
  },
  firstColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    background: theme.colors.white,
    borderRight: `1px solid ${theme.colors.shadow}`
  },
  text: {
    maxWidth: 110,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))
