import React, { ChangeEvent, FC, useMemo } from 'react'

import { AttributeInputType } from '../../../services'

import { Dropdown, DropdownItemData, Row, TextField } from '../../../components'

import { AttributeInputProps } from './attribute-input.types'

export const AttributeInput: FC<AttributeInputProps> = ({
  className,
  defaultValue,
  label,
  labelTx,
  type,
  values = [],
  onChange
}) => {
  const Values = useMemo(() => {
    if (values) {
      return values.map((value) => ({
        id: value.id,
        name: value.title,
        value: value.type
      }))
    }

    return []
  }, [values])

  const Value = useMemo(() => {
    if (defaultValue) {
      const value = defaultValue.type
      if (value) {
        return value
      }
    }
  }, [defaultValue])

  const handleOnChangeDropdown = (item: DropdownItemData) => {
    if (onChange) {
      onChange(item.name)
    }
  }

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (onChange) {
      onChange(value)
    }
  }

  if (type === AttributeInputType.DROPDOWN) {
    return (
      <Row fullWidth className={className}>
        <Dropdown
          data={Values}
          defaultValue={Value}
          preset="h5"
          label={label}
          labelTx={labelTx}
          onChange={handleOnChangeDropdown}
        />
      </Row>
    )
  }

  return (
    <TextField
      className={className}
      defaultValue={Value}
      maxLength={50}
      label={label}
      labelTx={labelTx}
      preset="border"
      onChange={handleOnChangeInput}
    />
  )
}
