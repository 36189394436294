import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 32,
    backgroundColor: theme.colors.white,
    padding: 4
  },
  slider: {
    borderBottom: `1px solid ${theme.colors.shadow}`
  }
}))
