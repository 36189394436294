import React, { ChangeEvent, useState, FC } from 'react'
import {
  Column,
  Row,
  Text,
  TextField,
  RadioItem,
  ICONS
} from '../../../components'
import { Sex, RegisterCredentials } from '../../../services'

import {
  ValidatorFirstName,
  ValidatorLastName,
  ValidatorUsername
} from '../validation'

import { PersonalInfoProps } from './personal-info.types'
import { useStyle } from './personal-info.styles'

export const PersonalInfo: FC<PersonalInfoProps> = ({
  firstName,
  lastName,
  username,
  sex,
  user,
  changeUser
}) => {
  const [sexState, changeSex] = useState<Sex>(sex)
  const [error, changeError] = useState(false)
  const [property, changeProp] = useState('')
  const classes = useStyle({ errorStyle: error })

  const handleOnChange =
    (prop: keyof RegisterCredentials) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeProp(prop)

      if (
        value.length === 0 &&
        (!ValidatorFirstName(value) ||
          !ValidatorLastName(value) ||
          !!ValidatorUsername(value))
      ) {
        changeError(true)
      } else changeError(false)

      changeUser({
        ...user,
        [prop]: value
      })
    }
  const handleOnChangeSex = (value: Sex) => () => {
    changeSex(value)
    changeUser({
      ...user,
      sex: value
    })
  }
  return (
    <Column className={classes.infoContainer} alignItems="flex-start" fullWidth>
      <Row fullWidth justifyContent="flex-start" className={classes.infoTitle}>
        <Text color="black" preset="h3" text="Personal Information" />
      </Row>
      <Row
        fullWidth
        alignItems="flex-start"
        justifyContent="space-between"
        className={classes.inputRow}
      >
        <Column fullWidth>
          <TextField
            className={classes.input}
            label="First Name"
            labelTx="register.firstName"
            preset="main"
            maxLength={30}
            onChange={handleOnChange('firstName')}
            defaultValue={firstName}
          />
          {error && property === 'firstName' && (
            <Row
              fullWidth
              justifyContent="flex-start"
              className={classes.error}
            >
              <Text
                text="The field can't be empty"
                tx="general.info.error"
                preset="secondTitle"
                color="error"
              />
            </Row>
          )}
          <TextField
            className={classes.input}
            label="Last Name"
            labelTx="register.lastName"
            preset="main"
            maxLength={30}
            onChange={handleOnChange('lastName')}
            defaultValue={lastName}
          />
          {error && property === 'lastName' && (
            <Row
              fullWidth
              justifyContent="flex-start"
              className={classes.errorLastName}
            >
              <Text
                text="The field can't be empty"
                tx="general.info.error"
                preset="secondTitle"
                color="error"
              />
            </Row>
          )}
        </Column>
        <Column fullWidth>
          <TextField
            className={classes.input}
            label="Username"
            labelTx="register.username"
            preset="main"
            maxLength={30}
            onChange={handleOnChange('username')}
            defaultValue={username}
          />
          {error && property === 'username' && (
            <Row
              fullWidth
              justifyContent="flex-start"
              className={classes.errorUsername}
            >
              <Text
                text="The field can't be empty"
                tx="general.info.error"
                preset="secondTitle"
                color="error"
              />
            </Row>
          )}
          <Row
            fullWidth
            alignItems="flex-start"
            justifyContent="space-between"
            className={classes.radioContainer}
          >
            <RadioItem
              className={classes.radio}
              active={Sex.FEMALE === sexState}
              src={ICONS.female}
              name="Female"
              nameTx="register.female"
              onClick={handleOnChangeSex(Sex.FEMALE)}
            />
            <RadioItem
              className={classes.radio}
              active={Sex.MALE === sexState}
              src={ICONS.male}
              name="Male"
              nameTx="register.male"
              onClick={handleOnChangeSex(Sex.MALE)}
            />
          </Row>
        </Column>
      </Row>
    </Column>
  )
}
