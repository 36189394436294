import React, { FC, MouseEvent, useRef } from 'react'
import ReactDOM from 'react-dom'

import { useOutsideAlerter, usePortal } from '../../hooks'

import { Text } from '../text'
import { Column } from '../column'
import { Row } from '../row'
import { Icon, ICONS } from '../icon'
import { Button } from '../button'

import { FiltersSidebarProps } from './filters-sidebar.types'
import { useStyle } from './filters-sidebar.styles'

export const FiltersSidebar: FC<FiltersSidebarProps> = ({
  children,
  open,
  title = 'FILTERS',
  titleTx = 'filtersSidebar.title',
  buttonSuccessText = 'Apply Filters',
  buttonSuccessTx = 'filtersSidebar.apply',
  buttonCancelText = 'Reset All',
  buttonCancelTx = 'filtersSidebar.reset',
  isDisabled,
  onClose,
  onApply,
  onReset
}) => {
  const classes = useStyle({ open, width: 408 })
  const containerRef = useRef<HTMLDivElement>(null)
  const target = usePortal('sidebar')

  useOutsideAlerter(containerRef, open, onClose)

  const handleOnClickContainer = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.stopPropagation()
  }

  return ReactDOM.createPortal(
    <Column
      className={classes.base}
      ref={containerRef}
      onClick={handleOnClickContainer}
      onScroll={handleOnClickContainer}
    >
      <Column className={classes.container} justifyContent="space-between">
        <Row fullWidth className={classes.title} justifyContent="space-between">
          <Text preset="h4" text={title} tx={titleTx} />
          <Row onClick={onClose}>
            <Icon src={ICONS.close} />
          </Row>
        </Row>
        <Column className={classes.content} justifyContent="flex-start">
          {children}
        </Column>
        <Row fullWidth className={classes.bottomContainer}>
          <Button
            className={classes.button}
            preset="button3"
            text={buttonSuccessText}
            tx={buttonSuccessTx}
            textColor="white"
            onClick={onApply}
            disabled={isDisabled}
          />
          <Button
            className={`${classes.buttonRight} ${classes.button}`}
            preset="borderButton3"
            text={buttonCancelText}
            tx={buttonCancelTx}
            textColor="yellow"
            onClick={onReset}
            disabled={isDisabled}
          />
        </Row>
      </Column>
    </Column>,
    target
  )
}
