import React, { FC } from 'react'

import {
  Column,
  ICONS,
  LoadingContainer,
  Row,
  SortItem,
  TablePagginationBar,
  Text,
  TextField
} from '../../../components'
import { OrderDirection, UserSortField } from '../../../services'
import { concatClassNames } from '../../views'

import { UserListCustomersTableItem } from '../user-list-customers-table-item'

import { useStyle } from './user-list-customers-table.styles'
import { UserListCustomersTableProps } from './user-list-customers-table.types'

export const UserListCustomersTable: FC<UserListCustomersTableProps> = ({
  className = '',
  data,
  loading,
  rowCounts,
  paginationState,
  onChangePage,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount,
  onChangeSearch,
  onSort
}) => {
  const classes = useStyle()

  const columnBig = concatClassNames(
    classes.tableColumn,
    classes.tableColumnBig
  )
  const columnMin = concatClassNames(
    classes.tableColumn,
    classes.tableColumnmMin
  )

  const handleOnSort =
    (userListOrderField: UserSortField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(userListOrderField, direction)
      }
    }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={concatClassNames(className, classes.container)}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          maxLength={200}
          label="Search"
          labelTx="statistic.table.header.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
      </Row>
      <Column fullWidth className={classes.table}>
        <Row className={classes.header} justifyContent="space-between">
          <Row
            className={concatClassNames(
              classes.firstColumn,
              classes.tableColumn
            )}
            justifyContent="flex-start"
          >
            <SortItem
              name="Name"
              nameTx="user.list.table.name"
              onClick={handleOnSort(UserSortField.FIRST_NAME)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Country"
              nameTx="user.list.table.country"
              onClick={handleOnSort(UserSortField.COUNTRY)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="City"
              nameTx="user.list.table.city"
              onClick={handleOnSort(UserSortField.CITY)}
            />
          </Row>
          <Row className={columnMin} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Phone Number"
              tx="user.list.table.phone.number"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Street Address"
              tx="user.list.table.street.address"
            />
          </Row>
          <Row className={columnBig} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Email"
              tx="user.list.table.email"
            />
          </Row>
        </Row>
        <LoadingContainer loading={loading}>
          {data.map((customer) => (
            <UserListCustomersTableItem
              key={`vendor_${customer.id}`}
              {...customer}
            />
          ))}
        </LoadingContainer>
      </Column>
      {paginationState && (
        <TablePagginationBar
          rowCounts={rowCounts}
          paginationState={paginationState}
          onChangePage={onChangePage}
          onPrevioslyPage={onPrevioslyPage}
          onNextPage={onNextPage}
          onChangeRowCount={onChangeRowCount}
        />
      )}
    </Column>
  )
}
