import { gql } from '@apollo/client'

import { PAGE_INFO_FRAGMENT, STATISTIC_USER_FRAGMENT } from '../fragments'

export const ADMIN_STATISTIC = gql`
  ${PAGE_INFO_FRAGMENT}
  ${STATISTIC_USER_FRAGMENT}
  query AdminStatistic(
    $filter: VendorFilterInput
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: UserSortingInput
  ) {
    adminStatistic(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          ...StatisticUserFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      filters
    }
  }
`
