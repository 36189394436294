import React, { FC, useRef, useState } from 'react'

import { useOutsideAlerter } from '../../hooks'
import { concatClassNames } from '../../pages/views'

import { Column } from '../column'
import { arrayHasElements } from '../helpers'
import { Icon, ICONS } from '../icon'
import { Text } from '../text'

import { useStyle } from './tooltip.styles'
import { TooltipProps } from './tooltip.types'

export const Tooltip: FC<TooltipProps> = ({ unitIds, className }) => {
  const classes = useStyle()

  const [openTooltip, changeOpenToolTip] = useState(false)

  const handleOnClickIcon = (state: boolean) => () => {
    changeOpenToolTip(state)
  }
  const ref = useRef(null)
  useOutsideAlerter(ref, openTooltip, handleOnClickIcon(false))

  return (
    <>
      <Icon src={ICONS.info} onClick={handleOnClickIcon(true)} />

      {openTooltip && (
        <Column
          ref={ref}
          justifyContent="flex-start"
          className={concatClassNames(className, classes.tooltipContainer)}
        >
          <Text
            className={classes.title}
            color="black"
            preset="h6"
            text="Item numbers"
            tx="tooltip.title"
          />
          <Column
            fullWidth
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.ids}
          >
            {unitIds &&
              arrayHasElements(unitIds) &&
              unitIds.map((unitId) => (
                <Text key={`id_${unitId}`} text={unitId} preset="secondTitle" />
              ))}
          </Column>
        </Column>
      )}
    </>
  )
}
