import { ApolloError, ApolloQueryResult, MutationResult } from '@apollo/client'

import {
  BackgroundImage,
  ListVariables,
  PaginationList,
  Edge,
  FilterItem,
  FetchMore,
  SortBy
} from '../api.types'
import { Collection } from '../collection'
import { Address, User } from '../auth'
import {
  CategoryByIdVariables,
  CategoryWithChildren,
  CategoryWithParent
} from '../category'
import {
  Currency,
  Money,
  TaxedMoney,
  TaxedMoneyRange,
  TaxRateType
} from '../tax'
import { LanguageCodeEnum } from '../language'
import { AttributeValue, AttributeType } from '../attribute'
import { ProductOrderField } from '../globalTypes.types'
import { DeliveryPrice } from '../delivery-price'
import { FeedbackWithCount } from '../feedback'

export enum ProductErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ATTRIBUTE_ALREADY_ASSIGNED = 'ATTRIBUTE_ALREADY_ASSIGNED',
  ATTRIBUTE_CANNOT_BE_ASSIGNED = 'ATTRIBUTE_CANNOT_BE_ASSIGNED',
  ATTRIBUTE_VARIANTS_DISABLED = 'ATTRIBUTE_VARIANTS_DISABLED',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_PRODUCTS_IMAGE = 'NOT_PRODUCTS_IMAGE',
  NOT_PRODUCTS_VARIANT = 'NOT_PRODUCTS_VARIANT',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  VARIANT_NO_DIGITAL_CONTENT = 'VARIANT_NO_DIGITAL_CONTENT'
}

export enum ProductStatus {
  VISIBLE = 'VISIBLE',
  INVISIBLE = 'INVISIBLE',
  DRAFT = 'DRAFT'
}

export enum ProductOptionType {
  RADIOBUTTON = 'RADIOBUTTON',
  CHECKBOX = 'CHECKBOX'
}

export enum ProductTypeSortField {
  NAME = 'NAME',
  DIGITAL = 'DIGITAL',
  SHIPPING_REQUIRED = 'SHIPPING_REQUIRED'
}

export enum ProductPurchaseSpecifics {
  ONLY_WITH_OFFER = 'ONLY_WITH_OFFER',
  DIRECT_PURCHASE = 'DIRECT_PURCHASE'
}

export type ProductError = {
  field: String
  message: String
  code: ProductErrorCode
}

export type ProductSortingInput = SortBy<ProductOrderField>

export type ProductOptionVariantInput = {
  title: string
  description: string
  extraPrice: number
  extraProductionDays: number
  isDefault?: boolean
}

export type ProductCreateMaterialInput = ProductOptionVariantInput

export interface ProductCreateColorInput extends ProductOptionVariantInput {
  color: string
  code?: string
}

export type ProductCreateOptionInput = {
  title: string
  type?: ProductOptionType
  variants?: ProductOptionVariantInput[]
}

export type ProductCreateAttributeInput = {
  attributeId: string
  type: string
}

export type ProductCreateInput = {
  attributes: ProductCreateAttributeInput[]
  options: ProductCreateOptionInput[]
  category: string
  collection: string
  materials: ProductCreateMaterialInput[]
  gender: string
  colors: ProductCreateColorInput[]
  age_group: string
  defaultPrice: number
  chargeTaxes: boolean
  collections: string[]
  size: string[]
  description: string
  isPublished: boolean
  name: string
  style: string
  feature: string
  modelNumber: string
  slug: string
  taxCode: string
  weight: string
  minimalVariantPriceAmount: number
  maximumVariantPriceAmount: number
  sku: string
  trackInventory: boolean
  basePrice: number
  visibleInListings: boolean
  vendor: string
  purchaseSpecifics: string
  productionDaysUpTo: number
  brandName: string
  countryOfOrigin: string
  materialCare: string
  materialComposition: string
  deliveryPrice: string
  isFragile: boolean
  status?: string
}

export type ProductImageCreateInput = {
  product: string
  image: File
  alt?: string
}

export type FeaturePrice = {
  amount: number
  currency: Currency
}

export type FeaturePriceTypes = {
  gross: FeaturePrice
  net: FeaturePrice
}

export type FeaturePriceRange = {
  start: FeaturePriceTypes
  stop: FeaturePriceTypes
}

export type Pricing = {
  onSale: boolean
  priceRangeUndiscounted: FeaturePriceRange
  priceRange: FeaturePriceRange
}

export type Color = {
  id: string
  name: string
  code: string
}

export type ProductColor = {
  title: string
  description: string
  extraPrice: number
  extraProductionDays: number
  isDefault: boolean
  id: string
  color: Color
}

export type ProductMaterial = {
  title: string
  description: string
  extraPrice: number
  extraProductionDays: number
  isDefault: boolean
  id: string
}

export type ProductAttribute = {
  id: string
  type: string
  attribute: AttributeType
}

export type ProductOptionVariant = {
  title: string
  description: string
  extraPrice: number
  extraProductionDays: number
  isDefault: boolean
  id: string
}

export type ProductOption = {
  id: string
  title: string
  type: ProductOptionType
  variants: ProductOptionVariant[]
}

export type Product = {
  id: string
  name: string
  visibleInListings: boolean
  defaultPrice: number
  totalPrice: number
  thumbnail: BackgroundImage
  thumbnail2x: BackgroundImage
  pricing: Pricing
  category: CategoryWithParent
  favorite?: boolean
  isRemovedFromVendorList: boolean
  colors: ProductColor[]
  materials?: ProductMaterial[]
  attributes?: ProductAttribute[]
  options?: ProductOption[]
  images: Image[]
  vendor: User
  description: string
  collection: Collection
  isTop: boolean
  currency?: Currency
  purchaseSpecifics?: ProductPurchaseSpecifics
  productType: ProductType
  productionDaysUpTo: number
  brandName: string
  countryOfOrigin: string
  materialCare: string
  materialComposition: string
  deliveryPrice: DeliveryPrice
  feedbacks: FeedbackWithCount[]
  isFragile: boolean
  status: ProductStatus
}

export type ProductOptions = {
  count: number
  color: Color
  size: string
}

export interface ProductWithOptions extends Product, ProductOptions {
  maxAmount?: number
}

export type GetTopProducts = {
  topProducts: PaginationList<Product>
}

export type GetPrevioslySeenProducts = {
  previouslySeenProducts: PaginationList<Product>
}

export type AttributeInput = {
  slug: string
  value: string
  values: string[]
}

export enum StockAvailability {
  IN_STOCK = 'IN_STOCK',
  OUT_OF_STOCK = 'OUT_OF_STOCK'
}

export type PriceRangeInput = {
  gte?: number
  lte?: number
}

export type WarehouseIds = string[]

export type ProductStockFilterInput = {
  warehouseIds: string[]
  quantity?: PriceRangeInput
}

export type ProductFilter = {
  isTop?: boolean
  isPublished?: boolean
  isRemovedFromVendorList?: boolean
  collections?: string[]
  categories?: string[]
  vendors?: string[]
  vendor?: string
  hasCategory?: boolean
  attributes?: AttributeInput[]
  stockAvailability?: StockAvailability
  productType?: string[]
  stocks?: ProductStockFilterInput
  search?: string
  price?: PriceRangeInput
  minimalPrice?: PriceRangeInput
  productTypes?: string[]
  companyNames?: string[]
}

export type ProductFilterKeys = keyof ProductFilter

export interface ProductsVariables extends ListVariables<ProductFilter> {
  sortBy?: ProductSortingInput
}

export interface GetTopProductsVariables extends ProductsVariables {}
export interface GetPrevioslyProductsVariables extends ProductsVariables {}

export type GetFeaturedProducts = {
  shop: {
    homepageCollection: {
      id: string
      products: PaginationList<Product>
    }
  }
}

export type GetProduct = {
  product: FullProduct
}

export interface ProductsByCategoryIdVariables
  extends CategoryByIdVariables,
    ListVariables<any> {}

export interface VendorCategoriesVariables extends ProductsVariables {}

export type GetProductsByCategoryId = {
  category: {
    id: string
    name: string
    products: PaginationList<Product>
  }
}

export type GetRecommendationProducts = {
  recommendations: PaginationList<Product>
}

export type ProductsFiltersParseJSON = {
  genders: FilterItem[]
  materials: FilterItem[]
  colors: FilterItem[]
  sizes: FilterItem[]
  priceRange: FilterItem[]
  productTypes: FilterItem[]
  companyNames: FilterItem[]
  vendors: FilterItem[]
}

export interface ProductsWithFilters extends PaginationList<Product> {
  filters: string
}

export type GetProductsData = {
  products: ProductsWithFilters
}

export type GetVendorCategories = {
  categories: PaginationList<CategoryWithChildren>
}

export enum WeightUnitsEnum {
  KG = 'KG',
  LB = 'LB',
  OZ = 'OZ',
  G = 'G'
}

export type Weight = {
  unit: WeightUnitsEnum
  value: number
}

export type MetadataItem = {
  key: string
  value: string
}

export type PageInfo = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string
  endCursor: string
}

export type ReducedRate = {
  rate: number
  rateType: TaxRateType
}

export type VAT = {
  countryCode: string
  standardRate: number
  reducedRates: ReducedRate[]
}

export type CountryDisplay = {
  code: string
  country: string
  vat: VAT
}

export type MoneyRange = {
  start: Money
  stop: Money
}

export enum ShippingMethodTypeEnum {
  PRICE = 'PRICE',
  WEIGHT = 'WEIGHT'
}

export interface ShippingMethodTranslation extends Node {
  name: string
  language: LanguageDisplay
}

export interface ShippingMethod extends Node {
  name: string
  price: Money
  minimumOrderPrice: Money
  maximumOrderPrice: Money
  minimumOrderWeight: Weight
  maximumOrderWeight: Weight
  type: ShippingMethodTypeEnum
  translation: ShippingMethodTranslation
}

export interface ShippingZone extends Node {
  name: string
  default: boolean
  priceRange: MoneyRange
  countries: CountryDisplay[]
  shippingMethods: ShippingMethod[]
  warehouses: Warehouse[]
}

export type CountableConnectionT<T> = {
  pageInfo: PageInfo
  edges: T[]
  totalCount: number
}

export type ProductCountableEdge = {
  node: ProductType
  cursor: string
}

export type ProductTypeCountableEdge = {
  node: ProductType
  cursor: string
}

export type ShippingZoneCountableEdge = {
  node: ShippingZone
  cursor: string
}

export type WishlistItemCountableEdge = {
  node: WishlistItem
  cursor: string
}

export type ProductVariantCountableEdge = {
  node: ProductVariant
  cursor: string
}

export type ProductCountableConnection =
  CountableConnectionT<ProductCountableEdge>
export type ProductTypeCountableConnection =
  CountableConnectionT<ProductTypeCountableEdge>
export type AttributeCountableConnection = Edge<AttributeType>
export type ShippingZoneCountableConnection =
  CountableConnectionT<ShippingZoneCountableEdge>
export type WishlistItemCountableConnection =
  CountableConnectionT<WishlistItemCountableEdge>
export type ProductVariantCountableConnection =
  CountableConnectionT<ProductVariantCountableEdge>

export type TaxType = {
  description: string
  taxCode: string
}

export type LanguageDisplay = {
  code: LanguageCodeEnum
  language: string
}

export type ProductType = {
  id: string
  name: string
  slug: string
  hasVariants: boolean
  isShippingRequired: boolean
  isDigital: boolean
  weight: Weight
  privateMetadata: MetadataItem
  MediaMetadata: MetadataItem
  products: ProductCountableConnection
  taxRate: TaxRateType
  taxType: TaxType
  variantAttributes: AttributeType[]
  productAttributes: AttributeType[]
  availableAttributes: AttributeCountableConnection
}

export type VariantPricingInfo = {
  onSale: Boolean
  discount: TaxedMoney
  discountLocalCurrency: TaxedMoney
  price: TaxedMoney
  priceUndiscounted: TaxedMoney
  priceLocalCurrency: TaxedMoney
}

export type SelectedAttribute = {
  attribute: AttributeType
  values: AttributeValue[]
}

export interface ProductImage extends Node {
  sortOrder: number
  alt: string
  url: string
}

export interface ProductVariantTranslation extends Node {
  name: string
  language: LanguageDisplay
}

export interface DigitalContentUrl extends Node {
  content: DigitalContent
  created: string
  downloadNum: number
  url: string
  token: string
}

export interface DigitalContent extends Node {
  useDefaultSettings: boolean
  automaticFulfillment: boolean
  productVariant: ProductVariant
  contentFile: string
  maxDownloads: number
  urlValidDays: number
  urls: DigitalContentUrl[]
  privateMetadata: MetadataItem
  metadata: MetadataItem
}

export interface Warehouse extends Node {
  name: string
  slug: string
  companyName: string
  shippingZones: ShippingZoneCountableConnection
  address: Address
  email: string
}

export interface Stock extends Node {
  warehouse: Warehouse
  productVariant: ProductVariant
  quantity: number
  quantityAllocated: number
}

export type ProductVariant = {
  id: string
  name: string
  sku: string
  product: Product
  trackInventory: Boolean
  weight: Weight
  privateMetadata: MetadataItem
  metadata: MetadataItem
  price: Money
  pricing: VariantPricingInfo
  costPrice: Money
  margin: number
  quantityOrdered: number
  revenue: TaxedMoney
  images: ProductImage[]
  translation: ProductVariantTranslation
  digitalContent: DigitalContent
  collections: Collection[]
  stocks: Stock[]
  quantityAvailable: number
}

export type Image = {
  id: string
  url: string
  alt: string
}

export type Margin = {
  start: number
  stop: number
}

export type ProductPricingInfo = {
  onSale: boolean
  discount: TaxedMoney
  discountLocalCurrency: TaxedMoney
  priceRange: TaxedMoneyRange
  priceRangeUndiscounted: TaxedMoneyRange
  priceRangeLocalCurrency: TaxedMoneyRange
}

export interface CollectionTranslation extends Node {
  seoTitle: string
  seoDescription: string
  name: string
  description: string
  descriptionJson: string
  language: LanguageDisplay
}

export type ProductTranslation = CollectionTranslation

/* META DATA types */
export type MetaItem = {
  key: string
  value: string
}

export type MetaClientStore = {
  name: string
  metadata: MetaItem[]
}

export type MetaStore = {
  namespace: string
  clients: MetaClientStore[]
}
export interface ObjectWithMetadata {
  privateMetadata: MetadataItem[]
  metadata: MetadataItem[]
  meta: MetaStore[]
}
/* eof META DATA types */

export interface ProductSize {
  id: string
  name: string
}

export interface FullProduct extends Product {
  id: string
  seoTitle: string
  seoDescription: string
  name: string
  description: string
  descriptionJson: string
  publicationDate: string // date
  isPublished: boolean
  productType: ProductType
  availableForPurcahse: string // Date
  visibleInListings: boolean
  defaultVariant: ProductVariant
  privateMetadata: MetadataItem
  metadata: MetadataItem
  thumbnail: Image
  thumbnail2x: Image
  pricing: ProductPricingInfo
  isAvailable: boolean
  minimalVariantPrice: Money
  taxType: TaxType
  attributes: ProductAttribute[]
  purchaseCost: MoneyRange
  margin: Margin
  imageById: ProductImage
  variants: ProductVariant[]
  collections: Collection[]
  translation: ProductTranslation
  isAvailableForPurchase: boolean
  size: ProductSize[]
  recommendations: Product[]
  favorite: boolean
  currency?: Currency
  purchaseSpecifics?: ProductPurchaseSpecifics
  feedbacks: FeedbackWithCount[]
}

export interface Wishlist extends Node {
  createdAt: Date
  items: WishlistItemCountableConnection
}

export type WishlistError = {
  field: string
  message: string
}

export interface WishlistItem extends Node {
  wishlist: Wishlist
  product: Product
  variants: ProductVariantCountableConnection
}

export type GetProductByIdRequest = {
  data: GetProduct | null
  loading: boolean
}

export type ProductByIdVariables = {
  id: string
}

export type GetWishlistAddResults = {
  wishlistAddProduct: {
    wishlist: Wishlist
    wishlistErrors: []
  }
}

export type GetCreateProductResults = {
  productCreate: {
    product: Product
    productErrors: ProductError[]
  }
}

export type GetProductUpdateResults = {
  productUpdate: {
    product: Product
    productErrors: ProductError[]
  }
}

export type GetProductRemoveResults = {
  productRemove: {
    product: Product
    productErrors: ProductError[]
  }
}

export type GetProductImageCreateResults = {
  productImageCreate: {
    product: Product[]
    productErrors: ProductError[]
  }
}

export type GetProductImageBulkDeleteResults = {
  productImageBulkDelete: {
    productErrors: ProductError[]
  }
}

export type GetSetTopProductsResults = {
  setTopProducts: {
    products: Product[]
  }
}

export type GetProductRemoveFromVendorListResults = {
  productRemoveFromVendorList: {
    products: Product[]
  }
}

export type GetProductDuplucateResults = {
  productDuplicate: {
    products: Product[]
  }
}

export type GetWishlistRemoveResults = {
  wishlistRemoveProduct: {
    wishlist: Wishlist
    wishlistErrors: []
  }
}

export interface WishlistVariables extends ListVariables<any> {
  search?: string
  productCategoryId?: string
  productVendorId?: string
  productIsSale?: boolean
}

export type GetWishlistResults = {
  me: {
    wishlist: PaginationList<WishlistItem>
  }
}

export type ProductCreateVariables = {
  input: ProductCreateInput
}

export interface ProductUpdateVariables extends ProductCreateVariables {
  id: string
}

export type ProductImageCreateVariables = {
  input: ProductImageCreateInput
}

export type ProductRemoveVariables = {
  id: string
}

export type ProductRemoveFromVendorListVariables = {
  products: string[]
}

export type ProductImageBulkDeleteVariables = {
  ids: string[]
}

export type SetTopProductsVariables = {
  isTop: boolean
  products: string[]
}

export type ProductDuplicateVariables = {
  products: string[]
}

export type GetTopProductsRequest = {
  data: GetTopProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<GetTopProductsVariables>
  ) => Promise<ApolloQueryResult<GetTopProducts>>
}

export type GetPrevioslyProductsRequest = {
  data: GetPrevioslySeenProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<GetPrevioslyProductsVariables>
  ) => Promise<ApolloQueryResult<GetPrevioslySeenProducts>>
}

export type GetProductsRequest = {
  data: GetFeaturedProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetFeaturedProducts>>
}

export type GetFeaturedProductsRequest = {
  data: GetFeaturedProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetFeaturedProducts>>
}

export type GetRecommendationProductsRequest = {
  data: GetRecommendationProducts | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetRecommendationProducts>>
}

export type GetProducts = {
  data?: GetProductsData
  loading: boolean
  error?: ApolloError
  refetch: (
    variables?: Partial<ProductsVariables>
  ) => Promise<ApolloQueryResult<GetProductsData>>
  fetchMore?: FetchMore<GetProductsData, ProductsVariables>
}

export type GetProductsByCategoryIdRequest = {
  data: GetProductsByCategoryId | null
  loading: boolean
  refetch: (
    variables?: Partial<ProductsByCategoryIdVariables>
  ) => Promise<ApolloQueryResult<GetProductsByCategoryId>>
}

export type GetWishlistRequest = {
  data: GetWishlistResults | null
  loading: boolean
  refetch: (variables: WishlistVariables) => void
}

export type GetVendorCategoriesRequest = {
  data: GetVendorCategories | null
  loading: boolean
}

export type GetAddProductWishlistRequest = {
  onSubmit: () => void
  response: MutationResult<GetWishlistAddResults>
}

export type GetRemoveProductWishlistRequest = {
  onSubmit: () => void
  response: MutationResult<GetWishlistRemoveResults>
}

export type ProductCreate = {
  onSubmit: (variables: ProductCreateVariables) => void
  response: MutationResult<GetCreateProductResults>
}

export type ProductUpdate = {
  onSubmit: (variables: ProductUpdateVariables) => void
  response: MutationResult<GetProductUpdateResults>
}

export type ProductRemove = {
  onSubmit: (variables: ProductRemoveVariables) => void
  response: MutationResult<GetProductRemoveResults>
}

export type ProductRemoveFromVendorList = {
  onSubmit: (
    variables: ProductRemoveFromVendorListVariables,
    productVariables: ProductsVariables
  ) => void
  response: MutationResult<GetProductRemoveFromVendorListResults>
}

export type ProductImageCreate = {
  onSubmit: (variables: ProductImageCreateVariables) => void
  response: MutationResult<GetProductImageCreateResults>
}

export type ProductImageBulkDelete = {
  onSubmit: (variables: ProductImageBulkDeleteVariables) => void
  response: MutationResult<GetProductImageBulkDeleteResults>
}

export type SetTopProducts = {
  onSubmit: (variables: SetTopProductsVariables) => void
  response: MutationResult<GetSetTopProductsResults>
}

export type ProductDuplicate = {
  onSubmit: (variables: ProductDuplicateVariables) => void
  response: MutationResult<GetProductDuplucateResults>
}

export type ProductApi = {
  useProducts: (variables: ProductsVariables) => GetProducts
  useFeaturedProducts: (
    variables: ProductsVariables
  ) => GetFeaturedProductsRequest
  useRecommendationProducts: (
    variables: ProductsVariables
  ) => GetRecommendationProductsRequest
  useTopProducts: (variables: GetTopProductsVariables) => GetTopProductsRequest
  usePrevioslySeenProducts: (
    variables: GetPrevioslyProductsVariables
  ) => GetPrevioslyProductsRequest
  useProductsByCategoryId: (
    variables: ProductsByCategoryIdVariables
  ) => GetProductsByCategoryIdRequest
  useProductById: (variables: ProductByIdVariables) => GetProductByIdRequest
  useProductPreviewById: (
    variables: ProductByIdVariables
  ) => GetProductByIdRequest
  useAddProductToWishlist: (productId: string) => GetAddProductWishlistRequest
  useRemoveProductFromWishlist: (
    productId: string
  ) => GetRemoveProductWishlistRequest
  useWishlist: (variables: WishlistVariables) => GetWishlistRequest
  useVendorCategories: (
    variables: VendorCategoriesVariables
  ) => GetVendorCategoriesRequest
  useProductCreate: () => ProductCreate
  useProductUpdate: () => ProductUpdate
  useProductRemove: () => ProductRemove
  useProductRemoveFromVendorList: () => ProductRemoveFromVendorList
  useProductImageCreate: () => ProductImageCreate
  useProductImageBulkDelete: () => ProductImageBulkDelete
  useSetTopProducts: () => SetTopProducts
  useProductDuplicate: () => ProductDuplicate
}
