import React, { FC, useMemo } from 'react'

import { Button, Column, PermissionContent, Row, Text } from '../../components'
import { OfferStatus, PermissionEnum } from '../../services'
import { zeroPad } from '../../pages/views/helpers'

import { OfferChatItem } from './chat-item-overview'

import { useStyle } from './chat-overview.styles'
import { ChatOverviewProps } from './chat-overview.types'
import { OfferPriceChat } from './chat-price-overview'
import { OrderChatStatus } from './chat-status-overview'

export const ChatOverview: FC<ChatOverviewProps> = ({
  statusColor,
  title,
  titleTx,
  titleValues,
  statusText,
  statusTx,
  disabledObserve,
  offer,
  orderDelievryPrice,
  currencySymbol,
  onToOffer,
  onDecline
}) => {
  const classes = useStyle()

  const isStatusDraft =
    offer.status === OfferStatus.DRAFT ||
    offer.status === OfferStatus.START_OFFER

  const DeliveryPrice = useMemo(() => {
    if (orderDelievryPrice) {
      return orderDelievryPrice
    }
    if (offer.deliveryPrice && offer.deliveryPrice !== null) {
      return offer.deliveryPrice
    }
    return 0
  }, [offer?.product, orderDelievryPrice])

  const { product, productTotalPrice } = offer

  return (
    <Column className={classes.container}>
      <OrderChatStatus
        isStatusDraft={isStatusDraft}
        color={statusColor}
        title={title}
        titleTx={titleTx}
        titleValues={titleValues}
        statusText={statusText}
        statusTx={statusTx}
      />
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.productContainer}
      >
        <Column fullWidth alignItems="flex-start" justifyContent="flex-start">
          <img className={classes.avatar} src={offer.product.thumbnail2x.url} />
          <Column fullWidth alignItems="flex-start">
            <Text
              className={classes.offerName}
              preset="h5"
              text={offer.product.name}
            />
            {offer.items &&
              offer.items.map((optionItem, index) => {
                const initialIndex = index + 1
                const itemNumber = zeroPad(initialIndex, 2)

                return (
                  <OfferChatItem
                    key={`option_${optionItem}_${index}`}
                    value={optionItem}
                    count={itemNumber}
                    totalQuantity={offer.quantity}
                    disabled={disabledObserve}
                    description={offer.description}
                    product={product}
                    options={offer.options}
                    discount={offer.discount}
                    noSuggestion={offer.noSuggestion}
                    productTotalPrice={productTotalPrice}
                    offerOptions={offer.productOptions}
                    offerColors={offer.productColors}
                    offerMaterials={offer.productMaterials}
                    isStatusDraft={isStatusDraft}
                  />
                )
              })}
          </Column>
        </Column>
        <OfferPriceChat
          className={classes.options}
          currencySymbol={currencySymbol}
          price={offer.productTotalPrice}
          deliveryPrice={DeliveryPrice}
          totalPrice={offer.totalPrice}
          quantity={offer.quantity}
        />
        <Row fullWidth className={classes.buttonContainer}>
          {onToOffer && (
            <Button
              textColor="white"
              textPreset="h5"
              preset="primary"
              text="Go To Offer"
              tx="chat.to.offer"
              onClick={onToOffer}
            />
          )}
        </Row>
        <Row fullWidth className={classes.buttonContainer}>
          {onDecline && (
            <PermissionContent
              permissions={[PermissionEnum.ADD_CHANGE_REMOVE_OWN_ORDERS]}
            >
              <Button
                textColor="darkBlue"
                color="darkBlue"
                textPreset="h5"
                preset="secondaryBorder"
                text="Decline Order"
                tx="order.item.declineOrder"
                onClick={onDecline}
              />
            </PermissionContent>
          )}
        </Row>
      </Column>
    </Column>
  )
}
