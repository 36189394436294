import React, { FC } from 'react'

import { Loader } from '../loader'

import { LoadingContainerProps } from './loading-container.types'

export const LoadingContainer: FC<LoadingContainerProps> = ({
  children,
  loading,
  ...props
}) => {
  if (loading) {
    return <Loader {...props} />
  }
  return <>{children}</>
}
