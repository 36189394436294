import { ApolloError } from '@apollo/client'

import {
  DateRangeInput,
  ListVariables,
  PaginationList,
  FilterItem,
  SortBy
} from '../api.types'

export enum UserSortField {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  EMAIL = 'EMAIL',
  DATE_JOINED = 'DATE_JOINED',
  LAST_LOGIN = 'LAST_LOGIN',
  ORDER_COUNT = 'ORDER_COUNT',
  IN_PROGRESS_ORDER_COUNT = 'IN_PROGRESS_ORDER_COUNT',
  COMPLETED_ORDER_COUNT = 'COMPLETED_ORDER_COUNT',
  CANCELED_ORDER_COUNT = 'CANCELED_ORDER_COUNT',
  ITEM_COUNT = 'ITEM_COUNT',
  COUNTRY = 'COUNTRY',
  SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE',
  CITY = 'CITY'
}

export type StatisticUser = {
  id: string
  firstName: string
  lastName: string
  username: string
  lastLogin: Date
  companyName: string
  dateJoined: string
  canceledOrdersAmount: number
  completedOrdersAmount: number
  inProgressOrdersAmount: number
  country: string
  itemsAmount: number
  ordersAmount: number
  subscription: {
    name: string
  }
}

export type VendorFiltersParseJSON = {
  companyNames: FilterItem[]
  countries: FilterItem[]
  subscriptionTypes: FilterItem[]
  vendors: FilterItem[]
}

export type VendorFilterInput = {
  dateJoined?: DateRangeInput
  companyNames?: string[]
  id?: string[]
  firstName?: string
  lastName?: string
  country?: string[]
  subscription?: string[]
  search?: string
}

export type VendorFilterInputKeys = keyof VendorFilterInput

export interface AdminStatisticVariables
  extends ListVariables<VendorFilterInput> {
  sortBy?: SortBy<UserSortField>
}

export interface AdminStatisticData extends PaginationList<StatisticUser> {
  filters: string
}

export type GetAdminStatisticData = {
  adminStatistic: AdminStatisticData
}

export type AdminStatistic = {
  data?: GetAdminStatisticData | null
  loading: boolean
  error?: ApolloError
  refetch: (variables?: Partial<AdminStatisticVariables>) => void
}

export type StatisticApi = {
  useAdminStatistic: (variables: AdminStatisticVariables) => AdminStatistic
}
