import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`
  },
  tableTopContainer: {
    padding: 16
  },
  search: {
    width: '100%'
  },
  header: {
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    height: 48
  }
}))
