import { TextProps } from '../components'
import { AccountError, AccountErrorCode } from '../services'

export const getAccountError = (
  code: AccountErrorCode
): TextProps | undefined => {
  switch (code) {
    case AccountErrorCode.INVALID_CREDENTIALS:
      return {
        text: 'Incorrect email or password',
        tx: 'login.error'
      }
      break

    default:
      return undefined
  }
}
export const getAccountErrors = (errors: AccountError[]) => {
  return errors.reduce<TextProps[]>((acc, error) => {
    const errorText = getAccountError(error.code)
    if (errorText) {
      return [...acc, errorText]
    }

    return acc
  }, [])
}
