import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  button: {
    marginLeft: 24
  },
  success: {
    width: 168,
    height: 40,
    marginLeft: 24
  },
  field: {
    marginTop: 16
  },
  bottom: {
    padding: '24px 0px'
  }
})
