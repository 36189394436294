import React, { FC } from 'react'

import { Column, Row } from '../../../../components'
import { Currency } from '../../../../services'

import { ProductPreviewGallery } from '../product-preview-gallery'
import { ProductPreviewBasicProductInfo } from '../product-preview-basic-product-info'
import { ProductPreviewVendorInfo } from '../product-preview-vendor-info'
import { ProductPreviewButtonsRow } from '../product-preview-buttons-row'
import { ProductPreviewDetails } from '../product-preview-details'

import { ProductPreviewGeneralInfoProps } from './product-preview-general-info.types'
import { useStyle } from './product-preview-general-info.styles'

export const ProductPreviewGeneralInfo: FC<ProductPreviewGeneralInfoProps> = ({
  product,
  favouriteIcon,
  averageRating,
  user
}) => {
  const classes = useStyle()
  const Container = Row

  const { images = [], currency, totalPrice, name, deliveryPrice } = product

  const productDeliveryPrice = deliveryPrice !== null ? deliveryPrice?.price : 0
  const isCurrency = currency || Currency.USD

  return (
    <Container
      fullWidth
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Row className={classes.imageContainer}>
        <ProductPreviewGallery images={images} />
      </Row>
      <Column
        fullWidth
        className={classes.infoContainer}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <ProductPreviewBasicProductInfo
          favouriteIcon={favouriteIcon}
          name={name}
          currency={isCurrency}
          deliveryPrice={productDeliveryPrice}
          totalPrice={totalPrice}
          averageRating={averageRating}
        />
        <ProductPreviewVendorInfo
          id={user?.id}
          className={classes.vendorInfo}
          name={user?.companyName}
          countryCode={user?.defaultBillingAddress?.country.code}
        />
        <ProductPreviewButtonsRow />
        <Column fullWidth className={classes.sliderContainer}>
          <ProductPreviewDetails product={product} id={product.id} />
        </Column>
      </Column>
    </Container>
  )
}
