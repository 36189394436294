import React, { FC } from 'react'

import { DatePicker, Row, Section } from '../../../components'

import { useStyle } from './pick-date-section.styles'

import { PickDateSectionProps } from './pick-date-section.types'

export const PickDateSection: FC<PickDateSectionProps> = ({
  className = '',
  defaultValueStart,
  defaultValueEnd,
  onChangeDate
}) => {
  const classes = useStyle()

  return (
    <Section
      className={className}
      title="Duration"
      titleTx="banner.duration.title"
    >
      <Row alignItems="flex-start" className={classes.sectionAdditionContainer}>
        <DatePicker
          defaultValue={defaultValueStart}
          label="Start Date"
          labelTx="banner.start.date"
          onChange={onChangeDate('startDate')}
        />
        <DatePicker
          defaultValue={defaultValueEnd}
          className={classes.end}
          label="End Date"
          labelTx="banner.end.date"
          onChange={onChangeDate('endDate')}
        />
      </Row>
    </Section>
  )
}
