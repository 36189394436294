import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    '&:hover > span': {
      color: theme.colors.yellow
    },
    '&:hover > svg': {
      fill: theme.colors.yellow
    },
    cursor: 'pointer',
    padding: '4px 0px'
  },
  title: {
    marginLeft: 16
  }
}))
