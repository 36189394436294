import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

import AuthProvider from './auth/auth-provider'
import { LocaleProvider, MessageManagerProvider } from './components'
import ThemeProvider from './components/Theme'

import { APP_MOUNT_URI, PAYPAL_CLIENT_ID } from './config'
import { Routes } from './routes'
import {
  AppStateProvider,
  ChatProvider,
  BackgroundTasksProvider,
  ApiProvider,
  ToastifyProvider
} from './providers'

import './index.css'

export const App: React.FC = () => {
  const paypalOptions = {
    'client-id': PAYPAL_CLIENT_ID,
    currency: 'USD',
    components: 'buttons'
  }

  const isDark = localStorage.getItem('theme') === 'true'

  return (
    <BrowserRouter basename={APP_MOUNT_URI}>
      <ApiProvider>
        <ThemeProvider isDefaultDark={isDark}>
          <PayPalScriptProvider options={paypalOptions}>
            <LocaleProvider>
              <MessageManagerProvider>
                <BackgroundTasksProvider>
                  <AppStateProvider>
                    <AuthProvider>
                      <ChatProvider>
                        <div id="sidebar" />
                        <div id="modal" />
                        <div id="modal-delete" />
                        <div id="logout" />
                        <ToastifyProvider>
                          <Routes />
                        </ToastifyProvider>
                      </ChatProvider>
                    </AuthProvider>
                  </AppStateProvider>
                </BackgroundTasksProvider>
              </MessageManagerProvider>
            </LocaleProvider>
          </PayPalScriptProvider>
        </ThemeProvider>
      </ApiProvider>
    </BrowserRouter>
  )
}
