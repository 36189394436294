import React, { FC } from 'react'

import { AddFundsButton } from '../../pages/views'
import { Currency } from '../../services'

import { Text } from '../text'
import { Column } from '../column'
import { Row } from '../row'
import { InfoTooltip } from '../info-tooltip'
import { getCurrencySymbol, getPriceToFixed } from '../helpers'

import { useStyle } from './user-info-badge-balance.styles'
import { UserInfoBadgeBalanceProps } from './user-info-badge-balance.types'

export const UserInfoBadgeBalance: FC<UserInfoBadgeBalanceProps> = ({
  id,
  differenceToBePaid,
  value,
  title,
  titleTx,
  instruction
}) => {
  const isDifference = Boolean(differenceToBePaid) || differenceToBePaid === 0
  const classes = useStyle({
    differenceToBePaid: isDifference
  })

  return (
    <Column fullWidth className={classes.container}>
      <Row fullWidth className={classes.title} justifyContent="space-between">
        <Row>
          <Text color="black" preset="h4" text={title} tx={titleTx} />
          <Row className={classes.tooltipContainer}>
            <InfoTooltip isBottom text={instruction} />
          </Row>
        </Row>
        <AddFundsButton
          title="Add Funds"
          titleTx="add.funds.modal.title"
          description="Attention!"
          descriptionTx="add.funds.modal.description"
          secondaryDescription="Funds deposited can be spent by you on advertising and subscription. Funds are withdrawn from the deposit for the coming day of advertising. In case of stopping advertising, funds are not returned, but can be spent on any paid service of the Ovaflopic marketplace."
          secondaryDescriptionTx="add.funds.modal.secondary.description"
          id={id}
          value={`${getCurrencySymbol(Currency.USD)}${getPriceToFixed(value)}`}
        />
      </Row>
      {isDifference && (
        <Row
          fullWidth
          className={classes.errorPriceContainer}
          justifyContent="flex-start"
        >
          <Text
            text="Difference to be paid:"
            tx="promotion.advertising.form.differenceToBePaid"
            preset="secondTitle"
            color="inactive"
          />
          <Text
            className={classes.text}
            text={`${getCurrencySymbol(Currency.USD)}${getPriceToFixed(
              differenceToBePaid
            )}`}
            preset="maxButton"
            color="error"
          />
        </Row>
      )}
      <Row fullWidth className={classes.value} justifyContent="flex-start">
        <Text
          color="black"
          preset="h2"
          text={`${getCurrencySymbol(Currency.USD)}${getPriceToFixed(value)}`}
          className={classes.valueText}
        />
      </Row>
    </Column>
  )
}
