import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

import { CalendarPickerType } from './calendar-picker.types'

export type StyleProps = {
  pickerType: CalendarPickerType
}

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '8px 0'
  },
  buttonContainer: {
    marginTop: 16,
    padding: 2,
    borderRadius: 4,
    backgroundColor: theme.colors.greyFon
  },
  button: {
    flex: 1,
    alignSelf: 'stretch'
  },
  calendar: {
    marginTop: 16
  },
  text: {
    minWidth: 120,
    textAlign: 'center'
  }
}))
