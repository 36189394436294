import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  searchContainer: {
    padding: 16,
    border: `1px solid ${theme.colors.shadow}`
  },
  order: {
    marginTop: 16
  },
  sort: {
    marginLeft: 16,
    minWidth: 224
  },
  sortDropdown: {
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: '1px solid black',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))
