import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import { OffersTableItemStyle } from './offers-table-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  image: {
    marginLeft: 16,
    objectFit: 'cover'
  },
  tableItem: {
    padding: '8px 16px',
    minWidth: 120
  },
  tableColumn: {
    flex: 1.5,
    height: '100%',
    padding: '8px 4px',
    minWidth: 80,
    maxWidth: 136,
    overflow: 'hidden'
  },
  tableColumnFirst: {
    minWidth: 88
  },
  tableColumnMin: {
    flex: 1,
    maxWidth: 88
  },
  tableColumnStatus: {
    flex: 1,
    minWidth: 80,
    maxWidth: 144,
    overflow: 'hidden'
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ({ hoverButton }: OffersTableItemStyle) =>
        hoverButton ? theme.colors.white : theme.colors.lightBlue
    }
  },
  contactVendor: {
    cursor: 'pointer'
  },
  statusText: {
    marginLeft: 8,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textHiden: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  button: {
    padding: 8
  }
}))
