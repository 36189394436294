import React, { FC } from 'react'

import { Row, Column } from '../../../../../components'

import { VendorPreviewDetailsItem } from '../vendor-preview-details-item'

import { VendorPreviewInfoProps } from './vendor-preview-info.types'
import { useStyle } from './vendor-preview-info.styles'

export const VendorPreviewInfo: FC<VendorPreviewInfoProps> = ({
  activity,
  averageResponseTime,
  positiveFeedback,
  dateJoined
}) => {
  const classes = useStyle()

  return (
    <Row
      className={classes.info}
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      wrap
    >
      <Column
        alignItems="flex-start"
        justifyContent="flex-start"
        fullHeight
        className={classes.vendorInfo}
      >
        <VendorPreviewDetailsItem
          className={classes.vendorStatuses}
          title="Activity on the site:"
          titleTx="vendor.preview.activity"
          value={activity}
        />
        <VendorPreviewDetailsItem
          className={classes.vendorStatuses}
          title="Average response time:"
          titleTx="vendor.preview.averange.responce.time"
          value={averageResponseTime || '-'}
        />
      </Column>
      <Column
        alignItems="flex-start"
        justifyContent="flex-start"
        fullHeight
        className={classes.vendorInfo}
      >
        <VendorPreviewDetailsItem
          className={classes.vendorStatuses}
          title="Positive Feedback:"
          titleTx="vendor.preview.positive.feedback"
          value={positiveFeedback}
        />
        <VendorPreviewDetailsItem
          className={classes.vendorStatuses}
          title="Ovaflopick data Since:"
          titleTx="vendor.preview.data.since"
          value={dateJoined || '-'}
        />
      </Column>
    </Row>
  )
}
