import React, { FC } from 'react'

import { Text } from '../text'
import { Column } from '../column'
import { Row } from '../row'
import { Icon } from '../icon'

import { useStyle } from './user-info-badge.styles'
import { UserInfoBadgeProps } from './user-info-badge.types'

export const UserInfoBadge: FC<UserInfoBadgeProps> = ({
  title,
  titleTx,
  description,
  descriptionTx,
  icon,
  value
}) => {
  const classes = useStyle()

  return (
    <Row fullWidth className={classes.container}>
      <Column className={classes.title} alignItems="flex-start">
        <Text color="black" preset="h4" text={title} tx={titleTx} />
        <Text
          color="inactive"
          preset="secondTitle"
          text={description}
          tx={descriptionTx}
          className={classes.secondTitle}
        />
      </Column>
      <Row className={classes.value} alignItems="flex-end">
        <Text color="black" preset="h2" text={value} />
        <Column fullHeight className={classes.iconContainer}>
          <Icon src={icon} width={56} height={56} className={classes.icon} />
        </Column>
      </Row>
    </Row>
  )
}
