import React, { FC, ReactNode } from 'react'

import { useStyles } from './styles'

export type ContainerProps = {
  children: ReactNode
}

const Container: FC<ContainerProps> = ({ children }) => {
  const classes = useStyles({})

  if (children) {
    return <div className={classes.container}>{children}</div>
  }

  return <></>
}

export default Container
