// import { ApolloQueryResult } from '@apollo/client'

import { ListVariables, PaginationList, SortBy } from '../api.types'

export enum AttributeInputType {
  DROPDOWN = 'DROPDOWN',
  MULTISELECT = 'MULTISELECT'
}

export enum AttributeSortField {
  // Sort attributes by name
  NAME = 'NAME',
  // Sort attributes by slug
  SLUG = 'SLUG',
  // Sort attributes by the value required flag
  VALUE_REQUIRED = 'VALUE_REQUIRED',
  // Sort attributes by the variant only flag
  IS_VARIANT_ONLY = 'IS_VARIANT_ONLY',
  // Sort attributes by visibility in the storefront
  VISIBLE_IN_STOREFRONT = 'VISIBLE_IN_STOREFRONT',
  // Sort attributes by the filterable in storefront flag
  FILTERABLE_IN_STOREFRONT = 'FILTERABLE_IN_STOREFRONT',
  // Sort attributes by the filterable in dashboard flag
  FILTERABLE_IN_DASHBOARD = 'FILTERABLE_IN_DASHBOARD',
  // Sort attributes by their position in storefront
  STOREFRONT_SEARCH_POSITION = 'STOREFRONT_SEARCH_POSITION',
  // Sort attributes based on whether they can be displayed or not in a product grid.
  AVAILABLE_IN_GRID = 'AVAILABLE_IN_GRID'
}

export type AttributeType = {
  id: string
  type: string
  title: string
}

export type AttributeSortingInput = SortBy<AttributeSortField>

export type AttributeFilterInput = {
  valueRequired: boolean
  isVariantOnly: boolean
  visibleInStorefront: boolean
  filterableInStorefront: boolean
  filterableInDashboard: boolean
  availableInGrid: boolean
  search: string
  ids: string[]
  inCollection: string
  inCategory: string
}

export type AttributeValue = {
  id: string
  title: string
  type: string
}

export type Attribute = {
  id: string
  type: string
  title: string
}

export type ProductAttributeFilter = {
  search?: string
}

export interface AttributeVariables
  extends ListVariables<ProductAttributeFilter> {}

export type GetAttributesData = {
  productAttributes: PaginationList<AttributeType>
}

export type GetAttributes = {
  data: GetAttributesData | null
  loading: boolean
  refetch: (variables: Partial<AttributeVariables>) => void
}

export type AttributeApi = {
  useAttributes: (variables: AttributeVariables) => GetAttributes
}
