import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Column, PermissionContent, Row } from '../../components'
import { useApi, useToastify } from '../../providers'
import { SortVariables, useSort, useTable } from '../../hooks'
import {
  CollectionSortField,
  CollectionUpdateInput,
  CollectionWithProductsCount,
  OrderDirection,
  PermissionEnum,
  ProductOrderField,
  VendorCollectionsVariables
} from '../../services'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { TableCollections, TableCollectionsProps } from './table-collections'
import { useStyle } from './items-collections.styles'

const rowCounts = [5, 10, 20, 30]

export const ItemsCollectionsPage = () => {
  const { open } = useToastify()
  const history = useHistory()
  const classes = useStyle()

  const defaultVariables = {
    first: rowCounts[1]
  }
  const [collectionVariables, changeCollectionVariables] =
    useState<VendorCollectionsVariables>({
      first: rowCounts[1]
    })

  const defaultVariablesWithSort = {
    ...defaultVariables,
    sortBy: { direction: OrderDirection.ASC, field: ProductOrderField.NAME }
  }

  const { collection } = useApi()
  const { data, refetch, loading } = collection.useVendorCollections(
    defaultVariablesWithSort
  )
  const {
    Data,
    changeCurrentData,
    currentData,
    paginationState,
    onSearch,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.vendorCollections,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  const { onSubmit: onRemove, response: collectionDeleteResponse } =
    collection.useCollectionDelete()
  const { onSubmit: onUpdate, response: collectionUpdateResponse } =
    collection.useCollectionUpdate()

  useEffect(() => {
    if (collectionDeleteResponse.data) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })
    }
  }, [collectionDeleteResponse.data?.collectionDelete?.collection])

  useEffect(() => {
    if (collectionUpdateResponse.data) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })
    }
  }, [collectionUpdateResponse.data?.collectionUpdate?.collection])

  const handleOnCreate = () => {
    history.push(CabinetNavigatorPathVendor.CREATE_COLLECTIONS)
  }

  const handleOnUpdate = (publishData: CollectionWithProductsCount[]) => {
    publishData.forEach((item) => {
      const newState = {
        id: item.id,
        input: {
          name: item.name,
          isPublished: item.isPublished
        } as CollectionUpdateInput
      }
      onUpdate(newState)
    })
  }

  const handleOnRemove = (ids: string[]) => {
    ids.forEach((id) => onRemove({ id }))

    const nextProducts = currentData.filter(({ node }) => {
      const currentItem = ids.find((id) => id === node.id)

      return !currentItem
    })
    changeCurrentData(nextProducts)
  }

  const handleOnSearch: TableCollectionsProps['onChangeSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnSort = (sortVariables: SortVariables<CollectionSortField>) => {
    if (refetch) {
      refetch({
        ...sortVariables,
        before: undefined,
        after: undefined,
        last: undefined
      })
      onResetPageState()
    }
    changeCollectionVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: collectionVariables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth alignItems="flex-end" justifyContent="flex-end">
        <PermissionContent
          permissions={[
            PermissionEnum.CREATE_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
        >
          <Button
            className={classes.button}
            preset="primary"
            text="CREATE COLLECTION"
            tx="item.collections.button.create"
            textPreset="h5"
            textColor="white"
            onClick={handleOnCreate}
          />
        </PermissionContent>
      </Row>
      <Column fullWidth className={classes.itemTable}>
        <TableCollections
          data={Data}
          loading={loading}
          rowCounts={rowCounts}
          onUpdate={handleOnUpdate}
          paginationState={paginationState}
          onChangeSearch={handleOnSearch}
          onRemove={handleOnRemove}
          onChangeRowCount={onChangeRowCount}
          onNextPage={onNextPage}
          onPrevioslyPage={onPrevioslyPage}
          onSort={onSort}
        />
      </Column>
    </Column>
  )
}
