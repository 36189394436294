import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  avatar: {
    marginLeft: 16,
    minWidth: 48
  },
  tableItem: {
    padding: '8px 16px',
    minWidth: 120
  },
  tableColumn: {
    flex: 1.5,
    height: '100%',
    padding: '8px 4px',
    minWidth: 80,
    maxWidth: 136
  },
  tableColumnFirst: {
    minWidth: 88
  },
  tableColumnLast: {
    minWidth: 120
  },
  tableColumnMinWidth: {
    flex: 1,
    maxWidth: 88
  },
  tableColumnStatus: {
    flex: 1,
    minWidth: 80,
    maxWidth: 144
  },
  button: {
    padding: 8
  }
})
