import { ADDRESS_FRAGMENT } from './auth'
import { PRODUCT_PRICING_FRAGMENT, PRODUCT_FRAGMENT_FOR_OFFER } from './product'
import { OFFER_FRAGMENT_FOR_ORDER, OFFER_ITEM_FRAGMENT } from './offer'

export const ORDER_USER_FRAGMENT = `
  fragment OrderUserFragment on User {
    email
    city
    firstName
    lastName
    country
    phone
    id
    avatar  {
      url
      alt
    }
  }
`

export const ORDER_VENDOR_FRAGMENT = `
  ${ORDER_USER_FRAGMENT}
  fragment OrderVendorFragment on User {
    ...OrderUserFragment
    companyName
  }
`

export const ORDER_CUSTOMER_FRAGMENT = `
  ${ORDER_USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  fragment OrderCustomerFragment on User {
    ...OrderUserFragment
    defaultShippingAddress {
      ...AddressFragment
    }
  }
`

export const ORDER_LINE_FRAGMENT = `
  ${PRODUCT_PRICING_FRAGMENT}
  ${PRODUCT_FRAGMENT_FOR_OFFER}
  ${OFFER_ITEM_FRAGMENT}
  fragment OrderLineFragment on OrderLine {
    productName
    variantName
    productSku
    isShippingRequired
    quantity
    quantityFulfilled
    taxRate
    totalPrice
    product {
      ...ProductFragmentForOffer
      ...ProductPricingField
    },
    items {
      ...OfferItemFragment
    }
  }
`

export const ORDER_TOTAL_FRAGMENT = `
  fragment OrderTotalFragment on TaxedMoney {
    currency
    gross {
      currency
      amount
    }
    net {
      currency
      amount
    }
    tax {
      currency
      amount
    }
  }
`

export const ORDER_FRAGMENT = `
  ${ADDRESS_FRAGMENT}
  ${ORDER_LINE_FRAGMENT}
  ${ORDER_TOTAL_FRAGMENT}
  ${ORDER_VENDOR_FRAGMENT}
  ${ORDER_CUSTOMER_FRAGMENT}
  ${OFFER_FRAGMENT_FOR_ORDER}
  fragment OrderFragment on Order {
    id
    created
    startDate
    finishDate
    mainStatus
    paymentStatus
    trackingNumber
    deliveryPrice
    remainingTimeProductionStarted
    shippingAddress {
      ...AddressFragment
    }
    lines {
      ...OrderLineFragment
    }
    number
    total {
      ...OrderTotalFragment
    }
    user {
      ...OrderCustomerFragment
    }
    vendor {
      ...OrderVendorFragment
    }
    offer {
      ...OfferFragmentForOrder
    }
    totalPrice
    event
  }
`

export const ORDER_FEEDBACK_FRAGMENT = `
  ${ORDER_LINE_FRAGMENT}
  ${ORDER_TOTAL_FRAGMENT}
  ${ORDER_VENDOR_FRAGMENT}
  ${ORDER_USER_FRAGMENT}
  ${OFFER_FRAGMENT_FOR_ORDER}
  fragment OrderFeedbackFragment on Order {
    id
    lines {
      ...OrderLineFragment
    }
    number
    total {
      ...OrderTotalFragment
    }
    user {
      ...OrderUserFragment
    }
    vendor {
      ...OrderVendorFragment
    }
    offer {
      ...OfferFragmentForOrder
    }
    totalPrice
    event
  }
`
