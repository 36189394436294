import React, { FC, useMemo, useState } from 'react'

import {
  CalendarSlider,
  Column,
  FilterListByAlphabet,
  FiltersSidebar,
  getGraphDate,
  Icon,
  ICONS
} from '../../../components'
import { OrderControlFiltersKeys, OrderFilterInput } from '../../../services'

import { useStyle } from './orders-activity-filters.styles'
import { OrdersActivityFiltersProps } from './orders-activity-filters.types'

export const OrdersActivityFilters: FC<OrdersActivityFiltersProps> = ({
  className = '',
  filters,
  onSubmit,
  onReset
}) => {
  const [openSlider, changeOpenSlider] = useState<
    Partial<Record<keyof OrderFilterInput, boolean | undefined>>
  >({})
  const [filter, changeFilter] = useState<Partial<OrderFilterInput>>({})
  const [open, changeOpen] = useState(false)

  const classes = useStyle()

  const isDisabled = () => {
    const disabled = filter.created || filter.customer
    if (disabled) {
      return false
    }

    return true
  }

  const dateValue = useMemo(() => {
    if (filter.created) {
      const { gte, lte } = filter.created

      if (gte && lte) {
        return [new Date(gte), new Date(lte)]
      }

      if (gte) {
        return [new Date(gte)]
      }
    }

    return undefined
  }, [filter.created])

  const handleOnChange =
    (prop: OrderControlFiltersKeys) => (value: string[]) => {
      changeFilter({ ...filter, [prop]: value })
    }

  const handleOnChangeDate = (from: string, to?: string) => {
    if (from) {
      changeFilter({
        ...filter,
        created: {
          gte: getGraphDate(from),
          lte: to ? getGraphDate(to) : undefined
        }
      })
    } else {
      changeFilter({
        ...filter,
        created: undefined
      })
    }
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    if (onSubmit) {
      onSubmit(filter)
    }
    close()
  }

  const handleOnOpenSlider =
    (prop: OrderControlFiltersKeys) => (state: boolean) => {
      changeOpenSlider({ ...openSlider, [prop]: state })
    }

  const handleOnReset = () => {
    const close = handleOnOpen(false)

    changeFilter({})
    changeOpenSlider({})
    if (onReset) {
      onReset()
    }
    close()
  }

  return (
    <Column
      className={`${className} ${classes.container}`}
      onClick={handleOnOpen(true)}
    >
      <Icon src={ICONS.filterList} height={32} width={32} />
      <FiltersSidebar
        open={open}
        onClose={handleOnOpen(false)}
        onApply={handleOnSubmit}
        onReset={handleOnReset}
        isDisabled={isDisabled()}
      >
        {open && (
          <Column fullWidth>
            <CalendarSlider
              className={classes.slider}
              defaultOpen={openSlider.created}
              title="Order Creation Date"
              titleTx="activity.filters.date"
              value={dateValue}
              onOpen={handleOnOpenSlider('created')}
              onChange={handleOnChangeDate}
            />
            <FilterListByAlphabet
              className={classes.slider}
              defaultValue={filter.customer}
              defaultOpen={openSlider.customer}
              data={filters.customers}
              name="customer"
              title="Purchaser Name"
              titleTx="activity.filters.publisher"
              onOpen={handleOnOpenSlider('customer')}
              onChange={handleOnChange('customer')}
            />
          </Column>
        )}
      </FiltersSidebar>
    </Column>
  )
}
