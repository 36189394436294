import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

import { DropdownProps, StyleProps } from './dropdown.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: ({ disabled }: Partial<DropdownProps>) =>
      disabled ? 'default' : 'pointer',
    minHeight: ({ custom }: StyleProps) => (!custom ? 56 : custom.container),
    border: ({ error }: StyleProps) =>
      error
        ? `1px solid ${theme.colors.error}`
        : `1px solid ${theme.colors.inactive}`,
    borderRadius: 4,
    padding: '6px 24px',
    backgroundColor: theme.colors.white,
    '&:focus-within': {
      border: `2px solid ${theme.colors.error}`
    }
  },
  label: ({ disabled, value }: StyleProps) => ({
    transformOrigin: '0 0',
    transform: value ? 'scale(1)' : 'scale(1.25) translateY(-2px)',
    transition: 'transform 0.25s ease-in-out',
    color: !disabled && value ? theme.colors.yellow : theme.colors.inactive
  }),
  disabled: {
    color: theme.colors.inactive
  },
  disabledLabel: {
    color: `${theme.colors.greyFon}!important`
  },
  dropdownItems: {
    top: 0,
    width: '100% !important',
    maxHeight: 500,
    overflowY: 'scroll',
    border: `1px solid ${theme.colors.inactive}`,
    '&::-webkit-scrollbar-thumb': {
      borderTop: `4px solid ${theme.colors.white}`,
      borderBottom: `4px solid ${theme.colors.white}`,
      borderRadius: 4
    }
  },
  input: {
    width: '100%'
  }
}))
