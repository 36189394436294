import React, { FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import { ProductCreateColorInput } from '../../../services'
import {
  arrayHasElements,
  Checkbox,
  Column,
  Icon,
  ICONS,
  LightTheme,
  Row,
  Text
} from '../../../components'

import { ProductColorItemModal } from '../product-color-item-modal'
import { ProductColorItemModalProps } from '../product-color-item-modal/product-color-item-modal.types'

import { ColorTableItem } from './color-table-item'
import { useStyle } from './color-table.styles'
import { ColorsTableProps } from './color-table.types'

export const ColorsTable: FC<ColorsTableProps> = ({
  className = '',
  data,
  colors,
  onChange
}) => {
  const [active, changeActive] = useState<number[]>([])
  const [checkAll, changeCheckAll] = useState(false)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const [openConfirmModal, changeOpenConfirmModal] = useState(false)

  const handleOnOpen = (state: boolean) => () => {
    changeOpenConfirmModal(state)
  }

  const handleOnChange = (index: number) => (state: boolean) => {
    if (state) {
      return changeActive([...active, index])
    }

    const prevState = active.filter((prevIndex) => prevIndex !== index)
    return changeActive(prevState)
  }

  const handleOnChangeDefault =
    (defaultIndex: number, nextData?: ProductCreateColorInput[]) => () => {
      const currentDataValue = nextData || data
      const nextDataValue = currentDataValue.map((color, index) => ({
        ...color,
        isDefault: index === defaultIndex
      }))

      if (onChange) {
        onChange(nextDataValue)
      }
    }

  const handleOnCheckAll = () => {
    if (!checkAll) {
      const checkedActive = data.map((_, index) => index)
      changeActive(checkedActive)
    } else {
      changeActive([])
    }
    changeCheckAll(!checkAll)
  }

  const getActive = (index: number) => {
    const activeColor = active.find((item) => item === index)

    return typeof activeColor === 'number'
  }

  const handleOnRemove = () => {
    const nextData = data.filter((_, index) => !getActive(index))
    changeActive([])

    const isDefaultActive = nextData.find((item) => item.isDefault)

    const changeDefault = handleOnChangeDefault(0, nextData)

    if (onChange) {
      if (!isDefaultActive) {
        changeDefault()
      } else {
        onChange(nextData)
      }
    }
  }

  const handleOnEditVariant: ProductColorItemModalProps['onSubmit'] = (
    variant
  ) => {
    const nextValue = data.map((variantItem, index) => {
      if (active[0] === index) {
        return variant
      }

      return variantItem
    })

    if (onChange) {
      onChange(nextValue)
    }
  }

  const activeData = useMemo(() => {
    return data.find((_, index) => index === active[0])
  }, [data, active[0]])

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Column fullWidth>
        <Row
          fullWidth
          className={classes.header}
          justifyContent="space-between"
        >
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Checkbox checked={checkAll} onChange={handleOnCheckAll} />
          </Row>
          {arrayHasElements(active) ? (
            <Row>
              {active.length === 1 && (
                <Row className={classes.remove} onClick={handleOnOpen(true)}>
                  <Icon src={ICONS.edit} height="24px" width="24px" />
                </Row>
              )}
              <Row className={classes.remove} onClick={handleOnRemove}>
                <Icon src={ICONS.trash} />
              </Row>
            </Row>
          ) : (
            <>
              <Row className={classes.tableColumn} justifyContent="flex-start">
                <Text
                  className={classes.text}
                  color="black"
                  preset="h6"
                  text="Title"
                />
              </Row>
              <Row
                className={classes.bigTableColumn}
                justifyContent="flex-start"
              >
                <Text
                  className={classes.text}
                  color="black"
                  preset="h6"
                  text="Description"
                />
              </Row>
              <Row className={classes.tableColumn} justifyContent="flex-start">
                <Text
                  className={classes.text}
                  color="black"
                  preset="h6"
                  text="Color"
                />
              </Row>
              <Row className={classes.tableColumn} justifyContent="flex-start">
                <Text
                  className={classes.text}
                  color="black"
                  preset="h6"
                  text="Extra Price"
                />
              </Row>
              <Row className={classes.tableColumn} justifyContent="flex-start">
                <Text
                  className={classes.text}
                  color="black"
                  preset="h6"
                  text="Extra Production Days"
                />
              </Row>
              <Row className={classes.tableColumn} justifyContent="flex-end">
                <Text
                  className={classes.text}
                  color="black"
                  preset="h6"
                  text="Default"
                />
              </Row>
            </>
          )}
        </Row>
        {data.map((color, index) => {
          return (
            <ColorTableItem
              key={`color_${index}_${color.color}`}
              {...color}
              checked={getActive(index)}
              onChange={handleOnChange(index)}
              onChangeDefault={handleOnChangeDefault(index)}
            />
          )
        })}
      </Column>
      {openConfirmModal && (
        <ProductColorItemModal
          activeData={activeData}
          colors={colors}
          onSubmit={handleOnEditVariant}
          onClose={handleOnOpen}
        />
      )}
    </Column>
  )
}
