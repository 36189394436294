import React, { FC } from 'react'

import { Row, Text } from '../../../../../components'

import { VendorPreviewDetailsItemProps } from './vendor-preview-details-item.types'

export const VendorPreviewDetailsItem: FC<VendorPreviewDetailsItemProps> = ({
  className = '',
  title,
  titleTx,
  value
}) => {
  return (
    <Row className={className}>
      <Text text={title} tx={titleTx} preset="body" color="inactive" />
      <Text text={value || '-'} preset="h5" color="black" />
    </Row>
  )
}
