import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Column, Row, Button, ButtonLink } from '../../components'
import { MARKETPLACE_LINK } from '../../config'
import { SortVariables, useSort, useTable, useUser } from '../../hooks'
import { useApi } from '../../providers'
import {
  PromotionBanner,
  PromotionBannersSortField,
  PromotionBannersVariables
} from '../../services'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { PromotionBannersFilters } from './promotion-banners-filters'
import {
  PromotionBannersTable,
  PromotionBannersTableProps
} from './promotion-banners-table'
import { useStyle } from './promotion-banners.styles'

const rowCounts = [5, 10, 20, 30]

export const PromotionBannersPage = () => {
  const history = useHistory()
  const classes = useStyle()
  const { menu } = useApi()
  const { user } = useUser()

  const defaultVariables = { first: rowCounts[1] }

  const [promotionBannersVariables, changePromotionBannersVariables] =
    useState<PromotionBannersVariables>({
      first: rowCounts[1]
    })

  const { onSubmit: onRemove } = menu.useRemovePromotionBanner()
  const { onSubmit: onUpdate } = menu.useUpdatePromotionBanner()
  const { data, loading, refetch } = menu.usePromotionBanners(defaultVariables)

  const {
    changeCurrentData,
    Data,
    currentData,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onNextPage,
    onPrevioslyPage,
    onSearch,
    onResetFilters,
    onSubmitFilters,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.promotionBanners,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  const handleOnCreate = () => {
    history.push(CabinetNavigatorPathVendor.CREATE_PROMOTION_BANNER)
  }

  const handleOnRemove = (ids: string[]) => {
    ids.forEach((id) => onRemove({ id }))

    const nextBanners = currentData.filter(({ node }) => {
      const currentItem = ids.find((id) => id === node.id)

      return !currentItem
    })

    changeCurrentData(nextBanners)
  }

  const handleOnUpdate = (publishData: PromotionBanner[]) => {
    publishData.forEach((item) => {
      const newState = {
        startDate: item.startDate,
        isVisible: item.isVisible
      }
      onUpdate(item.id, newState)
    })
    const nextBanners = currentData.map((promotionBanner) => {
      let isVisible = false
      if (
        publishData.find((item) => {
          isVisible = item.isVisible
          return item.id === promotionBanner.node.id
        })
      ) {
        return {
          ...promotionBanner,
          node: { ...promotionBanner.node, isVisible }
        }
      }
      return promotionBanner
    })
    changeCurrentData(nextBanners)
  }

  const handleOnSearch: PromotionBannersTableProps['onSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnSort = (
    sortVariables: SortVariables<PromotionBannersSortField>
  ) => {
    if (refetch) {
      refetch({
        ...sortVariables,
        before: undefined,
        after: undefined,
        last: undefined
      })
      onResetPageState()
    }
    changePromotionBannersVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: promotionBannersVariables,
    onSort: handleOnSort
  })

  const linkUserId = `${MARKETPLACE_LINK}/vendor/${user?.id}`

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="flex-end">
        <Button
          className={classes.button}
          preset="secondaryBorder"
          text="CREATE BANNER"
          textPreset="h5"
          textColor="yellow"
          tx="cabinet.banners.create.button"
          onClick={handleOnCreate}
        />
        <ButtonLink
          className={classes.button}
          link={linkUserId}
          preset="primary"
          text="GO TO THE MARKETPLACE"
          textPreset="h5"
          textColor="white"
          tx="cabinet.banners.go.to.marketplace"
        />
      </Row>
      <PromotionBannersTable
        loading={loading}
        paginationState={paginationState}
        rowCounts={rowCounts}
        className={classes.table}
        data={Data}
        onRemove={handleOnRemove}
        onUpdate={handleOnUpdate}
        onSearch={handleOnSearch}
        onChangePage={onChangePage}
        onPrevioslyPage={onPrevioslyPage}
        onNextPage={onNextPage}
        onChangeRowCount={onChangeRowCount}
        onSort={onSort}
      >
        <PromotionBannersFilters
          className={classes.filterButton}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
        />
      </PromotionBannersTable>
    </Column>
  )
}
