import { TypePolicies, useQuery } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

import { VENDORS } from './vendor.graphql'
import {
  VendorsApi,
  VendorsList,
  VendorsListData,
  VendorsListVariables
} from './vendor.types'

export const cacheVendorConfig: TypePolicies = {
  Query: {
    fields: {
      vendors: relayStylePagination()
    }
  }
}

export const vendorService = (): VendorsApi => {
  const useVendorListData = (variables: VendorsListVariables): VendorsList => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      VendorsListData,
      VendorsListVariables
    >(VENDORS, { variables })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  return {
    useVendorListData
  }
}
