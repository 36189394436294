import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { CheckboxStyleProps } from './checkbox.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    height: 24,
    width: 24
  },
  innerContainer: {
    background: theme.colors.white,
    border: ({ active }: CheckboxStyleProps) =>
      active ? 'none' : `2px solid ${theme.colors.black}`,
    borderRadius: 4,
    height: 18,
    width: 18
  }
}))
