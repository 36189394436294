import { Currency } from '../../services'

export type LinkDescription = {
  href: string
  rel: string
  method?: string
}

export type Intent = 'CAPTURE' | 'AUTHORIZE'

export enum Status {
  COMPLETED = 'COMPLETED',
  SAVED = 'SAVED',
  APPROVED = 'APPROVED',
  VOIDED = 'VOIDED',
  PAYER_ACTION_REQUIRED = 'PAYER_ACTION_REQUIRED'
}

export enum Label {
  PAYPAL = 'paypal',
  CHECKOUT = 'checkout',
  BUYNOW = 'buynow',
  PAY = 'pay',
  INSTALLMENT = 'installment',
  SUBSCRIBE = 'subscribe',
  DONATE = 'donate'
}

export enum Shape {
  RECT = 'rect',
  PILL = 'pill'
}

export enum Layout {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export enum Color {
  GOLD = 'gold',
  BLUE = 'blue',
  SILVER = 'silver',
  WHITE = 'white',
  BLACK = 'black'
}

type Authorization = Record<string, unknown>
type Captures = Record<string, unknown>
type Refunds = Record<string, unknown>

export enum UserAction {
  CONTINUE = 'CONTINUE',
  PAY_NOW = 'PAY_NOW'
}

export enum LandingPage {
  LOGIN = 'LOGIN',
  BILLING = 'BILLING',
  NO_PREFERENCE = 'NO_PREFERENCE'
}

export enum ShippingPreference {
  GET_FROM_FILE = 'GET_FROM_FILE',
  NO_SHIPPING = 'NO_SHIPPING',
  SET_PROVIDED_ADDRESS = 'SET_PROVIDED_ADDRESS'
}

export type DisbursementMode = 'INSTANT' | 'DELAYED'

export enum Category {
  DIGITAL_GOODS = 'DIGITAL_GOODS',
  PHYSICAL_GOODS = 'PHYSICAL_GOODS',
  DONATION = 'DONATION'
}

export type Address = {
  address_line_1: string
  address_line_2: string
  admin_area_2: string
  admin_area_1: string
  postal_code: string
  country_code: string
}

export type ShippingInfo = {
  name: Partial<{
    full_name: string
  }>
  email_address: string
  phone_number: {
    national_number: string
  }

  type: string

  options: any[]
  address: Address
}

export interface Amount {
  currency_code?: string
  value: string
}

export type PurchaseItem = {
  name: string
  quantity: string
  unit_amount: Amount
  tax?: Amount
  description?: string
  sku?: string
  category?: 'DIGITAL_GOODS' | 'PHYSICAL_GOODS' | 'DONATION'
}

export type PlatformFee = {
  amount: Amount
  payee?: Payee
}

export type PaymentInstruction = {
  platform_fees: PlatformFee[]
  disbursement_mode: DisbursementMode
}

export type Payee = {
  merchant_id?: string
  email_address?: string
}

export type Payer = {
  name: {
    given_name: string
    surname: string
  }
  email_address: string
  payer_id: string
  phone: {
    phone_type?: string
    phone_number: {
      national_number: string
    }
  }
  birth_date: string
  tax_info: {
    tax_id: string
    tax_id_type: string
  }
  address: Address
  tenant: string
}

export type Payments = {
  authorizations?: Authorization[]
  captures?: Captures[]
  refunds?: Refunds[]
}

export interface AmountWithRequiredCurrencyCode {
  currency_code: string
  value: string
}

export interface AmountWithBreakdown extends Amount {
  breakdown?: {
    item_total?: AmountWithRequiredCurrencyCode
    shipping?: AmountWithRequiredCurrencyCode
    handling?: AmountWithRequiredCurrencyCode
    tax_total?: AmountWithRequiredCurrencyCode
    insurance?: AmountWithRequiredCurrencyCode
    shipping_discount?: AmountWithRequiredCurrencyCode
    discount?: AmountWithRequiredCurrencyCode
  }
}

export type PurchaseUnit = {
  amount: AmountWithBreakdown
  reference_id?: string
  description?: string
  custom_id?: string
  invoice_id?: string
  soft_descriptor?: string
  payee?: Payee
  payment_instruction?: PaymentInstruction
  shipping?: ShippingInfo
  items?: PurchaseItem[]
  payments?: Payments
}

export type OrderApplicationContext = {
  brand_name?: string
  locale?: string
  landing_page?: LandingPage
  shipping_preference?: ShippingPreference
  user_action?: UserAction
  payment_method?: Record<string, unknown>
  return_url?: string
  cancel_url?: string
  stored_payment_source?: Record<string, unknown>
}

export type CreateOrderBody = {
  purchase_units: PurchaseUnit[]
  payer?: Payer
  application_context?: OrderApplicationContext
}

export type PaypalButtonsStyle = {
  color?: Color
  height?: number
  label?: Label

  layout?: Layout
  shape?: Shape
  tagline?: boolean
}

export type CreateOrderActions = {
  order: {
    /** Used to create an order for client-side integrations. Accepts the same options as the request body of the [/v2/checkout/orders api](https://developer.paypal.com/docs/api/orders/v2/#orders-create-request-body). */
    create: (options: CreateOrderBody) => Promise<string>
  }
}

export type OnApproveData = {
  billingToken?: string | null
  facilitatorAccessToken: string
  orderID: string
  payerID?: string | null
  paymentID?: string | null
  subscriptionID?: string | null
  authCode?: string | null
}

export type OrderResponseBody = {
  create_time: string
  update_time: string
  id: string
  intent: Intent
  payer: Payer
  purchase_units: PurchaseUnit[]
  status:
    | 'COMPLETED'
    | 'SAVED'
    | 'APPROVED'
    | 'VOIDED'
    | 'COMPLETED'
    | 'PAYER_ACTION_REQUIRED'
  links: LinkDescription[]
}

export type OnApproveActions = {
  order?: {
    capture: () => Promise<OrderResponseBody>
    authorize: () => Promise<OrderResponseBody>
  }
  subscription?: {
    get: () => Promise<any>
    activate: () => Promise<void>
  }
  redirect: (redirectURL: string) => void
  restart: () => void
}

export type PaypalButtonsViewProps = {
  orderNumber?: string
  orderId?: string
  amount: string
  currency?: Currency
}
