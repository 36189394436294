import React, { FC, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'

import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { useStyle } from './breadcrumbs.styles'
import { BackBreadcrumbsProps } from './breadcrumbs.types'

export const BackBreadcrumbs: FC<BackBreadcrumbsProps> = ({
  className = '',
  text,
  tx,
  path,
  onClick,
  ...props
}) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnClick = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    if (path) {
      history.push(path)
    } else {
      history.goBack()
    }

    if (onClick) {
      onClick(event)
    }
  }

  return (
    <Row
      fullWidth
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
      onClick={handleOnClick}
    >
      <Icon src={ICONS.trendingFlat} />
      <Text
        className={classes.text}
        color="inactive"
        preset="body"
        text={text}
        tx={tx}
        {...props}
      />
    </Row>
  )
}
