import { TextProps } from '../../../components'
import { Color } from '../../../components/Theme'

export const getDotColor = (isPublished: boolean): Color => {
  if (isPublished) {
    return 'yellow'
  }

  return 'shadow'
}

export const getStatusLabel = (isPublished: boolean): TextProps => {
  if (isPublished) {
    return {
      text: 'Active',
      tx: 'item.active'
    }
  }

  return {
    text: 'Inactive',
    tx: 'item.inactive'
  }
}
