import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CabinetNavigatorPathAdmin } from '..'
import { useNavigator } from '../../hooks'

import { useApi } from '../../providers'
import { EditBannerInput } from '../../services/menu'
import { BannerForm } from '../banner-form'

import { EditBannerParams } from './edit-banner.types'

export const EditBannerPage = () => {
  const { id } = useParams<EditBannerParams>()
  const { menu } = useApi()
  const { data: banners } = menu.useBanners({ first: 100 })
  const { response, onSubmit } = menu.useUpdateBanner()
  const navigate = useNavigator()

  useEffect(() => {
    if (response.data && response.data.bannerUpdate.banner) {
      navigate(CabinetNavigatorPathAdmin.BANNERS)
    }
  }, [response.data])

  const CurrentBanner = useMemo(() => {
    if (id && banners) {
      return banners.allBanners.edges.find((banner) => banner.node.id === id)
    }

    return undefined
  }, [id, banners])

  const handleOnSubmit = (nextBanner: EditBannerInput) => {
    onSubmit(id, nextBanner)
  }

  if (!CurrentBanner) {
    return <></>
  }

  return (
    <BannerForm
      banner={CurrentBanner.node}
      title="Update Banner"
      titleTx="banner.update"
      onSumbit={handleOnSubmit}
    />
  )
}
