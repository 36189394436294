import React, { FC } from 'react'

import { Column, Icon, ICONS, Section, TextField } from '../../../components'

import { useStyle } from './default-price-section.styles'
import { DefaultPriceProps } from './default-price-section.types'

export const DefaultPriceSection: FC<DefaultPriceProps> = ({
  priceFrom,
  instruction,
  error,
  onChangeInputValue
}) => {
  const classes = useStyle()
  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />

  const isValue = priceFrom === undefined || !priceFrom.toString.length

  const isValueError = isValue && error

  return (
    <Section
      isInfo
      className={classes.section}
      infoText={instruction}
      title="Default Price"
      titleTx="item.form.price.title"
    >
      <Column className={classes.sectionAdditionContainer}>
        <TextField
          isError={isValueError}
          defaultValue={priceFrom}
          label="Price From"
          labelTx="item.form.price.from"
          type="number"
          rightElement={dollarIcon}
          onChange={onChangeInputValue('defaultPrice')}
        />
      </Column>
    </Section>
  )
}
