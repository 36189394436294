import { MutationResult } from '@apollo/client'

import {
  DateRangeInput,
  FetchMore,
  ListVariables,
  ListVariablesWithoutFilter,
  PaginationList,
  SortBy
} from '../api.types'
import { CollectionWithProductsCount } from '../collection'
import { BannerError, PromotionBanner } from '../menu'
import { Product, ProductErrorCode } from '../product'

export enum AdvertisingType {
  TOP_PRODUCT = 'TOP_PRODUCT',
  BANNER = 'BANNER'
}

export enum AdvertisingSortField {
  NAME = 'NAME',
  STATUS = 'STATUS',
  TYPE = 'TYPE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  TOTAL_PRICE = 'TOTAL_PRICE'
}

export type AdvertisingErrors = {
  field: String
  message: String
  code: ProductErrorCode
}

export interface Advertising {
  id: string
  name: string
  type: AdvertisingType
  startDate: string
  endDate: string
  totalPrice: number
  pricePerDay?: number
  product?: Product
  banner?: PromotionBanner
  collection?: CollectionWithProductsCount
  isVisible: boolean
}

export interface AdvertisingCreateInput {
  name?: string
  type?: AdvertisingType
  startDate?: string
  endDate?: string
  product?: string
  collection?: string
  banner?: string
  totalPrice?: number
  pricePerDay?: number
  isVisible: boolean
}

export type AdvertisingFilterInput = {
  startDate?: DateRangeInput
  endDate?: DateRangeInput
  search?: string
  type?: AdvertisingType[]
  isVisible?: boolean
}

export type AdvertisingLimitsData = {
  allowedShowingAmount: number
  changesTime: number
  topProductPricePerDay: number
  bannerPricePerDay: number
  id: string
}

export type AdvertisingFilterKeys = keyof AdvertisingFilterInput

export type AdvertisingSortingInput = SortBy<AdvertisingSortField>

export interface AdvertisingListVariables
  extends ListVariables<AdvertisingFilterInput> {
  sortBy?: AdvertisingSortingInput
}

export interface AdvertisingLimitsVariables
  extends ListVariablesWithoutFilter {}

export interface EditAdvertisingInput extends AdvertisingCreateInput {}

export type AdvertisingRemoveVariables = {
  id: string
}

export interface CreateAdvertisingRequest {
  input: AdvertisingCreateInput
}

export interface UpdateAdvertisingRequest {
  id: string
  input: EditAdvertisingInput
}

export type AdvertisingByIdVariables = {
  id: string
}

export interface AdvertisingList {
  advertisingList: PaginationList<Advertising>
}

export interface AdvertisingLimits {
  advertisingLimits: PaginationList<AdvertisingLimitsData>
}

export interface CreateAdvertisingResults {
  advertisingCreate: {
    advertisingErrors: AdvertisingErrors[]
    advertising: Advertising
  }
}

export interface UpdateAdvertisingResults {
  advertisingUpdate: {
    bannerErrors: BannerError[]
    advertising: Advertising
  }
}

export type GetAdvertisingRemoveResults = {
  advertisingDelete: {
    bannerErrors: BannerError[]
    advertising: Advertising
  }
}

export type GetAdvertising = {
  advertising: Advertising
}

export type GetAdvertisingList = {
  data: AdvertisingList | null
  loading: boolean
  refetch?: (variables: Partial<AdvertisingListVariables>) => void
  fetchMore?: FetchMore<AdvertisingList, AdvertisingListVariables>
}

export type GetAdvertisingLimits = {
  data: AdvertisingLimits | null
  loading: boolean
  refetch?: (variables: Partial<AdvertisingLimitsVariables>) => void
}

export interface CreateAdvertising {
  onSubmit: (advertising: AdvertisingCreateInput) => void
  response: MutationResult<CreateAdvertisingResults>
}

export interface UpdateAdvertising {
  onSubmit: (id: string, advertising: EditAdvertisingInput) => void
  response: MutationResult<UpdateAdvertisingResults>
}

export type RemoveAdvertising = {
  onSubmit: (variables: AdvertisingRemoveVariables) => void
  response: MutationResult<GetAdvertisingRemoveResults>
}

export type GetAdvertisingByIdRequest = {
  data: GetAdvertising | null
  loading: boolean
}

export interface AdvertisingApi {
  useAdvertisingList: (
    variables: AdvertisingListVariables
  ) => GetAdvertisingList
  useAdvertisingById: (
    variables: AdvertisingByIdVariables
  ) => GetAdvertisingByIdRequest
  useCreateAdvertising: () => CreateAdvertising
  useUpdateAdvertising: () => UpdateAdvertising
  useRemoveAdvertising: () => RemoveAdvertising
  useAdvetrisingLimits: (
    variables: AdvertisingLimitsVariables
  ) => GetAdvertisingLimits
}
