import React, { FC, MouseEventHandler, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../../../components'
import { useAuth } from '../../../hooks'
import { GroupConversationOptions, useApi, useChat } from '../../../providers'
import { getItemsFromEdges } from '../../../services'

import { CabinetNavigatorPathAdmin } from '../../cabinet-navigator'

import { ContactAdministratorProps } from './contact-administrator.types'

export const ContactAdministrator: FC<ContactAdministratorProps> = ({
  className = '',
  onClick
}) => {
  const { user } = useAuth()
  const { onCreateAdminConversation } = useChat()

  const history = useHistory()
  const { admin: adminApi } = useApi()
  const { data } = adminApi.useAdmins({ first: 100 })

  const Admins = useMemo(() => {
    if (data) {
      return getItemsFromEdges(data.admins)
    }

    return []
  }, [data])

  const handleOnClick: MouseEventHandler<HTMLElement> = (event) => {
    if (onCreateAdminConversation) {
      const conversationOptions: GroupConversationOptions = {
        users: Admins,
        chatAttributes: {
          subject: 'Admins',
          photoUrl:
            'https://imgv3.fotor.com/images/videoImage/apply-photo-effects-on-Fotor-ru.png',
          welcomeMessages: ['Welcome to the Administrator Chat']
        }
      }

      onCreateAdminConversation(conversationOptions)

      history.push(CabinetNavigatorPathAdmin.COMMUNICATION)
    }

    if (onClick) {
      onClick(event)
    }
  }

  if (user?.isStaff) {
    return <></>
  }

  return (
    <Button
      className={className}
      color="darkBlue"
      preset="secondaryBorderLong"
      textColor="darkBlue"
      textPreset="maxButton"
      text="Contact Administrator"
      tx="contactAdmin"
      onClick={handleOnClick}
    />
  )
}
