import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    overflow: 'hidden'
  },
  title: {
    marginRight: 8
  },
  textContainer: {
    overflow: 'hidden'
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})
