import { CollectionWithProductsCount } from '../../../services'

export const clearInputForCollectionUpdate = (
  prevItem: CollectionWithProductsCount
) => {
  return {
    ...prevItem
  }
}

export const activateCollectionItem =
  (state: boolean) => (prevItem: CollectionWithProductsCount) => {
    const item = {
      ...prevItem,
      isPublished: state
    }

    return clearInputForCollectionUpdate(item)
  }
