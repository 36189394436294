import React, { FC } from 'react'
import { Column, Text } from '../../../../components'
import { useStyle } from './change-email-sent.styles'
import background from '../../../../assets/images/background-modal-success.png'

export const ChangeEmailSent: FC = () => {
  const classes = useStyle()

  return (
    <Column fullWidth className={classes.bottomContainer}>
      <img width="100%" src={background} />
      <Text
        className={classes.description}
        color="black"
        preset="h4"
        text="Your Email change confirmation is sent to your new email."
        tx="change.email.modal.secondaryDescription"
      />
      <Text
        className={classes.secondaryDesctiption}
        color="inactive"
        preset="body"
        text="Please confirm the change of email by clicking on the link in the letter."
        tx="change.email.modal.text"
      />
    </Column>
  )
}
