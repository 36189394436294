import React, { FC } from 'react'

import { arrayHasElements, Section } from '../../../components'
import { ProductOptionType, ProductOptionVariantInput } from '../../../services'

import { EmptySection } from '../../../pages/item-form/empty-section'
import {
  OfferAdditionalOptionTable,
  OptionItemModal
} from '../../../pages/views'
import { useStyle } from '../../../pages/offer-form/offer-form.styles'

import { OfferAdditionalOptionSectionProps } from './offer-additional-option-section.types'

export const OfferAdditionalOptionSection: FC<OfferAdditionalOptionSectionProps> =
  ({ value, instruction, onChangeTable, onSubmit }) => {
    const classes = useStyle()

    const handleOnSubmit = (option: ProductOptionVariantInput) => {
      const hasCurrentTitle = value.find(
        (currentOption) => currentOption.title === option.title
      )

      if (hasCurrentTitle) {
        return
      }

      if (onSubmit) {
        onSubmit([...value, option])
      }
    }

    const optionItemModal = (
      <OptionItemModal onSubmit={handleOnSubmit} title="Additional Option" />
    )

    return (
      <Section
        isInfo
        className={classes.sectionItem}
        titleContainerClassName={classes.titleContainerItem}
        rightButton={optionItemModal}
        infoText={instruction}
        title="Additional Options"
        titleTx="offer.form.additionalOption.title"
        titlePreset="h4"
      >
        {value && arrayHasElements(value) ? (
          <OfferAdditionalOptionTable
            type={ProductOptionType.CHECKBOX}
            data={value}
            title="Additional Options"
            titleTx="offer.form.additionalOption.title"
            onChange={onChangeTable}
          />
        ) : (
          <EmptySection
            className={classes.sectionAdditionWithText}
            text="Add at least one option."
            tx="item.form.no.variant"
          />
        )}
      </Section>
    )
  }
