import { Product } from '../../../services'

export const clearInputForProductUpdate = (prevItem: Product) => {
  return {
    ...prevItem
  }
}

export const activateProductItem = (state: boolean) => (prevItem: Product) => {
  const item = {
    ...prevItem,
    visibleInListings: state
  }

  return clearInputForProductUpdate(item)
}
