import { TextProps } from '../../../components'
import { Color } from '../../../components/Theme'
import { Pricing, ProductStatus } from '../../../services'

export const getDotColor = (isPublished: boolean): Color => {
  if (isPublished) {
    return 'yellow'
  }

  return 'shadow'
}

export const getStatusLabel = (isPublished: boolean): TextProps => {
  if (isPublished) {
    return {
      text: 'Active',
      tx: 'item.active'
    }
  }

  return {
    text: 'Inactive',
    tx: 'item.inactive'
  }
}

export const getPrice = (pricing: Pricing): string => {
  const { start } = pricing.priceRange

  return `${start.gross.amount} ${start.gross.currency}`
}

export const getColorByProductStatus = (status: ProductStatus): Color => {
  switch (status) {
    case ProductStatus.VISIBLE:
      return 'yellow'
    case ProductStatus.INVISIBLE:
      return 'inactiveSecondary'
    case ProductStatus.DRAFT:
      return 'darkBlue'
    default:
      return 'error'
  }
}

export const getMessageByProductStatus = (status: ProductStatus): TextProps => {
  switch (status) {
    case ProductStatus.VISIBLE:
      return {
        text: 'Active',
        tx: 'item.table.status.active'
      }
    case ProductStatus.INVISIBLE:
      return {
        text: 'Inactive',
        tx: 'item.table.status.inactive'
      }
    case ProductStatus.DRAFT:
      return {
        text: 'Draft',
        tx: 'item.table.status.draft'
      }
    default:
      return {
        text: 'Unknown',
        tx: 'offerStatus.unknown'
      }
  }
}
