import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    marginTop: 16,
    position: 'relative',
    width: '100%',
    padding: 16,
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  activeContainer: {
    border: `1px solid ${theme.colors.blue}`
  },
  active: {
    position: 'absolute',
    width: 85,
    height: 32,
    top: 0,
    right: '16px',
    background: theme.colors.blue
  }
}))
