import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { concatClassNames } from '../../pages/views'

import { Column } from '../column'
import { InfoTooltip } from '../info-tooltip'
import { Row } from '../row'
import { Text } from '../text'
import { LightTheme } from '../Theme'

import { useStyle } from './section.styles'
import { SectionProps } from './section.types'

export const Section: FC<SectionProps> = ({
  className = '',
  titleContainerClassName = '',
  children,
  description,
  descriptionTx,
  rightIcon,
  rightButton,
  isInfo,
  infoText,
  title,
  titleTx,
  isBottom,
  titlePreset = 'h3'
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  return (
    <Column
      fullWidth
      className={concatClassNames(className, classes.container)}
      alignItems="flex-start"
    >
      {title && (
        <Row
          className={concatClassNames(
            titleContainerClassName,
            classes.titleContainer
          )}
          justifyContent="space-between"
        >
          <Row alignItems="center" justifyContent="flex-start">
            <Text
              color="black"
              preset={titlePreset}
              text={title}
              tx={titleTx}
            />
            {isInfo && (
              <Row className={classes.tooltipContainer}>
                <InfoTooltip isBottom={isBottom} text={infoText} />
              </Row>
            )}
            {description && (
              <Text
                className={classes.description}
                color="inactive"
                text={description}
                tx={descriptionTx}
              />
            )}
          </Row>
          <Row>
            {rightIcon}
            {rightButton}
          </Row>
        </Row>
      )}
      {children}
    </Column>
  )
}
