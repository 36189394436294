import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  description: {
    marginTop: 8,
    maxWidth: 380,
    textAlign: 'center'
  },
  carouselContainer: {
    marginTop: 12,
    maxWidth: 1434
  },
  listRow: {
    columnGap: 16,
    rowGap: 16
  }
})
