import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { useNavigator, useUser } from '../../hooks'
import { useApi } from '../../providers'
import { AdvertisingCreateInput } from '../../services/advertising'

import {
  CabinetNavigatorPathVendor,
  PromotionsTabNavigation
} from '../cabinet-navigator'
import { AdvertisingForm } from '../advertising-form'

import { EditAdvertisingParams } from './edit-advertising.types'

export const EditAdvertisingPage = () => {
  const { id } = useParams<EditAdvertisingParams>()
  const { advertising } = useApi()
  const { user } = useUser()
  const { data } = advertising.useAdvertisingById({
    id
  })
  const { response, onSubmit } = advertising.useUpdateAdvertising()
  const { onSubmit: onRemove } = advertising.useRemoveAdvertising()
  const navigate = useNavigator()

  useEffect(() => {
    if (response.data && response.data.advertisingUpdate.advertising) {
      navigate(
        `${CabinetNavigatorPathVendor.PROMOTIONS}${PromotionsTabNavigation.ADVERTISEMENTS}`
      )
    }
  }, [response.data])

  const handleOnSubmit = (nextAdvertising: AdvertisingCreateInput) => {
    onSubmit(id, nextAdvertising)
  }

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove({ id })
    }
  }

  const Item = useMemo((): Partial<AdvertisingCreateInput> => {
    if (data) {
      const {
        name,
        type,
        startDate,
        endDate,
        product,
        collection,
        banner,
        totalPrice,
        pricePerDay,
        isVisible
      } = data.advertising

      return {
        name,
        type,
        startDate,
        endDate,
        product: product?.id,
        collection: collection?.id,
        banner: banner?.id,
        totalPrice,
        pricePerDay,
        isVisible
      }
    }
    return {}
  }, [data])

  if (!data || !Item) {
    return <></>
  }

  return (
    <AdvertisingForm
      key={`advertising_${id}_${uuid()}`}
      advertisingData={Item}
      product={data.advertising.product}
      collection={data.advertising.collection}
      banner={data.advertising.banner}
      title={data.advertising.name}
      balance={user?.vendorBalance}
      id={user?.id}
      onSumbit={handleOnSubmit}
      onRemove={handleOnRemove}
    />
  )
}
