import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { useStyle } from './top-item.styles'
import { TopItemProps } from './top-item.types'
import { LightTheme } from '../../../components/Theme'
import { Column, Row, Text, getCurrencySymbol } from '../../../components'
import { Currency } from '../../../services'

export const TopItem: FC<TopItemProps> = ({
  image,
  title,
  subtitle,
  price,
  orderedCount
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Row fullWidth justifyContent="flex-start" className={classes.topItemsItem}>
      <Column>
        <img height={92} width={92} src={image} />
      </Column>
      <Column
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.topItemsItemInfo}
      >
        <Row justifyContent="normal">
          <Text
            className={classes.title}
            color="black"
            preset="h5"
            text={title}
          />
        </Row>
        <Text color="black" preset="body" text={subtitle} />
        <Text
          color="black"
          preset="body"
          text={`${getCurrencySymbol(Currency.USD)}${price}`}
        />
      </Column>
      <Column>
        <Row justifyContent="center" alignItems="center">
          <Text color="yellow" preset="h5" text={String(orderedCount)} />
          <Text
            color="inactive"
            preset="body"
            text="Ordered"
            className={classes.orderedText}
          />
        </Row>
      </Column>
    </Row>
  )
}
