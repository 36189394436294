import { gql } from '@apollo/client'

import { ADMIN_USER_FRAGMENT } from '../fragments'

export const ADMINS_QUERY = gql`
  ${ADMIN_USER_FRAGMENT}
  query Admins($first: Int, $last: Int, $after: String, $before: String) {
    admins(first: $first, last: $last, after: $after, before: $before) {
      totalCount
      edges {
        node {
          ...AdminUserFragment
        }
      }
    }
  }
`
