import {
  OfferCreateInput,
  OfferItemInput,
  ProductOptionType,
  ProductOptionVariantInput
} from '../../services'
import {
  getFilterByDefaultWithProp,
  getFindByDefaultWithProp
} from '../../utils'

export const getAllProductOptionsPrice = (
  radioOptions?: (ProductOptionVariantInput | undefined)[]
) => {
  return radioOptions?.reduce((acc, optionProductItem) => {
    const extraPrice = Number(optionProductItem?.extraPrice) || 0

    return acc + extraPrice
  }, 0)
}

export const getDefaultOfferItemPrice = (
  productPrice: number,
  colorPrice: number,
  materialPrice: number,
  allProductOptionsPrice: number,
  allOptionsPrice: number
) =>
  productPrice +
  materialPrice +
  colorPrice +
  allProductOptionsPrice +
  allOptionsPrice

export const getDefaultProductOption = (data: OfferCreateInput) => {
  if (!data.productOptions) {
    return []
  }

  return data.productOptions
    .map((optionItem) => {
      if (!optionItem.variants) {
        return undefined
      }

      if (optionItem.type === ProductOptionType.RADIOBUTTON) {
        const defaultOptionVariant = getFindByDefaultWithProp(
          optionItem.variants
        )

        return defaultOptionVariant
      }
      if (optionItem.type === ProductOptionType.CHECKBOX) {
        const defaultOptionVariant = getFilterByDefaultWithProp(
          optionItem.variants
        )

        return defaultOptionVariant
      }

      return undefined
    })
    .flat()
}

export const getDefaultOfferItemOption = (data: OfferItemInput) => {
  if (!data.productOptions) {
    return []
  }

  return data.productOptions
    .map((optionItem) => {
      if (!optionItem.variants) {
        return undefined
      }

      if (optionItem.type === ProductOptionType.RADIOBUTTON) {
        const defaultOptionVariant = getFindByDefaultWithProp(
          optionItem.variants
        )

        return defaultOptionVariant
      }
      if (optionItem.type === ProductOptionType.CHECKBOX) {
        const defaultOptionVariant = getFilterByDefaultWithProp(
          optionItem.variants
        )

        return defaultOptionVariant
      }

      return undefined
    })
    .flat()
}

export const getOptionPrice = (data: OfferCreateInput) => {
  return data.options?.reduce((acc, optionItem) => {
    const extraPrice = Number(optionItem.extraPrice) || 0

    return acc + extraPrice
  }, 0)
}

export const getOfferItemOptionPrice = (data: OfferItemInput) => {
  return data.options?.reduce((acc, optionItem) => {
    const extraPrice = Number(optionItem.extraPrice) || 0

    return acc + extraPrice
  }, 0)
}

export const getPriceWithQuantity = (
  price?: number | null,
  quantity?: number
) => {
  if (price && quantity) {
    return price * quantity
  }
  return 0
}

export const getTotalOfferQuantity = (items: OfferItemInput[]) => {
  return items.reduce((acc, currentItem) => {
    const fullQuantity = acc + currentItem.quantity

    return fullQuantity
  }, 0)
}

export const getTotalOfferItemsPrice = (items: OfferItemInput[]) => {
  return items.reduce((acc, currentItem) => {
    const fullPrice = acc + currentItem.totalItemPrice

    return fullPrice
  }, 0)
}

export const getTotalOfferPrice = (items: number[]) => {
  return items.reduce((acc, currentItem) => {
    const fullPrice = acc + currentItem

    return fullPrice
  }, 0)
}
