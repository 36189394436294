import { DependencyList, useEffect } from 'react'

import { ToastifyOptionsProps, useToastify } from '../providers'

export interface UseMessageOnUpdateDataProps extends ToastifyOptionsProps {
  deps: DependencyList
  condition: any
}

export const useMessageOnUpdateData = ({
  condition,
  deps,
  text = 'Successful, changes have been saved',
  tx = 'successfull.message'
}: UseMessageOnUpdateDataProps) => {
  const { open } = useToastify()

  useEffect(() => {
    if (condition) {
      open({
        text,
        tx
      })
    }
  }, deps)
}
