import { useQuery } from '@apollo/client'

import { MATERIALS_QUERY } from './material.graphql'
import {
  GetMaterials,
  GetMaterialsData,
  MaterialApi,
  MaterialVariables
} from './material.types'

// our "constructor"
export const materialService = (): MaterialApi => {
  const useMaterials = (variables: MaterialVariables): GetMaterials => {
    const { data, loading, error, refetch } = useQuery<
      GetMaterialsData,
      MaterialVariables
    >(MATERIALS_QUERY, {
      variables
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useMaterials
  }
}
