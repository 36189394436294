import { gql } from '@apollo/client'

export const COUNTRIES = gql`
  query Countries {
    countries(last: 300) {
      edges {
        cursor
        node {
          id
          name
          code
        }
      }
      totalCount
    }
  }
`

export const COUNTRY_STATES_BY_ID = gql`
  query CountryStates($filter: CountryStateFilterInput!) {
    countryStates(first: 300, filter: $filter) {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`
