import React, { FC } from 'react'

import { Text } from '../text'
import { MenuSlider } from '../menu-slider'
import { CheckboxListByAlphabet } from '../checkbox-list-by-alphabet'

import { FilterListByAlphabetProps } from './filter-list-by-alphabet.types'

export const FilterListByAlphabet: FC<FilterListByAlphabetProps> = ({
  className = '',
  defaultOpen,
  title,
  titleTx,
  onOpen,
  ...checkboxProps
}) => {
  const header = <Text preset="h5" text={title} tx={titleTx} />
  return (
    <MenuSlider
      className={className}
      defaultOpen={defaultOpen}
      header={header}
      onOpen={onOpen}
    >
      <CheckboxListByAlphabet {...checkboxProps} />
    </MenuSlider>
  )
}
