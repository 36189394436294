import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white
  },
  icon: {
    '&:first-child': {
      marginRight: 16
    }
  }
}))
