import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Column,
  ICONS,
  Row,
  SortItem,
  TablePagginationBar,
  Text,
  TextField
} from '../../../components'
import { FeedbackOrderField, OrderDirection } from '../../../services'

import {
  CabinetNavigatorPathVendor,
  ActivityTabNavigation
} from '../../cabinet-navigator'

import { FeedbackTableItem } from '../feedbacks-activity-table-item'

import { useStyle } from './feedbacks-activity-table.styles'
import { FeedbackActivityTableProps } from './feedbacks-activity-table.types'

export const FeedbackActivityTable: FC<FeedbackActivityTableProps> = ({
  className = '',
  children,
  data,
  rowCounts = [],
  paginationState,
  onNextPage,
  onPrevioslyPage,
  onChangeSearch,
  onChangeRowCount,
  onChangePage,
  onSort
}) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnClickItem = () => {
    history.push(
      `${CabinetNavigatorPathVendor.ACTIVITY}${ActivityTabNavigation.ACTIVITY_FEEDBACK}`
    )
  }

  const handleOnSort =
    (feedbackOrderField: FeedbackOrderField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(feedbackOrderField, direction)
      }
    }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
        {children}
      </Row>
      <Column fullWidth>
        <Row
          fullWidth
          className={classes.header}
          justifyContent="space-between"
        >
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Feedback Object"
              nameTx="feedback.table.feedback.object"
              onClick={handleOnSort(FeedbackOrderField.FEEDBACK_OBJECT)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Feedback Type"
              tx="feedback.table.feedback.type"
            />
          </Row>
          <Row
            className={`${classes.tableColumn} ${classes.minTableColumn}`}
            justifyContent="flex-start"
          >
            <SortItem
              name="Rating"
              nameTx="feedback.table.rating"
              onClick={handleOnSort(FeedbackOrderField.RATING)}
            />
          </Row>
          <Row
            className={`${classes.tableColumn} ${classes.maxTableColumn}`}
            justifyContent="flex-start"
          >
            <Text
              color="black"
              preset="h6"
              text="Comment"
              tx="feedback.table.comment"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Name of the Customer"
              tx="feedback.table.name.of.the.customer"
            />
          </Row>
          <Row
            className={`${classes.tableColumn} ${classes.minTableColumn}`}
            justifyContent="flex-start"
          >
            <SortItem
              name="Date"
              nameTx="feedback.table.date"
              onClick={handleOnSort(FeedbackOrderField.DATE)}
            />
          </Row>
        </Row>

        {data.map((feedback) => (
          <FeedbackTableItem
            key={`feedback_${feedback.id}`}
            {...feedback}
            onClick={handleOnClickItem}
          />
        ))}
      </Column>
      {paginationState && (
        <TablePagginationBar
          rowCounts={rowCounts}
          paginationState={paginationState}
          onPrevioslyPage={onPrevioslyPage}
          onNextPage={onNextPage}
          onChangeRowCount={onChangeRowCount}
          onChangePage={onChangePage}
        />
      )}
    </Column>
  )
}
