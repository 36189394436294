import React, { FC } from 'react'

import { Text } from '../../text'
import { Row } from '../../row'

import { CalendarSliderHeaderProps } from './calendar-slider-header.types'

export const CalendarSliderHeader: FC<CalendarSliderHeaderProps> = ({
  text,
  tx
}) => {
  return (
    <Row>
      <Text preset="h5" text={text} tx={tx} />
    </Row>
  )
}
