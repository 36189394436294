import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  description: {
    marginTop: 56
  },
  field: {
    marginTop: 24
  }
})
