import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Edge,
  Offer,
  OfferCreateInput,
  OfferOrderField,
  OfferStatus,
  OffersVariables,
  OrderDirection
} from '../../services'
import { useApi, useToastify } from '../../providers'
import { arrayHasElements, Column, Row, Text } from '../../components'
import { SortVariables, useSort, useTable, useUser } from '../../hooks'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { OffersTable, OffersTableProps } from './offers-table'
import { useStyle } from './offers.styles'

const rowCounts = [5, 10, 20, 30]

export const OffersPage = () => {
  const history = useHistory()
  const classes = useStyle()
  const { onUpdateOfferTotalCount } = useUser()
  const { offer } = useApi()
  const STATUS = [
    OfferStatus.NEW,
    OfferStatus.ACCEPTED,
    OfferStatus.ACCEPTED_BACK_FROM_CART,
    OfferStatus.DRAFT,
    OfferStatus.DECLINED_BY_CUSTOMER,
    OfferStatus.DELETED_BY_CUSTOMER,
    OfferStatus.START_OFFER
  ]
  const defaultVariables = {
    first: rowCounts[1],
    filter: {
      status: STATUS
    }
  }

  const defaultVariablesWithSort = {
    ...defaultVariables,
    sortBy: { direction: OrderDirection.DESC, field: OfferOrderField.NUMBER }
  }

  const [offerVariables, changeOfferVariables] = useState<OffersVariables>({
    ...defaultVariables
  })
  const { data, loading, refetch } = offer.useOffers(defaultVariablesWithSort)

  const {
    Data,
    paginationState,
    currentData,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.offers,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })
  const { onSubmit: onUpdate } = offer.useOfferUpdate()
  const { onSubmit: onOfferDuplicate, response: offerDuplicateResponse } =
    offer.useOfferDuplicate()

  const { open } = useToastify()

  useEffect(() => {
    if (offerDuplicateResponse.data) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })
      refetch()
    }
  }, [offerDuplicateResponse.data?.offerDuplicate.offers.length])

  useEffect(() => {
    if (paginationState.page === 1) {
      refetch({ ...offerVariables, before: undefined, last: undefined })
    }
  }, [paginationState.page])

  const handleOnContactCustomer = (offerId: string) => {
    const path = `${CabinetNavigatorPathVendor.OFFER_CHAT}/${offerId}`

    history.push(path)
  }

  const handleOnUpdate = (publishData: Edge<Offer>[]) => {
    publishData.forEach((item) => {
      const deleteState =
        item.node.status === OfferStatus.DELETED_BY_CUSTOMER
          ? OfferStatus.DECLINED
          : OfferStatus.DECLINED_BY_VENDOR
      const newState = {
        id: item.node.id,
        input: {
          status: deleteState
        } as OfferCreateInput
      }
      onUpdate(newState)
    })
  }

  const handleOnRemove = (ids: string[]) => {
    const deletedOffers = currentData.filter(({ node }) => {
      const currentItem = ids.find((id) => id === node.id)

      return currentItem
    })
    const deletedDraftOffers = deletedOffers.filter(
      (deletedOffer) => deletedOffer.node.status === OfferStatus.START_OFFER
    )
    handleOnUpdate(deletedOffers)

    if (onUpdateOfferTotalCount) {
      onUpdateOfferTotalCount(-deletedDraftOffers.length)
    }
    if (refetch) {
      refetch(defaultVariablesWithSort)
    }
  }

  const handleOnSearch: OffersTableProps['onChangeSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnOfferDuplicate = (offerIds: string[]) => {
    if (arrayHasElements(offerIds)) {
      onOfferDuplicate({ offers: offerIds })
      if (onUpdateOfferTotalCount) {
        onUpdateOfferTotalCount(+offerIds.length)
      }
    }
  }

  const handleOnSort = (sortVariables: SortVariables<OfferOrderField>) => {
    if (refetch) {
      refetch({
        ...sortVariables,
        before: undefined,
        after: undefined,
        last: undefined
      })

      onResetPageState()
    }
    changeOfferVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: offerVariables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="flex-start"
      >
        <Text
          color="black"
          preset="h3"
          text="Offers"
          tx="cabinet.route.vendor.offers"
        />
      </Row>
      <OffersTable
        className={classes.table}
        data={Data}
        loading={loading}
        paginationState={paginationState}
        rowCounts={rowCounts}
        onDuplicateOffer={handleOnOfferDuplicate}
        onChangeSearch={handleOnSearch}
        onUpdate={handleOnUpdate}
        onRemove={handleOnRemove}
        onChangePage={onChangePage}
        onPrevioslyPage={onPrevioslyPage}
        onNextPage={onNextPage}
        onChangeRowCount={onChangeRowCount}
        onContactCustomer={handleOnContactCustomer}
        onSort={onSort}
      />
    </Column>
  )
}
