import React, { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Content, BackBreadcrumbs, LoadingContainer } from '../../components'
import { useApi, useChat } from '../../providers'
import { OrderChatView } from '../../views'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { useStyle } from './order-admin-customer-chat.styles'
import { OrderAdminCustomerChatParams } from './order-admin-customer-chat.types'

export const OrderAdminCustomerChat = () => {
  const history = useHistory()
  const { offerId, orderId } = useParams<OrderAdminCustomerChatParams>()
  const chatboxRef = useRef<HTMLDivElement>(null)
  const { onMountChatboxConversation } = useChat()
  const { order: orderApi } = useApi()
  const { data, refetch } = orderApi.useOrderById({ id: orderId })
  const { onSubmit } = orderApi.useOrderUpdate()

  const classes = useStyle()
  const order = data?.order

  useEffect(() => {
    if (refetch) {
      refetch({ id: orderId })
    }
  }, [offerId])

  useEffect(() => {
    if (order?.offer && onMountChatboxConversation) {
      const { offer } = order
      onMountChatboxConversation(chatboxRef, offer.adminCustomerConversationId)
    }
  }, [order?.offer])

  const handleGoBack = () => {
    history.push(CabinetNavigatorPathVendor.ORDER_CONTROL)
  }

  const handleOnDecline = () => {
    if (data) {
      onSubmit({
        id: orderId,
        input: {
          isCanceled: true
        }
      })
    }

    handleGoBack()
  }

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <BackBreadcrumbs
        className={classes.back}
        text="Back To Orders"
        tx="order.chat.breadcrumbs"
        onClick={handleGoBack}
      />
      <LoadingContainer loading={!order?.offer}>
        {order && (
          <OrderChatView
            ref={chatboxRef}
            order={order}
            onDecline={handleOnDecline}
          />
        )}
      </LoadingContainer>
    </Content>
  )
}
