import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { LoaderProps } from './loader.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  constainer: {
    padding: 24
  },
  loader: {
    border: `2px solid ${theme.colors.darkBlue}`,
    borderRadius: '50%',
    position: 'relative',
    width: ({ width = 56 }: LoaderProps) => width,
    height: ({ height = 56 }: LoaderProps) => height,
    '-webkit-animation': '$spin 2s linear infinite',
    animation: '$spin 1s ease-in-out infinite'
  },
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)'
    },
    to: {
      transform: 'rotate(360deg)'
    }
  },
  point: {
    content: '',
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: theme.colors.hover,
    position: 'absolute',
    top: -6,
    left: '50%',
    marginLeft: -5
  }
}))
