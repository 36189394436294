import React, { FC, useState } from 'react'

import { QuestionModal } from '../../../../components'
import { ProductRadioBox } from '../../../../components/product-radio-box'
import { Product } from '../../../../services'

import { ProductPickModalProps } from './product-pick-modal.types'

export const ProductPickModal: FC<ProductPickModalProps> = ({
  defaultActive,
  totalCount,
  data,
  title,
  titleTx,
  onClose,
  onSubmit,
  onLoadMore,
  onRefresh,
  onSearch
}) => {
  const [active, changeActive] = useState<Product | undefined>(defaultActive)

  const handleOnChange = (product: Product) => {
    changeActive(product)
  }

  const handleOnSubmit = () => {
    if (active && onSubmit) {
      onSubmit(active)
    }
  }

  return (
    <QuestionModal
      title={title}
      titleTx={titleTx}
      onClose={onClose}
      onSubmit={handleOnSubmit}
    >
      <ProductRadioBox
        active={active?.id}
        data={data}
        totalCount={totalCount}
        onFetchMore={onLoadMore}
        onChange={handleOnChange}
        onSearch={onSearch}
        onRefresh={onRefresh}
      />
    </QuestionModal>
  )
}
