import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: 24
  },
  list: {
    marginTop: 24
  },
  loadMore: {
    marginTop: 16,
    width: 537
  }
})
