import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import { LoginCardStyleProps } from './login-page.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  attention: {
    marginTop: 24,
    textDecorationColor: theme.colors.error,
    width: '100%'
  },
  container: {
    backgroundImage: ({ background }: LoginCardStyleProps) =>
      `url(${background})`,
    backgroundRepeat: 'space',
    backgroundSize: '100vw',
    paddingBottom: 24,
    zIndex: 1
  },
  forget: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 536,
    marginTop: 8
  },
  input: {
    marginTop: 24
  },
  loginForm: {
    marginTop: ({ isAdmin }: LoginCardStyleProps) => (isAdmin ? 40 : 96),
    width: 536
  },
  panel: {
    '& span': {
      color: theme.colors.error
    },
    borderRadius: 16,
    padding: 24
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  },
  submit: {
    marginTop: 32
  },
  text: {
    marginTop: 8
  }
}))
