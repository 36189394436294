import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Row } from '../row'
import { Text } from '../text'
import { Color, LightTheme } from '../Theme'
import { Icon } from '../icon'

import { CategoryNavItemProps } from './category-nav-item.types'
import { useStyle } from './category-nav-item.styles'

export const CategoryNavItem: FC<CategoryNavItemProps> = ({
  active,
  text,
  tx,
  icon,
  onClick
}) => {
  const theme = useTheme<LightTheme>()
  const activeColor: Color = active ? 'hover' : 'inactive'
  const activeTextColor: Color = active ? 'black' : 'inactive'
  const activeFill = theme.colors[activeColor]
  const classes = useStyle({ active })

  return (
    <>
      {icon ? (
        <Row
          className={`${classes.container} ${classes.iconContainer}`}
          justifyContent="flex-start"
          onClick={onClick}
        >
          <Icon fill={activeFill} height={24} src={icon} width={24} />
          <Text
            className={classes.text}
            text={text}
            tx={tx}
            preset="h4"
            color={activeTextColor}
          />
        </Row>
      ) : (
        <Row
          className={classes.container}
          justifyContent="flex-start"
          onClick={onClick}
        >
          <Text text={text} tx={tx} preset="h4" color={activeTextColor} />
        </Row>
      )}
    </>
  )
}
