import { SubscriptionTypeEdge } from '..'

export const SubTypes = {
  FREE: 'Free',
  MONTHLY: 'Monthly',
  ANNUALLY: 'Annually'
}
export interface SubscriptionOptionProps {
  defaultType: string
  types: SubscriptionTypeEdge[]
  nextPayment?: Date
  selected: string
  onChangeSelected: (name: string) => void
  onChangeSelectedPlanId: (name: string) => void
}

export type SubscriptionOptionsStyleProps = {
  active?: boolean
}
