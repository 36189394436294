import React, { FC } from 'react'

import { Column, isError, Row, Section, TextField } from '../../../components'

import { useStyle } from './feature-section.styles'
import { ProductFeaturesProps } from './features-section.types'

export const ProductFeaturesSection: FC<ProductFeaturesProps> = ({
  productionDaysUpTo,
  brandName,
  countryOfOrigin,
  materialCare,
  error,
  materialComposition,
  onChangeInputValue
}) => {
  const classes = useStyle()

  return (
    <Section
      className={classes.section}
      title="Product Features"
      titleTx="item.form.product-features.title"
    >
      <Column className={classes.sectionAdditionContainer}>
        <TextField
          defaultValue={productionDaysUpTo}
          isError={isError(productionDaysUpTo, error)}
          preset="border"
          type="number"
          label="Production days up to"
          labelTx="item.form.features.production.days"
          onChange={onChangeInputValue('productionDaysUpTo')}
        />
        <Row fullWidth className={classes.fieldRow}>
          <TextField
            defaultValue={brandName}
            isError={isError(brandName, error)}
            preset="border"
            maxLength={15}
            label="Brand name"
            labelTx="item.form.features.brand.name"
            onChange={onChangeInputValue('brandName')}
          />
          <TextField
            defaultValue={countryOfOrigin}
            isError={isError(countryOfOrigin, error)}
            preset="border"
            maxLength={15}
            label="Country of Origin"
            labelTx="item.form.features.origin.country"
            onChange={onChangeInputValue('countryOfOrigin')}
          />
        </Row>
        <TextField
          className={classes.field}
          defaultValue={materialComposition}
          isError={isError(materialComposition, error)}
          preset="border"
          maxLength={50}
          label="Material composition"
          labelTx="item.form.features.material.composition"
          onChange={onChangeInputValue('materialComposition')}
        />
        <TextField
          className={classes.field}
          defaultValue={materialCare}
          isError={isError(materialCare, error)}
          preset="border"
          maxLength={100}
          label="Material care"
          labelTx="item.form.features.material.care"
          onChange={onChangeInputValue('materialCare')}
        />
      </Column>
    </Section>
  )
}
