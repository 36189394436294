import { useContext } from 'react'

import { UserContext } from '../auth'

export function useUser() {
  const user = useContext(UserContext)
  return user
}

export default useUser
