import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Column,
  Row,
  Text,
  ICONS,
  UserInfoBadge,
  Loader,
  UserInfoBadgeBalance,
  getCurrencySymbol,
  getPriceToFixed,
  Button,
  getListFromList,
  getInstruction
} from '../../components'
import { useUser } from '../../hooks'
import { Currency } from '../../services'
import { useApi } from '../../providers'

import { ContactAdministrator } from '../views'
import { VendorPreview } from '../views/vendor-preview'
import {
  CabinetNavigatorPathVendor,
  ActivityTabNavigation
} from '../cabinet-navigator'

import { useStyle } from './your-info.styles'
import { TopItemsTable } from './top-items-table'
import { ActivityTable } from './activity-table'

export const YourInfoPage = () => {
  const {
    product: { useVendorCategories }
  } = useApi()
  const { instruction: instructionApi } = useApi()
  const history = useHistory()
  const { user } = useUser()
  const classes = useStyle()

  const categoryItemVariable = {
    first: 100,
    filter: {
      vendor: user?.id
    }
  }

  const { data: categories } = useVendorCategories(categoryItemVariable)
  const Categories = getListFromList(categories?.categories)

  const [openConfirmModal, changeOpenConfirmModal] = useState(false)

  const handleOnOpenConfirmModal = (state: boolean) => () => {
    changeOpenConfirmModal(state)
  }
  const { data: instructions } = instructionApi.useInstructions({ first: 100 })

  const instructionsList = getListFromList(instructions?.instructions)

  const youInfoBalanceInfo = getInstruction(
    instructionsList,
    'youInfoBalanceInfo'
  )

  const handleOnNavigateActivity = () => {
    history.push(
      `${CabinetNavigatorPathVendor.ACTIVITY}${ActivityTabNavigation.ACTIVITY_ORDERS}`
    )
  }

  if (!user) {
    return <Loader />
  }

  const {
    id: vendorId,
    sales = 0,
    ordersAmount = 0,
    topItems,
    activities,
    vendorBalance
  } = user

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Column alignItems="flex-start" justifyContent="flex-start">
          <Text
            color="black"
            preset="h3"
            text="Hello there!"
            tx="activity.title"
          />
          <Text
            color="inactive"
            preset="title"
            text="Here is some information we gathered about your store"
            tx="activity.description"
          />
          <ContactAdministrator className={classes.contactBtn} />
        </Column>
        <Button
          className={classes.button}
          preset="primary"
          text="YOUR PAGE PREVIEW"
          textPreset="h5"
          textColor="white"
          tx="activity.yuor.page.preview.button"
          onClick={handleOnOpenConfirmModal(true)}
        />
      </Row>
      <Row fullWidth className={classes.content} alignItems="flex-start">
        <Column fullWidth className={classes.leftGroup}>
          <Row fullWidth className={classes.dashboard}>
            <UserInfoBadge
              title="Sales"
              titleTx="userInfo.sales.title"
              value={`${getCurrencySymbol(Currency.USD)}${getPriceToFixed(
                sales
              )}`}
              icon={ICONS.localOffer}
            />
            <UserInfoBadge
              title="Orders"
              titleTx="userInfo.orders.title"
              value={String(ordersAmount || 0)}
              icon={ICONS.workOutline}
            />
          </Row>
          <TopItemsTable topItems={topItems} />
        </Column>
        <Column className={classes.rightGroup}>
          <UserInfoBadgeBalance
            title="Balance"
            titleTx="userInfo.balance.title"
            instruction={youInfoBalanceInfo}
            id={vendorId}
            value={vendorBalance}
          />
          <ActivityTable
            activities={activities}
            onNavigateActivity={handleOnNavigateActivity}
          />
        </Column>
      </Row>
      {openConfirmModal && (
        <VendorPreview
          data={user}
          categories={Categories}
          title="Your Page Preview"
          titleTx="your.info.vendor.page.preview"
          onClose={handleOnOpenConfirmModal(false)}
        />
      )}
    </Column>
  )
}
