import gql from 'graphql-tag'

export const SUBSCRIPTION_TYPE_QUERY = gql`
  {
    subscriptionTypes(first: 3) {
      edges {
        node {
          id
          name
          price
          pricePer
          description
          paypalPlanId
        }
      }
    }
  }
`

export const SUBSCRIPTION_CREATE = gql`
  mutation CreatePaypalSubscription(
    $paypalPlanId: String
    $cancelUrl: String
    $returnUrl: String
  ) {
    createPaypalSubscription(
      paypalPlanId: $paypalPlanId
      cancelUrl: $cancelUrl
      returnUrl: $returnUrl
    ) {
      link
      paymentErrors {
        field
        message
        code
      }
      errors {
        field
        message
      }
    }
  }
`
