import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'

import {
  IS_USER_EXIST,
  PART_ME_QUERY,
  REGISTER_USER,
  TOKEN_AUTH,
  TOKEN_REFRESH,
  USER_ME,
  REQUEST_EMAIL_CHANGE
} from './auth.graphql'
import {
  GetTokenRefreshResults,
  RegisterCredentials,
  TokenRefresh,
  UserRegister,
  AuthApi,
  GetRegisterRequest,
  GetRegisterResults,
  GetTokenCreateRequest,
  GetTokenCreateResults,
  TokenCreate,
  IsUserExist,
  IsUserExistResults,
  IsUserExistVariables,
  GetAccountMeData,
  AccountMe,
  AccountPartMe,
  GetPartAccountMeData,
  ChangeEmail,
  GetChangeEmailResults,
  GetChangeEmail
} from './auth.types'

export enum TokenStorageKey {
  AUTH = 'auth',
  REFRESH = 'refreshToken',
  CSRF = 'csrf'
}

// our "constructor"
export const authService = (): AuthApi => {
  const useToken = (): TokenCreate => {
    const [TokenAuth, response] = useMutation<
      GetTokenCreateResults,
      GetTokenCreateRequest
    >(TOKEN_AUTH)

    useEffect(() => {
      const { data } = response
      if (data?.tokenCreate.token) {
        const { token, refreshToken } = data.tokenCreate
        localStorage.setItem(TokenStorageKey.AUTH, String(token))
        localStorage.setItem(TokenStorageKey.REFRESH, String(refreshToken))
      }
    }, [response.data])

    const handleOnSubmit = (variables: GetTokenCreateRequest) => {
      TokenAuth({ variables })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRefreshToken = (): TokenRefresh => {
    const [TokenRefreshSubmit, response] = useMutation<
      GetTokenRefreshResults,
      { token: string }
    >(TOKEN_REFRESH)

    const handleOnSubmit = (token: string) => {
      if (token) {
        const options = {
          variables: {
            token
          }
        }

        TokenRefreshSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  /**
   * Login user request from service
   * @param userData - Data about user to login auth
   */
  const useRegister = (input: RegisterCredentials): UserRegister => {
    const [RegisterAccount, response] = useMutation<
      GetRegisterResults,
      GetRegisterRequest
    >(REGISTER_USER)

    useEffect(() => {
      const { data } = response
      if (data?.accountRegister.jwtToken) {
        const { jwtToken, refreshToken } = data.accountRegister
        localStorage.setItem(TokenStorageKey.AUTH, String(jwtToken))
        localStorage.setItem(TokenStorageKey.REFRESH, String(refreshToken))
      }
    }, [response.data])

    const options = {
      variables: {
        input
      }
    }

    const handleOnSubmit = () => {
      RegisterAccount(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useIsUserExist = (): IsUserExist => {
    const [onIsUserExist, response] = useMutation<
      IsUserExistResults,
      IsUserExistVariables
    >(IS_USER_EXIST)

    const handleOnSubmit = (variables: IsUserExistVariables) => {
      const options = {
        variables
      }

      onIsUserExist(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useIsLoggedIn = () => {
    const token = localStorage.getItem(TokenStorageKey.AUTH)

    return Boolean(token)
  }

  const useMe = (): AccountMe => {
    const [lazyFetch, { data, loading, refetch }] =
      useLazyQuery<GetAccountMeData>(USER_ME)

    return { data, loading, refetch, lazyFetch }
  }

  const usePartMe = (): AccountPartMe => {
    const { data, loading, refetch } =
      useQuery<GetPartAccountMeData>(PART_ME_QUERY)
    return { data, loading, refetch }
  }

  const useChangeEmail = (): ChangeEmail => {
    const [RequestChangeEmailSubmit, response] = useMutation<
      GetChangeEmailResults,
      GetChangeEmail
    >(REQUEST_EMAIL_CHANGE)

    const handleOnSubmit = (variables: GetChangeEmail) => {
      if (variables) {
        const options = {
          variables
        }

        RequestChangeEmailSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useIsLoggedIn,
    useRefreshToken,
    useRegister,
    useToken,
    useIsUserExist,
    useMe,
    usePartMe,
    useChangeEmail
  }
}
