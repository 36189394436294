import React, { FC } from 'react'
import { createUseStyles } from 'react-jss'

import { SOCIAL_MEDIA } from '../../core'
import { useApi } from '../../providers'
import { Column } from '../column'
import { Content } from '../content'
import { Row } from '../row'
import { SocialMediaIcon } from '../social-media-icon'
import { Text } from '../text'
import { TextField } from '../text-field'

import { NavCategory } from './nav-category'

import paymentImg from '../../assets/images/payments-icons.png'

const useStyle = createUseStyles({
  brand: {
    marginLeft: 4
  },
  category: {
    marginTop: 56
  },
  innerContainer: {
    marginBottom: 68,
    padding: '0px 96px',
    width: 'calc(100% - 192px)'
  },
  paymentBlock: {
    marginTop: 24
  },
  social: {
    marginTop: 24
  },
  socialIcon: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  }
})

export interface NavSocialBlockProps {
  className?: string
  classNameIcon?: string
}

const NavSocialBlock: FC<NavSocialBlockProps> = ({
  className = '',
  classNameIcon = ''
}) => (
  <Row className={className}>
    {SOCIAL_MEDIA.map((medium) => (
      <SocialMediaIcon
        className={classNameIcon}
        medium={medium}
        key={medium.ariaLabel}
      />
    ))}
  </Row>
)

const Nav = () => {
  const classes = useStyle()
  const { menu } = useApi()
  const { data, loading } = menu.useSecondaryMenu()

  if (!data || loading) {
    return <></>
  }

  const categories = data.shop.navigation.secondary.items

  const rightTextFieldElement = (
    <Text color="black" preset="rightButton" text="Subscribe" />
  )

  return (
    <Content>
      <Row
        wrap
        className={classes.innerContainer}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {categories.map((item, index) => {
          const additionalSection = index === 0 && (
            <NavSocialBlock
              className={classes.social}
              classNameIcon={classes.socialIcon}
            />
          )
          return (
            <NavCategory
              className={classes.category}
              additionalSection={additionalSection}
              key={item.id}
              item={item}
            />
          )
        })}
        <Column
          className={classes.category}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Text color="black" preset="body" text="Newsletter" />
          <TextField
            className={classes.paymentBlock}
            label=""
            preset="bottom-border"
            textInputPreset="secondTitle"
            rightElement={rightTextFieldElement}
          />
          <img
            className={classes.paymentBlock}
            src={paymentImg}
            alt="Available for pay"
          />
          <Row className={classes.paymentBlock}>
            <Text color="shadow" preset="secondTitle" text="Copyright by" />
            <Text
              color="yellow"
              className={classes.brand}
              preset="secondTitle"
              text="Ovaflopick"
            />
          </Row>
        </Column>
      </Row>
    </Content>
  )
}

export default Nav
