import { useEffect, useMemo, useState } from 'react'

import { PaginationState } from '../components'
import {
  Edge,
  FilterWithSearch,
  ListVariables,
  PaginationList
} from '../services'

import { useDebounceInput } from './useDebounceInput'

export type UseTableOptions<FiltersT extends FilterWithSearch, ItemT> = {
  defaultVariables: ListVariables<FiltersT>
  defaultRowCounts: number
  refetch?: (variables: Partial<ListVariables<FiltersT>>) => void
  data?: PaginationList<ItemT>
}

export function useTable<FiltersT extends FilterWithSearch, ItemT>({
  defaultVariables,
  data,
  refetch,
  defaultRowCounts
}: UseTableOptions<FiltersT, ItemT>) {
  const clearPagginationVariables = {
    first: undefined,
    after: undefined,
    last: undefined,
    before: undefined
  }
  const SEARCH_QUERY_MIN = 3

  const getPagination = (variables: ListVariables<FiltersT>) => ({
    ...clearPagginationVariables,
    ...variables
  })

  const defaultPaginationState = {
    pagesCount: 1,
    page: 1,
    limit: defaultRowCounts
  }

  const [paginationState, changePaginationState] = useState<PaginationState>(
    defaultPaginationState
  )

  const first = paginationState.limit

  const [variables, changeVariables] = useState<ListVariables<FiltersT>>({
    ...defaultVariables,
    first
  })

  const [currentData, changeCurrentData] = useState<Edge<ItemT>[]>([])
  const [loading, changeLoading] = useState(true)

  useEffect(() => {
    if (refetch) {
      changeLoading(true)
      refetch(variables)
    }
  }, [variables])

  useEffect(() => {
    if (data) {
      changeLoading(false)
      const nextProducts = data.edges
      changeCurrentData(nextProducts)
    }
  }, [data?.edges])

  useEffect(() => {
    if (data) {
      const { totalCount } = data
      changePaginationState({
        ...paginationState,
        pagesCount: Math.ceil(totalCount / paginationState.limit)
      })
    }
  }, [data?.totalCount, paginationState.limit])

  const handleOnResetPageState = () => {
    changePaginationState({ ...paginationState, page: 1 })
  }

  const handleOnChangePage = (nextPage: number) => {
    const { limit } = paginationState
    const page = Number(nextPage)
    changePaginationState((state) => ({ ...state, page }))

    const nextFirst = page * limit
    changeVariables({
      ...variables,
      ...getPagination({
        first: nextFirst
      })
    })
  }

  const handleOnChangeRowCount = (limit: number) => {
    if (data) {
      changePaginationState((state) => ({
        ...state,
        limit,
        page: 1
      }))

      const nextFirst = limit
      changeVariables({
        ...variables,
        ...getPagination({
          first: nextFirst
        })
      })
    }
  }

  const handleOnNextPage = () => {
    if (data) {
      const { hasNextPage, endCursor } = data.pageInfo
      const { page, limit } = paginationState

      if (!loading && hasNextPage) {
        changePaginationState({ ...paginationState, page: page + 1 })

        const nextVariables = {
          ...variables,
          ...clearPagginationVariables,
          ...getPagination({
            first: limit,
            after: endCursor
          })
        }
        changeVariables(nextVariables)
      }
    }
  }

  const handleOnPrevioslyPage = () => {
    if (data) {
      const { hasPreviousPage, startCursor } = data.pageInfo

      const { page, limit } = paginationState
      if (data && !loading && hasPreviousPage) {
        changePaginationState({ ...paginationState, page: page - 1 })

        changeVariables({
          ...variables,
          ...clearPagginationVariables,
          ...getPagination({
            last: limit,
            before: startCursor
          })
        })
      }
    }
  }

  const handleOnSearch = (value: string) => {
    const searchValue = value.length >= SEARCH_QUERY_MIN ? value : ''

    const nextVariables: ListVariables<FiltersT> = {
      ...variables,
      ...clearPagginationVariables,
      ...getPagination({
        first
      }),
      // @ts-ignore
      filter: {
        ...variables.filter,
        search: searchValue
      }
    }

    changeVariables(nextVariables)
    handleOnResetPageState()
  }

  const handleOnSubmitFilters = (filters: FiltersT) => {
    const nextVariables = {
      ...variables,
      ...clearPagginationVariables,
      ...getPagination({
        first
      }),
      filter: {
        ...variables.filter,
        ...filters
      }
    }

    changeVariables(nextVariables)
    handleOnResetPageState()
  }

  const handleOnResetFilters = () => {
    changeVariables({
      ...variables,
      ...clearPagginationVariables,
      // @ts-ignore
      filter: {
        search: variables.filter?.search
      },
      ...getPagination({
        first
      })
    })
    handleOnResetPageState()
  }

  const Data = useMemo(() => currentData.map(({ node }) => node), [currentData])
  const { onChange: onSearch } = useDebounceInput({ onChange: handleOnSearch })

  return {
    loading,
    Data,
    paginationState,
    changePaginationState,
    variables,
    changeVariables,
    currentData,
    changeCurrentData,
    onChangePage: handleOnChangePage,
    onChangeRowCount: handleOnChangeRowCount,
    onNextPage: handleOnNextPage,
    onPrevioslyPage: handleOnPrevioslyPage,
    onResetPageState: handleOnResetPageState,
    onSearch,
    onSubmitFilters: handleOnSubmitFilters,
    onResetFilters: handleOnResetFilters
  }
}
