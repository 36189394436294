import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '16px 0px'
  }
}))
