import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../Theme'

import { CalendarNumberItemStyleProps } from './calendar-number-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: 'pointer',
    padding: '8px 0'
  },
  leftSide: {
    backgroundColor: theme.colors.yellow,
    bottom: 16,
    left: 0,
    position: 'absolute',
    right: '50%',
    top: 16,
    zIndex: 1
  },
  rightSide: {
    backgroundColor: theme.colors.yellow,
    bottom: 16,
    left: '50%',
    position: 'absolute',
    right: 0,
    top: 16,
    zIndex: 1
  },
  text: {
    alignItems: 'center',
    backgroundColor: ({ active }: CalendarNumberItemStyleProps) =>
      active ? theme.colors.hover : 'transparent',
    borderRadius: 32,
    cursor: 'pointer',
    display: 'flex',
    height: 32,
    justifyContent: 'center',
    textAlign: 'center',
    width: 32,
    zIndex: 2
  }
}))
