import { TypePolicies, useQuery } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

import { CUSTOMERS } from './user-list.graphql'
import {
  CustomersListVariables,
  GetCustomersList,
  GetCustomersListData,
  UserListApi
} from './user-list.types'

export const cacheCustomerConfig: TypePolicies = {
  Query: {
    fields: {
      customers: relayStylePagination()
    }
  }
}

export const userListService = (): UserListApi => {
  const useCustomersListData = (
    variables: CustomersListVariables
  ): GetCustomersList => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetCustomersListData,
      CustomersListVariables
    >(CUSTOMERS, { variables })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  return {
    useCustomersListData
  }
}
