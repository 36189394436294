import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  offersRow: {
    marginTop: 16
  },
  name: {
    width: 200
  },
  value: {
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  text: {
    minWidth: 100,
    textAlign: 'end'
  },
  textRow: {
    width: 232
  },
  textDays: {
    display: 'flex',
    justifyContent: 'center',
    width: 160
  },
  textDescription: {
    marginTop: 4,
    maxWidth: 264
  }
})
