import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  checkbox: {
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },
  container: {
    maxHeight: 380
  },
  itemsContainer: {
    marginTop: 24,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 4
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.colors.blue}`,
      height: 120
    }
  },
  item: {
    padding: 16,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.colors.shadow}`
    }
  },
  bottom: {
    padding: '24px 0px',
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  success: {
    width: 168,
    height: 40,
    marginLeft: 24
  }
}))
