import { useQuery } from '@apollo/client'

import { COLORS } from './color.graphql'
import { ColorApi, Colors, ColorsVariables, ColorsData } from './color.types'

// our "constructor"
export const colorService = (): ColorApi => {
  const useColors = (): Colors => {
    const { data, loading, error, refetch } = useQuery<
      ColorsData,
      ColorsVariables
    >(COLORS)

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useColors
  }
}
