import React, { FC } from 'react'

import { Column, Row, Text } from '../../../../../components'

import { useStyle } from './vendor-preview-category-card.styles'
import { VendorPreviewCategoryCardProps } from './vendor-preview-category-card.types'

export const VendorPreviewCategoryCard: FC<VendorPreviewCategoryCardProps> = ({
  backgroundImage,
  className,
  name
}) => {
  const classes = useStyle()

  return (
    <Column className={`${className} ${classes.container}`}>
      <Row className={classes.imgContainer}>
        <Row className={classes.imgLabel}>
          <Text
            color="inactive"
            preset="maxButton"
            text="LOOK"
            tx="your.info.category.item.look"
          />
        </Row>
        {backgroundImage && (
          <img
            className={classes.img}
            src={backgroundImage.url}
            alt={backgroundImage.alt}
          />
        )}
      </Row>
      <Column className={classes.details}>
        {name && <Text color="inactive" preset="h4" text={name} />}
      </Column>
    </Column>
  )
}
