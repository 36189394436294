import React, { FC, ChangeEvent, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  Attention,
  Column,
  Row,
  Button,
  Text,
  Modal,
  TextField,
  ICONS
} from '../../../components'
import { LightTheme } from '../../../components/Theme'
import { useUser } from '../../../hooks/useUser'

import { ValidatorPassword } from '../validation'

import { ChangePasswordModalProps } from './change-password-modal.types'
import { useStyle } from './change-password-modal.styles'

export const ChangePasswordModal: FC<ChangePasswordModalProps> = ({
  title,
  titleTx,
  secondaryDescription,
  secondaryDescriptionTx,
  onClose
}) => {
  const userContext = useUser()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorText, setError] = useState('')
  const [errorTextPassword, setErrorPassword] = useState('')
  const [errorTextConfirmPassword, setErrorConfirmPassword] = useState('')
  const error = Boolean(errorText)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ error, theme })

  const handleSetError = (value: string) => {
    setError(value)
  }

  const handleSetErrorPassword = (value: string) => {
    if (value.length === 0 || !ValidatorPassword(value)) {
      setErrorPassword(
        'Password must contain from 5 to 30 characters, upper and lower case letters.'
      )
    } else {
      setErrorPassword('')
    }
  }

  const handleSetErrorConfirmPassword = (value: string) => {
    if (value.length === 0 || !ValidatorPassword(value)) {
      setErrorConfirmPassword(
        'Password must contain from 5 to 30 characters, upper and lower case letters.'
      )
    } else {
      setErrorConfirmPassword('')
    }
  }

  const handleOnCurrent = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setCurrentPassword(value)
  }

  const handleOnNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    handleSetErrorPassword(value)

    setNewPassword(value)
  }

  const handleOnConfirmNewPassword = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    handleSetErrorConfirmPassword(value)

    setConfirmNewPassword(value)
  }

  const handleOnChangePassword = () => {
    if (userContext.changePassword) {
      userContext.changePassword(
        currentPassword,
        newPassword,
        handleSetError,
        onClose
      )
    }
  }

  const submitChangePassword = () => {
    handleSetError('')
    if (newPassword === confirmNewPassword) {
      if (currentPassword !== newPassword) {
        handleOnChangePassword()
      } else {
        handleSetError('Current password and new password can not be equal')
      }
    } else {
      handleSetError("New password and Confirm doesn't match")
    }
  }

  const isDisabled = Boolean(
    errorTextPassword ||
      errorTextConfirmPassword ||
      !currentPassword ||
      !newPassword ||
      !confirmNewPassword
  )

  return (
    <Modal title={title} titleTx={titleTx} onClose={onClose}>
      <Column fullWidth className={classes.bottomContainer}>
        <Text
          color="inactive"
          preset="h7"
          text={secondaryDescription}
          tx={secondaryDescriptionTx}
        />

        <TextField
          className={classes.input}
          label="Current Password"
          labelTx="register.password"
          preset="border"
          maxLength={30}
          onChange={handleOnCurrent}
          isSecure={true}
        />
        <TextField
          className={classes.input}
          label="New Password"
          maxLength={30}
          labelTx="register.password"
          preset="border"
          onChange={handleOnNewPassword}
          isSecure={true}
          error={errorTextPassword}
        />
        <TextField
          className={classes.input}
          label="Confirm New Password"
          labelTx="register.password"
          preset="border"
          maxLength={30}
          onChange={handleOnConfirmNewPassword}
          isSecure={true}
          error={errorTextConfirmPassword}
        />
        <Attention
          className={classes.attention}
          color="error"
          icon={ICONS.attention}
          text={errorText}
        />
      </Column>
      <Row fullWidth justifyContent="flex-end" className={classes.buttonsRow}>
        <Button
          className={classes.button}
          color="yellow"
          preset="secondaryBorder"
          textColor="yellow"
          textPreset="maxButton"
          text="Back"
          onClick={onClose}
        />
        <Button
          className={classes.button}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="maxButton"
          text="Save"
          onClick={submitChangePassword}
          disabled={isDisabled}
        />
      </Row>
    </Modal>
  )
}
