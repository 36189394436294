import React, { FC, MouseEvent } from 'react'
import { useTheme } from 'react-jss'
import moment from 'moment'

import { useStyle } from './banner-table-item.styles'
import { BannerTableItemProps } from './banner-table-item.types'
import { LightTheme } from '../../../components/Theme'
import { Checkbox, Row, Text, ColorDot, TextProps } from '../../../components'
import { getBannerTypeNameByKind } from '../../helpers'
import { getDotColor } from './helpers'

export const BannerTableItem: FC<BannerTableItemProps> = ({
  id,
  checked,
  image,
  kind,
  isVisible,
  startDate,
  name,
  endDate = '',
  onClick,
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const isActive: TextProps = {
    text: 'Active',
    tx: 'banner.active'
  }
  const isNotActive: TextProps = {
    text: 'Inactive',
    tx: 'banner.inactive'
  }
  const colorVisible = getDotColor(isVisible)
  const visible = isVisible ? isActive : isNotActive

  const formatedStartDate = moment(new Date(startDate)).format('DD.MM.YYYY')
  const formatedEndDate = moment(new Date(endDate)).format('DD.MM.YYYY')
  const date = `${formatedStartDate} - ${formatedEndDate}`

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  const handleOnChange =
    (nextId: string) =>
    (state: boolean, event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      if (onChange) {
        onChange(nextId, state)
      }
    }

  const src = typeof image === 'string' ? image : ''

  return (
    <Row
      fullWidth
      key={`banner_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row className={classes.tableColumnFirst} justifyContent="flex-start">
        <Checkbox checked={checked} onChange={handleOnChange(id)} />
        <img className={classes.image} height={64} width={178} src={src} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {name && <Text color="black" preset="body" text={name} />}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="body" {...getBannerTypeNameByKind(kind)} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <ColorDot color={theme.colors[colorVisible]} />
        <Text
          className={classes.statusText}
          color="black"
          preset="body"
          {...visible}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="body" text={date} />
      </Row>
    </Row>
  )
}
