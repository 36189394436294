import { MutationResult } from '@apollo/client'

import { TaxedMoney, Money } from '../tax'
import {
  Product,
  ProductColor,
  ProductMaterial,
  Image,
  CountableConnectionT,
  CountryDisplay,
  LanguageDisplay,
  ProductCountableConnection,
  ProductVariant,
  ObjectWithMetadata,
  Warehouse,
  ShippingMethod,
  ProductOption
} from '../product'
import { Address, AddressInput, GiftCard, User } from '../auth'
import { CategoryWithChildren } from '../category'
import { Collection } from '../collection'
import {
  ListVariables,
  PaginationList,
  Edge,
  DateRangeInput,
  FetchMore,
  FilterItem,
  SortBy
} from '../api.types'
import { Offer, OfferItem } from '../offer'

/* ORDER info */
export type OrderCountableEdge = Edge<Order>
export type CategoryCountableEdge = Edge<CategoryWithChildren>
export type CollectionCountableEdge = Edge<Collection>

export type OrderCountableConnection = CountableConnectionT<OrderCountableEdge>
export type CategoryCountableConnection =
  CountableConnectionT<CategoryCountableEdge>
export type CollectionCountableConnection =
  CountableConnectionT<CollectionCountableEdge>

export enum VoucherTypeEnum {
  SHIPPING = 'SHIPPING',
  ENTIRE_ORDER = 'ENTIRE_ORDER',
  SPECIFIC_PRODUCT = 'SPECIFIC_PRODUCT'
}

export enum DiscountValueTypeEnum {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE'
}

export interface VoucherTranslation extends Node {
  name: string
  language: LanguageDisplay
}
export interface Voucher extends Node {
  name: string
  type: VoucherTypeEnum
  code: string
  usageLimit: number
  used: number
  startDate: Date
  endDate: Date
  applyOncePerOrder: boolean
  applyOncePerCustomer: boolean
  discountValueType: DiscountValueTypeEnum
  discountValue: number
  minSpent: Money
  minCheckoutItemsQuantity: number
  categories: CategoryCountableConnection
  collections: CollectionCountableConnection
  products: ProductCountableConnection
  countries: CountryDisplay[]
  translation: VoucherTranslation
}

export interface FulfillmentLine extends Node {
  quantity: number
  orderLine: OrderLine
}

export enum FulfillmentStatus {
  FULFILLED = 'Fulfilled',
  CANCELED = 'Canceled'
}

export interface Fulfillment extends Node, ObjectWithMetadata {
  fulfillmentOrder: number
  status: FulfillmentStatus
  trackingNumber: string
  created: Date
  lines: FulfillmentLine[]
  statusDisplay: String
  warehouse: Warehouse
}

export enum OrderAction {
  CAPTURE = 'Represents the capture action.',
  MARK_AS_PAID = 'Represents a mark-as-paid action.',
  REFUND = 'Represents a refund action.',
  VOID = 'Represents a void action.'
}

export enum JobStatusEnum {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  DELETED = 'DELETED'
}

export interface Job {
  status: JobStatusEnum
  createdAt: Date
  updatedAt: Date
  message: String
}

export interface Invoice extends ObjectWithMetadata, Node, Job {
  number: String
  externalUrl: String
  url: String
}

export enum TransactionKind {
  AUTH = 'Authorization',
  PENDING = 'Pending',
  ACTION_TO_CONFIRM = 'Action to confirm',
  REFUND = 'Refund',
  REFUND_ONGOING = 'Refund in progress',
  CAPTURE = 'Capture',
  VOID = 'Void',
  CONFIRM = 'Confirm',
  CANCEL = 'Cancel'
}

export enum TransactionError {
  TRANSACTIONERROR_INCORRECT_NUMBER = 'incorrect_number',
  TRANSACTIONERROR_INVALID_NUMBER = 'invalid_number',
  TRANSACTIONERROR_INCORRECT_CVV = 'incorrect_cvv',
  TRANSACTIONERROR_INVALID_CVV = 'invalid_cvv',
  TRANSACTIONERROR_INCORRECT_ZIP = 'incorrect_zip',
  TRANSACTIONERROR_INCORRECT_ADDRESS = 'incorrect_address',
  TRANSACTIONERROR_INVALID_EXPIRY_DATE = 'invalid_expiry_date',
  TRANSACTIONERROR_EXPIRED = 'expired',
  TRANSACTIONERROR_PROCESSING_ERROR = 'processing_error',
  TRANSACTIONERROR_DECLINED = 'declined'
}

export interface Transaction extends Node {
  created: Date
  payment: Payment
  token: string
  kind: TransactionKind
  isSuccess: boolean
  error: TransactionError
  amount: Money
}

export type CreditCard = {
  brand: string
  firstDigits: string
  lastDigits: string
  expMonth: number
  expYear: number
}

export interface CheckoutLine extends Node {
  variant: ProductVariant
  quantity: number
  totalPrice: TaxedMoney
  requiresShipping: boolean
}

export type GatewayConfigLine = {
  field: string
  value: string
}

export interface PaymentGateway extends Node {
  name: string
  config: GatewayConfigLine[]
  currencies: string[]
}

export interface Checkout extends Node, ObjectWithMetadata {
  created: string
  lastChange: string
  user: User
  quantity: number
  billingAddress: Address
  shippingAddress: Address
  shippingMethod: ShippingMethod
  note: string
  discount: Money
  discountName: string
  translatedDiscountName: string
  voucherCode: string
  giftCards: GiftCard[]
  availableShippingMethods: ShippingMethod[]
  availablePaymentGateways: PaymentGateway[]
  email: string
  isShippingRequired: boolean
  lines: CheckoutLine[]
  shippingPrice: TaxedMoney
  subtotalPrice: TaxedMoney
  token: string
  totalPrice: TaxedMoney
}

export interface Payment extends Node {
  gateway: string
  isActive: boolean
  created: Date
  modified: Date
  token: string
  checkout: Checkout
  order: Order
  customerIpAddress: string
  chargeStatus: PaymentChargeStatusEnum
  actions: OrderAction[]
  total: Money
  capturedAmount: Money
  transactions: Transaction[]
  availableCaptureAmount: Money
  availableRefundAmount: Money
  creditCard: CreditCard
}

export enum OrderEventsEnum {
  DRAFT_CREATED = 'DRAFT_CREATED',
  DRAFT_ADDED_PRODUCTS = 'DRAFT_ADDED_PRODUCTS',
  DRAFT_REMOVED_PRODUCTS = 'DRAFT_REMOVED_PRODUCTS',
  PLACED = 'PLACED',
  PLACED_FROM_DRAFT = 'PLACED_FROM_DRAFT',
  OVERSOLD_ITEMS = 'OVERSOLD_ITEMS',
  CANCELED = 'CANCELED',
  ORDER_MARKED_AS_PAID = 'ORDER_MARKED_AS_PAID',
  ORDER_FULLY_PAID = 'ORDER_FULLY_PAID',
  UPDATED_ADDRESS = 'UPDATED_ADDRESS',
  EMAIL_SENT = 'EMAIL_SENT',
  PAYMENT_AUTHORIZED = 'PAYMENT_AUTHORIZED',
  PAYMENT_CAPTURED = 'PAYMENT_CAPTURED',
  EXTERNAL_SERVICE_NOTIFICATION = 'EXTERNAL_SERVICE_NOTIFICATION',
  PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',
  PAYMENT_VOIDED = 'PAYMENT_VOIDED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  INVOICE_REQUESTED = 'INVOICE_REQUESTED',
  INVOICE_GENERATED = 'INVOICE_GENERATED',
  INVOICE_UPDATED = 'INVOICE_UPDATED',
  INVOICE_SENT = 'INVOICE_SENT',
  FULFILLMENT_CANCELED = 'FULFILLMENT_CANCELED',
  FULFILLMENT_RESTOCKED_ITEMS = 'FULFILLMENT_RESTOCKED_ITEMS',
  FULFILLMENT_FULFILLED_ITEMS = 'FULFILLMENT_FULFILLED_ITEMS',
  TRACKING_UPDATED = 'TRACKING_UPDATED',
  NOTE_ADDED = 'NOTE_ADDED',
  OTHER = 'OTHER'
}

export enum OrderEventsEmailsEnum {
  PAYMENT_CONFIRMATION = 'PAYMENT_CONFIRMATION',
  SHIPPING_CONFIRMATION = 'SHIPPING_CONFIRMATION',
  TRACKING_UPDATED = 'TRACKING_UPDATED',
  ORDER_CONFIRMATION = 'ORDER_CONFIRMATION',
  ORDER_CANCEL = 'ORDER_CANCEL',
  ORDER_REFUND = 'ORDER_REFUND',
  FULFILLMENT_CONFIRMATION = 'FULFILLMENT_CONFIRMATION',
  DIGITAL_LINKS = 'DIGITAL_LINKS'
}

export type OrderEventOrderLineObject = {
  quantity: number
  orderLine: OrderLine
  itemName: string
}

export interface OrderEvent extends Node {
  date: Date
  type: OrderEventsEnum
  user: User
  message: string
  email: string
  emailType: OrderEventsEmailsEnum
  amount: number
  paymentId: string
  paymentGateway: string
  quantity: number
  composedId: string
  orderNumber: string
  invoiceNumber: string
  oversoldItems: string[]
  lines: OrderEventOrderLineObject[]
  fulfilledItems: FulfillmentLine[]
  warehouse: Warehouse
}

export type OrderNotificationType = {
  title: string
  event: string
  id: string
  timePassed?: number
}

export enum PurchaseStatus {
  COMPLETED = 'COMPLETED',
  DECLINE = 'DECLINE'
}

export enum OrderErrorCode {
  BILLING_ADDRESS_NOT_SET = 'BILLING_ADDRESS_NOT_SET',
  CANNOT_CANCEL_FULFILLMENT = 'CANNOT_CANCEL_FULFILLMENT',
  CANNOT_CANCEL_ORDER = 'CANNOT_CANCEL_ORDER',
  CANNOT_DELETE = 'CANNOT_DELETE',
  CANNOT_REFUND = 'CANNOT_REFUND',
  CAPTURE_INACTIVE_PAYMENT = 'CAPTURE_INACTIVE_PAYMENT',
  NOT_EDITABLE = 'NOT_EDITABLE',
  FULFILL_ORDER_LINE = 'FULFILL_ORDER_LINE',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  PRODUCT_NOT_PUBLISHED = 'PRODUCT_NOT_PUBLISHED',
  PRODUCT_UNAVAILABLE_FOR_PURCHASE = 'PRODUCT_UNAVAILABLE_FOR_PURCHASE',
  NOT_FOUND = 'NOT_FOUND',
  ORDER_NO_SHIPPING_ADDRESS = 'ORDER_NO_SHIPPING_ADDRESS',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  PAYMENT_MISSING = 'PAYMENT_MISSING',
  REQUIRED = 'REQUIRED',
  SHIPPING_METHOD_NOT_APPLICABLE = 'SHIPPING_METHOD_NOT_APPLICABLE',
  SHIPPING_METHOD_REQUIRED = 'SHIPPING_METHOD_REQUIRED',
  TAX_ERROR = 'TAX_ERROR',
  UNIQUE = 'UNIQUE',
  VOID_INACTIVE_PAYMENT = 'VOID_INACTIVE_PAYMENT',
  ZERO_QUANTITY = 'ZERO_QUANTITY',
  INSUFFICIENT_STOCK = 'INSUFFICIENT_STOCK',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM'
}

export type OrderError = {
  field: string
  message: string
  code: OrderErrorCode
  orderLine: string
}

export enum OrderStatus {
  DRAFT = 'DRAFT',
  UNFULFILLED = 'UNFULFILLED',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  FULFILLED = 'FULFILLED',
  CANCELED = 'CANCELED',
  SHIPPED = 'SHIPPED',
  DELIVERIED = 'DELIVERIED'
}

export enum OrderMainStatus {
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  CONFIRMED = 'CONFIRMED',
  PRODUCTION_STARTED = 'PRODUCTION_STARTED',
  PRODUCTION_FINISHED = 'PRODUCTION_FINISHED',
  PRODUCT_SHIPPED = 'PRODUCT_SHIPPED',
  PARCEL_DELIVERED = 'PARCEL_DELIVERED',
  CANCELED_BY_CUSTOMER = 'CANCELED_BY_CUSTOMER',
  CANCELED_BY_VENDOR = 'CANCELED_BY_VENDOR',
  CANCELED_WITHOUT_REFUND = 'CANCELED_WITHOUT_REFUND',
  PAID = 'PAID',
  REFUNDED = 'REFUNDED',
  PARTLY_REFUNDED = 'PARTLY_REFUNDED'
}

export enum OrderSortField {
  NUMBER = 'NUMBER',
  CREATION_DATE = 'CREATION_DATE',
  CUSTOMER = 'CUSTOMER',
  PAYMENT = 'PAYMENT',
  FULFILLMENT_STATUS = 'FULFILLMENT_STATUS',
  TOTAL = 'TOTAL',
  TOTAL_PRICE = 'TOTAL_PRICE',
  VENDOR = 'VENDOR'
}

export enum OrderPaymentStatus {
  CONFIRMED = 'CONFIRMED',
  PROCESS = 'PROCESS',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED',
  CANCELED = 'CANCELED',
  DENIED = 'DENIED',
  FAILED = 'FAILED',
  HELD = 'HELD',
  REFUNDED = 'REFUNDED',
  RETURNED = 'RETURNED',
  SUCCEEDED = 'SUCCEEDED',
  UNCLAIMED = 'UNCLAIMED'
}

export enum OrderPaymentStatusFilter {
  CONFIRMED = 'CONFIRMED',
  PROCESS = 'PROCESS',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  REFUNDED = 'REFUNDED',
  RETURNED = 'RETURNED',
  SUCCEEDED = 'SUCCEEDED'
}

export enum PaymentChargeStatusEnum {
  NOT_CHARGED = 'NOT_CHARGED',
  PENDING = 'PENDING',
  PARTIALLY_CHARGED = 'PARTIALLY_CHARGED',
  FULLY_CHARGED = 'FULLY_CHARGED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  FULLY_REFUNDED = 'FULLY_REFUNDED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}

export type OrderSortingInput = SortBy<OrderSortField>

export type OrderFilterInput = {
  mainStatus?: OrderMainStatus[]
  paymentStatus?: OrderPaymentStatusFilter[]
  customer?: string[]
  vendor?: string[]
  created?: DateRangeInput
  search?: string
  companyName?: string[]
}

export type OrderControlFiltersKeys = keyof OrderFilterInput

export interface OrdersVariables extends ListVariables<OrderFilterInput> {
  sortBy?: OrderSortingInput
}

export type OrderLine = {
  id: string
  color?: ProductColor
  material?: ProductMaterial
  options: ProductOption[]
  productName: string
  variantName: string
  productSku: string
  isShippingRequired: boolean
  quantity: number
  quantityFulfilled: number
  taxRate: number
  // digitalContentUrl: DigitalContentUrl
  thumbnail: Image
  unitPrice: TaxedMoney
  totalPrice: number
  product: Product
  items: OfferItem[]
  translatedProductName: string
  translatedVariantName: string
}

export interface OrderUser
  extends Pick<
    User,
    | 'email'
    | 'city'
    | 'firstName'
    | 'lastName'
    | 'country'
    | 'phone'
    | 'id'
    | 'avatar'
  > {}

export interface OrderVendor extends OrderUser, Pick<User, 'companyName'> {}

export interface OrderCustomer
  extends OrderUser,
    Pick<User, 'defaultShippingAddress'> {}

export type Order = {
  id: string
  created: string
  startDate?: Date
  finishDate?: Date
  status: OrderStatus
  mainStatus: OrderMainStatus
  user: OrderCustomer
  totalPrice: number
  languageCode: string
  trackingClientId: string
  shippingAddress: Address
  remainingTimeProductionStarted?: string
  lines: OrderLine[]
  offer: Offer
  number: string
  event?: string
  paymentStatus: OrderPaymentStatus
  paymentStatusDisplay: string
  total: TaxedMoney
  vendor?: OrderVendor
  trackingNumber?: string
  deliveryPrice: number
}

export type OrderLineCreateInput = {
  quantity: number
  productId: string
}

export type OrderUpdateInput = {
  billingAddress?: AddressInput
  userEmail?: string
  shippingAddress?: AddressInput
  isConfirmed?: boolean
  isProductionStarted?: boolean
  isProductionFinished?: boolean
  isProductionShipped?: boolean
  isCanceled?: boolean
  trackingNumber?: string
}

export type DraftOrderCreateInput = {
  shippingAddress?: AddressInput
  lines: OrderLineCreateInput[]
}

export type OrderUpdateVariables = {
  id: string
  input: OrderUpdateInput
}

export type OrderCancelationByAdminVariables = {
  orderId: string
  refundPercentage: number
}

export type OrderByIdVariables = {
  id: string
}

export type DraftOrderCreateData = {
  draftOrderCreate: {
    order: Order
    orderErrors: OrderError[]
  }
}

export type OrderUpdateData = {
  orderUpdate: {
    order: Order
    orderErrors: OrderError[]
  }
}

export type OrderCancelationByAdminData = {
  cancelOrderByAdmin: {
    orderStatus: OrderMainStatus
  }
}

export type OrdersFiltersParseJSON = {
  customers: FilterItem[]
  vendors: FilterItem[]
  companyNames: FilterItem[]
}

export interface OrdersData extends PaginationList<Order> {
  filters: string
}

export type GetOrdersData = {
  orders: OrdersData
}

export type GetOrder = {
  order: Order
}

export type DraftOrderCreate = {
  onSubmit: (variables: DraftOrderCreateInput) => void
  response: MutationResult<DraftOrderCreateData>
}

export type OrderUpdate = {
  onSubmit: (variables: OrderUpdateVariables) => void
  response: MutationResult<OrderUpdateData>
}

export type OrderCancelationByAdmin = {
  onSubmit: (variables: OrderCancelationByAdminVariables) => void
  response: MutationResult<OrderCancelationByAdminData>
}

export type GetOrders = {
  data?: GetOrdersData | null
  loading: boolean
  refetch: (variables?: Partial<OrdersVariables>) => void
  fetchMore?: FetchMore<GetOrdersData, OrdersVariables>
}

export type GetOrderByIdRequest = {
  data: GetOrder | null
  loading: boolean
  refetch: (variables: OrderByIdVariables) => void
}

export type OrderApi = {
  useDraftOrderCreate: () => DraftOrderCreate
  useOrders: (variables: OrdersVariables) => GetOrders
  useOrderById: (variables: OrderByIdVariables) => GetOrderByIdRequest
  useOrderUpdate: () => OrderUpdate
  useOrderCancelationByAdmin: () => OrderCancelationByAdmin
}
