import React, { ChangeEvent, FC, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  arrayHasElements,
  Button,
  LightTheme,
  MultiSelect,
  Section
} from '../../../components'
import { AttributeVariables } from '../../../services'

import { EmptySection } from '../empty-section'
import { getAttributeType, getCompareAttributes } from '../helpers'
import { AttributeSlider } from '../attribute-slider'

import { useStyle } from './attribute-section.styles'
import { AttributeSectionProps } from './attribute-section.types'

export const AttributeSection: FC<AttributeSectionProps> = ({
  data,
  attributes,
  ids,
  value,
  instruction,
  onChangeValue,
  onRefetchAttributes
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [openAttributes, changeOpenAttributes] = useState(false)
  const [attributeVariables, changeAttributeVariables] =
    useState<AttributeVariables>({
      first: 100
    })

  const defaultAttributeValue = {
    ...attributeVariables,
    firts: 100,
    filter: {
      search: ''
    }
  }

  const handleOnOpenAttributes = (state: boolean) => () => {
    changeOpenAttributes(state)
  }
  const handleOnSearchAttribute = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: searchValue } = event.target
    const nextAttributeVariables = {
      ...attributeVariables,
      first: 100,
      filter: {
        ...attributeVariables.filter,
        search: searchValue
      }
    }
    if (onRefetchAttributes) {
      onRefetchAttributes(nextAttributeVariables)
    }

    changeAttributeVariables(nextAttributeVariables)
  }

  const handlOnCloseAttributes = () => {
    const close = handleOnOpenAttributes(false)

    close()
    if (onRefetchAttributes) {
      onRefetchAttributes(defaultAttributeValue)
    }
  }
  const handleOnChangeAttributeTypes = (nextIds: string[]) => {
    if (attributes) {
      const compareAttributes = getCompareAttributes(nextIds, attributes)

      const nextValue = {
        ...value,
        attributes: getAttributeType(compareAttributes, value?.attributes)
      }

      if (onChangeValue) {
        onChangeValue(nextValue)
      }
    }

    handlOnCloseAttributes()
  }

  const attributesButton = (
    <>
      <Button
        className={classes.button}
        color="yellow"
        preset="fixedBorderButton"
        textColor="yellow"
        textPreset="h5"
        text="ADD ATTRIBUTE"
        tx="item.form.button.text"
        onClick={handleOnOpenAttributes(true)}
      />
      {openAttributes && (
        <MultiSelect
          active={ids}
          data={attributes}
          title="Add Attributes"
          titleTx="item.form.add.attributes.title"
          defaultValue={attributeVariables.filter?.search}
          onClose={handlOnCloseAttributes}
          onChange={handleOnChangeAttributeTypes}
          onChangeSearch={handleOnSearchAttribute}
        />
      )}
    </>
  )

  return (
    <Section
      isInfo
      className={classes.section}
      rightButton={attributesButton}
      title="Attributes"
      titleTx="item.form.attributes.title"
      infoText={instruction}
    >
      {arrayHasElements(data) ? (
        <AttributeSlider
          title="Attributes"
          titleTx="item.form.attributes.title"
          data={data}
          item={value}
          onChange={onChangeValue}
        />
      ) : (
        <EmptySection
          className={classes.sectionAdditionWithText}
          text="No attributes created for this element. Use the button below to add new attribute field."
          tx="item.form.attributes.description"
        />
      )}
    </Section>
  )
}
