import moment from 'moment-timezone'
import { MutationResult } from '@apollo/client'
import { defineMessages, IntlShape } from 'react-intl'
import urlJoin from 'url-join'

import { APP_MOUNT_URI } from './config'
import {
  AddressInput,
  AddressType,
  UserError,
  CountryCode,
  OrderStatus,
  PaymentChargeStatusEnum
} from './services'

export function maybe<T>(exp: () => T): T | undefined
export function maybe<T>(exp: () => T, d: T): T
export function maybe(exp: any, d?: any) {
  try {
    const result = exp()
    return result === undefined ? d : result
  } catch {
    return d
  }
}

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  { [K in Keys]-?: Required<Pick<T, K>> }[Keys]

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
      Partial<Record<Exclude<Keys, K>, undefined>>
  }[Keys]

export function renderCollection<T>(
  collection: T[],
  renderItem: (
    item: T | undefined,
    index: number | undefined,
    collection: T[]
  ) => any,
  renderEmpty?: (collection: T[]) => any
) {
  if (collection === undefined) {
    return renderItem(undefined, undefined, collection)
  }
  if (collection.length === 0) {
    return renderEmpty ? renderEmpty(collection) : null
  }
  return collection.map(renderItem)
}

export function decimal(value: string | number) {
  if (typeof value === 'string') {
    return value === '' ? null : value
  }
  return value
}

export function weight(value: string) {
  return value === '' ? null : parseFloat(value)
}

export const removeDoubleSlashes = (url: string) =>
  url.replace(/([^:]\/)\/+/g, '$1')

const paymentStatusMessages = defineMessages({
  paid: {
    defaultMessage: 'Fully paid',
    id: '2pw5dQ',
    description: 'payment status'
  },
  partiallyPaid: {
    defaultMessage: 'Partially paid',
    id: 'INNPVX',
    description: 'payment status'
  },
  partiallyRefunded: {
    defaultMessage: 'Partially refunded',
    id: 'OGemtu',
    description: 'payment status'
  },
  refunded: {
    defaultMessage: 'Fully refunded',
    id: 'XJSRDK',
    description: 'payment status'
  },
  unpaid: {
    defaultMessage: 'Unpaid',
    id: 'FBtqtl',
    description: 'payment status'
  }
})

export const transformPaymentStatus = (status: string, intl: IntlShape) => {
  switch (status) {
    case PaymentChargeStatusEnum.PARTIALLY_CHARGED:
      return {
        localized: intl.formatMessage(paymentStatusMessages.partiallyPaid),
        status: 'error'
      }
    case PaymentChargeStatusEnum.FULLY_CHARGED:
      return {
        localized: intl.formatMessage(paymentStatusMessages.paid),
        status: 'success'
      }
    case PaymentChargeStatusEnum.PARTIALLY_REFUNDED:
      return {
        localized: intl.formatMessage(paymentStatusMessages.partiallyRefunded),
        status: 'error'
      }
    case PaymentChargeStatusEnum.FULLY_REFUNDED:
      return {
        localized: intl.formatMessage(paymentStatusMessages.refunded),
        status: 'success'
      }
    default:
      return {
        localized: intl.formatMessage(paymentStatusMessages.unpaid),
        status: 'error'
      }
  }
}

export const orderStatusMessages = defineMessages({
  cancelled: {
    defaultMessage: 'Cancelled',
    id: 'mvbXRP',
    description: 'order status'
  },
  draft: {
    defaultMessage: 'Draft',
    id: 'toDL5R',
    description: 'order status'
  },
  fulfilled: {
    defaultMessage: 'Fulfilled',
    id: 'pkjXPD',
    description: 'order status'
  },
  partiallyFulfilled: {
    defaultMessage: 'Partially fulfilled',
    id: 'PbqNhi',
    description: 'order status'
  },
  readyToCapture: {
    defaultMessage: 'Ready to capture',
    id: 'rqtV5d',
    description: 'order status'
  },
  readyToFulfill: {
    defaultMessage: 'Ready to fulfill',
    id: 'oLMXDv',
    description: 'order status'
  },
  unfulfilled: {
    defaultMessage: 'Unfulfilled',
    id: 'oB0y5Y',
    description: 'order status'
  }
})

export const transformOrderStatus = (status: string, intl: IntlShape) => {
  switch (status) {
    case OrderStatus.FULFILLED:
      return {
        localized: intl.formatMessage(orderStatusMessages.fulfilled),
        status: 'success'
      }
    case OrderStatus.PARTIALLY_FULFILLED:
      return {
        localized: intl.formatMessage(orderStatusMessages.partiallyFulfilled),
        status: 'neutral'
      }
    case OrderStatus.UNFULFILLED:
      return {
        localized: intl.formatMessage(orderStatusMessages.unfulfilled),
        status: 'error'
      }
    case OrderStatus.CANCELED:
      return {
        localized: intl.formatMessage(orderStatusMessages.cancelled),
        status: 'error'
      }
    case OrderStatus.DRAFT:
      return {
        localized: intl.formatMessage(orderStatusMessages.draft),
        status: 'error'
      }
    default:
      return {
        localized: status,
        status: 'error'
      }
  }
}

export const transformAddressToForm = (data: AddressType) => ({
  city: maybe(() => data.city, ''),
  cityArea: maybe(() => data.cityArea, ''),
  companyName: maybe(() => data.companyName, ''),
  country: maybe(() => data.country.code, ''),
  countryArea: maybe(() => data.countryArea, ''),
  firstName: maybe(() => data.firstName, ''),
  lastName: maybe(() => data.lastName, ''),
  phone: maybe(() => data.phone, ''),
  postalCode: maybe(() => data.postalCode, ''),
  streetAddress1: maybe(() => data.streetAddress1, ''),
  streetAddress2: maybe(() => data.streetAddress2, '')
})

export function only<T>(obj: T, key: keyof T): boolean {
  return Object.keys(obj).every((objKey) => {
    if (objKey === key) {
      return obj[key] !== undefined
    }

    return obj[key] === undefined
  })
}

export function empty(obj: Record<string, any>): boolean {
  return Object.keys(obj).every((key) => obj[key] === undefined)
}

export function hasErrors(errorList: UserError[] | null): boolean {
  return !(
    errorList === undefined ||
    errorList === null ||
    errorList.length === 0
  )
}

export function getMutationState(
  called: boolean,
  loading: boolean,
  ...errorList: any[][]
) {
  if (loading) {
    return 'loading'
  }
  if (called) {
    return errorList.map(hasErrors).reduce((acc, curr) => acc || curr, false)
      ? 'error'
      : 'success'
  }
  return 'default'
}

export function getMutationStatus<TData>(opts: MutationResult<TData>) {
  const errors = opts.data
    ? Object.values(opts.data).reduce(
        (acc: UserError[], mut) => [...acc, ...maybe(() => mut.errors, [])],
        []
      )
    : []

  return getMutationState(opts.called, opts.loading, errors)
}

interface User {
  email: string
  firstName?: string
  lastName?: string
}

export function getUserName(user?: User, returnEmail?: boolean) {
  if (user && (user.email || (user.firstName && user.lastName))) {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`
    }
    if (user.email) {
      return user.email.split('@')[0]
    }
    return returnEmail
  }

  return undefined
}

export function getUserInitials(user?: User) {
  return user && (user.email || (user.firstName && user.lastName))
    ? (user.firstName && user.lastName
        ? user.firstName[0] + user.lastName[0]
        : user.email.slice(0, 2)
      ).toUpperCase()
    : undefined
}

export function createHref(url: string) {
  return urlJoin(APP_MOUNT_URI, url)
}

interface AnyEvent {
  stopPropagation: () => void
}
export function stopPropagation(cb: (event?: AnyEvent) => void) {
  return (event: AnyEvent) => {
    event.stopPropagation()
    cb(event)
  }
}

export function joinDateTime(date: string, time?: string) {
  if (!date) {
    return null
  }
  const setTime = time || '00:00'
  const dateTime = moment(`${date} ${setTime}`).format()
  return dateTime
}

export function splitDateTime(dateTime: string) {
  if (!dateTime) {
    return {
      date: '',
      time: ''
    }
  }
  // Default html input format YYYY-MM-DD HH:mm
  const nextSplitTime = moment(dateTime).format('YYYY-MM-DD HH:mm').split(' ')

  return {
    date: nextSplitTime[0],
    time: nextSplitTime[1]
  }
}

export function generateCode(charNum: number) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (let i = 0; i < charNum; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

export function findInEnum<TEnum extends object>(
  needle: string,
  haystack: TEnum
) {
  const match = Object.keys(haystack).find((key) => key === needle)
  if (match) {
    return haystack[needle as keyof TEnum]
  }

  throw new Error(`Key ${needle} not found in enum`)
}

export function findValueInEnum<TEnum extends object>(
  needle: string,
  haystack: TEnum
): TEnum[keyof TEnum] {
  const match = Object.entries(haystack).find(
    ([item, value]) => item && value === needle
  )

  if (!match) {
    throw new Error(`Value ${needle} not found in enum`)
  }

  return needle as unknown as TEnum[keyof TEnum]
}

export function parseBoolean(a: string, defaultValue: boolean): boolean {
  if (a === undefined) {
    return defaultValue
  }
  return a === 'true'
}

export function capitalize(s: string) {
  return s.charAt(0).toLocaleUpperCase() + s.slice(1)
}

export function transformFormToAddress<T>(
  address: T & AddressInput
): T & AddressInput {
  return {
    ...address,
    country: findInEnum(address.country, CountryCode)
  }
}

export function getStringOrPlaceholder(s: string | undefined): string {
  return s || '...'
}
