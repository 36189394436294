import { gql } from '@apollo/client'

import {
  BASIC_PRODUCT_FRAGMENT,
  CATEGORY_FRAGMENT,
  COLLECTION_FRAGMENT,
  IMAGES_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  PRODUCT_FRAGMENT_FOR_PREVIEW,
  PRODUCT_PRICING_FRAGMENT,
  USER_FRAGMENT_FOR_PRODUCT_PREVIEW
} from '../fragments'
import { FEEDBACK_FRAGMENT } from '../fragments/feedback'

/**
 * TODO: Check need gross or net
 */

export const FEATURED_PRODUCTS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query FeaturedProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    shop {
      homepageCollection {
        id
        products(
          first: $first
          after: $after
          last: $last
          before: $before
          filter: $filter
        ) {
          totalCount
          pageInfo {
            ...PageInfoFragment
          }
          edges {
            cursor
            node {
              ...BasicProductFields
              ...ProductPricingField
              collection {
                ...CollectionFragment
              }
              category {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const TOP_PRODUCTS_QUERY = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query TopProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    topProducts(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const PREVIOSLY_SEEN_PRODUCTS_QUERY = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query PreviouslySeenProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    previouslySeenProducts(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...BasicProductFields
          ...ProductPricingField
          category {
            id
            name
          }
        }
      }
    }
  }
`

export const PRODUCT_BY_CATEGORY_ID = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query ProductsByCategoryId(
    $id: ID!
    $before: String
    $after: String
    $first: Int!
    $last: Int
  ) {
    category(id: $id) {
      id
      name
      products(first: $first, after: $after, last: $last, before: $before) {
        edges {
          cursor
          node {
            ...BasicProductFields
            ...ProductPricingField
            collection {
              ...CollectionFragment
            }
            category {
              id
              name
            }
          }
        }
        totalCount
      }
    }
  }
`

export const VENDOR_CATEGORIES = gql`
  query Categories(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: CategoryFilterInput
  ) {
    categories(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      edges {
        node {
          id
          name
          backgroundImage {
            url
            alt
          }
          children(first: 6) {
            edges {
              cursor
              node {
                id
                name
                backgroundImage {
                  url
                  alt
                }
                description
                slug
                level
              }
            }
            totalCount
          }
          topProducts(first: 6) {
            totalCount
            edges {
              node {
                category {
                  id
                  name
                }
              }
            }
          }
        }
        cursor
      }
      totalCount
    }
  }
`

export const PRODUCT_BY_ID = gql`
  ${CATEGORY_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${IMAGES_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${FEEDBACK_FRAGMENT}
  query ProductById($id: ID!) {
    product(id: $id) {
      category {
        ...CategoryFragment
        parent {
          ...CategoryFragment
          parent {
            ...CategoryFragment
          }
        }
        children(first: 6) {
          edges {
            node {
              ...CategoryFragment
              children(first: 6) {
                edges {
                  node {
                    ...CategoryFragment
                  }
                }
              }
            }
          }
        }
      }
      ...BasicProductFields
      ...ProductPricingField
      collection {
        ...CollectionFragment
      }
      recommendations {
        ...BasicProductFields
        ...ProductPricingField
      }
      description
      publicationDate
      vendor {
        id
        lastLogin
        firstName
        lastName
        email
        averageResponseTime
        status
        activity
      }
      images {
        ...Images
      }
      productType {
        id
        name
      }
      size {
        id
        name
      }
      feedbacks {
        ...FeedbackFragment
      }
      isAvailableForPurchase
    }
  }
`

export const PRODUCT_PREVIEW_BY_ID = gql`
  ${PRODUCT_FRAGMENT_FOR_PREVIEW}
  ${IMAGES_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${USER_FRAGMENT_FOR_PRODUCT_PREVIEW}
  ${FEEDBACK_FRAGMENT}
  query ProductById($id: ID!) {
    product(id: $id) {
      ...ProductFragmentForPreview
      ...ProductPricingField
      feedbacks {
        ...FeedbackFragment
      }
      description
      publicationDate
      vendor {
        ...UserFragmentForProductPreview
      }
      images {
        ...Images
      }
      productType {
        id
        name
      }
      isAvailableForPurchase
    }
  }
`

export const WISHLIST_ADD_PRODUCT = gql`
  mutation WishlistAddProduct($productId: ID!) {
    wishlistAddProduct(productId: $productId) {
      wishlistErrors {
        field
        message
      }
      wishlist {
        wishlist {
          id
          createdAt
          items(first: 20) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
      }
    }
  }
`

export const WISHLIST_REMOVE_PRODUCT = gql`
  mutation WishlistRemoveProduct($productId: ID!) {
    wishlistRemoveProduct(productId: $productId) {
      wishlistErrors {
        field
        message
      }
      wishlist {
        wishlist {
          id
          createdAt
          items(first: 20) {
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }
      }
    }
  }
`

export const WISHLIST = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query GetWishlist(
    $first: Int!
    $last: Int
    $after: String
    $before: String
    $search: String
    $productCategoryId: ID
    $productVendorId: ID
    $productIsSale: Boolean
  ) {
    me {
      wishlist(
        first: $first
        after: $after
        before: $before
        last: $last
        search: $search
        product_Category_Id: $productCategoryId
        product_Vendor_Id: $productVendorId
        product_IsSale: $productIsSale
      ) {
        totalCount
        edges {
          cursor
          node {
            id
            product {
              ...BasicProductFields
              ...ProductPricingField
              collection {
                ...CollectionFragment
              }
              category {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const RECOMMENDATION_PRODUCTS = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query RecommendationProducts(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: ProductFilterInput
  ) {
    recommendations(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...BasicProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            id
            name
          }
        }
      }
      totalCount
    }
  }
`

export const PRODUCTS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query Products(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: ProductOrder
    $filter: ProductFilterInput
  ) {
    products(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      filters
      edges {
        cursor
        node {
          ...BasicProductFields
          ...ProductPricingField
          collection {
            ...CollectionFragment
          }
          category {
            ...CategoryFragment
            parent {
              ...CategoryFragment
              parent {
                ...CategoryFragment
              }
            }
            children(first: 6) {
              edges {
                node {
                  ...CategoryFragment
                  children(first: 6) {
                    edges {
                      node {
                        ...CategoryFragment
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
    }
  }
`

export const PRODUCT_CREATE_MUTATION = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  mutation ProductCreate($input: ProductCreateInput!) {
    productCreate(input: $input) {
      productErrors {
        code
        message
      }
      product {
        ...BasicProductFields
        ...ProductPricingField
        collection {
          ...CollectionFragment
        }
        category {
          id
          name
        }
      }
    }
  }
`

export const PRODUCT_UPDATE_MUTATION = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  mutation ProductUpdate($id: ID!, $input: ProductInput!) {
    productUpdate(id: $id, input: $input) {
      productErrors {
        code
        message
      }
      product {
        ...BasicProductFields
        ...ProductPricingField
        collection {
          ...CollectionFragment
        }
        category {
          id
          name
        }
      }
    }
  }
`

export const PRODUCT_IMAGE_CREATE_MUTATION = gql`
  ${COLLECTION_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  mutation ProductImageCreate($input: ProductImageCreateInput!) {
    productImageCreate(input: $input) {
      product {
        ...BasicProductFields
        ...ProductPricingField
        collection {
          ...CollectionFragment
        }
        category {
          id
          name
        }
      }
    }
  }
`

export const SET_TOP_PRODUCTS_MUTATION = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  mutation SetTopProducts($isTop: Boolean!, $products: [ID]!) {
    setTopProducts(isTop: $isTop, products: $products) {
      products {
        ...BasicProductFields
      }
      errors {
        field
        message
      }
    }
  }
`

export const PRODUCT_IMAGE_BULK_DELETE_MUTATION = gql`
  mutation ProductImageBulkDelete($ids: [ID]!) {
    productImageBulkDelete(ids: $ids) {
      productErrors {
        code
        message
      }
    }
  }
`

export const PRODUCT_DUPLICATE_MUTATION = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  mutation ProductDuplicate($products: [ID]!) {
    productDuplicate(products: $products) {
      products {
        ...BasicProductFields
      }
      productErrors {
        code
        message
      }
      errors {
        field
        message
      }
    }
  }
`

export const PRODUCT_REMOVE_FROM_VENDOR_LIST_MUTATION = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  mutation productRemoveFromVendorList($products: [ID]!) {
    productRemoveFromVendorList(products: $products) {
      products {
        ...BasicProductFields
      }
      productErrors {
        code
        message
      }
      errors {
        field
        message
      }
    }
  }
`

export const PRODUCT_REMOVE_MUTATION = gql`
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  mutation ProductDelete($id: ID!) {
    productDelete(id: $id) {
      product {
        ...BasicProductFields
        ...ProductPricingField
      }
      productErrors {
        code
        message
      }
    }
  }
`
