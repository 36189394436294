import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  text: {
    marginLeft: 8
  },
  field: {
    marginLeft: 16
  },
  button: {
    marginLeft: 24
    // minWidth: 120
  },
  row: {
    flex: 2
  },
  secondRow: {
    flex: 0.3
  },
  rowField: {
    flex: 1
  },
  rowFieldLast: {
    flex: 2
  },
  email: {
    marginLeft: 40
  }
})
