import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { DatePickerStyleProps } from './date-picker.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  calendar: {
    bottom: 0,
    position: 'absolute',
    right: 0
  },
  container: {
    border: ({ focus }: DatePickerStyleProps) =>
      `${focus ? 2 : 1}px solid ${theme.colors[focus ? 'yellow' : 'inactive']}`,
    borderRadius: 4,
    padding: ({ focus }: DatePickerStyleProps) =>
      focus ? '3px 24px' : '4px 25px'
  },
  input: {
    marginTop: 4
  }
}))
