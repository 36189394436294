export const COLOR_FRAGMENT = `
  fragment ColorFragment on Color {
    code
    name
    id
  }
`

export const PRODUCT_COLOR_FRAGMENT = `
  ${COLOR_FRAGMENT}
  fragment ProductColorFragment on ProductColor {
    title
    description
    extraPrice
    extraProductionDays
    isDefault
    id
    color {
      ...ColorFragment
    }
  }
`

export const OFFER_COLOR_FRAGMENT = `
  ${COLOR_FRAGMENT}
  fragment OfferColorFragment on OfferColor {
    title
    description
    extraPrice
    extraProductionDays
    isDefault
    id
    color {
      ...ColorFragment
    }
  }
`
