import React, { FC, MouseEvent, useMemo, useState } from 'react'

import { DeclineOrderModal, getFormatedOrderDate } from '../../../pages/views'
import {
  OrderMainStatus,
  OrderUpdateVariables,
  PermissionEnum
} from '../../../services'

import { Button } from '../../button'
import { Row } from '../../row'
import { Text } from '../../text'
import { Column } from '../../column'
import { getFullName } from '../../helpers'
import { PermissionContent } from '../../permission-content'

import { OrderRow } from '../order-row'
import { OrderStaging } from '../order-staging'
import { OrderContactInfo } from '../order-contact-info'

import { OrderSliderDescriptionProps } from './order-slider-description.types'
import { useStyle } from './order-slider-description.styles'

export const OrderSliderDescription: FC<OrderSliderDescriptionProps> = ({
  isStaff,
  id,
  paymentStatus,
  mainStatus,
  remainingTimeProductionStarted,
  startDate,
  finishDate,
  trackingNumber,
  shippingAddress,
  vendor,
  number,
  price,
  onChange,
  onContactCustomer,
  onContactVendor
}) => {
  const [openDeclineModal, changeOpenDeclineModal] = useState(false)

  const classes = useStyle()
  const createdDate = getFormatedOrderDate(startDate)
  const finishedDate = getFormatedOrderDate(finishDate)
  const trackingNumberValue = trackingNumber || '–'

  const declineIsEnabled =
    !isStaff &&
    mainStatus !== OrderMainStatus.CANCELED_BY_CUSTOMER &&
    mainStatus !== OrderMainStatus.CANCELED_BY_VENDOR &&
    mainStatus !== OrderMainStatus.CANCELED_WITHOUT_REFUND &&
    mainStatus !== OrderMainStatus.REFUNDED &&
    mainStatus !== OrderMainStatus.PARTLY_REFUNDED

  const CustomerFullAddress = useMemo(() => {
    if (shippingAddress) {
      const { streetAddress1, postalCode, city } = shippingAddress
      return `${streetAddress1}, ${postalCode} ${city}`
    }

    return ''
  }, [shippingAddress])

  const CustomerFullName = useMemo(
    () => getFullName(shippingAddress),
    [shippingAddress]
  )

  const VendorFullName = useMemo(() => getFullName(vendor), [vendor])

  const handleOnChangeDeclineModal =
    (state: boolean) =>
    (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      changeOpenDeclineModal(state)
    }

  const handleOnSubmit = () => {
    const variables: OrderUpdateVariables = {
      id,
      input: {
        isCanceled: true
      }
    }

    if (onChange) {
      onChange(variables)
    }

    changeOpenDeclineModal(false)
  }

  return (
    <Column fullWidth>
      <OrderStaging
        id={id}
        paymentStatus={paymentStatus}
        mainStatus={mainStatus}
        remainingTimeProductionStarted={remainingTimeProductionStarted}
        onChange={onChange}
      />
      <OrderRow alignItems="center" justifyContent="space-between">
        <Row>
          <Row alignItems="flex-start">
            <Text
              color="inactive"
              preset="body"
              text="Start Date:"
              tx="order.item.startDate"
            />
            <Text
              className={classes.rowValue}
              color="black"
              preset="h7"
              text={createdDate}
            />
          </Row>
          <Row alignItems="flex-start" className={classes.rowField}>
            <Text
              color="inactive"
              preset="body"
              text="Finish Date:"
              tx="order.item.finishDate"
            />
            <Text
              className={classes.rowValue}
              color="black"
              preset="h7"
              text={finishedDate}
            />
          </Row>
          <Row alignItems="flex-start" className={classes.rowField}>
            <Text
              color="inactive"
              preset="body"
              text="Tracking Number:"
              tx="order.item.trackingNumber"
            />
            <Text
              className={classes.rowValue}
              color="black"
              preset="h7"
              text={trackingNumberValue}
            />
          </Row>
        </Row>
        {declineIsEnabled && (
          <Button
            color="darkBlue"
            className={classes.rowField}
            preset="secondaryBorderMini"
            textColor="darkBlue"
            textPreset="maxButton"
            text="Decline Order"
            tx="order.item.declineOrder"
            onClick={handleOnChangeDeclineModal(true)}
          />
        )}
      </OrderRow>
      {shippingAddress && (
        <OrderContactInfo
          type="customer"
          title="Customer Info:"
          titleTx="order.item.customerInfo"
          country={shippingAddress.country.code}
          fullAddress={CustomerFullAddress}
          fullName={CustomerFullName}
          phoneNumber={shippingAddress.phone}
          buttonTitle="Contact Customer"
          buttonTx="order.item.contactCustomer"
          onButtonClick={onContactCustomer}
        />
      )}
      <PermissionContent
        permissions={[
          PermissionEnum.ADD_CHANGE_REMOVE_ORDERS,
          PermissionEnum.VIEW_ORDER
        ]}
      >
        {vendor && (
          <OrderContactInfo
            type="vendor"
            title="Vendor Info:"
            titleTx="order.item.vendorInfo"
            country={vendor.country}
            companyName={vendor.companyName}
            email={vendor.email}
            city={vendor.city}
            fullName={VendorFullName}
            phoneNumber={vendor.phone}
            buttonTitle="Contact Maker"
            buttonTx="order.item.contact.maker"
            onButtonClick={onContactVendor}
          />
        )}
      </PermissionContent>

      <DeclineOrderModal
        open={openDeclineModal}
        orderNumber={number}
        totalPrice={price}
        paymentStatus={paymentStatus}
        onClose={handleOnChangeDeclineModal(false)}
        onSubmit={handleOnSubmit}
      />
    </Column>
  )
}
