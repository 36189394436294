import React, { ChangeEvent, FC, useState } from 'react'

import {
  Dropdown,
  DropdownItemData,
  QuestionModal,
  Row,
  Text,
  TextField,
  TextTranslate
} from '../../../components'
import { ProductCreateOptionInput, ProductOptionType } from '../../../services'

import { useStyle } from './option-modal.styles'
import { OptionModalProps } from './option-modal.types'

export const OptionModal: FC<OptionModalProps> = ({
  data,
  open,
  onClose,
  onSumbit,
  onChangeOpen
}) => {
  const classes = useStyle()
  const [option, changeOption] = useState<Partial<ProductCreateOptionInput>>()
  const [error, changeError] = useState<boolean>(false)

  const isDisabled = !option?.title || !option.type || error

  const dropdownData = [
    {
      id: '1',
      name: 'Single selection (allows You to choose one main variant/radio button)',
      nameTx: 'item.form.create.option.radio.button.type' as TextTranslate,
      value: ProductOptionType.RADIOBUTTON
    },
    {
      id: '2',
      name: 'Multi selection (allows You to choose several additional characteristics)',
      nameTx: 'item.form.create.option.checkbox.type' as TextTranslate,
      value: ProductOptionType.CHECKBOX
    }
  ]

  const handleOnChangeDropdown =
    (prop: keyof ProductCreateOptionInput) => (item: DropdownItemData) => {
      const { value: nextValue } = item

      if (changeOption) {
        changeOption({
          ...option,
          [prop]: String(nextValue)
        })
      }
    }

  const handleOnChange =
    (prop: keyof ProductCreateOptionInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      changeOption({
        ...option,
        [prop]: value
      })

      if (data) {
        const nextData = data.find((optionItem) => value === optionItem.title)
        if (nextData) {
          changeError(true)

          return error
        }

        changeError(false)
      }
    }

  const handleOnSubmit = () => {
    onSumbit(option as ProductCreateOptionInput)

    if (onChangeOpen) {
      onChangeOpen()
    }
  }

  if (!open) {
    return <></>
  }

  return (
    <>
      <QuestionModal
        disabled={isDisabled}
        title="Create Option"
        titleTx="item.form.create.option.title"
        successText="Apply"
        successTx="item.form.create.color.apply"
        onClose={onClose}
        onSubmit={handleOnSubmit}
      >
        <Text
          color="inactive"
          preset="body"
          text="Please fill in all fields."
          tx="item.form.create.color.description"
        />
        <TextField
          className={classes.field}
          maxLength={100}
          label="Title"
          labelTx="item.form.create.color.title.input"
          onChange={handleOnChange('title')}
        />
        {error && (
          <Row className={classes.error} fullWidth justifyContent="flex-start">
            <Text
              className={classes.errorText}
              text="An option with that name already exists!"
              tx="item.form.create.option.error"
              color="error"
              preset="secondTitle"
            />
          </Row>
        )}
        <Row fullWidth className={classes.dropdawnField}>
          <Dropdown
            className={classes.dropdown}
            textClassName={classes.dropdownText}
            data={dropdownData}
            preset="h5"
            label="Type of choosing option"
            labelTx="item.form.create.option.type"
            onChange={handleOnChangeDropdown('type')}
          />
        </Row>
      </QuestionModal>
    </>
  )
}
