import React, { useState, FC, useMemo } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { CabinetNavigatorPathVendor } from '../../cabinet-navigator'
import {
  arrayHasElements,
  Button,
  Checkbox,
  Column,
  Icon,
  ICONS,
  Row,
  Text,
  TextField,
  RadioBox,
  RadioItemWithIdProps,
  TablePagginationBar,
  Loader
} from '../../../components'
import { LightTheme } from '../../../components/Theme'

import { VendorBannerTableHeader } from './vendor-banner-table-header'
import { VendorBannerTableItem } from '../vendor-banner-table-item'
import { useStyle } from './vendor-banner-table.styles'

import { VendorBannerTableProps } from './vendor-banner-table.types'
import { activateVendorBanner } from './helpers'

const bannerStatusRadioData: RadioItemWithIdProps[] = [
  { id: '1', name: 'Active', nameTx: 'cabinet.banners.filters.active' },
  { id: '0', name: 'Inactive', nameTx: 'cabinet.banners.filters.inactive' }
]

export const VendorBannerTable: FC<VendorBannerTableProps> = ({
  className = '',
  data,
  loading,
  paginationState,
  rowCounts,
  filters,
  onRemove,
  onUpdate,
  onFilter,
  onChangePage,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount,
  onSearch
}) => {
  const history = useHistory()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [checkedIds, changeCheckedIds] = useState<string[]>([])
  const [activeStatus, changeActiveStatus] = useState('1')
  const [isBannerStatusChecked, changeIsBannerStatusChecked] = useState(false)

  const handleOnRemove = () => {
    changeCheckedIds([])
    if (onRemove) {
      onRemove(checkedIds)
    }
  }

  const handleOnPublish = (state: boolean) => () => {
    const publishData = data
      .filter((item) => checkedIds.includes(item.id))
      .map(activateVendorBanner(state))
    if (onUpdate && publishData.length) {
      onUpdate(publishData)
    }
  }

  const handleOnPick = (id: string, state: boolean) => {
    const filteredIds = checkedIds.filter((removeId) => removeId !== id)
    if (state) {
      changeCheckedIds([...filteredIds, id])
    } else {
      changeCheckedIds(filteredIds)
    }
  }

  const handleOnPickAll = (state: boolean) => {
    if (state) {
      const ids = data.map((product) => product.id)
      changeCheckedIds(ids)
    } else {
      changeCheckedIds([])
    }
  }

  const CheckedAll = useMemo(() => {
    return checkedIds.length === data.length
  }, [checkedIds, data])

  const getActive = (id: string) => {
    return Boolean(checkedIds.find((removeId) => removeId === id))
  }

  const handleOnClickItem = (id: string) => {
    history.push(`${CabinetNavigatorPathVendor.EDIT_VEDDOR_BANNER}/${id}`)
  }

  const handleOnClickFilterDropdown = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const handleOnBannerStatusCheck = () => {
    changeIsBannerStatusChecked(!isBannerStatusChecked)
    changeActiveStatus(bannerStatusRadioData[0].id)
    if (onFilter) {
      onFilter({ ...filters, isVisible: undefined })
    }
  }

  const handleStatusRadioChange = (nextValue: string) => {
    changeActiveStatus(nextValue)
    if (onFilter) {
      onFilter({ ...filters, isVisible: Boolean(Number(nextValue)) })
    }
  }

  const isBannerSattus = isBannerStatusChecked
    ? classes.iconFilterOpen
    : classes.iconFilter

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row
        fullWidth
        alignItems="flex-start"
        className={classes.tableTopContainer}
      >
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onSearch}
        />
        <Button
          className={classes.filter}
          color="yellow"
          preset="borderButton"
          text="FILTERS"
          tx="cabinet.banners.filters"
          textColor="yellow"
          textPreset="h5"
          onClick={handleOnClickFilterDropdown}
        />
        {isFilterOpen && (
          <Row className={classes.dropdownFilter}>
            <Row
              className={classes.dropdownFilterItem}
              justifyContent="flex-start"
              fullWidth
              onClick={handleOnBannerStatusCheck}
            >
              <Row>
                <Checkbox checked={isBannerStatusChecked} />
                <Text
                  color="black"
                  preset="body"
                  text="Status"
                  tx="cabinet.banners.filters.status"
                  className={classes.dropdownFilterItemText}
                />
              </Row>
              <Icon
                className={isBannerSattus}
                height={24}
                width={24}
                src={ICONS.keyboardArrowLeft}
              />
            </Row>
            {isBannerStatusChecked && (
              <Row
                fullWidth
                justifyContent="flex-start"
                className={classes.dropdownSubItem}
              >
                <RadioBox
                  active={activeStatus}
                  onChange={handleStatusRadioChange}
                  data={bannerStatusRadioData}
                />
              </Row>
            )}
          </Row>
        )}
      </Row>
      <Row fullWidth className={classes.header} justifyContent="space-between">
        <Row
          className={`${classes.tableColumn} ${classes.minTableColumn}`}
          justifyContent="flex-start"
        >
          <Checkbox checked={CheckedAll} onChange={handleOnPickAll} />
        </Row>
        {arrayHasElements(checkedIds) ? (
          <>
            <Button
              className={classes.button}
              preset="secondaryBorder"
              text="Activate"
              textPreset="maxButton"
              textColor="yellow"
              tx="cabinet.banners.table.activate.btn"
              onClick={handleOnPublish(true)}
            />
            <Button
              className={classes.button}
              preset="secondaryBorder"
              text="Deactivate"
              textPreset="maxButton"
              textColor="yellow"
              tx="cabinet.banners.table.deactivate.btn"
              onClick={handleOnPublish(false)}
            />
            <Row className={classes.remove} onClick={handleOnRemove}>
              <Icon src={ICONS.trash} />
            </Row>
          </>
        ) : (
          <VendorBannerTableHeader />
        )}
      </Row>
      {loading && <Loader />}
      {!loading &&
        data.map((vendorBanner) => (
          <VendorBannerTableItem
            key={`banner_${vendorBanner.id}`}
            {...vendorBanner}
            checked={getActive(vendorBanner.id)}
            onChange={handleOnPick}
            onClick={handleOnClickItem}
          />
        ))}
      {!loading && paginationState && (
        <TablePagginationBar
          paginationState={paginationState}
          rowCounts={rowCounts}
          onNextPage={onNextPage}
          onPrevioslyPage={onPrevioslyPage}
          onChangePage={onChangePage}
          onChangeRowCount={onChangeRowCount}
        />
      )}
    </Column>
  )
}
