import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../../column'
import { Text } from '../../text'
import { Button } from '../../button'
import { Row } from '../../row'
import { ModalBannerPreviewHeader } from '../modal-banner-preview-header'

import { LightTheme } from '../../Theme'
import { useStyle } from '../modal-banner-preview.styles'

import { PromotedStorePreviewProps } from './promoted-store-banner-preview.types'

export const PromotedStoreBanerPreview: FC<PromotedStorePreviewProps> = ({
  className,
  title,
  image,
  buttonText,
  mainHeading,
  notificationText,
  notificationBtnText,
  mainDescription,
  secondaryHeading,
  secondaryDescription,
  buttonPreset,
  buttonTextPreset,
  button,
  bannerMobileImage,
  active,
  isNotificationActive,
  isButtonActive,
  onClose,
  onOpenPreviewMobileBanner,
  onOpenPreviewDesktopBanner
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ theme })

  const isButton = isButtonActive && button && buttonText

  return (
    <Column className={classes.container}>
      <ModalBannerPreviewHeader
        active={active}
        onClose={onClose}
        onOpenPreviewMobileBanner={onOpenPreviewMobileBanner}
        onOpenPreviewDesktopBanner={onOpenPreviewDesktopBanner}
      />
      {active ? (
        <Column className={classes.mobileContainer}>
          {isNotificationActive && (
            <Row
              className={`${classes.notification} ${classes.notificationMob}`}
              justifyContent="space-between"
              fullWidth
            >
              {notificationText && (
                <Text
                  className={classes.notificationText}
                  text={notificationText}
                  preset="title"
                  color="white"
                />
              )}
              {notificationBtnText && (
                <Button
                  className={`${classes.button} ${classes.notificationBtn}`}
                  preset="button2"
                  text={notificationBtnText}
                  textPreset="maxButton"
                  color="white"
                />
              )}
            </Row>
          )}
          <Column fullHeight>
            {bannerMobileImage && (
              <img className={classes.image} src={bannerMobileImage}></img>
            )}
            <Column
              className={`${classes.mobileModal} ${classes.mobileModalView}`}
              fullWidth
              alignItems="flex-start"
              justifyContent="center"
            >
              {title && <Text text={title} preset="h4" color="blue" />}
              {mainHeading && (
                <Text
                  className={classes.text}
                  text={mainHeading}
                  preset="secondBanner"
                />
              )}
              {mainDescription && (
                <Text
                  className={classes.text}
                  text={mainDescription}
                  preset="subscriptionText"
                  color="inactive"
                />
              )}
              {secondaryHeading && (
                <Text
                  className={classes.secondaryHeadingText}
                  text={secondaryHeading}
                  preset="h5"
                />
              )}
              {secondaryDescription && (
                <Text
                  className={classes.text}
                  text={secondaryDescription}
                  preset="h6"
                  color="inactive"
                />
              )}
              {isButton && (
                <Button
                  className={classes.button}
                  preset={buttonPreset}
                  text={buttonText}
                  textPreset={buttonTextPreset}
                  textColor="white"
                />
              )}
            </Column>
          </Column>
        </Column>
      ) : (
        <Column>
          {isNotificationActive && (
            <Row
              className={classes.notification}
              justifyContent="space-between"
              fullWidth
            >
              {notificationText && (
                <Text text={notificationText} preset="title" color="white" />
              )}
              {notificationBtnText && (
                <Button
                  className={`${classes.button} ${classes.notificationBtn}`}
                  preset="button2"
                  text={notificationBtnText}
                  textPreset="maxButton"
                  color="white"
                />
              )}
            </Row>
          )}
          <Column
            className={`${classes.banerContainer} ${classes.promotedBannerContainer}`}
          >
            {image && <img className={classes.image} src={image}></img>}
            <Column
              className={`${className} ${classes.modal} ${classes.modalMaxWidth}`}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              {title && <Text text={title} preset="h4" color="blue" />}
              {mainHeading && (
                <Text
                  className={classes.text}
                  text={mainHeading}
                  preset="secondBanner"
                />
              )}
              {mainDescription && (
                <Text
                  className={classes.text}
                  text={mainDescription}
                  preset="subscriptionText"
                  color="inactive"
                />
              )}
              {secondaryHeading && (
                <Text
                  className={classes.secondaryHeadingText}
                  text={secondaryHeading}
                  preset="h5"
                />
              )}
              {secondaryDescription && (
                <Text
                  className={classes.text}
                  text={secondaryDescription}
                  preset="h6"
                  color="inactive"
                />
              )}
              {isButton && (
                <Button
                  className={classes.button}
                  preset={buttonPreset}
                  text={buttonText}
                  textPreset={buttonTextPreset}
                  textColor="white"
                />
              )}
            </Column>
          </Column>
        </Column>
      )}
    </Column>
  )
}
