import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    alignSelf: 'stretch',
    width: 476,
    border: `1px solid ${theme.colors.shadow}`
  },
  productContainer: {
    padding: '16px 24px',
    width: '100%',
    maxHeight: 688,
    overflowY: 'auto'
  },
  avatar: {
    objectFit: 'cover',
    height: 428,
    width: 428
  },
  offerName: {
    marginTop: 16
  },
  options: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '16px 0'
  },
  buttonContainer: {
    marginTop: 16
  }
}))
