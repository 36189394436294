import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { SubscriptionStyleProps } from './subscription-plan.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  price: {
    height: 240,
    width: 240,
    background: ({ selected }: SubscriptionStyleProps) =>
      selected ? theme.colors.blue : theme.colors.white,
    boxShadow: `4px 8px 16px ${theme.colors.shadow}`,
    borderRadius: 16,
    marginLeft: 38,
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.lightBlue,
      '& > span': {
        color: theme.colors.black
      }
    },
    '& > span': {
      color: ({ selected }: SubscriptionStyleProps) =>
        selected ? theme.colors.white : theme.colors.black
    }
  }
}))
