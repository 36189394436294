import { BASIC_PRODUCT_FRAGMENT } from './product'
import {
  ORDER_FEEDBACK_FRAGMENT,
  ORDER_USER_FRAGMENT,
  ORDER_VENDOR_FRAGMENT
} from './order'

export const FEEDBACK_FRAGMENT = `
${ORDER_USER_FRAGMENT}
${ORDER_VENDOR_FRAGMENT}
${BASIC_PRODUCT_FRAGMENT}
${ORDER_FEEDBACK_FRAGMENT}
  fragment FeedbackFragment on Feedback {
    id
    createdAt
    vendor {
      ...OrderVendorFragment
    }
    user {
      ...OrderUserFragment
    }
    product {
      ...BasicProductFields
    }
    order {
      ...OrderFeedbackFragment
    }
    comment
    answer
    rating
    type
  }
`
