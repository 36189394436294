import React, { ChangeEvent, FC, useState } from 'react'

import {
  Button,
  Dropdown,
  DropdownItemData,
  Icon,
  ICONS,
  QuestionModal,
  Text,
  TextArea,
  TextField
} from '../../../components'
import { ProductOptionVariantInput } from '../../../services'

import { useStyle } from './option-item-modal.styles'
import { OptionItemModalProps } from './option-item-modal.types'

export const OptionItemModal: FC<OptionItemModalProps> = ({
  onSubmit,
  data,
  descriptionPlaceholder,
  title
}) => {
  const classes = useStyle()
  const [open, changeClose] = useState(false)
  const defaultOptionInput = {
    title: '',
    description: '',
    extraPrice: 0,
    extraProductionDays: 0
  }
  const [option, changeOption] =
    useState<Partial<ProductOptionVariantInput>>(defaultOptionInput)

  const isDisabled =
    !option?.description ||
    !option.title ||
    !option.extraPrice ||
    option.extraPrice < 0 ||
    !option.extraProductionDays

  const handleOnChange =
    (prop: keyof ProductOptionVariantInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      changeOption({
        ...option,
        [prop]: value
      })
    }

  const handleOnChangeDropdown =
    (prop: keyof ProductOptionVariantInput) =>
    (dropdownItem: DropdownItemData) => {
      const { name: nextValue } = dropdownItem
      changeOption({
        ...option,
        [prop]: String(nextValue)
      })
    }

  const handleOnOpen = (state: boolean) => () => {
    changeClose(state)
  }

  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    if (onSubmit) {
      onSubmit(option as ProductOptionVariantInput)
    }
    changeOption(defaultOptionInput)
    close()
  }

  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />
  const titleValue = { title }
  const fullTitle = title
    ? `Create ${title} Variant`
    : 'Create Material Variant'
  const fullTitleTx = title
    ? 'item.form.create.option.variant.title'
    : 'item.form.create.material.title'

  const isMaterialPlaceholder = data ? descriptionPlaceholder : undefined

  return (
    <>
      <Button
        className={classes.button}
        color="yellow"
        preset="borderButton"
        textColor="yellow"
        textPreset="h5"
        text="ADD VARIANT"
        tx="item.form.create.variant"
        onClick={handleOnOpen(true)}
      />
      {open && (
        <QuestionModal
          disabled={isDisabled}
          title={fullTitle}
          titleTx={fullTitleTx}
          successText="Apply"
          successTx="item.form.create.material.apply"
          value={titleValue}
          onClose={handleOnOpen(false)}
          onSubmit={handleOnSubmit}
        >
          <Text
            color="inactive"
            preset="body"
            text="Please fill in all fields."
            tx="item.form.create.material.description"
          />
          {data ? (
            <Dropdown
              className={classes.dropdown}
              data={data}
              preset="h5"
              label="Material"
              labelTx="item.form.create.material"
              onChange={handleOnChangeDropdown('title')}
            />
          ) : (
            <TextField
              className={classes.field}
              maxLength={100}
              label="Title"
              labelTx="item.form.create.material.title.input"
              onChange={handleOnChange('title')}
            />
          )}
          <TextArea
            className={classes.field}
            preset="border"
            maxLength={500}
            placeholder={isMaterialPlaceholder}
            label="Description"
            labelTx="item.form.create.material.description"
            onChange={handleOnChange('description')}
          />
          <TextField
            className={classes.field}
            label="Extra Price"
            labelTx="item.form.create.material.price.input"
            type="number"
            rightElement={dollarIcon}
            onChange={handleOnChange('extraPrice')}
          />
          <TextField
            className={classes.field}
            label="Extra Production Days"
            labelTx="item.form.create.material.days.input"
            onChange={handleOnChange('extraProductionDays')}
          />
        </QuestionModal>
      )}
    </>
  )
}
