import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '16px 8px',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  dot: {
    marginLeft: 16
  }
}))
