import React, { useEffect } from 'react'

import { CabinetNavigatorPathAdmin } from '../cabinet-navigator'
import { useNavigator } from '../../hooks'
import { useApi } from '../../providers'
import { CreateBannerInput } from '../../services'

import { BannerForm } from '../banner-form'

export const CreateBannerPage = () => {
  const { menu } = useApi()
  const { response, onSubmit } = menu.useCreateBanner()
  const navigate = useNavigator()

  useEffect(() => {
    if (response.data && response.data.bannerCreate.banner) {
      navigate(CabinetNavigatorPathAdmin.BANNERS)
    }
  }, [response.data])

  const handleOnSubmit = (nextBanner: CreateBannerInput) => {
    if (nextBanner && nextBanner.image) {
      onSubmit(nextBanner)
    }
  }

  return (
    <BannerForm
      title="Create Banner"
      titleTx="banner.create"
      onSumbit={handleOnSubmit}
    />
  )
}
