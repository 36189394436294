import React, { FC, useMemo } from 'react'

import { getItemsFromEdges, ProductCreateColorInput } from '../../../services'
import { useApi } from '../../../providers'
import { arrayHasElements, Section } from '../../../components'

import { ColorItemModal, ColorsTable } from '../../views'

import { EmptySection } from '../empty-section'
import { getNextColors } from '../helpers'

import { useStyle } from './color-section.styles'
import { ColorSectionProps } from './color-section.types'

export const ColorSection: FC<ColorSectionProps> = ({
  value,
  descriptionPlaceholder,
  instruction,
  error,
  onChangeValue
}) => {
  const classes = useStyle()
  const { color: colorApi } = useApi()

  const isColorError = !arrayHasElements(value.colors) && error
  const emptySectionTextColor = isColorError ? 'error' : 'inactive'

  const { data: colors } = colorApi.useColors()

  const Colors = useMemo(() => {
    if (colors) {
      return getItemsFromEdges(colors.colors)
    }

    return []
  }, [colors])

  const ColorsTableData = useMemo(() => {
    if (value.colors) {
      return value.colors.map((prevColor) => {
        const currentColor = Colors.find(
          (color) => color.id === prevColor.color
        )

        return {
          ...prevColor,
          color: currentColor?.id || '',
          code: currentColor?.code || '#fff'
        }
      })
    }

    return []
  }, [value.colors, Colors])

  const handleOnChangeColorTable = (colorsValue: ProductCreateColorInput[]) => {
    if (onChangeValue) {
      onChangeValue({
        ...value,
        colors: colorsValue
      })
    }
  }

  const handleOnSubmitColor = (color: ProductCreateColorInput) => {
    const nextColors = getNextColors(color, value.colors)

    if (onChangeValue) {
      onChangeValue({
        ...value,
        colors: nextColors
      })
    }
  }

  const colorItemModal = (
    <ColorItemModal
      colors={Colors}
      onSumbit={handleOnSubmitColor}
      descriptionPlaceholder={descriptionPlaceholder}
    />
  )

  return (
    <Section
      isInfo
      className={classes.section}
      rightButton={colorItemModal}
      infoText={instruction}
      title="Color"
      titleTx="item.form.color.title"
    >
      {value.colors && arrayHasElements(value.colors) ? (
        <ColorsTable
          data={ColorsTableData}
          colors={Colors}
          onChange={handleOnChangeColorTable}
        />
      ) : (
        <EmptySection
          className={classes.sectionAdditionWithText}
          color={emptySectionTextColor}
          text="Add at least one option."
          tx="item.form.no.variant"
        />
      )}
    </Section>
  )
}
