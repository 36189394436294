import React, { FC } from 'react'

import { Column, Row, Text } from '../../../../components'

import { getPriceWithSymbol } from '../helpers'

import { useStyle } from './observe-options-variant.styles'
import { ObserveOptionVariantProps } from './observe-options-variant.types'

export const ObserveOptionVariant: FC<ObserveOptionVariantProps> = ({
  data,
  title,
  titleTx,
  currency
}) => {
  const classes = useStyle()

  return (
    <Column
      fullWidth
      className={classes.offersRow}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
        <Row fullWidth className={classes.name} justifyContent="space-between">
          <Text color="inactive" text={title} tx={titleTx} preset="body" />
        </Row>
        <Column fullWidth>
          {data.map((item) => (
            <Column
              fullWidth
              key={`variant_${item.id}`}
              className={classes.value}
              alignItems="flex-start"
            >
              <Row fullWidth justifyContent="space-between">
                <Text text={item.title || '-'} preset="h7" />
                <Row className={classes.textRow} justifyContent="space-between">
                  <Text
                    className={classes.textDays}
                    text={String(item.extraProductionDays)}
                  />
                  <Text
                    text={getPriceWithSymbol(item.extraPrice, currency)}
                    preset="body"
                  />
                </Row>
              </Row>
              <Text
                className={classes.textDescription}
                text={item.description || '-'}
                preset="title"
              />
            </Column>
          ))}
        </Column>
      </Row>
    </Column>
  )
}
