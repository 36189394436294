export const ADMIN_USER_FRAGMENT = `
  fragment AdminUserFragment on User {
    id
    firstName
    email
    avatar {
      url
      alt
    }
  }
`
