import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 20
  },
  titleContainer: {
    marginTop: 24
  },
  general: {
    width: '100%',
    padding: '16px 0',
    borderTop: `1px solid ${theme.colors.shadow}`,
    marginTop: 16
  },
  subtitleText: {
    width: 200
  },
  card: {
    cursor: 'pointer',
    width: 362,
    height: 238,
    marginLeft: 228,
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    '&>span': {
      textAlign: 'center'
    }
  },
  icon: {
    width: 72,
    height: 72,
    '& > path': {
      fill: theme.colors.yellow
    }
  }
}))
