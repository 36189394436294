import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  contsiner: {
    padding: '24px 16px'
  },
  table: {
    marginTop: 24
  }
})
