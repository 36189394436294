import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useApi } from '../../providers'
import { Loader } from '../../components'
import {
  OfferCreateInput,
  OfferItemInput,
  OfferStatus,
  ProductCreateColorInput,
  ProductCreateMaterialInput,
  ProductCreateOptionInput,
  ProductOptionVariantInput
} from '../../services'
import { useUser } from '../../hooks'

import { OfferForm } from '../offer-form'

import { OfferEditItemParams } from './offer-edit-item.types'

export const OfferEditItemPage = () => {
  const { offer: offerApi } = useApi()
  const { onUpdateOfferTotalCount } = useUser()
  const { id } = useParams<OfferEditItemParams>()
  const { data } = offerApi.useOfferById({ id })
  const { response, onSubmit: onSubmitProduct } = offerApi.useOfferUpdate()
  const { onSubmit: onRemove, response: removedOffer } =
    offerApi.useOfferRemove()
  const history = useHistory()
  const [currentStatus, changeSatus] = useState<String | undefined>('')
  const isDraftOrder = currentStatus === OfferStatus.DRAFT

  useEffect(() => {
    if (response.data || removedOffer.data) {
      history.goBack()
    }
  }, [response.data, removedOffer.data])

  useEffect(() => {
    const isDraftOrdereUpdate =
      isDraftOrder &&
      response.data?.offerUpdate.offer.status === OfferStatus.NEW

    const isDraftOrderDelete =
      removedOffer.data?.offerDelete.offer.status === OfferStatus.DRAFT

    if (isDraftOrdereUpdate || isDraftOrderDelete) {
      if (onUpdateOfferTotalCount) {
        onUpdateOfferTotalCount(-1)
      }
    }
  }, [
    response.data?.offerUpdate.offer.status,
    removedOffer.data?.offerDelete.offer.status,
    isDraftOrder
  ])

  const handleOnSubmit = (nextProduct: OfferCreateInput) => {
    if (nextProduct) {
      onSubmitProduct({ id, input: nextProduct })
    }
  }

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove({ id })
    }
  }

  const handleOnCurrentStatus = (name?: string) => {
    changeSatus(name)
  }

  const Item = useMemo<OfferCreateInput | null>(() => {
    if (data) {
      const {
        customer,
        product,
        totalPrice,
        discount,
        quantity,
        noSuggestion,
        status,
        description,
        productionDays,
        options: defaultOptions = [],
        productColors: defaultColors = [],
        productMaterials: dafaultMaterials = [],
        productOptions: defaultProductOptions = [],
        items: defaulOfferItems = []
      } = data.offer

      const items = defaulOfferItems.map<OfferItemInput>((itemVariants) => ({
        quantity: itemVariants.quantity,
        totalItemPrice: itemVariants.totalItemPrice,
        productColors: itemVariants.productColors.map((color) => ({
          ...color,
          color: color.color.id,
          code: color.color.code
        })),
        productMaterials: itemVariants.productMaterials,
        productOptions: itemVariants.productOptions,
        options: itemVariants.options
      }))

      const productColors = defaultColors.map<ProductCreateColorInput>(
        (color) => ({
          title: color.title,
          description: color.description,
          extraPrice: color.extraPrice,
          extraProductionDays: color.extraProductionDays,
          color: color.color.id,
          code: color.color.code,
          isDefault: color.isDefault
        })
      )

      const productMaterials = dafaultMaterials.map<ProductCreateMaterialInput>(
        (material) => ({
          title: material.title,
          description: material.description,
          extraPrice: material.extraPrice,
          extraProductionDays: material.extraProductionDays,
          isDefault: material.isDefault
        })
      )

      const productOptions =
        defaultProductOptions.map<ProductCreateOptionInput>(
          (productOption) => ({
            title: productOption.title,
            type: productOption.type,
            variants: productOption.variants
          })
        )

      const options = defaultOptions.map<ProductOptionVariantInput>(
        (additionalOption) => ({
          title: additionalOption.title,
          description: additionalOption.description,
          extraPrice: additionalOption.extraPrice,
          extraProductionDays: additionalOption.extraProductionDays,
          isDefault: additionalOption.isDefault
        })
      )

      return {
        description,
        totalPrice,
        customer: customer.id,
        product: product.id,
        discount,
        quantity,
        noSuggestion,
        status,
        productionDays,
        options,
        productColors,
        productMaterials,
        productOptions,
        items
      }
    }
    return null
  }, [data])

  if (!data || !Item) {
    return <Loader />
  }

  return (
    <OfferForm
      id={id}
      title={data.offer.product.name}
      defaultPrice={data.offer.product.defaultPrice}
      item={Item}
      number={data.offer.number}
      discount={data.offer.discount}
      noSuggestion={data.offer.noSuggestion}
      product={data.offer.product}
      currency={data.offer.product.currency}
      onSubmit={handleOnSubmit}
      onRemove={handleOnRemove}
      onCurrentStatus={handleOnCurrentStatus}
    />
  )
}
