import React, { FC, useMemo, useState } from 'react'

import { Column, MenuSlider, NumberSwitcher, Text } from '../../../components'
import {
  useDefaultOfferItemColor,
  useDefaultOfferItemMaterial
} from '../../../hooks'

import {
  getAllProductOptionsPrice,
  getDefaultOfferItemOption,
  getDefaultOfferItemPrice,
  getOfferItemOptionPrice,
  getPriceWithQuantity
} from '../helpers'
import { useStyle } from '../offer-form.styles'
import { OptionsSummaryRow } from '../options-summary-row'
import { SummaryRow } from '../summary-row'

import { SummaryVariantSliderProps } from './summary-variant-slider.types'

export const SummaryVariantSlider: FC<SummaryVariantSliderProps> = ({
  title,
  tittleTx,
  titleValue,
  defaultProductPrice,
  currency,
  itemVariant,
  onChangeValue,
  onGetVariantPrice
}) => {
  const classes = useStyle()
  const [quantity, changeQuantity] = useState<number>(itemVariant.quantity)

  const ItemValue = useMemo(() => {
    return { ...itemVariant, quantity }
  }, [quantity, itemVariant])

  const isDefaultProductPrice = defaultProductPrice || 0
  const defaulOfferItemsPrice = isDefaultProductPrice * itemVariant.quantity
  const defaultProductMaterial = useDefaultOfferItemMaterial(itemVariant)
  const defaulProductColor = useDefaultOfferItemColor(itemVariant)
  const allProductOptions = getDefaultOfferItemOption(itemVariant)

  const materialPrice = getPriceWithQuantity(
    defaultProductMaterial?.extraPrice,
    itemVariant.quantity
  )

  const colorPrice = getPriceWithQuantity(
    defaulProductColor?.extraPrice,
    itemVariant.quantity
  )

  const offerOptionsPrice = getPriceWithQuantity(
    getOfferItemOptionPrice(itemVariant),
    itemVariant.quantity
  )

  const allProductOptionsPrice = getPriceWithQuantity(
    getAllProductOptionsPrice(allProductOptions),
    itemVariant.quantity
  )

  const defaultOfferPrice = getDefaultOfferItemPrice(
    defaulOfferItemsPrice,
    materialPrice,
    colorPrice,
    allProductOptionsPrice,
    offerOptionsPrice
  )

  if (onGetVariantPrice) {
    onGetVariantPrice(defaultOfferPrice)
  }

  const handleOnSwitcherChange = (quantityValue: number) => {
    const nextItem = {
      ...ItemValue,
      quantity: quantityValue
    }

    changeQuantity(quantityValue)
    if (onChangeValue) {
      onChangeValue(nextItem)
    }
  }

  const header = (
    <Column alignItems="flex-start">
      <Text preset="h3" text={title} tx={tittleTx} values={titleValue} />
    </Column>
  )

  return (
    <MenuSlider
      className={classes.section}
      contentClassName={classes.sliderContent}
      defaultOpen={false}
      header={header}
    >
      <Column
        className={classes.sectionAdditionContainer}
        alignItems="flex-start"
        fullWidth
      >
        <SummaryRow
          title="Subtotal"
          titleTx="offer.form.summary.subtotal"
          price={defaulOfferItemsPrice}
          currency={currency}
        />
        <SummaryRow
          title={defaultProductMaterial?.title}
          price={materialPrice}
          currency={currency}
        />
        <SummaryRow
          title={defaulProductColor?.title}
          price={colorPrice}
          dotColor={defaulProductColor?.code}
          currency={currency}
        />
        <OptionsSummaryRow
          options={allProductOptions}
          quantity={quantity}
          currency={currency}
        />
        <OptionsSummaryRow
          options={itemVariant.options}
          quantity={quantity}
          currency={currency}
        />
      </Column>
      <Column
        className={classes.sectionAdditionContainer}
        alignItems="flex-start"
        fullWidth
      >
        <NumberSwitcher
          className={classes.switcher}
          defaultValue={quantity}
          label="Quantity"
          labelTx="offer.summary.section.quantity"
          preset="body"
          onChange={handleOnSwitcherChange}
        />
        <SummaryRow
          large={true}
          title="Price:"
          titleTx="offer.form.summary.price"
          price={defaultOfferPrice}
          currency={currency}
        />
      </Column>
    </MenuSlider>
  )
}
