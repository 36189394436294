import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import { ChangePasswordModalStyleProps } from './change-password-modal.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  attention: {
    marginTop: 24,
    opacity: ({ error }: ChangePasswordModalStyleProps) => (error ? 1 : 0),
    display: ({ error }: ChangePasswordModalStyleProps) =>
      error ? 'flex' : 'none',
    textDecorationColor: theme.colors.error,
    width: '100%'
  },
  bottomContainer: {
    padding: 16
  },
  input: {
    flex: 1,
    marginTop: 24
  },
  button: {
    width: 168,
    marginLeft: 24
  },
  buttonsRow: {
    marginTop: 24,
    padding: 24
  }
}))
