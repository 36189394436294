import React, { FC } from 'react'

import {
  Column,
  isError,
  Section,
  TextArea,
  TextField
} from '../../../components'

import { useStyle } from './general-info-section.styles'
import { GeneralInfoProps } from './general-info-section.types'

export const GeneralInfoSection: FC<GeneralInfoProps> = ({
  name,
  error,
  description,
  instruction,
  onChangeInputValue
}) => {
  const classes = useStyle()

  return (
    <Section
      isInfo
      infoText={instruction}
      title="General Information"
      titleTx="item.form.general.title"
    >
      <Column className={classes.sectionAdditionContainer}>
        <TextField
          isError={isError(name, error)}
          defaultValue={name}
          preset="border"
          maxLength={100}
          label="Name"
          labelTx="item.form.general.name"
          onChange={onChangeInputValue('name')}
        />
        <TextArea
          className={classes.area}
          error={isError(description, error)}
          defaultValue={description}
          preset="border"
          maxLength={1000}
          label="Description"
          labelTx="item.form.general.description"
          onChange={onChangeInputValue('description')}
        />
      </Column>
    </Section>
  )
}
