import React, {
  FC,
  useState,
  MouseEvent,
  ChangeEvent,
  useCallback,
  useMemo,
  KeyboardEventHandler,
  useRef,
  useEffect
} from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../Theme/theme'
import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import {
  DropdownItemData,
  DropdownItems,
  PositionDropdown,
  SearchableDropdownItems
} from '../dropdown-items'
import { getItemById } from '../helpers'
import { RightCaret } from './right-caret'

import { DropdownProps } from './dropdown.types'
import { useStyle } from './dropdown.styles'
import { TextField } from '../text-field'

export const Dropdown: FC<DropdownProps> = ({
  className = '',
  containerClassName = '',
  textClassName = '',
  custom,
  label,
  labelTx,
  active,
  preset,
  rightIconName,
  data,
  defaultValue,
  disabled = false,
  withSearch = true,
  error,
  onChange
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [value, changeValue] = useState(defaultValue)
  const [open, changeOpen] = useState<boolean>(false)
  const [list, changeList] = useState<DropdownItemData[]>(data)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ open, custom, disabled, value, theme, error })

  const disabledInputClass = disabled ? classes.disabled : ''
  const disabledLabelClass = disabled ? classes.disabledLabel : ''

  useEffect(() => {
    changeList(data)
  }, [data])

  const handleOnPropagationBackground = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
  }

  const handleOnChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: inputText } = event.target

    if (inputText) {
      const filteredList = data.filter((item) => {
        const formatedString = inputText.toLowerCase()
        return item.name.toString().toLowerCase().startsWith(formatedString)
      })

      changeList(filteredList)
    }
    if (!inputText) {
      changeList(data)
    }
  }

  const Value = useMemo(() => {
    const currentValue = getItemById(data, value)

    if (currentValue) {
      return currentValue
    }

    return {
      id: '',
      name: '',
      value: ''
    }
  }, [data, value])

  const handleOnClickInput = useCallback(() => {
    changeList(data)
  }, [data])

  const handleOnChange = (item: DropdownItemData) => {
    changeValue(item.id)
    if (onChange) {
      onChange(item)
    }
  }

  const handleOnOpen = useCallback(
    (state: boolean) => {
      if (!disabled) {
        changeOpen(state)
      }
    },
    [disabled]
  )

  const handleOnCaretClick = () => {
    handleOnOpen(false)
  }

  const handleOnKeyPress: KeyboardEventHandler<HTMLDivElement> = (event) => {
    const { code } = event
    if (inputRef.current && code !== 'Enter' && code !== 'Tab') {
      inputRef.current.focus()
    }
  }
  return (
    <Column fullWidth onKeyDown={handleOnKeyPress}>
      {!open || !withSearch ? (
        <DropdownItems
          className={`${disabledInputClass} ${className} ${classes.dropdownItems}`}
          containerClassName={containerClassName}
          isScrollable
          disabled={!disabled}
          active={active}
          data={data}
          position={PositionDropdown.BOTTOM}
          onOpen={handleOnOpen}
          onChange={handleOnChange}
        >
          <Row
            className={classes.container}
            fullWidth
            justifyContent="space-between"
          >
            <Column alignItems="flex-start">
              {label && (
                <Text
                  className={`${disabledLabelClass} ${classes.label}`}
                  text={label}
                  tx={labelTx}
                  color="darkBlue"
                  preset="subscriptionText"
                />
              )}
              {value && (
                <Text
                  className={textClassName}
                  text={Value.name}
                  tx={Value.nameTx}
                  preset={preset}
                />
              )}
            </Column>
            <RightCaret
              open={open}
              custom={custom}
              iconName={rightIconName}
              onClick={handleOnCaretClick}
            />
          </Row>
        </DropdownItems>
      ) : (
        <SearchableDropdownItems
          isScrollable
          open
          active={active}
          className={`${disabledInputClass} ${className} ${classes.dropdownItems}`}
          containerClassName={containerClassName}
          disabled={!disabled}
          data={list}
          position={PositionDropdown.BOTTOM}
          onOpen={handleOnOpen}
          onChange={handleOnChange}
        >
          <Row fullWidth onClick={handleOnPropagationBackground}>
            {label && (
              <TextField
                className={classes.input}
                label={label}
                defaultValue={Value?.name || ''}
                preset="border"
                type="text"
                ref={inputRef}
                onChange={handleOnChangeInput}
                onClick={handleOnClickInput}
              />
            )}
          </Row>
        </SearchableDropdownItems>
      )}
    </Column>
  )
}
