import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { TransitionGroup } from 'react-transition-group'

import {
  INotification,
  ITimer,
  MessageContext,
  MessageManagerTemplate
} from '.'
import Container from './Container'
import Transition from './Transition'

export const MessageManagerProvider: FC = ({ children }) => {
  const root = useRef<HTMLElement>(null)
  const timersArr = useRef<ITimer[]>([])
  const [notifications, setNotifications] = useState<INotification[]>([])

  useEffect(() => {
    // @ts-ignore
    root.current = document.createElement('div')
    root.current.id = '__message-manager__'
    document.body.appendChild(root.current)
    const timersArrRef = timersArr.current

    return () => {
      timersArrRef.forEach((timer) => clearTimeout(timer.timeoutId))
      if (root.current) {
        document.body.removeChild(root.current)
      }
    }
  }, [])

  const timerCallback = (notification: INotification) => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    remove(notification.id)
    timersArr.current = timersArr.current.filter(
      (timer) => timer.id !== notification.id
    )
  }

  const remove = useCallback((notificationId) => {
    const handleOnSetNotification = (currentNotifications: INotification[]) =>
      currentNotifications.filter((n) => n.id !== notificationId)

    setNotifications(handleOnSetNotification)
  }, [])

  const show = useCallback((message = {}, timeout = 3000) => {
    const id = Date.now()
    const notification = {
      close: () => remove(id),
      id,
      message,
      timeout
    }
    if (timeout !== null) {
      const timeoutId = window.setTimeout(() => {
        timerCallback(notification)
      }, timeout)

      timersArr.current.push({
        id: notification.id,
        notification,
        remaining: timeout,
        start: new Date().getTime(),
        timeoutId
      })
    }

    setNotifications((state) => [notification, ...state])

    return notification
  }, [])

  const getCurrentTimer = (notification: INotification) => {
    const currentTimerIndex = timersArr.current.findIndex(
      (timer) => timer.id === notification.id
    )
    return timersArr.current[currentTimerIndex]
  }

  const pauseTimer = (notification: INotification) => {
    const currentTimer = getCurrentTimer(notification)
    if (currentTimer) {
      const remainingTime = new Date().getTime() - currentTimer.start
      const remainingTimeFromTimer = currentTimer.remaining - remainingTime
      currentTimer.remaining = remainingTimeFromTimer
      window.clearTimeout(currentTimer.timeoutId)
    }
  }
  const resumeTimer = (notification: INotification) => {
    const currentTimer = getCurrentTimer(notification)
    if (currentTimer) {
      currentTimer.start = new Date().getTime()
      currentTimer.timeoutId = window.setTimeout(
        () => timerCallback(notification),
        currentTimer.remaining
      )
    }
  }

  const handleOnMouseEnter = (notification: INotification) => () => {
    if (notification.timeout) {
      pauseTimer(notification)
    }
  }

  const handleOnMouseLeave = (notification: INotification) => () => {
    if (notification.timeout) {
      resumeTimer(notification)
    }
  }

  return (
    <MessageContext.Provider value={{ remove, show }}>
      {children}
      {root.current &&
        createPortal(
          <TransitionGroup
            enter
            exit
            appear
            options={{ position: 'top right' }}
          >
            <Container>
              {notifications.length &&
                notifications.map((notification) => (
                  <Transition key={notification.id}>
                    <MessageManagerTemplate
                      {...notification}
                      onMouseEnter={handleOnMouseEnter(notification)}
                      onMouseLeave={handleOnMouseLeave(notification)}
                    />
                  </Transition>
                ))}
            </Container>
          </TransitionGroup>,
          root.current
        )}
    </MessageContext.Provider>
  )
}

export default MessageManagerProvider
