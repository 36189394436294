import { useMemo } from 'react'

import { hasPermission } from '../auth/misc'
import { PermissionEnum } from '../services'

import { useUser } from './useUser'

export const useHasPermission = (permissions: PermissionEnum[]) => {
  const { user } = useUser()

  const access = useMemo(() => {
    if (!user) {
      return false
    }

    if (permissions) {
      const hasPermissions = permissions
        .map((permission) => hasPermission(permission, user))
        .reduce((prev, curr) => prev || curr)

      if (!hasPermissions) {
        return false
      }
    }

    return true
  }, [permissions, user])

  return access
}
