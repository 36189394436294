import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { TextFieldStyleProps } from './text-field.types'

export const presetsContainer =
  (theme: LightTheme) =>
  (focus?: boolean, error?: string, disabled?: boolean, isError?: boolean) => ({
    border: focus
      ? {
          border: `2px solid ${theme.colors.yellow}`,
          borderRadius: 4,
          cursor: disabled ? 'default' : 'text',
          padding: '3px 24px',
          width: '100%'
        }
      : {
          border: `1px solid ${
            theme.colors[isError || error ? 'error' : 'inactive']
          }`,
          borderRadius: 4,
          cursor: disabled ? 'default' : 'text',
          padding: '4px 25px',
          width: '100%'
        },
    'bottom-border': focus
      ? {
          borderBottom: `2px solid ${theme.colors.yellow}`,
          padding: '0px 4px',
          cursor: disabled ? 'default' : 'text',
          width: '100%'
        }
      : {
          borderBottom: `2px solid ${theme.colors.black}`,
          cursor: disabled ? 'default' : 'text',
          padding: '0px 4px',
          width: '100%'
        },
    main: {
      borderRadius: 4,
      cursor: disabled ? 'default' : 'text',
      padding: '4px 24px',
      width: '100%'
    }
  })

export const useStyle = createUseStyles((theme: LightTheme) => {
  const containerPresets = presetsContainer(theme)

  return {
    container: ({
      preset = 'main',
      focus,
      error,
      disabled,
      isError
    }: TextFieldStyleProps) => ({
      background: theme.colors.white,
      ...containerPresets(focus, error, disabled, isError)[preset]
    }),
    icon: {
      cursor: 'pointer',
      marginLeft: 8
    },
    input: ({ textInputPreset = 'body', password }: TextFieldStyleProps) => ({
      ...theme.typography[textInputPreset],
      background: theme.colors.transparent,
      color: theme.colors.black,
      letterSpacing: password ? '8px' : 'normal',
      marginTop: 4,
      padding: 0,
      width: '100%',
      border: 'none',
      outline: 'none'
    }),
    inputPlaceholder: {
      '&::placeholder': {
        opacity: 0
      },
      '&:focus::placeholder': {
        opacity: 0.7
      }
    },
    inputContainer: {
      marginLeft: ({ leftIcon }: TextFieldStyleProps) => leftIcon && 8
    },
    label: {
      transform: ({ focus, value }: TextFieldStyleProps) =>
        !focus && !value && 'translate(0px, 13px) scale(1.15)',
      transformOrigin: ({ focus, value }: TextFieldStyleProps) =>
        !focus && !value && '0%',
      transition:
        'transform-origin 0.25s ease-in-out, transform 0.25s ease-in-out'
    },
    errorText: {
      width: 'calc(100% - 48px)',
      height: 14
    }
  }
})
