import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react'

import {
  arrayHasElements,
  Column,
  Dropdown,
  DropdownItemData,
  getItemById,
  isError,
  Loader,
  Row,
  Section,
  Text
} from '../../../components'
import { getItemsFromEdges, ProductCreateInput } from '../../../services'

import {
  getCategoriesFromList,
  getChildrenCategoryFromCategory,
  getDropdownItemsFromData
} from '../helpers'

import { useStyle } from './orginize-input.styles'
import { OrganizeInputProps } from './organize-input.types'

export const OrganizeInput: FC<OrganizeInputProps> = ({
  categoriesLevel2,
  categories,
  collections,
  value,
  error,
  onChangeValue
}) => {
  const classes = useStyle()

  const TopCategory = useMemo(() => {
    if (categoriesLevel2 && value?.category) {
      const categoriesData = getItemsFromEdges(categoriesLevel2.categories)
      const category = getItemById(categoriesData, value?.category)

      return category
    }
  }, [value?.category, categoriesLevel2])

  const [categoryId, changeCategory] = useState<string>(
    TopCategory?.parent.parent.id || ''
  )
  const [subcategoryId, changeSubcategory] = useState<string>(
    TopCategory?.parent.id || ''
  )

  useEffect(() => {
    const subCategory = TopCategory?.parent
    if (subCategory?.parent.id) {
      changeCategory(subCategory.parent.id)
    }

    if (subCategory?.id) {
      changeSubcategory(subCategory.id)
    }
  }, [TopCategory])

  const Categories = useMemo(
    () => getCategoriesFromList(categories),
    [categories]
  )

  const CategoriesDropdown = useMemo(() => {
    return getDropdownItemsFromData(Categories)
  }, [Categories])

  const Subcategories = useMemo(() => {
    return getChildrenCategoryFromCategory(categoryId, Categories)
  }, [categoryId, Categories])

  const SubcategoriesDropdown = useMemo(() => {
    return getDropdownItemsFromData(Subcategories)
  }, [Subcategories])

  const ProductTypes = useMemo(() => {
    return getChildrenCategoryFromCategory(subcategoryId, Subcategories)
  }, [subcategoryId, Subcategories])

  const ProductTypesDropdown = useMemo(() => {
    return getDropdownItemsFromData(ProductTypes)
  }, [ProductTypes])

  const Collections = useMemo(() => {
    if (collections) {
      return collections.vendorCollections.edges.map<DropdownItemData>(
        ({ node }) => ({
          id: node.id,
          name: node.name,
          value: node.id
        })
      )
    }

    return []
  }, [collections])

  const handleOnChangeCategory =
    (callback: Dispatch<SetStateAction<string>>) =>
    (dropdownItem: DropdownItemData) => {
      const { value: nextValue } = dropdownItem

      callback(String(nextValue))

      if (onChangeValue) {
        onChangeValue({
          ...value,
          category: undefined
        })
      }
    }

  const handleOnChangeDropdown =
    (prop: keyof ProductCreateInput) => (dropdownItem: DropdownItemData) => {
      const { value: nextValue } = dropdownItem

      if (onChangeValue) {
        onChangeValue({
          ...value,
          [prop]: String(nextValue)
        })
      }
    }

  if (value.category && !TopCategory) {
    return <Loader />
  }

  return (
    <Section
      className={classes.section}
      title="Organize Product"
      titleTx="item.form.organize.title"
    >
      <Column className={classes.sectionAdditionContainer}>
        <Dropdown
          data={CategoriesDropdown}
          defaultValue={TopCategory?.parent.parent.id}
          error={isError(categoryId, error)}
          preset="body"
          label="Category"
          labelTx="item.form.organize.category"
          onChange={handleOnChangeCategory(changeCategory)}
        />
        <Row fullWidth className={classes.field}>
          <Dropdown
            data={SubcategoriesDropdown}
            defaultValue={TopCategory?.parent.id}
            error={isError(subcategoryId, error)}
            disabled={!categoryId}
            preset="body"
            label="Sub-category"
            labelTx="item.form.organize.subcategory"
            onChange={handleOnChangeCategory(changeSubcategory)}
          />
        </Row>

        <Row fullWidth className={classes.field}>
          <Dropdown
            data={ProductTypesDropdown}
            defaultValue={value.category}
            disabled={!subcategoryId}
            error={isError(value.category, error)}
            preset="body"
            label="Product Type"
            labelTx="item.form.organize.productType"
            onChange={handleOnChangeDropdown('category')}
          />
        </Row>
      </Column>
      <Column className={classes.sectionAdditionContainer}>
        {arrayHasElements(Collections) && (
          <Dropdown
            data={Collections}
            preset="body"
            label="Collection"
            labelTx="item.form.organize.collection"
            defaultValue={value.collection}
            onChange={handleOnChangeDropdown('collection')}
          />
        )}
        <Text
          className={classes.description}
          preset="title"
          color="inactive"
          text="*Optional. Adding product to collection helps users find it."
        />
      </Column>
    </Section>
  )
}
