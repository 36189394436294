import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components/Theme'
import { PersonalInfoStyle } from './personal-info.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  infoContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${theme.colors.shadow}`,

    marginTop: 20,
    justifyContent: 'flex-start'
  },
  infoTitle: {
    padding: 16,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  inputRow: {
    padding: '16px',
    columnGap: 20
  },
  input: {
    flex: 1,
    borderRadius: 4,
    border: `1px solid ${theme.colors.inactive} `,
    '&:focus': {
      border: 'none'
    },
    '&:focus-within': {
      border: ({ errorStyle }: PersonalInfoStyle) =>
        errorStyle
          ? `1px solid ${theme.colors.error}`
          : `2px solid ${theme.colors.yellow}`,

      borderRadius: 4
    },
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  radio: {},
  radioContainer: {
    marginTop: 16
  },
  error: {
    position: 'absolute',
    padding: '4px 0px 0px 24px'
  },

  errorLastName: {
    position: 'absolute',
    bottom: '-16px',
    padding: '4px 0px 0px 24px'
  },
  errorUsername: {
    position: 'absolute',
    padding: '8px 0px 0px 24px'
  }
}))
