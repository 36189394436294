import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '24px 0'
  },
  back: {
    marginLeft: 24
  }
})
