import { useQuery } from '@apollo/client'

import { ADMIN_STATISTIC } from './statistic.graphql'
import {
  StatisticApi,
  AdminStatisticVariables,
  GetAdminStatisticData,
  AdminStatistic
} from './statistic.types'

export const statisticService = (): StatisticApi => {
  const useAdminStatistic = (
    variables: AdminStatisticVariables
  ): AdminStatistic => {
    const { data, loading, error, refetch } = useQuery<
      GetAdminStatisticData,
      AdminStatisticVariables
    >(ADMIN_STATISTIC, { variables })

    return { data, loading, refetch, error }
  }

  return {
    useAdminStatistic
  }
}
