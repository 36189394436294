import { useMutation, useQuery } from '@apollo/client'
import {
  ADVERTISING_BY_ID,
  ADVERTISING_LIMITS,
  ADVERTISING_LIST,
  ADVERTISING_REMOVE_MUTATION,
  CREATE_ADVERTISING,
  UPDATE_ADVERTISING
} from './advertising.graphql'

import {
  AdvertisingApi,
  AdvertisingByIdVariables,
  AdvertisingCreateInput,
  AdvertisingLimits,
  AdvertisingLimitsVariables,
  AdvertisingList,
  AdvertisingListVariables,
  AdvertisingRemoveVariables,
  CreateAdvertising,
  CreateAdvertisingRequest,
  CreateAdvertisingResults,
  EditAdvertisingInput,
  GetAdvertising,
  GetAdvertisingByIdRequest,
  GetAdvertisingLimits,
  GetAdvertisingList,
  GetAdvertisingRemoveResults,
  RemoveAdvertising,
  UpdateAdvertising,
  UpdateAdvertisingRequest,
  UpdateAdvertisingResults
} from './advertising.types'

// our "constructor"
export const advertisingService = (): AdvertisingApi => {
  const useAdvertisingList = (
    variables: AdvertisingListVariables
  ): GetAdvertisingList => {
    const { data, loading, refetch, error } = useQuery<
      AdvertisingList,
      AdvertisingListVariables
    >(ADVERTISING_LIST, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCreateAdvertising = (): CreateAdvertising => {
    const [onCreateAdvertising, response] = useMutation<
      CreateAdvertisingResults,
      CreateAdvertisingRequest
    >(CREATE_ADVERTISING)

    const handleOnSubmit = (advertising: AdvertisingCreateInput) => {
      const options = {
        variables: {
          input: { ...advertising, __typename: undefined }
        }
      }

      onCreateAdvertising(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useUpdateAdvertising = (): UpdateAdvertising => {
    const [onUpdateAdvertising, response] = useMutation<
      UpdateAdvertisingResults,
      UpdateAdvertisingRequest
    >(UPDATE_ADVERTISING)

    const handleOnSubmit = (id: string, advertising: EditAdvertisingInput) => {
      const options = {
        variables: {
          id,
          input: { ...advertising, __typename: undefined }
        }
      }

      onUpdateAdvertising(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRemoveAdvertising = (): RemoveAdvertising => {
    const [onRemoveAdvertising, response] = useMutation<
      GetAdvertisingRemoveResults,
      AdvertisingRemoveVariables
    >(ADVERTISING_REMOVE_MUTATION)

    const handleOnSubmit = (variables: AdvertisingRemoveVariables) => {
      const options = {
        variables
      }
      onRemoveAdvertising(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useAdvertisingById = (
    variables: AdvertisingByIdVariables
  ): GetAdvertisingByIdRequest => {
    const { data, loading, error } = useQuery<
      GetAdvertising,
      AdvertisingByIdVariables
    >(ADVERTISING_BY_ID, { variables, fetchPolicy: 'network-only' })

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useAdvetrisingLimits = (
    variables: AdvertisingLimitsVariables
  ): GetAdvertisingLimits => {
    const { data, loading, refetch, error } = useQuery<
      AdvertisingLimits,
      AdvertisingLimitsVariables
    >(ADVERTISING_LIMITS, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useAdvertisingList,
    useCreateAdvertising,
    useUpdateAdvertising,
    useRemoveAdvertising,
    useAdvertisingById,
    useAdvetrisingLimits
  }
}
