import { gql } from '@apollo/client'
import { PAGE_INFO_FRAGMENT, BASIC_OFFER_FRAGMENT } from '../fragments'

export const OFFERS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${BASIC_OFFER_FRAGMENT}
  query Offers(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: OfferFilterInput
    $sortBy: OfferOrder
  ) {
    offers(
      before: $before
      after: $after
      first: $first
      last: $last
      filter: $filter
      sortBy: $sortBy
    ) {
      totalCount
      filters
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...BasicOfferFields
        }
      }
    }
  }
`

export const OFFER_BY_ID = gql`
  ${BASIC_OFFER_FRAGMENT}
  query OfferById($id: ID!) {
    offer(id: $id) {
      ...BasicOfferFields
    }
  }
`

export const OFFER_UPDATE_MUTATION = gql`
  ${BASIC_OFFER_FRAGMENT}
  mutation OfferUpdate($id: ID!, $input: OfferCreateInput!) {
    offerUpdate(id: $id, input: $input) {
      offerErrors {
        code
        message
      }
      offer {
        ...BasicOfferFields
      }
    }
  }
`

export const OFFER_DELETE_MUTATION = gql`
  ${BASIC_OFFER_FRAGMENT}
  mutation OfferDelete($id: ID!) {
    offerDelete(id: $id) {
      productErrors {
        code
        message
      }
      offer {
        ...BasicOfferFields
      }
    }
  }
`

export const OFFER_BULK_DELETE_MUTATION = gql`
  mutation OfferBulkDelete($ids: [ID]!) {
    offerBulkDelete(ids: $ids) {
      count
      offerErrors {
        code
        message
      }
    }
  }
`

export const OFFER_DUPLICATE_MUTATION = gql`
  ${BASIC_OFFER_FRAGMENT}
  mutation OfferDuplicate($offers: [ID]!) {
    offerDuplicate(offers: $offers) {
      offers {
        ...BasicOfferFields
      }
      productErrors {
        code
        message
      }
      errors {
        field
        message
      }
    }
  }
`
