import { useQuery } from '@apollo/client'

import { PRODUCT_ATTRIBUTES_QUERY } from './attribute.graphql'
import {
  GetAttributes,
  GetAttributesData,
  AttributeApi,
  AttributeVariables
} from './attribute.types'

// our "constructor"
export const attributeService = (): AttributeApi => {
  const useAttributes = (variables: AttributeVariables): GetAttributes => {
    const { data, loading, error, refetch } = useQuery<
      GetAttributesData,
      AttributeVariables
    >(PRODUCT_ATTRIBUTES_QUERY, {
      variables
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useAttributes
  }
}
