import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  CabinetNavigatorPathVendor,
  SalerPageDesignNavigation
} from '../cabinet-navigator'

import { useApi } from '../../providers'
import { EditVendorBannerInput } from '../../services/menu'

import { VendorBannerState } from '../vendor-banner'
import { VendorBannerForm } from '../vendor-banner-form'
import { EditVendorBannerParams } from './edit-vendor-banner.types'

export const EditVendorBannerPage = () => {
  const { id } = useParams<EditVendorBannerParams>()
  const { menu } = useApi()
  const { data: banners } = menu.useVendorBanners({ first: 100 })
  const { response, onSubmit } = menu.useVendorBannerUpdate()
  const { response: responseImages, onSubmit: onSubmitImage } =
    menu.useVendorBannerImageCreate()
  const [image, changeImage] = useState<File>()
  const history = useHistory<VendorBannerState>()

  useEffect(() => {
    if (image) {
      if (response.data && response.data.vendorBannerUpdate.vendorBanner) {
        const { id: bannerId } = response.data.vendorBannerUpdate.vendorBanner
        const variables = { input: { banner: bannerId, image } }
        onSubmitImage(variables)
      }
    } else if (response.data) {
      const tablePath = `${CabinetNavigatorPathVendor.SELLER_PAGE_DESIGN}${SalerPageDesignNavigation.SALER_PAGE_BANNER}`
      history.push(tablePath, {
        vendorBanner: response.data.vendorBannerUpdate.vendorBanner
      })
    }
  }, [response.data])

  useEffect(() => {
    if (responseImages.data) {
      const { banner: nextBanner } = responseImages.data.vendorBannerImageCreate
      const tablePath = `${CabinetNavigatorPathVendor.SELLER_PAGE_DESIGN}${SalerPageDesignNavigation.SALER_PAGE_BANNER}`
      history.push(tablePath, {
        vendorBanner: nextBanner
      })
    }
  }, [responseImages])

  // FIXME: write to back-end developer about request vendor banner by id;
  const CurrentBanner = useMemo(() => {
    if (id && banners) {
      return banners.vendorBanners.edges.find((banner) => banner.node.id === id)
    }

    return undefined
  }, [id, banners])

  const handleOnSubmit = (
    nextBanner: EditVendorBannerInput,
    nextImage?: File
  ) => {
    if (nextBanner) {
      const newNextBanner = {
        name: nextBanner.name,
        title: nextBanner.title,
        subTitle: nextBanner.subTitle,
        isVisible: nextBanner.isVisible,
        buttonText: nextBanner.buttonText,
        buttonLink: nextBanner.buttonLink
      }
      onSubmit(id, newNextBanner)
      changeImage(nextImage)
    }
  }

  if (!CurrentBanner) {
    return <></>
  }

  return (
    <VendorBannerForm
      vendorBanner={CurrentBanner.node}
      title="Update Banner"
      titleTx="banner.update"
      activeBannersCount={banners?.vendorBanners.activeBannersCount}
      onSumbit={handleOnSubmit}
    />
  )
}
