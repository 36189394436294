import React, { FC } from 'react'

import {
  Checkbox,
  Row,
  Text,
  Slider,
  Button,
  Icon,
  ICONS
} from '../../../components'

import { useStyle } from './items-table-header-checked.styles'
import { ItemsTableHeaderCheckedProps } from './items-table-header-checked.types'

export const ItemsTableHeaderChecked: FC<ItemsTableHeaderCheckedProps> = ({
  className = '',
  checkedItemsCount,
  pickedAll,
  topProductTopItem,
  onPickAll,
  onSetTopProduct,
  onDuplicate,
  onActivate,
  onDeactivate,
  onOpenDelete
}) => {
  const classes = useStyle()

  const checkedItemsCountValue = { checkedItemsCount }
  const checkedItemText = `Selected ${checkedItemsCount} items`

  return (
    <Row fullWidth className={className} justifyContent="space-between">
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Checkbox checked={pickedAll} onChange={onPickAll} />
        <Text
          className={classes.textCheckBox}
          preset="body"
          text={checkedItemText}
          tx="item.table.selected.items"
          values={checkedItemsCountValue}
        />
      </Row>
      <Row className={classes.slider}>
        <Text
          color="black"
          preset="h6"
          text="Top Product"
          tx="item.top.product"
        />
        <Slider
          className={classes.sliderItem}
          value={topProductTopItem}
          onChange={onSetTopProduct}
        />
      </Row>
      <Row className={classes.buttonContainer}>
        <Button
          className={classes.button}
          preset="secondaryMini"
          text="Duplicate"
          textPreset="maxButton"
          textColor="white"
          color="darkBlue"
          tx="item.table.duplicate.btn"
          onClick={onDuplicate}
        />
        <Button
          className={classes.button}
          preset="secondaryBorderMini"
          text="Activate"
          textPreset="maxButton"
          textColor="yellow"
          tx="item.table.activate.btn"
          onClick={onActivate}
        />
        <Button
          className={classes.button}
          preset="secondaryBorderMini"
          text="Deactivate"
          textPreset="maxButton"
          textColor="yellow"
          tx="item.table.deactivate.btn"
          onClick={onDeactivate}
        />
        <Row className={classes.remove}>
          <Row className={classes.icon}>
            <Icon src={ICONS.trash} onClick={onOpenDelete(true)} />
          </Row>
        </Row>
      </Row>
    </Row>
  )
}
