import React, { useReducer } from 'react'
import useRouter from 'use-react-router'

import {
  initialAppState,
  reduceAppState,
  AppStateContextType,
  AppStateReducerAction
} from './app-state'

export const APP_ERROR_ACTION: AppStateReducerAction = {
  payload: {
    error: undefined
  },
  type: 'displayError'
}

export const AppStateContext = React.createContext<AppStateContextType>([
  initialAppState,
  () => undefined
])

export const AppStateProvider: React.FC = ({ children }) => {
  const { location } = useRouter()
  const stateAndDispatch = useReducer(reduceAppState, initialAppState)
  const [state, dispatch] = stateAndDispatch

  React.useEffect(() => {
    if (state.error) {
      dispatch(APP_ERROR_ACTION)
    }
  }, [location])

  return (
    <AppStateContext.Provider value={stateAndDispatch}>
      {children}
    </AppStateContext.Provider>
  )
}

export const { Consumer } = AppStateContext
