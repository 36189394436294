import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  icon: {
    marginLeft: 8
  },
  text: {
    marginLeft: 8
  }
})
