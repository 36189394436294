import React from 'react'

import {
  CategoryNav,
  CategoryNavItem,
  Column,
  ICONS,
  PermissionContent,
  Row,
  Text
} from '../../components'
import { PermissionEnum } from '../../services'

import { UserListTabNavigation } from '../cabinet-navigator'
import { UserListCustomersPage } from '../user-list-customers'
import { UserListVendorsPage } from '../user-list-vendors'

import { useStyle } from './user-list.styles'

export const UserListPage = () => {
  const classes = useStyle()

  const NAVIGATION_TABS: CategoryNavItem[] = [
    {
      text: 'Vendors',
      tx: 'user.list.vendors.title',
      link: UserListTabNavigation.VENDORS,
      icon: ICONS.workOutline,
      content: (
        <PermissionContent permissions={[PermissionEnum.VIEW_VENDORS]}>
          <UserListVendorsPage />
        </PermissionContent>
      )
    },
    {
      text: 'Customers',
      tx: 'user.list.customer.title',
      link: UserListTabNavigation.CUSTOMERS,
      icon: ICONS.personIdentity,
      content: (
        <PermissionContent permissions={[PermissionEnum.VIEW_CUSTOMERS]}>
          <UserListCustomersPage />
        </PermissionContent>
      )
    }
  ]

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="flex-start">
        <Text color="black" preset="h3" text="User List" tx="user.list.title" />
      </Row>
      <CategoryNav data={NAVIGATION_TABS} />
    </Column>
  )
}
