import React, { FC, useState } from 'react'

import { ProductOptionVariantInput } from '../../../services'

import { OfferOptionSection } from '../offer-option-section'

import { OfferOptionsFormProps } from './offer-options-form.types'

export const OfferOptionsForm: FC<OfferOptionsFormProps> = ({
  options,
  onChange
}) => {
  const [data, changeData] = useState(options)

  const handleOnChangeOption =
    (title: string) => (optionsValue: ProductOptionVariantInput[]) => {
      const nextValue = data.map((option) => {
        if (option.title !== title) {
          return option
        }

        return {
          ...option,
          variants: optionsValue
        }
      })

      changeData(nextValue)

      if (onChange) {
        onChange(nextValue)
      }
    }

  return (
    <>
      {data.map((optionItem, index) => (
        <OfferOptionSection
          key={`option_${optionItem.title}_${index}`}
          title={optionItem.title}
          type={optionItem.type}
          optionVariants={optionItem.variants}
          onChangeTable={handleOnChangeOption(optionItem.title)}
        />
      ))}
    </>
  )
}
