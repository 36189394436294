import React, { FC } from 'react'

import { Column, Text } from '../../../../components'

import { useStyle } from './observe-options-description.styles'
import { ObserveOptionDescriptionProps } from './observe-options-description.types'

export const ObserveOptionDescription: FC<ObserveOptionDescriptionProps> = ({
  title,
  titleTx,
  description
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth alignItems="flex-start" className={classes.describing}>
      <Text text={title} tx={titleTx} preset="body" color="inactive" />
      <Text
        className={classes.textRow}
        text={description}
        preset="body"
        color="black"
      />
    </Column>
  )
}
