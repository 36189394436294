import { RadioItemWithIdProps } from '../../components'
import {
  AdvertisingCreateInput,
  AdvertisingType
} from '../../services/advertising'

export const defaultValue: AdvertisingCreateInput = {
  name: '',
  type: AdvertisingType.TOP_PRODUCT,
  isVisible: true
}

export const typeRadioData: RadioItemWithIdProps[] = [
  {
    id: AdvertisingType.TOP_PRODUCT.toString(),
    name: 'Top Product',
    nameTx: 'advertising.type.topProduct'
  },
  {
    id: AdvertisingType.BANNER.toString(),
    name: 'Banner',
    nameTx: 'advertising.type.banner'
  }
]

export const visibilityRadioData: RadioItemWithIdProps[] = [
  { id: '1', name: 'Visible', nameTx: 'banner.visibility.active' },
  { id: '0', name: 'Invisible', nameTx: 'banner.visibility.inactive' }
]
