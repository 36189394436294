import React, { FC } from 'react'

import { Row, Text, RatingStarsComponent } from '../../../../../components'

import { VendorPreviewSummaryHeaderProps } from './vendor-preview-summary-header.types'
import { useStyle } from './vendor-preview-summary-header.styles'

export const VendorPreviewSummaryHeader: FC<VendorPreviewSummaryHeaderProps> =
  ({ className = '', rating }) => {
    const classes = useStyle()

    const isRating = rating || 0

    return (
      <Row
        fullWidth
        justifyContent="space-between"
        className={`${className} ${classes.title}`}
      >
        <Text text="Company Summary" preset="h3" color="black" />
        <RatingStarsComponent
          className={classes.rating}
          rating={isRating}
          width="36"
          height="36"
          edit={false}
        />
      </Row>
    )
  }
