import { DropdownItemData } from '../../../components'
import { OrderDirection } from '../../../services'
import { OrderSortField } from '../../../services/order/order.types'

export const orderSortDataAdmin: DropdownItemData[] = [
  {
    id: '',
    value: '',
    name: 'Clear',
    nameTx: 'order.sort.clear'
  },
  {
    id: `${OrderSortField.NUMBER},${OrderDirection.ASC}`,
    value: `${OrderSortField.NUMBER},${OrderDirection.ASC}`,
    name: 'Number A-Z',
    nameTx: 'order.sort.number.ascending'
  },
  {
    id: `${OrderSortField.NUMBER},${OrderDirection.DESC}`,
    value: `${OrderSortField.NUMBER},${OrderDirection.DESC}`,
    name: 'Number Z-A',
    nameTx: 'order.sort.number.descending'
  },
  {
    id: `${OrderSortField.CREATION_DATE},${OrderDirection.ASC}`,
    value: `${OrderSortField.CREATION_DATE},${OrderDirection.ASC}`,
    name: 'Date A-Z',
    nameTx: 'order.sort.date.ascending'
  },
  {
    id: `${OrderSortField.CREATION_DATE},${OrderDirection.DESC}`,
    value: `${OrderSortField.CREATION_DATE},${OrderDirection.DESC}`,
    name: 'Date Z-A',
    nameTx: 'order.sort.date.descending'
  },
  {
    id: `${OrderSortField.TOTAL_PRICE},${OrderDirection.ASC}`,
    value: `${OrderSortField.TOTAL_PRICE},${OrderDirection.ASC}`,
    name: 'Price A-Z',
    nameTx: 'order.sort.price.ascending'
  },
  {
    id: `${OrderSortField.TOTAL_PRICE},${OrderDirection.DESC}`,
    value: `${OrderSortField.TOTAL_PRICE},${OrderDirection.DESC}`,
    name: 'Price Z-A',
    nameTx: 'order.sort.price.descending'
  },
  {
    id: `${OrderSortField.VENDOR},${OrderDirection.ASC}`,
    value: `${OrderSortField.VENDOR},${OrderDirection.ASC}`,
    name: 'Vendor A-Z',
    nameTx: 'order.sort.vendor.ascending'
  },
  {
    id: `${OrderSortField.VENDOR},${OrderDirection.DESC}`,
    value: `${OrderSortField.VENDOR},${OrderDirection.DESC}`,
    name: 'Vendor Z-A',
    nameTx: 'order.sort.vendor.descending'
  },
  {
    id: `${OrderSortField.CUSTOMER},${OrderDirection.ASC}`,
    value: `${OrderSortField.CUSTOMER},${OrderDirection.ASC}`,
    name: 'Customer A-Z',
    nameTx: 'order.sort.customer.ascending'
  },
  {
    id: `${OrderSortField.CUSTOMER},${OrderDirection.DESC}`,
    value: `${OrderSortField.CUSTOMER},${OrderDirection.DESC}`,
    name: 'Customer Z-A',
    nameTx: 'order.sort.customer.descending'
  }
]

export const orderSortDataVendor: DropdownItemData[] = [
  {
    id: '',
    value: '',
    name: 'Clear',
    nameTx: 'order.sort.clear'
  },
  {
    id: `${OrderSortField.NUMBER},${OrderDirection.ASC}`,
    value: `${OrderSortField.NUMBER},${OrderDirection.ASC}`,
    name: 'Number A-Z',
    nameTx: 'order.sort.number.ascending'
  },
  {
    id: `${OrderSortField.NUMBER},${OrderDirection.DESC}`,
    value: `${OrderSortField.NUMBER},${OrderDirection.DESC}`,
    name: 'Number Z-A',
    nameTx: 'order.sort.number.descending'
  },
  {
    id: `${OrderSortField.CREATION_DATE},${OrderDirection.ASC}`,
    value: `${OrderSortField.CREATION_DATE},${OrderDirection.ASC}`,
    name: 'Date A-Z',
    nameTx: 'order.sort.date.ascending'
  },
  {
    id: `${OrderSortField.CREATION_DATE},${OrderDirection.DESC}`,
    value: `${OrderSortField.CREATION_DATE},${OrderDirection.DESC}`,
    name: 'Date Z-A',
    nameTx: 'order.sort.date.descending'
  },
  {
    id: `${OrderSortField.TOTAL_PRICE},${OrderDirection.ASC}`,
    value: `${OrderSortField.TOTAL_PRICE},${OrderDirection.ASC}`,
    name: 'Price A-Z',
    nameTx: 'order.sort.price.ascending'
  },
  {
    id: `${OrderSortField.TOTAL_PRICE},${OrderDirection.DESC}`,
    value: `${OrderSortField.TOTAL_PRICE},${OrderDirection.DESC}`,
    name: 'Price Z-A',
    nameTx: 'order.sort.price.descending'
  },
  {
    id: `${OrderSortField.CUSTOMER},${OrderDirection.ASC}`,
    value: `${OrderSortField.CUSTOMER},${OrderDirection.ASC}`,
    name: 'Customer A-Z',
    nameTx: 'order.sort.customer.ascending'
  },
  {
    id: `${OrderSortField.CUSTOMER},${OrderDirection.DESC}`,
    value: `${OrderSortField.CUSTOMER},${OrderDirection.DESC}`,
    name: 'Customer Z-A',
    nameTx: 'order.sort.customer.descending'
  }
]
