import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '24px 16px'
  },
  dropdown: {
    maxWidth: 162
  },
  column: {
    color: theme.colors.yellow
  },
  titleContainer: {},
  load: {
    marginTop: 16
  },
  button: {
    marginLeft: 24,
    maxWidth: 312
  },
  nav: {
    marginTop: 16,
    borderTop: `1px solid ${theme.colors.shadow}`,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  navRow: {
    marginTop: 16,
    minWidth: 400,
    padding: '8px 24px',
    borderBottom: `4px solid ${theme.colors.yellow}`
  },
  navEmpRow: {
    marginTop: 16,
    minWidth: 400,
    padding: '8px 24px'
  },
  link: {
    '&:hover': {
      color: theme.colors.darkBlue
    }
  },
  itemTable: {
    marginTop: 24
  },
  row: {
    marginTop: 24
  }
}))
