import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    cursor: 'pointer'
  },
  caret: {
    marginLeft: 16
  }
})
