import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  title: {
    backgroundColor: theme.colors.lightBlue
  },
  rating: {}
}))
