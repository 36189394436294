import React, { ChangeEventHandler, FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import InfiniteScroll from 'react-infinite-scroll-component'

import { User } from '../../services'

import { Column } from '../column'
import { getFullName } from '../helpers'
import { TextField } from '../text-field'
import { VendorRadioItem } from '../vendor-radio-item'
import { Loader } from '../loader'

import { useStyle } from './vendor-radio-box.styles'
import { VendorRadioBoxProps } from './vendor-radio-box.types'

import './index.css'

export const VendorRadioBox: FC<VendorRadioBoxProps> = ({
  active,
  className = '',
  data,
  itemClassName = '',
  totalCount = 0,
  onChange,
  onSearch,
  onFetchMore,
  onRefresh
}) => {
  const classes = useStyle()

  const getActive = (currentValue: string) => currentValue === active

  const handleOnChange = (user: User) => () => {
    if (onChange) {
      onChange(user)
    }
  }

  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target

    if (onSearch) {
      onSearch(value)
    }
  }

  const loader = <Loader />
  const hasMore = totalCount > data.length

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      className={className}
    >
      <TextField
        label="Search"
        labelTx="vendor.radio.search"
        preset="border"
        onChange={handleOnSearch}
      />
      <InfiniteScroll
        pullDownToRefresh
        pullDownToRefreshThreshold={250}
        className={classes.list}
        dataLength={data.length}
        loader={loader}
        hasMore={hasMore}
        next={onFetchMore}
        refreshFunction={onRefresh}
      >
        {data.map((user) => (
          <VendorRadioItem
            key={`${uuidv4()}_${user.id}`}
            avatar={user.avatar?.url}
            companyName={user.companyName}
            name={getFullName(user)}
            active={getActive(user.id)}
            className={itemClassName}
            onClick={handleOnChange(user)}
          />
        ))}
      </InfiniteScroll>
    </Column>
  )
}
