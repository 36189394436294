import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    justifyContent: 'space-between',
    height: 180
  },
  title: {
    alignSelf: 'flex-start'
  },
  value: {
    height: '100%',
    alignSelf: 'stretch',
    alignItems: 'flex-end'
  },
  iconContainer: {
    borderRadius: 8,
    width: 88,
    marginLeft: 16,
    backgroundColor: '#F8F8F8'
  },
  icon: {
    fill: `${theme.colors.yellow}`
  },
  secondTitle: {
    marginTop: 4
  }
}))
