import React from 'react'
import { useHistory } from 'react-router-dom'

import { Column, Row, Text, Icon, ICONS } from '../../components'
import { CabinetNavigatorPathVendor } from '../cabinet-navigator'
import { ContactAdministrator } from '../views'

import { useStyle } from './profile-settings.styles'

export const ProfileSettingsPage = () => {
  const classes = useStyle()
  const history = useHistory()
  const handleOnGeneral = () => {
    history.push(CabinetNavigatorPathVendor.PROFILE_SETTINGS_GENERAL)
  }
  const handleOnSubscription = () => {
    history.push(CabinetNavigatorPathVendor.PROFILE_SETTINGS_SUBSCRIPTION)
  }

  return (
    <>
      <Column
        fullWidth
        className={classes.container}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Row
          fullWidth
          className={classes.titleContainer}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Text color="black" preset="h3" text="Profile Settings" />
          <ContactAdministrator />
        </Row>
        <Row
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.general}
        >
          <Text
            className={classes.subtitleText}
            color="black"
            preset="h5"
            text="General Settings"
          />
          <Column className={classes.card} onClick={handleOnGeneral}>
            <Icon src={ICONS.businessCenter} className={classes.icon} />
            <Text color="black" preset="h3" text="General Information" />
            <Text
              color="black"
              preset="body"
              text="Tell us about your company"
            />
          </Column>
        </Row>
        <Row
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.general}
        >
          <Text
            className={classes.subtitleText}
            color="black"
            preset="h5"
            text="Manage subscription"
          />
          <Column className={classes.card} onClick={handleOnSubscription}>
            <Icon src={ICONS.payment} className={classes.icon} />
            <Text color="black" preset="h3" text="Subscription" />
            <Text color="black" preset="body" text="Manage your subscription" />
          </Column>
        </Row>
      </Column>
    </>
  )
}
