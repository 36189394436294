import React, { FC, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Loader } from '../../components'
import { useApi, useToastify } from '../../providers'
import { CollectionUpdateInput } from '../../services'

import { CollectionForm } from '../collection-form'
import {
  CabinetNavigatorPathVendor,
  ItemsTabNavigation
} from '../cabinet-navigator'

import {
  EditCollectionParams,
  EditCollectionProps
} from './edit-collection.types'

export const EditCollectionPage: FC<EditCollectionProps> = () => {
  const { open } = useToastify()
  const history = useHistory()
  const { collection: collectionApi } = useApi()
  const { id } = useParams<EditCollectionParams>()
  const { data } = collectionApi.useCollectionById({ id })
  const { onSubmit: onRemove } = collectionApi.useCollectionDelete()

  const { response: editResponse, onSubmit } =
    collectionApi.useCollectionUpdate()

  useEffect(() => {
    if (editResponse && editResponse.data) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })

      history.push(
        `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.COLLECTIONS}`,
        { banner: editResponse.data.collectionUpdate }
      )
    }
  }, [editResponse])

  const handleOnSubmit = (input: CollectionUpdateInput) => {
    onSubmit({ id, input })
  }

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove({ id })
    }
  }

  const handleOnCancel = () => {
    history.push(
      `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.COLLECTIONS}`
    )
  }

  if (!data) {
    return <Loader />
  }

  return (
    <CollectionForm
      title={data.collection.name}
      collection={data.collection}
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
      onRemove={handleOnRemove}
    />
  )
}
