import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Loader } from '../../../components'
import { useUser } from '../../../hooks/useUser'
import { EmailChangeConfirmParams } from './email-change-confirm.types'

export const EmailChangeConfirm: FC = () => {
  const userContext = useUser()
  const { token } = useParams<EmailChangeConfirmParams>()

  useEffect(() => {
    if (token && userContext.confirmEmailChange) {
      userContext.confirmEmailChange(token)
    }
  }, [token])

  return (
    <>
      <Loader />
    </>
  )
}
