import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  nav: {
    padding: 16,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    '& > :first-child': {
      justifyContent: 'flex-start'
    },
    '& > :last-child': {
      justifyContent: 'center'
    }
  },
  search: {
    width: '100%',
    backgroundColor: theme.colors.white
  }
}))
