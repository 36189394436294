import React, {
  createContext,
  FC,
  RefObject,
  useContext,
  useEffect,
  useState
} from 'react'
import { useIntl } from 'react-intl'
import Talk from 'talkjs'
import { v4 as uuid } from 'uuid'

import { TALKJS_APP_ID } from '../../config'
import { useAuth } from '../../hooks'
import { getMessageByMainStatus } from '../../utils'
import { ChatUserData, Order, Product } from '../../services'

import {
  ChatLocalStorageKey,
  ChatUserRole,
  ChatValue,
  GroupConversationOptions,
  GroupConversationRoleOptions,
  SendMessageData
} from './chat.types'
import { getOrderLinesAutoMessage, getProductFullOriginLink } from './helpers'

const defaultUser = {
  id: 'Unknown',
  email: undefined,
  name: 'Unknown',
  photoUrl: undefined,
  role: ChatUserRole.VENDOR
}

const defaultValue: ChatValue = {
  ready: false,
  createVendor: () => defaultUser,
  createCustomer: () => defaultUser
}

export const ChatContext = createContext<ChatValue>(defaultValue)

export const ChatProvider: FC = ({ children }) => {
  const intl = useIntl()
  const { isAuthenticated, user, signature, updateInfo } = useAuth()
  const [conversation, changeConversation] =
    useState<Talk.ConversationBuilder>()
  const [me, changeMe] = useState<Talk.User>()
  const [session, changeSession] = useState<Talk.Session>()
  const [ready, changeReady] = useState(defaultValue.ready)

  useEffect(() => {
    Talk.ready.then(() => {
      changeReady(true)
    })
  }, [])

  useEffect(() => {
    // @ts-ignore
    if (conversation?.id) {
      // @ts-ignore
      localStorage.setItem(ChatLocalStorageKey.CONVERSATION, conversation.id)
    }
  }, [conversation])

  const handleOnOpenSession = () => {
    if (isAuthenticated && user && signature) {
      const currentUser = new Talk.User({
        id: user.id,
        name: user.companyName || user.firstName || defaultUser.name,
        email: user.email,
        photoUrl: user.avatar?.url,
        role: user.isStaff ? ChatUserRole.STAFF : ChatUserRole.VENDOR
      })

      changeMe(currentUser)

      const nextSession = new Talk.Session({
        appId: TALKJS_APP_ID,
        me: currentUser,
        signature
      })

      changeSession(nextSession)
      changeReady(true)
    }
  }

  const handleOnDestroySession = () => {
    if (!isAuthenticated && session) {
      session.destroy()
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      handleOnOpenSession()
    } else {
      handleOnDestroySession()
    }

    return handleOnDestroySession
  }, [isAuthenticated, user, signature])

  const handleOnSendMessage = ({
    conversation: nextConversation,
    message,
    messageTx,
    values,
    additionalMessage
  }: SendMessageData) => {
    if (nextConversation) {
      const formatedMessage = intl.formatMessage(
        {
          id: messageTx,
          defaultMessage: message
        },
        values
      )

      const nextMessage = messageTx ? formatedMessage : message
      const nextMessageWithAddition = additionalMessage
        ? `${nextMessage}\n ${additionalMessage}`
        : nextMessage

      nextConversation.sendMessage(nextMessageWithAddition)
    }
  }

  const handleOnSendProductMessage = (
    nextConversation: Talk.ConversationBuilder,
    product: Product
  ) => {
    const fullLinkOnProduct = getProductFullOriginLink(product.id)

    const message = `
      Product Name: ${product.name}
      Product Price: ${product.totalPrice}
      ${fullLinkOnProduct}
    `
    const values = {
      name: product.name,
      price: product.totalPrice,
      link: fullLinkOnProduct
    }

    handleOnSendMessage({
      values,
      message,
      conversation: nextConversation
    })
  }

  const handleOnSendOrderMessage = (
    nextConversation: Talk.ConversationBuilder,
    order: Order
  ) => {
    const { mainStatus, number, totalPrice, created } = order
    const status = getMessageByMainStatus(mainStatus)
    const formatedStatus = intl.formatMessage({
      id: status.tx,
      defaultMessage: status.text || ''
    })
    const formatedTotal = `${totalPrice.toFixed(2)}$`

    const additionalMessage = getOrderLinesAutoMessage(intl, order.lines)
    const message = `
      Order Number: #${number} 
      Order Status: ${formatedStatus}
      Order Price: ${formatedTotal}
      Date: ${created}
      ${additionalMessage}
    `
    const values = {
      number,
      additionalMessage,
      status: formatedStatus,
      totalPrice: formatedTotal,
      created
    }

    handleOnSendMessage({
      values,
      message,
      conversation: nextConversation,
      additionalMessage
    })
  }

  const createVendor = (vendor?: ChatUserData): Talk.User => {
    if (vendor) {
      const { id, firstName, email, avatar, companyName } = vendor
      const chatVendor = new Talk.User({
        id,
        email,
        name: companyName || firstName || 'Unknown',
        photoUrl: avatar?.url,
        role: ChatUserRole.VENDOR
      })

      return chatVendor
    }

    return defaultUser
  }

  const createAdmin = (admin?: ChatUserData): Talk.User => {
    if (admin) {
      const { id, firstName, email, avatar } = admin
      const chatVendor = new Talk.User({
        id,
        email,
        name: firstName || 'Unknown',
        photoUrl: avatar?.url,
        role: ChatUserRole.STAFF
      })

      return chatVendor
    }

    return defaultUser
  }

  const createCustomer = (customer?: ChatUserData): Talk.User => {
    if (customer) {
      const { id, firstName, email, avatar } = customer
      const chatVendor = new Talk.User({
        id,
        email,
        name: firstName || 'Unknown',
        photoUrl: avatar?.url,
        role: ChatUserRole.CUSTOMER
      })

      return chatVendor
    }

    return defaultUser
  }

  const createUser = (
    userData?: ChatUserData,
    role?: ChatUserRole
  ): Talk.User => {
    switch (role) {
      case ChatUserRole.CUSTOMER:
        return createCustomer(userData)
      case ChatUserRole.VENDOR:
        return createVendor(userData)
      case ChatUserRole.STAFF:
        return createAdmin(userData)
      default:
        return createCustomer(userData)
    }
  }

  const handleOnGetConversation = () => {
    if (session && me) {
      const nextConversation = session.getOrCreateConversation(
        Talk.oneOnOneId(me, me)
      )

      changeConversation(nextConversation)
    }
  }

  const handleOnCreateGroupConversation = ({
    users,
    role,
    chatAttributes
  }: GroupConversationRoleOptions) => {
    if (me && session) {
      const conversationId = uuid()
      const nextConversation = session.getOrCreateConversation(conversationId)

      nextConversation.setParticipant(me)
      users.forEach((userData) => {
        const chatUser = createUser(userData, role)
        nextConversation.setParticipant(chatUser)
      })

      nextConversation.setAttributes(chatAttributes)
      changeConversation(nextConversation)

      return conversationId
    }

    return undefined
  }

  const handleOnCreateConversationById = (conversationId: string) => {
    if (session) {
      const nextConversation = session.getOrCreateConversation(conversationId)
      changeConversation(nextConversation)

      return conversationId
    }

    return undefined
  }

  const handleOnCreateAdminConversation = (
    options: GroupConversationOptions
  ) => {
    if (user && !user.conversationId) {
      const conversationId = handleOnCreateGroupConversation({
        ...options,
        role: ChatUserRole.STAFF
      })

      if (updateInfo && conversationId) {
        updateInfo({ input: { conversationId } })
      }
    }

    if (user && user.conversationId) {
      handleOnCreateConversationById(user.conversationId)
    }
  }

  const handleOnCreateConversation = (
    otherUser: Talk.User
  ): Talk.ConversationBuilder | undefined => {
    const other = new Talk.User(otherUser)

    if (me && session) {
      const meWithWelcomeMessase = createCustomer(user)

      const nextConversation = session.getOrCreateConversation(
        Talk.oneOnOneId(meWithWelcomeMessase, other)
      )

      nextConversation.setParticipant(me)
      nextConversation.setParticipant(other)

      changeConversation(nextConversation)
      return nextConversation
    }

    return undefined
  }

  const handleOnDestroyInboxes = () => {
    if (session) {
      const inboxes = session.getInboxes()

      inboxes.forEach((inboxItem) => inboxItem.destroy())
    }
  }

  const handleOnMountConversation = (ref: RefObject<HTMLElement>) => {
    if (session) {
      const inbox = session.createInbox()
      inbox.mount(ref.current)

      if (me && !conversation) {
        const localStorageId = localStorage.getItem(
          ChatLocalStorageKey.CONVERSATION
        )
        const oneOnOneId = Talk.oneOnOneId(me, me)
        const userId = user?.conversationId

        const nextConversation = session.getOrCreateConversation(
          localStorageId || userId || oneOnOneId
        )
        inbox.select(nextConversation)
      }

      if (me && conversation) {
        inbox.select(conversation)
      }
    }
  }

  const handleOnMountChatboxConversation = (
    ref: RefObject<HTMLDivElement>,
    conversationId: string
  ) => {
    if (session) {
      const inbox = session.createChatbox()
      inbox.mount(ref.current)

      if (me) {
        const nextConversation = session.getOrCreateConversation(conversationId)

        inbox.select(nextConversation)
      }
    }
  }

  const context = {
    ready,
    conversation,
    createVendor,
    createCustomer,
    session,
    onDestroyInboxes: handleOnDestroyInboxes,
    onCreateConversation: handleOnCreateConversation,
    onGetConversation: handleOnGetConversation,
    onMountConversation: handleOnMountConversation,
    onMountChatboxConversation: handleOnMountChatboxConversation,
    onSendMessage: handleOnSendMessage,
    onSendProductMessage: handleOnSendProductMessage,
    onSendOrderMessage: handleOnSendOrderMessage,
    onDestroySession: handleOnDestroySession,
    onOpenSession: handleOnOpenSession,
    onCreateGroupConversation: handleOnCreateGroupConversation,
    onCreateAdminConversation: handleOnCreateAdminConversation
  }

  return <ChatContext.Provider value={context}>{children}</ChatContext.Provider>
}

export const useChat = () => useContext(ChatContext)
