import { CATEGORY_FRAGMENT } from './category'

import { BASIC_PRODUCT_FRAGMENT, PRODUCT_PRICING_FRAGMENT } from './product'

export const ADDRESS_FRAGMENT = `
fragment AddressFragment on Address {
  id
  firstName
  lastName
  companyName
  streetAddress1
  streetAddress2
  city
  cityArea
  postalCode
  country {
    country
    code
  }
  countryArea
  phone
  isDefaultShippingAddress
  isDefaultBillingAddress
}
`

export const OFFER_FRAGMENT_FOR_CABINET = `
${BASIC_PRODUCT_FRAGMENT}
  fragment OfferFragmentForCabinet on Offer {
    id
    description
    noSuggestion
    quantity
    discount
    productTotalPrice
    totalPrice
    status
    number
    product {
      ...BasicProductFields
    }
  }
  `

export const USER_FRAGMENT = `
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  ${CATEGORY_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${OFFER_FRAGMENT_FOR_CABINET}
  fragment UserFragment on User {
    id
    email
    firstName
    lastName
    username
    paypalEmail
    contactsPersonName
    companyName
    country
    state
    phone
    city
    isStaff
    conversationId
    contactsPersonPosition
    vendorBalance
    vendorActiveProducts
    unreadMessages
    rating
    positiveFeedbackPercentage
    status
    activity
    averageResponseTime
    dateJoined
    userRole
    offersAmount
    defaultShippingAddress {
      ...AddressFragment
    }
    defaultBillingAddress {
      ...AddressFragment
    }
    jwtToken
    sex
    userPermissions {
      code
      name
    }
    avatar {
      url
      alt
    }
    ordersAmount
    sales
    subscription {
      id
      name
      price
      description
    }
    subscriptionNextPayment
    offers(
      first: 10
      filter : { status: DRAFT }
      ){
      totalCount
      edges {
        cursor
        node {
          ...OfferFragmentForCabinet
        }
      }
    }
 
    topItems(first: 5, sortBy: { direction: DESC, field: ORDERS }) {
      edges {
        node {
          ...BasicProductFields
          ...ProductPricingField
          ordersAmount
          category {
            ...CategoryFragment
            parent {
              ...CategoryFragment
              parent {
                ...CategoryFragment
              }
            }
          }
        }
      }
    }
    activities(first: 10, sortBy: { direction: DESC, field: DATE }) {
      edges {
        node {
          id
          title
          event
          timePassed
        }
      }
    }
  }
`

export const USER_FRAGMENT_FOR_PRODUCT_PREVIEW = `
  ${ADDRESS_FRAGMENT}
  fragment UserFragmentForProductPreview on User {
    id
    email
    firstName
    lastName
    sex
    status
    companyName
    userRole
    defaultBillingAddress {
      ...AddressFragment
    }
    avatar {
      url
      alt
    }
  }
`

export const USER_FRAGMENT_FOR_USER_LIST = `
  ${ADDRESS_FRAGMENT}
  fragment UserFragmentForUserList on User {
    id
    email
    firstName
    lastName
    sex
    status
    companyName
    username
    paypalEmail
    vendorBalance
    country
    phone
    city
    userRole
    subscription {
      id
      name
      price
      description
    }
    defaultBillingAddress {
      ...AddressFragment
    }
    defaultShippingAddress {
      ...AddressFragment
    }
    avatar {
      url
      alt
    }
  }
`

export const USER_PART_FRAGMENT = `
fragment UserPartFragment on User {
  id
  email
  firstName
  lastName
  sex
  unreadMessages
  userRole
}
`

export const STATISTIC_USER_FRAGMENT = `
  fragment StatisticUserFragment on User {
    id
    firstName
    lastName
    username
    lastLogin
    companyName
    dateJoined
    canceledOrdersAmount
    completedOrdersAmount
    inProgressOrdersAmount
    country
    itemsAmount
    ordersAmount
    userRole
    subscription {
      name
    }
  }
`
