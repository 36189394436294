import React, { FC } from 'react'

import { Button, Row } from '../../../../components'

import { ProductPreviewButtonsRowProps } from './product-preview-buttons-row.types'
import { useStyle } from './product-preview-buttons-row.styles'

export const ProductPreviewButtonsRow: FC<ProductPreviewButtonsRowProps> = ({
  onContactVendor,
  onGetNewOffer
}) => {
  const classes = useStyle()

  return (
    <Row fullWidth className={classes.btnRow} justifyContent="flex-start">
      <Button
        disabled
        className={classes.button}
        color="yellow"
        preset="primary"
        text="START CHAT WITH MAKER"
        tx="vendor.start.chat.with.maker"
        textColor="white"
        textPreset="h5"
        onClick={onGetNewOffer}
      />
      <Button
        disabled
        className={classes.button}
        color="yellow"
        preset="secondaryBorder"
        text="GO TO COMMUNICATION"
        tx="vendor.go.to.comminucation"
        textColor="yellow"
        textPreset="h5"
        onClick={onContactVendor}
      />
    </Row>
  )
}
