import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '56px 16px'
  },
  title: {},
  description: {
    marginTop: 24
  },
  refundTitle: {
    marginLeft: 4
  },
  input: {
    marginTop: 24
  }
})
