import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  btnRow: {
    columnGap: 16,
    rowGap: 16,
    marginTop: 24
  },
  button: {
    maxWidth: 280
  },
  '@media screen and (max-width: 1152px)': {
    btnRow: {
      flexWrap: 'wrap'
    },
    button: {
      maxWidth: '100%'
    }
  }
})
