import React, { FC } from 'react'

import {
  arrayHasElements,
  Icon,
  ICONS,
  Row,
  Section
} from '../../../components'
import { ProductOptionVariantInput } from '../../../services'

import { OptionItemModal, OptionTable } from '../../views'

import { EmptySection } from '../empty-section'
import { getNextOptions, getOptions } from '../helpers'

import { useStyle } from './option-section.styles'
import { OptionSectionProps } from './option-section.types'

export const OptionSection: FC<OptionSectionProps> = ({
  title,
  type,
  optionVariants,
  value,
  onDeleteOption,
  onChangeValue
}) => {
  const classes = useStyle()

  const handleOnChangeOptionTable =
    (optionTitle: string) => (optionsValue: ProductOptionVariantInput[]) => {
      const { options = [] } = value
      const nextOptions = getNextOptions(options, optionTitle, optionsValue)
      const nextVariables = {
        ...value,
        options: nextOptions
      }

      if (onChangeValue) {
        onChangeValue(nextVariables)
      }
    }

  const handleOnSubmitOption =
    (optionTitle: string) => (variant: ProductOptionVariantInput) => {
      if (value.options) {
        const options = getOptions(value.options, optionTitle, variant)

        if (onChangeValue) {
          onChangeValue({ ...value, options })
        }
      }
    }

  const optionItemModal = (optionTitle: string) => (
    <OptionItemModal
      onSubmit={handleOnSubmitOption(optionTitle)}
      title={optionTitle}
    />
  )

  const rightIcon = (
    <Row
      className={classes.icon}
      onClick={onDeleteOption}
      justifyContent="center"
    >
      <Icon src={ICONS.trash} />
    </Row>
  )

  return (
    <Section
      className={classes.section}
      rightButton={optionItemModal(title)}
      rightIcon={rightIcon}
      title={title}
    >
      {optionVariants && arrayHasElements(value.options) ? (
        <OptionTable
          type={type}
          data={optionVariants}
          title={title}
          onChange={handleOnChangeOptionTable(title)}
        />
      ) : (
        <EmptySection
          className={classes.sectionAdditionWithText}
          text="Add at least one option."
          tx="item.form.no.variant"
        />
      )}
    </Section>
  )
}
