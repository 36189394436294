export const ATTRIBUTE_VALUE_FRAGMENT = `
  fragment AttributeValueFragment on AttributeValue {
    id
    name
    slug
  }
`

export const ATTRIBUTE_FRAGMENT = `
  fragment AttributeFragment on AttributeType {
    id
    type
    title
  }
`

export const PRODUCT_ATTRIBUTE_FRAGMENT = `
  ${ATTRIBUTE_FRAGMENT}
  fragment ProductAttributeFragment on ProductAttributeType {
    id
    type
    attribute {
      ...AttributeFragment
    }
  }
`
