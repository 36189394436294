import React from 'react'

import {
  CategoryNav,
  CategoryNavItem,
  Column,
  ICONS,
  PermissionContent,
  Row,
  Text
} from '../../components'
import { PermissionEnum } from '../../services'

import { AdvertisementsPage } from '../advertisements'
import { PromotionsTabNavigation } from '../cabinet-navigator'
import { PromotionBannersPage } from '../promotion-banners'

import { useStyle } from './promotion.styles'

export const PromotionPage = () => {
  const classes = useStyle()

  const NAVIGATION_TABS: CategoryNavItem[] = [
    {
      text: 'Banners on Page',
      tx: 'promotion.anners.on.page',
      link: PromotionsTabNavigation.PROMOTIONS,
      icon: ICONS.collections,
      content: (
        <PermissionContent
          permissions={[PermissionEnum.CREATE_OWN_PROMOTION_BANNER]}
        >
          <PromotionBannersPage />
        </PermissionContent>
      )
    },
    {
      text: 'Advertisements ',
      tx: 'promotion.advertisements',
      link: PromotionsTabNavigation.ADVERTISEMENTS,
      icon: ICONS.localActivity,
      content: (
        <PermissionContent permissions={[PermissionEnum.CREATE_OWN_SALES]}>
          <AdvertisementsPage />
        </PermissionContent>
      )
    }
  ]

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="flex-start">
        <Text color="black" preset="h3" text="Promotion" tx="promotion.title" />
      </Row>
      <CategoryNav className={classes.category} data={NAVIGATION_TABS} />
    </Column>
  )
}
