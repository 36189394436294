import React, { ChangeEvent, FC, useState } from 'react'

import { Text } from '../text'
import { Column } from '../column'
import { Modal } from '../modal'
import { TextField } from '../text-field'
import { Icon, ICONS } from '../icon'
import { PaypalButtonsView } from '../paypal-buttons'

import { useStyle } from './add-funds-modal.styles'
import { AddFundsModalProps } from './add-funds-modal.types'

export const AddFundsModal: FC<AddFundsModalProps> = ({
  title,
  titleTx,
  description,
  descriptionTx,
  secondaryDescription,
  secondaryDescriptionTx,
  id,
  value,
  open,
  onClose
}) => {
  const classes = useStyle()

  const [amount, changeAmount] = useState('0.1')
  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />

  if (!open) {
    return <></>
  }

  const handleOnChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value: nextValue } = event.target
    if (nextValue !== '') {
      changeAmount(nextValue)
    } else changeAmount('0.1')
  }

  return (
    <Modal
      className={classes.modal}
      title={title}
      titleTx={titleTx}
      onClose={onClose}
    >
      <Column fullWidth className={classes.bottomContainer}>
        <Text
          className={classes.description}
          color="black"
          preset="h4"
          text={description}
          tx={descriptionTx}
        />
        <Text
          className={classes.secondaryDesctiption}
          color="inactive"
          preset="body"
          text={secondaryDescription}
          tx={secondaryDescriptionTx}
        />
        <Text
          className={classes.valueTitle}
          color="darkBlue"
          preset="h6"
          text="The current amount in the personal account:"
          tx="add.funds.modal.current.account.title"
        />
        <Text
          className={classes.secondaryDesctiption}
          color="error"
          preset="h4"
          text={value}
        />
        <TextField
          className={classes.field}
          label="Added Funds"
          labelTx="add.funds.modal.added.funds:"
          type="number"
          rightElement={dollarIcon}
          onChange={handleOnChange}
        />
        <Column fullWidth className={classes.buttonsContainer}>
          <PaypalButtonsView amount={String(amount)} orderId={id} />
        </Column>
      </Column>
    </Modal>
  )
}
