import {
  LazyQueryExecFunction,
  MutationResult,
  OperationVariables
} from '@apollo/client'

import { TextProps } from '../../components'

import { CountryCode } from '../country'
import { PaginationList } from '../api.types'
import { CountableConnectionT, Product } from '../product'
import { CreditCard, Order, OrderLine, OrderNotificationType } from '../order'
import { Money } from '../tax'
import { Offer } from '../offer'

export interface RegisterCredentials {
  email: string
  password: string
  firstName: string
  lastName: string
  role: number
  sex: Sex | string
  username: string
  companyName: string
  country: string
  state: string
  city: string
  address: string
  zipcode: string
  phone: string
  contactsPersonName: string
  contactsPersonPosition: string
  paypalEmail: string
}

export interface GiftCard extends Node {
  code: String
  user: User
  created: string
  startDate: Date
  endDate: Date
  lastUsedOn: string
  isActive: boolean
  initialBalance: Money
  currentBalance: Money
  displayCode: String
}

export type GiftCardCountableEdge = {
  node: GiftCard
  cursor: string
}
export type GiftCardCountableConnection =
  CountableConnectionT<GiftCardCountableEdge>

export enum PermissionEnum {
  MANAGE_USERS = 'MANAGE_USERS',
  MANAGE_STAFF = 'MANAGE_STAFF',
  MANAGE_SERVICE_ACCOUNTS = 'MANAGE_SERVICE_ACCOUNTS',
  CREATE_MULTIPLE_ACCOUNTS = 'CREATE_MULTIPLE_ACCOUNTS',
  SET_UP_PROFILE = 'SET_UP_PROFILE',
  SET_UP_OWN_PROFILE = 'SET_UP_OWN_PROFILE',
  VIEW_ACCOUNTS = 'VIEW_ACCOUNTS',
  MANAGE_PERMISSIONS = 'MANAGE_PERMISSIONS',
  CREATE_EDIT_DELETE_ROLE = 'CREATE_EDIT_DELETE_ROLE',
  CREATE_EDIT_DELETE_SUPERUSER = 'CREATE_EDIT_DELETE_SUPERUSER',
  CREATE_EDIT_DELETE_USER = 'CREATE_EDIT_DELETE_USER',
  RESPOND_TO_CUSTOMER_REVIEWS = 'RESPOND_TO_CUSTOMER_REVIEWS',
  RESPOND_TO_OWN_CUSTOMER_REVIEWS = 'RESPOND_TO_OWN_CUSTOMER_REVIEWS',
  START_COMMUNICATION_WITH_THE_SUPPORT = 'START_COMMUNICATION_WITH_THE_SUPPORT',
  START_COMMUNICATION_WITH_THE_CUSTOMER = 'START_COMMUNICATION_WITH_THE_CUSTOMER',
  MANAGE_APPS = 'MANAGE_APPS',
  MANAGE_DISCOUNTS = 'MANAGE_DISCOUNTS',
  MANAGE_PLUGINS = 'MANAGE_PLUGINS',
  MANAGE_GIFT_CARD = 'MANAGE_GIFT_CARD',
  MANAGE_MENUS = 'MANAGE_MENUS',
  MANAGE_ORDERS = 'MANAGE_ORDERS',
  ADD_CHANGE_REMOVE_ORDERS = 'ADD_CHANGE_REMOVE_ORDERS',
  ADD_CHANGE_REMOVE_OWN_ORDERS = 'ADD_CHANGE_REMOVE_OWN_ORDERS',
  MANAGE_ORDER_STATUS = 'MANAGE_ORDER_STATUS',
  MANAGE_OWN_ORDER_STATUS = 'MANAGE_OWN_ORDER_STATUS',
  MANAGE_RETURN_EXCHANGE_PROCESS = 'MANAGE_RETURN_EXCHANGE_PROCESS',
  INITIATE_ORDER_RETURN = 'INITIATE_ORDER_RETURN',
  MANAGE_PAGES = 'MANAGE_PAGES',
  MANAGE_PRODUCTS = 'MANAGE_PRODUCTS',
  ADD_CHANGE_REMOVE_ITEMS = 'ADD_CHANGE_REMOVE_ITEMS',
  ADD_CHANGE_REMOVE_OWN_ITEMS = 'ADD_CHANGE_REMOVE_OWN_ITEMS',
  CREATE_ATTRIBUTES = 'CREATE_ATTRIBUTES',
  CREATE_PRODUCT_TYPES = 'CREATE_PRODUCT_TYPES',
  ADD_CHANGE_REMOVE_CATEGORIES_AND_SUB_CATEGORIES = 'ADD_CHANGE_REMOVE_CATEGORIES_AND_SUB_CATEGORIES',
  ADD_CHANGE_REMOVE_COLLECTIONS = 'ADD_CHANGE_REMOVE_COLLECTIONS',
  ADD_CHANGE_REMOVE_OWN_COLLECTIONS = 'ADD_CHANGE_REMOVE_OWN_COLLECTIONS',
  MANAGE_SHIPPING = 'MANAGE_SHIPPING',
  MANAGE_SETTINGS = 'MANAGE_SETTINGS',
  MANAGE_TRANSLATIONS = 'MANAGE_TRANSLATIONS',
  MANAGE_CHECKOUTS = 'MANAGE_CHECKOUTS',
  CREATE_PROMOTION_BANNER = 'CREATE_PROMOTION_BANNER',
  CREATE_OWN_PROMOTION_BANNER = 'CREATE_OWN_PROMOTION_BANNER',
  CREATE_DISCOUNTS = 'CREATE_DISCOUNTS',
  CREATE_OWN_DISCOUNTS = 'CREATE_OWN_DISCOUNTS',
  ITEM_SALES = 'ITEM_SALES',
  OWN_ITEM_SALES = 'OWN_ITEM_SALES',
  SALES_RECONCILIATION = 'SALES_RECONCILIATION',
  OWN_SALES_RECONCILIATION = 'OWN_SALES_RECONCILIATION',
  PROMOTE_CATEGORIES = 'PROMOTE_CATEGORIES',
  OWN_PROMOTE_CATEGORIES = 'OWN_PROMOTE_CATEGORIES',
  CREATE_OWN_SPONSORSHIP = 'CREATE_OWN_SPONSORSHIP',
  CREATE_SALES = 'CREATE_SALES',
  CREATE_OWN_SALES = 'CREATE_OWN_SALES',
  CREATE_COUPONS = 'CREATE_COUPONS',
  CREATE_OWN_COUPONS = 'CREATE_OWN_COUPONS',
  CREATE_PROMO_CODES = 'CREATE_PROMO_CODES',
  CREATE_OWN_PROMO_CODES = 'CREATE_OWN_PROMO_CODES',
  PROMOTE_STORE = 'PROMOTE_STORE',
  PROMOTE_OWN_STORE = 'PROMOTE_OWN_STORE',
  CREATE_PREMIUM_USER_BANNER = 'CREATE_PREMIUM_USER_BANNER',
  TECHNICAL_SETTINGS = 'TECHNICAL_SETTINGS',
  MANAGE_PAYOUT_TAX_INFORMATION = 'MANAGE_PAYOUT_TAX_INFORMATION',
  NON_FINANCIAL_REPORTS = 'NON_FINANCIAL_REPORTS',
  YOUR_INFO = 'YOUR_INFO',
  PROMOTIONS = 'PROMOTIONS',
  OFFERS = 'OFFERS',
  PROFILE_SETTINGS = 'PROFILE_SETTINGS',
  FEEDBACK_MONITORING = 'FEEDBACK_MONITORING',
  BILLING = 'BILLING',

  VIEW_VENDORS = 'VIEW_VENDORS',
  VIEW_CUSTOMERS = 'VIEW_CUSTOMERS',
  VIEW_VENDOR_BANNER = 'VIEW_VENDOR_BANNER',
  CREATE_VENDOR_BANNER = 'CREATE_VENDOR_BANNER',
  EDIT_VENDOR_BANNER = 'EDIT_VENDOR_BANNER',
  DELETE_VENDOR_BANNER = 'DELETE_VENDOR_BANNER',
  VIEW_MARKETPLACE_BANNER = 'VIEW_MARKETPLACE_BANNER',
  CREATE_MARKETPLACE_BANNER = 'CREATE_MARKETPLACE_BANNER',
  EDIT_MARKETPLACE_BANNER = 'EDIT_MARKETPLACE_BANNER',
  DELETE_MARKETPLACE_BANNER = 'DELETE_MARKETPLACE_BANNER',
  VIEW_COMMUNICATION_CUSTOMER = 'VIEW_COMMUNICATION_CUSTOMER',
  DELETE_COMMUNICATION_CUSTOMER = 'DELETE_COMMUNICATION_CUSTOMER',
  VIEW_COMMUNICATION_VENDOR = 'VIEW_COMMUNICATION_VENDOR',
  DELETE_COMMUNICATION_VENDOR = 'DELETE_COMMUNICATION_VENDOR',
  VIEW_COMMUNICATION_OFFERS_ORDER = 'VIEW_COMMUNICATION_OFFERS_ORDER',
  DELETE_COMMUNICATION_OFFERS_ORDER = 'DELETE_COMMUNICATION_OFFERS_ORDER',
  VIEW_COMMUNICATION_RETURN = 'VIEW_COMMUNICATION_RETURN',
  CREATE_COMMUNICATION_RETURN = 'CREATE_COMMUNICATION_RETURN',
  EDIT_COMMUNICATION_RETURN = 'EDIT_COMMUNICATION_RETURN',
  DELETE_COMMUNICATION_RETURN = 'DELETE_COMMUNICATION_RETURN',
  VIEW_COMMUNICATION_EXCEED_TIMELINE = 'VIEW_COMMUNICATION_EXCEED_TIMELINE',
  DELETE_COMMUNICATION_EXCEED_TIMELINE = 'DELETE_COMMUNICATION_EXCEED_TIMELINE',
  VIEW_STATISTIC = 'VIEW_STATISTIC',
  VIEW_PAYOUT = 'VIEW_PAYOUT',
  INITIATE_PAYOUT_TO_VENDOR = 'INITIATE_PAYOUT_TO_VENDOR',
  INITIATE_REFUND_TO_CUSTOMER = 'INITIATE_REFUND_TO_CUSTOMER',
  VIEW_PAYOUT_REFUND_REPORT = 'VIEW_PAYOUT_REFUND_REPORT',
  PAYOUT_FROM_PAYPAL = 'PAYOUT_FROM_PAYPAL',
  REFUND_FROM_PAYPAL = 'REFUND_FROM_PAYPAL',
  VIEW_PAYOUT_REFUND_PAYPAL_REPORT = 'VIEW_PAYOUT_REFUND_PAYPAL_REPORT',
  SALES_TAX = 'SALES_TAX',
  SHIPPING_COST = 'SHIPPING_COST',
  VIEW_ITEM = 'VIEW_ITEM',
  CREATE_ITEM = 'CREATE_ITEM',
  EDIT_ITEM = 'EDIT_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
  ACTIVATE_DEACTIVATE_ITEM = 'ACTIVATE_DEACTIVATE_ITEM',
  VIEW_ORDER = 'VIEW_ORDER',
  CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS',
  SHIPPED_ORDER_STATUS = 'SHIPPED_ORDER_STATUS',
  VIEW_COMMUNICATION = 'VIEW_COMMUNICATION'
}

export enum UserRoleEnum {
  VENDOR = 'VENDOR',
  CUSTOMER = 'CUSTOMER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  DEVELOPER = 'DEVELOPER',
  TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
  BUSINESS_ANALYTIC = 'BUSINESS_ANALYTIC',
  VENDOR_SUPPORT = 'VENDOR_SUPPORT',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  FINANCIAL_MANAGER = 'FINANCIAL_MANAGER',
  MARKETING_PUBLISHER = 'MARKETING_PUBLISHER',
  WAREHOUSE = 'WAREHOUSE'
}

export type Permission = {
  code: PermissionEnum
  name: string
}

export interface Group extends Node {
  name: string
  permissions: Permission[]
  users: User[]
  userCanManage: boolean
}

export type UserPermission = {
  code: PermissionEnum
  name: string
  sourcePermissionGroups: Group[]
}

export enum CustomerEventsEnum {
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  PASSWORD_RESET_LINK_SENT = 'PASSWORD_RESET_LINK_SENT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  EMAIL_CHANGED_REQUEST = 'EMAIL_CHANGED_REQUEST',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  EMAIL_CHANGED = 'EMAIL_CHANGED',
  PLACED_ORDER = 'PLACED_ORDER',
  NOTE_ADDED_TO_ORDER = 'NOTE_ADDED_TO_ORDER',
  DIGITAL_LINK_DOWNLOADED = 'DIGITAL_LINK_DOWNLOADED',
  CUSTOMER_DELETED = 'CUSTOMER_DELETED',
  NAME_ASSIGNED = 'NAME_ASSIGNED',
  EMAIL_ASSIGNED = 'EMAIL_ASSIGNED',
  NOTE_ADDED = 'NOTE_ADDED'
}

export interface CustomerEvent extends Node {
  date: Date
  type: CustomerEventsEnum
  user: User
  message: string
  count: number
  order: Order
  orderLine: OrderLine
}

export type PaymentSource = {
  gateway: string
  creditCardInfo: CreditCard
}

export interface AddressTypeInput {
  city: string
  cityArea?: string
  companyName?: string
  country: string
  countryArea?: string
  firstName?: string
  lastName?: string
  phone: string
  postalCode: string
  streetAddress1: string
  streetAddress2?: string
}
export interface AddressType {
  id: string
  city: string
  cityArea?: string
  companyName?: string
  country: {
    code: string
    country: string
  }
  countryArea?: string
  firstName: string
  lastName: string
  phone: string
  postalCode: string
  streetAddress1: string
  streetAddress2?: string
}

export interface ProductWithAmount extends Product {
  ordersAmount: number
}

export type TopItems = PaginationList<ProductWithAmount>
export type Activities = PaginationList<OrderNotificationType>

export interface UserPermissions {
  __typename: 'UserPermission'
  code: PermissionEnum
  name: string
}

export interface UserAvatar {
  __typename: 'Image'
  url: string
}

export interface UserCredentials {
  email: string
  password: string
}

export type AddressInput = {
  firstName: string
  lastName: string
  companyName: string
  streetAddress1: string
  streetAddress2: string
  city: string
  cityArea: string
  postalCode: string
  country: CountryCode
  countryArea: string
  phone: string
  username: string
}

export type AccountInput = {
  firstName?: string
  lastName?: string
  sex?: string | Sex
  phone?: string
  email?: string
  companyName?: string
  username?: string
  country?: string
  state?: string
  contactsPersonName?: string
  contactsPersonPosition?: string
  paypalEmail?: string
  vendorBalance?: number
  defaultBillingAddress?: AddressInput
  defaultShippingAddress?: AddressInput
  conversationId?: string
}

export type Address = {
  id: string
  firstName: string
  lastName: string
  companyName: string
  streetAddress1: string
  streetAddress2: string
  city: string
  cityArea: string
  postalCode: string
  country: {
    country: string
    code: CountryCode
  }
  countryArea: string
  phone: string
  isDefaultShippingAddress: boolean
  isDefaultBillingAddress: boolean
}

export enum ChatUserRole {
  STAFF = 'Staff',
  VENDOR = 'Vendor',
  CUSTOMER = 'Customer'
}

export interface SubscriptionTypeProfile {
  id: string
  name: string
  pricePer?: string
  price: number
  description: string
  paypalPlanId: string
}

export interface User {
  __typename: 'User'
  userPermissions: UserPermissions[]
  userRole: string
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  city: string
  location?: string
  jwtToken: string
  sex: Sex
  defaultShippingAddress: Address | null
  defaultBillingAddress: Address | null
  status: string
  activity: string
  averageResponseTime: string
  avatar: UserAvatar | null
  ordersAmount: number
  vendorActiveProducts: number
  sales: number
  topItems: TopItems
  activities: Activities
  companyName?: string
  vendorBalance?: number
  country: string
  state: string
  username: string
  offers: PaginationList<Offer>
  subscription?: SubscriptionTypeProfile
  subscriptionNextPayment: Date
  isStaff: boolean
  conversationId?: string
  unreadMessages: number
  rating?: string
  positiveFeedbackPercentage?: string
  dateJoined?: string
  paypalEmail: string
  offersAmount?: number
}

export enum Sex {
  MALE = 1,
  FEMALE = 2
}

export enum AccountErrorCode {
  ACTIVATE_OWN_ACCOUNT = 'ACTIVATE_OWN_ACCOUNT',
  ACTIVATE_SUPERUSER_ACCOUNT = 'ACTIVATE_SUPERUSER_ACCOUNT',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  DEACTIVATE_OWN_ACCOUNT = 'DEACTIVATE_OWN_ACCOUNT',
  DEACTIVATE_SUPERUSER_ACCOUNT = 'DEACTIVATE_SUPERUSER_ACCOUNT',
  DELETE_NON_STAFF_USER = 'DELETE_NON_STAFF_USER',
  DELETE_OWN_ACCOUNT = 'DELETE_OWN_ACCOUNT',
  DELETE_STAFF_ACCOUNT = 'DELETE_STAFF_ACCOUNT',
  DELETE_SUPERUSER_ACCOUNT = 'DELETE_SUPERUSER_ACCOUNT',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  LEFT_NOT_MANAGEABLE_PERMISSION = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  NOT_FOUND = 'NOT_FOUND',
  OUT_OF_SCOPE_SERVICE_ACCOUNT = 'OUT_OF_SCOPE_SERVICE_ACCOUNT',
  OUT_OF_SCOPE_USER = 'OUT_OF_SCOPE_USER',
  OUT_OF_SCOPE_GROUP = 'OUT_OF_SCOPE_GROUP',
  OUT_OF_SCOPE_PERMISSION = 'OUT_OF_SCOPE_PERMISSION',
  PASSWORD_ENTIRELY_NUMERIC = 'PASSWORD_ENTIRELY_NUMERIC',
  PASSWORD_TOO_COMMON = 'PASSWORD_TOO_COMMON',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  PASSWORD_TOO_SIMILAR = 'PASSWORD_TOO_SIMILAR',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  JWT_SIGNATURE_EXPIRED = 'JWT_SIGNATURE_EXPIRED',
  JWT_INVALID_TOKEN = 'JWT_INVALID_TOKEN',
  JWT_DECODE_ERROR = 'JWT_DECODE_ERROR',
  JWT_MISSING_TOKEN = 'JWT_MISSING_TOKEN',
  JWT_INVALID_CSRF_TOKEN = 'JWT_INVALID_CSRF_TOKEN'
}

export interface GetRegisterRequest {
  input: RegisterCredentials
}

export interface UserRegistration extends User {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  jwtToken: string
  csrfToken: string
}

export type GetChangeEmail = {
  password: string
  newEmail: string
  redirectUrl?: string
  cancelUrl?: string
  isDashboard: boolean
}

export type AccountErrors = {
  code: AccountErrorCode
  field: string
  message: string
}

export interface GetLoginResponse {
  token: string
}

export interface AccountError {
  code: AccountErrorCode
  field: string
  message: string
}

export interface GetRegisterResults {
  accountRegister: {
    errors: AccountError[]
    requiresConfirmation: boolean
    jwtToken: string
    refreshToken: string
    csrfToken: string
    talkjsSignature?: string
    user: User
  }
}

export interface AccountUpdateResults {
  accountUpdate: {
    accountErrors: AccountError[]
    user: User
  }
}

export interface AccountUpdateRequest {
  input: Partial<AccountInput>
}

export interface UpdateAvatarImageResults {
  updateAvatar: {
    accountErrors: AccountError[]
    user: User
  }
}
export type GetChangeEmailResults = {
  requestEmailChange: {
    accountErrors: AccountErrors[]
  }
}

export interface UpdateAvatarImageRequest {
  file: File
}

export interface GetTokenCreateResults {
  tokenCreate: {
    token: string
    refreshToken: string
    csrfToken: string
    talkjsSignature?: string
    user: UserRegistration
    errors: AccountError[]
  }
}

export interface GetTokenRefreshResults {
  tokenRefresh: {
    accountErrors: AccountError
    user: User
    token: string
    talkjsSignature?: string
  }
}

export interface GetTokenCreateRequest {
  email: string
  password: string
  page?: string
  name?: string
  sex?: Sex
  error?: TextProps | undefined
}

export type IsUserExistVariables = {
  email: string
}

export interface UserMe {
  me: UserRegistration
}

export type GetAccountMeData = {
  me: User
}

export type GetPartAccountMeData = {
  me: User
}

export type AccountMe = {
  data?: GetAccountMeData
  lazyFetch: LazyQueryExecFunction<GetAccountMeData, OperationVariables>
  loading: boolean
  refetch: () => void
}

export type AccountPartMe = {
  data?: GetPartAccountMeData
  loading: boolean
  refetch: () => void
}

export interface IsUserExistResults {
  isUserExists: {
    isUserExists: boolean
  }
}

export interface IsUserExist {
  onSubmit: (variables: IsUserExistVariables) => void
  response: MutationResult<IsUserExistResults>
}

export interface TokenCreate {
  onSubmit: (varialbles: GetTokenCreateRequest) => void
  response: MutationResult<GetTokenCreateResults>
}

export interface TokenRefresh {
  onSubmit: (refreshToken: string) => void
  response: MutationResult<GetTokenRefreshResults>
}

export interface UserRegister {
  onSubmit: () => void
  response: MutationResult<GetRegisterResults>
}

export interface AuthApiOptions {
  setHeader: (key: string, prop: string) => void
}

export type ChangeEmail = {
  onSubmit: (variables: GetChangeEmail) => void
  response: MutationResult<GetChangeEmailResults>
}

export interface AuthApi {
  useToken: () => TokenCreate
  useChangeEmail: () => ChangeEmail
  useRegister: (input: RegisterCredentials) => UserRegister
  useRefreshToken: () => TokenRefresh
  useIsLoggedIn: () => boolean
  useIsUserExist: () => IsUserExist
  useMe: () => AccountMe
  usePartMe: () => AccountPartMe
}
