import { LightTheme } from '../Theme'

import { ButtonStyleProps } from './button.types'

export const buttonPresets = (theme: LightTheme) => ({
  borderButton: {
    padding: '4px 8px',
    borderRadius: 4,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`
  },
  button2: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    borderRadius: 4,
    padding: '4px 8px'
  },
  button3: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    borderRadius: 4,
    padding: '12px 8px'
  },
  borderButton3: {
    borderRadius: 4,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    padding: '12px 8px'
  },
  button4: {
    width: '100%',
    maxWidth: 96,
    height: 40,
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    borderRadius: 4
  },
  button5: {
    width: '100%',
    maxWidth: 80,
    padding: '8px 0',
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `1px solid ${theme.colors[color]}`,
    '&:hover': {
      backgroundColor: theme.colors.lightBlue
    }
  },
  fixedPrimaryButton: {
    padding: 16,
    width: 204,
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    borderRadius: 4
  },
  fixedBorderButton: {
    padding: 14,
    borderRadius: 4,
    width: 204,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`
  },
  fixedBorderButtonLong: {
    padding: 14,
    borderRadius: 4,
    width: 256,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`
  },
  primary: {
    padding: '16px 10px',
    width: '100%',
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    borderRadius: 4
  },
  secondary: {
    padding: '14px 24px',
    width: '100%',
    borderRadius: 4,
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color]
  },
  secondaryBorder: {
    padding: '14px 10px',
    width: '100%',
    borderRadius: 4,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`
  },
  secondaryBorderLong: {
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '8px 4px',
    width: 148
  },
  secondaryLong: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    borderRadius: 4,
    padding: '10px 4px',
    width: 148
  },
  secondaryBorderMini: {
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '10px 4px',
    width: 120
  },
  secondaryBorderMiniWidth: {
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '8px 4px',
    minWidth: 120
  },
  secondaryMini: {
    backgroundColor: ({ color = 'yellow' }: ButtonStyleProps) =>
      theme.colors[color],
    border: ({ color = 'yellow' }: ButtonStyleProps) =>
      `2px solid ${theme.colors[color]}`,
    borderRadius: 4,
    padding: '10px 4px',
    width: 120
  },
  cards: {
    padding: 8,
    width: '100%',
    maxWidth: 160,
    backgroundColor: theme.colors.transparent,
    border: ({ color = 'shadow' }: ButtonStyleProps) =>
      `1px solid ${theme.colors[color]}`,
    '&:hover': {
      backgroundColor: theme.colors.lightBlue
    }
  }
})
