import { createUseStyles } from 'react-jss'
import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  itemNumber: {
    width: 64
  },
  title: {
    marginLeft: 16,
    width: 300
  },
  line: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '12px 0px',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  observeOptionsBtn: {
    padding: '8px 6px',
    marginLeft: 16,
    textTransform: 'uppercase'
  },
  column: {
    minWidth: 120,
    maxWidth: 260
  },
  infoRow: {},
  delivery: {
    wordBreak: 'break-word'
  },
  tooltip: {
    marginLeft: 8
  },

  '@media screen and (max-width: 480px)': {
    title: {
      width: '100%'
    },
    line: {
      padding: 0
    },
    infoRow: {
      marginTop: 24,
      justifyContent: 'space-around'
    }
  }
}))
