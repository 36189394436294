import React, { forwardRef, useMemo, useState } from 'react'

import { Column, Row, getCurrencySymbol } from '../../components'
import { Currency } from '../../services'
import { getColorByMainStatus, getMessageByMainStatus } from '../../utils'

import { ChatOverview } from '../chat-overview'
import { DeclineOrderModal } from '../decline-order-modal'

import { useStyle } from './offer-chat.styles'
import { OrderChatViewProps } from './offer-chat.types'

export const OrderChatView = forwardRef<HTMLDivElement, OrderChatViewProps>(
  ({ order, onDecline }, ref) => {
    const [openDecline, changeOpenDecline] = useState(false)

    const classes = useStyle()

    const CurrencySybmol = useMemo(() => getCurrencySymbol(Currency.USD), [])

    const handleOnChangeDeclineModal = (state: boolean) => () => {
      changeOpenDecline(state)
    }

    const { offer, number, mainStatus, paymentStatus, totalPrice } = order

    const statusTextProps = getMessageByMainStatus(mainStatus)
    const title = `# ${number}`
    const titleValues = { number }

    return (
      <>
        <Row fullWidth className={classes.content}>
          <ChatOverview
            declineText="Decline Order"
            declineTx="declineOrder.modal.title"
            statusColor={getColorByMainStatus(mainStatus)}
            title={title}
            titleTx="order.number"
            titleValues={titleValues}
            statusText={statusTextProps.text}
            statusTx={statusTextProps.tx}
            offer={offer}
            orderDelievryPrice={order.deliveryPrice || 0}
            currencySymbol={CurrencySybmol}
            onDecline={handleOnChangeDeclineModal(true)}
          />
          <Column
            fullWidth
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.chatboxContainer}
          >
            <Column
              fullWidth
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.chatbox}
              ref={ref}
            />
          </Column>
        </Row>
        <DeclineOrderModal
          open={openDecline}
          orderNumber={number}
          paymentStatus={paymentStatus}
          totalPrice={String(totalPrice)}
          onClose={handleOnChangeDeclineModal(false)}
          onSubmit={onDecline}
        />
      </>
    )
  }
)
