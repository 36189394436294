import React, { FC } from 'react'

import { OrderUpdateVariables } from '../../services'

import { MenuSlider } from '../menu-slider'
import { OrderLine } from '../order-line'
import { OrderLineOffer } from '../order-line-offer'
import { OfferLineVariant } from '../offer-line-variant'
import { Column } from '../column'

import { OrderSliderHeader } from './order-slider-header'
import { OrderSliderDescription } from './order-slider-description'
import { useStyle } from './order-slider.styles'
import { OrderSliderProps } from './order-slider.types'
import { zeroPad } from '../../pages/views'

export const OrderSlider: FC<OrderSliderProps> = ({
  isStaff,
  className = '',
  orderId,
  number,
  created,
  startDate,
  finishDate,
  trackingNumber,
  price,
  totalPrice,
  status,
  mainStatus,
  paymentStatus,
  vendorName,
  customerName,
  vendor,
  shippingAddress,
  lines,
  offer,
  remainingTimeProductionStarted,
  deliveryPrice,
  onChange,
  onContactCustomer,
  onContactVendor
}) => {
  const classes = useStyle()

  const handleOnChange = (variables: OrderUpdateVariables) => {
    if (onChange) {
      onChange(variables)
    }
  }

  const isOrderFromOffer = offer !== null

  const header = (
    <OrderSliderHeader
      id={orderId}
      number={number}
      status={status}
      mainStatus={mainStatus}
      paymentStatus={paymentStatus}
      created={created}
      price={price}
      vendorName={vendorName}
      customerName={customerName}
      onChangeStatus={handleOnChange}
    />
  )
  return (
    <MenuSlider
      className={`${className} ${classes.container}`}
      contentClassName={classes.content}
      header={header}
    >
      <OrderSliderDescription
        isStaff={!isStaff}
        id={orderId}
        paymentStatus={paymentStatus}
        mainStatus={mainStatus}
        remainingTimeProductionStarted={remainingTimeProductionStarted}
        startDate={startDate}
        finishDate={finishDate}
        trackingNumber={trackingNumber}
        shippingAddress={shippingAddress}
        vendor={vendor}
        price={price}
        number={number}
        onChange={handleOnChange}
        onContactCustomer={onContactCustomer}
        onContactVendor={onContactVendor}
      />

      {!isOrderFromOffer &&
        lines.map((orderLine, index) => (
          <OrderLine
            className={classes.line}
            {...orderLine}
            key={`order_${orderId}_orderLine_${orderLine.id}_${index}`}
            price={orderLine.totalPrice}
            product={orderLine.product}
          />
        ))}

      {isOrderFromOffer && (
        <Column fullWidth>
          <OrderLineOffer
            className={classes.line}
            product={lines[0].product}
            totalPrice={totalPrice}
            deliveryPrice={deliveryPrice}
            quantity={lines[0].quantity}
            offer={offer}
          />
          {lines[0].items.map((item, index) => {
            const initialIndex = index + 1
            const itemNumber = zeroPad(initialIndex, 2)

            return (
              <OfferLineVariant
                key={`item_${orderId}_orderLine_${item.id}_${index}`}
                className={classes.line}
                count={itemNumber}
                product={lines[0].product}
                totalPrice={totalPrice}
                deliveryPrice={deliveryPrice}
                quantity={lines[0].quantity}
                offer={offer}
                offerItem={item}
                isTooltip={true}
              />
            )
          })}
        </Column>
      )}
    </MenuSlider>
  )
}
