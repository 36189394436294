export const COLLECTION_FRAGMENT = `
  fragment CollectionFragment on Collection {
    id
    name
    backgroundImage {
      url
      alt
    }
    description
    isPublished
    visibleInListings
  }
`
