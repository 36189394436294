import React, { FC, MouseEvent } from 'react'
import { useTheme } from 'react-jss'

import { useStyle } from './table-items.styles'
import { ItemsTableItemProps } from './table-items.types'
import { LightTheme } from '../../../components/Theme'
import {
  Checkbox,
  ColorDot,
  PermissionContent,
  Row,
  Text
} from '../../../components'
import { getDotColor, getStatusLabel } from './helpers'
import { PermissionEnum } from '../../../services'

export const TableItemsCollection: FC<ItemsTableItemProps> = ({
  id,
  name,
  products,
  isPublished,
  backgroundImage,
  checked,
  onClick,
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const colorVisible = getDotColor(isPublished)
  const visible = getStatusLabel(isPublished)

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  const handleOnChange =
    (nextId: string) =>
    (state: boolean, event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      if (onChange) {
        onChange(nextId, state)
      }
    }

  return (
    <Row
      fullWidth
      key={`banner_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row className={classes.row} justifyContent="flex-start">
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <PermissionContent
            permissions={[
              PermissionEnum.EDIT_ITEM,
              PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
            ]}
          >
            <Checkbox checked={checked} onChange={handleOnChange(id)} />
          </PermissionContent>
          <img
            className={classes.image}
            height={48}
            width={48}
            src={backgroundImage?.url}
          />
        </Row>
        <Row className={classes.secondTableColumn} justifyContent="flex-start">
          {name && <Text color="black" preset="body" text={name} />}
        </Row>
      </Row>
      <Row className={classes.thirdTableColumn} justifyContent="flex-start">
        <Text className={classes.statusText} color="black" preset="body" />
      </Row>
      <Row className={classes.thirdTableColumn} justifyContent="flex-start">
        <ColorDot color={theme.colors[colorVisible]} />
        <Text
          className={classes.statusText}
          color="black"
          preset="body"
          {...visible}
        />
      </Row>
      <Row className={classes.thirdTableColumn} justifyContent="flex-start">
        <Text
          className={classes.statusText}
          color="black"
          preset="body"
          text={String(products.totalCount)}
        />
      </Row>
    </Row>
  )
}
