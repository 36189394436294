import { User } from '../services'

export const isSupported =
  navigator.credentials && Boolean(navigator.credentials.preventSilentAccess)

export async function login<T>(loginFn: (id: string, password: string) => T) {
  if (isSupported) {
    try {
      const credential = await navigator.credentials.get({ password: true })

      if (credential instanceof PasswordCredential && credential.password) {
        return loginFn(credential.id, credential.password)
      }

      return undefined
    } catch {
      return undefined
    }
  }
}

export function saveCredentials(user: User, password: string) {
  const isFirefox = navigator.userAgent.search('Firefox')
  if (isFirefox) {
    return
  }

  if (isSupported && PasswordCredential) {
    const cred = new PasswordCredential({
      iconURL: user.avatar ? user.avatar.url : undefined,
      id: user.email,
      name: user.firstName ? `${user.firstName} ${user.lastName}` : undefined,
      password
    })
    navigator.credentials.store(cred)
  }
}
