import React, { FC, MouseEvent } from 'react'
import { useTheme } from 'react-jss'

import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { LightTheme } from '../Theme'
import { useStyle } from './checkbox.styles'
import { CheckboxProps } from './checkbox.types'

export const Checkbox: FC<CheckboxProps> = ({
  className = '',
  checked,
  disabled,
  children,
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ active: checked, theme })

  const handleOnClick = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    if (!disabled) {
      if (onChange) {
        onChange(!checked, event)
      }
    }
  }

  return (
    <Row className={className} onClick={handleOnClick}>
      <Row className={` ${classes.container}`}>
        {checked ? (
          <Icon src={ICONS.checkbox} />
        ) : (
          <Row className={classes.innerContainer}>
            <div />
          </Row>
        )}
      </Row>
      {children}
    </Row>
  )
}
