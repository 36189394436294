import moment from 'moment'

import { TextProps } from '../../components'
import { OrderPaymentStatus, AdvertisingType } from '../../services'

export const getFormatedOrderDate = (date?: Date) => {
  if (date) {
    return moment(date).format('MM.DD.YYYY')
  }

  return '__.__.____'
}

export const getMessageByAdvertisingType = (
  status: AdvertisingType
): TextProps => {
  switch (status) {
    case AdvertisingType.TOP_PRODUCT:
      return {
        text: 'Top Product',
        tx: 'advertising.type.top.product'
      }
    case AdvertisingType.BANNER:
      return {
        text: 'Banner',
        tx: 'advertising.type.top.banner'
      }
    default:
      return {
        text: 'No Type',
        tx: 'advertising.type.no.type'
      }
  }
}

export const getFormatedDescription = (description: string) =>
  description.replace(';', '').split('\r\n')

export const getAvaliableToPayByPaymentStatus = (status: OrderPaymentStatus) =>
  status === OrderPaymentStatus.FAILED || !status

export type ClassName = string | undefined

export const concatClassNames = (...arr: ClassName[]) =>
  arr.reduce((acc, className) => {
    if (acc && className) {
      return `${acc} ${className}`
    }

    if (className) {
      return className
    }

    return acc
  }, '')

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0')
