import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  header: {
    borderRadius: '0 0 4px 4px',
    borderTop: `1px solid ${theme.colors.shadow}`,
    '& > :first-child': {
      borderBottom: `1px solid ${theme.colors.shadow}`
    }
  },
  container: {
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  field: {
    marginTop: 16,
    maxWidth: '49%'
  }
}))
