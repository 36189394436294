import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'
import { SubscriptionCardStyleProps } from './subscription-card.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  plan: {
    minHeight: 180,
    width: '50%',
    marginTop: 16,
    position: 'relative',
    padding: 16,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    background: ({ active }: SubscriptionCardStyleProps) =>
      active ? theme.colors.blue : theme.colors.white,
    boxShadow: `4px 8px 16px ${theme.colors.shadow}`,
    '&:hover': {
      background: ({ active }: SubscriptionCardStyleProps) =>
        active ? theme.colors.blue : theme.colors.lightBlue,
      '& > div > span': {
        color: ({ active }: SubscriptionCardStyleProps) =>
          active ? theme.colors.white : theme.colors.black
      }
    },
    '& > div > span': {
      color: ({ active }: SubscriptionCardStyleProps) =>
        active ? theme.colors.white : theme.colors.black
    }
  },
  price: {
    marginTop: 56
  }
}))
