import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { iconButtonPresets } from './icon-button.presets'
import { IconButtonProps } from './icon-button.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    cursor: ({ disabled }: IconButtonProps) =>
      disabled ? 'default' : 'pointer',
    pointerEvents: ({ disabled }: IconButtonProps) => disabled && 'none'
  },
  ...iconButtonPresets(theme)
}))
