import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  Row,
  Text,
  LightTheme,
  isTableItem,
  getCurrencySymbol
} from '../../../components'
import { Currency } from '../../../services'
import { concatClassNames } from '../../views'
import { getPriceWithSymbol } from '../../views/observe-options-offer'

import { useStyle } from './user-list-vendors-table-item.styles'
import { UserListVendorsTableItemProps } from './user-list-vendors-table-item.types'

export const UserListVendorsTableItem: FC<UserListVendorsTableItemProps> = ({
  id,
  companyName,
  firstName,
  lastName,
  country,
  phone,
  email,
  defaultBillingAddress,
  paypalEmail,
  subscription,
  vendorBalance,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const name = firstName ? `${firstName} ${lastName}` : '-'
  const currensy = getCurrencySymbol(Currency.USD)
  const payoutBalance = vendorBalance
    ? getPriceWithSymbol(vendorBalance, currensy)
    : '-'

  const columnBig = concatClassNames(
    classes.tableColumn,
    classes.tableColumnBig
  )
  const columnMin = concatClassNames(
    classes.tableColumn,
    classes.tableColumnmMin
  )

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  return (
    <Row
      fullWidth
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row
        className={concatClassNames(classes.firstColumn, classes.tableColumn)}
        justifyContent="flex-start"
      >
        <Text color="black" preset="body" text={isTableItem(companyName)} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="body" text={name} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="body" text={isTableItem(country)} />
      </Row>
      <Row className={columnMin} justifyContent="flex-start">
        <Text
          className={classes.text}
          color="black"
          preset="body"
          text={isTableItem(phone)}
        />
      </Row>
      <Row className={columnBig} justifyContent="flex-start">
        <Text
          className={classes.text}
          color="black"
          preset="body"
          text={isTableItem(email)}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text
          color="black"
          preset="body"
          text={defaultBillingAddress?.streetAddress1 || '-'}
        />
      </Row>
      <Row className={columnBig} justifyContent="flex-start">
        <Text
          className={classes.text}
          color="black"
          preset="body"
          text={isTableItem(paypalEmail)}
        />
      </Row>
      <Row className={columnMin} justifyContent="flex-start">
        <Text
          color="black"
          preset="body"
          text={isTableItem(subscription?.name)}
        />
      </Row>
      <Row className={columnMin} justifyContent="flex-start">
        <Text color="black" preset="body" text="-" />
      </Row>
      <Row className={columnMin} justifyContent="flex-start">
        <Text color="black" preset="body" text={payoutBalance} />
      </Row>
    </Row>
  )
}
