import React, { useEffect } from 'react'

import { NotFoundPage } from './components/NotFoundPage'
import { useUser } from './hooks'
import { useNavigator } from './hooks/useNavigator'
import { getDefaultAuthorizedRoute } from './utils'

export const NotFound: React.FC = () => {
  const { user } = useUser()
  const navigate = useNavigator()

  useEffect(() => {
    navigate(getDefaultAuthorizedRoute(user))
  }, [user])

  const handleOnBack = () => navigate(getDefaultAuthorizedRoute(user))

  return <NotFoundPage onBack={handleOnBack} />
}
export default NotFound
