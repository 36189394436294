import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  section: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  sectionAdditionWithText: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '24px 16px'
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))
