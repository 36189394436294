import React, { FC } from 'react'

import { arrayHasElements, Column, Row, Text } from '../../../../components'

import { getPriceWithSymbol } from '../helpers'

import { useStyle } from './observe-options-options.styles'
import { ObserveOptionOptionsProps } from './observe-options-options.types'

export const ObserveOptionOptions: FC<ObserveOptionOptionsProps> = ({
  data,
  title,
  titleTx,
  currency
}) => {
  const classes = useStyle()

  const pickedData = data.filter((option) => option.isDefault === true)

  if (!arrayHasElements(pickedData)) {
    return <></>
  }

  return (
    <Column
      fullWidth
      className={classes.offersRow}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
        <Row fullWidth className={classes.name} justifyContent="space-between">
          <Text
            color="inactive"
            text={`${title} Options:`}
            tx={titleTx}
            preset="body"
          />
        </Row>
        <Column fullWidth>
          {arrayHasElements(pickedData) &&
            pickedData.map((item) => (
              <Row
                fullWidth
                key={`variant_${item.id}`}
                className={classes.value}
                justifyContent="space-between"
              >
                <Text text={item.title || '-'} preset="h7" />
                <Row className={classes.textRow} justifyContent="space-between">
                  <Text
                    className={classes.textDays}
                    text={String(item.extraProductionDays)}
                  />
                  <Text
                    text={getPriceWithSymbol(item.extraPrice, currency)}
                    preset="body"
                  />
                </Row>
              </Row>
            ))}
        </Column>
      </Row>
    </Column>
  )
}
