import { createUseStyles } from 'react-jss'

import { LightTheme, Color } from '../../Theme'

import { OrderStagingDotStyle } from './order-staging-dot.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: ({ active }: OrderStagingDotStyle) =>
      theme.colors[active ? 'yellow' : 'shadow'],
    height: 40,
    width: 40,
    borderRadius: 40,
    cursor: ({ disabled }: OrderStagingDotStyle) =>
      disabled ? 'default' : 'pointer',
    '&:hover': {
      background: ({ active, disabled }: OrderStagingDotStyle) => {
        const hover: Color = disabled ? 'shadow' : 'lightBlue'
        const activeColor: Color = active ? 'yellow' : hover
        return theme.colors[activeColor]
      },
      border: ({ disabled }: OrderStagingDotStyle) =>
        !disabled && `2px solid ${theme.colors.yellow}`
    }
  }
}))
