import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import { FeedbackMonitoringVendorItemStyleProps } from './feedback-monitiring-vendor-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    marginTop: 16,
    padding: 16
  },
  tableTopContainer: {},
  name: {
    marginLeft: 8,
    lineHeight: '24px'
  },
  headerLeftItem: {
    flex: 2,
    minWidth: 184
  },
  headerRightItem: {
    flex: 1,
    minWidth: 128
  },
  textRow: {
    flex: 1,
    marginTop: 16,
    maxWidth: 994,
    overflow: 'hidden'
  },
  item: {
    marginTop: 16
  },
  textField: {
    width: '100%'
  },
  filter: {
    marginLeft: 16,
    height: 56,
    maxWidth: 168
  },
  answer: {
    marginTop: 16,
    paddingTop: 16,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  answerTitle: {
    minWidth: 80
  },
  answerText: {
    display: ({ open }: FeedbackMonitoringVendorItemStyleProps) =>
      open ? 'flex' : '-webkit-box',
    marginLeft: 8,
    cursor: 'pointer',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2
  },
  stars: {
    flex: 2
  }
}))
