import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  section: {
    border: `1px solid ${theme.colors.shadow}`,
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  sliderContent: {
    padding: 0,
    width: '100%'
  }
}))
