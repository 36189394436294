import { gql } from '@apollo/client'

import { BASIC_INSTRUCTION_FRAGMENT } from '../fragments/instruction'

export const INSTRUCTIONS_QUERY = gql`
  ${BASIC_INSTRUCTION_FRAGMENT}
  query Instructions(
    $first: Int!
    $last: Int
    $after: String
    $before: String
  ) {
    instructions(first: $first, last: $last, after: $after, before: $before) {
      totalCount
      edges {
        cursor
        node {
          ...BasicInstructionFields
        }
      }
    }
  }
`
