import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  info: {
    marginLeft: 16
  },
  image: {
    objectFit: 'cover'
  }
})
