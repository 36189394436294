import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px'
  },
  textCheckBox: {
    marginLeft: 24
  },
  slider: {
    height: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  sliderItem: {
    marginLeft: 8
  },
  buttonContainer: {
    padding: '4px 16px 4px 40px'
  },
  button: {
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  remove: {
    cursor: 'pointer',
    padding: '8px 16px'
  },
  icon: {
    marginLeft: 8
  }
}))
