import React, { FC } from 'react'

import { Button } from '../button'
import { Column } from '../column'
import { Row } from '../row'

import { useStyle } from './page-form.styles'
import { PageFormProps } from './page-form.types'

export const PageForm: FC<PageFormProps> = ({
  className = '',
  children,
  disabled,
  pannelLeftContent,
  backButtonText = 'BACK',
  backButtonTx = 'back',
  saveButtonText = 'SAVE',
  saveButtonTx = 'save',
  onBack,
  onSave
}) => {
  const classes = useStyle()

  return (
    <>
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={`${className} ${classes.container}`}
      >
        {children}
      </Column>
      <Row fullWidth className={classes.submitContainer}>
        <Row fullWidth justifyContent="space-between">
          <Row fullWidth justifyContent="flex-start">
            {pannelLeftContent}
          </Row>
          <Row>
            <Button
              className={classes.button}
              color="yellow"
              preset="fixedBorderButton"
              textColor="yellow"
              textPreset="h5"
              text={backButtonText}
              tx={backButtonTx}
              onClick={onBack}
            />
            <Button
              className={classes.button}
              disabled={disabled}
              color="yellow"
              preset="fixedPrimaryButton"
              textColor="white"
              textPreset="h5"
              text={saveButtonText}
              tx={saveButtonTx}
              onClick={onSave}
            />
          </Row>
        </Row>
      </Row>
    </>
  )
}
