import React, { FC } from 'react'

import { Checkbox, Column, Row, SortItem, Text } from '../../../components'
import { OfferOrderField } from '../../../services'

import { useStyle } from './offers-table-header.styles'
import { OffersTableHeaderProps } from './offers-table-header.types'

export const OffersTableHeader: FC<OffersTableHeaderProps> = ({
  className = '',
  checked,
  onPickAll,
  onSort
}) => {
  const classes = useStyle()

  return (
    <Row fullWidth className={className} justifyContent="space-between">
      <Row className={classes.tableItem} justifyContent="flex-start">
        <Checkbox checked={checked} onChange={onPickAll} />
        <Column className={classes.avatar} />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
        justifyContent="flex-start"
      >
        <SortItem
          name="Item Name"
          nameTx="offer.item.name.title"
          onClick={onSort(OfferOrderField.ITEM_NAME)}
        />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnMinWidth}`}
        justifyContent="flex-start"
      >
        <Text
          color="black"
          preset="h6"
          text="Offer #"
          tx="offer.number.title"
        />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnMinWidth}`}
        justifyContent="flex-start"
      >
        <Text
          color="black"
          preset="h6"
          text="Days"
          tx="offer.table.header.days"
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text
          color="black"
          preset="h6"
          text="Delivery Price"
          tx="offer.form.delivery.price"
        />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnMinWidth}`}
        justifyContent="flex-start"
      >
        <SortItem
          name="Quantity"
          nameTx="item.quantity.title"
          onClick={onSort(OfferOrderField.QUANTITY)}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <SortItem
          name="Total Price"
          nameTx="item.totalPrice.title"
          onClick={onSort(OfferOrderField.TOTAL_PRICE)}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <SortItem
          name="Customer"
          nameTx="offer.item.customerName.title"
          onClick={onSort(OfferOrderField.CUSTOMER_NAME)}
        />
      </Row>
      <Row className={classes.tableColumnStatus} justifyContent="flex-start">
        <SortItem
          name="Status"
          nameTx="item.status.title"
          onClick={onSort(OfferOrderField.STATUS)}
        />
      </Row>
      <Row className={classes.button} alignItems="flex-start">
        <Column className={classes.tableColumnLast} />
      </Row>
    </Row>
  )
}
