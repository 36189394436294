import React, { FC } from 'react'

import { arrayHasElements, Column, Modal } from '../../../components'

import { VendorPreviewCategoryList } from './vendor-preview-category-list'
import { VendorSummaryPreview } from './vendor-preview-summary'
import { useStyle } from './vendor-preview.styles'
import { VendorPreviewProps } from './vendor-preview.types'

export const VendorPreview: FC<VendorPreviewProps> = ({
  className = '',
  title,
  titleTx,
  value,
  data,
  categories,
  onClose
}) => {
  const classes = useStyle()

  return (
    <Modal
      className={classes.modalContainer}
      title={title}
      titleTx={titleTx}
      value={value}
      onClose={onClose}
    >
      <Column
        fullWidth
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
      >
        <VendorSummaryPreview data={data} />
        {arrayHasElements(categories) && (
          <VendorPreviewCategoryList
            className={classes.categories}
            data={categories}
            title="Items by Category"
            titleTx="your.info.categoris.header.title"
            titlePreset="h1"
            description="A new collection shot in the warmth of soft evening light. Discover elegant silhouettes, refined details."
            descriptionTx="your.info.categories.header.description"
          />
        )}
      </Column>
    </Modal>
  )
}
