import React, { FC } from 'react'

import { ColorDot, Column, Row, Text } from '../../../../components'
import { getPriceWithSymbol } from '../helpers'

import { useStyle } from './observe-options-color.styles'
import { ObserveOptionColorProps } from './observe-options-color.types'

export const ObserveOptionColor: FC<ObserveOptionColorProps> = ({
  data,
  title,
  titleTx,

  currency
}) => {
  const classes = useStyle()

  return (
    <Column
      fullWidth
      className={classes.offersRow}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
        <Row fullWidth className={classes.name} justifyContent="space-between">
          <Text color="inactive" text={title} tx={titleTx} preset="body" />
        </Row>
        <Column fullWidth>
          {data
            .filter((item) => item.isDefault === true)
            .map((item) => (
              <Row
                fullWidth
                key={`item_${item.id}`}
                className={classes.value}
                justifyContent="space-between"
              >
                <Row>
                  <ColorDot
                    className={classes.color}
                    color={item.color.code}
                    preset="productColor"
                  />
                  <Text text={item.title || '-'} preset="h7" />
                </Row>
                <Row className={classes.textRow} justifyContent="space-between">
                  <Text
                    className={classes.textDays}
                    text={String(item.extraProductionDays)}
                  />
                  <Text
                    text={getPriceWithSymbol(item.extraPrice, currency)}
                    preset="body"
                  />
                </Row>
              </Row>
            ))}
        </Column>
      </Row>
    </Column>
  )
}
