import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Column, ICONS, PermissionContent, Row, Text } from '../../components'
import { useAuth } from '../../hooks'
import { PermissionEnum } from '../../services'

import { useStyle } from './cabiner-navigator.styles'
import { CabinetLink } from './cabinet-link'
import {
  CabinetNavigatorPathAdmin,
  CabinetNavigatorPathVendor
} from './cabinet-navigator.types'

export const CabinetNavigator = () => {
  const classes = useStyle()
  const { user, unreadMessages } = useAuth()

  const OfferCount = useMemo(() => {
    if (user && user.offersAmount) {
      return user.offersAmount
    }

    return 0
  }, [user?.offersAmount])

  const MessagesCount = useMemo(() => {
    if (unreadMessages) {
      return unreadMessages
    }

    return 0
  }, [unreadMessages])

  return (
    <Column fullWidth justifyContent="flex-start">
      <Row fullWidth justifyContent="flex-start">
        <Link className={classes.link} to="/">
          <Text color="black" preset="h4" text="OVAFLOPICK" />
        </Link>
      </Row>
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.supervisorAccount}
        text="Your Info"
        to={CabinetNavigatorPathVendor.YOUR_INFO}
        tx="cabinet.route.admin.userList.title"
        permissions={[PermissionEnum.YOUR_INFO]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.supervisorAccount}
        text="User List"
        to={CabinetNavigatorPathAdmin.USER_LIST}
        tx="user.list.title"
        permissions={[
          PermissionEnum.VIEW_VENDORS,
          PermissionEnum.VIEW_CUSTOMERS
        ]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.collections}
        text="Banners"
        to={CabinetNavigatorPathAdmin.BANNERS}
        tx="cabinet.route.admin.banners.title"
        permissions={[PermissionEnum.CREATE_MARKETPLACE_BANNER]}
      />
      {/* TODO: Temporarily hidden until the client returns to discuss this page. */}
      {/* <CabinetLink
        className={classes.navLink}
        icon={ICONS.libraryBooks}
        text="News"
        to={CabinetNavigatorPathAdmin.NEWS}
        tx="cabinet.route.admin.news.title"
        permissions={[PermissionEnum.MANAGE_APPS]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.newReleases}
        text="Promotions"
        to={CabinetNavigatorPathAdmin.PROMOTIONS}
        tx="cabinet.route.admin.promotion.title"
        permissions={[PermissionEnum.MANAGE_DISCOUNTS]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.workOutline}
        text="Vendors"
        to={CabinetNavigatorPathAdmin.VENDORS}
        tx="cabinet.route.admin.vendors.title"
        permissions={[PermissionEnum.MANAGE_USERS]}
      /> */}
      <Row fullWidth>
        <CabinetLink
          className={classes.navLink}
          icon={ICONS.chatVendor}
          text="Communication"
          to={CabinetNavigatorPathAdmin.COMMUNICATION}
          tx="cabinet.route.admin.chatVendors.title"
          permissions={[PermissionEnum.VIEW_COMMUNICATION]}
        />
        <PermissionContent permissions={[PermissionEnum.VIEW_COMMUNICATION]}>
          {MessagesCount > 0 && (
            <Row className={classes.count}>
              <Text
                color="white"
                preset="secondTitle"
                text={String(MessagesCount)}
              />
            </Row>
          )}
        </PermissionContent>
      </Row>
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.settings}
        text="Profile Settings"
        to={CabinetNavigatorPathVendor.PROFILE_SETTINGS}
        tx="cabinet.route.vendor.profileSettings.title"
        permissions={[PermissionEnum.PROFILE_SETTINGS]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.insertChart}
        text="Statistics"
        to={CabinetNavigatorPathAdmin.STATISTICS}
        tx="cabinet.route.admin.statistics.title"
        permissions={[PermissionEnum.VIEW_STATISTIC]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.payment}
        text="Payouts"
        to={CabinetNavigatorPathAdmin.PAYOUTS}
        tx="cabinet.route.admin.payouts.title"
        permissions={[PermissionEnum.VIEW_PAYOUT]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.insertChart}
        text="Items Page"
        to={CabinetNavigatorPathVendor.ITEMS}
        tx="cabinet.route.vendor.itemsPage.title"
        permissions={[
          PermissionEnum.VIEW_ITEM,
          PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
        ]}
      />

      {/* TODO: Temporarily hidden until the client returns to discuss this page. */}
      {/* <CabinetLink
        className={classes.navLink}
        icon={ICONS.grade}
        text="Promotion"
        tx="cabinet.route.vendor.promotion.title"
        to={CabinetNavigatorPathVendor.PROMOTIONS}
        permissions={[PermissionEnum.PROMOTIONS]}
      /> */}
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.workOutline}
        text="Order Control"
        to={CabinetNavigatorPathVendor.ORDER_CONTROL}
        tx="cabinet.route.vendor.ordersControl.title"
        permissions={[
          PermissionEnum.VIEW_ORDER,
          PermissionEnum.MANAGE_OWN_ORDER_STATUS
        ]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.feedback}
        text="Feedback Monitoring"
        to={CabinetNavigatorPathVendor.FEEDBACK_MONITORING}
        tx="cabinet.route.vendor.feedback.monitoring.title"
        permissions={[PermissionEnum.FEEDBACK_MONITORING]}
      />
      <CabinetLink
        className={classes.navLink}
        icon={ICONS.bill}
        text="Billing"
        to={CabinetNavigatorPathVendor.BILLINGS}
        tx="cabinet.route.vendor.billings.title"
        permissions={[PermissionEnum.BILLING]}
      />
      <Row fullWidth>
        <CabinetLink
          className={classes.navLink}
          icon={ICONS.listAlt}
          text="Offers"
          to={CabinetNavigatorPathVendor.OFFERS}
          tx="cabinet.route.admin.offers.title"
          permissions={[PermissionEnum.OFFERS]}
        />
        <PermissionContent permissions={[PermissionEnum.OFFERS]}>
          {OfferCount > 0 && (
            <Row className={classes.count}>
              <Text
                color="white"
                preset="secondTitle"
                text={String(OfferCount)}
              />
            </Row>
          )}
        </PermissionContent>
      </Row>
    </Column>
  )
}
