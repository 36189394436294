import { createUseStyles } from 'react-jss'

import { StyleProps } from '..'

export const useStyle = createUseStyles({
  caretDown: {
    position: 'absolute',
    right: 0,
    transform: ({ open }: StyleProps) =>
      open ? 'rotate(180deg) translateY(2px)' : 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out'
  }
})
