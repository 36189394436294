import { defineMessages, IntlShape } from 'react-intl'
import { BulkProductErrorFragment } from '../../fragments/types/BulkProductErrorFragment'
import { ProductErrorFragment } from '../../fragments/types/ProductErrorFragment'
import { commonMessages } from '../../intl'
import { ProductErrorCode } from '../../services'

import commonErrorMessages from './common'

const messages = defineMessages({
  alreadyExists: {
    defaultMessage: 'A product with this SKU already exists',
    id: '2NgTCJ'
  },
  attributeAlreadyAssigned: {
    defaultMessage:
      'This attribute has already been assigned to this product type',
    id: 'aggaJg'
  },
  attributeCannotBeAssigned: {
    defaultMessage: 'This attribute cannot be assigned to this product type',
    id: 'u24Ppd'
  },
  attributeRequired: {
    defaultMessage: 'All attributes should have value',
    id: 'cd13nN',
    description: 'product attribute error'
  },
  attributeVariantsDisabled: {
    defaultMessage: 'Variants are disabled in this product type',
    id: 'lLwtgs'
  },
  duplicatedInputItem: {
    defaultMessage: 'Variant with these attributes already exists',
    id: 'pFVX6g'
  },
  nameAlreadyTaken: {
    defaultMessage: 'This name is already taken. Please provide another.',
    id: 'FuAV5G'
  },
  skuUnique: {
    defaultMessage: 'SKUs must be unique',
    id: 'rZf1qL',
    description: 'bulk variant create error'
  },
  variantNoDigitalContent: {
    defaultMessage: 'This variant does not have any digital content',
    id: 'Z6QAbw'
  },
  variantUnique: {
    defaultMessage: 'This variant already exists',
    id: 'i3Mvj8',
    description: 'product attribute error'
  }
})

function getProductErrorMessage(
  err: Omit<ProductErrorFragment, '__typename'> | undefined,
  intl: IntlShape
): string {
  if (err) {
    switch (err.code) {
      case ProductErrorCode.ATTRIBUTE_ALREADY_ASSIGNED:
        return intl.formatMessage(messages.attributeAlreadyAssigned)
      case ProductErrorCode.ALREADY_EXISTS:
        return intl.formatMessage(messages.alreadyExists)
      case ProductErrorCode.ATTRIBUTE_CANNOT_BE_ASSIGNED:
        return intl.formatMessage(messages.attributeCannotBeAssigned)
      case ProductErrorCode.ATTRIBUTE_VARIANTS_DISABLED:
        return intl.formatMessage(messages.attributeVariantsDisabled)
      case ProductErrorCode.DUPLICATED_INPUT_ITEM:
        return intl.formatMessage(messages.duplicatedInputItem)
      case ProductErrorCode.GRAPHQL_ERROR:
        return intl.formatMessage(commonErrorMessages.graphqlError)
      case ProductErrorCode.REQUIRED:
        return intl.formatMessage(commonMessages.requiredField)
      case ProductErrorCode.VARIANT_NO_DIGITAL_CONTENT:
        return intl.formatMessage(messages.variantNoDigitalContent)
      case ProductErrorCode.INVALID:
        return intl.formatMessage(commonErrorMessages.invalid)
      case ProductErrorCode.UNIQUE:
        return intl.formatMessage(messages.nameAlreadyTaken)
      default:
        return intl.formatMessage(commonErrorMessages.unknownError)
    }
  }

  return ''
}

export function getProductVariantAttributeErrorMessage(
  err: Omit<ProductErrorFragment, '__typename'> | undefined,
  intl: IntlShape
): string {
  if (err) {
    switch (err.code) {
      case ProductErrorCode.REQUIRED:
        return intl.formatMessage(messages.attributeRequired)
      case ProductErrorCode.UNIQUE:
        return intl.formatMessage(messages.variantUnique)
      default:
        return getProductErrorMessage(err, intl)
    }
  }

  return ''
}

export function getBulkProductErrorMessage(
  err: BulkProductErrorFragment | undefined,
  intl: IntlShape
): string {
  if (err?.code === ProductErrorCode.UNIQUE && err.field === 'sku') {
    return intl.formatMessage(messages.skuUnique)
  }
  return getProductErrorMessage(err, intl)
}

export default getProductErrorMessage
