import {
  validateFirstName,
  validateLastName,
  validateUsername,
  validateState,
  validatePhone,
  validateEmail,
  validatePassword
} from '../helpers'

export const ValidatorFirstName = (value: string) => {
  if (value) {
    const isValideFirstName = validateFirstName(value)
    return isValideFirstName
  }
  return undefined
}

export const ValidatorLastName = (value: string) => {
  if (value) {
    const isValidLastName = validateLastName(value)
    return isValidLastName
  }
  return undefined
}

export const ValidatorUsername = (value: string) => {
  if (value) {
    const isValidUsername = validateUsername(value)
    return isValidUsername
  }
  return undefined
}

export const ValidatorState = (value: string) => {
  if (value) {
    const isValidState = validateState(value)
    return isValidState
  }
  return undefined
}

export const ValidatorPhone = (value: string) => {
  if (value) {
    const isValidPhone = validatePhone(value)
    return isValidPhone
  }
  return undefined
}

export const ValidatorEmail = (value: string) => {
  if (value) {
    const isValidEmail = validateEmail(value)
    return isValidEmail
  }
  return undefined
}

export const ValidatorPassword = (value: string) => {
  if (value) {
    const isValidPassword = validatePassword(value)
    return isValidPassword
  }
  return undefined
}
