import React, { FC } from 'react'

import { Column, Content, FilterContainer, Row } from '../../components'

import { useStyle } from './cabinet.styles'
import { CabinetPageProps } from './cabinet.types'

export const CabinetPage: FC<CabinetPageProps> = ({ children, navigator }) => {
  const classes = useStyle()
  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Row fullWidth alignItems="flex-start" className={classes.row}>
        <FilterContainer>{navigator}</FilterContainer>
        <Column
          fullWidth
          className={classes.content}
          justifyContent="flex-start"
        >
          {children}
        </Column>
      </Row>
    </Content>
  )
}
