import { gql } from '@apollo/client'

import {
  BANNER_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  PROMOTION_BANNER_FRAGMENT,
  VENDOR_BANNER_FRAGMENT
} from '../fragments'

export const SECONDARY_MENU = gql`
  fragment SecondaryMenuSubItem on MenuItem {
    id
    name
    category {
      id
      name
    }
    url
    collection {
      id
      name
    }
    page {
      slug
    }
  }
  query SecondaryMenu {
    shop {
      navigation {
        secondary {
          items {
            ...SecondaryMenuSubItem
            children {
              ...SecondaryMenuSubItem
            }
          }
        }
      }
    }
  }
`

export const MAIN_MENU = gql`
  fragment MainMenuSubItem on MenuItem {
    id
    name
    category {
      id
      name
      description
      backgroundImage {
        url
        alt
      }
    }
    url
    collection {
      id
      name
    }
    page {
      slug
    }
  }
  query SecondaryMenu {
    shop {
      navigation {
        main {
          items {
            ...MainMenuSubItem
            children {
              ...MainMenuSubItem
            }
          }
        }
      }
    }
  }
`

export const BANNERS = gql`
  ${PAGE_INFO_FRAGMENT}
  ${BANNER_FRAGMENT}
  query Banners(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: BannerFilterInput
  ) {
    allBanners(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...BannerFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      activeBannersCount
    }
  }
`

export const PROMOTION_BANNERS = gql`
  ${PAGE_INFO_FRAGMENT}
  ${PROMOTION_BANNER_FRAGMENT}
  query PromotionBanners(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: PromotionBannerFilterInput
    $sortBy: PromotionBannersSortingInput
  ) {
    promotionBanners(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          ...PromotionBammerFrafment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      activeBannersCount
    }
  }
`

export const CREATE_PROMOTION_BANNER = gql`
  ${PROMOTION_BANNER_FRAGMENT}
  mutation PromotionBannerCreate($input: PromotionBannerCreateInput!) {
    promotionBannerCreate(input: $input) {
      promotionBanner {
        ...PromotionBammerFrafment
      }
      errors: bannerErrors {
        code
        message
        field
      }
    }
  }
`

export const CREATE_BANNER = gql`
  ${BANNER_FRAGMENT}
  mutation BannerCreate($input: BannerCreateInput!) {
    bannerCreate(input: $input) {
      banner {
        ...BannerFragment
      }
      errors: bannerErrors {
        code
      }
    }
  }
`

export const UPDATE_PROMOTION_BANNER = gql`
  ${PROMOTION_BANNER_FRAGMENT}
  mutation PromotionBannerUpdate(
    $id: ID!
    $input: PromotionBannerCreateInput!
  ) {
    promotionBannerUpdate(id: $id, input: $input) {
      promotionBanner {
        ...PromotionBammerFrafment
      }
      errors: bannerErrors {
        code
      }
    }
  }
`

export const UPDATE_BANNER = gql`
  ${BANNER_FRAGMENT}
  mutation BannerUpdate($id: ID!, $input: BannerCreateInput!) {
    bannerUpdate(id: $id, input: $input) {
      banner {
        ...BannerFragment
      }
      errors: bannerErrors {
        code
      }
    }
  }
`

export const IMAGE_BANNER_CREATE = gql`
  ${BANNER_FRAGMENT}
  mutation BannerImageCreate($id: ID!, $file: Upload!) {
    bannerImageCreate(input: { image: $file, banner: $id }) {
      errors: bannerErrors {
        code
      }
      banner {
        ...BannerFragment
        images {
          url
        }
      }
    }
  }
`

export const PROMOTION_BANNER_REMOVE_MUTATION = gql`
  ${PROMOTION_BANNER_FRAGMENT}
  mutation PromotionBannerDelete($id: ID!) {
    promotionBannerDelete(id: $id) {
      promotionBanner {
        ...PromotionBammerFrafment
      }
      errors: bannerErrors {
        code
      }
    }
  }
`

export const BANNER_REMOVE_MUTATION = gql`
  ${BANNER_FRAGMENT}
  mutation BannerDelete($id: ID!) {
    bannerDelete(id: $id) {
      banner {
        ...BannerFragment
      }
      errors: bannerErrors {
        code
      }
    }
  }
`

export const VENDOR_BANNERS = gql`
  ${PAGE_INFO_FRAGMENT}
  ${VENDOR_BANNER_FRAGMENT}
  query Banners(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: VendorBannerFilterInput
  ) {
    vendorBanners(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
    ) {
      edges {
        cursor
        node {
          ...VendorBannerFragment
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      activeBannersCount
    }
  }
`

export const VENDOR_BANNER_CREATE_MUTATION = gql`
  ${VENDOR_BANNER_FRAGMENT}
  mutation VendorBannerCreate($input: VendorBannerCreateInput!) {
    vendorBannerCreate(input: $input) {
      vendorBanner {
        ...VendorBannerFragment
      }
      errors: bannerErrors {
        code
      }
    }
  }
`

export const VENDOR_BANNER_UPDATE_MUTATION = gql`
  ${VENDOR_BANNER_FRAGMENT}
  mutation VendorBannerUpdate($id: ID!, $input: VendorBannerCreateInput!) {
    vendorBannerUpdate(id: $id, input: $input) {
      vendorBanner {
        ...VendorBannerFragment
      }
      bannerErrors {
        code
        message
        field
      }
      errors {
        field
        message
      }
    }
  }
`

export const VENDOR_BANNER_IMAGE_CREATE_MUTATION = gql`
  ${VENDOR_BANNER_FRAGMENT}
  mutation VendorBannerImageCreate($input: VendorBannerImageCreateInput!) {
    vendorBannerImageCreate(input: $input) {
      bannerErrors {
        code
        message
        field
      }
      errors {
        field
        message
      }
      banner {
        ...VendorBannerFragment
      }
    }
  }
`

export const VENDOR_BANNER_REMOVE_MUTATION = gql`
  ${VENDOR_BANNER_FRAGMENT}
  mutation VendorBannerDelete($id: ID!) {
    vendorBannerDelete(id: $id) {
      vendorBanner {
        ...VendorBannerFragment
      }
      errors: bannerErrors {
        code
      }
    }
  }
`
