import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'
import { FeedbackMonitoringNavItemStyle } from './feedback-monitoring-nav-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    flex: 1,
    padding: 16,
    minWidth: 176,
    borderRadius: 4,
    height: 56,
    marginLeft: 16,
    backgroundColor: ({ active }: FeedbackMonitoringNavItemStyle) =>
      active ? theme.colors.darkBlue : theme.colors.greyFon,
    cursor: 'pointer'
  },
  iconContainer: {
    paddingLeft: 0
  },
  text: {
    marginLeft: 12
  }
}))
