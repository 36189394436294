import { ADDRESS_FRAGMENT } from './auth'
import { OFFER_COLOR_FRAGMENT } from './color'
import { OFFER_MATERIAL_FRAGMENT } from './material'
import { OFFER_OPTION_FRAGMENT, OPTION_VARIANT_FRAGMENT } from './option'
import { PRODUCT_FRAGMENT_FOR_OFFER } from './product'

export const OFFER_USER_FRAGMENT = `
  fragment OfferUserFragment on User {
    email
    city
    firstName
    lastName
    country
    phone
    id
    avatar  {
      url
      alt
    }
  }
`

export const OFFER_VENDOR_FRAGMENT = `
  ${OFFER_USER_FRAGMENT}
  fragment OfferVendorFragment on User {
    ...OfferUserFragment
    companyName
  }
`

export const OFFER_CUSTOMER_FRAGMENT = `
  ${OFFER_USER_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  fragment OfferCustomerFragment on User {
    ...OfferUserFragment
    defaultShippingAddress {
      ...AddressFragment
    }
  }
`

export const OFFER_ITEM_FRAGMENT = `
${OPTION_VARIANT_FRAGMENT}
${OFFER_MATERIAL_FRAGMENT}
${OFFER_COLOR_FRAGMENT}
${OFFER_OPTION_FRAGMENT}
fragment OfferItemFragment on OfferItem {
  id
  quantity
  totalItemPrice
  unitIds
  productMaterials {
    ...OfferMaterialFragment
  }
  productColors {
    ...OfferColorFragment
  }
  productOptions {
    ...OfferOptionFragment
  }
  options {
    ...OptionVariantFragment
  }
}
`

export const BASIC_OFFER_FRAGMENT = `
${PRODUCT_FRAGMENT_FOR_OFFER}
${OFFER_VENDOR_FRAGMENT}
${OFFER_CUSTOMER_FRAGMENT}
${OFFER_ITEM_FRAGMENT}
  fragment BasicOfferFields on Offer {
    id
    description
    noSuggestion
    quantity
    productionDays
    discount
    productTotalPrice
    totalPrice
    status
    number
    conversationId
    deliveryPrice
    adminCustomerConversationId
    customer {
      ...OfferCustomerFragment
    }
    vendor {
      ...OfferVendorFragment
    }
    product {
      ...ProductFragmentForOffer
    }
    items {
      ...OfferItemFragment
    }
  }
  `

export const OFFER_FRAGMENT_FOR_ORDER = `
  ${PRODUCT_FRAGMENT_FOR_OFFER}
  fragment OfferFragmentForOrder on Offer {
    id
    description
    noSuggestion
    productionDays
    quantity
    discount
    productTotalPrice
    totalPrice
    status
    number
    conversationId
    adminCustomerConversationId
    product {
      ...ProductFragmentForOffer
    }
  }
  `
