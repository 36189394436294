import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

import { ToastifyStyle } from './toastify.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    position: 'fixed',
    height: 64,
    width: 433,
    right: 88,
    top: 20,
    bottom: 0,
    zIndex: 11,
    borderRadius: 4,
    boxShadow: ' 0px 4px 8px rgba(0, 0, 0, 0.12)',
    borderLeft: `8px solid ${theme.colors.white}`,
    background: theme.colors.white,
    padding: '0 16px',
    transform: ({ open }: ToastifyStyle) =>
      open ? 'translateX(0px)' : 'translateX(1920px)',
    transition: 'transform .5s ease-in-out'
  },
  error: {
    borderLeft: `8px solid ${theme.colors.error}`
  },
  success: {
    borderLeft: `8px solid ${theme.colors.active}`
  },
  '@media screen and (max-width: 480px)': {
    container: {
      width: 308,
      right: 0,
      left: 12,
      top: 'calc(100vh - 88px)',
      bottom: 24
    }
  },
  '@media screen and (min-width: 481px) and (max-width: 1223px)': {
    container: {
      width: 400,
      right: 0,
      left: 'calc(100vw - 472px)',
      top: 'calc(100vh - 88px)',
      bottom: 24
    }
  }
}))
