import React, { FC, useMemo, useState } from 'react'

import {
  Icon,
  ICONS,
  MenuSlider,
  Row,
  Text,
  onStopEvent,
  QuestionConfirmModal
} from '../../../components'
import {
  OfferItemInput,
  ProductCreateColorInput,
  ProductCreateMaterialInput,
  ProductCreateOptionInput,
  ProductOptionVariantInput
} from '../../../services'

import { OfferAdditionalOptionSection } from '../offer-additional-option-section'
import { OfferOptionsForm } from '../offer-options-form'
import { OfferColorSection } from '../offer-color-section'
import { OfferMaterialSection } from '../offer-material-section'

import { OfferVariantSliderProps } from './offer-variant-slider.types'
import { useStyle } from './offer-variant-slider.styles'

export const OfferVariantSlider: FC<OfferVariantSliderProps> = ({
  title,
  titleTx,
  titleValue,
  value,
  count,
  instruction,
  onChangeValue,
  onDeleteItem
}) => {
  const classes = useStyle()

  const [itemValue, changeItemValue] = useState<OfferItemInput>(value)
  const [openDeleteItemModal, changeOpenDeleteItemModal] = useState(false)

  const ItemValue = useMemo(() => {
    return { ...itemValue, quantity: value.quantity }
  }, [value.quantity, itemValue])

  const handleOnOpenDeleteModal = (state: boolean) => () => {
    changeOpenDeleteItemModal(state)
  }

  const handleOnDeleteItem = () => {
    if (onDeleteItem) {
      onDeleteItem()
      changeOpenDeleteItemModal(false)
    }
  }

  const handleOnChangeMaterialTable = (
    materialsValue: ProductCreateMaterialInput[]
  ) => {
    const nextItem = {
      ...ItemValue,
      productMaterials: materialsValue
    }

    changeItemValue(nextItem)
    if (onChangeValue) {
      onChangeValue(nextItem)
    }
  }

  const handleOnChangeColorTable = (colorsValue: ProductCreateColorInput[]) => {
    const nextItemValue = {
      ...ItemValue,
      productColors: colorsValue
    }
    changeItemValue(nextItemValue)

    if (onChangeValue) {
      onChangeValue(nextItemValue)
    }
  }

  const handleOnChangeProductOptionsTable = (
    nextOptions: ProductCreateOptionInput[]
  ) => {
    const nextItemValue = {
      ...ItemValue,
      productOptions: nextOptions
    }
    changeItemValue(nextItemValue)

    if (onChangeValue) {
      onChangeValue(nextItemValue)
    }
  }

  const handleOnChangeOptionTable = (
    nextOptions: ProductOptionVariantInput[]
  ) => {
    const nextItemValue = {
      ...ItemValue,
      options: nextOptions
    }
    changeItemValue(nextItemValue)

    if (onChangeValue) {
      onChangeValue(nextItemValue)
    }
  }

  const header = (
    <Row fullWidth justifyContent="space-between">
      <Text preset="h3" text={title} tx={titleTx} values={titleValue} />
      {count && (
        <Row onClick={onStopEvent}>
          <Row
            justifyContent="flex-end"
            onClick={handleOnOpenDeleteModal(true)}
          >
            <Icon src={ICONS.trash} />
          </Row>
        </Row>
      )}
    </Row>
  )

  return (
    <>
      <MenuSlider
        defaultOpen
        className={classes.section}
        contentClassName={classes.sliderContent}
        header={header}
      >
        <OfferMaterialSection
          value={itemValue}
          onChangeTable={handleOnChangeMaterialTable}
        />
        <OfferColorSection
          value={itemValue}
          onChangeTable={handleOnChangeColorTable}
        />
        <OfferOptionsForm
          options={itemValue.productOptions}
          onChange={handleOnChangeProductOptionsTable}
        />

        <OfferAdditionalOptionSection
          value={itemValue.options}
          instruction={instruction}
          onChangeTable={handleOnChangeOptionTable}
          onSubmit={handleOnChangeOptionTable}
        />
      </MenuSlider>
      {openDeleteItemModal && (
        <QuestionConfirmModal
          cancelText="Back"
          cancelTx="delete.offer.modal.cancel"
          successText="Delete"
          successTx="delete.offer.modal.ok"
          title="Delete Item Variant"
          titleTx="offer.modal.delete.variant.title"
          description="Are you sure?"
          descriptionTx="offer.modal.delete.description"
          secondaryDescription="You really want to delete this Item Variant?"
          secondaryDescriptionTx="offer.modal.delete.variant.secondaryDescription"
          onSubmit={handleOnDeleteItem}
          onClose={handleOnOpenDeleteModal(false)}
        />
      )}
    </>
  )
}
