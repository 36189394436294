import { isFunction } from 'lodash'
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  MouseEvent,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTheme } from 'react-jss'

import { concatClassNames } from '../../pages/views'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { Color, LightTheme } from '../Theme'

import { useStyle } from './text-field.styles'
import { TextFieldProps } from './text-field.types'

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      preset = 'border',
      className = '',
      inputClassName = '',
      isSecure,
      isHidePlaceholder,
      label,
      labelTx,
      type: defaultType = 'text',
      defaultValue,
      leftIcon,
      rightElement,
      textInputPreset,
      value: outerValue,
      isError,
      onChange,
      onRightElementClick,
      error,
      errorTx,
      disabled,
      onFocus,
      onBlur,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [value, changeValue] = useState(defaultValue || '')
    const [focus, changeFocus] = useState(false)
    const [visible, changeVisible] = useState(!isSecure)
    const CurrentValue = useMemo(() => {
      if (typeof outerValue !== 'undefined') {
        return outerValue
      }
      return value
    }, [value, outerValue])
    const theme = useTheme<LightTheme>()
    const classes = useStyle({
      error,
      focus,
      leftIcon,
      password: isSecure && !visible,
      preset,
      textInputPreset,
      theme,
      value: CurrentValue,
      disabled,
      isError
    })

    const activeLabelColor: Color =
      !focus && !CurrentValue ? 'inactive' : 'yellow'
    const type = isSecure && !visible ? 'password' : defaultType
    const secureIcon = visible ? ICONS.removeRedEye : ICONS.visibilityOff

    useLayoutEffect(() => {
      if (ref) {
        if (isFunction(ref)) {
          ref(inputRef.current)
        } else {
          // eslint-disable-next-line no-param-reassign
          ref.current = inputRef.current
        }
      }
    }, [inputRef])
    const handleOnVisible = (
      event: MouseEvent<SVGElement, globalThis.MouseEvent>
    ) => {
      changeVisible(!visible)
      event.preventDefault()
      event.stopPropagation()
    }
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value: nextValue } = event.target
      changeValue(nextValue)
      if (onChange) {
        onChange(event)
      }
    }
    const handleOnFocus =
      (state: boolean) => (event: FocusEvent<HTMLInputElement>) => {
        changeFocus(state)
        if (state) {
          if (onFocus) {
            onFocus(event)
          }
        } else if (onBlur) {
          onBlur(event)
        }
      }
    const handleOnClickContainer = () => {
      if (inputRef.current) {
        inputRef.current.focus()
      }
    }
    return (
      <Column fullWidth className={className}>
        <Row
          className={classes.container}
          justifyContent="space-between"
          onClick={handleOnClickContainer}
        >
          {leftIcon && (
            <Icon color="inactive" src={leftIcon} height={24} width={24} />
          )}
          <Column
            fullWidth
            className={classes.inputContainer}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Text
              className={classes.label}
              color={activeLabelColor}
              preset="subscriptionText"
              text={label}
              tx={labelTx}
            />
            {isHidePlaceholder ? (
              <input
                {...props}
                className={concatClassNames(
                  inputClassName,
                  classes.input,
                  classes.inputPlaceholder
                )}
                disabled={disabled}
                value={CurrentValue}
                type={type}
                ref={inputRef}
                onFocus={handleOnFocus(true)}
                onBlur={handleOnFocus(false)}
                onChange={handleOnChange}
              />
            ) : (
              <input
                {...props}
                className={`${inputClassName} ${classes.input}`}
                disabled={disabled}
                value={CurrentValue}
                type={type}
                ref={inputRef}
                onFocus={handleOnFocus(true)}
                onBlur={handleOnFocus(false)}
                onChange={handleOnChange}
              />
            )}
          </Column>
          {isSecure && CurrentValue && (
            <Icon
              className={classes.icon}
              color="black"
              src={secureIcon}
              onClick={handleOnVisible}
            />
          )}
          {rightElement && (
            <Row className={classes.icon} onClick={onRightElementClick}>
              {rightElement}
            </Row>
          )}
        </Row>
        {typeof error !== 'undefined' && (
          <Column fullWidth>
            <Text
              className={classes.errorText}
              text={error}
              tx={errorTx}
              color="error"
              preset="secondTitle"
            />
          </Column>
        )}
      </Column>
    )
  }
)
