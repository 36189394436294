import { createUseStyles } from 'react-jss'
import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  bottomContainer: {
    padding: 24
  },
  description: {
    marginTop: 24,
    textAlign: 'center'
  },
  secondaryDesctiption: {
    marginTop: 16,

    textAlign: 'center'
  },
  bottom: {
    columnGap: 24,
    borderTop: `1px solid ${theme.colors.shadow}`,
    background: theme.colors.white,
    padding: 24
  },
  button: {
    width: 168
  }
}))
