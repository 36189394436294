import React, { FC } from 'react'

import { Modal } from '../modal'
import { Row } from '../row'
import { Button } from '../button'
import { Column } from '../column'

import { useStyle } from './question-modal.styles'
import { QuestionModalProps } from './question-modal.types'

export const QuestionModal: FC<QuestionModalProps> = ({
  className = '',
  title,
  titleTx,
  disabled,
  children,
  value,
  onClose,
  onCancel,
  onSubmit,
  cancelText = 'Cancel',
  cancelTx = 'question.modal.cancel',
  successText = 'Ok',
  successTx = 'question.modal.ok'
}) => {
  const classes = useStyle()

  return (
    <Modal
      className={classes.modal}
      title={title}
      titleTx={titleTx}
      value={value}
      onClose={onClose}
    >
      <Column
        fullWidth
        className={`${className} ${classes.details}`}
        justifyContent="flex-start"
      >
        {children}
      </Column>
      <Row fullWidth className={classes.bottom} justifyContent="flex-end">
        <Button
          className={classes.success}
          color="yellow"
          preset="secondaryBorder"
          textColor="yellow"
          textPreset="maxButton"
          text={cancelText}
          tx={cancelTx}
          onClick={onCancel || onClose}
        />
        <Button
          className={classes.success}
          color="yellow"
          disabled={disabled}
          preset="primary"
          textColor="white"
          textPreset="maxButton"
          text={successText}
          tx={successTx}
          onClick={onSubmit}
        />
      </Row>
    </Modal>
  )
}
