import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useNavigator } from '../../hooks'
import { useApi } from '../../providers'
import { EditPromotionBannerInput } from '../../services/menu'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'
import { PromotionBannerForm } from '../promotions-banner-form'

import { EditPromotionBannerParams } from './edit-promotion-banner.types'

export const EditPromotionBannerPage = () => {
  const { id } = useParams<EditPromotionBannerParams>()
  const { menu } = useApi()
  const { data: banners } = menu.usePromotionBanners({ first: 100 })
  const { response, onSubmit } = menu.useUpdatePromotionBanner()
  const navigate = useNavigator()

  useEffect(() => {
    if (response.data && response.data.promotionBannerUpdate.promotionBanner) {
      navigate(CabinetNavigatorPathVendor.PROMOTIONS)
    }
  }, [response.data])

  const CurrentBanner = useMemo(() => {
    if (id && banners) {
      return banners.promotionBanners.edges.find(
        (banner) => banner.node.id === id
      )
    }

    return undefined
  }, [id, banners])

  const handleOnSubmit = (nextBanner: EditPromotionBannerInput) => {
    onSubmit(id, nextBanner)
  }

  if (!CurrentBanner) {
    return <></>
  }

  return (
    <PromotionBannerForm
      banner={CurrentBanner.node}
      title={CurrentBanner.node.name}
      onSumbit={handleOnSubmit}
    />
  )
}
