import { TextTranslate } from '../text'
import { Color } from '../Theme'

export enum ToastifyType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export type ToastifyTypeStringify = keyof typeof ToastifyType

export type ToastifyProps = {
  open: boolean
  text?: string
  color?: Color
  tx?: TextTranslate
  type?: ToastifyTypeStringify
  values?: Record<string, any>
  className?: string
  onClose?: () => void
}

export type ToastifyStyle = {
  open: boolean
  width: number
}
