import React, { FC } from 'react'

import { OptionExtraBadge } from '../option-extra-badge'
import { Row } from '../row'

import { useStyle } from './option-extra-row.styles'
import { OptionExtraRowProps } from './option-extra-row.types'

export const OptionExtraRow: FC<OptionExtraRowProps> = ({
  className = '',
  extraDays,
  extraPrice,
  currencySymbol
}) => {
  const classes = useStyle()

  return (
    <Row className={className}>
      <Row className={classes.line}></Row>
      {Boolean(extraPrice) && (
        <OptionExtraBadge price={extraPrice} currencySymbol={currencySymbol} />
      )}
      {Boolean(extraDays) && <OptionExtraBadge days={extraDays} />}
    </Row>
  )
}
