import React, { ChangeEvent, FC, useState } from 'react'

import {
  Column,
  RadioBox,
  RadioItemWithIdProps,
  Section,
  Slider,
  Text,
  TextArea,
  TextField,
  LoadPhotoContainer
} from '../../../components'
import { CreateBannerInput, BannerButtonType } from '../../../services'

import { useStyle } from './promoted-store-banner.styles'
import { PromotedStoreBannerViewProps } from './promoted-store-banner.types'

const buttonTypeRadioData: RadioItemWithIdProps[] = [
  {
    id: BannerButtonType.BIG_BUTTON.toString(),
    name: 'Big Button',
    nameTx: 'banner.button.type.bigButton'
  },
  {
    id: BannerButtonType.LITTLE_BUTTON.toString(),
    name: 'Little Button',
    nameTx: 'banner.button.type.littleButton'
  }
]

export const PromotedStoreBannerView: FC<PromotedStoreBannerViewProps> = ({
  banner: defaultBanner,
  children,
  mobileImages,
  onChange,
  onChangeMobileImage
}) => {
  const [banner, changeBanner] = useState<CreateBannerInput>(defaultBanner)
  const [prevBannerState, changePrevBannerState] =
    useState<CreateBannerInput>(defaultBanner)
  const classes = useStyle()

  const handleOnChangeInput =
    (prop: keyof CreateBannerInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      const nextBanner: CreateBannerInput = {
        ...banner,
        [prop]: value
      }

      const nextPrevBanner: CreateBannerInput = {
        ...prevBannerState,
        [prop]: value
      }

      changePrevBannerState(nextPrevBanner)
      changeBanner(nextBanner)

      if (onChange) {
        onChange(nextBanner)
      }
    }

  const handleOnChangeRadio = (id: string) => {
    const nextBanner: CreateBannerInput = {
      ...banner,
      buttonType: id as BannerButtonType
    }

    const nextPrevBanner: CreateBannerInput = {
      ...prevBannerState,
      buttonType: id as BannerButtonType
    }

    changePrevBannerState(nextBanner)
    changeBanner(nextPrevBanner)

    if (onChange) {
      onChange(nextBanner)
    }
  }

  const handleOnChangeActiveButton = (isButtonActive: boolean) => {
    const nextBanner: CreateBannerInput = {
      ...banner,
      isButtonActive
    }

    const nextPrevBanner: CreateBannerInput = {
      ...prevBannerState,
      isButtonActive
    }

    changePrevBannerState(nextBanner)
    changeBanner(nextPrevBanner)

    if (onChange) {
      onChange(nextBanner)
    }
  }

  const handleOnChangeActiveNotification = (isNotificationActive: boolean) => {
    const nextBanner: CreateBannerInput = {
      ...banner,
      isNotificationActive
    }

    const nextPrevBanner: CreateBannerInput = {
      ...prevBannerState,
      isNotificationActive
    }

    changePrevBannerState(nextBanner)
    changeBanner(nextPrevBanner)

    if (onChange) {
      onChange(nextBanner)
    }
  }

  const buttonSectionSlider = (
    <Slider
      defaultValue={defaultBanner.isButtonActive}
      onChange={handleOnChangeActiveButton}
    />
  )

  const notificationSectionSlider = (
    <Slider
      defaultValue={defaultBanner.isNotificationActive}
      onChange={handleOnChangeActiveNotification}
    />
  )

  const secondaryDescriptionLength = prevBannerState.secondaryDescription
    ? prevBannerState.secondaryDescription.length
    : 0
  const mainDescriptionLength = prevBannerState.mainDescription
    ? prevBannerState.mainDescription.length
    : 0

  return (
    <>
      <LoadPhotoContainer
        className={classes.section}
        defaultValue={mobileImages}
        title="Mobile Banner Image"
        titleTx="banner.mobile.image"
        onChange={onChangeMobileImage}
      />
      <Section
        className={classes.section}
        description="(Optional)"
        descriptionTx="banner.text.fields.description"
        title="Text fields"
        titleTx="banner.text.fields.title"
      >
        <Column
          alignItems="flex-start"
          className={classes.sectionAdditionContainer}
        >
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.mainHeading}
            preset="border"
            label="Main Heading"
            maxLength={25}
            labelTx="banner.main.heading"
            onChange={handleOnChangeInput('mainHeading')}
          />
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.title}
            preset="border"
            maxLength={50}
            label="Title"
            labelTx="banner.title"
            onChange={handleOnChangeInput('title')}
          />
          <TextField
            className={classes.field}
            inputClassName={classes.input}
            defaultValue={prevBannerState.secondaryHeading}
            preset="border"
            maxLength={15}
            label="Secondary Heading"
            labelTx="banner.secondary.heading"
            onChange={handleOnChangeInput('secondaryHeading')}
          />
          <TextArea
            key="mainDescription"
            className={classes.area}
            defaultValue={prevBannerState.mainDescription}
            preset="border"
            maxLength={200}
            label={`Main Description ${mainDescriptionLength}/200`}
            labelTx="banner.main.description"
            valuesTx={{ length: mainDescriptionLength }}
            onChange={handleOnChangeInput('mainDescription')}
          />
          <TextArea
            key="secondaryDescription"
            className={classes.area}
            preset="border"
            defaultValue={prevBannerState.secondaryDescription}
            label={`Secondary Description ${secondaryDescriptionLength}/100`}
            labelTx="banner.secondary.description"
            maxLength={100}
            valuesTx={{ length: secondaryDescriptionLength }}
            onChange={handleOnChangeInput('secondaryDescription')}
          />
        </Column>
      </Section>
      <Section
        className={classes.section}
        title="Button"
        titleTx="banner.button.title"
        rightButton={buttonSectionSlider}
      >
        <Column
          alignItems="flex-start"
          className={classes.sectionAdditionContainer}
        >
          <Text
            color="black"
            preset="h5"
            text="Button Type"
            tx="banner.button.type"
          />
          <RadioBox
            active={String(prevBannerState.buttonType)}
            className={classes.field}
            data={buttonTypeRadioData}
            onChange={handleOnChangeRadio}
          />
        </Column>
        <Column
          alignItems="flex-start"
          className={classes.sectionAdditionContainer}
        >
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.buttonText}
            preset="border"
            maxLength={15}
            label="Button Text"
            labelTx="banner.button.text"
            onChange={handleOnChangeInput('buttonText')}
          />
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.buttonLink}
            preset="border"
            maxLength={200}
            label="Link for Button"
            labelTx="banner.button.link"
            onChange={handleOnChangeInput('buttonLink')}
          />
        </Column>
      </Section>
      {children}
      <Section
        className={classes.section}
        title="Notification"
        titleTx="banner.notification.title"
        rightButton={notificationSectionSlider}
      >
        <Column
          alignItems="flex-start"
          className={classes.sectionAdditionContainer}
        >
          <TextField
            defaultValue={prevBannerState.notificationMessageText}
            preset="border"
            label="Message text"
            maxLength={15}
            labelTx="banner.notification.message"
            onChange={handleOnChangeInput('notificationMessageText')}
          />
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.notificationButtonText}
            preset="border"
            label="Button Text"
            maxLength={15}
            labelTx="banner.notification.text"
            onChange={handleOnChangeInput('notificationButtonText')}
          />
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.notificationButtonLink}
            preset="border"
            label="Link for Button"
            maxLength={200}
            labelTx="banner.notification.link"
            onChange={handleOnChangeInput('notificationButtonLink')}
          />
        </Column>
      </Section>
    </>
  )
}
