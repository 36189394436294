import React, { FC } from 'react'

import { Color, Row, Text, TextTranslate } from '../../components'

export type EmptySectionProps = {
  className?: string
  text: string
  tx?: TextTranslate
  color?: Color
}

export const EmptySection: FC<EmptySectionProps> = ({
  className,
  color = 'inactive',
  text,
  tx
}) => {
  return (
    <Row fullWidth className={className} justifyContent="flex-start">
      <Text color={color} preset="body" text={text} tx={tx} />
    </Row>
  )
}
