import { TextTranslate } from '../text'

export enum PickerType {
  DATE = 'date',
  PERIOD = 'period'
}

export interface DateOfWeek {
  text: string
  tx: TextTranslate
  values: string[]
}

export interface CalendarProps {
  value?: Date
  onChange?: (fromValue: Date, toValue?: Date) => void
}
