import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '24px 16px'
  },
  content: {
    marginTop: 24
  },
  button: {
    marginLeft: 24
  },
  leftColumn: {
    flex: 2,
    width: '66%'
  },
  rightColumn: {
    flex: 1,
    width: '33%',
    marginLeft: 24
  },
  section: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  submitContainer: {
    background: theme.colors.white,
    bottom: 0,
    boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
    marginTop: 16,
    padding: 24,
    position: 'absolute'
  },
  sectionAdditionContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: '100%'
  },
  field: {
    marginTop: 16
  },
  title: {
    marginTop: 24
  },
  link: {
    textDecoration: 'underline',
    marginLeft: 16
  }
}))
