import { gql } from '@apollo/client'

import { COLLECTION_FRAGMENT, PAGE_INFO_FRAGMENT } from '../fragments'

export const HOME_COLLECTIONS_QUERY = gql`
  ${COLLECTION_FRAGMENT}
  query Collections(
    $first: Int
    $last: Int
    $before: String
    $after: String
    $filter: CollectionFilterInput
  ) {
    collections(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: $filter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...CollectionFragment
          products(first: 1) {
            totalCount
          }
        }
      }
    }
  }
`
export const VENDOR_COLLECTIONS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  query VendorCollections(
    $filter: CollectionFilterInput
    $sortBy: CollectionSortingInput
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    vendorCollections(
      filter: $filter
      sortBy: $sortBy
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      totalCount
      filters
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...CollectionFragment
          products(first: 1) {
            totalCount
          }
        }
      }
    }
  }
`

export const COLLECTION_BY_ID_QUERY = gql`
  ${COLLECTION_FRAGMENT}
  query CollectionById($id: ID!, $slug: String) {
    collection(id: $id, slug: $slug) {
      ...CollectionFragment
    }
  }
`
export const CREATE_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  mutation CollectionCreate($input: CollectionCreateInput!) {
    collectionCreate(input: $input) {
      collection {
        ...CollectionFragment
      }
      productErrors {
        field
        message
        code
      }
    }
  }
`

export const UPDATE_COLLECTIONS = gql`
  ${COLLECTION_FRAGMENT}
  mutation CollectionUpdate($id: ID!, $input: CollectionInput!) {
    collectionUpdate(id: $id, input: $input) {
      collection {
        ...CollectionFragment
      }
      productErrors {
        field
        message
        code
      }
    }
  }
`

export const DELETE_COLLECTION = gql`
  ${COLLECTION_FRAGMENT}
  mutation collectionDelete($id: ID!) {
    collectionDelete(id: $id) {
      collection {
        ...CollectionFragment
      }
      productErrors {
        field
        message
        code
      }
    }
  }
`
