import React, { FC, useMemo, useRef, useState } from 'react'
import { useTheme } from 'react-jss'

import { Color } from '../../services'
import { useOutsideAlerter } from '../../hooks'

import { Column } from '../column'
import { ColorDot } from '../color-dot'
import { Row } from '../row'
import { Text } from '../text'
import { Icon, ICONS } from '../icon'
import { LightTheme, Color as ColorTheme } from '../Theme'

import { useStyle } from './color-picker.styles'
import { ColorPickerProps } from './color-picker.types'

export const ColorPicker: FC<ColorPickerProps> = ({
  className = '',
  data = [],
  defaultValue,
  text = 'Color',
  tx = 'color.picker',
  onChange
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [value, changeValue] = useState<Color | undefined>(defaultValue)
  const [focus, changeFocus] = useState(false)

  const theme: LightTheme = useTheme()
  const classes = useStyle({ focus, value })
  const valueColor: ColorTheme = value || focus ? 'yellow' : 'inactive'

  const Code = useMemo(() => {
    if (value) {
      return value.code
    }
  }, [value])

  const Name = useMemo(() => {
    if (value) {
      return value.name
    }

    return ''
  }, [value])

  const handleOnChangeValue = (color: Color) => () => {
    changeFocus(false)
    changeValue(color)

    if (onChange) {
      onChange(color)
    }
  }

  const handleOnFocus = (state: boolean) => () => {
    changeFocus(state)
  }

  useOutsideAlerter(containerRef, focus, handleOnFocus(false))

  return (
    <Column fullWidth className={className} ref={containerRef}>
      <Row
        fullWidth
        className={classes.container}
        justifyContent="space-between"
        onClick={handleOnFocus(!focus)}
      >
        <Column alignItems="flex-start" justifyContent="flex-start">
          <Text
            color={valueColor}
            preset="subscriptionText"
            text={text}
            tx={tx}
          />
          <Row className={classes.valueDot}>
            <ColorDot preset="productColor" color={Code || 'transparent'} />
            <Text
              className={classes.valueText}
              color="black"
              preset="body"
              text={Name}
            />
          </Row>
        </Column>
        <Icon fill={theme.colors.black} src={ICONS.arrowDown} />
      </Row>
      <Column fullWidth>
        {focus && (
          <Row
            wrap
            className={classes.colorsContainer}
            justifyContent="flex-start"
          >
            {data.map((color) => (
              <ColorDot
                key={color.id}
                className={classes.colorDot}
                color={color.code}
                preset="productColor"
                onClick={handleOnChangeValue(color)}
              />
            ))}
          </Row>
        )}
      </Column>
    </Column>
  )
}
