import React, { FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  arrayHasElements,
  Column,
  ICONS,
  Row,
  TextField,
  TablePagginationBar,
  LoadingContainer,
  EmptySection,
  QuestionConfirmModal
} from '../../../components'
import { Product } from '../../../services'

import { CabinetNavigatorPathVendor } from '../../cabinet-navigator'

import { ItemsTableHeaderDefault } from '../items-table-header-default'
import { ItemsTableHeaderChecked } from '../items-table-header-checked'
import { ItemsTableItem } from '../items-table-item'

import { useStyle } from './items-table.styles'
import { ItemsTableProps } from './items-table.types'
import { activateProductItem } from './helpers'
import { ItemsTableHeader } from '../items-table-header'

export const ItemsTable: FC<ItemsTableProps> = ({
  className = '',
  children,
  data,
  loading,
  rowCounts,
  paginationState,
  onActivate,
  onDeactivate,
  onChangeSearch,
  onChangeRowCount,
  onDuplicateProduct,
  onRemoveProductFromList,
  onNextPage,
  onChangeSetTopProduct,
  onPrevioslyPage,
  onChangePage,
  onSort,
  onChangeActiveItems
}) => {
  const history = useHistory()
  const classes = useStyle()
  const [checkedIds, changeCheckedIds] = useState<string[]>([])
  const [topProduct, changeTopProduct] = useState<boolean[]>([])
  const [topProductValue, changeTopProductValue] = useState<boolean>()
  const [openDelete, changeOpenDelete] = useState(false)
  const [checkedActiveItems, changeCheckedActiveItems] = useState<Product[]>([])
  const checkedItemsCount = checkedIds.length

  const TopProductTopItem = useMemo(() => {
    if (typeof topProductValue === 'undefined') {
      return topProduct.reduce((acc, item) => acc && item, true)
    }

    return topProductValue
  }, [topProduct, topProductValue])

  useEffect(() => {
    if (onChangeActiveItems) {
      onChangeActiveItems(checkedActiveItems.length)
    }
  }, [checkedActiveItems])

  const handleOnClickItem = (id: string) => {
    history.push(`${CabinetNavigatorPathVendor.ITEMS_EDIT}/${id}`)
  }

  const handleOnDuplicate = () => {
    if (onDuplicateProduct) {
      onDuplicateProduct(checkedIds)
    }
  }

  const handleOnOpenDelete = (state: boolean) => () => {
    changeOpenDelete(state)
  }

  const handleOnRemoveFromList = () => {
    if (onRemoveProductFromList) {
      onRemoveProductFromList(checkedIds)
      changeOpenDelete(false)
      changeCheckedIds([])
    }
  }

  const handleOnUpdate = (state: boolean) => () => {
    const publishData = data
      .filter((item) => checkedIds.includes(item.id))
      .map(activateProductItem(state))

    if (!arrayHasElements(publishData)) {
      return
    }

    if (state && onActivate) {
      onActivate(publishData)
    }

    if (!state && onDeactivate) {
      onDeactivate(publishData)
    }
  }

  const firstTopProductState = (isTopProduct: boolean) => {
    const firstProductState = topProduct.findIndex(
      (topProductState) => topProductState === isTopProduct
    )
    if (firstProductState !== -1) {
      changeTopProduct(topProduct.splice(firstProductState, firstProductState))
    }
  }

  const handleOnPick = (id: string, state: boolean, isTopProduct: boolean) => {
    const filteredIds = checkedIds.filter((removeId) => removeId !== id)
    const filteredCheckedProducts = checkedActiveItems.filter(
      (item) => item.id !== id
    )
    if (state) {
      const checkedProduct = data.filter((item) => item.id === id)

      if (
        checkedProduct[0]?.visibleInListings === true &&
        onChangeActiveItems
      ) {
        changeCheckedActiveItems([
          ...filteredCheckedProducts,
          ...checkedProduct
        ])
      }
      changeCheckedIds([...filteredIds, id])
      changeTopProduct([...topProduct, isTopProduct])
    } else {
      firstTopProductState(isTopProduct)
      changeCheckedIds(filteredIds)
      changeCheckedActiveItems(filteredCheckedProducts)
    }

    changeTopProductValue(undefined)
  }

  const handleOnPickAll = (state: boolean) => {
    if (state) {
      const ids = data.map((product) => product.id)
      changeCheckedIds(ids)
      const activeProducts = data.filter(
        (product) => product.visibleInListings === true
      )
      changeCheckedActiveItems(activeProducts)
    } else {
      changeCheckedIds([])
      changeCheckedActiveItems([])
    }
  }

  const PickedAll = useMemo(() => {
    return checkedIds.length === data.length
  }, [checkedIds, data])

  const getActive = (id?: string) => {
    return Boolean(checkedIds.find((removeId) => removeId === id))
  }

  const handleOnSetTopProduct = (active: boolean) => {
    changeTopProductValue(active)

    if (onChangeSetTopProduct) {
      onChangeSetTopProduct(checkedIds, active)
    }
  }

  const HeaderChecked = (
    <ItemsTableHeaderChecked
      className={classes.header}
      pickedAll={PickedAll}
      checkedItemsCount={checkedItemsCount}
      topProductTopItem={TopProductTopItem}
      onPickAll={handleOnPickAll}
      onSetTopProduct={handleOnSetTopProduct}
      onDuplicate={handleOnDuplicate}
      onActivate={handleOnUpdate(true)}
      onDeactivate={handleOnUpdate(false)}
      onOpenDelete={handleOnOpenDelete}
    />
  )

  const Header = (
    <ItemsTableHeaderDefault
      className={classes.header}
      pickedAll={PickedAll}
      onPickAll={handleOnPickAll}
      onSort={onSort}
    />
  )

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
        {children}
      </Row>
      <Column fullWidth alignItems="flex-start">
        <ItemsTableHeader
          checked={arrayHasElements(checkedIds)}
          header={Header}
          headerChecked={HeaderChecked}
        />
        <LoadingContainer loading={Boolean(loading)}>
          {data.map((product) => (
            <ItemsTableItem
              key={`banner_${product.id}`}
              {...product}
              product={product}
              checked={getActive(product.id)}
              onClick={handleOnClickItem}
              onChange={handleOnPick}
            />
          ))}
          {!arrayHasElements(data) && (
            <EmptySection
              text="No Products found."
              tx="item.table.no.products.found"
            />
          )}
          {paginationState && (
            <TablePagginationBar
              paginationState={paginationState}
              rowCounts={rowCounts}
              onNextPage={onNextPage}
              onPrevioslyPage={onPrevioslyPage}
              onChangePage={onChangePage}
              onChangeRowCount={onChangeRowCount}
            />
          )}
        </LoadingContainer>
      </Column>
      {openDelete && (
        <QuestionConfirmModal
          title="Delete Item"
          titleTx="item.modal.delete.title"
          description="Are you sure you want to delete this Item?"
          descriptionTx="item.modal.delete.description"
          onClose={handleOnOpenDelete(false)}
          onSubmit={handleOnRemoveFromList}
        />
      )}
    </Column>
  )
}
