import { createUseStyles } from 'react-jss'

import background from '../../assets/images/background-pattern.png'

export const useStyle = createUseStyles({
  container: {
    backgroundImage: `url(${background})`,
    paddingBottom: 24
  },
  title: {
    marginTop: 165
  },
  subtitle: {
    marginTop: 28
  },
  subcsriptionContainer: {
    marginTop: 32
  },
  priceRow: {
    marginLeft: 16
  },
  buttonContainer: {
    marginTop: 42
  },
  submit: {
    marginTop: 24,
    width: 360
  }
})
