import React, { FC } from 'react'

import { BannerButtonType, BannerKind } from '../../services'

import { TextPresets } from '../text'
import { ButtonPresets } from '../button'

import { DefaultBanerPreview } from './default-banner-preview'
import { PromotedStoreBanerPreview } from './promoted-store-banner-preview'
import { TopProductBanerPreview } from './top-product-banner-preview'
import { PremiumUserBanerPreview } from './premium-user-banner-preview'
import { PromotionBannerPreview } from './promotion-banner-preview'
import { ModalBannerPreviewProps } from './modal-banner-preview.types'

export const ModalBanerPreview: FC<ModalBannerPreviewProps> = ({
  className,
  open,
  kind,
  title,
  image,
  button,
  buttonText,
  onClose,
  active,
  mainHeading,
  secondaryHeading,
  mainDescription,
  secondaryDescription,
  notificationText,
  notificationBtnText,
  bannerMobileImage,
  isNotificationActive,
  isButtonActive,
  onOpenPreviewDesktopBanner,
  onOpenPreviewMobileBanner
}) => {
  const isBigButton = button === BannerButtonType.BIG_BUTTON

  const buttonPreset: ButtonPresets = isBigButton ? 'primary' : 'button2'
  const buttonTextPreset: TextPresets = isBigButton ? 'h5' : 'maxButton'

  if (!open) {
    return <></>
  }
  switch (kind) {
    case BannerKind.PROMOTION_BANNER:
      return (
        <PromotionBannerPreview
          className={className}
          title={title}
          image={image}
          bannerMobileImage={bannerMobileImage}
          buttonText={buttonText}
          mainHeading={mainHeading}
          button={button}
          buttonPreset={buttonPreset}
          buttonTextPreset={buttonTextPreset}
          active={active}
          isButtonActive={isButtonActive}
          onOpenPreviewDesktopBanner={onOpenPreviewDesktopBanner}
          onOpenPreviewMobileBanner={onOpenPreviewMobileBanner}
          onClose={onClose}
        />
      )
    case BannerKind.PREMIUM_USER_BANNER:
      return (
        <PremiumUserBanerPreview
          className={className}
          title={title}
          image={image}
          bannerMobileImage={bannerMobileImage}
          buttonText={buttonText}
          mainHeading={mainHeading}
          mainDescription={mainDescription}
          secondaryHeading={secondaryHeading}
          secondaryDescription={secondaryDescription}
          button={button}
          active={active}
          buttonPreset={buttonPreset}
          buttonTextPreset={buttonTextPreset}
          isButtonActive={isButtonActive}
          onOpenPreviewDesktopBanner={onOpenPreviewDesktopBanner}
          onOpenPreviewMobileBanner={onOpenPreviewMobileBanner}
          onClose={onClose}
        />
      )
    case BannerKind.TOP_PRODUCT_BANNER:
      return <TopProductBanerPreview image={image} onClose={onClose} />
    case BannerKind.PROMOTED_STORE_BANNER:
      return (
        <PromotedStoreBanerPreview
          title={title}
          image={image}
          buttonText={buttonText}
          mainHeading={mainHeading}
          notificationText={notificationText}
          notificationBtnText={notificationBtnText}
          mainDescription={mainDescription}
          secondaryHeading={secondaryHeading}
          secondaryDescription={secondaryDescription}
          buttonPreset={buttonPreset}
          buttonTextPreset={buttonTextPreset}
          button={button}
          bannerMobileImage={bannerMobileImage}
          active={active}
          isNotificationActive={isNotificationActive}
          isButtonActive={isButtonActive}
          onOpenPreviewDesktopBanner={onOpenPreviewDesktopBanner}
          onOpenPreviewMobileBanner={onOpenPreviewMobileBanner}
          onClose={onClose}
        />
      )
    default:
      return (
        <DefaultBanerPreview
          className={className}
          title={title}
          image={image}
          bannerMobileImage={bannerMobileImage}
          buttonText={buttonText}
          mainHeading={mainHeading}
          active={active}
          onOpenPreviewDesktopBanner={onOpenPreviewDesktopBanner}
          onOpenPreviewMobileBanner={onOpenPreviewMobileBanner}
          onClose={onClose}
        />
      )
  }
}
