import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  offerTitleContainer: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '8px 24px',
    width: '100%',
    minHeight: 64
  },
  offerTitle: {
    marginLeft: 24
  },
  offerDescription: {},
  title: {
    padding: '8px 0'
  }
}))
