import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`
  },
  header: {
    width: '100%'
  },
  search: {
    width: '100%'
  },
  tableTopContainer: {
    padding: 16
  },
  tableColumn: {
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    flex: 1,
    height: '100%',
    padding: 8,
    minWidth: 304
  },
  tableColumnmMin: {
    minWidth: 180
  },
  tableColumnBig: {
    minWidth: 352
  },
  table: {
    overflowX: 'auto'
  },
  firstColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    background: theme.colors.greyFon,
    borderRight: `1px solid ${theme.colors.shadow}`
  }
}))
