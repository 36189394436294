import React, { ChangeEventHandler, FC, useEffect } from 'react'
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { ICONS, Row, TextField } from '../../../components'
import { arrayHasElements } from '../../../utils/tables'

import { FeedbackMonitoringNavItem } from '../feedback-monitoring-nav-item'
import { useStyle } from './feedback-monitoring-nav.styles'
import { FeedbackMonitoringNavProps } from './feedback-monitoring-nav.types'

export const FeedbackMonitoringNav: FC<FeedbackMonitoringNavProps> = ({
  className = '',
  data,
  dafaultSearchValue,
  onSearch
}) => {
  const { path: prefix } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyle()

  const getLink = (link: string) => `${prefix}${link}`

  useEffect(() => {
    if (prefix === location.pathname && arrayHasElements(data)) {
      history.push(getLink(data[0].link))
    }
  }, [location])

  const handleOnClick = (link: string) => () => {
    history.push(link)
  }

  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target

    if (onSearch) {
      onSearch(value)
    }
  }

  return (
    <>
      <Row
        fullWidth
        className={`${className} ${classes.nav}`}
        justifyContent="space-between"
      >
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          defaultValue={dafaultSearchValue}
          maxLength={200}
          leftIcon={ICONS.search}
          onChange={handleOnSearch}
        />
        {data.map((navItem) => {
          const fullLink = getLink(navItem.link)
          return (
            <FeedbackMonitoringNavItem
              key={`navigation_path_${navItem.link}`}
              active={location.pathname.includes(fullLink)}
              text={navItem.text}
              tx={navItem.tx}
              value={navItem.value}
              preset={navItem.preset}
              onClick={handleOnClick(fullLink)}
            />
          )
        })}
      </Row>
      {data.map((navItem) => (
        <Route
          exact
          key={`navigation_route_${navItem.link}`}
          path={getLink(navItem.link)}
        >
          {navItem.content}
        </Route>
      ))}
    </>
  )
}
