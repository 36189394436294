import React, { ChangeEvent, useMemo, FC, useState } from 'react'
import { useApi } from '../../../providers'
import {
  Column,
  Row,
  Text,
  TextField,
  Dropdown,
  DropdownItemData,
  arrayHasElements,
  Loader
} from '../../../components'
import { RegisterCredentials } from '../../../services'

import { ValidatorPhone, ValidatorState } from '../validation'

import { ContactInfoProps } from './contact-info.types'

import { useStyle } from './contact-info.styles'

export const ContactInfo: FC<ContactInfoProps> = ({
  email,
  phone,
  state,
  country,
  user,
  changeUser
}) => {
  const { country: countryApi } = useApi()
  const { data: countriesData } = countryApi.useCountries()
  const [error, changeError] = useState(false)
  const [property, changeProp] = useState('')
  const classes = useStyle({ errorStyle: error })

  const Countries = useMemo(() => {
    if (countriesData) {
      return countriesData.countries.edges.map((countryItem) => ({
        ...countryItem.node,
        value: countryItem.node.code
      }))
    }

    return []
  }, [countriesData])

  const CountryId = useMemo(() => {
    return Countries.find((countryItem) => countryItem.name === country)?.id
  }, [country, Countries])

  const handleOnChange =
    (prop: keyof RegisterCredentials) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeProp(prop)

      if (
        value.length === 0 &&
        (!ValidatorState(value) || !ValidatorPhone(value))
      ) {
        changeError(true)
      } else changeError(false)

      changeUser({
        ...user,
        [prop]: value
      })
    }

  const handleOnChangeCountry = (value: DropdownItemData) => {
    changeUser({
      ...user,
      country: value.value
    })
  }

  if (!arrayHasElements(Countries)) {
    return <Loader />
  }

  return (
    <Column className={classes.infoContainer} alignItems="flex-start" fullWidth>
      <Row fullWidth justifyContent="flex-start" className={classes.infoTitle}>
        <Text color="black" preset="h3" text="Contact Information" />
      </Row>
      <Row
        fullWidth
        alignItems="flex-start"
        justifyContent="space-between"
        className={classes.inputRow}
      >
        <Column fullWidth>
          <Dropdown
            className={classes.dropdown}
            label="Country"
            labelTx="userInfo.country"
            data={Countries}
            preset="body"
            onChange={handleOnChangeCountry}
            defaultValue={CountryId}
          />
          <TextField
            className={classes.inputCity}
            label="City/State"
            labelTx="userInfo.city"
            preset="main"
            maxLength={30}
            onChange={handleOnChange('state')}
            defaultValue={state}
          />
          {error && property === 'state' && (
            <Row
              fullWidth
              justifyContent="flex-start"
              className={classes.error}
            >
              <Text
                text="The field can't be empty"
                tx="general.info.error"
                preset="secondTitle"
                color="error"
              />
            </Row>
          )}
        </Column>
        <Column fullWidth>
          <Column
            fullWidth
            alignItems="flex-start"
            className={classes.summaryMail}
          >
            <Text
              text="E-mail"
              tx="register.email"
              preset="subscriptionText"
              color="yellow"
            />
            <Text
              text={email}
              preset="body"
              color="inactive"
              className={classes.mailText}
            />
          </Column>
          <TextField
            className={classes.inputPhone}
            label="Phone"
            labelTx="userInfo.phone"
            preset="main"
            onChange={handleOnChange('phone')}
            defaultValue={phone}
            maxLength={16}
          />
          {error && property === 'phone' && (
            <Row
              fullWidth
              justifyContent="flex-start"
              className={classes.error}
            >
              <Text
                text="Please enter a valid phone number"
                tx="general.info.phone.error"
                preset="secondTitle"
                color="error"
              />
            </Row>
          )}
        </Column>
      </Row>
    </Column>
  )
}
