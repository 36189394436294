import React, { FC, MouseEventHandler, useState } from 'react'

import { AddFundsModal, Button } from '../../../components'

import { AddFundsButtonProps } from './add-funds-button.types'

export const AddFundsButton: FC<AddFundsButtonProps> = ({
  className = '',
  defaultOpen = false,
  name = 'Add Funds',
  nameTx = 'add.funds.button.title',
  onClick,
  ...addFundsModalProps
}) => {
  const [open, changeOpen] = useState(defaultOpen)

  const handleOnOpen =
    (state: boolean): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      changeOpen(state)

      if (onClick) {
        onClick(event)
      }
    }

  return (
    <>
      <Button
        className={className}
        color="yellow"
        preset="button3"
        textColor="white"
        textPreset="maxButton"
        text={name}
        tx={nameTx}
        onClick={handleOnOpen(true)}
      />
      <AddFundsModal
        {...addFundsModalProps}
        open={open}
        onClose={handleOnOpen(false)}
      />
    </>
  )
}
