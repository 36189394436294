import gql from 'graphql-tag'

import {
  ORDER_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  PAYMENT_ERROR_FRAGMENT
} from '../fragments'

export const PAYOUTS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${ORDER_FRAGMENT}
  query Payouts(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: OrderSortingInput
    $filter: PayoutFilterInput
  ) {
    payouts(
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
      filter: $filter
    ) {
      filters
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...OrderFragment
          referralFee
          paypalOrder
          customerPayment
          sellingFee
          promotionalFee
          vendorPayment
          paymentStatus
        }
      }
    }
  }
`

export const BILLING_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${ORDER_FRAGMENT}
  query Billing(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: OrderSortingInput
    $filter: OrderFilterInput
  ) {
    billing(
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
      filter: $filter
    ) {
      filters
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        cursor
        node {
          ...OrderFragment
          referralFee
          paypalOrder
          customerPayment
          sellingFee
          promotionalFee
          vendorPayment
          paymentStatus
        }
      }
    }
  }
`
export const CREATE_PAYPAL_ORDER_MUTATION = gql`
  ${PAYMENT_ERROR_FRAGMENT}
  mutation CreatePaypalOrder($orderId: ID!, $paypalOrderId: String!) {
    createPaypalOrder(orderId: $orderId, paypalOrderId: $paypalOrderId) {
      paymentErrors {
        ...PaymentErrorFragment
      }
    }
  }
`
