import React, { FC, useMemo } from 'react'

import { Column, ICONS, LoadingContainer, Modal } from '../../../components'
import { useAuth } from '../../../hooks'
import { useApi } from '../../../providers'
import { getFeedbackAmount } from '../../item-form/helpers'

import { ProductPreviewGeneralInfo } from './product-preview-general-info'
import { useStyle } from './product-preview.styles'
import { ProductPreviewProps } from './product-preview.types'

export const ProductPreview: FC<ProductPreviewProps> = ({
  open,
  id,
  onClose
}) => {
  const classes = useStyle()
  const {
    product: { useProductPreviewById }
  } = useApi()
  const { user } = useAuth()

  const { data } = useProductPreviewById({ id })

  const Product = useMemo(() => {
    if (data) {
      return data.product
    }

    return null
  }, [JSON.stringify(data?.product)])

  const Feedbacks = data?.product.feedbacks
  const averageRating = Feedbacks && getFeedbackAmount(Feedbacks)
  const favoriteIcon = ICONS.favorite

  if (!open) {
    return <></>
  }

  return (
    <Modal
      isTitleCenter
      title="Show Product Card"
      titleTx="product.preview.title"
      className={classes.container}
      onClose={onClose}
    >
      <Column fullWidth className={classes.content} justifyContent="flex-start">
        <LoadingContainer className={classes.loader} loading={!Product}>
          {Product && (
            <ProductPreviewGeneralInfo
              product={Product}
              user={user}
              favouriteIcon={favoriteIcon}
              averageRating={averageRating}
            />
          )}
        </LoadingContainer>
      </Column>
    </Modal>
  )
}
