import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  titleContainer: {
    marginTop: 24
  },
  price: {
    marginTop: 24
  },
  benefits: {
    marginTop: 16,
    flexWrap: 'wrap'
  },
  options: {
    columnGap: 20
  }
})
