import React, { FC, MouseEvent } from 'react'
import ReactDOM from 'react-dom'

import { usePortal } from '../../hooks'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Text } from '../text'
import { Row } from '../row'

import { ModalProps } from './modal.types'
import { useStyle } from './modal.styles'

export const Modal: FC<ModalProps> = ({
  className = '',
  title,
  titleTx,
  value,
  children,
  isTitleCenter,
  onClose
}) => {
  const classes = useStyle()
  const target = usePortal('modal')

  const textContainer = isTitleCenter ? `${classes.center}` : `${classes.right}`

  const handleOnPropagationBackground = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return ReactDOM.createPortal(
    <Column className={classes.container}>
      <button className={classes.buttonContainer} onClick={onClose} />
      <Column
        className={`${className} ${classes.modal}`}
        justifyContent="flex-start"
        onClick={handleOnPropagationBackground}
      >
        <Row className={classes.title} justifyContent="space-between">
          <Row className={textContainer}>
            <Text preset="h3" text={title} tx={titleTx} values={value} />
          </Row>
          <button className={classes.closeContainer} onClick={onClose}>
            <Icon src={ICONS.close} className={classes.close} />
          </button>
        </Row>
        <Column
          fullWidth
          className={classes.bottomContainer}
          justifyContent="flex-start"
        >
          {children}
        </Column>
      </Column>
    </Column>,
    target
  )
}
