import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  bottomContainer: {
    padding: 16
  },
  input: {
    flex: 1,
    marginTop: 24
  },
  button: {
    marginLeft: 24,
    width: 168
  },
  buttonsRow: {
    width: '100%',
    padding: '24px 16px',
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  summaryItem: {
    marginTop: 16,
    width: '100%',
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    padding: '4px 24px',
    height: 50
  },
  description: {
    marginTop: 24,
    textAlign: 'center'
  },
  secondaryDesctiption: {
    padding: 16,
    textAlign: 'center'
  }
}))
