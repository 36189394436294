import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Icon } from '../icon'
import { Row } from '../row'
import { LightTheme } from '../Theme'
import { useStyle } from './icon-button.styles'
import { IconButtonProps } from './icon-button.types'

export const IconButton: FC<IconButtonProps> = ({
  backgroundColor,
  iconColor = 'white',
  className = '',
  disabled,
  iconName,
  preset = 'default',
  onClick
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({
    backgroundColor,
    className,
    disabled,
    iconColor,
    iconName,
    preset,
    theme,
    onClick
  })

  const disableIconColor =
    preset === 'transparent' ? theme.colors.yellow : theme.colors[iconColor]
  const nextIconColor = disabled ? theme.colors.inactive : disableIconColor

  return (
    <Row
      className={`${className} ${classes.container} ${classes[preset]}`}
      onClick={onClick}
    >
      <Icon src={iconName} fill={nextIconColor} />
    </Row>
  )
}
