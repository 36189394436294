import React, { ChangeEvent, FC, useMemo, useState } from 'react'

import {
  Dropdown,
  DropdownItemData,
  Icon,
  ICONS,
  QuestionModal,
  Text,
  TextArea,
  TextField
} from '../../../components'
import { ProductOptionVariantInput } from '../../../services'

import { useStyle } from './product-option-item-modal.styles'
import { ProductOptionItemModalProps } from './product-option-item-modal.types'

export const ProductOptionItemModal: FC<ProductOptionItemModalProps> = ({
  onSubmit,
  onClose,
  data,
  activeData,
  title
}) => {
  const classes = useStyle()
  const [option, changeOption] = useState<
    Partial<ProductOptionVariantInput> | undefined
  >(activeData)

  const isDisabled =
    !option?.description ||
    !option.title ||
    option.extraPrice === undefined ||
    option.extraPrice < 0 ||
    !option.extraProductionDays

  const DefaultOption = useMemo(() => {
    const current = data?.find(
      (optionItem) => optionItem.name === option?.title
    )

    return current?.id
  }, [data, option?.title])

  const handleOnChange =
    (prop: keyof ProductOptionVariantInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      changeOption({
        ...option,
        [prop]: value
      })
    }

  const handleOnChangeNumber =
    (prop: keyof ProductOptionVariantInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      const propValue = value === '' ? undefined : Number(value)
      changeOption({
        ...option,
        [prop]: propValue
      })
    }

  const handleOnChangeDropdown =
    (prop: keyof ProductOptionVariantInput) =>
    (dropdownItem: DropdownItemData) => {
      const { name: nextValue } = dropdownItem
      changeOption({
        ...option,
        [prop]: String(nextValue)
      })
    }

  const handleOnSubmit = () => {
    const close = onClose(false)

    if (onSubmit) {
      onSubmit(option as ProductOptionVariantInput)
    }
    close()
  }

  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />
  const titleValue = { title }
  const fullTitle = title ? `Edit ${title} Variant` : 'Edit Material Variant'
  const fullTitleTx = title
    ? 'item.form.edit.option.variant.title'
    : 'item.form.edit.material.title'

  return (
    <>
      <QuestionModal
        disabled={isDisabled}
        title={fullTitle}
        titleTx={fullTitleTx}
        successText="Apply"
        successTx="item.form.create.material.apply"
        value={titleValue}
        onClose={onClose(false)}
        onSubmit={handleOnSubmit}
      >
        <Text
          color="inactive"
          preset="body"
          text="Please fill in all fields."
          tx="item.form.create.material.description"
        />
        {data ? (
          <Dropdown
            className={classes.dropdown}
            data={data}
            defaultValue={DefaultOption}
            preset="h5"
            label="Material"
            labelTx="item.form.create.material"
            onChange={handleOnChangeDropdown('title')}
          />
        ) : (
          <TextField
            defaultValue={option?.title}
            className={classes.field}
            maxLength={100}
            label="Title"
            labelTx="item.form.create.material.title.input"
            onChange={handleOnChange('title')}
          />
        )}
        <TextArea
          defaultValue={option?.description}
          className={classes.field}
          preset="border"
          maxLength={500}
          label="Description"
          labelTx="item.form.create.material.description"
          onChange={handleOnChange('description')}
        />
        <TextField
          defaultValue={option?.extraPrice || '0'}
          className={classes.field}
          label="Extra Price"
          labelTx="item.form.create.material.price.input"
          type="number"
          rightElement={dollarIcon}
          onChange={handleOnChangeNumber('extraPrice')}
        />
        <TextField
          defaultValue={option?.extraProductionDays}
          className={classes.field}
          label="Extra Production Days"
          labelTx="item.form.create.material.days.input"
          type="number"
          onChange={handleOnChangeNumber('extraProductionDays')}
        />
      </QuestionModal>
    </>
  )
}
