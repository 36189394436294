import React, { FC, useState } from 'react'

import { QuestionModal } from '../../../../components'
import { PromotionBanner } from '../../../../services'
import { ProductRadioBox } from '../../../../components/product-radio-box'

import { BannerPickModalProps } from './banner-pick-modal.types'

export const BannerPickModal: FC<BannerPickModalProps> = ({
  defaultActive,
  totalCount,
  data,
  title,
  titleTx,
  onClose,
  onSubmit,
  onLoadMore,
  onRefresh,
  onSearch
}) => {
  const [active, changeActive] = useState<PromotionBanner | undefined>(
    defaultActive
  )

  const handleOnChange = (banner: PromotionBanner) => {
    changeActive(banner)
  }

  const handleOnSubmit = () => {
    if (active && onSubmit) {
      onSubmit(active)
    }
  }

  return (
    <QuestionModal
      title={title}
      titleTx={titleTx}
      onClose={onClose}
      onSubmit={handleOnSubmit}
    >
      <ProductRadioBox
        active={active?.id}
        bannerData={data}
        totalCount={totalCount}
        onFetchMore={onLoadMore}
        onChangeBanner={handleOnChange}
        onSearch={onSearch}
        onRefresh={onRefresh}
      />
    </QuestionModal>
  )
}
