import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '24px 16px'
  },
  titleContainer: {
    marginTop: 24
  },
  content: {
    columnGap: 20,
    marginTop: 20
  },
  leftGroup: {
    flex: 2,
    justifyContent: 'unset'
  },
  dashboard: {
    marginBottom: 20,
    columnGap: 20
  },
  rightGroup: {
    flex: 1
  },
  contactBtn: {
    marginTop: 16
  },
  button: {
    maxWidth: 312
  }
})
