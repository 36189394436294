import {
  ApolloError,
  MutationUpdaterFn,
  MutationFunction,
  MutationResult,
  useMutation
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import React from 'react'
import { useIntl } from 'react-intl'

import { isJwtError } from './auth/errors'
import { useNotifier } from './hooks/useNotifier'
import { useUser } from './hooks/useUser'
import { commonMessages } from './intl'
import { getMutationStatus } from './misc'

export interface TypedMutationInnerProps<TData, TVariables> {
  children: (
    mutateFn: MutationFunction<TData, TVariables>,
    result: MutationResult<TData>
  ) => React.ReactNode
  onCompleted?: (data: TData) => void
  onError?: (error: ApolloError) => void
  variables?: TVariables
}

// For some reason Mutation returns () => Element instead of () => ReactNode
export function TypedMutation<TData, TVariables>(
  mutation: DocumentNode,
  update?: MutationUpdaterFn<TData>
) {
  return (props: TypedMutationInnerProps<TData, TVariables>) => {
    const { children, onCompleted, onError, variables } = props

    const notify = useNotifier()
    const intl = useIntl()
    const user = useUser()
    const [mutateFn, result] = useMutation<TData, TVariables>(mutation, {
      variables,
      update,
      onCompleted,
      onError: (err: ApolloError) => {
        if (err.networkError) {
          notify({
            status: 'error',
            text: intl.formatMessage(commonMessages.somethingWentWrong)
          })
        }
        if (
          err.graphQLErrors[0].extensions &&
          err.graphQLErrors[0].extensions.exception?.code ===
            'ReadOnlyException'
        ) {
          notify({
            status: 'error',
            text: intl.formatMessage(commonMessages.readOnly)
          })
        } else if (err.graphQLErrors.some(isJwtError)) {
          if (user.logout) {
            user.logout()
          }
          notify({
            status: 'error',
            text: intl.formatMessage(commonMessages.sessionExpired)
          })
        } else {
          notify({
            status: 'error',
            text: intl.formatMessage(commonMessages.somethingWentWrong)
          })
        }
        if (onError) {
          onError(err)
        }
      }
    })

    return children(mutateFn, {
      ...result,
      // @ts-ignore
      status: getMutationStatus(result)
    })
  }
}
