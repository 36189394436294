import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { MenuSliderStyleProps } from './menu-slider.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  slider: {
    backgroundColor: theme.colors.white,
    borderRadius: 4
  },
  sliderText: {
    cursor: 'pointer',
    marginLeft: 16
  },
  titleRow: {
    cursor: 'pointer',
    padding: 16,
    width: '100%'
  },
  content: ({ open }: MenuSliderStyleProps) => ({
    display: 'block',
    height: !open && 0,
    visibility: open ? 'visible' : 'hidden',
    margin: open ? '0px 16px' : 0,
    padding: open ? '16px 0px' : 0,
    transition: 'all 0.25s ease-in-out',
    overflow: 'hidden',
    width: 'calc(100% - 32px)'
  }),
  icon: ({ open }) => ({
    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
    transformOrigin: 'center',
    transition: 'transform 0.25s ease-in-out'
  })
}))
