import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { ColorDot, LightTheme, Row, Text } from '../../../../components'

import { useStyle } from './product-preview-features-details-block-option.styles'
import { ProductPreviewFeaturesOptionProps } from './product-preview-features-details-block-option.types'

export const ProductPreviewFeaturesDetailsBlockOption: FC<ProductPreviewFeaturesOptionProps> =
  ({ className = '', label, labelTx, text, tx }) => {
    const theme = useTheme<LightTheme>()
    const classes = useStyle()

    return (
      <Row
        alignItems="flex-start"
        justifyContent="flex-start"
        className={className}
      >
        <ColorDot
          className={classes.dot}
          color={theme.colors.yellow}
          // preset="mini"
        />
        <Row
          fullWidth
          className={classes.featuresRow}
          justifyContent="flex-start"
        >
          {label && <Text color="inactive" text={label} tx={labelTx} />}
          <Text className={classes.text} text={text} tx={tx} />
        </Row>
      </Row>
    )
  }
