import { gql } from '@apollo/client'

import { TypedMutation } from '../mutations'
import { USER_FRAGMENT } from '../services/fragments'
import {
  RequestPasswordReset,
  RequestPasswordResetVariables
} from './types/RequestPasswordReset'
import { SetPassword, SetPasswordVariables } from './types/SetPassword'

export const REGISTER_MUTATION = gql`
  ${USER_FRAGMENT}
  mutation RegisterAccount($input: AccountRegisterInput!) {
    accountRegister(input: $input) {
      errors: accountErrors {
        field
        message
        code
      }
      jwtToken
      refreshToken
      csrfToken
      talkjsSignature
      user {
        ...UserFragment
      }
    }
  }
`

export const USER_INFO_REGISTER_MUTATION = gql`
  ${USER_FRAGMENT}
  mutation AccountUpdate($input: AccountInput!) {
    accountUpdate(input: $input) {
      accountErrors {
        field
        message
        code
      }
      user {
        ...UserFragment
      }
    }
  }
`

export const USER_AVATAR_UPDATE = gql`
  ${USER_FRAGMENT}
  mutation userAvatarUpdate($file: Upload!) {
    userAvatarUpdate(image: $file) {
      accountErrors {
        field
        message
        code
      }
      user {
        ...UserFragment
      }
    }
  }
`

export const USER_AVATAR_DELETE = gql`
  ${USER_FRAGMENT}
  mutation userAvatarDelete {
    userAvatarDelete {
      accountErrors {
        field
        message
        code
      }
      user {
        ...UserFragment
      }
    }
  }
`

export const USER_ACCOUNT_DELETE = gql`
  ${USER_FRAGMENT}
  mutation accountDelete {
    accountDelete {
      accountErrors {
        field
        message
        code
      }
      user {
        ...UserFragment
      }
    }
  }
`

export const TOKEN_AUTH_MUTATION = gql`
  ${USER_FRAGMENT}
  mutation TokenAuth($email: String!, $password: String!, $page: String) {
    tokenCreate(email: $email, password: $password, page: $page) {
      errors: accountErrors {
        code
        field
        message
      }
      csrfToken
      token
      refreshToken
      talkjsSignature
      user {
        ...UserFragment
      }
    }
  }
`

export const TOKEN_VERIFY_MUTATION = gql`
  ${USER_FRAGMENT}
  mutation VerifyToken($token: String!) {
    tokenVerify(token: $token) {
      payload
      user {
        ...UserFragment
      }
    }
  }
`

export const TOKEN_REFRESH_MUTATION = gql`
  ${USER_FRAGMENT}
  mutation RefreshToken($token: String!, $csrfToken: String!) {
    tokenRefresh(refreshToken: $token, csrfToken: $csrfToken) {
      accountErrors {
        field
        message
        code
      }
      token
      talkjsSignature
      user {
        ...UserFragment
      }
    }
  }
`

// export const REQUEST_PASSWORD_RESET = gql`
//   ${accountErrorFragment}
//   mutation RequestPasswordReset($email: String!, $redirectUrl: String!) {
//     requestPasswordReset(email: $email, redirectUrl: $redirectUrl) {
//       errors: accountErrors {
//         ...AccountErrorFragment
//       }
//     }
//   }
// `

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!, $server: String!) {
    requestPasswordReset(email: $email, server: $server) {
      errors {
        field
        message
      }
    }
  }
`

export const RequestPasswordResetMutation = TypedMutation<
  RequestPasswordReset,
  RequestPasswordResetVariables
>(REQUEST_PASSWORD_RESET)

export const SET_PASSWORD = gql`
  mutation SetPassword($email: String!, $token: String!, $password: String!) {
    setPassword(email: $email, token: $token, password: $password) {
      errors {
        field
        message
      }
      accountErrors {
        field
        message
        code
      }
      user {
        id
      }
      token
      refreshToken
    }
  }
`

export const PASSWORD_CHANGE = gql`
  mutation PasswordChange($newPassword: String!, $oldPassword: String!) {
    passwordChange(newPassword: $newPassword, oldPassword: $oldPassword) {
      errors {
        field
        message
      }
      accountErrors {
        field
        message
        code
      }
      user {
        id
      }
    }
  }
`

export const UPDATE_EMAIL = gql`
  mutation emailUpdate($token: String!) {
    confirmEmailChange(token: $token) {
      user {
        email
      }
      errors {
        field
        message
      }
      accountErrors {
        code
        message
        field
      }
    }
  }
`

export const SetPasswordMutation = TypedMutation<
  SetPassword,
  SetPasswordVariables
>(SET_PASSWORD)
