import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../../column'
import { Text } from '../../text'
import { Button } from '../../button'
import { LightTheme } from '../../Theme'

import { useStyle } from '../modal-banner-preview.styles'
import { ModalBannerPreviewHeader } from '../modal-banner-preview-header'

import { PremiumUserBannerPreviewProps } from './premium-user-banner-preview.types'

export const PremiumUserBanerPreview: FC<PremiumUserBannerPreviewProps> = ({
  className,
  title,
  image,
  bannerMobileImage,
  buttonText,
  mainHeading,
  mainDescription,
  secondaryHeading,
  secondaryDescription,
  button,
  buttonPreset,
  buttonTextPreset,
  active,
  isButtonActive,
  onClose,
  onOpenPreviewDesktopBanner,
  onOpenPreviewMobileBanner
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ theme })

  const isButton = isButtonActive && button && buttonText

  return (
    <Column className={classes.container}>
      <ModalBannerPreviewHeader
        active={active}
        onClose={onClose}
        onOpenPreviewDesktopBanner={onOpenPreviewDesktopBanner}
        onOpenPreviewMobileBanner={onOpenPreviewMobileBanner}
      />
      {active ? (
        <Column className={classes.mobileContainer}>
          {bannerMobileImage && (
            <img className={classes.image} src={bannerMobileImage}></img>
          )}
          <Column
            fullWidth
            className={`${classes.mobileModal} ${classes.mobileModalView}`}
            justifyContent="center"
            alignItems="flex-start"
          >
            {title && <Text text={title} preset="h4" color="blue" />}
            {mainHeading && (
              <Text
                className={classes.text}
                text={mainHeading}
                preset="secondBanner"
              />
            )}
            {mainDescription && (
              <Text
                className={classes.text}
                text={mainDescription}
                preset="subscriptionText"
                color="inactive"
              />
            )}
            {secondaryHeading && (
              <Text
                className={classes.secondaryHeadingText}
                text={secondaryHeading}
                preset="h5"
              />
            )}
            {secondaryDescription && (
              <Text
                className={classes.text}
                text={secondaryDescription}
                preset="h6"
                color="inactive"
              />
            )}
            {isButton && (
              <Button
                className={classes.button}
                preset={buttonPreset}
                text={buttonText}
                textPreset={buttonTextPreset}
                textColor="white"
              />
            )}
          </Column>
        </Column>
      ) : (
        <Column className={classes.premiumBannerContainer}>
          {image && <img className={classes.image} src={image}></img>}
          <Column
            className={`${className} ${classes.modal} ${classes.modalMaxWidth}`}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {title && <Text text={title} preset="h4" color="blue" />}
            {mainHeading && (
              <Text
                className={classes.text}
                text={mainHeading}
                preset="secondBanner"
              />
            )}
            {mainDescription && (
              <Text
                className={classes.text}
                text={mainDescription}
                preset="subscriptionText"
                color="inactive"
              />
            )}
            {secondaryHeading && (
              <Text
                className={classes.secondaryHeadingText}
                text={secondaryHeading}
                preset="h5"
              />
            )}
            {secondaryDescription && (
              <Text
                className={classes.text}
                text={secondaryDescription}
                preset="h6"
                color="inactive"
              />
            )}
            {isButton && (
              <Button
                className={classes.button}
                preset={buttonPreset}
                text={buttonText}
                textPreset={buttonTextPreset}
                textColor="white"
              />
            )}
          </Column>
        </Column>
      )}
    </Column>
  )
}
