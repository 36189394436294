import React, { ChangeEvent, FC, useState } from 'react'

import {
  Icon,
  ICONS,
  QuestionModal,
  Row,
  Text,
  TextField
} from '../../../components'
import { getAvaliableToPayByPaymentStatus } from '../helpers'

import { useStyle } from './cancelation-order-modal.styles'
import { CancelOrderModalProps } from './cancelation-order-modal.types'

export const CancelOrderModal: FC<CancelOrderModalProps> = ({
  open,
  amount,
  paymentStatus,
  onSubmit,
  onClose
}) => {
  const defaultValue = '0'
  const [value, changeValue] = useState(defaultValue)
  const classes = useStyle()

  const isAvailableToPay = getAvaliableToPayByPaymentStatus(paymentStatus)

  if (!open) {
    return <></>
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: nextValue } = event.target
    const percentage = Number(nextValue)

    if (percentage < 0) {
      return changeValue(defaultValue)
    }

    if (percentage <= 100) {
      return changeValue(nextValue)
    }
  }

  const handleOnSuccess = () => {
    if (onSubmit) {
      onSubmit(value)
    }
  }

  return (
    <QuestionModal
      className={classes.container}
      title="Cancellation Confirmation"
      titleTx="cancelationOrder.modal.title"
      successText="Confirm"
      successTx="declineOrder.modal.success"
      onClose={onClose}
      onSubmit={handleOnSuccess}
    >
      <Text
        className={classes.title}
        color="darkBlue"
        preset="h4"
        text="Set up the Refund percentage amount"
        tx="cancelationOrder.modal.secondaryTitle"
      />
      <Row className={classes.description}>
        <Text
          color="inactive"
          preset="title"
          text="Paid price amount:"
          tx="declineOrder.modal.description"
        />
        <Text
          className={classes.refundTitle}
          color="darkBlue"
          preset="h5"
          text={amount}
        />
      </Row>
      <TextField
        className={classes.input}
        disabled={isAvailableToPay}
        label="Refund Percentage"
        labelTx="cancelationOrder.modal.refund"
        type="number"
        value={value}
        rightElement={<Icon src={ICONS.percentage} />}
        onChange={handleOnChange}
      />
    </QuestionModal>
  )
}
