import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  modal: {
    maxHeight: 680,
    overflow: 'auto'
  },
  bottomContainer: {
    width: 'calc(100%-32px)',
    padding: '40px 16px 16px '
  },
  description: {
    textAlign: 'center'
  },
  secondaryDesctiption: {
    marginTop: 16,
    textAlign: 'center'
  },
  valueTitle: {
    marginTop: 40,
    textAlign: 'center'
  },
  field: {
    marginTop: 40,
    zIndex: 5
  },
  buttonsContainer: {}
})
