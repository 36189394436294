import React from 'react'

import { Content, Inbox, Loader } from '../../components'
import { useChat } from '../../providers'

import { useStyle } from './inbox.styles'

export const InboxPage = () => {
  const { session, ready } = useChat()

  const classes = useStyle()

  if (!ready || !session) {
    return (
      <Content className={classes.container} justifyContent="flex-start">
        <Loader />
      </Content>
    )
  }

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Inbox />
    </Content>
  )
}
