export type ObjectWithDefault = {
  isDefault?: boolean
}

export const getFindByDefaultWithProp = <T extends ObjectWithDefault>(
  data: T[]
) => {
  return data.find((item) => item.isDefault === true)
}

export const getFilterByDefaultWithProp = <T extends ObjectWithDefault>(
  data: T[]
) => {
  return data.filter((item) => item.isDefault === true)
}
