import { ApolloError } from '@apollo/client'
import { IntlShape } from 'react-intl'

import { IMessageContext } from '../components/messages'
import { UseNotifierResult } from '../hooks/useNotifier'
import { commonMessages } from '../intl'
import { ChatLocalStorageKey } from '../providers'
import { TokenStorageKey } from '../services'

import { isJwtError, isTokenExpired } from './errors'

export const getTokens = () => ({
  auth:
    localStorage.getItem(TokenStorageKey.AUTH) ||
    sessionStorage.getItem(TokenStorageKey.AUTH),
  refresh:
    localStorage.getItem(TokenStorageKey.REFRESH) ||
    sessionStorage.getItem(TokenStorageKey.REFRESH),
  csrf:
    localStorage.getItem(TokenStorageKey.CSRF) ||
    sessionStorage.getItem(TokenStorageKey.CSRF)
})

export const setTokens = (
  auth: string,
  csrf: string,
  refresh: string,
  persist: boolean
) => {
  if (persist) {
    localStorage.setItem(TokenStorageKey.AUTH, auth)
    localStorage.setItem(TokenStorageKey.REFRESH, refresh)
    localStorage.setItem(TokenStorageKey.CSRF, csrf)
  } else {
    sessionStorage.setItem(TokenStorageKey.AUTH, auth)
    localStorage.setItem(TokenStorageKey.REFRESH, refresh)
    sessionStorage.setItem(TokenStorageKey.CSRF, csrf)
  }
}

export const setAuthToken = (auth: string, persist: boolean) => {
  if (persist) {
    localStorage.setItem(TokenStorageKey.AUTH, auth)
  } else {
    sessionStorage.setItem(TokenStorageKey.AUTH, auth)
  }
}

export const setRefreshToken = (refresh: string, persist: boolean) => {
  if (persist) {
    localStorage.setItem(TokenStorageKey.REFRESH, refresh)
  } else {
    sessionStorage.setItem(TokenStorageKey.REFRESH, refresh)
  }
}

export const removeTokens = () => {
  localStorage.removeItem(TokenStorageKey.AUTH)
  localStorage.removeItem(TokenStorageKey.REFRESH)
  localStorage.removeItem(TokenStorageKey.CSRF)
  localStorage.removeItem(ChatLocalStorageKey.CONVERSATION)
  sessionStorage.removeItem(TokenStorageKey.AUTH)
}

export const displayDemoMessage = (
  intl: IntlShape,
  notify: UseNotifierResult
) => {
  notify({
    text: intl.formatMessage(commonMessages.demo)
  })
}

export async function handleQueryAuthError(
  error: ApolloError,
  notify: IMessageContext,
  intl: IntlShape,
  tokenRefresh?: () => Promise<boolean>,
  logout?: () => void
) {
  if (error.graphQLErrors.some(isJwtError)) {
    if (error.graphQLErrors.every(isTokenExpired)) {
      if (tokenRefresh) {
        const success = await tokenRefresh()

        if (!success) {
          if (logout) {
            logout()
          }
          notify({
            status: 'error',
            text: intl.formatMessage(commonMessages.sessionExpired)
          })
        }
      } else {
        if (logout) {
          logout()
        }
        notify({
          status: 'error',
          text: intl.formatMessage(commonMessages.somethingWentWrong)
        })
      }
    }
  } else if (
    !error.graphQLErrors.every(
      (err) => err.extensions?.exception?.code === 'PermissionDenied'
    )
  ) {
    notify({
      status: 'error',
      text: intl.formatMessage(commonMessages.somethingWentWrong)
    })
  }
}

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = (email: string) => {
  const testEmail = REGEX_EMAIL.test(email)

  return testEmail
}

export const REGEX_PHONE = /^\+(?=(?:\s?\d){7,17}$)\d+(?:\s?\d+){0,3}$/

export const validatePhone = (phone: string) => {
  const testPhone = REGEX_PHONE.test(phone)

  return testPhone
}
