import React from 'react'
import useRouter from 'use-react-router'

import appStateReducer from './reducer'
import { initialAppState } from './state'

import { AppStateContextType } from './app-state.types'

export const AppStateContext = React.createContext<AppStateContextType>([
  initialAppState,
  () => undefined
])
const AppStateProvider: React.FC = ({ children }) => {
  const { location } = useRouter()
  const stateAndDispatch = React.useReducer(appStateReducer, initialAppState)
  const [state, dispatch] = stateAndDispatch

  React.useEffect(() => {
    if (state.error) {
      dispatch({
        payload: {
          error: undefined
        },
        type: 'displayError'
      })
    }
  }, [location])

  return (
    <AppStateContext.Provider value={stateAndDispatch}>
      {children}
    </AppStateContext.Provider>
  )
}

export const { Consumer } = AppStateContext

export default AppStateProvider
