import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import {
  BackBreadcrumbs,
  Column,
  DatePicker,
  Icon,
  ICONS,
  LoadPhotoContainer,
  ModalBanerPreview,
  PageForm,
  RadioBox,
  Row,
  Section,
  Text,
  TextField
} from '../../components'
import { PromotionBannerCreateInput, PromotionBanner } from '../../services'

import { useStyle } from './promotion-banner-form.styles'
import { PromotionBannerFormProps } from './promotion-banner-form.types'
import { defaultValue, validateUrl, visibilityRadioData } from './helpers'

export const PromotionBannerForm: FC<PromotionBannerFormProps> = ({
  banner,
  title,
  titleTx,
  onSumbit
}) => {
  // TODO: temporary hidden according to ovm-1771
  // const { menu } = useApi()
  // const maxCount = 10
  // const { data } = menu.usePromotionBanners({ first: 1 })

  const history = useHistory()
  const classes = useStyle()
  const defaultImagesCount = banner?.image ? 1 : 0
  const defaultBanner = banner
    ? {
        ...banner,
        id: undefined,
        image: undefined,
        mobileImage: undefined,
        days: undefined
      }
    : undefined

  const [bannerValue, changeBannerValue] = useState<PromotionBannerCreateInput>(
    defaultBanner || defaultValue
  )

  const [prevBannerState, changePrevBannerState] =
    useState<PromotionBannerCreateInput>(bannerValue)

  const [imageCount, changeImageCount] = useState(defaultImagesCount)
  const [mobileImageCount, changeMobileImageCount] =
    useState(defaultImagesCount)

  const [openBannerPreview, changeOpenBannerPreview] = useState(false)

  const [openMobileBanner, changeOpenMobileBanner] = useState(false)

  const handleOnChangeBannerPreview = (state: boolean) => () => {
    changeOpenMobileBanner(state)
  }

  const handleOnMobilePreviewClick = () => {
    const changeView = handleOnChangeBannerPreview(true)

    changeView()
  }

  const handleOnDesktopPreviewClick = () => {
    const changeView = handleOnChangeBannerPreview(false)

    changeView()
  }

  const isButtonLink = () => {
    if (bannerValue.buttonLink) {
      const valueUrl = validateUrl(bannerValue.buttonLink)

      return valueUrl
    }

    return undefined
  }

  const isDisabled =
    !imageCount ||
    !mobileImageCount ||
    !bannerValue.title ||
    !bannerValue.name ||
    !bannerValue.startDate ||
    !bannerValue.endDate ||
    !bannerValue.buttonText ||
    !isButtonLink()

  const BannerImage = useMemo(() => {
    if (bannerValue.image) {
      return URL.createObjectURL(bannerValue.image)
    }

    return banner?.image
  }, [bannerValue.image])

  const BannerMobileImage = useMemo(() => {
    if (bannerValue.mobileImage) {
      return URL.createObjectURL(bannerValue.mobileImage)
    }

    return banner?.mobileImage
  }, [bannerValue.mobileImage])

  const handleOnOpenBannerPreviev = (state: boolean) => () => {
    changeOpenBannerPreview(state)
  }

  const handleOnBannerPreviewClick = () => {
    const openModal = handleOnOpenBannerPreviev(true)

    openModal()
  }

  const handleOnChangeVisibility = (nextValue: string) => {
    const isVisible = Boolean(Number(nextValue))
    changeBannerValue({
      ...bannerValue,
      isVisible
    })
  }

  const handleOnChangeDate = (prop: keyof PromotionBanner) => (date: Date) => {
    const nextBanner = {
      ...bannerValue,
      [prop]: moment(date).format('YYYY-MM-DD')
    }

    changeBannerValue(nextBanner)
  }

  const handleOnChangeInput =
    (prop: keyof PromotionBannerCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      const nextBanner: PromotionBannerCreateInput = {
        ...bannerValue,
        [prop]: value
      }

      const nextPrevBanner: PromotionBannerCreateInput = {
        ...prevBannerState,
        [prop]: value
      }

      changePrevBannerState(nextPrevBanner)

      if (changeBannerValue) {
        changeBannerValue(nextBanner)
      }
    }

  const handleOnChangeImage = (images: File[], urls?: string[]) => {
    changeBannerValue({
      ...bannerValue,
      image: images.length > 0 ? images[0] : undefined
    })
    const prevImagesCount = urls?.length || 0

    changeImageCount(images.length + prevImagesCount)
  }

  const handleOnChangeMobileImage = (mobileImages: File[], urls?: string[]) => {
    changeBannerValue({
      ...bannerValue,
      mobileImage: mobileImages.length > 0 ? mobileImages[0] : undefined
    })
    const prevImagesCount = urls?.length || 0

    changeMobileImageCount(mobileImages.length + prevImagesCount)
  }

  const handleOnSubmit = () => {
    onSumbit(bannerValue)
  }

  // TODO: temporary hidden according to ovm-1771
  // const countsValues = {
  //   count: 5,
  //   maxCount
  // }
  // const maxCountsValues = {
  //   maxCount
  // }

  const visibleId = String(Number(bannerValue.isVisible))

  const loadPhotoDefaultValue = useMemo(
    () => (banner?.image ? [banner.image] : []),
    [banner?.image]
  )
  const loadBannerMobileImageDefaultValue = useMemo(
    () => (banner?.mobileImage ? [banner.mobileImage] : []),
    [banner?.mobileImage]
  )

  const startDate = bannerValue.startDate
    ? new Date(bannerValue.startDate)
    : undefined
  const endDate = bannerValue.endDate
    ? new Date(bannerValue.endDate)
    : startDate

  const favorite = (
    <Row className={classes.favorite}>
      <Icon src={ICONS.removeRedEye} onClick={handleOnBannerPreviewClick} />
    </Row>
  )

  return (
    <>
      <PageForm
        pannelLeftContent={favorite}
        disabled={isDisabled}
        onBack={history.goBack}
        onSave={handleOnSubmit}
      >
        <Column
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.container}
        >
          <BackBreadcrumbs text="All banners" tx="banner.breadcrumbs.back" />
          <Text
            className={classes.title}
            preset="h3"
            text={title}
            tx={titleTx}
          />
          <Row fullWidth alignItems="flex-start" className={classes.content}>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.leftColumn}
            >
              <Section
                className={classes.section}
                title="General Information"
                titleTx="promotion.banner.text.fields.generalInformation"
              >
                <Column
                  alignItems="flex-start"
                  className={classes.sectionAdditionContainer}
                >
                  <TextField
                    className={classes.field}
                    defaultValue={prevBannerState.title}
                    preset="border"
                    maxLength={25}
                    label="Title"
                    labelTx="promotion.banner.title"
                    onChange={handleOnChangeInput('title')}
                  />
                  <TextField
                    className={classes.field}
                    defaultValue={prevBannerState.subtitle}
                    preset="border"
                    maxLength={50}
                    label="Subtitle"
                    labelTx="promotion.banner.subtitle"
                    onChange={handleOnChangeInput('subtitle')}
                  />
                </Column>
              </Section>
              <LoadPhotoContainer
                className={classes.section}
                defaultValue={loadPhotoDefaultValue}
                title="Desktop Banner Image"
                titleTx="banner.image"
                onChange={handleOnChangeImage}
              />
              <LoadPhotoContainer
                className={classes.section}
                defaultValue={loadBannerMobileImageDefaultValue}
                title="Mobile Banner Image"
                titleTx="banner.mobile.image"
                onChange={handleOnChangeMobileImage}
              />
              <Section
                className={classes.section}
                title="Button"
                titleTx="banner.button.title"
              >
                <Column
                  alignItems="flex-start"
                  className={classes.sectionAdditionContainer}
                >
                  <TextField
                    className={classes.field}
                    defaultValue={prevBannerState.buttonText}
                    preset="border"
                    label="Button Text"
                    labelTx="banner.button.text"
                    onChange={handleOnChangeInput('buttonText')}
                  />
                  <TextField
                    className={classes.field}
                    defaultValue={prevBannerState.buttonLink}
                    preset="border"
                    label="Link for Button"
                    maxLength={200}
                    labelTx="banner.button.link"
                    onChange={handleOnChangeInput('buttonLink')}
                  />
                </Column>
              </Section>
              <Section
                className={classes.section}
                title="Duration"
                titleTx="banner.duration.title"
              >
                <Row
                  alignItems="flex-start"
                  className={classes.sectionAdditionContainer}
                >
                  <DatePicker
                    defaultValue={startDate}
                    label="Start Date"
                    labelTx="banner.start.date"
                    onChange={handleOnChangeDate('startDate')}
                  />
                  <DatePicker
                    defaultValue={endDate}
                    className={classes.end}
                    label="End Date"
                    labelTx="banner.end.date"
                    onChange={handleOnChangeDate('endDate')}
                  />
                </Row>
              </Section>
            </Column>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.rightColumn}
            >
              <Section
                className={classes.section}
                title="Organize Banner"
                titleTx="promotion.banner.text.fields.organizeBanner"
              >
                <Column
                  alignItems="flex-start"
                  className={classes.sectionAdditionContainer}
                >
                  <TextField
                    className={classes.field}
                    defaultValue={prevBannerState.name}
                    preset="border"
                    maxLength={15}
                    label="Name"
                    labelTx="promotion.banner.name"
                    onChange={handleOnChangeInput('name')}
                  />
                </Column>
              </Section>
              <Section title="Visibility" titleTx="banner.visibility.title">
                <RadioBox
                  active={visibleId}
                  className={classes.sectionAdditionContainer}
                  data={visibilityRadioData}
                  onChange={handleOnChangeVisibility}
                />
                {/* TODO: temporary hidden according to ovm-1771 */}
                {/* <Column
                  className={classes.visibleTitleContainer}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Text
                    className={classes.visibleTitle}
                    color="black"
                    preset="h5"
                    text={`Now active
                    ${data?.promotionBanners.activeBannersCount}/${maxCount} Banners!`}
                    tx="promotion.banner.active.counts"
                    values={countsValues}
                  />
                  <Text
                    className={classes.visibleDescription}
                    color="inactive"
                    preset="title"
                    text={`There can be up ${maxCount} Banners at the same time.`}
                    tx="promotion.banner.active.max.count"
                    values={maxCountsValues}
                  />
                </Column> */}
              </Section>
            </Column>
          </Row>
        </Column>
      </PageForm>
      <ModalBanerPreview
        image={BannerImage}
        bannerMobileImage={BannerMobileImage}
        title={bannerValue?.title}
        mainHeading={bannerValue?.subtitle}
        buttonText={bannerValue.buttonText}
        open={openBannerPreview}
        active={openMobileBanner}
        onOpenPreviewDesktopBanner={handleOnDesktopPreviewClick}
        onOpenPreviewMobileBanner={handleOnMobilePreviewClick}
        onClose={handleOnOpenBannerPreviev(false)}
      />
    </>
  )
}
