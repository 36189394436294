import { OrderMainStatus, OrderStatus } from '../../../services'

export const statusToIndex = (status: OrderStatus): number => {
  switch (status) {
    case OrderStatus.DRAFT:
      return 1
    case OrderStatus.PARTIALLY_FULFILLED:
      return 2
    case OrderStatus.FULFILLED:
      return 3
    case OrderStatus.SHIPPED:
      return 4
    case OrderStatus.DELIVERIED:
      return 5
    default:
      return 0
  }
}

export const mainStatusToIndex = (status: OrderMainStatus): number => {
  switch (status) {
    case OrderMainStatus.CONFIRMED:
      return 1
    case OrderMainStatus.PRODUCTION_STARTED:
      return 2
    case OrderMainStatus.PRODUCTION_FINISHED:
      return 3
    case OrderMainStatus.PRODUCT_SHIPPED:
      return 4
    case OrderMainStatus.PARCEL_DELIVERED:
      return 5
    case OrderMainStatus.PAID:
      return 0
    case OrderMainStatus.NOT_CONFIRMED:
    case OrderMainStatus.CANCELED_BY_CUSTOMER:
    case OrderMainStatus.CANCELED_BY_VENDOR:
      return -1
    default:
      return -1
  }
}
