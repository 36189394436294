import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { LightTheme } from '../../../components/Theme'
import { Column, getPriceToFixed, Row, Text } from '../../../components'

import { TopItem } from '../top-item'

import { useStyle } from './top-items-table.styles'
import { TopItemsTableProps } from './top-items-table.types'

export const TopItemsTable: FC<TopItemsTableProps> = ({ topItems }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Row fullWidth>
      <Column className={classes.topItems} alignItems="flex-start" fullWidth>
        <Row
          fullWidth
          justifyContent="flex-start"
          className={classes.topItemsTitle}
        >
          <Text color="black" preset="h2" text="Top Items" />
        </Row>
        {topItems &&
          topItems.edges.map(
            ({
              node: {
                category,
                id,
                name,
                defaultPrice,
                ordersAmount: topOrdersAmount,
                thumbnail
              }
            }) => {
              const categoryParent = category.parent
              const subtitle = categoryParent.parent.name || categoryParent.name

              return (
                <TopItem
                  key={`top_product_${id}`}
                  title={name}
                  subtitle={subtitle}
                  price={`${getPriceToFixed(defaultPrice)}`}
                  orderedCount={topOrdersAmount}
                  image={thumbnail?.url}
                />
              )
            }
          )}
      </Column>
    </Row>
  )
}
