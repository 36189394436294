import { createUseStyles } from 'react-jss'

import { DropdownItemsStyleProps } from '.'

export const useStyle = createUseStyles({
  container: {
    cursor: ({ disabled }: DropdownItemsStyleProps) =>
      disabled ? 'default' : 'pointer'
  },
  listContainer: {
    position: 'absolute',
    top: 56,
    width: '100%',
    zIndex: 2,
    borderRadius: 4,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
})
