import React, { useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import {
  BackBreadcrumbs,
  ButtonProps,
  Column,
  Content,
  getCurrencyByProduct,
  Loader,
  Row,
  Text
} from '../../components'
import { useApi, useChat } from '../../providers'
import { OfferStatus } from '../../services'
import { getColorByOfferStatus, getMessageByOfferStatus } from '../../utils'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'
import { ChatOverview } from '../views'

import { useStyle } from './offer-chat.styles'
import { OfferChatParams } from './offer-chat.types'

export const OfferChat = () => {
  const history = useHistory()
  const { offerId } = useParams<OfferChatParams>()
  const chatboxRef = useRef<HTMLDivElement>(null)
  const { onMountChatboxConversation } = useChat()
  const { offer: offerApi } = useApi()
  const { data, refetch } = offerApi.useOfferById({ id: offerId })

  const classes = useStyle()

  useEffect(() => {
    if (refetch) {
      refetch({ id: offerId })
    }
  }, [offerId])

  useEffect(() => {
    if (data?.offer.conversationId && onMountChatboxConversation) {
      onMountChatboxConversation(chatboxRef, data?.offer.conversationId)
    }
  }, [data?.offer.conversationId])

  const handleGoBack = () => {
    history.push(CabinetNavigatorPathVendor.OFFERS)
  }

  const handleOnToOffer: ButtonProps['onClick'] = () => {
    if (data) {
      history.push(`${CabinetNavigatorPathVendor.OFFER_EDIT}/${data.offer.id}`)
    }
  }

  if (!data?.offer) {
    return (
      <Content justifyContent="flex-start">
        <BackBreadcrumbs
          text="Back to Offers"
          tx="order.preview.back.to.offers"
          onClick={handleGoBack}
        />
        <Text preset="h1" text="Offer chat" />
        <Loader />
      </Content>
    )
  }

  const { offer } = data
  const statusTextProps = getMessageByOfferStatus(offer.status)
  const { product, status } = offer

  const isDisabled =
    status !== OfferStatus.NEW && status !== OfferStatus.ACCEPTED
  const currency = getCurrencyByProduct(product)

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <BackBreadcrumbs
        className={classes.back}
        text="Back to Offers"
        tx="order.preview.back.to.offers"
        onClick={handleGoBack}
      />

      <Row fullWidth className={classes.content}>
        <Column className={classes.offerView}>
          <ChatOverview
            title={`# ${offer.number}`}
            titleTx={'offer.number'}
            titleValues={{ number: offer.number }}
            offer={offer}
            statusColor={getColorByOfferStatus(offer.status)}
            statusText={statusTextProps.text}
            statusTx={statusTextProps.tx}
            currencySymbol={currency}
            disabledObserve={isDisabled}
            onToOffer={handleOnToOffer}
          />
        </Column>
        <Column
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.chatboxContainer}
        >
          <Column
            fullWidth
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.chatbox}
            ref={chatboxRef}
          />
        </Column>
      </Row>
    </Content>
  )
}
