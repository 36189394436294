/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

import { AddressInput, PermissionEnum, UserSortField } from '../services'
import { DateRangeInput, SortBy } from './api.types'
import { ProductCreateAttributeInput } from './product'
import { CountryCode } from './country'
import { DiscountValueTypeEnum, VoucherTypeEnum } from './order'
import {
  AttributeInput,
  PriceRangeInput,
  ProductFilter,
  ProductStockFilterInput,
  ShippingMethodTypeEnum,
  StockAvailability,
  WeightUnitsEnum
} from './product'

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AddressTypeEnum {
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING'
}

export enum AppErrorCode {
  FORBIDDEN = 'FORBIDDEN',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  INVALID_MANIFEST_FORMAT = 'INVALID_MANIFEST_FORMAT',
  INVALID_PERMISSION = 'INVALID_PERMISSION',
  INVALID_STATUS = 'INVALID_STATUS',
  INVALID_URL_FORMAT = 'INVALID_URL_FORMAT',
  MANIFEST_URL_CANT_CONNECT = 'MANIFEST_URL_CANT_CONNECT',
  NOT_FOUND = 'NOT_FOUND',
  OUT_OF_SCOPE_APP = 'OUT_OF_SCOPE_APP',
  OUT_OF_SCOPE_PERMISSION = 'OUT_OF_SCOPE_PERMISSION',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum AppSortField {
  CREATION_DATE = 'CREATION_DATE',
  NAME = 'NAME'
}

export enum AppTypeEnum {
  LOCAL = 'LOCAL',
  THIRDPARTY = 'THIRDPARTY'
}

export enum AttributeInputTypeEnum {
  DROPDOWN = 'DROPDOWN',
  MULTISELECT = 'MULTISELECT'
}

export enum AttributeTypeEnum {
  PRODUCT = 'PRODUCT',
  VARIANT = 'VARIANT'
}

export enum AttributeValueType {
  COLOR = 'COLOR',
  GRADIENT = 'GRADIENT',
  STRING = 'STRING',
  URL = 'URL'
}

export enum AuthorizationKeyType {
  FACEBOOK = 'FACEBOOK',
  GOOGLE_OAUTH2 = 'GOOGLE_OAUTH2'
}

export enum CategorySortField {
  NAME = 'NAME',
  PRODUCT_COUNT = 'PRODUCT_COUNT',
  SUBCATEGORY_COUNT = 'SUBCATEGORY_COUNT'
}

export enum CollectionPublished {
  HIDDEN = 'HIDDEN',
  PUBLISHED = 'PUBLISHED'
}

export enum CollectionSortField {
  AVAILABILITY = 'AVAILABILITY',
  NAME = 'NAME',
  PRODUCT_COUNT = 'PRODUCT_COUNT'
}

export enum ConfigurationTypeFieldEnum {
  BOOLEAN = 'BOOLEAN',
  PASSWORD = 'PASSWORD',
  SECRET = 'SECRET',
  STRING = 'STRING'
}

export enum DiscountErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum DiscountStatusEnum {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  SCHEDULED = 'SCHEDULED'
}

export enum ExportErrorCode {
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED'
}

export enum ExportScope {
  ALL = 'ALL',
  FILTER = 'FILTER',
  IDS = 'IDS'
}

export enum FileTypesEnum {
  CSV = 'CSV',
  XLSX = 'XLSX'
}

export enum InvoiceErrorCode {
  EMAIL_NOT_SET = 'EMAIL_NOT_SET',
  INVALID_STATUS = 'INVALID_STATUS',
  NOT_FOUND = 'NOT_FOUND',
  NOT_READY = 'NOT_READY',
  NUMBER_NOT_SET = 'NUMBER_NOT_SET',
  REQUIRED = 'REQUIRED',
  URL_NOT_SET = 'URL_NOT_SET'
}

export enum LanguageCodeEnum {
  AR = 'AR',
  AZ = 'AZ',
  BG = 'BG',
  BN = 'BN',
  CA = 'CA',
  CS = 'CS',
  DA = 'DA',
  DE = 'DE',
  EL = 'EL',
  EN = 'EN',
  ES = 'ES',
  ES_CO = 'ES_CO',
  ET = 'ET',
  FA = 'FA',
  FI = 'FI',
  FR = 'FR',
  HI = 'HI',
  HU = 'HU',
  HY = 'HY',
  ID = 'ID',
  IS = 'IS',
  IT = 'IT',
  JA = 'JA',
  KM = 'KM',
  KO = 'KO',
  LT = 'LT',
  MN = 'MN',
  MY = 'MY',
  NB = 'NB',
  NL = 'NL',
  PL = 'PL',
  PT = 'PT',
  PT_BR = 'PT_BR',
  RO = 'RO',
  RU = 'RU',
  SK = 'SK',
  SL = 'SL',
  SQ = 'SQ',
  SR = 'SR',
  SV = 'SV',
  SW = 'SW',
  TA = 'TA',
  TH = 'TH',
  TR = 'TR',
  UK = 'UK',
  VI = 'VI',
  ZH_HANS = 'ZH_HANS',
  ZH_HANT = 'ZH_HANT'
}

export enum MenuErrorCode {
  CANNOT_ASSIGN_NODE = 'CANNOT_ASSIGN_NODE',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  INVALID_MENU_ITEM = 'INVALID_MENU_ITEM',
  NOT_FOUND = 'NOT_FOUND',
  NO_MENU_ITEM_PROVIDED = 'NO_MENU_ITEM_PROVIDED',
  REQUIRED = 'REQUIRED',
  TOO_MANY_MENU_ITEMS = 'TOO_MANY_MENU_ITEMS',
  UNIQUE = 'UNIQUE'
}

export enum MenuSortField {
  ITEMS_COUNT = 'ITEMS_COUNT',
  NAME = 'NAME'
}

export enum MetadataErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND'
}

export enum OrderStatusFilter {
  CANCELED = 'CANCELED',
  FULFILLED = 'FULFILLED',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  READY_TO_CAPTURE = 'READY_TO_CAPTURE',
  READY_TO_FULFILL = 'READY_TO_FULFILL',
  UNFULFILLED = 'UNFULFILLED'
}

export enum PageErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum PageSortField {
  CREATION_DATE = 'CREATION_DATE',
  PUBLICATION_DATE = 'PUBLICATION_DATE',
  SLUG = 'SLUG',
  TITLE = 'TITLE',
  VISIBILITY = 'VISIBILITY'
}

export enum PermissionGroupErrorCode {
  ASSIGN_NON_STAFF_MEMBER = 'ASSIGN_NON_STAFF_MEMBER',
  CANNOT_REMOVE_FROM_LAST_GROUP = 'CANNOT_REMOVE_FROM_LAST_GROUP',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  LEFT_NOT_MANAGEABLE_PERMISSION = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  OUT_OF_SCOPE_PERMISSION = 'OUT_OF_SCOPE_PERMISSION',
  OUT_OF_SCOPE_USER = 'OUT_OF_SCOPE_USER',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum PermissionGroupSortField {
  NAME = 'NAME'
}

export enum PluginErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  PLUGIN_MISCONFIGURED = 'PLUGIN_MISCONFIGURED',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum PluginSortField {
  IS_ACTIVE = 'IS_ACTIVE',
  NAME = 'NAME'
}

export enum ProductFieldEnum {
  AVAILABLE_FOR_PURCHASE = 'AVAILABLE_FOR_PURCHASE',
  CATEGORY = 'CATEGORY',
  CHARGE_TAXES = 'CHARGE_TAXES',
  COLLECTIONS = 'COLLECTIONS',
  COST_PRICE = 'COST_PRICE',
  DESCRIPTION = 'DESCRIPTION',
  NAME = 'NAME',
  PRODUCT_IMAGES = 'PRODUCT_IMAGES',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  PRODUCT_WEIGHT = 'PRODUCT_WEIGHT',
  SEARCHABLE = 'SEARCHABLE',
  VARIANT_IMAGES = 'VARIANT_IMAGES',
  VARIANT_PRICE = 'VARIANT_PRICE',
  VARIANT_SKU = 'VARIANT_SKU',
  VARIANT_WEIGHT = 'VARIANT_WEIGHT',
  VISIBLE = 'VISIBLE'
}

export enum ProductOrderField {
  DATE = 'DATE',
  MINIMAL_PRICE = 'MINIMAL_PRICE',
  NAME = 'NAME',
  PRICE = 'PRICE',
  PUBLISHED = 'PUBLISHED',
  TYPE = 'TYPE'
}

export enum ProductTypeConfigurable {
  CONFIGURABLE = 'CONFIGURABLE',
  SIMPLE = 'SIMPLE'
}

export enum ProductTypeEnum {
  DIGITAL = 'DIGITAL',
  SHIPPABLE = 'SHIPPABLE'
}

// export enum ProductTypeSortField {
//   DIGITAL = 'DIGITAL',
//   NAME = 'NAME',
//   SHIPPING_REQUIRED = 'SHIPPING_REQUIRED'
// }

export enum SaleSortField {
  END_DATE = 'END_DATE',
  NAME = 'NAME',
  START_DATE = 'START_DATE',
  TYPE = 'TYPE',
  VALUE = 'VALUE'
}

export enum SaleType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE'
}

export enum ShippingErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  MAX_LESS_THAN_MIN = 'MAX_LESS_THAN_MIN',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum ShopErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  CANNOT_FETCH_TAX_RATES = 'CANNOT_FETCH_TAX_RATES',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum StaffMemberStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED'
}

export enum StockErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum VoucherDiscountType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
  SHIPPING = 'SHIPPING'
}

export enum VoucherSortField {
  CODE = 'CODE',
  END_DATE = 'END_DATE',
  MINIMUM_SPENT_AMOUNT = 'MINIMUM_SPENT_AMOUNT',
  START_DATE = 'START_DATE',
  TYPE = 'TYPE',
  USAGE_LIMIT = 'USAGE_LIMIT',
  VALUE = 'VALUE'
}

export enum WarehouseErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum WarehouseSortField {
  NAME = 'NAME'
}

export enum WebhookErrorCode {
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum WebhookEventTypeEnum {
  ANY_EVENTS = 'ANY_EVENTS',
  CHECKOUT_CREATED = 'CHECKOUT_CREATED',
  CHECKOUT_QUANTITY_CHANGED = 'CHECKOUT_QUANTITY_CHANGED',
  CHECKOUT_UPDATED = 'CHECKOUT_UPDATED',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  FULFILLMENT_CREATED = 'FULFILLMENT_CREATED',
  INVOICE_DELETED = 'INVOICE_DELETED',
  INVOICE_REQUESTED = 'INVOICE_REQUESTED',
  INVOICE_SENT = 'INVOICE_SENT',
  ORDER_CANCELLED = 'ORDER_CANCELLED',
  ORDER_CREATED = 'ORDER_CREATED',
  ORDER_FULFILLED = 'ORDER_FULFILLED',
  ORDER_FULLY_PAID = 'ORDER_FULLY_PAID',
  ORDER_UPDATED = 'ORDER_UPDATED',
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_UPDATED = 'PRODUCT_UPDATED'
}

export enum WebhookSortField {
  APP = 'APP',
  NAME = 'NAME',
  SERVICE_ACCOUNT = 'SERVICE_ACCOUNT',
  TARGET_URL = 'TARGET_URL'
}

export interface AppFilterInput {
  search?: string | null
  isActive?: boolean | null
  type?: AppTypeEnum | null
}

export interface AppInput {
  name?: string | null
  isActive?: boolean | null
  permissions?: (PermissionEnum | null)[] | null
}

export interface AppInstallInput {
  appName?: string | null
  manifestUrl?: string | null
  activateAfterInstallation?: boolean | null
  permissions?: (PermissionEnum | null)[] | null
}

export type AppSortingInput = SortBy<AppSortField>

export interface AppTokenInput {
  name?: string | null
  app: string
}

export interface AttributeAssignInput {
  id: string
  type: AttributeTypeEnum
}

export interface AttributeCreateInput {
  inputType?: AttributeInputTypeEnum | null
  name: string
  slug?: string | null
  values?: (AttributeValueCreateInput | null)[] | null
  valueRequired?: boolean | null
  isVariantOnly?: boolean | null
  visibleInStorefront?: boolean | null
  filterableInStorefront?: boolean | null
  filterableInDashboard?: boolean | null
  storefrontSearchPosition?: number | null
  availableInGrid?: boolean | null
}

export interface AttributeUpdateInput {
  name?: string | null
  slug?: string | null
  removeValues?: (string | null)[] | null
  addValues?: (AttributeValueCreateInput | null)[] | null
  valueRequired?: boolean | null
  isVariantOnly?: boolean | null
  visibleInStorefront?: boolean | null
  filterableInStorefront?: boolean | null
  filterableInDashboard?: boolean | null
  storefrontSearchPosition?: number | null
  availableInGrid?: boolean | null
}

export interface AttributeValueCreateInput {
  name: string
}

export interface AuthorizationKeyInput {
  key: string
  password: string
}

export interface CatalogueInput {
  products?: (string | null)[] | null
  categories?: (string | null)[] | null
  collections?: (string | null)[] | null
}

export interface CategoryFilterInput {
  search?: string | null
  ids?: (string | null)[] | null
}

export interface CategoryInput {
  description?: string | null
  descriptionJson?: any | null
  name?: string | null
  slug?: string | null
  seo?: SeoInput | null
  backgroundImage?: any | null
  backgroundImageAlt?: string | null
}

export type CategorySortingInput = SortBy<CategorySortField>

export interface CollectionFilterInput {
  published?: CollectionPublished | null
  search?: string | null
  ids?: (string | null)[] | null
}

export interface CollectionInput {
  isPublished?: boolean | null
  name?: string | null
  slug?: string | null
  description?: string | null
  descriptionJson?: any | null
  backgroundImage?: any | null
  backgroundImageAlt?: string | null
  seo?: SeoInput | null
  publicationDate?: any | null
}

export type CollectionSortingInput = SortBy<CollectionSortField>

export interface ConfigurationItemInput {
  name: string
  value?: string | null
}

export interface CustomerFilterInput {
  dateJoined?: DateRangeInput | null
  moneySpent?: PriceRangeInput | null
  numberOfOrders?: IntRangeInput | null
  placedOrders?: DateRangeInput | null
  search?: string | null
}

export interface CustomerInput {
  defaultBillingAddress?: AddressInput | null
  defaultShippingAddress?: AddressInput | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  isActive?: boolean | null
  note?: string | null
}

export interface DateTimeRangeInput {
  gte?: any | null
  lte?: any | null
}

export interface DraftOrderInput {
  billingAddress?: AddressInput | null
  user?: string | null
  userEmail?: string | null
  discount?: any | null
  shippingAddress?: AddressInput | null
  shippingMethod?: string | null
  voucher?: string | null
  customerNote?: string | null
}

export interface ExportInfoInput {
  attributes?: string[] | null
  warehouses?: string[] | null
  fields?: ProductFieldEnum[] | null
}

export interface ExportProductsInput {
  scope: ExportScope
  filter?: ProductFilter | null
  ids?: string[] | null
  exportInfo?: ExportInfoInput | null
  fileType: FileTypesEnum
}

export interface FulfillmentCancelInput {
  warehouseId: string
}

export interface FulfillmentUpdateTrackingInput {
  trackingNumber?: string | null
  notifyCustomer?: boolean | null
}

export interface IntRangeInput {
  gte?: number | null
  lte?: number | null
}

export interface MenuCreateInput {
  name: string
  items?: (MenuItemInput | null)[] | null
}

export interface MenuItemCreateInput {
  name: string
  url?: string | null
  category?: string | null
  collection?: string | null
  page?: string | null
  menu: string
  parent?: string | null
}

export interface MenuItemInput {
  name?: string | null
  url?: string | null
  category?: string | null
  collection?: string | null
  page?: string | null
}

export interface MenuItemMoveInput {
  itemId: string
  parentId?: string | null
  sortOrder?: number | null
}

export type MenuSortingInput = SortBy<MenuSortField>

export interface MetadataInput {
  key: string
  value: string
}

export interface NameTranslationInput {
  name?: string | null
}

export interface OrderAddNoteInput {
  message: string
}

export interface OrderDraftFilterInput {
  customer?: string | null
  created?: DateRangeInput | null
  search?: string | null
}

export interface OrderFulfillInput {
  lines: OrderFulfillLineInput[]
  notifyCustomer?: boolean | null
}

export interface OrderFulfillLineInput {
  orderLineId?: string | null
  stocks: OrderFulfillStockInput[]
}

export interface OrderFulfillStockInput {
  quantity?: number | null
  warehouse?: string | null
}

export interface OrderLineInput {
  quantity: number
}

export interface OrderUpdateShippingInput {
  shippingMethod?: string | null
}

export interface PageFilterInput {
  search?: string | null
}

export interface PageInput {
  slug?: string | null
  title?: string | null
  content?: string | null
  contentJson?: any | null
  isPublished?: boolean | null
  publicationDate?: string | null
  seo?: SeoInput | null
}

export type PageSortingInput = SortBy<PageSortField>

export interface PageTranslationInput {
  seoTitle?: string | null
  seoDescription?: string | null
  title?: string | null
  content?: string | null
  contentJson?: any | null
}

export interface PermissionGroupCreateInput {
  addPermissions?: PermissionEnum[] | null
  addUsers?: string[] | null
  name: string
}

export interface PermissionGroupFilterInput {
  search?: string | null
}

export type PermissionGroupSortingInput = SortBy<PermissionGroupSortField>

export interface PermissionGroupUpdateInput {
  addPermissions?: PermissionEnum[] | null
  addUsers?: string[] | null
  name?: string | null
  removePermissions?: PermissionEnum[] | null
  removeUsers?: string[] | null
}

export interface PluginFilterInput {
  active?: boolean | null
  search?: string | null
}

export type PluginSortingInput = SortBy<PluginSortField>

export interface PluginUpdateInput {
  active?: boolean | null
  configuration?: (ConfigurationItemInput | null)[] | null
}

export interface ProductInput {
  attributes?: (ProductCreateAttributeInput | null)[] | null
  publicationDate?: any | null
  category?: string | null
  chargeTaxes?: boolean | null
  collections?: (string | null)[] | null
  description?: string | null
  descriptionJson?: any | null
  isPublished?: boolean | null
  name?: string | null
  slug?: string | null
  taxCode?: string | null
  seo?: SeoInput | null
  weight?: any | null
  sku?: string | null
  trackInventory?: boolean | null
  basePrice?: any | null
  visibleInListings?: boolean | null
}

export interface ProductOrder extends SortBy<ProductOrderField> {
  attributeId?: string | null
}

export interface ProductTypeFilterInput {
  search?: string | null
  configurable?: ProductTypeConfigurable | null
  productType?: ProductTypeEnum | null
  ids?: (string | null)[] | null
}

export interface ProductTypeInput {
  name?: string | null
  slug?: string | null
  hasVariants?: boolean | null
  productAttributes?: (string | null)[] | null
  variantAttributes?: (string | null)[] | null
  isShippingRequired?: boolean | null
  isDigital?: boolean | null
  weight?: any | null
  taxCode?: string | null
}

export interface ProductVariantBulkCreateInput {
  attributes: (ProductCreateAttributeInput | null)[]
  costPrice?: any | null
  price?: any | null
  sku: string
  trackInventory?: boolean | null
  weight?: any | null
  stocks?: StockInput[] | null
}

export interface ProductVariantCreateInput {
  attributes: (ProductCreateAttributeInput | null)[]
  costPrice?: any | null
  price?: any | null
  sku?: string | null
  trackInventory?: boolean | null
  weight?: any | null
  product: string
  stocks?: StockInput[] | null
}

export interface ProductVariantInput {
  attributes?: (ProductCreateAttributeInput | null)[] | null
  costPrice?: any | null
  price?: any | null
  sku?: string | null
  trackInventory?: boolean | null
  weight?: any | null
}

export interface ReorderInput {
  id: string
  sortOrder?: number | null
}

export interface SaleFilterInput {
  status?: (DiscountStatusEnum | null)[] | null
  saleType?: DiscountValueTypeEnum | null
  started?: DateTimeRangeInput | null
  search?: string | null
}

export interface SaleInput {
  name?: string | null
  type?: DiscountValueTypeEnum | null
  value?: any | null
  products?: (string | null)[] | null
  categories?: (string | null)[] | null
  collections?: (string | null)[] | null
  startDate?: any | null
  endDate?: any | null
}

export interface SaleSortingInput extends SortBy<SaleSortField> {}

export interface SeoInput {
  title?: string | null
  description?: string | null
}

export interface ShippingPriceInput {
  name?: string | null
  price?: any | null
  minimumOrderPrice?: any | null
  maximumOrderPrice?: any | null
  minimumOrderWeight?: any | null
  maximumOrderWeight?: any | null
  type?: ShippingMethodTypeEnum | null
  shippingZone?: string | null
}

export interface ShippingZoneCreateInput {
  name?: string | null
  countries?: (string | null)[] | null
  default?: boolean | null
  addWarehouses?: (string | null)[] | null
}

export interface ShippingZoneUpdateInput {
  name?: string | null
  countries?: (string | null)[] | null
  default?: boolean | null
  addWarehouses?: (string | null)[] | null
  removeWarehouses?: (string | null)[] | null
}

export interface ShopSettingsInput {
  headerText?: string | null
  description?: string | null
  includeTaxesInPrices?: boolean | null
  displayGrossPrices?: boolean | null
  chargeTaxesOnShipping?: boolean | null
  trackInventoryByDefault?: boolean | null
  defaultWeightUnit?: WeightUnitsEnum | null
  automaticFulfillmentDigitalProducts?: boolean | null
  defaultDigitalMaxDownloads?: number | null
  defaultDigitalUrlValidDays?: number | null
  defaultMailSenderName?: string | null
  defaultMailSenderAddress?: string | null
  customerSetPasswordUrl?: string | null
}

export interface SiteDomainInput {
  domain?: string | null
  name?: string | null
}

export interface StaffCreateInput {
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  isActive?: boolean | null
  note?: string | null
  addGroups?: string[] | null
  redirectUrl?: string | null
}

export interface StaffUpdateInput {
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  isActive?: boolean | null
  note?: string | null
  addGroups?: string[] | null
  removeGroups?: string[] | null
}

export interface StaffUserInput {
  status?: StaffMemberStatus | null
  search?: string | null
}

export interface StockInput {
  warehouse: string
  quantity?: number | null
}

export interface TranslationInput {
  seoTitle?: string | null
  seoDescription?: string | null
  name?: string | null
  description?: string | null
  descriptionJson?: any | null
}

export interface UserCreateInput {
  defaultBillingAddress?: AddressInput | null
  defaultShippingAddress?: AddressInput | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  isActive?: boolean | null
  note?: string | null
  redirectUrl?: string | null
}

export interface UserSortingInput extends SortBy<UserSortField> {}

export interface VoucherFilterInput {
  status?: (DiscountStatusEnum | null)[] | null
  timesUsed?: IntRangeInput | null
  discountType?: (VoucherDiscountType | null)[] | null
  started?: DateTimeRangeInput | null
  search?: string | null
}

export interface VoucherInput {
  type?: VoucherTypeEnum | null
  name?: string | null
  code?: string | null
  startDate?: any | null
  endDate?: any | null
  discountValueType?: DiscountValueTypeEnum | null
  discountValue?: any | null
  products?: (string | null)[] | null
  collections?: (string | null)[] | null
  categories?: (string | null)[] | null
  minAmountSpent?: any | null
  minCheckoutItemsQuantity?: number | null
  countries?: (string | null)[] | null
  applyOncePerOrder?: boolean | null
  applyOncePerCustomer?: boolean | null
  usageLimit?: number | null
}

export interface VoucherSortingInput extends SortBy<VoucherSortField> {}

export interface WarehouseAddressInput {
  streetAddress1: string
  streetAddress2?: string | null
  city: string
  cityArea?: string | null
  postalCode?: string | null
  country: CountryCode
  countryArea?: string | null
  phone?: string | null
}

export interface WarehouseCreateInput {
  slug?: string | null
  companyName?: string | null
  email?: string | null
  name: string
  address: WarehouseAddressInput
  shippingZones?: (string | null)[] | null
}

export interface WarehouseFilterInput {
  search?: string | null
  ids?: (string | null)[] | null
}

export interface WarehouseSortingInput extends SortBy<WarehouseSortField> {}

export interface WarehouseUpdateInput {
  slug?: string | null
  companyName?: string | null
  email?: string | null
  name?: string | null
  address?: WarehouseAddressInput | null
}

export interface WebhookCreateInput {
  name?: string | null
  targetUrl?: string | null
  events?: (WebhookEventTypeEnum | null)[] | null
  serviceAccount?: string | null
  app?: string | null
  isActive?: boolean | null
  secretKey?: string | null
}

export interface WebhookFilterInput {
  search?: string | null
  isActive?: boolean | null
}

export interface WebhookSortingInput extends SortBy<WebhookSortField> {}

export interface WebhookUpdateInput {
  name?: string | null
  targetUrl?: string | null
  events?: (WebhookEventTypeEnum | null)[] | null
  serviceAccount?: string | null
  app?: string | null
  isActive?: boolean | null
  secretKey?: string | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
