import React, { FC } from 'react'

import { ObserveButton } from '../../pages/views'

import { getCurrencySymbol, getPriceToFixed } from '../helpers'

import { Column } from '../column'
import { Text } from '../text'
import { Row } from '../row'

import { OrderLineProps } from './order-line.types'
import { useStyle } from './order-line.styles'

export const OrderLine: FC<OrderLineProps> = ({
  className = '',
  product,
  price,
  totalPrice,
  color,
  material,
  options,
  quantity
}) => {
  const classes = useStyle()
  const { name, pricing, thumbnail2x } = product
  const { currency } = pricing.priceRange.start.gross
  const symbolCurrency = getCurrencySymbol(currency)
  const totalPriceWithSymbol = `${symbolCurrency}${getPriceToFixed(totalPrice)}`

  return (
    <Column fullWidth className={className}>
      <Row
        fullWidth
        className={classes.line}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Row alignItems="flex-start">
          <img
            className={classes.productAvatar}
            height="104px"
            width="104px"
            src={thumbnail2x.url}
          />
          <Row fullWidth alignItems="flex-start" justifyContent="space-between">
            <Column alignItems="flex-start" className={classes.title}>
              <Text color="black" preset="h5" text={name} />
              <ObserveButton
                className={classes.observeOptionsBtn}
                colorCode={color?.color.code}
                colorTitle={color?.color.name}
                material={material?.title}
                orderOptions={options}
                nameTx="order.line.observe.options"
              />
            </Column>
          </Row>
        </Row>
        <Row fullWidth justifyContent="space-between" alignItems="flex-start">
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Price"
              tx="order.line.product.price"
            />
            <Text color="black" preset="h7" text={`${price}`} />
          </Column>
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Quantity:"
              tx="order.line.product.quantity"
            />
            <Text
              className={classes.delivery}
              color="black"
              preset="h7"
              text={String(quantity)}
            />
          </Column>
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Total Price:"
              tx="order.line.product.total"
            />
            <Text
              className={classes.delivery}
              color="black"
              preset="h7"
              text={totalPriceWithSymbol}
            />
          </Column>
        </Row>
      </Row>
    </Column>
  )
}
