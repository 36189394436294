import { MouseEvent, MutableRefObject, useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutsideAlerter = (
  ref: MutableRefObject<HTMLElement | null | undefined>,
  open?: boolean,
  onClick?: (event: MouseEvent<Element, globalThis.MouseEvent>) => void
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (
      event: MouseEvent<Element, globalThis.MouseEvent>
    ) => {
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target) && open) {
        event.stopPropagation()
        if (onClick) {
          onClick(event)
        }
      }
    }

    // Bind the event listener
    if (open) {
      // @ts-ignore
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      // @ts-ignore
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      // @ts-ignore
      document.removeEventListener('mousedown', handleClickOutside)
      // Unbind the event listener on clean up
    }
  }, [onClick, open, ref])
}
