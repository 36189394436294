import React, { FC, useEffect } from 'react'
import { Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

import { Row } from '../row'
import { arrayHasElements } from '../../utils/tables'

import { CategoryNavItem } from '../category-nav-item'
import { useStyle } from './category-nav.styles'
import { CategoryNavProps } from './category-nav.types'

export const CategoryNav: FC<CategoryNavProps> = ({ className = '', data }) => {
  const { path: prefix } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const classes = useStyle()

  const getLink = (link: string) => `${prefix}${link}`

  useEffect(() => {
    if (prefix === location.pathname && arrayHasElements(data)) {
      history.push(getLink(data[0].link))
    }
  }, [location])

  const handleOnClick = (link: string) => () => {
    history.push(link)
  }

  return (
    <>
      <Row
        fullWidth
        className={`${className} ${classes.nav}`}
        justifyContent="space-between"
      >
        {data.map((navItem) => {
          const fullLink = getLink(navItem.link)
          return (
            <CategoryNavItem
              key={`navigation_path_${navItem.link}`}
              active={location.pathname.includes(fullLink)}
              icon={navItem.icon}
              text={navItem.text}
              tx={navItem.tx}
              onClick={handleOnClick(fullLink)}
            />
          )
        })}
      </Row>
      {data.map((navItem) => (
        <Route
          exact
          key={`navigation_route_${navItem.link}`}
          path={getLink(navItem.link)}
        >
          {navItem.content}
        </Route>
      ))}
    </>
  )
}
