import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    minHeight: 'calc(100vh - 368px)',
    maxWidth: '100vw',
    flex: 1,
    alignSelf: 'stretch'
  },
  content: {
    alignSelf: 'stretch',
    background: theme.colors.lightFon,
    borderLeft: `1px solid ${theme.colors.shadow}`,
    flex: 2,
    marginLeft: 12,
    width: 'calc(100% - 368px)'
  },
  row: {
    flex: 1,
    alignSelf: 'stretch'
  }
}))
