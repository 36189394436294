import React, { FC, useMemo, useState } from 'react'

import {
  CalendarSlider,
  CheckboxListItem,
  Column,
  FilterList,
  FilterListByAlphabet,
  FiltersSidebar,
  getGraphDate,
  Icon,
  ICONS,
  PermissionContent
} from '../../../components'
import {
  OrderControlFiltersKeys,
  OrderFilterInput,
  OrderMainStatus,
  PermissionEnum
} from '../../../services'
import { getMessageByMainStatus } from '../../../utils'

import { useStyle } from './order-control-filters.styles'
import { OrderControlFiltersProps } from './order-control-filters.types'

export const OrderControlFilters: FC<OrderControlFiltersProps> = ({
  filters,
  onSubmit,
  onReset
}) => {
  const [openSlider, changeOpenSlider] = useState<
    Partial<Record<keyof OrderFilterInput, boolean | undefined>>
  >({})

  const [filter, changeFilter] = useState<Partial<OrderFilterInput>>({})
  const classes = useStyle()
  const [open, changeOpen] = useState(false)

  const dateValue = useMemo(() => {
    if (filter.created) {
      const { gte, lte } = filter.created

      if (gte && lte) {
        return [new Date(gte), new Date(lte)]
      }

      if (gte) {
        return [new Date(gte)]
      }
    }

    return undefined
  }, [filter.created])

  const Statuses = useMemo<CheckboxListItem[]>(() => {
    if (OrderMainStatus) {
      return Object.values(OrderMainStatus).map((status) => {
        const props = getMessageByMainStatus(status)
        return {
          name: props.text || status,
          nameTx: props.tx,
          id: status
        }
      })
    }

    return []
  }, [OrderMainStatus])

  const handleOnChangeStatus = (status?: string[]) => {
    const nextStatus = status as OrderMainStatus[]
    changeFilter({ ...filter, mainStatus: nextStatus })
  }

  const handleOnChange =
    (prop: OrderControlFiltersKeys) => (value: string[]) => {
      changeFilter({ ...filter, [prop]: value })
    }

  const handleOnChangeDate = (from: string, to?: string) => {
    if (from) {
      changeFilter({
        ...filter,
        created: {
          gte: getGraphDate(from),
          lte: to ? getGraphDate(to) : undefined
        }
      })
    } else {
      changeFilter({
        ...filter,
        created: undefined
      })
    }
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const isDisabledFilter = () => {
    const disabled =
      filter.created ||
      filter.companyName?.length ||
      filter.customer?.length ||
      filter.mainStatus
    if (disabled) {
      return false
    }
    return true
  }
  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    if (onSubmit) {
      onSubmit(filter)
    }
    close()
  }

  const handleOnOpenSlider =
    (prop: OrderControlFiltersKeys) => (state: boolean) => {
      changeOpenSlider({ ...openSlider, [prop]: state })
    }

  const handleOnReset = () => {
    const close = handleOnOpen(false)

    changeFilter({})
    changeOpenSlider({})
    if (onReset) {
      onReset()
    }
    close()
  }

  return (
    <Column className={classes.container} onClick={handleOnOpen(true)}>
      <Icon src={ICONS.filterList} height={32} width={32} />
      <FiltersSidebar
        open={open}
        onClose={handleOnOpen(false)}
        onApply={handleOnSubmit}
        onReset={handleOnReset}
        isDisabled={isDisabledFilter()}
      >
        {open && (
          <Column fullWidth>
            <CalendarSlider
              className={classes.slider}
              defaultOpen={openSlider.created}
              title="Order Creation Date"
              titleTx="orderControl.filters.date"
              value={dateValue}
              onOpen={handleOnOpenSlider('created')}
              onChange={handleOnChangeDate}
            />
            <FilterList
              className={classes.slider}
              defaultValue={filter.mainStatus}
              defaultOpen={openSlider.mainStatus}
              data={Statuses}
              name="mainStatus"
              title="Statuses"
              titleTx="orderControl.filters.status"
              onOpen={handleOnOpenSlider('mainStatus')}
              onChange={handleOnChangeStatus}
            />
            <FilterListByAlphabet
              className={classes.slider}
              defaultValue={filter.customer}
              defaultOpen={openSlider.customer}
              data={filters.customers}
              name="customer"
              title="Customer Name"
              titleTx="orderControl.filters.customer"
              onOpen={handleOnOpenSlider('customer')}
              onChange={handleOnChange('customer')}
            />
            <PermissionContent
              permissions={[
                PermissionEnum.VIEW_ORDER,
                PermissionEnum.ADD_CHANGE_REMOVE_ORDERS
              ]}
            >
              <FilterListByAlphabet
                className={classes.slider}
                defaultValue={filter.companyName}
                defaultOpen={openSlider.companyName}
                data={filters.companyNames}
                name="company"
                title="Company"
                titleTx="orderControl.filters.customer"
                onOpen={handleOnOpenSlider('companyName')}
                onChange={handleOnChange('companyName')}
              />
            </PermissionContent>

            <PermissionContent permissions={[PermissionEnum.MANAGE_ORDERS]}>
              <FilterListByAlphabet
                className={classes.slider}
                defaultValue={filter.vendor}
                defaultOpen={openSlider.vendor}
                data={filters.vendors}
                name="vendor"
                title="Vendor"
                titleTx="orderControl.filters.vendor"
                onOpen={handleOnOpenSlider('vendor')}
                onChange={handleOnChange('vendor')}
              />
            </PermissionContent>
          </Column>
        )}
      </FiltersSidebar>
    </Column>
  )
}
