import React, { FC } from 'react'

import { Column, Icon, ICONS, Section, TextField } from '../../../components'

import { useStyle } from './advertising-price-section.styles'

import { AdvertisingPriceSectionProps } from './advertising-price-section.types'

export const AdveretisingPriceSection: FC<AdvertisingPriceSectionProps> = ({
  className = '',
  totalPrice,
  totalPriceToPay,
  pricePerDay
}) => {
  const classes = useStyle()

  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />

  return (
    <Section
      className={className}
      title="Advertising Price"
      titleTx="promotion.advertising.advetrtisingPrice"
    >
      <Column
        alignItems="flex-start"
        className={classes.sectionAdditionContainer}
      >
        <TextField
          className={classes.field}
          defaultValue={totalPrice}
          label="Total Price"
          labelTx="promotion.advertising.form.total.price"
          type="number"
          maxLength={15}
          rightElement={dollarIcon}
          value={totalPriceToPay}
          disabled={true}
        />
        <TextField
          className={classes.field}
          defaultValue={pricePerDay}
          label="Price Per Day"
          labelTx="promotion.advertising.form.price.per.day"
          type="number"
          maxLength={15}
          rightElement={dollarIcon}
          value={pricePerDay}
          disabled={true}
        />
      </Column>
    </Section>
  )
}
