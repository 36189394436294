import { ApolloError, ApolloQueryResult, MutationResult } from '@apollo/client'

import {
  ListVariablesWithoutFilter,
  PaginationList,
  SortBy
} from '../api.types'
import { User } from '../auth'
import {
  Product,
  ProductColor,
  ProductCreateColorInput,
  ProductCreateMaterialInput,
  ProductCreateOptionInput,
  ProductErrorCode,
  ProductMaterial,
  ProductOption,
  ProductOptionVariant,
  ProductOptionVariantInput
} from '../product'

export enum OfferStatus {
  NEW = 'NEW',
  DRAFT = 'DRAFT',
  ACCEPTED = 'ACCEPTED',
  ACCEPTED_BACK_FROM_CART = 'ACCEPTED_BACK_FROM_CART',
  DECLINED = 'DECLINED',
  DECLINED_BY_CUSTOMER = 'DECLINED_BY_CUSTOMER',
  DECLINED_BY_VENDOR = 'DECLINED_BY_VENDOR',
  DELETED_BY_CUSTOMER = 'DELETED_BY_CUSTOMER',
  DELETED_BY_VENDOR = 'DELETED_BY_VENDOR',
  START_OFFER = 'START_OFFER'
}

export type OfferError = {
  field: String
  message: String
  code: ProductErrorCode
}

export interface OfferColor extends ProductColor {}

export interface OfferMaterial extends ProductMaterial {}

export interface OfferOption extends ProductOption {}

export type OfferItem = {
  id: string
  quantity: number
  totalItemPrice: number
  unitIds: string[]
  options: ProductOptionVariant[]
  productMaterials: OfferMaterial[]
  productColors: OfferColor[]
  productOptions: OfferOption[]
}

export type Offer = {
  id: string
  description: string
  noSuggestion: boolean
  conversationId: string
  adminCustomerConversationId: string
  quantity: number
  discount: number
  productTotalPrice: number
  totalPrice: number
  vendor: User
  customer: User
  product: Product
  status: OfferStatus
  number: number
  options: ProductOptionVariant[]
  productMaterials: OfferMaterial[]
  productColors: OfferColor[]
  productOptions: OfferOption[]
  deliveryPrice: number
  productionDays: number
  items: OfferItem[]
}

export type OfferItemInput = {
  uuid?: string
  quantity: number
  totalItemPrice: number
  productColors: ProductCreateColorInput[]
  productMaterials: ProductCreateMaterialInput[]
  productOptions: ProductCreateOptionInput[]
  options: ProductOptionVariantInput[]
}

export type OfferCreateInput = {
  customer?: string
  product: string
  productColors: ProductCreateColorInput[]
  productMaterials: ProductCreateMaterialInput[]
  productOptions?: ProductCreateOptionInput[]
  options?: ProductOptionVariantInput[]
  totalPrice: number
  discount?: number
  quantity?: number
  noSuggestion?: boolean
  description?: string
  status?: string
  productionDays?: number
  items?: OfferItemInput[]
}

export interface OffersWithFilters extends PaginationList<Offer> {
  filters: string
}

export type GetOffersData = {
  offers: OffersWithFilters
}

export enum OfferOrderField {
  NUMBER = 'NUMBER',
  ITEM_NAME = 'ITEM_NAME',
  CUSTOMER_NAME = 'CUSTOMER_NAME',
  TOTAL_PRICE = 'TOTAL_PRICE',
  STATUS = 'STATUS',
  QUANTITY = 'QUANTITY'
}

export type OfferOrder = SortBy<OfferOrderField>

export type OfferFilterInput = {
  status?: OfferStatus[]
  noSuggesting?: boolean
  search?: string
}

export interface OffersVariables extends ListVariablesWithoutFilter {
  sortBy?: OfferOrder
  filter?: OfferFilterInput
}

export type OfferByIdVariables = {
  id: string
}

export type OfferDuplicateVariables = {
  offers: string[]
}

export type OfferItemDuplicateVariables = {
  offer: string
}

export type GetOffer = {
  offer: Offer
}

export type GetOfferByIdRequest = {
  data: GetOffer | null
  loading: boolean
  refetch?: (
    variables?: OfferByIdVariables
  ) => Promise<ApolloQueryResult<GetOffer>>
}

export type OfferCreateVariables = {
  input: OfferCreateInput
}

export interface OfferUpdateVariables extends OfferCreateVariables {
  id: string
}

export type OfferRemoveVariables = {
  id: string
}

export type OfferBulkDeleteVariables = {
  ids: string[]
}

export type GetOffers = {
  data?: GetOffersData
  loading: boolean
  error?: ApolloError
  refetch: (
    variables?: Partial<OffersVariables>
  ) => Promise<ApolloQueryResult<GetOffersData>>
}

export type GetOfferUpdateResults = {
  offerUpdate: {
    offer: Offer
    offerErrors: OfferError[]
  }
}

export type GetOfferRemoveResults = {
  offerDelete: {
    offer: Offer
    productErrors: OfferError[]
  }
}

export type GetOfferBulkDeleteResults = {
  offerBulkDelete: {
    count: number
    offerErrors: OfferError[]
  }
}

export type GetOfferDuplicateResults = {
  offerDuplicate: {
    offers: Offer[]
  }
}

export type GetOfferItemDuplicateResults = {
  offerItemDuplicate: {
    item: OfferItem
  }
}

export type OfferUpdate = {
  onSubmit: (variables: OfferUpdateVariables) => void
  response: MutationResult<GetOfferUpdateResults>
}

export type OfferRemove = {
  onSubmit: (variables: OfferRemoveVariables) => void
  response: MutationResult<GetOfferRemoveResults>
}

export type OfferBulkDelete = {
  onSubmit: (variables: OfferBulkDeleteVariables) => void
  response: MutationResult<GetOfferBulkDeleteResults>
}

export type OfferDuplicate = {
  onSubmit: (variables: OfferDuplicateVariables) => void
  response: MutationResult<GetOfferDuplicateResults>
}

export type OfferItemDuplicate = {
  onSubmit: (variables: OfferItemDuplicateVariables) => void
  response: MutationResult<GetOfferItemDuplicateResults>
}

export type OfferApi = {
  useOffers: (variables: OffersVariables) => GetOffers
  useOfferById: (variables: OfferByIdVariables) => GetOfferByIdRequest
  useOfferUpdate: () => OfferUpdate
  useOfferRemove: () => OfferRemove
  useOfferBulkDelete: () => OfferBulkDelete
  useOfferDuplicate: () => OfferDuplicate
}
