import { createUseStyles } from 'react-jss'
import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  icon: {
    width: 32,
    height: 32,
    fill: theme.colors.yellow
  },
  benefits: {
    marginLeft: 14
  }
}))
