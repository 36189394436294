import {
  ExtensionMessageType,
  sendMessageToExtension,
  ThemeChangeMessage
} from '@saleor/macaw-ui/extensions'
import React, { useEffect } from 'react'
import { ThemeProvider as JssThemeProvider, JssProvider } from 'react-jss'

import Baseline from '../../Baseline'
import { light as jssLight, dark as jssDark } from './theme'

interface IThemeContext {
  isDark: boolean
  sendThemeToExtension: () => void
  toggleTheme: () => void
}
export const ThemeContext = React.createContext<IThemeContext>({
  isDark: false,
  sendThemeToExtension: () => undefined,
  toggleTheme: () => undefined
})

interface ThemeProviderProps {
  isDefaultDark?: boolean
}
const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
  isDefaultDark
}) => {
  const [isDark, setDark] = React.useState(isDefaultDark || false)
  const sendThemeToExtension = () =>
    sendMessageToExtension<ThemeChangeMessage>(
      {
        theme: isDark ? 'dark' : 'light',
        type: ExtensionMessageType.THEME
      },
      '*'
    )

  const toggleTheme = () => {
    setDark(!isDark)
    localStorage.setItem('theme', (!isDark).toString())
  }

  useEffect(() => {
    sendThemeToExtension()
  }, [isDark])

  const jssTheme = isDark ? jssDark : jssLight

  return (
    <JssProvider>
      <ThemeContext.Provider
        value={{
          isDark,
          sendThemeToExtension,
          toggleTheme
        }}
      >
        <JssThemeProvider theme={jssTheme}>
          <Baseline />
          {children}
        </JssThemeProvider>
      </ThemeContext.Provider>
    </JssProvider>
  )
}
ThemeProvider.defaultProps = {
  isDefaultDark: false
}
export default ThemeProvider
