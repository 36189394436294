const lightColors = {
  active: '#A6E8D7',
  black: '#000000dd',
  blue: '#75B4F2',
  darkBlue: '#293A56',
  error: '#DD0000',
  green: '#219653',
  purple: '#9B51E0',
  errorBackground: '#FCE6E6',
  greyFon: '#EEEEEE',
  hover: '#FF9E00',
  inactive: '#666666DE',
  lightBlue: '#DFFAF9',
  lightFon: '#FAFDFD',
  inactiveSecondary: '#666666B3',
  shadow: '#E0E0E0',
  transparent: 'transparent',
  white: '#FFFFFF',
  yellow: '#FEB53E',
  canceled: '#EB5757',
  notConfirmed: '#CACACA',
  canceledWithoutRefund: '#F256E2',
  vendorProfileFon: '#F8FAFA',
  refunded: '#6FCF97',
  partlyRefunded: '#F2994A',
  overlay: 'rgba(0, 0, 0, 0.4)'
}

export const light = {
  boxShadow: `4px 4px 16px ${lightColors.shadow}`,
  colors: lightColors,
  fonts: {
    defaultSize: 16,
    main: ['Jost', 'sans-serif']
  },
  link: {
    padding: '.25em 1em',
    textDecoration: 'none',
    textTransform: 'capitalize',
    fontFamily: 'Jost'
  },
  typography: {
    mainBanner: {
      fontWeight: 600,
      fontSize: 92,
      lineHeight: '123px',
      letterSpacing: '0.0002em',
      margin: 0,
      fontFamily: 'Jost'
    },
    secondBanner: {
      fontWeight: 600,
      fontSize: 64,
      lineHeight: '70px',
      margin: 0,
      fontFamily: 'Jost'
    },
    h1: {
      fontWeight: 600,
      fontSize: 48,
      lineHeight: '62px',
      letterSpacing: '0.006em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h2: {
      fontWeight: 400,
      fontSize: 45,
      lineHeight: '62px',
      letterSpacing: '0.004em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h3: {
      fontWeight: 400,
      fontSize: 34,
      lineHeight: '46px',
      letterSpacing: '0.003em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h4: {
      fontWeight: 700,
      fontSize: 23,
      lineHeight: '31px',
      letterSpacing: '0.002em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h5: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '26px',
      letterSpacing: '0.025em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h6: {
      fontWeight: 600,
      fontSize: 15,
      lineHeight: '21px',
      letterSpacing: '0.006em',
      margin: 0,
      fontFamily: 'Jost'
    },
    h7: {
      fontWeight: 500,
      fontSize: 15,
      lineHeight: '21px',
      letterSpacing: '-0.005em',
      margin: 0,
      fontFamily: 'Jost'
    },
    title: {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: '21px',
      letterSpacing: '-0.003em',
      margin: 0,
      fontFamily: 'Jost'
    },
    subscriptionText: {
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '18px',
      letterSpacing: '-0.009em',
      margin: 0,
      fontFamily: 'Jost'
    },
    maxButton: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      letterSpacing: '0.01em',
      margin: 0,
      fontFamily: 'Jost'
    },
    cardText: {
      fontWeight: 600,
      fontSize: 11,
      lineHeight: '15px',
      letterSpacing: '0.017em',
      margin: 0,
      fontFamily: 'Jost'
    },
    body: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: '24px',
      margin: 0,
      fontFamily: 'Roboto'
    },
    secondTitle: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: '14px',
      margin: 0,
      fontFamily: 'Roboto'
    },
    rightButton: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: '40px',
      margin: 0,
      fontFamily: 'Jost'
    },
    notFound: {
      fontWeight: 600,
      fontSize: 144,
      lineHeight: '184.61px'
    },
    button: {
      fontWeight: 400,
      fontSize: 8,
      lineHeight: '9px',
      fontFamily: 'Jost'
    },
    spacing: {
      unit: 16
    },
    boxShadow:
      '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)',
    link: {
      textDecoration: 'none',
      textTransform: 'capitalize',
      padding: '.25em 1em'
    },
    mediumScreen: '992px',
    largeScreen: '1438px'
  }
}

export const dark = {
  ...light,
  boxShadow: `4px 4px 16px ${lightColors.shadow}`,
  colors: lightColors,
  typography: {
    ...light.typography,
    boxShadow:
      '0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12)'
  }
}

export type Color = keyof typeof lightColors

type LightType = typeof light

export type LightTheme = LightType
