import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import background from '../../../assets/images/background-pattern.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundSize: '100vw',
    backgroundRepeat: 'space',
    backgroundImage: `url(${background})`,
    paddingBottom: 64,
    zIndex: 1
  },
  changeForm: {
    marginTop: 96,
    width: 536
  },
  changeSecondaryText: {
    marginTop: 8
  },
  input: {
    marginTop: 24
  },
  error: {
    position: 'absolute',
    backgroundColor: theme.colors.errorBackground,
    padding: '8px 16px',
    top: 24,
    width: 'calc(100% - 32px)',
    borderRadius: 4
  },
  errorIcon: {},
  errorText: {
    marginLeft: 16
  },
  submit: {
    marginTop: 96
  },
  logIn: {
    marginTop: 256,
    width: 488
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  }
}))
