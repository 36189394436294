import { useQuery } from '@apollo/client'

import {
  DELIVERY_PRICE_QUERY,
  TOTAL_DELIVERY_PRICE_QUERY
} from './delivery-price.graphql'
import {
  DeliveryPriceApi,
  DeliveryPriceVariables,
  GetDeliveryPrice,
  GetDeliveryPriceData,
  GetTotalDeliveryPrice,
  GetTotalDeliveryPriceData,
  TotalDeliveryPriceVariables
} from './delivery.types'

// our "constructor"
export const deliveryService = (): DeliveryPriceApi => {
  const useDeliveryPrice = (
    variables: DeliveryPriceVariables
  ): GetDeliveryPrice => {
    const { data, loading, error, refetch } = useQuery<
      GetDeliveryPriceData,
      DeliveryPriceVariables
    >(DELIVERY_PRICE_QUERY, {
      variables
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useTotalDeliveryPrice = (
    variables: TotalDeliveryPriceVariables
  ): GetTotalDeliveryPrice => {
    const { data, loading, error, refetch } = useQuery<
      GetTotalDeliveryPriceData,
      TotalDeliveryPriceVariables
    >(TOTAL_DELIVERY_PRICE_QUERY, {
      variables
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useDeliveryPrice,
    useTotalDeliveryPrice
  }
}
