import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.inactiveSecondary,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw'
  }
}))
