import React, { FC, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'
import { ICONS } from '../icon'
import { Button, ButtonProps } from '../button'
import { IconButton } from '../icon-button'
import { Color, LightTheme } from '../Theme'

import { getGraphDate, getDateWithStartTime, getMonthLocale } from './helpers'
import { CalendarNumberItem } from './calendar-number-item'
import {
  CalendarPickerProps,
  DateOfWeekPicker,
  CalendarPickerType
} from './calendar-picker.types'
import { useStyle } from './calendar-picker.styles'
import { arrayHasElements } from '..'

const daysOfWeek: DateOfWeekPicker[] = [
  {
    text: 'Su',
    tx: 'datePicker.sunday',
    values: []
  },
  {
    text: 'Mo',
    tx: 'datePicker.monday',
    values: []
  },
  {
    text: 'Tu',
    tx: 'datePicker.tuesday',
    values: []
  },
  {
    text: 'We',
    tx: 'datePicker.wednesday',
    values: []
  },
  {
    text: 'Th',
    tx: 'datePicker.thursday',
    values: []
  },
  {
    text: 'Fr',
    tx: 'datePicker.friday',
    values: []
  },
  {
    text: 'Sa',
    tx: 'datePicker.saturday',
    values: []
  }
]

export const CalendarPicker: FC<CalendarPickerProps> = ({
  onChange,
  value: currentValue
}) => {
  const PickerType = useMemo(() => {
    if (Array.isArray(currentValue) && arrayHasElements(currentValue)) {
      if (currentValue[1]) {
        return CalendarPickerType.PERIOD
      }
    }

    return CalendarPickerType.DATE
  }, [currentValue])

  // Simple pick date or period pick date state
  const [pickerType, changePeriodType] = useState(PickerType)
  const defaultDate = new Date().setDate(1)
  const [monthDate, changeMonthDate] = useState(new Date(defaultDate))
  const [fromDate, changeFromDate] = useState<Date | undefined>()
  const [toDate, changeToDate] = useState<Date | undefined>()

  const theme = useTheme<LightTheme>()
  const classes = useStyle({ theme })

  const dateByWeek: DateOfWeekPicker[] = useMemo(() => {
    const lastDayOfMonth = new Date(
      monthDate.getFullYear(),
      monthDate.getMonth() + 1,
      0
    ).getDate()
    const firstWeekdayOfMonth = new Date(
      monthDate.getFullYear(),
      monthDate.getMonth(),
      1
    ).getDay()

    const month = monthDate.getMonth()
    const year = monthDate.getFullYear()

    const daysComponents: DateOfWeekPicker[] = daysOfWeek.map((item) => ({
      ...item,
      values: []
    }))

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < firstWeekdayOfMonth; i++) {
      daysComponents[i].values.push('')
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= lastDayOfMonth; i++) {
      const dayOfWeek = new Date(year, month, i).getDay()
      daysComponents[dayOfWeek].values.push(String(i))
    }

    return daysComponents
  }, [monthDate])

  const normalizedMonth = useMemo(() => getMonthLocale(monthDate), [monthDate])

  const textColor = (value: CalendarPickerType): Color =>
    value === pickerType ? 'white' : 'inactive'

  const isSentDisabled = !fromDate

  const buttonSelectDateTextProps: ButtonProps = {
    text: 'Select a Date',
    tx: 'datePicker.select.date',
    textColor: textColor(CalendarPickerType.DATE)
  }

  const buttonSelectPeriodTextProps: ButtonProps = {
    text: 'Select a Period',
    tx: 'datePicker.select.period',
    textColor: textColor(CalendarPickerType.PERIOD)
  }

  const buttonBackgroundColor = (value: CalendarPickerType): Color =>
    value === pickerType ? 'darkBlue' : 'transparent'

  const handleOnPrevMonth = () => {
    const currentMonth = monthDate.getMonth()

    const nextTime = monthDate.setMonth(currentMonth - 1)

    changeMonthDate(new Date(nextTime))

    if (pickerType === CalendarPickerType.DATE) {
      changeFromDate(undefined)
      changeToDate(undefined)
    }
  }

  const handleOnNextMonth = () => {
    const currentMonth = monthDate.getMonth()

    const nextTime = monthDate.setMonth(currentMonth + 1)

    changeMonthDate(new Date(nextTime))

    if (pickerType === CalendarPickerType.DATE) {
      changeFromDate(undefined)
      changeToDate(undefined)
    }
  }

  const handleOnChangePeriod = (state: CalendarPickerType) => () => {
    changePeriodType(state)

    changeFromDate(undefined)
    changeToDate(undefined)
  }

  const handleOnChange = (nextFromDate: Date, nextToDate?: Date) => {
    if (nextToDate) {
      const fromFormatDate = getGraphDate(nextFromDate)
      const toFormatDate = getGraphDate(nextToDate)

      if (onChange) {
        onChange(fromFormatDate, toFormatDate)
      }
    }

    if (CalendarPickerType.DATE === pickerType) {
      const fromFormatDate = getGraphDate(nextFromDate)

      if (onChange) {
        onChange(fromFormatDate)
      }
    }
  }

  const handleOnPickFromPeriod = (day: string) => {
    const month = monthDate.getMonth()
    const year = monthDate.getFullYear()

    const nowDate = getDateWithStartTime(year, month, Number(day))
    changeFromDate(nowDate)

    if (CalendarPickerType.DATE) {
      handleOnChange(nowDate)
    }
  }

  const handleOnPickToPeriod = (day: string) => {
    const month = monthDate.getMonth()
    const year = monthDate.getFullYear()

    const nextToDate = getDateWithStartTime(year, month, Number(day))

    if (fromDate) {
      if (nextToDate.getTime() <= fromDate.getTime()) {
        changeToDate(fromDate)
        changeFromDate(nextToDate)
        handleOnChange(nextToDate, fromDate)
      } else {
        changeToDate(nextToDate)
        handleOnChange(fromDate, nextToDate)
      }
    }
  }

  const handleOnPick = (day: string) => () => {
    if (toDate && pickerType === CalendarPickerType.PERIOD) {
      changeToDate(undefined)
      return handleOnPickFromPeriod(day)
    }

    if (fromDate && pickerType === CalendarPickerType.PERIOD) {
      return handleOnPickToPeriod(day)
    }

    return handleOnPickFromPeriod(day)
  }

  const handleOnReset = () => {
    const currentDefaultDate = new Date().setDate(1)

    changeMonthDate(new Date(currentDefaultDate))
    changeFromDate(undefined)
    changeToDate(undefined)

    if (onChange) {
      onChange('')
    }
  }

  useEffect(() => {
    if (Array.isArray(currentValue) && arrayHasElements(currentValue)) {
      const from = currentValue[0]
      changeFromDate(from)
      if (currentValue[1]) {
        const to = currentValue[1]
        changeToDate(to)
      }
      if (pickerType === CalendarPickerType.DATE) {
        changeMonthDate(from)
      }
    } else {
      handleOnReset()
    }
  }, [currentValue])

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="space-between">
        <IconButton
          iconColor="black"
          iconName={ICONS.arrowLeft}
          preset="transparent"
          onClick={handleOnPrevMonth}
        />
        <Text className={classes.text} text={normalizedMonth} preset="h6" />
        <IconButton
          iconColor="black"
          iconName={ICONS.arrowRight}
          preset="transparent"
          onClick={handleOnNextMonth}
        />
        <Button
          disabled={isSentDisabled}
          preset="button4"
          text="Reset"
          textColor="white"
          textPreset="maxButton"
          tx="datePicker.reset"
          onClick={handleOnReset}
        />
      </Row>
      <Row className={classes.buttonContainer} fullWidth>
        <Button
          {...buttonSelectDateTextProps}
          color={buttonBackgroundColor(CalendarPickerType.DATE)}
          className={classes.button}
          preset="primary"
          textPreset="h7"
          onClick={handleOnChangePeriod(CalendarPickerType.DATE)}
        />
        <Button
          {...buttonSelectPeriodTextProps}
          color={buttonBackgroundColor(CalendarPickerType.PERIOD)}
          className={classes.button}
          preset="primary"
          textPreset="h7"
          onClick={handleOnChangePeriod(CalendarPickerType.PERIOD)}
        />
      </Row>
      <Row
        fullWidth
        className={classes.calendar}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {dateByWeek.map((dayOfWeek) => (
          <Column fullWidth key={`day_${dayOfWeek.text}`}>
            <Text
              color="inactiveSecondary"
              text={dayOfWeek.text}
              tx={dayOfWeek.tx}
            />
            {dayOfWeek.values.map((dateOfMonth, index) => (
              <CalendarNumberItem
                key={`date_month_${index}_${dateOfMonth}`}
                from={fromDate}
                to={toDate}
                monthDate={monthDate}
                pickerType={pickerType}
                text={dateOfMonth}
                onClick={handleOnPick(dateOfMonth)}
              />
            ))}
          </Column>
        ))}
      </Row>
    </Column>
  )
}
