import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    maxWidth: '1600px',
    width: '100%'
  },
  '@media screen and (max-width: 1600px)': {
    container: {
      maxWidth: '100%'
    }
  },
  '@media screen and (max-width: 425px)': {
    container: {
      maxWidth: '100%',
      flex: 1
    }
  }
})
