import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import { useApi, useToastify } from '../../providers'
import { arrayHasElements, Loader } from '../../components'
import {
  ProductCreateAttributeInput,
  ProductCreateColorInput,
  ProductCreateInput,
  ProductCreateMaterialInput,
  ProductCreateOptionInput
} from '../../services'

import { ProductPreview } from '../views'
import { ItemForm } from '../item-form'
import {
  CabinetNavigatorPathVendor,
  ItemsTabNavigation
} from '../cabinet-navigator'

import { EditItemParams } from './edit-item.types'

export const EditItemPage = () => {
  const { open } = useToastify()
  const { product: productApi } = useApi()
  const { id } = useParams<EditItemParams>()
  const { data } = productApi.useProductById({ id })
  const { response, onSubmit: onSubmitProduct } = productApi.useProductUpdate()
  const { onSubmit: onSubmitImage } = productApi.useProductImageCreate()
  const { onSubmit: onSubmitRemoveImages } =
    productApi.useProductImageBulkDelete()
  const [images, changeImages] = useState<File[]>([])
  const [openProductPreview, changeOpenProductPreview] = useState(false)

  const history = useHistory()
  const goBackPath = `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.PRODUCTS}`

  useEffect(() => {
    if (response.data?.productUpdate.product) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })

      images.forEach((image) => {
        const variables = { input: { product: id, image } }
        onSubmitImage(variables)
      })

      if (!openProductPreview) {
        history.push(goBackPath)
      }
    }
  }, [response.data])

  const handleOnOpenProductPreview = (state: boolean) => () => {
    changeOpenProductPreview(state)
  }

  const handleOnSubmitPreview = (
    nextProduct: ProductCreateInput,
    nextImages: File[]
  ) => {
    if (nextProduct) {
      onSubmitProduct({ id, input: nextProduct })
      changeImages(nextImages)
    }

    changeOpenProductPreview(true)
  }

  const handleOnSubmit = (
    nextProduct: ProductCreateInput,
    nextImages: File[]
  ) => {
    if (nextProduct) {
      onSubmitProduct({ id, input: nextProduct })
      changeImages(nextImages)
    }
  }

  const handleOnRemoveSubmit = (imagesIds: string[]) => {
    if (arrayHasElements(imagesIds)) {
      onSubmitRemoveImages({ ids: imagesIds })
    }
  }

  const Item = useMemo((): Partial<ProductCreateInput> => {
    if (data) {
      const {
        name,
        visibleInListings,
        purchaseSpecifics,
        defaultPrice,
        pricing,
        category,
        description,
        collection,
        productionDaysUpTo,
        brandName,
        countryOfOrigin,
        materialCare,
        materialComposition,
        deliveryPrice,
        isFragile,
        colors: defaultColors = [],
        materials: defaultMaterials = [],
        attributes: defaultAttributes = [],
        vendor,
        options: defaultOptions = []
      } = data.product

      const colors = defaultColors.map<ProductCreateColorInput>((color) => ({
        title: color.title,
        description: color.description,
        extraPrice: color.extraPrice,
        extraProductionDays: color.extraProductionDays,
        color: color.color.id,
        isDefault: color.isDefault
      }))

      const materials = defaultMaterials.map<ProductCreateMaterialInput>(
        (material) => ({
          title: material.title,
          description: material.description,
          extraPrice: material.extraPrice,
          extraProductionDays: material.extraProductionDays,
          isDefault: material.isDefault
        })
      )

      const attributes = defaultAttributes.map<ProductCreateAttributeInput>(
        (productAttribute) => ({
          type: productAttribute.type,
          attributeId: productAttribute.attribute.id
        })
      )

      const options = defaultOptions.map<ProductCreateOptionInput>(
        (productOption) => ({
          title: productOption.title,
          type: productOption.type,
          variants: productOption.variants
        })
      )

      return {
        attributes,
        name,
        visibleInListings,
        purchaseSpecifics,
        basePrice: pricing.priceRange.start.gross.amount,
        category: category?.id,
        description,
        colors,
        defaultPrice,
        materials,
        collection: collection?.id,
        vendor: vendor?.id,
        options,
        productionDaysUpTo,
        brandName,
        countryOfOrigin,
        materialCare,
        materialComposition,
        deliveryPrice: deliveryPrice?.id,
        isFragile
      }
    }
    return {}
  }, [data])

  if (!data || !Item) {
    return <Loader />
  }

  return (
    <>
      <ItemForm
        key={`item_${id}_${uuid()}`}
        productId={id}
        title={data.product.name}
        item={Item}
        images={data.product.images}
        vendor={data.product.vendor}
        onSubmit={handleOnSubmit}
        onSubmitEditPreview={handleOnSubmitPreview}
        onRemoveImages={handleOnRemoveSubmit}
      />
      {openProductPreview && (
        <ProductPreview
          id={id}
          open={openProductPreview}
          onClose={handleOnOpenProductPreview(false)}
        />
      )}
    </>
  )
}
