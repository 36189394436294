import React, { FC } from 'react'

import { Text } from '../../../text'
import { Column } from '../../../column'

import { OrderSliderHeaderColumnProps } from './order-slider-header-column.types'
import { useStyle } from './order-slider-header-column.styles'
import { Row } from '../../../row'

export const OrderSliderHeaderColumn: FC<OrderSliderHeaderColumnProps> = ({
  className = '',
  title,
  titleTx,
  value,
  valueTx
}) => {
  const classes = useStyle()

  return (
    <Column
      alignItems="flex-start"
      justifyContent="flex-start"
      className={`${classes.container} ${className}`}
    >
      <Text
        className={classes.title}
        color="inactive"
        preset="body"
        text={title}
        tx={titleTx}
      />
      {value && (
        <Row
          fullWidth
          justifyContent="flex-start"
          className={classes.textContainer}
        >
          <Text
            className={classes.text}
            color="black"
            preset="h7"
            text={value}
            tx={valueTx}
          />
        </Row>
      )}
    </Column>
  )
}
