import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Content,
  Row,
  Text,
  arrayHasElements,
  CoreItem,
  getListFromList,
  LoadMoreButton,
  DropdownItemData
} from '../../components'
import { useApi, useToastify } from '../../providers'
import {
  Order,
  GetOrdersData,
  OrderFilterInput,
  OrdersFiltersParseJSON,
  OrdersVariables,
  OrderUpdateVariables,
  parseFilterItems,
  OrderDirection,
  OrderSortField
} from '../../services'

import { getQueryParams } from '../helpers'

import { OrderControlList } from './order-contol-list'
import { OrderControlFilters } from './order-control-filters'
import { useStyle } from './order-control.styles'

export const OrderControlPage = () => {
  const { open } = useToastify()
  const location = useLocation()
  const classes = useStyle()

  const FIRST_VARIABLE = 10
  const EQUALS_SYMBOL = '=='
  const { order } = useApi()
  const [variables, changeVariables] = useState<OrdersVariables>({
    first: FIRST_VARIABLE
  })
  const { data: response, refetch, fetchMore } = order.useOrders(variables)
  const { onSubmit: onSubmitUpdate, response: orderUpdateResponse } =
    order.useOrderUpdate()
  const [data, changeData] = useState<GetOrdersData | null | undefined>(
    response
  )
  const [loading, changeLoading] = useState(true)
  const [loadingMore, changeLoadingMore] = useState(false)

  const QueryParams = useMemo(() => {
    return getQueryParams(location.search) as OrderFilterInput
  }, [location.search])

  const QueryParamsOrderId = useMemo(() => {
    return getQueryParams(location.search) as Order
  }, [location.search])

  const getFullOrderId = QueryParamsOrderId.id
    ? QueryParamsOrderId.id + EQUALS_SYMBOL
    : ''

  const { data: orderById } = order.useOrderById({
    id: getFullOrderId
  })

  useEffect(() => {
    if (orderUpdateResponse.data?.orderUpdate.order) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })
    }
  }, [orderUpdateResponse.data?.orderUpdate.order])

  useEffect(() => {
    if (!loadingMore && response) {
      changeData(response)
    }
  }, [loadingMore, response])

  useEffect(() => {
    if (response) {
      changeLoading(false)
      changeLoadingMore(false)
    }
  }, [response?.orders.edges])

  const Filters = useMemo<
    Record<keyof OrdersFiltersParseJSON, CoreItem[]>
  >(() => {
    if (response && response.orders.filters) {
      const filters = parseFilterItems<OrdersFiltersParseJSON>(
        response.orders.filters
      )

      if (filters) {
        return filters
      }
    }

    return {
      companyNames: [],
      customers: [],
      vendors: []
    }
  }, [response?.orders.filters])

  const Data = useMemo(() => {
    if (data) {
      return getListFromList(data?.orders)
    }

    return []
  }, [data?.orders])

  const GetOrderById = () => {
    if (orderById) {
      return orderById.order
    }

    return undefined
  }

  const TotalCount = useMemo(() => {
    if (data) {
      return data.orders.totalCount
    }

    return 0
  }, [data?.orders.totalCount])

  const count = Data.length || 0

  const handleOnChange = (updateVariables: OrderUpdateVariables) => {
    if (onSubmitUpdate) {
      onSubmitUpdate(updateVariables)
    }
  }

  const handleOnChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: search } = event.target
    const nextVariables = {
      ...variables,
      after: undefined,
      filter: {
        ...variables.filter,
        search
      }
    }

    changeVariables(nextVariables)
    changeLoading(true)
    refetch(nextVariables)
  }

  const handleOnNext = () => {
    if (data && arrayHasElements(Data)) {
      const { edges } = data.orders
      const nextVariables = {
        ...variables,
        after: edges[edges.length - 1].cursor
      }

      changeVariables(nextVariables)
      changeLoadingMore(true)

      if (fetchMore) {
        fetchMore({ variables: nextVariables })
      }
    }
  }

  const handleOnSubmitFilters = (filter: OrderFilterInput) => {
    if (refetch) {
      const nextVariables = {
        first: FIRST_VARIABLE,
        filter
      }
      changeLoading(true)
      refetch(nextVariables)
      changeVariables(nextVariables)
    }
  }

  const handleOnResetFilters = () => {
    if (refetch) {
      changeLoading(true)
      refetch({ first: FIRST_VARIABLE, filter: undefined })
    }
  }

  const getSortByValue = (value: string) => {
    if (value) {
      const values = value.split(',')
      return {
        field: values[0] as OrderSortField,
        direction: values[1] as OrderDirection
      }
    }

    return undefined
  }

  const handleOnChangeDropdown = (item: DropdownItemData) => {
    const input = {
      ...variables,
      after: undefined,
      sortBy: getSortByValue(item.value)
    }

    refetch(input)
    changeVariables(input)
  }

  const checkSearchValue = variables.filter?.search === undefined
  const checkOrderById =
    QueryParamsOrderId.id && GetOrderById() && checkSearchValue
  const checkLoadMoreButtonVisible =
    !loading && TotalCount > count && !checkOrderById

  return (
    <Content className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="space-between">
        <Text color="black" preset="h3" text="All Orders" tx="order.title" />
        <OrderControlFilters
          filters={Filters}
          onSubmit={handleOnSubmitFilters}
          onReset={handleOnResetFilters}
        />
      </Row>
      <OrderControlList
        loadingMore={loadingMore}
        loading={loading}
        className={classes.list}
        data={Data}
        dataById={GetOrderById()}
        checkOrderById={checkOrderById}
        defaultSearchValue={QueryParams.search}
        totalCount={TotalCount}
        onChange={handleOnChange}
        onChangeSearch={handleOnChangeSearch}
        onChangeDropdown={handleOnChangeDropdown}
      />

      {checkLoadMoreButtonVisible && (
        <LoadMoreButton
          className={classes.loadMore}
          loading={loadingMore}
          onLoadNext={handleOnNext}
        />
      )}
    </Content>
  )
}
