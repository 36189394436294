import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {},
  header: {
    width: 'calc(100% - 48px)',
    padding: 24
  },

  vendor: {
    width: 'calc(100% - 48px)',
    padding: '24px 24px 80px 24px'
  },
  vendorImage: {
    width: 120,
    height: 120
  },
  countryName: {
    marginLeft: 16,
    '& > *:not(:first-child)': {
      marginTop: 12
    }
  },
  flag: {
    width: 48
  },
  secondaryText: {},
  separator: {
    height: 1,
    marginTop: 24,
    backgroundColor: theme.colors.shadow
  },
  buttonsRow: {
    marginTop: 54,
    width: 'calc(100% - 72px)',
    padding: '0 36px'
  },
  btnContact: {},
  btnFeedback: {
    padding: '14px 24px',
    border: `2px solid ${theme.colors.yellow}`,
    marginLeft: 16
  }
}))
