import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Row, Text, LightTheme, isTableItem } from '../../../components'
import { concatClassNames } from '../../views'

import { useStyle } from './user-list-customers-table-item.styles'
import { UserListCustomersTableItemProps } from './user-list-customers-table-item.types'

export const UserListCustomersTableItem: FC<UserListCustomersTableItemProps> =
  ({
    id,
    firstName,
    lastName,
    phone,
    email,
    defaultShippingAddress,
    onClick
  }) => {
    const theme: LightTheme = useTheme()
    const classes = useStyle({ theme })

    const name = firstName ? `${firstName} ${lastName}` : '-'

    const columnBig = concatClassNames(
      classes.tableColumn,
      classes.tableColumnBig
    )
    const columnMin = concatClassNames(
      classes.tableColumn,
      classes.tableColumnmMin
    )

    const handleOnClick = () => {
      if (onClick) {
        onClick(id)
      }
    }

    return (
      <Row
        fullWidth
        className={classes.tableRow}
        justifyContent="space-between"
        onClick={handleOnClick}
      >
        <Row
          className={concatClassNames(classes.firstColumn, classes.tableColumn)}
          justifyContent="flex-start"
        >
          <Text color="black" preset="body" text={name} />
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <Text
            color="black"
            preset="body"
            text={isTableItem(defaultShippingAddress?.country.country)}
          />
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <Text
            color="black"
            preset="body"
            text={isTableItem(defaultShippingAddress?.city)}
          />
        </Row>
        <Row className={columnMin} justifyContent="flex-start">
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={isTableItem(phone)}
          />
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <Text
            color="black"
            preset="body"
            text={isTableItem(defaultShippingAddress?.streetAddress1)}
          />
        </Row>
        <Row className={columnBig} justifyContent="flex-start">
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={isTableItem(email)}
          />
        </Row>
      </Row>
    )
  }
