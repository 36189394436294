import React, { ChangeEvent, FC, useState } from 'react'

import { Text } from '../../text'
import { QuestionModal } from '../../question-modal'
import { TextField } from '../../text-field'
import { Column } from '../../column'

import { useStyle } from './order-shipping-modal.styles'
import { OrderShippingModalProps } from './order-shipping-modal.types'

export const OrderShippingModal: FC<OrderShippingModalProps> = ({
  onClose,
  onSubmit
}) => {
  const [trackingNumber, changeTrackingNumber] = useState('')
  const classes = useStyle()

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    changeTrackingNumber(value)
  }

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit(trackingNumber)
    }
  }

  return (
    <QuestionModal
      title="Product Shipped"
      titleTx="orderProductShipped.modal.title"
      disabled={!trackingNumber}
      onClose={onClose}
      onSubmit={handleOnSubmit}
    >
      <Column fullWidth justifyContent="flex-start">
        <Text
          className={classes.description}
          color="inactive"
          preset="body"
          text="Please enter tracking number for this order."
          tx="orderProductShipped.modal.description"
        />
        <TextField
          className={classes.field}
          label="Tracking Number"
          labelTx="orderProductShipped.modal.trackingNumber"
          onChange={handleOnChange}
        />
      </Column>
    </QuestionModal>
  )
}
