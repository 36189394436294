import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles(
  (theme) => ({
    arrow: {
      marginLeft: theme.spacing(2),
      transition: `${theme.transitions.duration.standard}ms`
    },
    avatar: {
      '&&': {
        height: 32,
        width: 32
      }
    },
    darkThemeSwitch: {
      [theme.breakpoints.down('sm')]: {
        marginRight: -theme.spacing(1.5)
      },
      marginRight: theme.spacing(2)
    },
    header: {
      [theme.breakpoints.down('sm')]: {
        height: 88,
        marginBottom: 0
      },
      display: 'flex',
      height: 40
    },
    popover: {
      zIndex: 1
    },
    rotate: {
      transform: 'rotate(180deg)'
    },
    spacer: {
      flex: 1
    },
    userBar: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-end',
        flexDirection: 'column-reverse',
        overflow: 'hidden'
      },
      alignItems: 'center',
      display: 'flex'
    },
    userChip: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 24,
      boxShadow: '1px 1px 4px #E0E0E0',
      color: theme.palette.text.primary,
      height: 40,
      padding: theme.spacing(0.5)
    },
    userMenuContainer: {
      position: 'relative'
    },
    userMenuItem: {
      textAlign: 'right',
      width: '100%'
    },
    viewContainer: {
      marginTop: 24
    }
  }),
  {
    name: 'AppLayout'
  }
)
