import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  activity: {
    marginTop: 20,
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${theme.colors.shadow}`,
    justifyContent: 'flex-start'
  },
  activityTitle: {
    padding: 16,
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.shadow}`
  }
}))
