import React, { useState, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  Column,
  Row,
  Text,
  Button,
  ICONS,
  SubscriptionPlan,
  SubscriptionBenefit,
  ConfirmModal,
  SubscriptionErrorModal
} from '../../components'
import { useUser } from '../../hooks'
import { useApi } from '../../providers'
import { RedirectStatus, RedirectSubscriptionQueryParams } from '../../services'
import { getDefaultAuthorizedRoute } from '../../utils'
import { OptionsNavigatorPathes } from '../cabinet-navigator'

import { getQueryParams, getSubscriptionInput } from '../helpers'
import { getFormatedDescription } from '../views'

import { useStyle } from './subscription.styles'

export const SubscriptionPage = () => {
  const first = 3

  const location = useLocation()
  const history = useHistory()
  const { subscription: subscriptionApi } = useApi()
  const { user, changeSubscription } = useUser()

  const [selected, changeSelected] = useState('')
  const [selectedPaypalPlanId, changeSelectedPaypalPlanId] = useState('')
  const [openConfirmModal, changeOpenConfirmModal] = useState(false)
  const [openErrorModal, changeOpenErrorModal] = useState(false)

  const classes = useStyle()
  const { data } = subscriptionApi.useSubscriptionTypes({ first })
  const { response, onSubmit } = subscriptionApi.useCreateSubscription()

  const icons = [ICONS.infinity, ICONS.phonelink, ICONS.localATM]

  const notFreeSubscriptionTypes = useMemo(() => {
    if (data) {
      const { edges } = data.subscriptionTypes
      return edges.filter((type) => type.node.name !== 'Free')
    }
    return []
  }, [data])

  useEffect(() => {
    const active =
      data &&
      data?.subscriptionTypes.edges.find(
        (item) =>
          selectedPaypalPlanId === item.node.paypalPlanId &&
          item.node.name !== 'Free'
      )
    if (response.data) {
      const { createPaypalSubscription } = response.data
      if (active) {
        if (createPaypalSubscription.link) {
          window.open(createPaypalSubscription.link, '_self')
        }
      } else {
        history.push(getDefaultAuthorizedRoute(user))
      }
    }
  }, [response.data, data])

  const Benefits = useMemo(() => {
    const selectedSubscriptionType = notFreeSubscriptionTypes.find(
      (type) => type.node.id === selected
    )

    if (selectedSubscriptionType) {
      return getFormatedDescription(selectedSubscriptionType.node.description)
    }

    return [
      'Able to sell unlimited items',
      'Free access to the VTMT application through the Marketplace',
      '20% referral fee depending on the items sold.'
    ]
  }, [selected, notFreeSubscriptionTypes])

  useEffect(() => {
    const params = getQueryParams(
      location.search
    ) as RedirectSubscriptionQueryParams

    if (params.event === RedirectStatus.SUCCESS) {
      if (data && changeSubscription) {
        const subscription = data.subscriptionTypes.edges.find(
          ({ node }) => node.paypalPlanId === params.subscription_platform_id
        )

        changeSubscription(subscription?.node)
      }

      changeOpenConfirmModal(true)
    }

    if (params.event === RedirectStatus.ERROR) {
      changeOpenErrorModal(true)
    }
  }, [location.search, data])

  const handleOnSelectSubscription = (id: string) => () => {
    changeSelected(id)
    const selectedSubscriptionType = notFreeSubscriptionTypes.find(
      (type) => type.node.id === id
    )

    if (selectedSubscriptionType) {
      changeSelectedPaypalPlanId(selectedSubscriptionType.node.paypalPlanId)
    }
  }

  const handleOnFreeSubscriptionClick = () => {
    const input = getSubscriptionInput('', '', '')
    onSubmit(input)

    changeSelectedPaypalPlanId('')
  }

  const handleCreatePaypalSubscription = () => {
    const selectedSubscriptionType = notFreeSubscriptionTypes.find(
      (type) => type.node.paypalPlanId === selectedPaypalPlanId
    )

    if (selectedSubscriptionType) {
      const { id } = selectedSubscriptionType.node
      const input = getSubscriptionInput(
        selectedPaypalPlanId,
        id,
        OptionsNavigatorPathes.SUBSCRIPTION
      )
      onSubmit(input)
    }
  }

  const handleOnOpenConfirmModal = () => {
    changeOpenConfirmModal(!openConfirmModal)

    history.push(getDefaultAuthorizedRoute(user))
  }
  const handleOnOpenErrorModal = () => {
    changeOpenErrorModal(!openErrorModal)
  }

  return (
    <Column fullWidth className={classes.container}>
      <Text preset="h1" text="Subscription" className={classes.title} />
      <Text
        className={classes.subtitle}
        color="inactive"
        preset="h7"
        text="Benefit Includes:"
      />
      <Row alignItems="center" className={classes.subcsriptionContainer}>
        <Column alignItems="flex-start">
          {Benefits.map((benefit, index) => (
            <SubscriptionBenefit
              key={Math.random()}
              src={icons[index]}
              text={benefit}
            />
          ))}
        </Column>
        <Row className={classes.priceRow}>
          {notFreeSubscriptionTypes.map(({ node }) => {
            const { id, name, price } = node
            const active = selected === id

            return (
              <SubscriptionPlan
                key={`subscription_price_${name}`}
                name={name}
                price={price}
                selected={active}
                onClick={handleOnSelectSubscription(id)}
              />
            )
          })}
        </Row>
      </Row>
      <Column className={classes.buttonContainer}>
        <Button
          className={classes.submit}
          preset="primary"
          text="CONFIRM"
          textColor="white"
          textPreset="h5"
          onClick={handleCreatePaypalSubscription}
        />
        <Button
          className={classes.submit}
          preset="secondaryBorder"
          text="FREE VERSION"
          textColor="yellow"
          textPreset="h5"
          onClick={handleOnFreeSubscriptionClick}
        />
      </Column>
      {openConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          titleTx="subscriptionConfirm.modal.title"
          description="Your payment is successful!"
          descriptionTx="subscriptionConfirm.modal.description"
          secondaryDescription="Congratulate you with the Ovaflopick Premium plan purchase!"
          secondaryDescriptionTx="subscriptionConfirm.modal.secondaryDescription"
          onClose={handleOnOpenConfirmModal}
        />
      )}
      {openErrorModal && (
        <SubscriptionErrorModal
          title="Ooops..."
          titleTx="subscriptionError.modal.title"
          description="Your payment isn't successful!"
          descriptionTx="subscriptionError.modal.description"
          secondaryDescription="Please check your payment method or balance and try again."
          secondaryDescriptionTx="subscriptionError.modal.secondaryDescription"
          onClose={handleOnOpenErrorModal}
        />
      )}
    </Column>
  )
}
