import { VendorBanner } from '../../../services'

export const clearInputForBannerUpdate = (prevItem: VendorBanner) => {
  return {
    ...prevItem,
    updatedAt: undefined,
    createdAt: undefined
  }
}

export const activateVendorBanner =
  (state: boolean) => (prevItem: VendorBanner) => {
    const item = {
      ...prevItem,
      isVisible: state
    }

    return clearInputForBannerUpdate(item)
  }
