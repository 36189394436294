import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'
import StarRatingComponent from 'react-star-rating-component'

import { useDefaultValue } from '../../hooks/useDefaultValue'

import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Color, LightTheme } from '../Theme'

import { RatingStarsProps } from './rating-stars.types'
import { useStyle } from './rating-stars.styles'

export const RatingStarsComponent: FC<RatingStarsProps> = ({
  className = '',
  width = '20',
  height = '20',
  rating,
  defaultRating = 0,
  onChange,
  onHover,
  edit
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()
  const [ratingStar, changeRatingStar] = useState(defaultRating)

  const Value = useDefaultValue<number>({
    outerValue: rating,
    value: ratingStar
  })

  const handleOnHover = (nextValue: number) => {
    changeRatingStar(nextValue)

    if (onHover) {
      onHover(nextValue)
    }
  }

  const handleOnRating = (nextValue: number) => {
    changeRatingStar(nextValue)

    if (onChange) {
      onChange(nextValue)
    }
  }

  const handleOnClickStar = (nextValue: number) => {
    const active = nextValue <= Value
    const color: Color = active ? 'yellow' : 'black'
    const src = active ? ICONS.starFilled : ICONS.starBorder
    return (
      <Icon
        className={classes.icon}
        fill={theme.colors[color]}
        src={src}
        width={width}
        height={height}
      />
    )
  }

  return (
    <Row className={className}>
      <StarRatingComponent
        name="rate1"
        starCount={5}
        value={Value}
        starColor="red"
        renderStarIcon={handleOnClickStar}
        onStarClick={handleOnRating}
        onStarHover={handleOnHover}
        editing={edit}
      />
    </Row>
  )
}
