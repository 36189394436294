import React, { ChangeEventHandler, useMemo, useState } from 'react'

import { Column } from '../../components'
import { SortVariables, useSort, useTable } from '../../hooks'
import { useApi } from '../../providers'
import {
  FeedbackOrderField,
  FeedbacksActivityFiltersParseJSON,
  FeedbackVariables,
  parseFilterItems
} from '../../services'

import { FeedbackActivityFilters } from './feedbacks-activity-filter'
import { FeedbackActivityTable } from './feedbacks-activity-table'
import { useStyle } from './feedbacks-activity.styles'

const rowCounts = [5, 10, 20, 30]

export const FeedbackActivityPage = () => {
  const classes = useStyle()

  const defaultRowCountsValue = rowCounts[2]
  const [feedbackVariables, changeFeedbackVariables] =
    useState<FeedbackVariables>({
      first: defaultRowCountsValue
    })
  const { feedback } = useApi()

  const { data, refetch } = feedback.useFeedbacks(feedbackVariables)

  const {
    variables,
    changeVariables,
    Data,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSubmitFilters,
    onResetFilters,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.feedbacks,
    defaultRowCounts: defaultRowCountsValue,
    defaultVariables: feedbackVariables
  })

  const Filters = useMemo(() => {
    if (data && data.feedbacks.filters) {
      const filters = parseFilterItems<FeedbacksActivityFiltersParseJSON>(
        data.feedbacks.filters
      )

      if (filters) {
        return filters
      }
    }

    return {
      feedbackObjects: [],
      customers: [],
      feedbackTypes: []
    }
  }, [data])

  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target

    const nextVariables = {
      ...variables,
      filter: {
        search: value
      }
    }

    changeVariables(nextVariables)

    if (refetch) {
      refetch(nextVariables)
    }
  }

  const handleOnSort = (sortVariables: SortVariables<FeedbackOrderField>) => {
    refetch({
      ...sortVariables,
      before: undefined,
      after: undefined,
      last: undefined
    })

    onResetPageState()
    changeFeedbackVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: feedbackVariables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <FeedbackActivityTable
        className={classes.table}
        data={Data}
        onChangeSearch={handleOnSearch}
        rowCounts={rowCounts}
        paginationState={paginationState}
        onChangeRowCount={onChangeRowCount}
        onNextPage={onNextPage}
        onPrevioslyPage={onPrevioslyPage}
        onChangePage={onChangePage}
        onSort={onSort}
      >
        <FeedbackActivityFilters
          className={classes.filterButton}
          filters={Filters}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
        />
      </FeedbackActivityTable>
    </Column>
  )
}
