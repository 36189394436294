import React, { ChangeEvent, FC, useMemo, useState } from 'react'

import {
  ColorPicker,
  Icon,
  ICONS,
  QuestionModal,
  Text,
  TextArea,
  TextField
} from '../../../components'
import { Color, ProductCreateColorInput } from '../../../services'

import { useStyle } from './product-color-item-modal.styles'
import { ProductColorItemModalProps } from './product-color-item-modal.types'

export const ProductColorItemModal: FC<ProductColorItemModalProps> = ({
  colors = [],
  activeData,
  onClose,
  onSubmit
}) => {
  const classes = useStyle()
  const [color, changeColor] = useState<
    Partial<ProductCreateColorInput> | undefined
  >(activeData)

  const isDisabled =
    !color?.color ||
    !color.description ||
    !color.title ||
    !color.extraPrice ||
    color.extraPrice < 0 ||
    !color.extraProductionDays

  const DefaultColor = useMemo(() => {
    const current = colors?.find((colorItem) => colorItem.code === color?.code)

    return current
  }, [colors, color?.code])

  const handleOnChange =
    (prop: keyof ProductCreateColorInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      changeColor({
        ...color,
        [prop]: value
      })
    }

  const handleOnChangeNumber =
    (prop: keyof ProductCreateColorInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      changeColor({
        ...color,
        [prop]: Number(value)
      })
    }

  const handleOnChangeColor = (item: Color) => {
    changeColor({
      ...color,
      color: item.id
    })
  }

  const handleOnSubmit = () => {
    const close = onClose(false)

    if (onSubmit) {
      onSubmit(color as ProductCreateColorInput)
    }
    close()
  }

  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />

  return (
    <>
      <QuestionModal
        disabled={isDisabled}
        title="Edit Color Variant"
        titleTx="item.form.edit.color.title"
        successText="Apply"
        successTx="item.form.create.color.apply"
        onClose={onClose(false)}
        onSubmit={handleOnSubmit}
      >
        <Text
          color="inactive"
          preset="body"
          text="Please fill in all fields."
          tx="item.form.create.color.description"
        />
        <TextField
          className={classes.field}
          maxLength={100}
          defaultValue={color?.title}
          label="Title"
          labelTx="item.form.create.color.title.input"
          onChange={handleOnChange('title')}
        />
        <TextArea
          className={classes.area}
          preset="border"
          maxLength={500}
          defaultValue={color?.description}
          label="Description"
          labelTx="item.form.create.color.description"
          onChange={handleOnChange('description')}
        />
        <ColorPicker
          className={classes.field}
          data={colors}
          defaultValue={DefaultColor}
          onChange={handleOnChangeColor}
        />
        <TextField
          className={classes.field}
          defaultValue={color?.extraPrice}
          label="Extra Price"
          labelTx="item.form.create.color.price.input"
          type="number"
          rightElement={dollarIcon}
          onChange={handleOnChangeNumber('extraPrice')}
        />
        <TextField
          className={classes.field}
          defaultValue={color?.extraProductionDays}
          label="Extra Production Days"
          labelTx="item.form.create.color.days.input"
          type="number"
          onChange={handleOnChangeNumber('extraProductionDays')}
        />
      </QuestionModal>
    </>
  )
}
