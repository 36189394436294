import React, { FC, useState } from 'react'

import { Row } from '../row'
import { Text } from '../text'

import { SortRightCaret } from './sort-right-caret'
import { SortItemProps } from './sort-item.types'
import { useStyle } from './sort-item.styles'

export const SortItem: FC<SortItemProps> = ({
  className = '',
  defaultOpen = false,
  textClassName = '',
  name,
  nameTx,
  preset = 'h6',
  rightIconName,
  onClick
}) => {
  const [open, changeOpen] = useState<boolean>(defaultOpen)
  const classes = useStyle()

  const handleOnClickItem = (state: boolean) => {
    changeOpen(state)
  }

  const handleOnCaretClick = () => {
    if (onClick && !open) {
      handleOnClickItem(true)
    } else {
      handleOnClickItem(false)
    }
  }

  const handleOnClick = () => {
    if (onClick) {
      onClick(!open)
      handleOnCaretClick()
    }
  }

  return (
    <Row
      className={`${classes.container} ${className}`}
      fullWidth
      justifyContent="flex-start"
      onClick={handleOnClick}
    >
      <Text className={textClassName} text={name} tx={nameTx} preset={preset} />
      <Row className={classes.caret}>
        <SortRightCaret
          open={open}
          iconName={rightIconName}
          onClick={handleOnCaretClick}
        />
      </Row>
    </Row>
  )
}
