import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  characteristics: {
    '& > :not(:first-child)': {
      marginTop: 12
    }
  },
  slider: {
    border: `1px solid ${theme.colors.shadow}`,
    '& > div:first-child': {
      padding: '12px 16px'
    }
  },
  optionsRow: {
    '&:not(:first-child)': {
      marginTop: 4
    }
  },
  detailsColumn: {
    marginTop: 24
  },
  sliderContent: {
    marginLeft: 16
  },
  text: {
    textAlign: 'left'
  }
}))
