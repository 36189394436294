import { ENV } from '../../config'
import {
  CreateSubscriptionPaypalVariables,
  RedirectStatus
} from '../../services'

export const getEvent = (status: RedirectStatus) => `event=${status}`
export const getSubscriptionId = (id: string) =>
  `subscription_platform_id=${id}`

export const getParams = (status: RedirectStatus, id: string) =>
  `?${getEvent(status)}&${getSubscriptionId(id)}`

export const getSubscriptionInput = (
  paypalPlanId: string,
  subscriptionId: string,
  additionalPath: string
) => {
  const { href } = window.location
  const link =
    ENV === 'development'
      ? `https://dashboard.dev.marketplace.consultinar.com${
          additionalPath || ''
        }`
      : href

  const input: CreateSubscriptionPaypalVariables = {
    returnUrl: `${link}${getParams(RedirectStatus.SUCCESS, subscriptionId)}`,
    cancelUrl: `${link}${getParams(RedirectStatus.ERROR, subscriptionId)}`,
    paypalPlanId
  }

  return input
}
