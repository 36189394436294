import { defineMessages } from 'react-intl'

export default defineMessages({
  exportFailedTitle: {
    defaultMessage: 'Product Export Failed',
    id: 'yHeZRQ'
  },
  exportFinishedText: {
    defaultMessage:
      'Product export has finished and was sent to your email address.',
    id: 'CJEIRC'
  },
  exportFinishedTitle: {
    defaultMessage: 'Exporting CSV finished',
    id: 'JTcz2G',
    description: 'csv file exporting has finished, header'
  },
  invoiceGenerateFinishedText: {
    defaultMessage:
      'Requested Invoice was generated. It was added to the top of the invoice list on this view. Enjoy!',
    id: 'ryAyPr'
  },
  invoiceGenerateFinishedTitle: {
    defaultMessage: 'Invoice Generated',
    id: 'i+Vox0',
    description: 'invoice generating has finished, header'
  },
  invoiceGenerationFailedTitle: {
    defaultMessage: 'Invoice Generation',
    id: 'Dhherd',
    description: 'dialog header, title'
  }
})
