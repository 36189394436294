import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { LightTheme } from '../Theme'

import { useStyle } from './attention.styles'
import { AttentionProps } from './attention.types'

export const Attention: FC<AttentionProps> = ({
  className = '',
  color,
  icon,
  text,
  children,
  tx
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ color, theme })
  return (
    <Row fullWidth className={classes.overflow}>
      <Row
        fullWidth
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
      >
        <Icon src={icon} />
        <Text
          className={classes.text}
          color={color}
          preset="body"
          text={text}
          tx={tx}
        />
        {children}
      </Row>
    </Row>
  )
}
