import { ListVariables, OrderDirection, SortBy } from '../services'

export interface SortVariables<FieldEnum> extends ListVariables<any> {
  sortBy?: SortBy<FieldEnum>
}

export type UseSortProps<FieldEnum> = {
  variables: SortVariables<FieldEnum>
  onSort?: (variables: SortVariables<FieldEnum>) => void
}

export const useSort = <FieldEnum>({
  variables,
  onSort
}: UseSortProps<FieldEnum>) => {
  const defaultInput = (
    orderDirection: OrderDirection,
    sortField: FieldEnum
  ) => {
    const input = {
      ...variables,
      sortBy: { direction: orderDirection, field: sortField }
    }
    return input
  }

  const handleOnSort = (
    sortField: FieldEnum,
    orderDirection: OrderDirection
  ) => {
    if (onSort) {
      onSort(defaultInput(orderDirection, sortField))
    }
  }

  return { onSort: handleOnSort }
}
