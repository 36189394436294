import { createUseStyles } from 'react-jss'

import { LightTheme } from '..'
import { CategoryNavItemStyle } from './category-nav-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    flex: 1,
    padding: '16px 24px 4px',
    borderBottom: ({ active }: CategoryNavItemStyle) =>
      active ? `4px solid ${theme.colors.yellow}` : 'none',
    cursor: 'pointer',
    '&:hover > span': {
      color: theme.colors.black
    },
    '&:hover > svg': {
      fill: theme.colors.yellow
    }
  },
  iconContainer: {
    paddingLeft: 0
  },
  text: {
    marginLeft: 12
  }
}))
