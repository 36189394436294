import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  price: {
    marginTop: 8
  },
  discount: {
    textDecoration: 'line-through'
  },
  discountPrice: {
    marginLeft: 16
  },
  dash: {
    padding: '0 6px'
  },
  minPrice: {
    marginLeft: 8
  }
})
