import React from 'react'

import { Row } from '../../row'
import { Icon, ICONS } from '../../icon'
import { RightCaretProps } from './right-caret.types'
import { useStyle } from './right-caret.styles'

export const RightCaret = ({
  open,
  custom,
  iconName,
  onClick
}: RightCaretProps) => {
  const classes = useStyle({ open, custom })

  return (
    <Row onClick={onClick}>
      {iconName ? (
        <Icon src={ICONS.filledArrow} color="black" />
      ) : (
        <Icon
          className={classes.caretDown}
          src={ICONS.filledArrow}
          color="black"
        />
      )}
    </Row>
  )
}
