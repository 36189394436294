import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import {
  arrayHasElements,
  BackBreadcrumbs,
  Button,
  Column,
  Icon,
  ICONS,
  LoadPhotoContainer,
  ModalBanerPreview,
  RadioBox,
  RadioItemWithIdProps,
  Row,
  Section,
  Text,
  TextField
} from '../../components'
import { useStyle } from './vendor-banner-form.styles'

import { VendorBannerFormProps } from './vendor-banner-form.types'
import { VendorBannerCreateInput } from '../../services'
import { LightTheme } from '../../components/Theme'

export const defaultValue: VendorBannerCreateInput = {
  name: '',
  title: '',
  subTitle: '',
  buttonText: '',
  buttonLink: '',
  isVisible: true
}

export const visibilityRadioData: RadioItemWithIdProps[] = [
  { id: '1', name: 'Visible', nameTx: 'banner.visibility.active' },
  { id: '0', name: 'Invisible', nameTx: 'banner.visibility.inactive' }
]

export const VendorBannerForm: FC<VendorBannerFormProps> = ({
  vendorBanner,
  title,
  activeBannersCount,
  titleTx,
  onChange,
  onSumbit
}) => {
  const history = useHistory()
  const maxCount = 10
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const defaultBanner = vendorBanner
    ? {
        ...vendorBanner,
        id: undefined,
        images: undefined
      }
    : undefined
  const [value, changeValue] = useState<VendorBannerCreateInput>(
    defaultBanner || defaultValue
  )
  const [openBannerPreview, changeOpenBannerPreview] = useState(false)

  const [prevBannerState, changePrevBannerState] =
    useState<VendorBannerCreateInput>(defaultValue)

  const [images, changeImages] = useState<File[]>([])

  const Images = useMemo(() => {
    return vendorBanner?.images.map((image) => image.url)
  }, [vendorBanner])

  const handleOnChangeImage = (nextImages: File[]) => {
    changeImages(nextImages)
  }

  const handleOnChangeInput =
    (prop: keyof VendorBannerCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value: valueType } = event.target
      const nextBanner: VendorBannerCreateInput = {
        ...value,
        [prop]: valueType
      }

      const nextPrevBanner: VendorBannerCreateInput = {
        ...prevBannerState,
        [prop]: valueType
      }

      changePrevBannerState(nextPrevBanner)
      changeValue(nextBanner)

      if (onChange) {
        onChange(nextBanner)
      }
    }

  const BannerImage = useMemo(() => {
    if (images[0]) {
      return URL.createObjectURL(images[0])
    }

    return vendorBanner?.images[0].url
  }, [images[0]])

  const handleOnOpenBannerPreviev = (state: boolean) => () => {
    changeOpenBannerPreview(state)
  }

  const handleOnBannerPreviewClick = () => {
    const openModal = handleOnOpenBannerPreviev(true)

    openModal()
  }

  const handleOnChangeVisibility = (nextValue: string) => {
    const isVisible = Boolean(Number(nextValue))
    changeValue({
      ...value,
      isVisible
    })
  }

  const handleOnSubmit = () => {
    const image = arrayHasElements(images) ? images[0] : undefined
    onSumbit(value, image)
  }

  const countsValues = {
    count: 5,
    maxCount
  }
  const maxCountsValues = {
    maxCount
  }

  const visibleId = String(Number(value.isVisible))

  const isDisabled =
    !value.name ||
    !value.title ||
    !value.subTitle ||
    !value.buttonText ||
    !value.buttonLink ||
    !BannerImage

  return (
    <>
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.container}
      >
        <BackBreadcrumbs text="Banners" tx="banner.breadcrumbs.back" />
        <Text className={classes.title} preset="h3" text={title} tx={titleTx} />
        <Row fullWidth alignItems="flex-start" className={classes.content}>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.leftColumn}
          >
            <Section
              className={classes.section}
              title="General Information"
              titleTx="vendor.banner.text.fields.title"
            >
              <Column
                alignItems="flex-start"
                className={classes.sectionAdditionContainer}
              >
                <TextField
                  className={classes.field}
                  defaultValue={defaultBanner?.title}
                  preset="border"
                  label="Title"
                  labelTx="vendor.banner.title"
                  onChange={handleOnChangeInput('title')}
                />
                <TextField
                  className={classes.field}
                  defaultValue={defaultBanner?.subTitle}
                  preset="border"
                  label="Subtitle"
                  labelTx="vendor.banner.subtitle"
                  onChange={handleOnChangeInput('subTitle')}
                />
              </Column>
            </Section>
            <LoadPhotoContainer
              className={classes.section}
              defaultValue={Images}
              title="Image"
              titleTx="banner.image"
              onChange={handleOnChangeImage}
            />
            <Section
              className={classes.section}
              title="Button"
              titleTx="banner.button.title"
            >
              <Column
                alignItems="flex-start"
                className={classes.sectionAdditionContainer}
              >
                <TextField
                  className={classes.field}
                  defaultValue={defaultBanner?.buttonText}
                  preset="border"
                  label="Button Text"
                  labelTx="banner.button.text"
                  onChange={handleOnChangeInput('buttonText')}
                />
                <TextField
                  className={classes.field}
                  defaultValue={defaultBanner?.buttonLink}
                  preset="border"
                  maxLength={200}
                  label="Link for Button"
                  labelTx="banner.button.link"
                  onChange={handleOnChangeInput('buttonLink')}
                />
              </Column>
            </Section>
          </Column>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.rightColumn}
          >
            <Section
              className={classes.section}
              title="Organize Banner"
              titleTx="vendor.banner.name.section.title"
            >
              <Column
                alignItems="flex-start"
                className={classes.sectionAdditionContainer}
              >
                <TextField
                  className={classes.field}
                  defaultValue={defaultBanner?.name}
                  preset="border"
                  label="Name"
                  labelTx="vendor.banner.name"
                  onChange={handleOnChangeInput('name')}
                />
              </Column>
            </Section>
            <Section
              className={classes.visibility}
              title="Visibility"
              titleTx="banner.visibility.title"
            >
              <RadioBox
                active={visibleId}
                className={classes.sectionAdditionContainer}
                data={visibilityRadioData}
                onChange={handleOnChangeVisibility}
              />
              <Column
                className={classes.visibleTitleContainer}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Text
                  className={classes.visibleTitle}
                  color="black"
                  preset="h5"
                  text={`Now active ${activeBannersCount}/${maxCount} Banners!`}
                  tx="vendor.banner.active.counts"
                  values={countsValues}
                />
                <Text
                  className={classes.visibleDescription}
                  color="inactive"
                  preset="title"
                  text={`There can be up ${maxCount} Banners at the same time.`}
                  tx="vendor.banner.active.max.count"
                  values={maxCountsValues}
                />
              </Column>
            </Section>
          </Column>
        </Row>
      </Column>
      <Row fullWidth className={classes.submitContainer}>
        <Column />
        <Row fullWidth justifyContent="space-between">
          <Row className={classes.preview} onClick={handleOnBannerPreviewClick}>
            <Icon src={ICONS.removeRedEye} />
          </Row>
          <Row>
            <Button
              className={classes.button}
              color="yellow"
              preset="fixedBorderButton"
              textColor="yellow"
              textPreset="h5"
              text="BACK"
              tx="banner.back"
              onClick={history.goBack}
            />
            <Button
              className={classes.button}
              color="yellow"
              disabled={isDisabled}
              preset="fixedPrimaryButton"
              textColor="white"
              textPreset="h5"
              text="SAVE"
              tx="banner.save"
              onClick={handleOnSubmit}
            />
          </Row>
        </Row>
      </Row>
      <ModalBanerPreview
        image={BannerImage}
        title={value?.subTitle}
        mainHeading={value?.title}
        buttonText={value.buttonText}
        open={openBannerPreview}
        onClose={handleOnOpenBannerPreviev(false)}
      />
    </>
  )
}
