import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  avatar: {
    marginLeft: 16,
    minWidth: 48
  },
  bottomRow: {
    padding: 16,
    width: '100%'
  },
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`
  },
  dropdownRow: {
    marginLeft: 8
  },
  header: {
    width: '100%'
  },
  icon: {
    marginLeft: 8
  },
  search: {
    width: '100%'
  },
  filter: {
    marginLeft: 16,
    height: 56,
    width: 360
  },
  tableTopContainer: {
    padding: 16
  },
  tableColumn: {
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    flex: 1,
    height: '100%',
    padding: 8,
    minWidth: 156
  },
  tableColumnBig: {
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    height: '100%',
    padding: 12,
    minWidth: 210
  },
  remove: {
    cursor: 'pointer',
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  table: {
    overflowX: 'auto'
  },
  firstColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    background: theme.colors.greyFon,
    borderRight: `1px solid ${theme.colors.shadow}`
  }
}))
