import { LightTheme } from '../Theme'
import { IconButtonProps } from './icon-button.types'

export const iconButtonPresets = (theme: LightTheme) => ({
  default: {
    backgroundColor: ({
      disabled,
      backgroundColor = 'yellow'
    }: IconButtonProps) =>
      disabled ? theme.colors.shadow : theme.colors[backgroundColor],
    borderRadius: 48,
    padding: 12
  },
  transparent: {
    backgroundColor: () => 'transparent'
  }
})
