import React, { FC } from 'react'

import { SummaryRow } from '../summary-row'

import { OptionsSummaryRowProps } from './options-summary-row.types'

export const OptionsSummaryRow: FC<OptionsSummaryRowProps> = ({
  options,
  quantity,
  currency
}) => {
  return (
    <>
      {options?.map((item, index) => {
        const priceQuantity = item && quantity ? item.extraPrice * quantity : 0

        return (
          item && (
            <SummaryRow
              key={`productOptionVariant_${item?.title}_${index}`}
              title={item?.title}
              price={priceQuantity}
              currency={currency}
            />
          )
        )
      })}
    </>
  )
}
