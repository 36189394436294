import React, { FC, useMemo, useState } from 'react'

import { Button, getFullName, Section, VendorItem } from '../../../components'
import { useDebounceInput } from '../../../hooks'
import { useApi } from '../../../providers'
import { getItemsFromEdges, User } from '../../../services'

import { EmptySection } from '../empty-section'
import { VendorPickModal } from '../vendor-pick-modal'

import { useStyle } from './vendor-input.styles'
import { VendorInputProps } from './vendor-input.types'

const FIRST = 100

export const VendorInput: FC<VendorInputProps> = ({
  vendorId: defaultVendor,
  vendor: vendorValue,
  onChange
}) => {
  const { vendor: vendorApi } = useApi()
  const { data, refetch, fetchMore } = vendorApi.useVendorListData({
    first: FIRST
  })
  const classes = useStyle()

  const Data = useMemo<User[]>(() => {
    if (data) {
      return getItemsFromEdges(data.vendors)
    }

    return []
  }, [data?.vendors])

  const TotalCount = useMemo<number>(() => {
    if (data) {
      return data.vendors.totalCount
    }

    return 0
  }, [data?.vendors.totalCount])

  const Vendor = useMemo(() => {
    return Data.find((user) => user.id === defaultVendor) || vendorValue
  }, [defaultVendor, Data])

  const [vendor, changeVendor] = useState<User | undefined>(Vendor)
  const [open, changeOpen] = useState(false)

  const defaultSearchValue = {
    first: FIRST,
    filter: {
      search: undefined
    }
  }

  const handleOnSearch = (search: string) => {
    refetch({ first: FIRST, filter: { search } })
  }

  const { onChange: onChangeSearch } = useDebounceInput({
    onChange: handleOnSearch
  })

  const handleOnChangeSearch = (value: string) => {
    onChangeSearch(value)
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnChange = (vendorNext: User) => {
    const close = handleOnOpen(false)

    changeVendor(vendorNext)
    close()
    refetch(defaultSearchValue)

    if (onChange) {
      onChange(vendorNext.id)
    }
  }

  const handleOnLoadMore = () => {
    if (data && fetchMore) {
      const { edges } = data.vendors
      const { cursor } = edges[edges.length - 1]

      fetchMore({ variables: { first: FIRST, after: cursor } })
    }
  }

  const handleOnRefresh = () => {
    refetch({ first: FIRST })
  }

  const handleOnCloseVendorPickModal = () => {
    const close = handleOnOpen(false)

    close()
    refetch(defaultSearchValue)
  }

  const button = (
    <Button
      className={classes.button}
      color="yellow"
      preset="fixedBorderButton"
      textColor="yellow"
      textPreset="h5"
      text="ASSIGN VENDOR"
      tx="item.form.button.text"
      onClick={handleOnOpen(true)}
    />
  )

  const content = vendor ? (
    <VendorItem
      className={classes.sectionAdditionWithText}
      avatar={vendor.avatar?.url}
      avatarRadius={48}
      name={getFullName(vendor)}
      companyName={Vendor?.companyName}
    />
  ) : (
    <EmptySection
      className={classes.sectionAdditionWithText}
      text="The item is not assigned to a vendor."
    />
  )

  return (
    <Section
      title="Vendor"
      titleTx="item.form.price.title"
      rightButton={button}
    >
      {content}
      {open && (
        <VendorPickModal
          defaultActive={Vendor}
          data={Data}
          totalCount={TotalCount}
          onLoadMore={handleOnLoadMore}
          onRefresh={handleOnRefresh}
          onClose={handleOnCloseVendorPickModal}
          onSubmit={handleOnChange}
          onSearch={handleOnChangeSearch}
        />
      )}
    </Section>
  )
}
