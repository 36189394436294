import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry
} from 'postcode-validator'

import {
  Button,
  Column,
  Text,
  TextField,
  Dropdown,
  DropdownItemData,
  Row,
  TextFieldErrorProps,
  LightTheme
} from '../../components'
import { AccountUpdateRequest, AddressInput } from '../../services'
import { useUser } from '../../hooks/useUser'
import { validatePhone } from '../helpers'
import { useApi } from '../../providers'
import { AuthRoutes } from '../../auth/urls'
import background from '../../assets/images/background-pattern.png'
import { CountryCode } from '../../services/country/country.types'

import { ContactRegisterPageState } from '../contact-register-page'

import { useStyle } from './user-info-update.styles'
import { UserInfoUpdatePageState } from './user-info-update.types'

const defaultUserState: AddressInput = {
  firstName: '',
  lastName: '',
  companyName: '',
  streetAddress1: '',
  streetAddress2: '',
  city: '',
  cityArea: '',
  postalCode: '',
  country: CountryCode.US,
  countryArea: '',
  phone: '',
  username: ''
}

export const UserInfoUpdatePage = () => {
  const location = useLocation<UserInfoUpdatePageState>()
  const history = useHistory()
  const { country: countryApi } = useApi()
  const userContext = useUser()
  const [user, changeUser] = useState<AddressInput>(defaultUserState)
  const [countryState, changeCountryState] = useState<DropdownItemData>()

  useEffect(() => {
    if (!location.state || !location.state.user) {
      history.push(AuthRoutes.REGISTER)
    }
  }, [location.state])

  const { data: countriesData, refetch } = countryApi.useCountries()

  useEffect(() => {
    refetch()
  }, [])

  const { data: countryStatesData, refetch: refetchCountryStates } =
    countryApi.useCountryStatesById({
      filter: {
        country: ''
      }
    })

  const error = userContext.user?.defaultBillingAddress === null

  const {
    companyName,
    streetAddress1,
    city,
    postalCode,
    country,
    countryArea,
    phone
  } = user

  const ValidatePhone = () => {
    if (user) {
      const isValidePhone = validatePhone(user.phone)

      return isValidePhone
    }
    return false
  }

  const ValidateZipCode = () => {
    if (user) {
      const isCountryExist = postcodeValidatorExistsForCountry(user.country)
      if (isCountryExist) {
        const isValidPostcode = postcodeValidator(user.postalCode, user.country)
        return isValidPostcode
      }

      // eslint-disable-next-line no-console
      console.error('Current country does not exist to validation postal code')

      return true
    }
    return false
  }

  const isDisabled =
    !companyName ||
    !streetAddress1 ||
    !city ||
    !postalCode ||
    !country ||
    !countryArea ||
    !phone ||
    !ValidatePhone() ||
    !ValidateZipCode()

  const theme: LightTheme = useTheme()
  const classes = useStyle({ background, error, theme })

  const postalCodeError: TextFieldErrorProps =
    !ValidateZipCode() && postalCode
      ? {
          error: 'This code is unknown. Please enter a valid zip code.'
          // errorTx: 'error.postal.code'
        }
      : {}
  const phoneError: TextFieldErrorProps =
    !ValidatePhone() && phone
      ? {
          error: 'Please enter a valid phone number.'
          // errorTx: 'error.phone.number'
        }
      : {}

  const Countries = useMemo(() => {
    if (countriesData) {
      return countriesData.countries.edges.map((countryItem) => ({
        ...countryItem.node,
        value: countryItem.node.code
      }))
    }

    return []
  }, [countriesData])

  const UserCountryCode = useMemo(() => {
    if (country) {
      return country
    }
    return undefined
  }, [country])

  const UserCountry = useMemo(() => {
    return Countries.find((countryItem) => countryItem.code === UserCountryCode)
  }, [Countries, UserCountryCode])

  useEffect(() => {
    if (UserCountry && UserCountry.id) {
      refetchCountryStates({ filter: { country: UserCountry.id } })
    }
  }, [UserCountry])

  useEffect(() => {
    if (countryState) {
      refetchCountryStates({ filter: { country: String(countryState.id) } })
    }
  }, [countryState])

  const CountryStates = useMemo(() => {
    if (countryStatesData) {
      return countryStatesData.countryStates.edges.map((countryStateItem) => ({
        ...countryStateItem.node,
        value: countryStateItem.node.id
      }))
    }

    return []
  }, [Countries, countryStatesData])

  const handleOnChange =
    (prop: keyof AddressInput) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeUser({
        ...user,
        [prop]: value
      })
    }

  const handleOnChangeCountry = (value: DropdownItemData) => {
    changeUser({
      ...user,
      country: value.value as CountryCode
    })

    changeCountryState(value)
  }

  const handleOnChangeState = (value: DropdownItemData) => {
    changeUser({
      ...user,
      countryArea: String(value.name)
    })
  }

  const handleOnClick = () => {
    if (userContext.updateInfoOnRegister) {
      const prevState = location.state
      const billingAddress: AccountUpdateRequest = {
        input: {
          firstName: location.state.user.firstName,
          lastName: location.state.user.lastName,
          phone: user.phone,
          companyName: user.companyName,
          country: user.country,
          state: user.countryArea,
          username: user.username
        }
      }
      const nextPageState: ContactRegisterPageState = {
        ...prevState,
        billingAddress
      }

      history.push(AuthRoutes.CONTACT_INFO_UPDATE, nextPageState)
    }
  }

  return (
    <Column fullWidth className={classes.container}>
      <Column className={classes.loginForm}>
        <Text
          preset="h1"
          text="Tell us a little about you."
          tx="userInfo.title"
        />
        <Text
          color="inactive"
          preset="h7"
          text="Company Information. Please fill in all the fields to continue the registration."
          tx="userInfo.description"
        />

        <TextField
          className={classes.input}
          label="Business/Company Name"
          labelTx="userInfo.companyName"
          preset="border"
          type="text"
          maxLength={50}
          onChange={handleOnChange('companyName')}
        />

        <Row className={classes.dropdawnRow}>
          <Dropdown
            className={classes.dropdown}
            label="Country"
            labelTx="userInfo.country"
            data={Countries}
            preset="body"
            onChange={handleOnChangeCountry}
          />
        </Row>

        <Row className={classes.dropdawnRow}>
          <Dropdown
            className={classes.dropdown}
            label="State"
            labelTx="userInfo.state"
            data={CountryStates}
            preset="body"
            onChange={handleOnChangeState}
          />
        </Row>

        <TextField
          className={classes.input}
          label="City"
          labelTx="userInfo.city"
          preset="border"
          type="text"
          maxLength={30}
          onChange={handleOnChange('city')}
        />
        <TextField
          className={classes.input}
          label="Address Line 1"
          labelTx="userInfo.address"
          preset="border"
          type="text"
          maxLength={50}
          onChange={handleOnChange('streetAddress1')}
        />
        <TextField
          {...postalCodeError}
          className={classes.input}
          label="Zip Code"
          labelTx="userInfo.zip"
          preset="border"
          type="text"
          maxLength={10}
          onChange={handleOnChange('postalCode')}
        />
        <TextField
          {...phoneError}
          isHidePlaceholder
          className={classes.input}
          label="Phone Number"
          placeholder="+country code XXXXXXXXXX"
          placeholderTx="contact.info.placeholder.phone"
          labelTx="userInfo.phone"
          preset="border"
          type="text"
          maxLength={17}
          onChange={handleOnChange('phone')}
        />

        <Button
          className={classes.submit}
          disabled={isDisabled}
          text="SAVE AND CONTINUE"
          tx="userInfo.save"
          preset="primary"
          textColor="white"
          textPreset="h5"
          onClick={handleOnClick}
        />
      </Column>
    </Column>
  )
}
