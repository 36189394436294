import React, { Dispatch, FC, SetStateAction, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import {
  arrayHasElements,
  Checkbox,
  Column,
  ICONS,
  Row,
  Text,
  TextField,
  TablePagginationBar,
  LightTheme,
  Icon,
  LoadingContainer,
  QuestionConfirmModal,
  Button
} from '../../../components'
import { OfferOrderField, OrderDirection } from '../../../services'

import { CabinetNavigatorPathVendor } from '../../cabinet-navigator'

import { OffersTableHeader } from '../offers-table-header'
import { OffersTableItem, OffersTableItemProps } from '../offers-table-item'

import { useStyle } from './offers-table.styles'
import { OffersTableProps } from './offers-table.types'

export const OffersTable: FC<OffersTableProps> = ({
  className = '',
  children,
  data,
  loading,
  rowCounts,
  paginationState,
  onChangeSearch,
  onDuplicateOffer,
  onChangeRowCount,
  onNextPage,
  onPrevioslyPage,
  onChangePage,
  onRemove,
  onContactCustomer,
  onSort
}) => {
  const history = useHistory()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [checkedIds, changeCheckedIds] = useState<string[]>([])
  const [openConfirmModal, changeOpenConfirmModal] = useState(false)

  const checkedItemsCount = checkedIds.length
  const checkedItemsCountValue = { checkedItemsCount }
  const checkedItemText = `Selected ${checkedItemsCount} items`

  const handleOnClickItem = (id: string) => {
    history.push(`${CabinetNavigatorPathVendor.OFFER_EDIT}/${id}`)
  }
  const handleOnDuplicate = () => {
    if (onDuplicateOffer) {
      onDuplicateOffer(checkedIds)
    }
  }

  const handleOnOpenConfirmModal =
    (callback: Dispatch<SetStateAction<boolean>>, state: boolean) => () => {
      return callback(state)
    }

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(checkedIds)
      changeCheckedIds([])
      changeOpenConfirmModal(false)
    }
  }

  const handleOnPick = (id: string, state: boolean) => {
    const filteredIds = checkedIds.filter((removeId) => removeId !== id)
    if (state) {
      changeCheckedIds([...filteredIds, id])
    } else {
      changeCheckedIds(filteredIds)
    }
  }

  const handleOnPickAll = (state: boolean) => {
    if (state) {
      const ids = data.map((product) => product.id)
      changeCheckedIds(ids)
    } else {
      changeCheckedIds([])
    }
  }

  const handleOnContactCustomer =
    (offerId: string): OffersTableItemProps['onContactCustomer'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (onContactCustomer) {
        onContactCustomer(offerId)
      }
    }

  const handleOnSort =
    (offerOrderField: OfferOrderField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(offerOrderField, direction)
      }
    }

  const PickedAll = useMemo(() => {
    return checkedIds.length === data.length
  }, [checkedIds, data])

  const getActive = (id?: string) => {
    return Boolean(checkedIds.find((removeId) => removeId === id))
  }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
        {children}
      </Row>
      <Column fullWidth>
        {arrayHasElements(checkedIds) ? (
          <Row
            fullWidth
            className={classes.header}
            justifyContent="space-between"
          >
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Checkbox checked={PickedAll} onChange={handleOnPickAll} />
              <Text
                className={classes.textCheckBox}
                preset="body"
                text={checkedItemText}
                tx="item.table.selected.items"
                values={checkedItemsCountValue}
              />
            </Row>
            <Row className={classes.duplicateBtnContainer}>
              <Button
                className={classes.button}
                preset="secondaryMini"
                text="Duplicate"
                textPreset="maxButton"
                textColor="white"
                color="darkBlue"
                tx="item.table.duplicate.btn"
                onClick={handleOnDuplicate}
              />
            </Row>
            <Row
              className={classes.remove}
              onClick={handleOnOpenConfirmModal(changeOpenConfirmModal, true)}
            >
              <Icon src={ICONS.trash} />
            </Row>
            {openConfirmModal && (
              <QuestionConfirmModal
                cancelText="Back"
                cancelTx="delete.offer.modal.cancel"
                successText="Delete"
                successTx="delete.offer.modal.ok"
                title="Delete Offer"
                titleTx="offer.modal.delete.title"
                description="Are you sure?"
                descriptionTx="offer.modal.delete.description"
                secondaryDescription="Are you sure you want to delete this Offer?"
                secondaryDescriptionTx="offer.modal.delete.secondaryDescription"
                onSubmit={handleOnRemove}
                onClose={handleOnOpenConfirmModal(
                  changeOpenConfirmModal,
                  false
                )}
              />
            )}
          </Row>
        ) : (
          <OffersTableHeader
            className={classes.header}
            checked={PickedAll}
            onPickAll={handleOnPickAll}
            onSort={handleOnSort}
          />
        )}
        <LoadingContainer loading={loading}>
          {!loading &&
            data.map((offer) => (
              <OffersTableItem
                key={`banner_${offer.id}`}
                {...offer}
                offer={offer}
                checked={getActive(offer.id)}
                onClick={handleOnClickItem}
                onChange={handleOnPick}
                onContactCustomer={handleOnContactCustomer(offer.id)}
              />
            ))}
        </LoadingContainer>
        {!loading && paginationState && (
          <TablePagginationBar
            paginationState={paginationState}
            rowCounts={rowCounts}
            onNextPage={onNextPage}
            onPrevioslyPage={onPrevioslyPage}
            onChangePage={onChangePage}
            onChangeRowCount={onChangeRowCount}
          />
        )}
      </Column>
    </Column>
  )
}
