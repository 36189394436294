import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  header: {
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px'
  },
  tableColumnFirst: {
    flex: 2
  },
  section: {
    marginTop: 20
  },
  image: {
    objectFit: 'cover'
  },
  text: {
    marginLeft: 16
  },
  button: {
    marginLeft: 16
  },
  emptySectionText: {
    padding: '8px 16px'
  },
  icon: {
    cursor: 'pointer'
  }
}))
