import React, { FC } from 'react'

import { Column, Row, Text, Button, Avatar } from '../../../../components'
import { concatClassNames } from '../../helpers'

import { VendorSummaryPreviewProps } from './vendor-preview-summary.types'
import { useStyle } from './vendor-preview-summary.styles'
import { VendorPreviewSummaryHeader } from './vendor-preview-summary-header'
import { VendorPreviewInfo } from './vendor-preview-info'

export const VendorSummaryPreview: FC<VendorSummaryPreviewProps> = ({
  className = '',
  data
}) => {
  const classes = useStyle()

  const companyName = data?.companyName ? data.companyName : '-'
  const averageRating = data ? Number(data.rating) : 0
  const positiveFeedback = `${data?.positiveFeedbackPercentage}%`

  return (
    <Column
      fullWidth
      className={concatClassNames(className, classes.container)}
    >
      <VendorPreviewSummaryHeader
        className={classes.header}
        rating={averageRating}
      />
      {data && (
        <Column fullWidth className={classes.vendor}>
          <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
            <Row className={classes.vendorImage}>
              {data && (
                <Avatar
                  alt="user"
                  src={data.avatar?.url}
                  name={companyName}
                  presetTextPlaceholder="h1"
                  radius={120}
                />
              )}
            </Row>
            <Column
              className={classes.countryName}
              fullWidth
              alignItems="flex-start"
            >
              <Text text={companyName} preset="h1" color="black" />
              <Row>
                {data && (
                  <Text
                    className={classes.secondaryText}
                    text={data.defaultBillingAddress?.country.code}
                    preset="h3"
                  />
                )}
              </Row>
            </Column>
          </Row>
          <Row className={classes.separator} fullWidth />
          <VendorPreviewInfo
            status={data.status}
            activity={data.activity}
            averageResponseTime={data.averageResponseTime}
            positiveFeedback={positiveFeedback}
            dateJoined={data.dateJoined}
          />

          <Row fullWidth justifyContent="center" className={classes.buttonsRow}>
            <Button
              disabled
              className={classes.btnFeedback}
              color="yellow"
              preset="primary"
              text="CONTACT VENDOR"
              tx="vendor.preview.contact.vendor.button"
              textColor="white"
              textPreset="h5"
            />
            <Button
              disabled
              className={classes.btnFeedback}
              color="white"
              preset="secondaryBorder"
              text="GIVE FEEDBACK"
              tx="vendor.preview.give.feegback.button"
              textColor="yellow"
              textPreset="h5"
            />
          </Row>
        </Column>
      )}
    </Column>
  )
}
