import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: `${theme.colors.shadow}88`,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 4
  },
  buttonContainer: {
    background: theme.colors.transparent,
    position: 'absolute',
    inset: '0 0 0 0',
    border: 'none',
    outline: 'none',
    zIndex: 1
  },
  closeContainer: {
    border: 'none',
    outline: 'none',
    background: 'transparent'
  },
  modal: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    position: 'relative',
    minHeight: 404,
    maxHeight: 'calc(100vh - 80px)',
    width: 568,
    zIndex: 2
  },
  title: {
    padding: 16,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  close: {
    cursor: 'pointer'
  },
  bottomContainer: {
    alignSelf: 'stretch',
    flex: 1
  },
  illustration: {
    opacity: 0.7
  },
  center: {
    width: '100%',
    justifyContent: 'center'
  },
  right: {
    justifyContent: 'flex-start'
  }
}))
