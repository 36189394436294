import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  Column,
  Row,
  Button,
  Text,
  TextField,
  Attention,
  ICONS
} from '../../../../components'
import { LightTheme } from '../../../../components/Theme'
import { validateEmail } from '../../../../auth/utils'

import { ChangeEmailFormProps } from './change-email-form.types'
import { useStyle } from './change-email-form.styles'

export const ChangeEmailForm: FC<ChangeEmailFormProps> = ({
  email,
  newEmail,
  invalidPasswordError,
  errorText,
  errorTextPassword,
  onEmailChange,
  onPasswordChange,
  onSetError,
  onChangePasswordError,
  onClose,
  onChangeStep
}) => {
  const error = Boolean(errorText || errorTextPassword)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const handleClickSave = () => {
    const isEmail = validateEmail(newEmail)
    if (newEmail === email) {
      onSetError('New email can not be same as old')
    } else if (!isEmail) {
      onSetError('Email is not right')
    } else if (isEmail) {
      if (!error) {
        onChangeStep('accept')
      }
    }
  }

  return (
    <>
      <Column
        fullWidth
        className={classes.bottomContainer}
        onClick={onChangePasswordError}
      >
        <Text
          color="inactive"
          preset="h7"
          text="You can change your E-mail for login."
          tx="change.email.modal.secondaryDescription"
        />
        <Column
          fullWidth
          alignItems="flex-start"
          className={classes.summaryItem}
        >
          <Text
            text="Current Email"
            tx="register.email"
            preset="subscriptionText"
            color="inactive"
          />
          <Text text={email} preset="body" color="inactive" />
        </Column>
        <TextField
          className={classes.input}
          label="New email"
          labelTx="register.email"
          preset="border"
          maxLength={50}
          onChange={onEmailChange}
          error={errorText}
        />

        <TextField
          className={classes.input}
          label="Password"
          labelTx="register.password"
          preset="border"
          maxLength={30}
          onChange={onPasswordChange}
          isSecure={true}
          error={errorTextPassword}
        />
        {invalidPasswordError !== undefined && (
          <Attention
            className={classes.attention}
            color="error"
            icon={ICONS.attention}
            text={invalidPasswordError}
          />
        )}
      </Column>
      <Row fullWidth justifyContent="flex-end" className={classes.buttonsRow}>
        <Button
          className={classes.button}
          color="yellow"
          preset="secondaryBorder"
          textColor="yellow"
          textPreset="maxButton"
          text="Back"
          onClick={onClose}
        />
        <Button
          className={classes.button}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="maxButton"
          text="Save"
          onClick={handleClickSave}
          disabled={error}
        />
      </Row>
    </>
  )
}
