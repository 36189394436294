import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { arrayHasElements, Button, Column, Row, Text } from '../../components'
import { useTable } from '../../hooks'
import { useApi, useToastify } from '../../providers'
import { Banner } from '../../services'

import { CabinetNavigatorPathAdmin } from '../cabinet-navigator'

import { BannersFilters } from './banners-filters'
import { BannersTable, BannersTableProps } from './banners-table'
import { useStyle } from './banners.styles'

const rowCounts = [5, 10, 20, 30]

export const BannersPage = () => {
  const { open } = useToastify()
  const history = useHistory()
  const classes = useStyle()
  const { menu } = useApi()

  const defaultVariables = { first: rowCounts[1] }

  const { onSubmit: onRemove, response: bannerRemoveResponse } =
    menu.useRemoveBanner()
  const { onSubmit: onUpdate, response: bannerUpdateResponse } =
    menu.useUpdateBanner()
  const { data, loading, refetch } = menu.useBanners(defaultVariables)

  const {
    changeCurrentData,
    Data,
    currentData,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch,
    onSubmitFilters,
    onResetFilters
  } = useTable({
    refetch,
    data: data?.allBanners,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  useEffect(() => {
    if (bannerRemoveResponse.data) {
      const hasErrors = arrayHasElements(
        bannerRemoveResponse.data?.bannerDelete.errors
      )
      if (!hasErrors) {
        open({
          text: 'Successful, changes have been saved',
          tx: 'successfull.message'
        })
      }
    }
  }, [bannerRemoveResponse.data?.bannerDelete.errors])

  useEffect(() => {
    if (bannerUpdateResponse.data) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })
    }
  }, [bannerUpdateResponse.data?.bannerUpdate.banner])

  const handleOnCreate = () => {
    history.push(CabinetNavigatorPathAdmin.CREATE_BANNERS)
  }

  const handleOnRemove = (ids: string[]) => {
    ids.forEach((id) => onRemove({ id }))

    const nextProducts = currentData.filter(({ node }) => {
      const currentItem = ids.find((id) => id === node.id)

      return !currentItem
    })
    changeCurrentData(nextProducts)
  }

  const handleOnUpdate = (publishData: Banner[]) => {
    publishData.forEach((item) => {
      const newState = {
        kind: item.kind,
        buttonType: item.buttonType,
        startDate: item.startDate,
        isVisible: item.isVisible
      }
      onUpdate(item.id, newState)
    })

    const nextProducts = currentData.map((banner) => {
      let isVisible = false
      const active = publishData.find((item) => {
        isVisible = item.isVisible
        return item.id === banner.node.id
      })

      if (active) {
        return {
          ...banner,
          node: { ...banner.node, isVisible }
        }
      }

      return banner
    })

    changeCurrentData(nextProducts)
  }

  const handleOnSearch: BannersTableProps['onSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="space-between"
      >
        <Text
          color="black"
          preset="h3"
          text="Banners"
          tx="cabinet.banners.title"
        />
        <BannersFilters onSubmit={onSubmitFilters} onReset={onResetFilters} />
      </Row>
      <Row fullWidth justifyContent="flex-end">
        <Button
          className={classes.button}
          preset="primary"
          text="CREATE BANNER"
          textPreset="h5"
          textColor="white"
          tx="cabinet.banners.create.button"
          onClick={handleOnCreate}
        />
      </Row>
      <BannersTable
        loading={loading}
        paginationState={paginationState}
        rowCounts={rowCounts}
        className={classes.table}
        data={Data}
        onRemove={handleOnRemove}
        onUpdate={handleOnUpdate}
        onSearch={handleOnSearch}
        onChangePage={onChangePage}
        onPrevioslyPage={onPrevioslyPage}
        onNextPage={onNextPage}
        onChangeRowCount={onChangeRowCount}
      />
    </Column>
  )
}
