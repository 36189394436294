import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { CabinetNavigatorPathVendor } from '../../pages'

import { Text } from '../text'
import { Column } from '../column'
import { Modal } from '../modal'

import { Button } from '../button'
import { Row } from '../row'

import background from './background.png'
import { useStyle } from './subscription-error-modal.styles'
import { SubscriptionErrorModalProps } from './subscription-error-modal.types'

export const SubscriptionErrorModal: FC<SubscriptionErrorModalProps> = ({
  title,
  titleTx,
  description,
  descriptionTx,
  secondaryDescription,
  secondaryDescriptionTx,
  onClose,
  onCancel
}) => {
  const classes = useStyle()
  const history = useHistory()

  const handleOnSubmit = () => {
    history.push(CabinetNavigatorPathVendor.PROFILE_SETTINGS_SUBSCRIPTION)
  }

  return (
    <Modal title={title} titleTx={titleTx} onClose={onClose}>
      <Column fullWidth className={classes.bottomContainer}>
        <img width="100%" src={background} />
        <Text
          className={classes.description}
          color="black"
          preset="h4"
          text={description}
          tx={descriptionTx}
        />
        <Text
          className={classes.secondaryDesctiption}
          color="inactive"
          preset="body"
          text={secondaryDescription}
          tx={secondaryDescriptionTx}
        />
      </Column>
      <Row fullWidth className={classes.bottom} justifyContent="flex-end">
        <Button
          className={classes.button}
          color="yellow"
          preset="secondaryBorder"
          textColor="yellow"
          textPreset="maxButton"
          text="Cancel"
          tx="subscriptopn.error.modal.cancel"
          onClick={onCancel || onClose}
        />
        <Button
          className={classes.button}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="maxButton"
          text="Proceed"
          tx="subscriptopn.error.modal.proceed"
          onClick={handleOnSubmit}
        />
      </Row>
    </Modal>
  )
}
