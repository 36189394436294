import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { arrayHasElements, QuestionConfirmModal } from '../../components'
import { useApi, useToastify } from '../../providers'
import { ProductCreateInput } from '../../services'

import {
  CabinetNavigatorPathVendor,
  ItemsTabNavigation
} from '../cabinet-navigator'
import { ItemForm } from '../item-form'
import { ProductPreview } from '../views'

export const CreateItemPage = () => {
  const { open } = useToastify()
  const history = useHistory()
  const { product } = useApi()
  const { response, onSubmit: onSubmitProduct } = product.useProductCreate()
  const { onSubmit: onSubmitImage } = product.useProductImageCreate()
  const [images, changeImages] = useState<File[]>([])
  const [productId, changeProductId] = useState('')
  const [openSaveModal, changeOpenSaveModal] = useState(false)
  const [openSavePreviewModal, changeOpenSavePreviewModal] = useState(false)
  const [openProductPreview, changeOpenProductPreview] = useState(false)
  const [openSaveProductPreview, changeOpenSaveProductPreview] = useState(false)

  useEffect(() => {
    if (response.data && response.data.productCreate.product) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })

      const { id } = response.data.productCreate.product
      changeProductId(id)
      images.forEach((image) => {
        const variables = { input: { product: id, image } }
        onSubmitImage(variables)
      })
    }
  }, [response.data])

  const handleOnSubmit = (
    nextProduct: ProductCreateInput,
    nextImages: File[]
  ) => {
    if (nextProduct && arrayHasElements(nextImages)) {
      onSubmitProduct({ input: nextProduct })
      changeImages(nextImages)
    }

    changeOpenSaveModal(true)
  }

  const handleOnSubmitPreview = (
    nextProduct: ProductCreateInput,
    nextImages: File[]
  ) => {
    if (nextProduct && arrayHasElements(nextImages)) {
      onSubmitProduct({ input: nextProduct })
      changeImages(nextImages)
    }

    changeOpenSavePreviewModal(true)
  }

  const handleOnCloseProductPreview = (state: boolean) => () => {
    changeOpenProductPreview(state)

    history.push(`${CabinetNavigatorPathVendor.ITEMS_EDIT}/${productId}`)
  }

  const handleOnCloseSaveProductPreview = (state: boolean) => () => {
    changeOpenSaveProductPreview(state)

    history.push(
      `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.PRODUCTS}`
    )
  }

  const handleOnCloseSaveModal = () => {
    changeOpenSaveModal(false)
    history.push(
      `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.PRODUCTS}`
    )
  }

  const handleOnCloseSavePreviewModal = () => {
    changeOpenSavePreviewModal(false)
    history.push(`${CabinetNavigatorPathVendor.ITEMS_EDIT}/${productId}`)
  }

  const handleOnOpenLink = () => {
    changeOpenSaveModal(false)
    changeOpenSaveProductPreview(true)
  }

  const handleOnOpenLinkPreview = () => {
    changeOpenSavePreviewModal(false)
    changeOpenProductPreview(true)
  }

  return (
    <>
      <ItemForm
        images={[]}
        title="Create Item"
        titleTx="item.create"
        onSubmit={handleOnSubmit}
        onSubmitCreatePreview={handleOnSubmitPreview}
      />
      {openSaveModal && (
        <QuestionConfirmModal
          title="Item Saved"
          titleTx="item.form.create.modal.title"
          description="Would you like to see the Preview of this item?"
          descriptionTx="item.form.create.modal.description"
          cancelText="No"
          cancelTx="item.form.create.modal.cancel"
          successText="Yes"
          successTx="item.form.create.modal.ok"
          onSubmit={handleOnOpenLink}
          onClose={handleOnCloseSaveModal}
        />
      )}
      {openSavePreviewModal && (
        <QuestionConfirmModal
          title="Item Saved"
          titleTx="item.form.create.modal.title"
          description="Would you like to see the Preview of this item?"
          descriptionTx="item.form.create.modal.description"
          cancelText="No"
          cancelTx="item.form.create.modal.cancel"
          successText="Yes"
          successTx="item.form.create.modal.ok"
          onSubmit={handleOnOpenLinkPreview}
          onClose={handleOnCloseSavePreviewModal}
        />
      )}
      {openProductPreview && (
        <ProductPreview
          id={productId}
          open={openProductPreview}
          onClose={handleOnCloseProductPreview(false)}
        />
      )}
      {openSaveProductPreview && (
        <ProductPreview
          id={productId}
          open={openSaveProductPreview}
          onClose={handleOnCloseSaveProductPreview(false)}
        />
      )}
    </>
  )
}
