import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    marginTop: 24
  },
  chatboxContainer: {
    border: `1px solid ${theme.colors.shadow}`,
    flex: 1,
    alignSelf: 'stretch'
  },
  chatbox: {
    flex: 1,
    alignSelf: 'stretch'
  }
}))
