import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Icon, ICONS } from '../../icon'
import { Row } from '../../row'

import { Color, LightTheme } from '../../Theme'
import { useStyle } from '../modal-banner-preview.styles'

import { ModalBannerPreviewHeaderProps } from './modal-banner-preview-header.types'

export const ModalBannerPreviewHeader: FC<ModalBannerPreviewHeaderProps> = ({
  active,
  onClose,
  onOpenPreviewDesktopBanner,
  onOpenPreviewMobileBanner
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ active, theme })

  const colorWebIcon: Color = active ? 'inactive' : 'black'
  const colorMobIcon: Color = active ? 'black' : 'inactive'

  return (
    <Row className={classes.header} fullWidth>
      <Row
        className={`${classes.icon} ${classes.iconWeb}`}
        onClick={onOpenPreviewDesktopBanner}
      >
        <Icon src={ICONS.desktopWindows} fill={theme.colors[colorWebIcon]} />
      </Row>
      <Row
        className={`${classes.icon} ${classes.iconMob}`}
        onClick={onOpenPreviewMobileBanner}
      >
        <Icon src={ICONS.phoneIphone} fill={theme.colors[colorMobIcon]} />
      </Row>
      <Icon className={classes.iconClose} onClick={onClose} src={ICONS.close} />
    </Row>
  )
}
