import React, { useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import { Text } from '../text'
import { Column } from '../column'
import { TextField } from '../text-field'
import { Row } from '../row'
import { Checkbox } from '../checkbox'
import { LightTheme } from '../Theme'
import { QuestionModal } from '../question-modal'

import { MultiSelectItem, MultiSelectProps } from './multi-select.types'
import { useStyle } from './multi-select.styles'

export const MultiSelect = <T extends MultiSelectItem>({
  data,
  active = [],
  title,
  titleTx,
  onChangeSearch,
  onChange,
  onClose
}: MultiSelectProps<T>) => {
  const [ids, changeIds] = useState(active)

  const theme = useTheme<LightTheme>()
  const classes = useStyle({ theme })

  const Data = useMemo(() => {
    return data.map((item) => {
      const isActive = ids.find((id) => id === item.id)

      return {
        ...item,
        state: Boolean(isActive)
      }
    })
  }, [ids, data])

  const handleOnChange = (item: T) => (state: boolean) => {
    const nextIds = ids.filter((id) => item.id !== id)

    if (state) {
      return changeIds([...nextIds, item.id])
    }

    return changeIds(nextIds)
  }

  const handleOnSubmit = () => {
    if (onChange) {
      onChange(ids)
    }
  }

  return (
    <QuestionModal
      title={title}
      titleTx={titleTx}
      successText="Apply"
      successTx="item.form.create.material.apply"
      onSubmit={handleOnSubmit}
      onClose={onClose}
    >
      <Column fullWidth className={classes.container}>
        <TextField
          preset="border"
          label="Search"
          labelTx="item.form.general.name"
          onChange={onChangeSearch}
        />
        <Column
          fullWidth
          className={classes.itemsContainer}
          justifyContent="flex-start"
        >
          {Data.map((item) => (
            <Row
              fullWidth
              key={`checkbox_${title}_${item.id}`}
              className={classes.item}
            >
              <Checkbox
                className={classes.checkbox}
                checked={item.state}
                onChange={handleOnChange(item)}
              >
                <Text color="black" preset="body" text={item.title} />
              </Checkbox>
            </Row>
          ))}
        </Column>
      </Column>
    </QuestionModal>
  )
}
