import React, { useState, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useApi } from '../../providers'
import {
  Column,
  Row,
  Text,
  Button,
  BackBreadcrumbs,
  Icon,
  ICONS,
  ConfirmModal,
  SubscriptionErrorModal,
  Loader,
  QuestionConfirmModal
} from '../../components'
import { useUser } from '../../hooks'
import { RedirectStatus, RedirectSubscriptionQueryParams } from '../../services'

import { getQueryParams, getSubscriptionInput } from '../helpers'
import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { useStyle } from './profile-settings-subscription.styles'
import { SubscriptionContainer } from './subsciption-container'
import { SubscriptionOption, SubTypes } from './subscription-option'

const { FREE } = SubTypes

export const ProfileSettingsSubscriptionPage = () => {
  const history = useHistory()
  const location = useLocation()
  const { user, changeSubscription } = useUser()
  const { subscription: subscriptionApi } = useApi()
  const classes = useStyle()
  const first = 3

  const defaultType = useMemo(() => user?.subscription?.name || FREE, [user])

  const [selected, changeSelected] = useState('')
  const [selectedPaypalPlanId, changeSelectedPaypalPlanId] = useState('')
  const [openConfirmModal, changeOpenConfirmModal] = useState(false)
  const [openErrorModal, changeOpenErrorModal] = useState(false)
  const [openUpgradeModal, changeOpenUpgradeModal] = useState(false)

  const { data } = subscriptionApi.useSubscriptionTypes({ first })
  const { response, onSubmit } = subscriptionApi.useCreateSubscription()

  const SubsciptionTypes = useMemo(() => {
    if (data) {
      const { edges } = data.subscriptionTypes
      const nextSubsciptionTypes = edges
      return nextSubsciptionTypes
    }

    return []
  }, [data, user])

  useEffect(() => {
    const params = getQueryParams(
      location.search
    ) as RedirectSubscriptionQueryParams

    if (params.event === RedirectStatus.SUCCESS) {
      changeOpenConfirmModal(true)

      if (data && changeSubscription) {
        const subscription = data.subscriptionTypes.edges.find(
          ({ node }) => node.paypalPlanId === params.subscription_platform_id
        )

        changeSubscription(subscription?.node)
      }
    }

    if (params.event === RedirectStatus.ERROR) {
      changeOpenErrorModal(true)
    }
  }, [location.search, data])

  useEffect(() => {
    const active =
      data &&
      data.subscriptionTypes.edges.find(
        (item) =>
          selectedPaypalPlanId === item.node.paypalPlanId &&
          item.node.name !== 'Free'
      )

    if (response.data && active) {
      window.open(response?.data?.createPaypalSubscription.link, '_self')
    }
  }, [data, response.data])

  const handleOnOpenConfirmModal = () => {
    changeOpenConfirmModal(!openConfirmModal)

    if (openConfirmModal) {
      history.push(CabinetNavigatorPathVendor.PROFILE_SETTINGS_SUBSCRIPTION)
    }
  }
  const handleOnOpenErrorModal = () => {
    changeOpenErrorModal(!openErrorModal)
  }
  const handleOpenUpgradeModal = (state: boolean) => () => {
    changeOpenUpgradeModal(state)
  }

  const handleCreatePaypalSubscription = () => {
    const close = handleOpenUpgradeModal(false)

    if (data) {
      const selectedSubscriptionType = data?.subscriptionTypes.edges.find(
        ({ node }) => node.paypalPlanId === selectedPaypalPlanId
      )

      if (selectedSubscriptionType) {
        const { id, name } = selectedSubscriptionType.node

        const input = getSubscriptionInput(
          selectedPaypalPlanId,
          id,
          CabinetNavigatorPathVendor.PROFILE_SETTINGS_SUBSCRIPTION
        )

        onSubmit(input)

        if (name === 'Free' && changeSubscription) {
          changeOpenConfirmModal(true)

          changeSubscription(selectedSubscriptionType?.node)
        }
      }
    }

    close()
  }

  if (response.loading) {
    return (
      <Column
        fullWidth
        className={classes.container}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Loader />
      </Column>
    )
  }

  return (
    <Column fullWidth fullHeight justifyContent="space-between">
      <Column
        fullWidth
        className={classes.container}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <BackBreadcrumbs
          text="Profile Settings"
          tx="profile.settings.general.back"
        />
        <Column
          fullWidth
          className={classes.titleContainer}
          alignItems="flex-start"
        >
          <Text color="black" preset="h3" text="Current Subscription" />
        </Column>
        <Row
          alignItems="flex-start"
          justifyContent="space-between"
          className={classes.content}
        >
          <Column
            className={classes.leftGroup}
            alignItems="flex-start"
            fullWidth
          >
            <SubscriptionOption
              defaultType={defaultType}
              types={SubsciptionTypes}
              selected={selected}
              nextPayment={user?.subscriptionNextPayment}
              onChangeSelected={changeSelected}
              onChangeSelectedPlanId={changeSelectedPaypalPlanId}
            />
          </Column>
          <Column className={classes.rightGroup}>
            <SubscriptionContainer
              justifyContent="flex-start"
              title="Payment Method"
              titleTx="subscription.plan.monthly"
            >
              <Icon src={ICONS.paypal} className={classes.icon} />
              <Text
                className={classes.paymentText}
                color="inactive"
                preset="body"
                text="Pay using your PayPal account or credit card."
              />
              <Button
                className={classes.buttonChange}
                color="yellow"
                preset="borderButton"
                textColor="yellow"
                textPreset="h5"
                text="CHANGE PAYMENT METHOD"
                onClick={handleOnOpenErrorModal}
              />
            </SubscriptionContainer>
          </Column>
        </Row>
      </Column>
      <Row
        fullWidth
        className={classes.submitContainer}
        justifyContent="space-between"
      >
        <Column />
        <Row>
          <Button
            className={classes.button}
            color="yellow"
            preset="fixedPrimaryButton"
            textColor="white"
            textPreset="h5"
            text="UPGRADE"
            onClick={handleOpenUpgradeModal(true)}
            disabled={!selected}
          />
        </Row>
      </Row>
      {openConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          titleTx="subscriptionConfirm.modal.title"
          description="Your payment is successful!"
          descriptionTx="subscriptionConfirm.modal.description"
          secondaryDescription="Congratulate you with the Ovaflopick Premium plan purchase!"
          secondaryDescriptionTx="subscriptionConfirm.modal.secondaryDescription"
          onClose={handleOnOpenConfirmModal}
        />
      )}
      {openErrorModal && (
        <SubscriptionErrorModal
          title="Ooops..."
          titleTx="subscriptionError.modal.title"
          description="Your payment isn't successful!"
          descriptionTx="subscriptionError.modal.description"
          secondaryDescription="Please check your payment method or balance and try again."
          secondaryDescriptionTx="subscriptionError.modal.secondaryDescription"
          onClose={handleOnOpenErrorModal}
        />
      )}
      {openUpgradeModal && (
        <QuestionConfirmModal
          title="Upgrade Subscription"
          titleTx="upgrade.subscription.modal.title"
          description="Are you sure?"
          descriptionTx="upgrade.subscription.modal.description"
          secondaryDescription="Switching to a Free subscription suspends the Premium subscription."
          secondaryDescriptionTx="upgrade.subscription.modal.secondaryDescription"
          successText="Upgrade Subscription"
          successTx="upgrade.subscription.modal.title"
          onClose={handleOpenUpgradeModal(false)}
          onSubmit={handleCreatePaypalSubscription}
        />
      )}
    </Column>
  )
}
