import React, { FC, useRef } from 'react'

import { Column, Row, Text } from '../../../../components'

import { VendorPreviewCategoryListProps } from './vendor-preview-category-list.types'
import { useStyle } from './vendor-preview-category-list.styles'
import { VendorPreviewCategoryCard } from './vendor-preview-category-card'

export const VendorPreviewCategoryList: FC<VendorPreviewCategoryListProps> = ({
  className = '',
  data,
  description,
  descriptionTx,
  title,
  titleTx,
  titlePreset
}) => {
  const classes = useStyle()
  const scrollContainer = useRef<HTMLDivElement>(null)

  return (
    <Column fullWidth className={className}>
      <Text color="black" preset={titlePreset} text={title} tx={titleTx} />
      {description && (
        <Text
          className={classes.description}
          color="inactive"
          preset="h7"
          text={description}
          tx={descriptionTx}
        />
      )}
      <Row
        fullWidth
        justifyContent="center"
        className={classes.carouselContainer}
        ref={scrollContainer}
      >
        <Row wrap className={classes.listRow} justifyContent="flex-start">
          {data.map(({ id, name, backgroundImage }) => {
            return (
              <VendorPreviewCategoryCard
                key={`category${id}`}
                name={name}
                backgroundImage={backgroundImage}
              />
            )
          })}
        </Row>
      </Row>
    </Column>
  )
}
