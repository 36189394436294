import React, { forwardRef } from 'react'

import { Color } from '../../../Theme'
import { Column } from '../../../column'
import { Text } from '../../../text'

import { OrderStagingDot } from '../../order-staging-dot'

import { OrderStagingItemProps } from './order-staging-item.types'
import { useStyle } from './order-staging-item.styles'

export const OrderStagingItem = forwardRef<
  HTMLDivElement,
  OrderStagingItemProps
>(({ active, disabled, title, titleTx, onClick }, ref) => {
  const classes = useStyle()

  const getActiveTextColor: Color = active ? 'black' : 'inactiveSecondary'

  const disabledOnClick = !disabled ? onClick : undefined

  return (
    <Column ref={ref} onClick={disabledOnClick}>
      <OrderStagingDot active={active} disabled={disabled} />
      <Text
        className={classes.title}
        color={getActiveTextColor}
        preset="h6"
        text={title}
        tx={titleTx}
      />
    </Column>
  )
})
