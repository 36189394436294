import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../column'
import { LightTheme } from '../Theme'

import { LoaderProps } from './loader.types'
import { useStyle } from './loader.styles'

export const Loader: FC<LoaderProps> = ({ className = '', height, width }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ height, theme, width })
  return (
    <Column className={`${className} ${classes.constainer}`}>
      <Column className={classes.loader}>
        <Column className={classes.point} />
      </Column>
    </Column>
  )
}
