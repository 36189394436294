import moment, { LocaleSpecifier } from 'moment'
import 'moment/locale/uk'

export const getDateWithStartTime = (
  year: number,
  month: number,
  day: number
) => {
  const dayWithSetZeroHours = new Date(year, month, Number(day)).setHours(
    0,
    0,
    0
  )
  const nextDate = new Date(dayWithSetZeroHours)

  return nextDate
}

export const getDateTimestampWithStartTime = (
  year: number,
  month: number,
  day: number
) => getDateWithStartTime(year, month, day).getTime()

export const getDateByDateWithStartTime = (date: Date) => {
  const dayWithSetZeroHours = date.setHours(0, 0, 0)
  const nextDate = new Date(dayWithSetZeroHours)

  return nextDate
}

export const getDateByDateTimestampWithStartTime = (date?: Date) => {
  if (date) {
    return getDateByDateWithStartTime(date).getTime()
  }

  return null
}

export const getDateLocale = (
  date: Date | string,
  locale?: LocaleSpecifier
) => {
  return moment(date)
    .locale(locale || 'en-US')
    .format('D MMM, YYYY')
}

export const getGraphDate = (date: Date | string) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getMonthLocale = (
  date: Date | string,
  locale?: LocaleSpecifier
) => {
  return moment(date)
    .locale(locale || 'en-US')
    .format('MMMM YYYY')
}
