import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  link: {
    textDecoration: 'none'
  },
  navLink: {
    marginTop: 16
  },
  count: {
    minWidth: 24,
    minHeight: 24,
    borderRadius: '50%',
    marginTop: 18,
    background: theme.colors.yellow
  }
}))
