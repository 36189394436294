import React, { FC } from 'react'

import { Dot } from '../radio-item'
import { Row } from '../row'
import { VendorItem } from '../vendor-item'

import { useStyle } from './vendor-radio-item.styles'
import { VendorRadioItemProps } from './vendor-radio-item.types'

export const VendorRadioItem: FC<VendorRadioItemProps> = ({
  active,
  onClick,
  ...props
}) => {
  const classes = useStyle()

  return (
    <Row
      fullWidth
      className={classes.container}
      justifyContent="space-between"
      onClick={onClick}
    >
      <VendorItem {...props} />
      <Dot active={active} className={classes.dot} />
    </Row>
  )
}
