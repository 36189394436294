import React, { FC, useState } from 'react'

import { Text } from '../text'
import { Checkbox } from '../checkbox'
import { Column } from '../column'
import { Row } from '../row'

import { CheckboxListProps } from './checkbox-list.types'
import { useStyle } from './checkbox-list.styles'
import { arrayHasElements } from '../helpers'

export const CheckboxList: FC<CheckboxListProps> = ({
  name,
  defaultValue = [],
  data,
  onChange
}) => {
  const classes = useStyle()
  const [activeIds, changeActiveIds] = useState<string[]>(defaultValue)

  const getChecked = (id: string) =>
    Boolean(activeIds.find((activeId) => activeId === id))

  const handleOnChange = (id: string) => (state: boolean) => {
    const filteredActiveIds = activeIds.filter((item) => item !== id)

    if (state) {
      const nextValue = [...filteredActiveIds, id]
      changeActiveIds(nextValue)

      if (onChange) {
        onChange(nextValue)
      }
    } else {
      changeActiveIds(filteredActiveIds)

      if (onChange) {
        if (!arrayHasElements(filteredActiveIds)) {
          onChange(undefined)
        } else {
          onChange(filteredActiveIds)
        }
      }
    }
  }

  return (
    <Column
      fullWidth
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      {data.map((item) => {
        const checked = getChecked(item.id)
        return (
          <Row
            key={`checkbox_${name}_${item.id}`}
            className={classes.item}
            justifyContent="flex-start"
          >
            <Checkbox checked={checked} onChange={handleOnChange(item.id)}>
              <Text
                className={classes.text}
                preset="body"
                text={item.name}
                tx={item.nameTx}
              />
            </Checkbox>
          </Row>
        )
      })}
    </Column>
  )
}
