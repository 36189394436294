import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    marginLeft: 24
  },
  success: {
    width: 168,
    height: 40,
    marginLeft: 24
  },
  field: {
    marginTop: 16
  },
  area: {
    '&:focus-within': {
      border: `2px solid ${theme.colors.yellow}`,
      padding: '3px 22px',
      width: '100%'
    },
    border: `1px solid ${theme.colors.inactive}`,
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 23px',
    width: '100%',
    marginTop: 16
  },
  bottom: {
    padding: '24px 0px'
  }
}))
