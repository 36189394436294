import React from 'react'

import { ActivityTabNavigation } from '../cabinet-navigator'
import {
  BackBreadcrumbs,
  CategoryNav,
  CategoryNavItem,
  Column,
  Row,
  Text
} from '../../components'
import { OrdersActivityPage } from '../orders-activity'
import { FeedbackActivityPage } from '../feedbacks-activity'

import { useStyle } from './activity.styles'

export const ActivityPage = () => {
  const classes = useStyle()

  const NAVIGATION_TABS: CategoryNavItem[] = [
    {
      text: 'Orders',
      tx: 'orders.activity.orders',
      link: ActivityTabNavigation.ACTIVITY_ORDERS,
      content: <OrdersActivityPage />
    },
    {
      text: 'Feedback',
      tx: 'orders.activity.feedbacks',
      link: ActivityTabNavigation.ACTIVITY_FEEDBACK,
      content: <FeedbackActivityPage />
    }
  ]

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <BackBreadcrumbs text="Your Info" tx="orders.activity.you.info" />
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="flex-start"
      >
        <Text color="black" preset="h3" text="Activity" tx="orders.activity" />
      </Row>
      <CategoryNav className={classes.categoryNav} data={NAVIGATION_TABS} />
    </Column>
  )
}
