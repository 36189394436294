import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  button: {
    maxWidth: 361
  },
  leftButton: {
    height: 58
  },
  rightButton: {
    marginLeft: 16
  },
  load: {
    marginTop: 16
  },
  container: {
    padding: '24px 0'
  },
  table: {
    marginTop: 24
  },
  titleContainer: {
    marginTop: 24
  }
})
