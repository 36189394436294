import React, { FC } from 'react'

import { Column, Section, TextField } from '../../../components'

import { useStyle } from './organize-banner-section.styles'
import { OrganizeBannerSectionProps } from './organize-banner-section.types'

export const OrganizeBannerSection: FC<OrganizeBannerSectionProps> = ({
  name,
  onChangeInputValue
}) => {
  const classes = useStyle()

  return (
    <Section
      title="Organize Banner"
      titleTx="promotion.banner.text.fields.organizeBanner"
    >
      <Column
        alignItems="flex-start"
        className={classes.sectionAdditionContainer}
      >
        <TextField
          className={classes.field}
          defaultValue={name}
          preset="border"
          maxLength={15}
          label="Name"
          labelTx="promotion.banner.name"
          onChange={onChangeInputValue('name')}
        />
      </Column>
    </Section>
  )
}
