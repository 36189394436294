import React from 'react'

import { Row } from '../../row'
import { Icon, ICONS } from '../../icon'
import { SortRightCaretProps } from './sort-right-caret.types'
import { useStyle } from './sort-right-caret.styles'

export const SortRightCaret = ({
  open,
  iconName,
  onClick
}: SortRightCaretProps) => {
  const classes = useStyle({ open })

  return (
    <Row onClick={onClick}>
      {iconName ? (
        <Icon src={ICONS.trendingFlatTop} color="black" />
      ) : (
        <Icon
          className={classes.caretDown}
          src={ICONS.trendingFlatTop}
          color="black"
        />
      )}
    </Row>
  )
}
