import React, { FC, ChangeEvent, useState, useEffect } from 'react'

import { Modal } from '../../../components'
import { CabinetNavigatorPathVendor } from '../../cabinet-navigator'
import { AuthRoutes } from '../../../auth/urls'
import { useApi } from '../../../providers'
import { arrayHasElements } from '../../../utils/tables'

import { ValidatorEmail, ValidatorPassword } from '../validation'

import { ChangeEmailModalProps } from './change-email-modal.types'
import { ChangeEmailForm } from './change-email-form'
import { ChangeEmailAccept } from './change-email-accept'
import { ChangeEmailSent } from './change-email-sent'

export const ChangeEmailModal: FC<ChangeEmailModalProps> = ({
  title,
  titleTx,
  onClose,
  email
}) => {
  const { auth: AuthApi } = useApi()
  const { onSubmit, response } = AuthApi.useChangeEmail()
  const [errorTextEmail, setErrorEmail] = useState('')
  const [errorTextPassword, setErrorPassword] = useState('')
  const [newEmail, changeNewEmail] = useState<string>('')
  const [step, changeStep] = useState<string>('form')
  const [password, changePassword] = useState<string>('')
  const [invalidPasswordError, changeInvalidPasswordError] = useState<
    string | undefined
  >()

  const redirectUrl = `${window.origin}${CabinetNavigatorPathVendor.PROFILE_SETTINGS_GENERAL}`
  const cancelUrl = `${window.origin}${AuthRoutes.SIGN_IN}`
  const isDashboard: boolean = true

  useEffect(() => {
    const error = response.data?.requestEmailChange.accountErrors

    if (error !== undefined && !arrayHasElements(error)) {
      changeStep('successs')
    }

    if (error !== undefined && arrayHasElements(error)) {
      changeStep('fail')
      changeInvalidPasswordError(error[0].message)
    }
  }, [response.data?.requestEmailChange.accountErrors.length])

  const handleSetError = (value: string) => {
    setErrorEmail(value)
  }

  const handleSetErrorPassword = (value: string) => {
    setErrorPassword(value)
  }

  const handleOnClearPasswordError = () => {
    changeInvalidPasswordError(undefined)
  }

  const handleOnEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (value.length === 0 || !ValidatorEmail(value)) {
      handleSetError('Please enter a valid e-mail address.')
    } else {
      handleSetError('')
    }

    changeNewEmail(value)
  }

  const handleOnPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    if (value.length === 0 || !ValidatorPassword(value)) {
      handleSetErrorPassword(
        'Password must contain from 5 to 30 characters, upper and lower case letters.'
      )
    } else {
      handleSetErrorPassword('')
    }
    changePassword(value)
  }

  const handleChangeStep = (value: string) => {
    changeStep(value)
  }

  const submitChangeEmail = () => {
    if (onSubmit) {
      onSubmit({
        password,
        newEmail,
        redirectUrl,
        cancelUrl,
        isDashboard
      })
    }
  }

  return (
    <Modal title={title} titleTx={titleTx} onClose={onClose}>
      {step === 'form' && (
        <ChangeEmailForm
          email={email}
          newEmail={newEmail}
          onClose={onClose}
          errorText={errorTextEmail}
          errorTextPassword={errorTextPassword}
          invalidPasswordError={invalidPasswordError}
          onChangeStep={handleChangeStep}
          onEmailChange={handleOnEmailChange}
          onPasswordChange={handleOnPasswordChange}
          onSetError={handleSetError}
          onSetErrorPassword={handleSetErrorPassword}
          onChangePasswordError={handleOnClearPasswordError}
        />
      )}
      {step === 'accept' && (
        <ChangeEmailAccept
          onChangeStep={handleChangeStep}
          email={newEmail}
          onSubmit={submitChangeEmail}
        />
      )}
      {step === 'successs' && <ChangeEmailSent />}
      {step === 'fail' && (
        <ChangeEmailForm
          email={email}
          newEmail={newEmail}
          onClose={onClose}
          errorText={errorTextEmail}
          errorTextPassword={errorTextPassword}
          invalidPasswordError={invalidPasswordError}
          onChangeStep={handleChangeStep}
          onEmailChange={handleOnEmailChange}
          onPasswordChange={handleOnPasswordChange}
          onSetError={handleSetError}
          onSetErrorPassword={handleSetErrorPassword}
          onChangePasswordError={handleOnClearPasswordError}
        />
      )}
    </Modal>
  )
}
