import React, { FC } from 'react'

import { Section } from '../../../components'
import { OfferColorsTable } from '../../../pages/views'

import { useStyle } from './offer-color-section.styles'
import { OfferColorSectionProps } from './offer-color-section.types'

export const OfferColorSection: FC<OfferColorSectionProps> = ({
  value,
  onChangeTable
}) => {
  const classes = useStyle()

  return (
    <Section
      className={classes.sectionItem}
      titleContainerClassName={classes.titleContainerItem}
      title="Color"
      titleTx="item.form.color.title"
      titlePreset="h4"
    >
      {value.productColors && (
        <OfferColorsTable data={value.productColors} onChange={onChangeTable} />
      )}
    </Section>
  )
}
