import React, { FC, useMemo } from 'react'

import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './avatar.styles'
import { AvatarProps } from './avatar.types'

export const Avatar: FC<AvatarProps> = ({
  alt,
  src,
  radius,
  size,
  name = '',
  presetTextPlaceholder = 'h4'
}) => {
  const FirstCharOfName = useMemo(
    () => (name ? name.charAt(0).toUpperCase() : ''),
    [name]
  )
  const classes = useStyle({ radius, size })

  const children = src ? (
    <img className={classes.image} src={src} alt={alt} />
  ) : (
    <Text color="white" preset={presetTextPlaceholder} text={FirstCharOfName} />
  )

  return <Row className={classes.avatar}>{children}</Row>
}
