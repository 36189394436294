import { Color, TextProps } from '../../components'
import {
  OrderMainStatus,
  OrderPaymentStatus,
  OrderPaymentStatusFilter,
  PurchaseStatus
} from '../../services'

export const getColorByPurchaseStatus = (status: PurchaseStatus): Color => {
  switch (status) {
    case PurchaseStatus.COMPLETED:
      return 'active'
    case PurchaseStatus.DECLINE:
      return 'error'
    default:
      return 'error'
  }
}

export const getColorByMainStatus = (status: OrderMainStatus): Color => {
  switch (status) {
    case OrderMainStatus.NOT_CONFIRMED:
      return 'notConfirmed'
    case OrderMainStatus.CONFIRMED:
      return 'blue'
    case OrderMainStatus.PRODUCTION_STARTED:
      return 'darkBlue'
    case OrderMainStatus.PRODUCTION_FINISHED:
      return 'yellow'
    case OrderMainStatus.PRODUCT_SHIPPED:
      return 'purple'
    case OrderMainStatus.PARCEL_DELIVERED:
      return 'green'
    case OrderMainStatus.CANCELED_BY_CUSTOMER:
    case OrderMainStatus.CANCELED_BY_VENDOR:
      return 'canceled'
    case OrderMainStatus.CANCELED_WITHOUT_REFUND:
      return 'canceledWithoutRefund'
    case OrderMainStatus.PAID:
      return 'green'
    case OrderMainStatus.REFUNDED:
      return 'refunded'
    case OrderMainStatus.PARTLY_REFUNDED:
      return 'partlyRefunded'
    default:
      return 'inactiveSecondary'
  }
}

export const getMessageByPurchaseStatus = (
  status: PurchaseStatus
): TextProps => {
  switch (status) {
    case PurchaseStatus.COMPLETED:
      return {
        text: 'Completed',
        tx: 'purchase.item.completed'
      }
    case PurchaseStatus.DECLINE:
      return {
        text: 'Decline',
        tx: 'purchase.item.decline'
      }
    default:
      return {
        text: 'Decline',
        tx: 'purchase.item.decline'
      }
  }
}

export const getMessageByPaymentStatus = (
  status: OrderPaymentStatus
): TextProps => {
  switch (status) {
    case OrderPaymentStatus.CONFIRMED:
      return {
        text: 'Confirmed',
        tx: 'paymentStatus.confirmed'
      }
    case OrderPaymentStatus.PROCESS:
      return {
        text: 'Process',
        tx: 'paymentStatus.process'
      }
    case OrderPaymentStatus.PENDING:
      return {
        text: 'Pending',
        tx: 'paymentStatus.pending'
      }
    case OrderPaymentStatus.BLOCKED:
      return {
        text: 'Blocked',
        tx: 'paymentStatus.blocked'
      }
    case OrderPaymentStatus.CANCELED:
      return {
        text: 'Canceled',
        tx: 'paymentStatus.canceled'
      }
    case OrderPaymentStatus.DENIED:
      return {
        text: 'Denied',
        tx: 'paymentStatus.denied'
      }
    case OrderPaymentStatus.FAILED:
      return {
        text: 'Failed',
        tx: 'paymentStatus.failed'
      }
    case OrderPaymentStatus.HELD:
      return {
        text: 'Held',
        tx: 'paymentStatus.held'
      }
    case OrderPaymentStatus.REFUNDED:
      return {
        text: 'Refunded',
        tx: 'paymentStatus.refunded'
      }
    case OrderPaymentStatus.RETURNED:
      return {
        text: 'Returned',
        tx: 'paymentStatus.returned'
      }
    case OrderPaymentStatus.SUCCEEDED:
      return {
        text: 'Succeeded',
        tx: 'paymentStatus.succeeded'
      }
    case OrderPaymentStatus.UNCLAIMED:
      return {
        text: 'Unclaimed',
        tx: 'paymentStatus.unclaimed'
      }
    default:
      return {
        text: 'Not Charged',
        tx: 'paymentStatus.notCharged'
      }
  }
}

export const getMessageByMainStatus = (status: OrderMainStatus): TextProps => {
  switch (status) {
    case OrderMainStatus.CONFIRMED:
      return { text: 'Confirmed', tx: 'mainStatus.confirmed' }
    case OrderMainStatus.PRODUCTION_STARTED:
      return { text: 'Production Started', tx: 'mainStatus.productionStarted' }
    case OrderMainStatus.PRODUCTION_FINISHED:
      return {
        text: 'Production Finished',
        tx: 'mainStatus.productionFinished'
      }
    case OrderMainStatus.PRODUCT_SHIPPED:
      return { text: 'Product Shipped', tx: 'mainStatus.productShipped' }
    case OrderMainStatus.PARCEL_DELIVERED:
      return { text: 'Parcel Delivered', tx: 'mainStatus.parcelDelivered' }
    case OrderMainStatus.CANCELED_BY_CUSTOMER:
      return { text: 'Canceled By Customer', tx: 'mainStatus.canceledCustomer' }
    case OrderMainStatus.CANCELED_BY_VENDOR:
      return { text: 'Canceled By Vendor', tx: 'mainStatus.canceledVendor' }
    case OrderMainStatus.CANCELED_WITHOUT_REFUND:
      return {
        text: 'Canceled without Refund',
        tx: 'mainStatus.canceledWithoutRefund'
      }
    case OrderMainStatus.PAID:
      return { text: 'Paid', tx: 'mainStatus.paid' }
    case OrderMainStatus.REFUNDED:
      return { text: 'Refunded', tx: 'mainStatus.refunded' }
    case OrderMainStatus.PARTLY_REFUNDED:
      return { text: 'Partly Refunded', tx: 'mainStatus.partlyRefunded' }
    default:
      return { text: 'Not Confirmed', tx: 'mainStatus.notConfirmed' }
  }
}

export const getMessageByPaymentStatusFilter = (
  status: OrderPaymentStatusFilter
): TextProps => {
  switch (status) {
    case OrderPaymentStatusFilter.CONFIRMED:
      return {
        text: 'Confirmed',
        tx: 'paymentStatus.process'
      }
    case OrderPaymentStatusFilter.PROCESS:
      return {
        text: 'Process',
        tx: 'paymentStatus.process'
      }
    case OrderPaymentStatusFilter.PENDING:
      return {
        text: 'Pending',
        tx: 'paymentStatus.pending'
      }
    case OrderPaymentStatusFilter.BLOCKED:
      return {
        text: 'Blocked',
        tx: 'paymentStatus.blocked'
      }
    case OrderPaymentStatusFilter.CANCELED:
      return {
        text: 'Canceled',
        tx: 'paymentStatus.canceled'
      }
    case OrderPaymentStatusFilter.FAILED:
      return {
        text: 'Failed',
        tx: 'paymentStatus.failed'
      }
    case OrderPaymentStatusFilter.REFUNDED:
      return {
        text: 'Refunded',
        tx: 'paymentStatus.refunded'
      }
    case OrderPaymentStatusFilter.RETURNED:
      return {
        text: 'Returned',
        tx: 'paymentStatus.returned'
      }
    case OrderPaymentStatusFilter.SUCCEEDED:
      return {
        text: 'Succeeded',
        tx: 'paymentStatus.succeeded'
      }
    default:
      return {
        text: 'Not Charged',
        tx: 'paymentStatus.notCharged'
      }
  }
}
