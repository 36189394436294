import React, { FC, useState } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { MainMenuItem } from '../../../../services/menu'
import { Column } from '../../../column'
import { Row } from '../../../row'
import { Text } from '../../../text'
import { LightTheme } from '../../../Theme'

export interface HeaderNavSubMenuProps {
  item: MainMenuItem
}

export const useStyle = createUseStyles((theme: LightTheme) => ({
  containerChild: {
    backgroundColor: theme.colors.lightBlue,
    top: 106,
    left: 0,
    right: 0,
    padding: '12px 136px',
    position: 'fixed',
    width: 'calc(100% - 272px)',
    zIndex: 2
  },
  item: {
    cursor: 'pointer',
    '&:not(:first-child)': {
      marginLeft: 40
    },
    '& > span:hover': {
      color: theme.colors.yellow
    }
  },
  child: {
    cursor: 'pointer',
    marginLeft: 40,
    '&:hover': {
      color: theme.colors.yellow
    }
  }
}))

export const HeaderNavSubMenu: FC<HeaderNavSubMenuProps> = ({ item }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [over, changeOver] = useState(false)

  const handleOnMouseOver = (state: boolean) => () => {
    changeOver(state)
  }

  return (
    <Column className={classes.item} alignItems="flex-start">
      <Text
        color="inactive"
        preset="h6"
        text={item.name}
        onClick={handleOnMouseOver(true)}
      />
      {over && item.children.length > 0 && (
        <Row className={classes.containerChild} justifyContent="flex-start">
          {item.children.map((child) => (
            <Row key={child.id} onClick={handleOnMouseOver(false)}>
              <Text
                className={classes.child}
                color="inactive"
                preset="h6"
                text={child.name}
              />
            </Row>
          ))}
        </Row>
      )}
    </Column>
  )
}
