import React, { ChangeEvent, FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  arrayHasElements,
  BackBreadcrumbs,
  Button,
  Column,
  LoadPhotoContainer,
  PageForm,
  QuestionConfirmModal,
  RadioBox,
  RadioItemWithIdProps,
  Row,
  Section,
  Text,
  TextField
} from '../../components'
import { CollectionCreateInput } from '../../services'

import { useStyle } from './collection-form.styles'
import { CollectionFormProps } from './collection-form.types'

export const visibilityRadioData: RadioItemWithIdProps[] = [
  { id: '1', name: 'Visible', nameTx: 'banner.visibility.active' },
  { id: '0', name: 'Invisible', nameTx: 'banner.visibility.inactive' }
]

export const CollectionForm: FC<CollectionFormProps> = ({
  collection,
  title,
  titleTx,
  onSubmit,
  onCancel,
  onRemove
}) => {
  const classes = useStyle()
  const history = useHistory()
  const [value, changeValue] = useState<CollectionCreateInput>({
    name: collection?.name || '',
    isPublished: collection?.isPublished
  })
  const [openDelete, changeOpenDelete] = useState(false)
  const imageNotFound = !collection?.backgroundImage && !value.backgroundImage
  const isDisabled = imageNotFound || !value.name
  const visibleId = String(Number(value?.isPublished || false))
  const imageDefaultValue = collection?.backgroundImage?.url
    ? [collection.backgroundImage.url]
    : undefined

  const handleOnOpenDeleteCollection = (state: boolean) => () => {
    changeOpenDelete(state)
  }

  const handleOnOpenModalDelete = () => {
    const openModalDelete = handleOnOpenDeleteCollection(true)

    openModalDelete()
  }

  const handleOnChangeVisibility = (nextValue: string) => {
    const isPublished = Boolean(Number(nextValue))

    changeValue({
      ...value,
      isPublished
    })
  }

  const handleOnChange =
    (prop: keyof CollectionCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      changeValue({
        ...value,
        [prop]: event.target.value
      })
    }

  const handleOnChangeImage = (images: File[]) => {
    if (arrayHasElements(images)) {
      changeValue({
        ...value,
        backgroundImage: images[0]
      })
    }
  }

  const handleOnSubmit = () => {
    if (onSubmit) {
      onSubmit(value)
    }
  }
  const handleOnRemove = () => {
    const close = handleOnOpenDeleteCollection(false)

    if (onRemove) {
      close()
      onRemove()
      history.goBack()
    }
  }

  const leftPannel = onRemove && (
    <Button
      className={classes.button}
      color="yellow"
      preset="fixedBorderButton"
      textColor="yellow"
      textPreset="h5"
      text="REMOVE"
      tx="autumn.collections.button"
      onClick={handleOnOpenModalDelete}
    />
  )

  return (
    <>
      <PageForm
        pannelLeftContent={leftPannel}
        disabled={isDisabled}
        onBack={onCancel}
        onSave={handleOnSubmit}
      >
        <BackBreadcrumbs text="Collections" />
        <Text className={classes.title} preset="h3" text={title} tx={titleTx} />
        <Row fullWidth alignItems="flex-start" className={classes.content}>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.leftColumn}
          >
            <Section
              className={classes.section}
              title="General Information"
              titleTx="item.form.general.title"
            >
              <Column className={classes.sectionAdditionContainer}>
                <TextField
                  label="Title"
                  labelTx="banner.title"
                  defaultValue={value.name}
                  onChange={handleOnChange('name')}
                />
              </Column>
            </Section>
            <LoadPhotoContainer
              className={classes.section}
              title="Background image"
              titleTx="item.collections.image"
              defaultValue={imageDefaultValue}
              onChange={handleOnChangeImage}
            />
          </Column>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.rightColumn}
          >
            <Section
              className={classes.section}
              title="Visibility"
              titleTx="item.form.visibility.title"
            >
              <RadioBox
                active={visibleId}
                className={classes.sectionAdditionContainer}
                data={visibilityRadioData}
                onChange={handleOnChangeVisibility}
              />
            </Section>
          </Column>
        </Row>
      </PageForm>
      {openDelete && (
        <QuestionConfirmModal
          title="Delete Collection"
          titleTx="collection.modal.delete.title"
          description="Are you sure?"
          descriptionTx="collection.modal.delete.description"
          secondaryDescription="Delete your collection? This cannot be undone."
          secondaryDescriptionTx="collection.modal.delete.secondaryDescription"
          onClose={handleOnOpenDeleteCollection(false)}
          onSubmit={handleOnRemove}
        />
      )}
    </>
  )
}
