import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Avatar, Row, Text } from '../../../components'

import { getFormatedOrderDate } from '../../views'
import {
  CabinetNavigatorPathVendor,
  FeedbackMonitoringPageNavigation
} from '../../cabinet-navigator'

import { useStyle } from './feedbacks-activity-table-item.styles'
import { FeedbackTableItemProps } from './feedbacks-activity-table-item.types'

export const FeedbackTableItem: FC<FeedbackTableItemProps> = ({
  id,
  comment,
  vendor,
  user,
  product,
  createdAt,
  type,
  rating
}) => {
  const classes = useStyle()
  const history = useHistory()

  const customerName = `${user.firstName} ${user.lastName}`
  const toVendorLink = `${CabinetNavigatorPathVendor.FEEDBACK_MONITORING}${FeedbackMonitoringPageNavigation.FEEDBACK_TO_VENDOR}`
  const toProductLink = `${CabinetNavigatorPathVendor.FEEDBACK_MONITORING}${FeedbackMonitoringPageNavigation.FEEDBACK_TO_PRODUCT}`

  const handleOnClick = () => {
    if (vendor) {
      history.push(`${toVendorLink}?search=${user.firstName}&id=${id}`)
    } else {
      history.push(`${toProductLink}?search=${user.firstName}&id=${id}`)
    }
  }

  return (
    <Row
      fullWidth
      key={`feedback_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      {vendor && (
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <Row className={classes.imageContainer}>
            <Avatar
              alt="user"
              src={vendor.avatar?.url}
              name={vendor.companyName}
              radius={48}
            />
          </Row>
          <Text
            className={`${classes.name} ${classes.textHiden}`}
            preset="body"
            text={`${vendor.companyName}`}
            tx="orders.table.item.number"
          />
        </Row>
      )}
      {product && (
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <img
            className={classes.imageProduct}
            src={product.thumbnail2x.url}
            alt={product.thumbnail2x.alt}
            width={48}
            height={48}
          />
          <Text
            className={`${classes.name} ${classes.textHiden}`}
            color="black"
            preset="body"
            text={product.name}
            tx="orders.table.item.number"
          />
        </Row>
      )}
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {type && <Text color="black" preset="body" text={type} />}
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.minTableColumn}`}
        justifyContent="flex-start"
      >
        {rating && (
          <Text color="black" preset="body" text={String(rating?.toFixed(1))} />
        )}
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.maxTableColumn}`}
        justifyContent="flex-start"
      >
        {comment && (
          <Text
            className={classes.textHiden}
            color="black"
            preset="body"
            text={comment}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {user && <Text color="black" preset="body" text={customerName} />}
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.minTableColumn}`}
        justifyContent="flex-start"
      >
        {createdAt && (
          <Text
            color="black"
            preset="body"
            text={getFormatedOrderDate(createdAt)}
          />
        )}
      </Row>
    </Row>
  )
}
