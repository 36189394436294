import { TypePolicies, useMutation, useQuery } from '@apollo/client'

import {
  BANNERS,
  CREATE_BANNER,
  IMAGE_BANNER_CREATE,
  MAIN_MENU,
  SECONDARY_MENU,
  UPDATE_BANNER,
  BANNER_REMOVE_MUTATION,
  VENDOR_BANNERS,
  VENDOR_BANNER_CREATE_MUTATION,
  VENDOR_BANNER_UPDATE_MUTATION,
  VENDOR_BANNER_IMAGE_CREATE_MUTATION,
  VENDOR_BANNER_REMOVE_MUTATION,
  PROMOTION_BANNERS,
  PROMOTION_BANNER_REMOVE_MUTATION,
  UPDATE_PROMOTION_BANNER,
  CREATE_PROMOTION_BANNER
} from './menu.graphql'
import {
  CreateBanner,
  CreateBannerInput,
  CreateBannerRequest,
  CreateBannerResults,
  EditBannerInput,
  GetHomeBanners,
  HomeBanners,
  ImageBannerCreate,
  ImageBannerCreateRequest,
  ImageBannerCreateResults,
  MainMenu,
  MainMenuShop,
  MenuApi,
  BannersVariables,
  SecondaryMenu,
  SecondaryMenuShop,
  UpdateBanner,
  UpdateBannerRequest,
  UpdateBannerResults,
  BannerRemove,
  BannerRemoveVariables,
  GetBannerRemoveResults,
  GetHomeVendorBanners,
  HomeVendorBanners,
  VendorBannersVariables,
  VendorBannerCreate,
  VendorBannerCreateInput,
  VendorBannerCreateRequest,
  VendorBannerCreateResults,
  EditVendorBannerInput,
  VendorBannerUpdate,
  VendorBannerUpdateRequest,
  VendorBannerUpdateResults,
  GetVendorBannerRemoveResults,
  VendorBannerImageCreate,
  VendorBannerImageCreateResults,
  VendorBannerImageCreateRequest,
  VendorBannerRemove,
  PromotionBannersVariables,
  GetPromotionBanners,
  PromotionBanners,
  CreatePromotionBanner,
  CreatePromotionBannerResults,
  CreatePromotionBannerRequest,
  PromotionBannerCreateInput,
  UpdatePromotionBanner,
  UpdatePromotionBannerResults,
  UpdatePromotionBannerRequest,
  EditPromotionBannerInput,
  PromotionBannerRemove,
  GetPromotionBannerRemoveResults,
  PromotionBannerRemoveVariables
} from './menu.types'

export const cacheMenuConfig: TypePolicies = {
  VendorBannerImage: {
    fields: {
      url: {
        merge(excoming, incoming) {
          if (excoming === incoming) {
            return undefined
          }
          return incoming
        }
      }
    }
  }
}

// our "constructor"
export const menuService = (): MenuApi => {
  const useSecondaryMenu = (): SecondaryMenu => {
    const { data, loading, error } = useQuery<SecondaryMenuShop, any>(
      SECONDARY_MENU
    )

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useMainMenu = (): MainMenu => {
    const { data, loading, error, refetch } = useQuery<MainMenuShop, any>(
      MAIN_MENU
    )

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useBanners = (variables: BannersVariables): GetHomeBanners => {
    const { data, loading, refetch, error } = useQuery<
      HomeBanners,
      BannersVariables
    >(BANNERS, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const usePromotionBanners = (
    variables: PromotionBannersVariables
  ): GetPromotionBanners => {
    const { data, loading, refetch, error } = useQuery<
      PromotionBanners,
      PromotionBannersVariables
    >(PROMOTION_BANNERS, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCreateBanner = (): CreateBanner => {
    const [onCreateBanner, response] = useMutation<
      CreateBannerResults,
      CreateBannerRequest
    >(CREATE_BANNER)

    const handleOnSubmit = (banner: CreateBannerInput) => {
      const options = {
        variables: {
          input: { ...banner, __typename: undefined }
        }
      }

      onCreateBanner(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useCreatePromotionBanner = (): CreatePromotionBanner => {
    const [onCreatePromotionBanner, response] = useMutation<
      CreatePromotionBannerResults,
      CreatePromotionBannerRequest
    >(CREATE_PROMOTION_BANNER)

    const handleOnSubmit = (promotionBanner: PromotionBannerCreateInput) => {
      const options = {
        variables: {
          input: { ...promotionBanner, __typename: undefined }
        }
      }

      onCreatePromotionBanner(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useUpdateBanner = (): UpdateBanner => {
    const [onUpdateBanner, response] = useMutation<
      UpdateBannerResults,
      UpdateBannerRequest
    >(UPDATE_BANNER)

    const handleOnSubmit = (id: string, banner: EditBannerInput) => {
      const options = {
        variables: {
          id,
          input: { ...banner, __typename: undefined }
        }
      }

      onUpdateBanner(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useUpdatePromotionBanner = (): UpdatePromotionBanner => {
    const [onUpdatePtomotionBanner, response] = useMutation<
      UpdatePromotionBannerResults,
      UpdatePromotionBannerRequest
    >(UPDATE_PROMOTION_BANNER)

    const handleOnSubmit = (
      id: string,
      promotionBanner: EditPromotionBannerInput
    ) => {
      const options = {
        variables: {
          id,
          input: { ...promotionBanner, __typename: undefined }
        }
      }

      onUpdatePtomotionBanner(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRemoveBanner = (): BannerRemove => {
    const [onRemoveBanner, response] = useMutation<
      GetBannerRemoveResults,
      BannerRemoveVariables
    >(BANNER_REMOVE_MUTATION)

    const handleOnSubmit = (variables: BannerRemoveVariables) => {
      const options = {
        variables
      }
      onRemoveBanner(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRemovePromotionBanner = (): PromotionBannerRemove => {
    const [onRemovePromotionBanner, response] = useMutation<
      GetPromotionBannerRemoveResults,
      PromotionBannerRemoveVariables
    >(PROMOTION_BANNER_REMOVE_MUTATION)

    const handleOnSubmit = (variables: PromotionBannerRemoveVariables) => {
      const options = {
        variables
      }
      onRemovePromotionBanner(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useImageBannerCreate = (): ImageBannerCreate => {
    const [onImageBannerCreate, response] = useMutation<
      ImageBannerCreateResults,
      ImageBannerCreateRequest
    >(IMAGE_BANNER_CREATE)

    const handleOnSubmit = (id: string, file: File) => {
      const options = {
        variables: {
          id,
          file
        }
      }

      onImageBannerCreate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useVendorBanners = (
    variables: VendorBannersVariables
  ): GetHomeVendorBanners => {
    const { data, loading, refetch, error } = useQuery<
      HomeVendorBanners,
      VendorBannersVariables
    >(VENDOR_BANNERS, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useVendorBannerCreate = (): VendorBannerCreate => {
    const [onCreateBanner, response] = useMutation<
      VendorBannerCreateResults,
      VendorBannerCreateRequest
    >(VENDOR_BANNER_CREATE_MUTATION)

    const handleOnSubmit = (vendorBanner: VendorBannerCreateInput) => {
      const options = {
        variables: {
          input: { ...vendorBanner, __typename: undefined }
        }
      }

      onCreateBanner({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<HomeVendorBanners>({
            query: VENDOR_BANNERS
          })
          if (queryResult) {
            cache.writeQuery({
              query: VENDOR_BANNERS,
              variables: { first: 10 },
              data: {
                ...queryResult,
                vendorBanners: {
                  ...queryResult.vendorBanners,
                  edges: [
                    {
                      node: result.data?.vendorBannerCreate.vendorBanner,
                      cursor: ''
                    },
                    ...queryResult.vendorBanners.edges
                  ]
                }
              }
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useVendorBannerUpdate = (): VendorBannerUpdate => {
    const [onUpdateBanner, response] = useMutation<
      VendorBannerUpdateResults,
      VendorBannerUpdateRequest
    >(VENDOR_BANNER_UPDATE_MUTATION)

    const handleOnSubmit = (
      id: string,
      vendorBanner: EditVendorBannerInput
    ) => {
      const options = {
        variables: {
          id,
          input: { ...vendorBanner, __typename: undefined }
        }
      }

      onUpdateBanner({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<HomeVendorBanners>({
            query: VENDOR_BANNERS
          })
          if (queryResult) {
            cache.writeQuery({
              query: VENDOR_BANNERS,
              variables: { first: 10 },
              data: {
                ...queryResult,
                vendorBanners: {
                  ...queryResult.vendorBanners,
                  edges: queryResult.vendorBanners.edges.map((item) => {
                    if (result.data) {
                      const { vendorBanner: data } =
                        result.data.vendorBannerUpdate
                      if (item.node.id === data.id) {
                        return {
                          ...item,
                          node: data
                        }
                      }
                    }

                    return item
                  })
                }
              }
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useVendorBannerRemove = (): VendorBannerRemove => {
    const [onRemoveBanner, response] = useMutation<
      GetVendorBannerRemoveResults,
      BannerRemoveVariables
    >(VENDOR_BANNER_REMOVE_MUTATION)

    const handleOnSubmit = (variables: BannerRemoveVariables) => {
      const options = {
        variables
      }
      onRemoveBanner({
        ...options,
        update(cache) {
          const queryResult = cache.readQuery<HomeVendorBanners>({
            query: VENDOR_BANNERS
          })
          if (queryResult) {
            cache.writeQuery({
              query: VENDOR_BANNERS,
              data: {
                ...queryResult,
                vendorBanners: {
                  ...queryResult.vendorBanners,
                  edges: queryResult.vendorBanners.edges.filter(
                    (vendorBanner) => vendorBanner.node.id !== variables.id
                  )
                }
              }
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useVendorBannerImageCreate = (): VendorBannerImageCreate => {
    const [onImageBannerCreate, response] = useMutation<
      VendorBannerImageCreateResults,
      VendorBannerImageCreateRequest
    >(VENDOR_BANNER_IMAGE_CREATE_MUTATION)

    const handleOnSubmit = (variables: VendorBannerImageCreateRequest) => {
      const options = {
        variables
      }

      onImageBannerCreate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useBanners,
    useCreateBanner,
    useMainMenu,
    useSecondaryMenu,
    useUpdateBanner,
    useImageBannerCreate,
    useRemoveBanner,
    useVendorBanners,
    useVendorBannerCreate,
    useVendorBannerUpdate,
    useVendorBannerRemove,
    useVendorBannerImageCreate,
    usePromotionBanners,
    useCreatePromotionBanner,
    useUpdatePromotionBanner,
    useRemovePromotionBanner
  }
}
