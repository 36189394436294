import React, { forwardRef } from 'react'

import { Row } from '../../row'

import { useStyle } from './order-row.styles'
import { OrderRowProps } from './order-row.types'

export const OrderRow = forwardRef<HTMLDivElement, OrderRowProps>(
  ({ children, className = '', ...props }, ref) => {
    const classes = useStyle()

    return (
      <Row
        fullWidth
        className={`${className} ${classes.container}`}
        justifyContent="flex-start"
        {...props}
        ref={ref}
      >
        {children}
      </Row>
    )
  }
)
