import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme, Color } from '../../Theme'
import { Icon, ICONS } from '../../icon'
import { Row } from '../../row'

import { useStyle } from './order-staging-dot.styles'
import { OrderStagingDotProps } from './order-staging-dot.types'

export const OrderStagingDot: FC<OrderStagingDotProps> = ({
  active,
  disabled,
  className = ''
}) => {
  const [focus, changeFocus] = useState(false)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ active, disabled, theme })

  const disabledColor: Color = disabled ? 'white' : 'yellow'
  const activeColor: Color = focus ? disabledColor : 'white'

  const handleOnHover = (state: boolean) => () => {
    changeFocus(state)
  }

  return (
    <Row
      className={`${className} ${classes.container}`}
      onMouseMove={handleOnHover(true)}
      onMouseLeave={handleOnHover(false)}
    >
      <Icon
        fill={theme.colors[activeColor]}
        height={24}
        width={24}
        src={ICONS.doneAll}
      />
    </Row>
  )
}
