import { useQuery, useMutation } from '@apollo/client'

import {
  GetSubscriptionTypes,
  GetSubscriptionTypesData,
  SubscriptionTypeApi,
  SubscriptionTypesVariables,
  CreateSubscriptionPaypalResults,
  CreateSubscriptionPaypal,
  CreateSubscriptionPaypalVariables
} from './profile-settings.types'
import {
  SUBSCRIPTION_TYPE_QUERY,
  SUBSCRIPTION_CREATE
} from './profile-settings.graphql'

export const subscriptionService = (): SubscriptionTypeApi => {
  const useSubscriptionTypes = (
    variables: SubscriptionTypesVariables
  ): GetSubscriptionTypes => {
    const { data, loading, error, refetch } = useQuery<
      GetSubscriptionTypesData,
      SubscriptionTypesVariables
    >(SUBSCRIPTION_TYPE_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCreateSubscription = (): CreateSubscriptionPaypal => {
    const [onCreateSubscription, response] = useMutation<
      CreateSubscriptionPaypalResults,
      CreateSubscriptionPaypalVariables
    >(SUBSCRIPTION_CREATE)

    const handleOnSubmit = (variables: CreateSubscriptionPaypalVariables) => {
      const options = {
        variables
      }
      onCreateSubscription(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useSubscriptionTypes,
    useCreateSubscription
  }
}
