import React, { FC } from 'react'

import {
  Column,
  ICONS,
  Loader,
  Row,
  TablePagginationBar,
  Text,
  TextField,
  SortItem
} from '../../../components'
import { OrderDirection, OrderSortField } from '../../../services'

import { OrdersActivityTableItem } from '../orders-activity-table-item'

import { useStyle } from './orders-activity-table.styles'
import { OrdersActivityTableProps } from './orders-activity-table.types'

export const OrdersActivityTable: FC<OrdersActivityTableProps> = ({
  className = '',
  children,
  data,
  loading,
  paginationState,
  rowCounts,
  onChangePage,
  onChangeSearch,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount,
  onClickItem,
  onClick,
  onSort
}) => {
  const classes = useStyle()

  const handleOnSort =
    (feedbackOrderField: OrderSortField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(feedbackOrderField, direction)
      }
    }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
        {children}
      </Row>
      <Column fullWidth>
        <Row
          fullWidth
          className={classes.header}
          justifyContent="space-between"
        >
          <Row
            className={`${classes.tableColumn} ${classes.maxTableColumn}`}
            justifyContent="flex-start"
          >
            <SortItem
              defaultOpen
              name="Order Number"
              nameTx="orders.table.order.number"
              onClick={handleOnSort(OrderSortField.NUMBER)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Event"
              tx="orders.table.order.event"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Name of Purchaser"
              tx="orders.table.order.purchaser"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Status"
              tx="orders.table.order.status"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Date"
              nameTx="orders.table.order.date"
              onClick={handleOnSort(OrderSortField.CREATION_DATE)}
            />
          </Row>
          <Row
            className={`${classes.tableColumn} ${classes.minTableColumn}`}
            justifyContent="flex-start"
          >
            <SortItem
              name="Total Price"
              nameTx="orders.table.order.price"
              onClick={handleOnSort(OrderSortField.TOTAL_PRICE)}
            />
          </Row>
        </Row>
        {loading && <Loader />}
        {!loading &&
          data.map((order) => (
            <OrdersActivityTableItem
              key={`order_${order.id}`}
              {...order}
              onClickItem={onClickItem}
              onClick={onClick}
            />
          ))}
      </Column>

      {!loading && paginationState && (
        <TablePagginationBar
          rowCounts={rowCounts}
          paginationState={paginationState}
          onChangePage={onChangePage}
          onPrevioslyPage={onPrevioslyPage}
          onNextPage={onNextPage}
          onChangeRowCount={onChangeRowCount}
        />
      )}
    </Column>
  )
}
