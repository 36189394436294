import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  tableColumn: {
    flex: 2,
    padding: '8px 16px',
    width: '48px'
  },
  maxTableColumn: {
    flex: 3,
    paddingLeft: 0
  },
  arowIcon: {
    marginLeft: 12
  }
})
