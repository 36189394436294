import React, { useState, FC, useMemo } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import { CabinetNavigatorPathAdmin } from '../../cabinet-navigator'
import {
  arrayHasElements,
  Button,
  Checkbox,
  Column,
  Icon,
  ICONS,
  Row,
  Text,
  TextField,
  TablePagginationBar,
  LoadingContainer,
  QuestionConfirmModal,
  EmptySection
} from '../../../components'
import { LightTheme } from '../../../components/Theme'

import { BannerTableItem } from '../banner-table-item'
import { useStyle } from './banners-table.styles'
import { BannersTableProps } from './banners-table.types'

export const BannersTable: FC<BannersTableProps> = ({
  className = '',
  data,
  loading,
  paginationState,
  rowCounts,
  onRemove,
  onUpdate,
  onChangePage,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount,
  onSearch
}) => {
  const history = useHistory()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const [checkedIds, changeCheckedIds] = useState<string[]>([])
  const [openRemoveModal, changeOpenRemoveModal] = useState(false)

  const checkedItemsCount = checkedIds.length
  const checkedItemsCountValue = { checkedItemsCount }
  const checkedItemText = `Selected ${checkedItemsCount} items`

  const CheckedAll = useMemo(() => {
    return checkedIds.length === data.length
  }, [checkedIds, data])

  const handleOnPublish = () => {
    const publishData = data
      .filter((item) => checkedIds.includes(item.id))
      .map((item) => {
        return { ...item, isVisible: true }
      })
    if (onUpdate && publishData.length) {
      onUpdate(publishData)
    }
  }

  const handleOnUnpublish = () => {
    const publishData = data
      .filter((item) => checkedIds.includes(item.id))
      .map((item) => {
        return { ...item, isVisible: false }
      })
    if (onUpdate && publishData.length) {
      onUpdate(publishData)
    }
  }

  const handleOnPick = (id: string, state: boolean) => {
    const filteredIds = checkedIds.filter((removeId) => removeId !== id)
    if (state) {
      changeCheckedIds([...filteredIds, id])
    } else {
      changeCheckedIds(filteredIds)
    }
  }

  const handleOnPickAll = (state: boolean) => {
    if (state) {
      const ids = data.map((product) => product.id)
      changeCheckedIds(ids)
    } else {
      changeCheckedIds([])
    }
  }

  const getActive = (id: string) => {
    return Boolean(checkedIds.find((removeId) => removeId === id))
  }

  const handleOnClickItem = (id: string) => {
    history.push(`${CabinetNavigatorPathAdmin.EDIT_BANNERS}/${id}`)
  }

  const handleOnOpenRemoveModal = (state: boolean) => () => {
    changeOpenRemoveModal(state)
  }

  const handleOnRemove = () => {
    const close = handleOnOpenRemoveModal(false)
    close()

    if (onRemove) {
      changeCheckedIds([])
      onRemove(checkedIds)
    }
  }

  const funcSelect = arrayHasElements(checkedIds) && (
    <Text
      className={classes.textCheckBox}
      preset="body"
      text={checkedItemText}
      tx="item.table.selected.items"
      values={checkedItemsCountValue}
    />
  )

  const funcHeader = arrayHasElements(checkedIds) && (
    <Row>
      <Row className={classes.buttonContainer}>
        <Button
          className={classes.button}
          preset="secondaryBorderMini"
          text="Activate"
          textPreset="maxButton"
          textColor="yellow"
          tx="cabinet.banners.activate.button"
          onClick={handleOnPublish}
        />
        <Button
          className={classes.button}
          preset="secondaryBorderMini"
          text="Deactivate"
          textPreset="maxButton"
          textColor="yellow"
          tx="cabinet.banners.deactivate.button"
          onClick={handleOnUnpublish}
        />
      </Row>
      <Row className={classes.remove} onClick={handleOnOpenRemoveModal(true)}>
        <Icon src={ICONS.trash} />
      </Row>
    </Row>
  )

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row
        fullWidth
        alignItems="flex-start"
        className={classes.tableTopContainer}
      >
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onSearch}
        />
      </Row>
      <Row fullWidth className={classes.header} justifyContent="space-between">
        <Row className={classes.tableColumnFirst} justifyContent="flex-start">
          <Checkbox checked={CheckedAll} onChange={handleOnPickAll} />
          {funcSelect}
        </Row>
        {funcHeader || (
          <>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Text color="black" preset="h6" text="Name" />
            </Row>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Text color="black" preset="h6" text="Type" />
            </Row>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Text color="black" preset="h6" text="Status" />
            </Row>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Text color="black" preset="h6" text="Duration" />
            </Row>
          </>
        )}
      </Row>
      <LoadingContainer loading={loading}>
        {data.map((banner) => (
          <BannerTableItem
            key={`banner_${banner.id}`}
            {...banner}
            id={banner.id}
            name={banner.name}
            image={banner.image}
            kind={banner.kind}
            isVisible={banner.isVisible}
            startDate={banner.startDate}
            endDate={banner.endDate}
            checked={getActive(banner.id)}
            onChange={handleOnPick}
            onClick={handleOnClickItem}
          />
        ))}
        {!arrayHasElements(data) && (
          <EmptySection text="No Banners found." tx="banner.no.banners.found" />
        )}
        {paginationState && (
          <TablePagginationBar
            paginationState={paginationState}
            rowCounts={rowCounts}
            onNextPage={onNextPage}
            onPrevioslyPage={onPrevioslyPage}
            onChangePage={onChangePage}
            onChangeRowCount={onChangeRowCount}
          />
        )}
      </LoadingContainer>

      {openRemoveModal && (
        <QuestionConfirmModal
          title="Delete Banners"
          titleTx="delete.banners.modal.title"
          description="Are you sure?"
          descriptionTx="delete.banners.modal.description"
          secondaryDescription="Delete your banners? This cannot be undone."
          secondaryDescriptionTx="delete.banners.modal.secondaryDescription"
          successText="Delete"
          successTx="delete.banners.modal.success"
          onSubmit={handleOnRemove}
          onClose={handleOnOpenRemoveModal(false)}
        />
      )}
    </Column>
  )
}
