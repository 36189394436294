import { useMutation, useQuery } from '@apollo/client'

import {
  GetHomeCollections,
  GetHomeCollectionsRequest,
  CollectionsApi,
  HomeCollectionVariables,
  CollectionByIdVariables,
  GetCollectionById,
  GetCollectionByIdRequest,
  CollectionCreateVariables,
  CollectionCreateData,
  CollectionCreate,
  CollectionDelete,
  CollectionUpdate,
  CollectionUpdateVariables,
  CollectionUpdateData,
  CollectionDeleteVariables,
  CollectionDeleteData,
  VendorCollectionsVariables,
  GetVendorCollections,
  GetVendorCollectionRequest
} from './collection.types'

import {
  COLLECTION_BY_ID_QUERY,
  CREATE_COLLECTIONS,
  DELETE_COLLECTION,
  HOME_COLLECTIONS_QUERY,
  UPDATE_COLLECTIONS,
  VENDOR_COLLECTIONS_QUERY
} from './collection.graphql'

// our "constructor"
export const collectionService = (): CollectionsApi => {
  const useHomeCollections = (
    variables: HomeCollectionVariables
  ): GetHomeCollectionsRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetHomeCollections,
      HomeCollectionVariables
    >(HOME_COLLECTIONS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }
  const useVendorCollections = (
    variables: VendorCollectionsVariables
  ): GetVendorCollectionRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetVendorCollections,
      VendorCollectionsVariables
    >(VENDOR_COLLECTIONS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCollectionById = (
    variables: CollectionByIdVariables
  ): GetCollectionByIdRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetCollectionById,
      CollectionByIdVariables
    >(COLLECTION_BY_ID_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCollectionCreate = (): CollectionCreate => {
    const [onProductCreate, response] = useMutation<
      CollectionCreateData,
      CollectionCreateVariables
    >(CREATE_COLLECTIONS)

    const handleOnSubmit = (variables: CollectionCreateVariables) => {
      const options = {
        variables
      }
      onProductCreate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useCollectionUpdate = (): CollectionUpdate => {
    const [onProductCreate, response] = useMutation<
      CollectionUpdateData,
      CollectionUpdateVariables
    >(UPDATE_COLLECTIONS)

    const handleOnSubmit = (variables: CollectionUpdateVariables) => {
      const options = {
        variables
      }
      onProductCreate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useCollectionDelete = (): CollectionDelete => {
    const [onProductCreate, response] = useMutation<
      CollectionDeleteData,
      CollectionDeleteVariables
    >(DELETE_COLLECTION)

    const handleOnSubmit = (variables: CollectionDeleteVariables) => {
      const options = {
        variables
      }
      onProductCreate(options)
    }
    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useHomeCollections,
    useCollectionById,
    useCollectionUpdate,
    useCollectionCreate,
    useCollectionDelete,
    useVendorCollections
  }
}
