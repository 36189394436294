import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  modalContainer: {
    boxShadow: `4px 8px 24px ${theme.colors.shadow}`,
    width: '1160px !important'
  },
  container: {
    maxHeight: 685,
    padding: '24px 56px',
    overflowY: 'auto',
    '&::-webkit-scrollbar-thumb': {
      borderBottom: `4px solid ${theme.colors.white}`,
      borderRadius: '0 0 4px'
    }
  },
  categories: {
    marginTop: 48
  }
}))
