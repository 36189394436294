import React, { FC } from 'react'
import { FlagProps } from './flag.types'

export const Flag: FC<FlagProps> = ({ src, ...props }) => {
  if (!src) {
    return <></>
  }

  return <img height={16} width={32} src={src} {...props} />
}
