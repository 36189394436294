import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    maxWidth: 608
  },
  image: {
    width: 600,
    objectFit: 'cover',
    overflow: 'hidden'
  },
  emptyImage: {},
  color: {
    width: 21,
    height: 21,
    borderRadius: '50%',
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  list: {
    width: 'auto'
  }
})
