import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  sectionAdditionContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: '100%'
  },
  area: {
    '&:focus-within': {
      border: `2px solid ${theme.colors.yellow}`,
      padding: '3px 22px',
      width: '100%'
    },
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 23px',
    width: '100%',
    marginTop: 16
  }
}))
