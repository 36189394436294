import React, { FC } from 'react'

import { NavLinkIconRow, PermissionContent } from '../../../components'

import { CabinetLinkProps } from './cabinet-link.types'

export const CabinetLink: FC<CabinetLinkProps> = ({
  isStaff,
  permissions,
  ...props
}) => {
  return (
    <PermissionContent isStaff={isStaff} permissions={permissions}>
      <NavLinkIconRow {...props} />
    </PermissionContent>
  )
}
