import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Column, Row, Button } from '../../components'
import { SortVariables, useSort, useTable } from '../../hooks'
import { useApi } from '../../providers'
import {
  Advertising,
  AdvertisingListVariables,
  AdvertisingSortField
} from '../../services/advertising'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { AdvertisementsFilters } from './advertisements-filters'
import {
  AdvertisementsTable,
  AdvertisementsTableProps
} from './advertisements-table'
import { useStyle } from './advertisements.styles'

const rowCounts = [5, 10, 20, 30]

export const AdvertisementsPage = () => {
  const history = useHistory()
  const classes = useStyle()
  const { advertising } = useApi()

  const defaultVariables = { first: rowCounts[1] }

  const [advertisingVariables, changeAdvertisingVariables] =
    useState<AdvertisingListVariables>({
      first: rowCounts[1]
    })

  const { onSubmit: onRemove } = advertising.useRemoveAdvertising()
  const { onSubmit: onUpdate } = advertising.useUpdateAdvertising()
  const { data, loading, refetch } =
    advertising.useAdvertisingList(defaultVariables)

  const {
    changeCurrentData,
    Data,
    currentData,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onNextPage,
    onPrevioslyPage,
    onSearch,
    onResetPageState,
    onResetFilters,
    onSubmitFilters
  } = useTable({
    refetch,
    data: data?.advertisingList,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  const handleOnCreate = () => {
    history.push(CabinetNavigatorPathVendor.CREATE_ADVRTISING)
  }

  const handleOnRemove = (ids: string[]) => {
    ids.forEach((id) => onRemove({ id }))

    const nextBanners = currentData.filter(({ node }) => {
      const currentItem = ids.find((id) => id === node.id)

      return !currentItem
    })

    changeCurrentData(nextBanners)
  }

  const handleOnUpdate = (publishData: Advertising[]) => {
    publishData.forEach((item) => {
      const newState = {
        startDate: item.startDate,
        isVisible: item.isVisible
      }
      onUpdate(item.id, newState)
    })
    const nextAdvertisings = currentData.map((advertisingItem) => {
      let isVisible = false
      if (
        publishData.find((item) => {
          isVisible = item.isVisible
          return item.id === advertisingItem.node.id
        })
      ) {
        return {
          ...advertisingItem,
          node: { ...advertisingItem.node, isVisible }
        }
      }
      return advertisingItem
    })
    changeCurrentData(nextAdvertisings)
  }

  const handleOnSearch: AdvertisementsTableProps['onSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnSort = (sortVariables: SortVariables<AdvertisingSortField>) => {
    if (refetch) {
      refetch({
        ...sortVariables,
        before: undefined,
        after: undefined,
        last: undefined
      })
      onResetPageState()
    }
    changeAdvertisingVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: advertisingVariables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="flex-end">
        <Button
          className={classes.button}
          preset="primary"
          text="CREATE ADVERTISING"
          textPreset="h5"
          textColor="white"
          tx="advertising.create.button.title"
          onClick={handleOnCreate}
        />
      </Row>
      <AdvertisementsTable
        loading={loading}
        paginationState={paginationState}
        rowCounts={rowCounts}
        className={classes.table}
        data={Data}
        onRemove={handleOnRemove}
        onUpdate={handleOnUpdate}
        onSearch={handleOnSearch}
        onChangePage={onChangePage}
        onPrevioslyPage={onPrevioslyPage}
        onNextPage={onNextPage}
        onChangeRowCount={onChangeRowCount}
        onSort={onSort}
      >
        <AdvertisementsFilters
          className={classes.filterButton}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
        />
      </AdvertisementsTable>
    </Column>
  )
}
