import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useMemo,
  useState
} from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import {
  BackBreadcrumbs,
  Button,
  Column,
  PageForm,
  QuestionConfirmModal,
  RadioBox,
  Row,
  Section,
  SubscriptionErrorModal,
  Text,
  UserInfoBadgeBalance
} from '../../components'
import {
  Advertising,
  AdvertisingCreateInput,
  AdvertisingLimitsData,
  AdvertisingType
} from '../../services/advertising'
import { useApi } from '../../providers'
import { getItemsFromEdges } from '../../services'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { defaultValue, typeRadioData } from './helpers'
import { ViewByAdvertisingType } from './view-by-advertising-type'
import { AdveretisingPriceSection } from './advertising-price-section'
import { AdsGeneralInfoSection } from './ads-general-info-section'
import { PickDateSection } from './pick-date-section'
import { useStyle } from './advertising-form.styles'
import { AdvertisingFormProps } from './advertising-form.types'

export const AdvertisingForm: FC<AdvertisingFormProps> = ({
  advertisingData = { ...defaultValue },
  product,
  collection,
  banner,
  id,
  balance,
  userActiveItemsCount,
  title,
  titleTx,
  onSumbit,
  onRemove
}) => {
  const { advertising } = useApi()
  const history = useHistory()
  const classes = useStyle()

  const { data } = advertising.useAdvetrisingLimits({
    first: 1
  })

  const [advertisingValue, changeAdvertisingValue] =
    useState<Partial<AdvertisingCreateInput>>(advertisingData)

  const [prevAdvertisingState, changePrevAdvertisingState] =
    useState<Partial<AdvertisingCreateInput>>(advertisingValue)

  const [openConfirmModal, changeOpenConfirmModal] = useState(false)
  const [openErrorModal, changeOpenErrorModal] = useState(false)

  const isDisabledByType = () => {
    const disabled =
      advertisingValue.product ||
      advertisingValue.collection ||
      advertisingValue.banner
    if (disabled) {
      return false
    }

    return true
  }

  const advertisingLimitsData = useMemo<AdvertisingLimitsData[]>(() => {
    if (data) {
      return getItemsFromEdges(data.advertisingLimits)
    }

    return []
  }, [data?.advertisingLimits])

  const Balance = useMemo(() => {
    if (balance) {
      return balance
    }

    return 0
  }, [balance])

  const advertisingLimitData = advertisingLimitsData[0]

  const advertisingLimitsValue = () => {
    if (advertisingLimitData) {
      if (advertisingValue.type === AdvertisingType.TOP_PRODUCT) {
        return advertisingLimitData.topProductPricePerDay
      } else {
        return advertisingLimitData.bannerPricePerDay
      }
    }

    return 0
  }

  const handleOnOpenErrorModal = () => {
    changeOpenErrorModal(!openErrorModal)
  }

  const handleOnCreateProduct = () => {
    if (userActiveItemsCount && userActiveItemsCount >= 5) {
      return handleOnOpenErrorModal()
    }
    history.push(CabinetNavigatorPathVendor.ITEMS_CREATE)
  }

  const handleOnCreateBanner = () => {
    history.push(CabinetNavigatorPathVendor.CREATE_PROMOTION_BANNER)
  }
  const handleOnCreateCollection = () => {
    history.push(CabinetNavigatorPathVendor.CREATE_COLLECTIONS)
  }

  const handleOnChangeProduct = (productId: string) => {
    changeAdvertisingValue({
      ...advertisingValue,
      product: productId
    })
  }

  const handleOnChangeCollection = (collectionId: string) => {
    changeAdvertisingValue({
      ...advertisingValue,
      collection: collectionId
    })
  }

  const handleOnChangeBanner = (bannerId: string) => {
    changeAdvertisingValue({
      ...advertisingValue,
      banner: bannerId
    })
  }

  const handleOnChangeDate = (prop: keyof Advertising) => (date: Date) => {
    const nextAdvertising = {
      ...advertisingValue,
      [prop]: moment(date).format('YYYY-MM-DD')
    }

    changeAdvertisingValue(nextAdvertising)
  }

  const handleOnChangeType = (nextValue: string) => {
    changeAdvertisingValue({
      ...advertisingValue,
      type: nextValue as AdvertisingType
    })
  }

  const handleOnChangeInput =
    (prop: keyof AdvertisingCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      const nextAdvertising: Partial<AdvertisingCreateInput> = {
        ...advertisingValue,
        [prop]: value
      }

      const nextPrevAdvertising: Partial<AdvertisingCreateInput> = {
        ...prevAdvertisingState,
        [prop]: value
      }

      changePrevAdvertisingState(nextPrevAdvertising)

      if (changeAdvertisingValue) {
        changeAdvertisingValue(nextAdvertising)
      }
    }

  const handleOnSubmit = () => {
    const nextValue = advertisingValue as AdvertisingCreateInput

    onSumbit({
      ...nextValue,
      pricePerDay: advertisingLimitsValue()
    })
  }

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove()
      changeOpenConfirmModal(false)
      history.goBack()
    }
  }

  const handleOnOpenConfirmModal =
    (callback: Dispatch<SetStateAction<boolean>>, state: boolean) => () => {
      return callback(state)
    }

  const startDate = advertisingValue.startDate
    ? new Date(advertisingValue.startDate)
    : undefined
  const endDate = advertisingValue.endDate
    ? new Date(advertisingValue.endDate)
    : startDate

  const getTotalPrice = () => {
    if (startDate && endDate) {
      const dater = new Date(endDate).getTime() - new Date(startDate).getTime()

      return (
        Math.floor(dater / 86400000) * advertisingLimitsValue() +
        advertisingLimitsValue()
      )
    }
  }
  const totalPriceToPay = getTotalPrice()
  const isEnoughFunds = totalPriceToPay && Balance >= totalPriceToPay

  const isDisabled =
    !advertisingValue.name ||
    !advertisingValue.startDate ||
    !advertisingValue.endDate ||
    isDisabledByType() ||
    !isEnoughFunds

  const differenceToBePaid = useMemo(() => {
    if (!isEnoughFunds && totalPriceToPay) {
      return totalPriceToPay - Balance
    }
    return 0
  }, [Balance, totalPriceToPay])

  const removeButton = (
    <Button
      className={classes.button}
      color="yellow"
      preset="fixedBorderButton"
      textColor="yellow"
      textPreset="h5"
      text="DELETE"
      tx="advertisiments.delete.button"
      onClick={handleOnOpenConfirmModal(changeOpenConfirmModal, true)}
    />
  )

  const removeButtonVisible = onRemove ? removeButton : undefined

  return (
    <>
      <PageForm
        onBack={history.goBack}
        onSave={handleOnSubmit}
        disabled={isDisabled}
        pannelLeftContent={removeButtonVisible}
      >
        <Column
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.container}
        >
          <BackBreadcrumbs
            text="All advertisiments"
            tx="advertisiments.breadcrumbs.back"
          />
          <Text
            className={classes.title}
            preset="h3"
            text={title}
            tx={titleTx}
          />
          <Row fullWidth alignItems="flex-start" className={classes.content}>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.leftColumn}
            >
              <AdsGeneralInfoSection
                className={classes.section}
                defaultValue={prevAdvertisingState.name}
                onChangeInputValue={handleOnChangeInput}
              />
              <Section
                className={classes.section}
                title="Advertising Type"
                titleTx="promotion.advertising.advertisingType"
              >
                <RadioBox
                  active={String(advertisingValue.type)}
                  className={classes.sectionAdditionContainer}
                  data={typeRadioData}
                  onChange={handleOnChangeType}
                />
              </Section>
              <PickDateSection
                className={classes.section}
                defaultValueStart={startDate}
                defaultValueEnd={endDate}
                onChangeDate={handleOnChangeDate}
              />
              <ViewByAdvertisingType
                advertising={advertisingValue}
                productId={advertisingValue.product}
                collectionId={advertisingValue.collection}
                bannerId={advertisingValue.banner}
                product={product}
                collection={collection}
                banner={banner}
                type={advertisingValue.type}
                onChangeProduct={handleOnChangeProduct}
                onChangeCollection={handleOnChangeCollection}
                onChangeBanner={handleOnChangeBanner}
                onCreateProduct={handleOnCreateProduct}
                onCreateBanner={handleOnCreateBanner}
                onCreateCollection={handleOnCreateCollection}
              />
            </Column>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.rightColumn}
            >
              <UserInfoBadgeBalance
                id={id}
                differenceToBePaid={differenceToBePaid}
                value={balance}
                title="Balance"
                titleTx="promotion.advertising.form.balance"
              />
              <AdveretisingPriceSection
                className={classes.section}
                totalPrice={advertisingValue.totalPrice}
                totalPriceToPay={totalPriceToPay}
                pricePerDay={advertisingLimitsValue()}
              />
            </Column>
          </Row>
          {openConfirmModal && (
            <QuestionConfirmModal
              title="Delete Advertising"
              titleTx="advertising.modal.delete.title"
              description="Are you sure?"
              descriptionTx="advertising.modal.delete.description"
              secondaryDescription="You really want to delete this advertising?"
              secondaryDescriptionTx="advertising.modal.delete.secondaryDescription"
              onSubmit={handleOnRemove}
              onClose={handleOnOpenConfirmModal(changeOpenConfirmModal, false)}
            />
          )}
          {openErrorModal && (
            <SubscriptionErrorModal
              title="Ooops..."
              titleTx="subscriptionError.modal.title"
              description="Sorry as an Individual Vendor you can have only 5 active products."
              descriptionTx="item.subscription.error.modal.description"
              secondaryDescription="You can upgrade your subscription to have
          unlimited products and access to the OVTMT application.
          Do you want to change your plan?"
              secondaryDescriptionTx="subscriptionError.modal.secondaryDescription"
              onClose={handleOnOpenErrorModal}
            />
          )}
        </Column>
      </PageForm>
    </>
  )
}
