import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components'

import background from '../../../assets/images/empty-avatars.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: theme.colors.white,
    padding: '24px 0'
  },
  image: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top, center left 50px',
    backgroundImage: `url(${background})`,
    zIndex: 1,
    width: '100%',
    marginTop: 94,
    height: 255
  },
  button: {
    marginTop: 24,
    width: 264,
    backgroundColor: theme.colors.yellow
  },
  text: {},
  title: {
    padding: '8px 0'
  },
  subText: {
    marginTop: 16
  },
  header: {}
}))
