import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Row, Text, LightTheme } from '../../../components'
import { getFormatedOrderDate } from '../../views'

import { useStyle } from './statistic-table-item.styles'
import { StatisticUserTableItemProps } from './statistic-table-item.types'

export const StatisticTableItem: FC<StatisticUserTableItemProps> = ({
  id,
  lastLogin,
  companyName,
  dateJoined,
  canceledOrdersAmount,
  completedOrdersAmount,
  inProgressOrdersAmount,
  country,
  itemsAmount,
  ordersAmount,
  subscription,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  return (
    <Row
      fullWidth
      key={`banner_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row
        className={`${classes.firstColumn} ${classes.tableColumn}`}
        justifyContent="flex-start"
      >
        <Text
          className={classes.text}
          color="black"
          preset="body"
          text={companyName}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {subscription?.name && (
          <Text color="black" preset="body" text={subscription.name} />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {itemsAmount && (
          <Text color="black" preset="body" text={String(itemsAmount)} />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {country && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={country}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {ordersAmount && (
          <Text color="black" preset="body" text={String(ordersAmount)} />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {inProgressOrdersAmount && (
          <Text
            color="black"
            preset="body"
            text={String(inProgressOrdersAmount)}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {completedOrdersAmount && (
          <Text
            color="black"
            preset="body"
            text={String(completedOrdersAmount)}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {canceledOrdersAmount && (
          <Text
            color="black"
            preset="body"
            text={String(canceledOrdersAmount)}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {dateJoined && <Text color="black" preset="body" text={dateJoined} />}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {lastLogin && (
          <Text
            color="black"
            preset="body"
            text={getFormatedOrderDate(lastLogin)}
          />
        )}
      </Row>
    </Row>
  )
}
