import packageInfo from '../package.json'
import { SearchVariables } from './hooks/makeSearch'
import { ListSettings, ListViews, Pagination } from './services/enums.types'

export enum NodeEnv {
  DEV = 'development',
  TEST = 'test',
  PRODUCTION = 'production'
}

const {
  REACT_APP_MOUNT_URI,
  REACT_APP_API_URI,
  REACT_APP_TALKJS_APP_ID,
  NODE_ENV,
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_MARKETPLACE_LINK,
  REACT_APP_CONSOLE_ENV
} = process.env

if (REACT_APP_CONSOLE_ENV === 'true') {
  // eslint-disable-next-line no-console
  console.log(process.env)
}

export const APP_MOUNT_URI = REACT_APP_MOUNT_URI || ''
export const API_URI = REACT_APP_API_URI || '/graphql'
export const PAYPAL_CLIENT_ID =
  REACT_APP_PAYPAL_CLIENT_ID ||
  'ARi7-7yFKuW0VaN447pHi02hFKf_2DvDa3OYdd4qQnpPKEwlArcKk9FcDOAMBsJFLt8LV7LOcswx4ujM'
export const TALKJS_APP_ID = REACT_APP_TALKJS_APP_ID || 'taWgU4Em'
export const MARKETPLACE_LINK =
  REACT_APP_MARKETPLACE_LINK || 'https://dev.marketplace.consultinar.com'
export const ENV = NODE_ENV

if (!REACT_APP_API_URI) {
  // eslint-disable-next-line no-console
  console.error('Not setting REACT_APP_API_URI to environment')
}

if (!REACT_APP_PAYPAL_CLIENT_ID) {
  // eslint-disable-next-line no-console
  console.error('Not setting REACT_APP_PAYPAL_CLIENT_ID to environment')
}

if (!REACT_APP_TALKJS_APP_ID) {
  // eslint-disable-next-line no-console
  console.error('Not setting REACT_APP_TALKJS_APP_ID to environment')
}

if (!REACT_APP_MARKETPLACE_LINK) {
  // eslint-disable-next-line no-console
  console.error('Not setting REACT_APP_MARKETPLACE_LINK to environment')
}

export const DEFAULT_INITIAL_SEARCH_DATA: SearchVariables = {
  after: undefined,
  first: 20,
  query: ''
}

export const DEFAULT_INITIAL_PAGINATION_DATA: Pagination = {
  after: undefined,
  before: undefined
}

export const PAGINATE_BY = 20

export type ProductListColumns = 'productType' | 'isPublished' | 'price'
export interface AppListViewSettings {
  [ListViews.APPS_LIST]: ListSettings
  [ListViews.CATEGORY_LIST]: ListSettings
  [ListViews.COLLECTION_LIST]: ListSettings
  [ListViews.CUSTOMER_LIST]: ListSettings
  [ListViews.DRAFT_LIST]: ListSettings
  [ListViews.NAVIGATION_LIST]: ListSettings
  [ListViews.ORDER_LIST]: ListSettings
  [ListViews.PAGES_LIST]: ListSettings
  [ListViews.PLUGINS_LIST]: ListSettings
  [ListViews.PRODUCT_LIST]: ListSettings<ProductListColumns>
  [ListViews.SALES_LIST]: ListSettings
  [ListViews.SHIPPING_METHODS_LIST]: ListSettings
  [ListViews.STAFF_MEMBERS_LIST]: ListSettings
  [ListViews.PERMISSION_GROUP_LIST]: ListSettings
  [ListViews.VOUCHER_LIST]: ListSettings
  [ListViews.WAREHOUSE_LIST]: ListSettings
  [ListViews.WEBHOOK_LIST]: ListSettings
}
export const defaultListSettings: AppListViewSettings = {
  [ListViews.APPS_LIST]: {
    rowNumber: 10
  },
  [ListViews.CATEGORY_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.COLLECTION_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.CUSTOMER_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.DRAFT_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.NAVIGATION_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.ORDER_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.PAGES_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.PLUGINS_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.PRODUCT_LIST]: {
    columns: ['isPublished', 'price', 'productType'],
    rowNumber: PAGINATE_BY
  },
  [ListViews.SALES_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.SHIPPING_METHODS_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.STAFF_MEMBERS_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.PERMISSION_GROUP_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.VOUCHER_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.WAREHOUSE_LIST]: {
    rowNumber: PAGINATE_BY
  },
  [ListViews.WEBHOOK_LIST]: {
    rowNumber: PAGINATE_BY
  }
}

export const APP_VERSION = packageInfo.version
export const DEMO_MODE = process.env.REACT_APP_DEMO_MODE === 'true'
