import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  section: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  },

  weightDropdown: {
    padding: 16,
    width: '100%',
    columnGap: 40,
    '&> *': {
      flex: 1,
      justifyContent: 'flex-start'
    }
  },
  sliderItem: {
    marginLeft: 20
  }
})
