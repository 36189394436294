import React, { FC, MouseEvent, useState, useMemo } from 'react'

import { Row } from '../row'

import { useStyle } from './slider.styles'
import { SliderProps } from './slider.types'

export const Slider: FC<SliderProps> = ({
  className = '',
  defaultValue,
  value,
  onChange
}) => {
  const [active, changeActive] = useState(defaultValue)

  const Value = useMemo(() => {
    if (typeof value !== 'undefined') {
      return value
    }

    return active
  }, [value, active])

  const classes = useStyle({ active: Value })

  const handleOnClick = (
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    changeActive(!active)
    if (onChange) {
      onChange(!Value, event)
    }
  }
  return (
    <Row
      className={`${className} ${classes.container}`}
      onClick={handleOnClick}
    >
      <div className={classes.dot} />
    </Row>
  )
}
