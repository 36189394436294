import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  content: {
    marginTop: 4
  },
  field: {
    '&:focus-within': {
      width: '100%'
    },
    flex: 1,
    marginTop: 16,
    width: '100%'
  },
  section: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  sectionAdditionContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '20px 16px',
    width: '100%'
  },
  title: {
    width: '100%'
  },
  input: {
    background: theme.colors.transparent,
    outline: 'none',
    border: 'none',
    color: theme.colors.black,
    fontFamily: 'inherit',
    letterSpacing: 'normal',
    marginTop: 4,
    minHeight: 56,
    padding: 0,
    width: '100%',
    resize: 'none',
    boxSizing: 'border-box',
    overflow: 'hidden',
    fontSize: 16
  },
  area: {
    border: `1px solid ${theme.colors.inactive}`,
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 23px',
    marginTop: 16,
    width: '100%'
  }
}))
