import React, { FC } from 'react'

import { Avatar } from '../avatar'
import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './vendor-item.styles'
import { VendorItemProps } from './vendor-item.types'

export const VendorItem: FC<VendorItemProps> = ({
  avatar,
  avatarRadius,
  className = '',
  companyName,
  name,
  onClick
}) => {
  const classes = useStyle()

  return (
    <Row
      fullWidth
      className={className}
      justifyContent="flex-start"
      onClick={onClick}
    >
      <Avatar radius={avatarRadius} name={name} src={avatar} />
      <Column className={classes.info} alignItems="flex-start">
        <Text text={name} />
        <Row className={classes.description}>
          <Icon src={ICONS.storeMallDirectory} height={16} width={16} />
          <Text
            className={classes.companyName}
            color="inactive"
            text={companyName}
          />
        </Row>
      </Column>
    </Row>
  )
}
