import React, { FC } from 'react'

import { Column, Text } from '../../../../components'

import { useStyle } from './product-preview-details-block-subitem.styles'
import { ProductPreviewDetailsBlockSubitemProps } from './product-preview-details-block-subitem.types'

export const ProductPreviewDetailsBlockSubitem: FC<ProductPreviewDetailsBlockSubitemProps> =
  ({ className = '', children, text, tx }) => {
    const classes = useStyle()
    return (
      <Column
        fullWidth
        className={className}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Text text={text} tx={tx} preset="h6" color="inactive" />
        <Column fullWidth alignItems="flex-start" className={classes.options}>
          {children}
        </Column>
      </Column>
    )
  }
