import { defineMessages } from 'react-intl'

const commonErrorMessages = defineMessages({
  graphqlError: {
    defaultMessage: 'API error',
    id: 'c5pMZ8'
  },
  invalid: {
    defaultMessage: 'Invalid value',
    id: '577R2r'
  },
  unknownError: {
    defaultMessage: 'Unknown error',
    id: 'qDwvZ4'
  }
})

export default commonErrorMessages
