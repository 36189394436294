import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Column,
  Text,
  BackBreadcrumbs,
  QuestionConfirmModal,
  PageForm
} from '../../components'
import { useUser } from '../../hooks/useUser'
import { RegisterCredentials } from '../../services'
import { Sex } from '../../services/auth/auth.types'

import { ProfileInfoView } from '../views'
import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { useStyle } from './profile-settings-general.styles'
import {
  ValidatorFirstName,
  ValidatorLastName,
  ValidatorUsername,
  ValidatorState,
  ValidatorPhone
} from './validation'

const defaultUserState: RegisterCredentials = {
  email: '',
  password: '',
  sex: Sex.MALE,
  firstName: '',
  lastName: '',
  username: '',
  companyName: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zipcode: '',
  phone: '',
  contactsPersonName: '',
  contactsPersonPosition: '',
  paypalEmail: '',
  role: 1
}

export const ProfileSettingsGeneralPage = () => {
  const { user } = useUser()
  const userContext = useUser()
  const classes = useStyle()
  const history = useHistory()

  const [openDeleteAccountModal, changeOpenDeleteAccountModal] = useState(false)
  const handleOnOpenDeleteAccountModal = () => {
    changeOpenDeleteAccountModal(!openDeleteAccountModal)
  }

  const [myUser, changeUser] = useState<RegisterCredentials>(defaultUserState)
  const [myUserImage, changeMyUserImage] = useState<File>()
  const [isToDelete, changeIsToDelete] = useState<Boolean>(false)

  const isAdmin = user?.isStaff
  const pageTitle = isAdmin ? 'Account Settings' : 'General Information'
  const pageTitleTx = isAdmin
    ? 'profile.settings.account.settings'
    : 'profile.settings.general.information'

  useEffect(() => {
    changeUser({ ...myUser, ...user })
  }, [user])

  const handleOnSave = () => {
    if (userContext.updateInfo && userContext.updateAvatar) {
      const newUserState = {
        firstName: myUser.firstName,
        lastName: myUser.lastName,
        sex: myUser.sex,
        phone: myUser.phone,
        email: myUser.email,
        companyName: myUser.companyName,
        username: myUser.username,
        country: myUser.country,
        state: myUser.state
      }

      if (myUserImage) {
        userContext.updateAvatar(myUserImage)
      }

      userContext.updateInfo(
        { input: newUserState },
        CabinetNavigatorPathVendor.PROFILE_SETTINGS
      )

      if (isToDelete && userContext.deleteAvatar) {
        userContext.deleteAvatar()
      }
    }
  }

  const handeOnDeleteAccount = () => {
    if (userContext.deleteAccount) {
      userContext.deleteAccount()
    }
  }

  const handleOnDeleteImage = (value: boolean) => {
    changeIsToDelete(value)
  }

  const handleUserImage = (image: File) => {
    changeMyUserImage(image ?? undefined)
  }

  const isValidPersonalInfo =
    !myUser.firstName ||
    !ValidatorFirstName(myUser.firstName) ||
    !myUser.lastName ||
    !ValidatorLastName(myUser.lastName) ||
    !myUser.username ||
    !ValidatorUsername(myUser.username)

  const isValidContactInfo =
    !myUser.state ||
    !ValidatorState(myUser.state) ||
    !myUser.phone ||
    !ValidatorPhone(myUser.phone)

  const isDisabled =
    isValidPersonalInfo || isValidContactInfo || !myUser.companyName

  return (
    <PageForm
      disabled={isDisabled}
      onBack={history.goBack}
      onSave={handleOnSave}
    >
      <Column
        fullWidth
        className={classes.container}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {!isAdmin && (
          <BackBreadcrumbs
            text="Profile Settings"
            tx="profile.settings.general.back"
          />
        )}

        <Text
          className={classes.title}
          color="black"
          preset="h3"
          text={pageTitle}
          tx={pageTitleTx}
        />
        {user && (
          <ProfileInfoView
            user={user}
            userCredentials={myUser}
            changeUser={changeUser}
            onChangeImage={handleUserImage}
            onDeleteImage={handleOnDeleteImage}
          />
        )}
        {!isAdmin && (
          <Text
            className={classes.delete}
            color="blue"
            preset="h6"
            text="Delete Account?"
            onClick={handleOnOpenDeleteAccountModal}
          />
        )}
      </Column>

      {openDeleteAccountModal && (
        <QuestionConfirmModal
          title="Delete Account"
          titleTx="delete.account.modal.title"
          description="Are you sure?"
          descriptionTx="delete.account.modal.description"
          secondaryDescription="Deleting your account will remove all of your information from our database. This cannot be undone."
          secondaryDescriptionTx="delete.account.modal.secondaryDescription"
          successText="Delete Account"
          successTx="delete.account.modal.success"
          onSubmit={handeOnDeleteAccount}
          onClose={handleOnOpenDeleteAccountModal}
        />
      )}
    </PageForm>
  )
}
