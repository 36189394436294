import { gql } from '@apollo/client'

import {
  BASIC_PRODUCT_FRAGMENT,
  PAGE_INFO_FRAGMENT,
  USER_FRAGMENT
} from '../fragments'
import { FEEDBACK_FRAGMENT } from '../fragments/feedback'

export const FEEDBACK_CREATE_MUTATION = gql`
  ${USER_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  mutation FeedbackCreate($input: FeedbackCreateInput!) {
    feedbackCreate(input: $input) {
      feedback {
        id
        rating
        comment
        vendor {
          ...UserFragment
        }
        product {
          ...BasicProductFields
        }
      }
      feedbackErrors {
        field
        code
        message
      }
      errors {
        message
        field
      }
    }
  }
`

export const FEEDBACKS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${FEEDBACK_FRAGMENT}
  query Feedbacks(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $sortBy: FeedbackOrder
    $filter: FeedbackFilterInput
  ) {
    feedbacks(
      before: $before
      after: $after
      first: $first
      last: $last
      sortBy: $sortBy
      filter: $filter
    ) {
      filters
      options
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...FeedbackFragment
        }
      }
    }
  }
`

export const FEEDBACK_UPDATE_MUTATION = gql`
  ${FEEDBACK_FRAGMENT}
  mutation UpdateFeedback($id: ID!, $input: FeedbackUpdateInput!) {
    feedbackUpdate(id: $id, input: $input) {
      feedback {
        ...FeedbackFragment
      }
      feedbackErrors {
        field
        code
        message
      }
      errors {
        message
        field
      }
    }
  }
`
