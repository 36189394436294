import React, { FC, MouseEventHandler, useState } from 'react'

import { Button, HintRow, Row } from '../../../components'

import { OptionModal } from '../option-modal'

import { CreateOptionButtonProps } from './create-option-button.types'

export const CreateOptionButton: FC<CreateOptionButtonProps> = ({
  className = '',
  hintClassName = '',
  defaultOpen = false,
  name = 'CREATE OPTION',
  nameTx = 'item.form.create.option',
  btnInstruction,
  onClick,
  ...optionModalProps
}) => {
  const [open, changeOpen] = useState(defaultOpen)

  const handleOnOpen =
    (state: boolean): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      changeOpen(state)

      if (onClick) {
        onClick(event)
      }
    }

  const onChangeOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  return (
    <Row fullWidth>
      <HintRow className={hintClassName} text={btnInstruction} top={109}>
        <Button
          className={className}
          color="yellow"
          preset="primary"
          textColor="white"
          textPreset="h5"
          text={name}
          tx={nameTx}
          onClick={handleOnOpen(true)}
        />
      </HintRow>
      <OptionModal
        {...optionModalProps}
        open={open}
        onChangeOpen={onChangeOpen(false)}
        onClose={handleOnOpen(false)}
      />
    </Row>
  )
}
