import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  dot: {
    margin: 9
  },
  text: {
    marginLeft: 8
  },
  featuresRow: {
    display: 'inline-block',
    marginLeft: 8
  }
})
