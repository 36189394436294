import React, { FC } from 'react'

import { Currency } from '../../services'

import { Column } from '../column'
import { getCurrencySymbol, getPriceToFixed } from '../helpers'
import { Text } from '../text'

import { useStyle } from './subscription-plan.styles'
import { SubscriptionPlanProps } from './subscription-plan.types'

export const SubscriptionPlan: FC<SubscriptionPlanProps> = ({
  name,
  price,
  selected,
  onClick
}) => {
  const classes = useStyle({ selected })

  return (
    <Column
      key={`subscription_price_${name}`}
      className={classes.price}
      onClick={onClick}
    >
      <Text
        color="black"
        preset="h1"
        text={`${getCurrencySymbol(Currency.USD)}${getPriceToFixed(price)}`}
      />
      <Text preset="body" text={name} color="black" />
    </Column>
  )
}
