import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  image: {
    marginLeft: 16,
    objectFit: 'cover'
  },
  tableColumn: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    flex: 2,
    height: '100%',
    padding: '8px 16px'
  },
  minTableColumn: {
    flex: 1
  },
  maxTableColumn: {
    flex: 3,
    paddingLeft: 0
  },
  tableRow: {
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.lightBlue
    }
  },
  statusText: {
    marginLeft: 8
  }
}))
