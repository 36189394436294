import { ApolloQueryResult } from '@apollo/client'
import { IntlShape } from 'react-intl'

import { IMessageContext } from '../../components/messages'
import { commonMessages } from '../../intl'
import { JobStatusEnum } from '../../services'

import messages from './messages'
import {
  InvoiceGenerateParams,
  QueuedTask,
  TaskData,
  TaskStatus,
  CheckExportFileStatus,
  CheckOrderInvoicesStatus,
  OnSuccessSetTask
} from './types'

function getTaskStatus(jobStatus: JobStatusEnum): TaskStatus {
  switch (jobStatus) {
    case JobStatusEnum.SUCCESS:
      return TaskStatus.SUCCESS
    case JobStatusEnum.PENDING:
      return TaskStatus.PENDING
    default:
      return TaskStatus.FAILURE
  }
}

export async function handleTask(task: QueuedTask): Promise<TaskStatus> {
  let status = TaskStatus.PENDING
  try {
    status = await task.handle()
    if (status !== TaskStatus.PENDING) {
      task.onCompleted({
        status
      })
    }
  } catch (error) {
    task.onError(error as Error)
  }

  return status
}

export function handleError(error: Error) {
  throw error
}

export function queueCustom(
  id: number,
  data: TaskData,
  success: OnSuccessSetTask
) {
  const taskDataKeys: Array<keyof TaskData> = ['handle', 'onCompleted']
  taskDataKeys
    .filter((field) => !data[field])
    .forEach((field) => {
      throw new Error(`${field} is required when creating custom task`)
    })

  success({
    handle: data.handle,
    id,
    onCompleted: data.onCompleted,
    onError: data.onError || handleError,
    status: TaskStatus.PENDING
  })
}

export function queueInvoiceGenerate(
  id: number,
  generateInvoice: InvoiceGenerateParams,
  tasks: React.MutableRefObject<QueuedTask[]>,
  fetch: () => Promise<ApolloQueryResult<CheckOrderInvoicesStatus>>,
  notify: IMessageContext,
  intl: IntlShape
) {
  if (!generateInvoice) {
    throw new Error('generateInvoice is required when creating custom task')
  }
  // eslint-disable-next-line no-param-reassign
  tasks.current = [
    ...tasks.current,
    {
      handle: async () => {
        const result = await fetch()
        const invoice = result.data.order.invoices.find(
          (currentInvoice) => currentInvoice.id === generateInvoice.invoiceId
        )

        if (invoice) {
          return getTaskStatus(invoice.status)
        }

        return getTaskStatus(JobStatusEnum.FAILED)
      },
      id,
      onCompleted: (data) =>
        data.status === TaskStatus.SUCCESS
          ? notify({
              status: 'success',
              text: intl.formatMessage(messages.invoiceGenerateFinishedText),
              title: intl.formatMessage(messages.invoiceGenerateFinishedTitle)
            })
          : notify({
              status: 'error',
              text: intl.formatMessage(commonMessages.somethingWentWrong),
              title: intl.formatMessage(messages.invoiceGenerationFailedTitle)
            }),
      onError: handleError,
      status: TaskStatus.PENDING
    }
  ]
}

export function queueExport(
  id: number,
  tasks: React.MutableRefObject<QueuedTask[]>,
  fetch: () => Promise<ApolloQueryResult<CheckExportFileStatus>>,
  notify: IMessageContext,
  intl: IntlShape
) {
  // eslint-disable-next-line no-param-reassign
  tasks.current = [
    ...tasks.current,
    {
      handle: async () => {
        const result = await fetch()
        const { status } = result.data.exportFile

        return getTaskStatus(status)
      },
      id,
      onCompleted: (data) =>
        data.status === TaskStatus.SUCCESS
          ? notify({
              status: 'success',
              text: intl.formatMessage(messages.exportFinishedText),
              title: intl.formatMessage(messages.exportFinishedTitle)
            })
          : notify({
              status: 'error',
              text: intl.formatMessage(commonMessages.somethingWentWrong),
              title: intl.formatMessage(messages.exportFailedTitle)
            }),
      onError: handleError,
      status: TaskStatus.PENDING
    }
  ]
}
