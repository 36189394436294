import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { debounce } from 'lodash'

import { Text } from '../text'
import { Checkbox } from '../checkbox'
import { Column } from '../column'
import { Row } from '../row'
import { ICONS } from '../icon'

import { CheckboxListByAlphabetProps } from './checkbox-list-by-alphabet.types'
import { useStyle } from './checkbox-list-by-alphabet.styles'
import { getCoreItemsByAlphabet } from '../helpers'
import { TextField } from '../text-field'

export const CheckboxListByAlphabet: FC<CheckboxListByAlphabetProps> = ({
  name,
  defaultValue = [],
  data,
  onChange
}) => {
  const classes = useStyle()
  const [activeIds, changeActiveIds] = useState<string[]>(defaultValue)
  const [searchItem, changeSearchItem] = useState('')

  const ItemsByAlphabet = useMemo(() => {
    if (data) {
      const formatedSearch = searchItem.toLowerCase()
      const filterItem = data.filter((item) => {
        if (!item || !item.name) {
          return false
        }
        const fotmatedItem = item.name.toLowerCase()

        return fotmatedItem.includes(formatedSearch)
      })
      return getCoreItemsByAlphabet(filterItem)
    }

    return {}
  }, [data, searchItem])

  const ItemsKeys = useMemo(() => {
    if (data) {
      const formatedSearch = searchItem.toLowerCase()
      const filterItem = data.filter((item) => {
        const fotmatedItem = item.name.toLowerCase()

        return fotmatedItem.includes(formatedSearch)
      })
      const itemByAlphabet = getCoreItemsByAlphabet(filterItem)
      return Object.keys(itemByAlphabet)
    }

    return []
  }, [data, searchItem])

  const getChecked = (id: string) =>
    Boolean(activeIds.find((activeId) => activeId === id))

  const handleOnChange = (id: string) => (state: boolean) => {
    const filteredActiveIds = activeIds.filter((item) => item !== id)

    if (state) {
      const nextValue = [...filteredActiveIds, id]
      changeActiveIds(nextValue)

      if (onChange) {
        onChange(nextValue)
      }
    } else {
      changeActiveIds(filteredActiveIds)

      if (onChange) {
        onChange(filteredActiveIds)
      }
    }
  }

  const handleOnChangeSearchCoreItem = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value: nextValue } = event.target
    const searchValueLower = nextValue.toLowerCase()

    const change = debounce(changeSearchItem, 1000)

    change(searchValueLower)
  }

  return (
    <Column
      fullWidth
      className={classes.container}
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <TextField
        className={classes.search}
        leftIcon={ICONS.search}
        label="Search"
        labelTx="filter.by.alphabet.search"
        preset="border"
        onChange={handleOnChangeSearchCoreItem}
      />

      {ItemsKeys.map((key) => (
        <Column
          fullWidth
          alignItems="flex-start"
          key={`key_${name}_alphabet_core_${key}`}
        >
          <Text className={classes.key} preset="h4" text={key} />
          {ItemsByAlphabet[key].map((item) => (
            <Row
              key={`checkbox_${name}_${item.id}`}
              className={classes.item}
              justifyContent="flex-start"
            >
              <Checkbox
                checked={getChecked(item.id)}
                onChange={handleOnChange(item.id)}
              >
                <Text className={classes.text} preset="body" text={item.name} />
              </Checkbox>
            </Row>
          ))}
        </Column>
      ))}
    </Column>
  )
}
