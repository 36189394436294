import React, { FC } from 'react'

import { Icon } from '../icon'
import { Row } from '../row'
import { Text } from '../text'

import { Dot } from './dot'
import { useStyle } from './radio-item.styles'
import { RadioItemProps } from './radio-item.types'

export const RadioItem: FC<RadioItemProps> = ({
  active,
  className,
  src,
  name,
  nameTx,
  onClick
}) => {
  const classes = useStyle()

  return (
    <Row className={className} onClick={onClick}>
      <Dot active={active} />
      {src && <Icon className={classes.icon} src={src} />}
      <Text
        className={classes.text}
        color="black"
        preset="body"
        text={name}
        tx={nameTx}
      />
    </Row>
  )
}
