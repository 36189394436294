import { DropdownItemData } from '../../components'
import { FeedbackOrderField } from '../../services/feedback/feedback.types'
import { OrderDirection } from '../../services'

export const sortData: DropdownItemData[] = [
  {
    id: '',
    value: '',
    name: 'Clear',
    nameTx: 'feedback.monitoring.clear.sort'
  },
  {
    id: `${FeedbackOrderField.RATING},${OrderDirection.ASC}`,
    value: `${FeedbackOrderField.RATING},${OrderDirection.ASC}`,
    name: 'Rate Low-High',
    nameTx: 'feedback.monitoring.sort.rate.lowHigh'
  },
  {
    id: `${FeedbackOrderField.RATING},${OrderDirection.DESC}`,
    value: `${FeedbackOrderField.RATING},${OrderDirection.DESC}`,
    name: 'Rate High-Low',
    nameTx: 'feedback.monitoring.sort.rate.highLow'
  },
  {
    id: `${FeedbackOrderField.ANSWER},${OrderDirection.ASC}`,
    value: `${FeedbackOrderField.ANSWER},${OrderDirection.ASC}`,
    name: 'Answered',
    nameTx: 'feedback.monitoring.sort.answered'
  },
  {
    id: `${FeedbackOrderField.ANSWER},${OrderDirection.DESC}`,
    value: `${FeedbackOrderField.ANSWER},${OrderDirection.DESC}`,
    name: 'Unanswered',
    nameTx: 'feedback.monitoring.sort.unanswered'
  }
]
