import { Color, TextProps } from '../../components'
import { DeliveryPrice, OfferCreateInput, OfferStatus } from '../../services'

export const getOfferInputDiscount = (offerInput: OfferCreateInput) => {
  const { discount } = offerInput
  if (discount) {
    return discount
  }

  return 0
}

export const getDeliveryPriceFromProductPerItem = (
  deliveryPrice: DeliveryPrice
) => {
  if (deliveryPrice) {
    return deliveryPrice.weight
  }

  return 0
}

export const getColorByOfferStatus = (status: OfferStatus): Color => {
  switch (status) {
    case OfferStatus.NEW:
      return 'yellow'
    case OfferStatus.DRAFT:
      return 'darkBlue'
    case OfferStatus.START_OFFER:
      return 'inactiveSecondary'
    case OfferStatus.ACCEPTED:
      return 'active'
    case OfferStatus.ACCEPTED_BACK_FROM_CART:
      return 'active'
    default:
      return 'error'
  }
}

export const getMessageByOfferStatus = (status: OfferStatus): TextProps => {
  switch (status) {
    case OfferStatus.NEW:
      return {
        text: 'Sent',
        tx: 'offerStatus.new'
      }
    case OfferStatus.START_OFFER:
      return {
        text: 'Start offer',
        tx: 'offerStatus.underDiscussion'
      }
    case OfferStatus.DRAFT:
      return {
        text: 'Draft',
        tx: 'offerStatus.draft'
      }
    case OfferStatus.ACCEPTED:
      return {
        text: 'Accepted',
        tx: 'offerStatus.accepted'
      }
    case OfferStatus.ACCEPTED_BACK_FROM_CART:
      return {
        text: 'Accepted',
        tx: 'offerStatus.accepted'
      }
    case OfferStatus.DELETED_BY_CUSTOMER:
      return {
        text: 'Declined By Customer',
        tx: 'offerStatus.declined.by.customer'
      }
    case OfferStatus.DECLINED_BY_CUSTOMER:
      return {
        text: 'Declined',
        tx: 'offerStatus.declined'
      }
    case OfferStatus.DECLINED_BY_VENDOR:
      return {
        text: 'Declined',
        tx: 'offerStatus.declined'
      }
    default:
      return {
        text: 'Unknown',
        tx: 'offerStatus.unknown'
      }
  }
}
