import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  section: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  sectionAdditionContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: '100%'
  },
  field: {
    marginTop: 16
  },
  description: {
    marginTop: 8
  }
}))
