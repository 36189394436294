import React, { useEffect, useRef, useState } from 'react'
import { IntlShape, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { ApolloClient, useApolloClient, useMutation } from '@apollo/client'

import { IMessageContext } from '../components/messages'
import { DEMO_MODE } from '../config'
import { useApi } from '../providers'
import { useNotifier, useNavigator } from '../hooks'
import { getMutationStatus } from '../misc'
import {
  AccountUpdateRequest,
  AccountUpdateResults,
  GetRegisterRequest,
  GetRegisterResults,
  User,
  UpdateAvatarImageRequest,
  UpdateAvatarImageResults,
  RegisterCredentials,
  SubscriptionTypeProfile,
  GetTokenCreateResults,
  GetTokenCreateRequest
} from '../services'
import { saveCredentials } from '../utils/credentials-management'
import { arrayHasElements, TextProps } from '../components'
import { CabinetNavigatorPathVendor, OptionsNavigatorPathes } from '../pages'
import {
  REGISTER_MUTATION,
  TOKEN_AUTH_MUTATION,
  TOKEN_REFRESH_MUTATION,
  TOKEN_VERIFY_MUTATION,
  USER_INFO_REGISTER_MUTATION,
  REQUEST_PASSWORD_RESET,
  SET_PASSWORD,
  USER_AVATAR_UPDATE,
  USER_AVATAR_DELETE,
  USER_ACCOUNT_DELETE,
  PASSWORD_CHANGE,
  UPDATE_EMAIL
} from './mutations'
import { RefreshToken, RefreshTokenVariables } from './types/RefreshToken'
import { TokenAuth } from './types/TokenAuth'
import { VerifyToken, VerifyTokenVariables } from './types/VerifyToken'
import {
  RequestPasswordReset,
  RequestPasswordResetVariables
} from './types/RequestPasswordReset'
import { SetPassword, SetPasswordVariables } from './types/SetPassword'
import {
  ChangeVendorPassword,
  ChangeVendorPasswordVariables
} from './types/ChangeVendorPassword'

import { UpdateEmail, UpdateEmailVariables } from './types/UpdateEmail'

import {
  displayDemoMessage,
  getTokens,
  removeTokens,
  setAuthToken,
  setTokens
} from './utils'
import { AuthRoutes } from './urls'
import { UserContext } from './index'
import { getAccountErrors } from './helpers'

const persistToken = true

export function useAuthProvider(
  intl: IntlShape,
  notify: IMessageContext,
  apolloClient: ApolloClient<any>
) {
  const client = useApolloClient()
  const navigate = useNavigator()
  const { auth: Auth } = useApi()
  const { data: responseMe, lazyFetch: refetchMe } = Auth.useMe()
  const { data: responsePartMe, refetch: refetchPartMe } = Auth.usePartMe()
  const [userContext, changeUserContext] = useState<undefined | User>(undefined)
  const [unreadMessages, changeUnreadMessages] = useState(0)
  const [signature, changeSignature] = useState<string>()
  const [loadingSession, changeLoadingSession] = useState<boolean>(true)
  const [accountErrors, changeAccountErrors] = useState<TextProps[]>([])
  const refreshPromise = useRef<Promise<boolean>>()
  const location = useLocation()

  const logout = () => {
    changeUserContext(undefined)
    removeTokens()
    sessionStorage.clear()
    client.clearStore()
  }

  const onLogin = (user?: User) => {
    if (DEMO_MODE) {
      displayDemoMessage(intl, notify)
    }

    if (!user?.subscription && !user?.isStaff) {
      navigate(OptionsNavigatorPathes.SUBSCRIPTION)
    }
  }

  const [accountUpdate] = useMutation<
    AccountUpdateResults,
    AccountUpdateRequest
  >(USER_INFO_REGISTER_MUTATION, {
    client: apolloClient,
    onCompleted: (result) => {
      if (result.accountUpdate.accountErrors.length > 0) {
        navigate(AuthRoutes.REGISTER)
      }

      const { user } = result.accountUpdate

      // FIXME: Now we set state also when auth fails and returned user is
      // `null`, because the LoginView uses this `null` to display error.
      changeUserContext(user)
    },
    onError: () => navigate(AuthRoutes.REGISTER)
  })

  const [registerAuth] = useMutation<GetRegisterResults, GetRegisterRequest>(
    REGISTER_MUTATION,
    {
      client: apolloClient,
      onCompleted: (result) => {
        const {
          errors,
          jwtToken,
          csrfToken,
          refreshToken,
          user,
          talkjsSignature
        } = result.accountRegister

        if (errors.length > 0) {
          navigate(AuthRoutes.REGISTER)
          logout()
        }

        changeUserContext(user)
        if (user) {
          navigate(OptionsNavigatorPathes.SUBSCRIPTION)
          changeSignature(talkjsSignature || undefined)
        }
        setTokens(jwtToken, csrfToken, refreshToken, persistToken)
      },
      onError: () => {
        navigate(AuthRoutes.REGISTER)
        logout()
      }
    }
  )

  const [tokenAuth, tokenAuthResult] = useMutation<
    GetTokenCreateResults,
    GetTokenCreateRequest
  >(TOKEN_AUTH_MUTATION, {
    client: apolloClient,
    onCompleted: (result) => {
      const { errors, token, csrfToken, refreshToken, user, talkjsSignature } =
        result.tokenCreate

      if (errors.length > 0) {
        const nestAccountErrors = getAccountErrors(result.tokenCreate.errors)
        changeAccountErrors(nestAccountErrors)
        logout()
      }

      // FIXME: Now we set state also when auth fails and returned user is
      // `null`, because the LoginView uses this `null` to display error.
      if (user) {
        changeUserContext(user)
        changeSignature(talkjsSignature || undefined)

        if (onLogin) {
          onLogin(user)
        }
      }
      setTokens(token, csrfToken, refreshToken, persistToken)
    },
    onError: logout
  })

  const [tokenRefresh] = useMutation<RefreshToken, RefreshTokenVariables>(
    TOKEN_REFRESH_MUTATION,
    {
      client: apolloClient,
      onCompleted: (result) => {
        if (result && result.tokenRefresh && !result.tokenRefresh.token) {
          logout()
        }
      },
      onError: logout
    }
  )

  const [requestPasswordReset] = useMutation<
    RequestPasswordReset,
    RequestPasswordResetVariables
  >(REQUEST_PASSWORD_RESET, {
    client: apolloClient,
    onError: () => {
      logout()
    }
  })

  const [updateAvatarImage] = useMutation<
    UpdateAvatarImageResults,
    UpdateAvatarImageRequest
  >(USER_AVATAR_UPDATE, {
    client: apolloClient,
    onError: () => {
      logout()
    }
  })

  const [deleteAvatarImage] = useMutation<UpdateAvatarImageResults, {}>(
    USER_AVATAR_DELETE,
    {
      client: apolloClient,
      onError: () => {
        logout()
      }
    }
  )

  const [deleteUserAccount] = useMutation<UpdateAvatarImageResults, {}>(
    USER_ACCOUNT_DELETE,
    {
      client: apolloClient,
      onError: () => {
        logout()
      }
    }
  )

  const [setPassword] = useMutation<SetPassword, SetPasswordVariables>(
    SET_PASSWORD,
    {
      client: apolloClient,
      onError: () => {
        logout()
      }
    }
  )

  const [changeVendorPassword] = useMutation<
    ChangeVendorPassword,
    ChangeVendorPasswordVariables
  >(PASSWORD_CHANGE, {
    client: apolloClient,
    onError: () => {
      logout()
    }
  })

  const [updateEmail] = useMutation<UpdateEmail, UpdateEmailVariables>(
    UPDATE_EMAIL,
    {
      client: apolloClient,
      onError: () => {
        navigate('/')
      }
    }
  )

  const [, tokenVerifyResult] = useMutation<VerifyToken, VerifyTokenVariables>(
    TOKEN_VERIFY_MUTATION,
    {
      client: apolloClient,
      onCompleted: (result) => {
        if (result.tokenVerify === null) {
          logout()
        } else {
          const { user } = result.tokenVerify

          if (user) {
            changeUserContext(user)
          }
        }
      },
      onError: logout
    }
  )

  const tokenAuthOpts = {
    ...tokenAuthResult,
    // @ts-ignore
    status: getMutationStatus<TokenAuth>(tokenAuthResult)
  }
  const tokenVerifyOpts = {
    ...tokenVerifyResult,
    // @ts-ignore
    status: getMutationStatus<VerifyToken>(tokenVerifyResult)
  }

  const updateInfo = async (
    variables: AccountUpdateRequest,
    navigatePath?: string
  ) => {
    const result = await accountUpdate({
      variables
    })

    if (result.data?.accountUpdate.accountErrors.length) {
      return null
    }

    if (result.data) {
      if (onLogin) {
        onLogin(result.data.accountUpdate.user)
      }

      if (navigatePath) {
        navigate(navigatePath)
      }

      return result.data.accountUpdate.user
    }
  }

  const updateInfoOnRegister = async (variables: AccountUpdateRequest) => {
    const result = await accountUpdate({
      variables
    })

    if (result.data && !result.data.accountUpdate.accountErrors.length) {
      if (onLogin) {
        onLogin(result.data.accountUpdate.user)
      }
      return result.data.accountUpdate.user
    }

    return null
  }

  const register = async (
    credentials: RegisterCredentials,
    billingAddress: AccountUpdateRequest
  ) => {
    const result = await registerAuth({
      variables: { input: { ...credentials, role: 1 } }
    })

    if (result && result.data && !result.data.accountRegister.errors.length) {
      saveCredentials(result.data.accountRegister.user, credentials.password)
      updateInfoOnRegister(billingAddress)

      if (billingAddress && credentials) {
        const currentBillingAddress =
          billingAddress?.input?.defaultBillingAddress
        const { email, username } = credentials

        const newUserState = {
          email,
          username,
          phone: currentBillingAddress?.phone,
          companyName: currentBillingAddress?.companyName,
          country: currentBillingAddress?.country,
          state: currentBillingAddress?.countryArea
        }
        updateInfoOnRegister({
          input: { ...billingAddress.input, ...newUserState }
        })
      }

      return result.data.accountRegister.user
    }

    return null
  }

  const changeSubscription = (subscription?: SubscriptionTypeProfile) => {
    if (userContext) {
      changeUserContext({ ...userContext, subscription })
    }
  }

  const changeAmount = (amount: number) => {
    if (userContext) {
      const { vendorBalance: prevVendorBalance = 0 } = userContext
      const vendorBalance = prevVendorBalance + amount

      changeUserContext({ ...userContext, vendorBalance })
    }
  }

  const login = async (email: string, password: string, page: string) => {
    const result = await tokenAuth({ variables: { email, password, page } })

    if (result.data && !result.data.tokenCreate.errors.length) {
      const { user } = result.data.tokenCreate

      saveCredentials(user, password)

      return user
    }

    return null
  }

  const setNewPassword = async (
    email: string,
    token: string,
    password: string
  ) => {
    const result = await setPassword({ variables: { email, token, password } })
    const hasErrors = arrayHasElements(result.data?.setPassword.errors)

    if (result.data) {
      if (!hasErrors) {
        navigate(AuthRoutes.RESET_PASSWORD_SUCCESS)
      } else {
        navigate(AuthRoutes.FORGOT_PASSWORD)
      }
    }

    return null
  }

  const changePassword = async (
    oldPassword: string,
    newPassword: string,
    setError: (value: string) => void,
    onClose: () => void
  ) => {
    const result = await changeVendorPassword({
      variables: { newPassword, oldPassword }
    })

    const hasErrors = arrayHasElements(result.data?.passwordChange.errors)

    if (result.data) {
      if (hasErrors) {
        setError(result.data.passwordChange.errors[0].message)
      } else {
        onClose()
      }
    }

    return null
  }

  const confirmEmailChange = async (token: string) => {
    const result = await updateEmail({
      variables: { token }
    })

    if (result.data && !result.data.confirmEmailChange.errors.length) {
      navigate(CabinetNavigatorPathVendor.PROFILE_SETTINGS_GENERAL)
    }
    return null
  }

  const updateAvatar = async (file: File) => {
    await updateAvatarImage({ variables: { file } })

    return null
  }

  const deleteAvatar = async () => {
    await deleteAvatarImage()

    return null
  }

  const deleteAccount = async () => {
    const result = await deleteUserAccount()
    if (result.data) {
      logout()
    }
    return null
  }

  const resetPassword = async (email: string) => {
    const server = 'dashboard'
    await requestPasswordReset({ variables: { email, server } })

    return null
  }

  const loginByToken = (
    auth: string,
    csrf: string,
    refresh: string,
    user: User
  ) => {
    changeUserContext(user)
    setTokens(auth, csrf, refresh, persistToken)
  }

  const refreshToken = (): Promise<boolean> => {
    if (refreshPromise.current) {
      return refreshPromise.current
    }

    return new Promise((resolve) => {
      const tokens = getTokens()
      const token = tokens.refresh
      const csrfToken = tokens.csrf

      if (token && csrfToken) {
        return tokenRefresh({ variables: { token, csrfToken } }).then(
          (refreshData) => {
            if (refreshData.data && refreshData.data.tokenRefresh?.token) {
              const {
                token: nextToken,
                talkjsSignature,
                user: nextUser
              } = refreshData.data.tokenRefresh

              setAuthToken(nextToken, persistToken)
              changeSignature(talkjsSignature || undefined)
              changeUserContext(nextUser)
              setTokens(nextToken, csrfToken, token, persistToken)

              if (onLogin) {
                onLogin(nextUser)
              }

              changeLoadingSession(false)
              return resolve(true)
            }

            changeLoadingSession(false)
            return resolve(false)
          }
        )
      }

      changeLoadingSession(false)
      // eslint-disable-next-line no-console
      return console.log('Not Authorizated')
    })
  }

  const handleOnClearAccountErrors = () => {
    changeAccountErrors([])
  }

  const onUpdateOfferTotalCount = (diffCount: number) => {
    if (userContext) {
      const nextUser: User = {
        ...userContext,
        offers: {
          ...userContext.offers,
          totalCount: userContext.offers.totalCount + diffCount
        }
      }

      changeUserContext(nextUser)
    }
  }

  const onUpdateActiveItemCount = (diffCount: number) => {
    if (userContext) {
      const nextUser: User = {
        ...userContext,
        vendorActiveProducts: userContext.vendorActiveProducts + diffCount
      }

      changeUserContext(nextUser)
    }
  }

  useEffect(() => {
    if (responseMe?.me) {
      changeUserContext(responseMe?.me)
    }
  }, [responseMe?.me])

  const onResyncUser = () => {
    refetchMe({})
  }

  const onResyncPartUser = () => {
    refetchPartMe()
  }

  useEffect(() => {
    onResyncPartUser()
  }, [location.pathname])

  useEffect(() => {
    if (responsePartMe?.me) {
      changeUnreadMessages(responsePartMe.me.unreadMessages)
      if (userContext) {
        const nextUser: User = {
          ...userContext,
          unreadMessages: responsePartMe.me.unreadMessages
        }
        changeUserContext(nextUser)
      }
    }
  }, [responsePartMe?.me])

  return {
    signature,
    accountErrors,
    changeSubscription,
    changeAmount,
    changePassword,
    confirmEmailChange,
    deleteAvatar,
    deleteAccount,
    loadingSession,
    login,
    loginByToken,
    logout,
    refreshToken,
    register,
    resetPassword,
    setNewPassword,
    tokenAuthOpts,
    tokenVerifyOpts,
    updateInfo,
    updateInfoOnRegister,
    userContext,
    unreadMessages,
    updateAvatar,
    onClearAccountErrors: handleOnClearAccountErrors,
    onUpdateOfferTotalCount,
    onUpdateActiveItemCount,
    onResyncUser,
    onResyncPartUser
  }
}

interface AuthProviderProps {
  children: React.ReactNode
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const apolloClient = useApolloClient()
  const intl = useIntl()
  const notify = useNotifier()

  const {
    signature,
    accountErrors,
    loadingSession,
    login,
    loginByToken,
    logout,
    tokenAuthOpts,
    refreshToken,
    register,
    updateInfo,
    updateInfoOnRegister,
    changeSubscription,
    tokenVerifyOpts,
    userContext,
    unreadMessages,
    resetPassword,
    setNewPassword,
    updateAvatar,
    deleteAvatar,
    deleteAccount,
    changeAmount,
    changePassword,
    confirmEmailChange,
    onClearAccountErrors,
    onUpdateOfferTotalCount,
    onUpdateActiveItemCount,
    onResyncUser
  } = useAuthProvider(intl, notify, apolloClient)

  useEffect(() => {
    refreshToken()
  }, [])

  return (
    <UserContext.Provider
      value={{
        signature,
        accountErrors,
        changeAmount,
        loadingSession,
        login,
        loginByToken,
        logout,
        register,
        updateInfo,
        updateInfoOnRegister,
        changeSubscription,
        tokenAuthLoading: tokenAuthOpts.loading,
        tokenRefresh: refreshToken,
        tokenVerifyLoading: tokenVerifyOpts.loading,
        user: userContext,
        unreadMessages,
        resetPassword,
        setNewPassword,
        updateAvatar,
        deleteAvatar,
        deleteAccount,
        changePassword,
        confirmEmailChange,
        onClearAccountErrors,
        onUpdateOfferTotalCount,
        onUpdateActiveItemCount,
        onResyncUser
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default AuthProvider
