import React, { ChangeEvent, FC, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  Button,
  Column,
  Row,
  Text,
  TextField,
  RatingStarsComponent,
  getFullName
} from '../../../components'
import { FeedbackUpdateInput } from '../../../services'
import { LightTheme } from '../../../components/Theme'

import { getFormatedOrderDate } from '../../views'

import { useStyle } from './feedback-monitiring-vendor-item.styles'
import { FeedbackMonitoringVendorItemProps } from './feedback-monitiring-vendor-item.types'

export const FeedbackMonitoringVendorItem: FC<FeedbackMonitoringVendorItemProps> =
  ({
    className = '',
    id,
    user,
    createdAt,
    rating,
    comment,
    vendor,
    answer,
    onSubmit
  }) => {
    const [open, changeOpen] = useState(false)
    const theme: LightTheme = useTheme()
    const classes = useStyle({ theme, open })

    const [value, changeValue] = useState<FeedbackUpdateInput>()

    const customerName = getFullName(user)

    const handleOnOpen = () => {
      changeOpen(!open)
    }

    const handleOnChange =
      (prop: keyof FeedbackUpdateInput) =>
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value: nextValue } = event.target
        changeValue({
          ...value,
          [prop]: nextValue
        })
      }

    const handleOnSubmit = () => {
      if (value && id) {
        onSubmit(id, value)
      }
    }

    const isDisabled = !value?.answer

    return (
      <>
        {vendor && (
          <Column
            fullWidth
            key={`feedback_vendor_${id}`}
            alignItems="flex-start"
            className={`${className} ${classes.container}`}
            justifyContent="flex-start"
          >
            <Row
              fullWidth
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.tableTopContainer}
            >
              <Row fullWidth justifyContent="flex-start">
                <Row
                  className={classes.headerLeftItem}
                  justifyContent="flex-start"
                >
                  <Text
                    text="Customer:"
                    tx="feedback.monitoring.customer"
                    color="inactive"
                    preset="body"
                  />
                  {user && (
                    <Text
                      className={classes.name}
                      text={customerName}
                      color="black"
                      preset="h7"
                    />
                  )}
                </Row>
                <Row
                  className={classes.headerRightItem}
                  justifyContent="flex-start"
                >
                  <Text
                    text="Date:"
                    tx="feedback.monitoring.date"
                    color="inactive"
                    preset="body"
                  />
                  {createdAt && (
                    <Text
                      className={classes.name}
                      text={getFormatedOrderDate(createdAt)}
                      color="black"
                      preset="h7"
                    />
                  )}
                </Row>
                <Row className={classes.stars} justifyContent="flex-end">
                  <RatingStarsComponent rating={rating} edit={false} />
                </Row>
              </Row>
            </Row>
            <Row
              fullWidth
              className={classes.textRow}
              justifyContent="flex-start"
            >
              {comment && <Text text={comment} preset="body" />}
            </Row>
            {comment && (
              <>
                {answer ? (
                  <Row
                    fullWidth
                    className={classes.answer}
                    onClick={handleOnOpen}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Text
                      className={classes.answerTitle}
                      text="Your reply:"
                      tx="feedback.monitoring.your.reply"
                      color="inactive"
                      preset="body"
                    />
                    <Text
                      className={classes.answerText}
                      text={answer}
                      color="black"
                      preset="body"
                    />
                  </Row>
                ) : (
                  <Row fullWidth className={classes.item}>
                    <TextField
                      className={classes.textField}
                      preset="border"
                      label="Answer"
                      labelTx="feedback.monitoring.answer"
                      onChange={handleOnChange('answer')}
                    />
                    <Button
                      className={classes.filter}
                      color="yellow"
                      preset="primary"
                      text="REPLY"
                      tx="feedback.monitoring.reply"
                      textColor="white"
                      textPreset="h5"
                      onClick={handleOnSubmit}
                      disabled={isDisabled}
                    />
                  </Row>
                )}
              </>
            )}
          </Column>
        )}
      </>
    )
  }
