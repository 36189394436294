import { ApolloProvider } from '@apollo/client'
import React, { createContext, FC, useContext } from 'react'

import { API_URI } from '../../config'
import { Api, api } from '../../services'

// @ts-ignore
export const ApiContext = createContext<Api>(undefined)

export const ApiProvider: FC = ({ children }) => {
  const nextApi = api ? api.create(API_URI || '/graphql/') : undefined

  if (!nextApi) {
    return <></>
  }

  return (
    <ApiContext.Provider value={nextApi}>
      <ApolloProvider client={nextApi.api}>{children}</ApolloProvider>
    </ApiContext.Provider>
  )
}

export const useApi = () => useContext(ApiContext)
