import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../Theme'

import { DotProps } from './dot.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  active: {
    background: theme.colors.yellow,
    borderRadius: 12,
    display: ({ active }: DotProps) => (active ? 'block' : 'none'),
    height: 12,
    width: 12
  },
  container: {
    border: ({ active }: DotProps) =>
      `2px solid ${theme.colors[active ? 'yellow' : 'inactive']}`,
    borderRadius: 24,
    height: 24,
    width: 24
  }
}))
