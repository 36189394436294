import { gql } from '@apollo/client'

import { PAGE_INFO_FRAGMENT, USER_FRAGMENT_FOR_USER_LIST } from '../fragments'

export const CUSTOMERS = gql`
  ${PAGE_INFO_FRAGMENT}
  ${USER_FRAGMENT_FOR_USER_LIST}
  query Customers(
    $filter: CustomerFilterInput
    $sortBy: UserSortingInput
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    customers(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      filters
      options
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...UserFragmentForUserList
        }
      }
    }
  }
`
