import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  avatar: {
    marginLeft: 16,
    minWidth: 178
  },
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    marginBottom: 60
  },
  header: {
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    marginTop: 16
  },
  arrowOpened: {
    transform: 'rotate(90deg)'
  },
  search: {
    width: '100%'
  },
  filter: {
    marginLeft: 16,
    height: 56,
    minWidth: 360
  },
  tableTopContainer: {
    padding: 16,
    position: 'relative'
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px',
    width: '48px'
  },
  remove: {
    cursor: 'pointer',
    padding: '8px 16px'
  },
  buttonContainer: {
    padding: '4px 0'
  },
  button: {
    marginLeft: 8
  },
  dropdownFilterRow: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 'calc(100% - 16px)',
    right: 16,
    backgroundColor: `${theme.colors.white}`,
    zIndex: 500,
    width: 360,
    border: `1px solid ${theme.colors.shadow}`
  },
  dropdownFilterItem: {
    padding: '12px 20px',
    justifyContent: 'space-between'
  },
  dropdownFilterItemText: {
    marginLeft: 16
  },
  dropdownSubItem: {
    backgroundColor: '#F8FAFA',
    margin: 8,
    paddingLeft: 36,
    paddingTop: 16,
    paddingBottom: 16
  },
  iconFilter: {
    height: 24,
    marginLeft: 8,
    cursor: 'pointer',
    justifySelf: 'flex-end',
    transform: 'rotate(270deg)'
  },
  iconFilterOpen: {
    height: 24,
    marginLeft: 8,
    cursor: 'pointer',
    justifySelf: 'flex-end',
    transform: 'rotate(90deg)'
  },
  tableColumnFirst: {
    height: '100%',
    padding: '8px 16px',
    width: 120
  },
  tableColumnMin: {
    flex: 0.5
  },
  tableColumnMax: {
    flex: 1.5
  },
  text: {
    marginLeft: 16
  }
}))
