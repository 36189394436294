import React, { FC, useState } from 'react'
import { useTheme } from 'react-jss'

import { concatClassNames } from '../../pages/views'

import { LightTheme } from '../Theme'
import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'

import { useStyle } from './menu-slider.styles'
import { MenuSliderProps } from './menu-slider.types'

export const MenuSlider: FC<MenuSliderProps> = ({
  defaultOpen = false,
  className = '',
  contentClassName = '',
  children,
  header,
  onOpen
}) => {
  const [open, changeOpen] = useState(defaultOpen)

  const theme = useTheme<LightTheme>()
  const classes = useStyle({ theme, open })

  const handleOnOpen = () => {
    changeOpen(!open)

    if (onOpen) {
      onOpen(!open)
    }
  }

  return (
    <Column fullWidth className={concatClassNames(className, classes.slider)}>
      <Row
        fullWidth
        className={classes.titleRow}
        justifyContent="space-between"
        onClick={handleOnOpen}
      >
        <Row fullWidth justifyContent="flex-start">
          {header}
        </Row>
        <Icon className={classes.icon} src={ICONS.arrowBottom} />
      </Row>
      <Column
        fullWidth
        className={concatClassNames(contentClassName, classes.content)}
      >
        {open && <Column fullWidth>{children}</Column>}
      </Column>
    </Column>
  )
}
