import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  sectionItem: {
    border: 'none',
    borderRadius: 0,
    marginTop: 0,
    '&:first-child': {
      borderTop: `1px solid ${theme.colors.shadow}`
    },
    '&:last-child': {
      borderRadius: '0px 0px 4px 4px'
    }
  },
  titleContainerItem: {
    paddingTop: 24
  }
}))
