import { gql } from '@apollo/client'

import { ORDER_FRAGMENT, PAGE_INFO_FRAGMENT } from '../fragments'

export const DRAFT_ORDER_CREATE = gql`
  ${ORDER_FRAGMENT}
  mutation DraftOrderCreate(
    $lines: [OrderLineCreateInput]
    $shippingAddress: AddressInput
  ) {
    draftOrderCreate(
      input: { lines: $lines, shippingAddress: $shippingAddress }
    ) {
      orderErrors {
        field
        message
        code
        orderLine
      }
      order {
        ...OrderFragment
      }
    }
  }
`

export const ORDERS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${ORDER_FRAGMENT}
  query Orders(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $sortBy: OrderSortingInput
    $filter: OrderFilterInput
  ) {
    orders(
      first: $first
      last: $last
      after: $after
      before: $before
      sortBy: $sortBy
      filter: $filter
    ) {
      filters
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      edges {
        cursor
        node {
          ...OrderFragment
        }
      }
    }
  }
`

export const ORDER_UPDATE_MUTATION = gql`
  ${ORDER_FRAGMENT}
  mutation OrderUpdate($id: ID!, $input: OrderUpdateInput!) {
    orderUpdate(id: $id, input: $input) {
      order {
        ...OrderFragment
      }
    }
  }
`

export const ORDER_CANCELATION_BY_ADMIN = gql`
  mutation CancelOrderByAdmin($orderId: ID!, $refundPercentage: Int!) {
    cancelOrderByAdmin(orderId: $orderId, refundPercentage: $refundPercentage) {
      orderStatus
    }
  }
`

export const ORDER_BY_ID = gql`
  ${ORDER_FRAGMENT}
  query OrderById($id: ID!) {
    order(id: $id) {
      ...OrderFragment
    }
  }
`
