import { hasPermission } from '../auth/misc'
import { CabinetNavigatorPathAdmin, CabinetNavigatorPathVendor } from '../pages'
import { PermissionEnum, User } from '../services'

export const getDefaultAuthorizedRoute = (user?: User) => {
  if (!user) {
    return CabinetNavigatorPathAdmin.YOUR_INFO
  }

  const getHasPermission = (permission: PermissionEnum) =>
    hasPermission(permission, user)

  if (getHasPermission(PermissionEnum.YOUR_INFO)) {
    return CabinetNavigatorPathAdmin.YOUR_INFO
  }

  if (getHasPermission(PermissionEnum.MANAGE_OWN_ORDER_STATUS)) {
    return CabinetNavigatorPathVendor.ORDER_CONTROL
  }

  if (getHasPermission(PermissionEnum.MANAGE_ORDERS)) {
    return CabinetNavigatorPathVendor.ORDER_CONTROL
  }

  if (getHasPermission(PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS)) {
    return CabinetNavigatorPathVendor.ITEMS
  }

  if (getHasPermission(PermissionEnum.CREATE_PROMOTION_BANNER)) {
    return CabinetNavigatorPathAdmin.BANNERS
  }

  return CabinetNavigatorPathVendor.PROFILE_SETTINGS_GENERAL
}
