import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    cursor: 'pointer'
  },
  text: {
    cursor: 'pointer',
    marginLeft: 4
  }
})
