import React from 'react'
import { useTheme } from 'react-jss'

import { Icon, ICONS, Row, Text } from '../../../../components'
import { LightTheme } from '../../../../components/Theme'

import { useStyle } from './vendor-banner-table-header.styles'

export const VendorBannerTableHeader = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <>
      <Row
        className={`${classes.tableColumn} ${classes.maxTableColumn}`}
        justifyContent="flex-start"
      >
        <Text
          color="black"
          preset="h6"
          text="Name"
          tx="cabinet.vendor.banner.table.name"
        />
        <Icon className={classes.arowIcon} src={ICONS.trendingFlatTop} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text
          color="black"
          preset="h6"
          text="Status"
          tx="cabinet.vendor.banner.table.status"
        />
        <Icon className={classes.arowIcon} src={ICONS.trendingFlatTop} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text
          color="black"
          preset="h6"
          text="Date"
          tx="cabinet.vendor.banner.table.date"
        />
        <Icon className={classes.arowIcon} src={ICONS.trendingFlatTop} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text
          color="black"
          preset="h6"
          text="Last Editing"
          tx="cabinet.vendor.banner.table.last.editing"
        />
        <Icon className={classes.arowIcon} src={ICONS.trendingFlatTop} />
      </Row>
    </>
  )
}
