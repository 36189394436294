import { COLLECTION_FRAGMENT } from './collection'
import { PROMOTION_BANNER_FRAGMENT } from './menu'
import { BASIC_PRODUCT_FRAGMENT } from './product'

export const BASIC_ADVERTISING_FRAGMENT = `
  ${BASIC_PRODUCT_FRAGMENT}
  ${COLLECTION_FRAGMENT}
  ${PROMOTION_BANNER_FRAGMENT}
  fragment BasicAdvertisingFields on Advertising {
    id
    name
    type
    startDate
    endDate
    totalPrice
    pricePerDay
    isVisible
    product {
      ...BasicProductFields
    }
    banner {
      ...PromotionBammerFrafment
    }
    collection {
      ...CollectionFragment
    }
  }
`

export const BASIC_ADVERTISING_LIMITS_FRAGMENT = `
  fragment BasicAdvertisingLimitsFragment on AdvertisingLimits {
    id
    bannerPricePerDay
    topProductPricePerDay
    changesTime
    allowedShowingAmount
  }
`
