import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  image: {
    objectFit: 'cover',
    border: '4px solid transparent',
    '&:hover': {
      background: theme.colors.white,
      boxShadow: `4px 4px 16px ${theme.colors.shadow}`
    },
    '&:not(:first-child)': {
      marginLeft: 20
    }
  },
  currentImage: {
    objectFit: 'cover'
  },
  carouselContainer: {
    height: 250,
    width: 600,
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  arrowContainer: {
    background: theme.colors.white,
    width: 50,
    top: 0,
    zIndex: 2,
    cursor: 'pointer'
  },
  bottom: {
    left: 'auto'
  },
  top: {
    left: 'auto'
  },
  selected: {
    border: `4px solid ${theme.colors.yellow}`
  },
  imgColumn: {},
  '@media screen and (max-width: 1400px)': {
    currentImage: {
      width: 336,
      height: 280
    },
    image: {
      width: 136,
      height: 136,
      '&:not(:first-child)': {
        marginLeft: 16
      }
    },
    carouselContainer: {
      height: 200
    }
  }
}))
