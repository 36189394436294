import React, { FC, MouseEvent, useState } from 'react'
import { useTheme } from 'react-jss'

import { CancelOrderModal } from '../../../pages/views'
import { OrderMainStatus, PermissionEnum } from '../../../services'
import { useApi } from '../../../providers'
import { getColorByMainStatus, getMessageByMainStatus } from '../../../utils'

import { PermissionContent } from '../../permission-content'
import { Row } from '../../row'
import { Column } from '../../column'
import { Button } from '../../button'
import { ColorDot } from '../../color-dot'
import { LightTheme } from '../../Theme'

import { useStyle } from './order-slider-header.styles'
import { OrderSliderHeaderProps } from './order-slider-header.types'
import { OrderSliderHeaderColumn } from './order-slider-header-column'

export const OrderSliderHeader: FC<OrderSliderHeaderProps> = ({
  id,
  number,
  created,
  price,
  mainStatus,
  paymentStatus,
  vendorName,
  customerName
}) => {
  const { order } = useApi()
  const [cancelOpen, changeCancelOpen] = useState(false)

  const { onSubmit } = order.useOrderCancelationByAdmin()

  const theme: LightTheme = useTheme()
  const classes = useStyle()
  const orderIdText = `# ${number}`
  const colorStatus = theme.colors[getColorByMainStatus(mainStatus)]
  const mainStatusText = getMessageByMainStatus(mainStatus)

  const cancelIsEnabled =
    mainStatus === OrderMainStatus.CANCELED_BY_CUSTOMER ||
    mainStatus === OrderMainStatus.CANCELED_BY_VENDOR

  const handleOnOpenConfirmModal =
    (state: boolean) =>
    (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      changeCancelOpen(state)
    }

  const handleOnCancelSubmit = (value: string) => {
    const refundPercentage = Number(value)

    changeCancelOpen(false)

    if (refundPercentage >= 0) {
      onSubmit({ orderId: id, refundPercentage })
    }
  }

  return (
    <Column fullWidth className={classes.container}>
      <Row wrap fullWidth alignItems="center" justifyContent="space-between">
        <ColorDot preset="big" color={colorStatus} />
        <OrderSliderHeaderColumn
          className={classes.firstColumn}
          title={orderIdText}
          value={mainStatusText.text}
          valueTx={mainStatusText.tx}
        />
        <OrderSliderHeaderColumn
          className={classes.column}
          title="Date:"
          titleTx="order.item.date.title"
          value={created}
        />
        <OrderSliderHeaderColumn
          className={classes.column}
          title="Total Price:"
          titleTx="order.totalPrice"
          value={price}
        />
        <PermissionContent
          permissions={[
            PermissionEnum.ADD_CHANGE_REMOVE_ORDERS,
            PermissionEnum.VIEW_ORDER
          ]}
        >
          {vendorName && (
            <OrderSliderHeaderColumn
              className={classes.column}
              title="Company:"
              titleTx="order.item.vendor.title"
              value={vendorName}
            />
          )}
        </PermissionContent>

        <OrderSliderHeaderColumn
          className={classes.column}
          title="Customer:"
          titleTx="order.item.customer.title"
          value={customerName}
        />
        <PermissionContent
          permissions={[
            PermissionEnum.MANAGE_ORDER_STATUS,
            PermissionEnum.CHANGE_ORDER_STATUS
          ]}
        >
          <Row className={classes.cancelRow}>
            {cancelIsEnabled && (
              <Button
                className={classes.button}
                textPreset="maxButton"
                textColor="white"
                preset="secondaryMini"
                text="Cancellation"
                tx="order.item.cancellation"
                onClick={handleOnOpenConfirmModal(true)}
              />
            )}
          </Row>
        </PermissionContent>
      </Row>
      <CancelOrderModal
        open={cancelOpen}
        paymentStatus={paymentStatus}
        amount={price}
        onClose={handleOnOpenConfirmModal(false)}
        onSubmit={handleOnCancelSubmit}
      />
    </Column>
  )
}
