import React, { FC } from 'react'

import { hasPermission } from '../../auth/misc'
import { useUser } from '../../hooks'

import { PermissionContentProps } from './permission-content.types'

export const PermissionContent: FC<PermissionContentProps> = ({
  isStaff,
  children,
  permissions
}) => {
  const { user } = useUser()

  if (!user) {
    return <></>
  }

  if (permissions) {
    const hasPermissions = permissions
      .map((permission) => hasPermission(permission, user))
      .reduce((prev, curr) => prev || curr)

    if (!hasPermissions) {
      return <></>
    }

    if (typeof isStaff !== 'undefined' && !user.isStaff) {
      return <></>
    }
  }

  return <>{children}</>
}
