import React, { ChangeEvent, FC, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  Column,
  LoadPhotoContainer,
  RadioBox,
  RadioItemWithIdProps,
  Section,
  Slider,
  Text,
  TextArea,
  TextField
} from '../../../components'
import { LightTheme } from '../../../components/Theme'
import { CreateBannerInput, BannerButtonType } from '../../../services/menu'

import { useStyle } from './promoted-banner.styles'
import { PromotedBannerViewProps } from './promoted-banner.types'

const buttonTypeRadioData: RadioItemWithIdProps[] = [
  {
    id: BannerButtonType.BIG_BUTTON.toString(),
    name: 'Big Button',
    nameTx: 'banner.button.type.bigButton'
  },
  {
    id: BannerButtonType.LITTLE_BUTTON.toString(),
    name: 'Little Button',
    nameTx: 'banner.button.type.littleButton'
  }
]

export const PromotedBannerView: FC<PromotedBannerViewProps> = ({
  banner: defaultBanner,
  children,
  mobileImages,
  onChange,
  onChangeMobileImage
}) => {
  const [banner, changeBanner] = useState<CreateBannerInput>(defaultBanner)
  const [prevBannerState, changePrevBannerState] =
    useState<CreateBannerInput>(defaultBanner)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const handleOnChangeInput =
    (prop: keyof CreateBannerInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target
      const nextBanner: CreateBannerInput = {
        ...banner,
        [prop]: value
      }

      const nextPrevBanner: CreateBannerInput = {
        ...prevBannerState,
        [prop]: value
      }

      changePrevBannerState(nextPrevBanner)
      changeBanner(nextBanner)

      if (onChange) {
        onChange(nextBanner)
      }
    }

  const handleOnChangeRadio = (id: string) => {
    const nextBanner: CreateBannerInput = {
      ...banner,
      buttonType: id as BannerButtonType
    }

    const nextPrevBanner: CreateBannerInput = {
      ...prevBannerState,
      buttonType: id as BannerButtonType
    }

    changePrevBannerState(nextBanner)
    changeBanner(nextPrevBanner)

    if (onChange) {
      onChange(nextBanner)
    }
  }

  const handleOnChangeActiveButton = (isButtonActive: boolean) => {
    const nextBanner: CreateBannerInput = {
      ...banner,
      isButtonActive
    }

    const nextPrevBanner: CreateBannerInput = {
      ...prevBannerState,
      isButtonActive
    }

    changePrevBannerState(nextBanner)
    changeBanner(nextPrevBanner)

    if (onChange) {
      onChange(nextBanner)
    }
  }

  const buttonSectionSlider = (
    <Slider
      defaultValue={defaultBanner.isButtonActive}
      onChange={handleOnChangeActiveButton}
    />
  )

  const descriptionLength = prevBannerState.description
    ? prevBannerState.description.length
    : 0

  return (
    <>
      <LoadPhotoContainer
        className={classes.section}
        defaultValue={mobileImages}
        title="Mobile Banner Image"
        titleTx="banner.mobile.image"
        onChange={onChangeMobileImage}
      />
      <Section
        className={classes.section}
        description="(Optional)"
        descriptionTx="banner.text.fields.description"
        title="Text fields"
        titleTx="banner.text.fields.title"
      >
        <Column
          alignItems="flex-start"
          className={classes.sectionAdditionContainer}
        >
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.mainHeading}
            preset="border"
            maxLength={25}
            label="Main Heading"
            labelTx="banner.main.heading"
            onChange={handleOnChangeInput('mainHeading')}
          />
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.title}
            preset="border"
            maxLength={50}
            label="Title"
            labelTx="banner.title"
            onChange={handleOnChangeInput('title')}
          />
          <TextArea
            className={classes.field}
            defaultValue={prevBannerState.description}
            preset="border"
            label={`Description ${descriptionLength}/200`}
            labelTx="banner.main.description"
            maxLength={200}
            valuesTx={{ length: descriptionLength }}
            onChange={handleOnChangeInput('description')}
          />
        </Column>
      </Section>
      <Section
        className={classes.section}
        title="Button"
        titleTx="banner.button.title"
        rightButton={buttonSectionSlider}
      >
        <Column
          alignItems="flex-start"
          className={classes.sectionAdditionContainer}
        >
          <Text
            color="black"
            preset="h5"
            text="Button Type"
            tx="banner.button.type"
          />
          <RadioBox
            active={String(prevBannerState.buttonType)}
            className={classes.field}
            data={buttonTypeRadioData}
            onChange={handleOnChangeRadio}
          />
        </Column>
        <Column
          alignItems="flex-start"
          className={classes.sectionAdditionContainer}
        >
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.buttonText}
            preset="border"
            label="Button Text"
            maxLength={15}
            labelTx="banner.button.text"
            onChange={handleOnChangeInput('buttonText')}
          />
          <TextField
            className={classes.field}
            defaultValue={prevBannerState.buttonLink}
            preset="border"
            maxLength={200}
            label="Link for Button"
            labelTx="banner.button.link"
            onChange={handleOnChangeInput('buttonLink')}
          />
        </Column>
      </Section>
      {children}
    </>
  )
}
