import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  summaryContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${theme.colors.shadow}`,
    flex: 1,
    marginTop: 20,
    justifyContent: 'flex-start'
  },
  infoTitle: {
    padding: 16,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  summaryColumn: {
    padding: '16px',
    columnGap: 20,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  summaryItem: {
    marginTop: 16,
    width: '100%',
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    padding: '4px 24px',
    height: 50
  },
  summaryMail: {
    width: '100%',
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    padding: '4px 24px'
  },
  imgContainer: {
    width: 120,
    height: 120,
    borderRadius: 50,
    margin: '0 auto',
    '& img': {
      width: 120,
      height: 120,
      borderRadius: '50%',
      objectFit: 'cover'
    }
  },
  change: {
    cursor: 'pointer',
    marginTop: 16,
    textDecoration: 'underline'
  }
}))
