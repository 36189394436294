import { useMutation, useQuery } from '@apollo/client'

import {
  BillingVariables,
  CreatePaypalOrder,
  CreatePaypalOrderData,
  CreatePaypalOrderVariables,
  GetBilling,
  GetBillingData,
  GetPayouts,
  GetPayoutsData,
  PaymentApi,
  PayoutsVariables
} from './payment.types'
import {
  BILLING_QUERY,
  CREATE_PAYPAL_ORDER_MUTATION,
  PAYOUTS_QUERY
} from './payment.graphql'

export const paymentService = (): PaymentApi => {
  const usePayouts = (variables: PayoutsVariables): GetPayouts => {
    const { data, loading, error, refetch } = useQuery<
      GetPayoutsData,
      PayoutsVariables
    >(PAYOUTS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useBilling = (variables: BillingVariables): GetBilling => {
    const { data, loading, error, refetch } = useQuery<
      GetBillingData,
      BillingVariables
    >(BILLING_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCreatePaypalOrder = (): CreatePaypalOrder => {
    const [OrderUpdateSubmit, response] = useMutation<
      CreatePaypalOrderData,
      CreatePaypalOrderVariables
    >(CREATE_PAYPAL_ORDER_MUTATION)

    const handleOnSubmit = (variables: CreatePaypalOrderVariables) => {
      const options = {
        variables
      }
      OrderUpdateSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useCreatePaypalOrder,
    usePayouts,
    useBilling
  }
}
