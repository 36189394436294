import camelcaseKeys from 'camelcase-keys'
import { CoreItem } from '../components'

import { PaginationList, FilterItem } from './api.types'

export const getItemsFromEdges = <T>(data: PaginationList<T>): T[] => {
  return data.edges.map(({ node }) => node)
}

export const getFiltersJSONToCoreItemFilters = <
  T extends Record<string, string[]>
>(
  list: T
) =>
  Object.entries(list).reduce<Record<keyof T, CoreItem[]>>(
    (acc, item) => ({
      ...acc,
      [item[0]]: item[1].map((filterItem) => ({
        id: filterItem,
        name: filterItem
      }))
    }),
    {} as Record<keyof T, CoreItem[]>
  )

export const getFilterItemsJSONToCoreItemFilters = <
  T extends Record<string, FilterItem[] | string[]>
>(
  list: T
) =>
  Object.entries(list).reduce<Record<keyof T, CoreItem[]>>(
    (acc, item) => ({
      ...acc,
      [item[0]]: item[1].map((filterItem) => {
        if (typeof filterItem === 'object') {
          const { id = '', name = '' } = filterItem
          return {
            id,
            name
          }
        }
        if (typeof filterItem === 'string') {
          return {
            id: filterItem,
            name: filterItem
          }
        }

        return {
          id: '',
          name: ''
        }
      })
    }),
    {} as Record<keyof T, FilterItem[]>
  )

export const parseFilterItems = <
  T extends Record<string, FilterItem[] | string[]>
>(
  dataJSON: string
): Record<keyof T, CoreItem[]> | undefined => {
  if (dataJSON) {
    const list = camelcaseKeys<T>(JSON.parse(dataJSON) as T, {
      deep: true
    }) as T

    if (list) {
      return getFilterItemsJSONToCoreItemFilters(list)
    }
  }

  return undefined
}
