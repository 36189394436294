import React, { FC, useState } from 'react'

import { Column, Row, Text } from '../../../components'

import avatar_sample from '../business-info/avatar.png'
import { ChangeEmailModal } from '../change-email-modal'
import { ChangePasswordModal } from '../chage-password-modal'

import { useStyle } from './profile-summary.styles'
import { ProfileSummaryProps } from './profile-summary.types'

export const ProfileSummary: FC<ProfileSummaryProps> = ({
  avatar,
  companyName,
  country,
  email,
  isStaff
}) => {
  const classes = useStyle()
  const [openChangeEmailModal, changeOpenChangeEmailModal] = useState(false)
  const [openChangePasswordModal, changeOpenChangePasswordModal] =
    useState(false)

  const handleOnOpenChangeEmailModal = () => {
    changeOpenChangeEmailModal(!openChangeEmailModal)
  }
  const handleOnOpenChangePasswordModal = () => {
    changeOpenChangePasswordModal(!openChangePasswordModal)
  }

  return (
    <Column
      className={classes.summaryContainer}
      alignItems="flex-start"
      fullWidth
    >
      <Row fullWidth justifyContent="flex-start" className={classes.infoTitle}>
        <Text color="black" preset="h3" text="Summary" />
      </Row>
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="space-between"
        className={classes.summaryColumn}
      >
        <Row fullWidth className={classes.imgContainer}>
          <img
            src={avatar?.url ? `${avatar?.url}` : avatar_sample}
            alt="avatar"
          />
        </Row>
        <Column fullWidth alignItems="flex-start">
          <Column
            fullWidth
            alignItems="flex-start"
            className={classes.summaryItem}
          >
            <Text
              text="Business/Company name"
              tx="userInfo.companyName"
              preset="subscriptionText"
              color="inactive"
            />
            <Text text={companyName ?? ' '} preset="body" color="inactive" />
          </Column>
          <Column
            fullWidth
            alignItems="flex-start"
            className={classes.summaryItem}
          >
            <Text
              text="Country"
              tx="userInfo.country"
              preset="subscriptionText"
              color="inactive"
            />
            <Text text={country ?? ''} preset="body" color="inactive" />
          </Column>
        </Column>
      </Column>
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="space-between"
        className={classes.summaryColumn}
      >
        <Column
          fullWidth
          alignItems="flex-start"
          className={classes.summaryMail}
        >
          <Text
            text="Current E-mail"
            tx="register.email"
            preset="subscriptionText"
            color="inactive"
          />
          <Text text={email ?? ''} preset="body" color="inactive" />
        </Column>
        {!isStaff && (
          <Text
            color="blue"
            preset="h6"
            text="Change Email"
            onClick={handleOnOpenChangeEmailModal}
            className={classes.change}
          />
        )}
        <Text
          color="blue"
          preset="h6"
          text="Change Password"
          onClick={handleOnOpenChangePasswordModal}
          className={classes.change}
        />
      </Column>
      {openChangeEmailModal && (
        <ChangeEmailModal
          title="Change Email"
          titleTx="change.email.modal.title"
          onClose={handleOnOpenChangeEmailModal}
          email={email ?? ''}
        />
      )}
      {openChangePasswordModal && (
        <ChangePasswordModal
          title="Change Password"
          titleTx="change.password.modal.title"
          secondaryDescription="You can change your Password."
          secondaryDescriptionTx="change.password.modal.secondaryDescription"
          onClose={handleOnOpenChangePasswordModal}
        />
      )}
    </Column>
  )
}
