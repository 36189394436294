import { TypePolicies, useMutation, useQuery } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

import {
  DraftOrderCreateInput,
  DraftOrderCreateData,
  DraftOrderCreate,
  GetOrders,
  GetOrdersData,
  OrderUpdateData,
  OrderUpdateVariables,
  OrderUpdate,
  OrderApi,
  OrdersVariables,
  OrderCancelationByAdmin,
  OrderCancelationByAdminData,
  OrderCancelationByAdminVariables,
  OrderByIdVariables,
  GetOrderByIdRequest,
  GetOrder
} from './order.types'
import {
  DRAFT_ORDER_CREATE,
  ORDERS_QUERY,
  ORDER_BY_ID,
  ORDER_CANCELATION_BY_ADMIN,
  ORDER_UPDATE_MUTATION
} from './order.graphql'
import { updateFromQueryById } from '../update-cache'

export const cacheOrderConfig: TypePolicies = {
  Query: {
    fields: {
      orders: relayStylePagination()
    }
  }
}

export const orderService = (): OrderApi => {
  const useDraftOrderCreate = (): DraftOrderCreate => {
    const [RequestAccountAddressCreateSubmit, response] = useMutation<
      DraftOrderCreateData,
      DraftOrderCreateInput
    >(DRAFT_ORDER_CREATE)

    const handleOnSubmit = (variables: DraftOrderCreateInput) => {
      const options = {
        variables
      }
      RequestAccountAddressCreateSubmit(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOrderUpdate = (): OrderUpdate => {
    const [OrderUpdateSubmit, response] = useMutation<
      OrderUpdateData,
      OrderUpdateVariables
    >(ORDER_UPDATE_MUTATION)

    const handleOnSubmit = (variables: OrderUpdateVariables) => {
      const options = {
        variables
      }
      OrderUpdateSubmit({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetOrdersData>({
            query: ORDERS_QUERY
          })

          if (queryResult) {
            cache.writeQuery<GetOrdersData>({
              query: ORDERS_QUERY,
              data: updateFromQueryById(
                'orders',
                queryResult,
                result.data?.orderUpdate.order
              )
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOrderCancelationByAdmin = (): OrderCancelationByAdmin => {
    const [OrderUpdateSubmit, response] = useMutation<
      OrderCancelationByAdminData,
      OrderCancelationByAdminVariables
    >(ORDER_CANCELATION_BY_ADMIN)

    const handleOnSubmit = (variables: OrderCancelationByAdminVariables) => {
      const options = {
        variables
      }

      OrderUpdateSubmit({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetOrdersData>({
            query: ORDERS_QUERY
          })

          if (queryResult) {
            cache.writeQuery<GetOrdersData>({
              query: ORDERS_QUERY,
              data: {
                ...queryResult,
                orders: {
                  ...queryResult.orders,
                  edges: queryResult.orders.edges.map((item) => {
                    if (item.node.id === variables.orderId) {
                      return {
                        ...item,
                        node: {
                          ...item.node,
                          mainStatus:
                            result.data?.cancelOrderByAdmin.orderStatus ||
                            item.node.mainStatus
                        }
                      }
                    }

                    return item
                  })
                }
              }
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOrders = (variables: OrdersVariables): GetOrders => {
    const { data, loading, error, refetch, fetchMore } = useQuery<
      GetOrdersData,
      OrdersVariables
    >(ORDERS_QUERY, { variables, fetchPolicy: 'network-only' })

    if (error) {
      return { data: null, loading, refetch, fetchMore }
    }

    if (!data) {
      return { data: null, loading, refetch, fetchMore }
    }

    return { data, loading, refetch, fetchMore }
  }

  const useOrderById = (variables: OrderByIdVariables): GetOrderByIdRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetOrder,
      OrderByIdVariables
    >(ORDER_BY_ID, { variables, fetchPolicy: 'network-only' })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useDraftOrderCreate,
    useOrders,
    useOrderUpdate,
    useOrderCancelationByAdmin,
    useOrderById
  }
}
