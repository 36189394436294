import React, { useState, FC, useMemo, Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import {
  arrayHasElements,
  Button,
  Checkbox,
  Column,
  Icon,
  ICONS,
  Row,
  Text,
  TextField,
  TablePagginationBar,
  Loader,
  SortItem,
  QuestionConfirmModal,
  EmptySection
} from '../../../components'
import { OrderDirection, PromotionBannersSortField } from '../../../services'

import { CabinetNavigatorPathVendor } from '../../cabinet-navigator'

import { PromotionBannerTableItem } from '../promotion-banners-table-item'

import { useStyle } from './promotion-banners-table.styles'
import { PromotionBannersTableProps } from './promotion-banners-table.types'

export const PromotionBannersTable: FC<PromotionBannersTableProps> = ({
  className = '',
  children,
  data,
  loading,
  paginationState,
  rowCounts,
  onRemove,
  onUpdate,
  onChangePage,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount,
  onSearch,
  onSort
}) => {
  const history = useHistory()
  const classes = useStyle()

  const handleOnSort =
    (feedbackOrderField: PromotionBannersSortField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(feedbackOrderField, direction)
      }
    }

  const [checkedIds, changeCheckedIds] = useState<string[]>([])
  const [openConfirmModal, changeOpenConfirmModal] = useState(false)

  const checkedItemsCount = checkedIds.length
  const checkedItemsCountValue = { checkedItemsCount }
  const checkedItemText = `Selected ${checkedItemsCount} items`

  const handleOnOpenConfirmModal =
    (callback: Dispatch<SetStateAction<boolean>>, state: boolean) => () => {
      return callback(state)
    }

  const handleOnRemove = () => {
    if (onRemove) {
      onRemove(checkedIds)
      changeCheckedIds([])
      changeOpenConfirmModal(false)
    }
  }
  const handleOnPublish = () => {
    const publishData = data
      .filter((item) => checkedIds.includes(item.id))
      .map((item) => {
        return { ...item, isVisible: true }
      })
    if (onUpdate && publishData.length) {
      onUpdate(publishData)
    }
  }

  const handleOnUnpublish = () => {
    const publishData = data
      .filter((item) => checkedIds.includes(item.id))
      .map((item) => {
        return { ...item, isVisible: false }
      })
    if (onUpdate && publishData.length) {
      onUpdate(publishData)
    }
  }

  const handleOnPick = (id: string, state: boolean) => {
    const filteredIds = checkedIds.filter((removeId) => removeId !== id)
    if (state) {
      changeCheckedIds([...filteredIds, id])
    } else {
      changeCheckedIds(filteredIds)
    }
  }

  const handleOnPickAll = (state: boolean) => {
    if (state) {
      const ids = data.map((product) => product.id)
      changeCheckedIds(ids)
    } else {
      changeCheckedIds([])
    }
  }

  const CheckedAll = useMemo(() => {
    return checkedIds.length === data.length
  }, [checkedIds, data])

  const getActive = (id: string) => {
    return Boolean(checkedIds.find((removeId) => removeId === id))
  }

  const handleOnClickItem = (id: string) => {
    history.push(`${CabinetNavigatorPathVendor.EDIT_PROMOTION_BANNER}/${id}`)
  }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onSearch}
        />
        {children}
      </Row>
      <Row fullWidth className={classes.header} justifyContent="space-between">
        {arrayHasElements(checkedIds) ? (
          <Row fullWidth justifyContent="space-between">
            <Row
              className={`${classes.tableColumnFirst} ${classes.tableColumnMax}`}
              justifyContent="flex-start"
            >
              <Checkbox checked={CheckedAll} onChange={handleOnPickAll} />
              <Text
                className={classes.text}
                preset="body"
                color="black"
                text={checkedItemText}
                tx="cabinet.promotion.banners.count.text"
                values={checkedItemsCountValue}
              />
            </Row>
            <Row>
              <Row className={classes.buttonContainer}>
                <Button
                  className={classes.button}
                  preset="secondaryBorderMini"
                  text="Activate"
                  textPreset="maxButton"
                  textColor="yellow"
                  tx="cabinet.banners.activate.button"
                  onClick={handleOnPublish}
                />
                <Button
                  className={classes.button}
                  preset="secondaryBorderMini"
                  text="Deactivate"
                  textPreset="maxButton"
                  textColor="yellow"
                  tx="cabinet.banners.deactivate.button"
                  onClick={handleOnUnpublish}
                />
              </Row>
              <Row
                className={classes.remove}
                onClick={handleOnOpenConfirmModal(changeOpenConfirmModal, true)}
              >
                <Icon src={ICONS.trash} />
              </Row>
              {openConfirmModal && (
                <QuestionConfirmModal
                  title="Delete Promotion Banner"
                  titleTx="promotion.banner.modal.delete.title"
                  description="Are you sure?"
                  descriptionTx="promotion.banner.modal.delete.description"
                  secondaryDescription="You really want to delete this promotion banner?"
                  secondaryDescriptionTx="promotion.banner.modal.delete.secondaryDescription"
                  onSubmit={handleOnRemove}
                  onClose={handleOnOpenConfirmModal(
                    changeOpenConfirmModal,
                    false
                  )}
                />
              )}
            </Row>
          </Row>
        ) : (
          <>
            <Row
              className={classes.tableColumnFirst}
              justifyContent="flex-start"
            >
              <Checkbox checked={CheckedAll} onChange={handleOnPickAll} />
            </Row>
            <Row
              className={`${classes.tableColumn} ${classes.tableColumnMax}`}
              justifyContent="flex-start"
            >
              <SortItem
                name="Name"
                nameTx="cabinet.promotion.banners.name"
                onClick={handleOnSort(PromotionBannersSortField.NAME)}
              />
            </Row>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <SortItem
                name="Status"
                nameTx="cabinet.promotion.banners.status"
                onClick={handleOnSort(PromotionBannersSortField.IS_VISIBLE)}
              />
            </Row>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <SortItem
                name="Start Date"
                nameTx="cabinet.promotion.banners.startDate"
                onClick={handleOnSort(PromotionBannersSortField.START_DATE)}
              />
            </Row>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <SortItem
                name="End Date"
                nameTx="cabinet.promotion.banners.endDate"
                onClick={handleOnSort(PromotionBannersSortField.END_DATE)}
              />
            </Row>
            <Row
              className={`${classes.tableColumn} ${classes.tableColumnMin}`}
              justifyContent="flex-start"
            >
              <Text
                color="black"
                preset="h6"
                text="Days"
                tx="cabinet.promotion.banners.days"
              />
            </Row>
          </>
        )}
      </Row>
      {loading && <Loader />}
      {!loading &&
        data.map((promotionBanner) => (
          <PromotionBannerTableItem
            key={`banner_${promotionBanner.id}`}
            {...promotionBanner}
            id={promotionBanner.id}
            image={promotionBanner.image}
            name={promotionBanner.name}
            days={promotionBanner.days}
            isVisible={promotionBanner.isVisible}
            startDate={promotionBanner.startDate}
            endDate={promotionBanner.endDate}
            checked={getActive(promotionBanner.id)}
            onChange={handleOnPick}
            onClick={handleOnClickItem}
          />
        ))}
      {!loading && !arrayHasElements(data) && (
        <EmptySection
          text="No Banners found."
          tx="advertising.no.banners.found"
        />
      )}
      {!loading && paginationState && (
        <TablePagginationBar
          paginationState={paginationState}
          rowCounts={rowCounts}
          onNextPage={onNextPage}
          onPrevioslyPage={onPrevioslyPage}
          onChangePage={onChangePage}
          onChangeRowCount={onChangeRowCount}
        />
      )}
    </Column>
  )
}
