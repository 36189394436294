import React, { useEffect } from 'react'

import { useNavigator } from '../../hooks'
import { useApi } from '../../providers'
import { PromotionBannerCreateInput } from '../../services'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'
import { PromotionBannerForm } from '../promotions-banner-form'

export const CreatePromotionBannerPage = () => {
  const { menu } = useApi()
  const { response, onSubmit } = menu.useCreatePromotionBanner()
  const navigate = useNavigator()

  useEffect(() => {
    if (response.data && response.data.promotionBannerCreate.promotionBanner) {
      navigate(CabinetNavigatorPathVendor.PROMOTIONS)
    }
  }, [response.data])

  const handleOnSubmit = (nextBanner: PromotionBannerCreateInput) => {
    if (nextBanner && nextBanner.image) {
      onSubmit(nextBanner)
    }
  }

  return (
    <PromotionBannerForm
      title="Create Banner"
      titleTx="banner.create"
      onSumbit={handleOnSubmit}
    />
  )
}
