import { createUseStyles } from 'react-jss'

import { ColumnProps } from './column.types'

export const useStyle = createUseStyles({
  column: {
    alignItems: (props: ColumnProps) => props.alignItems || 'center',
    display: 'flex',
    flexDirection: 'column',
    height: (props: ColumnProps) => props.fullHeight && '100%',
    justifyContent: (props: ColumnProps) => props.justifyContent || 'center',
    position: 'relative',
    width: (props: ColumnProps) => props.fullWidth && '100%'
  }
})
