import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    border: `1px solid ${theme.colors.shadow}`
  },
  line: {},
  content: {
    borderTop: `1px solid ${theme.colors.shadow}`
  }
}))
