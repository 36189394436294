import { TextTranslate } from '../text'

export enum CalendarPickerType {
  DATE = 'date',
  PERIOD = 'period'
}

export interface DateOfWeekPicker {
  text: string
  tx: TextTranslate
  values: string[]
}

export type CalendarPickerProps = {
  value?: Date[]
  onChange?: (from: string, to?: string) => void
}
