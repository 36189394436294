import React, { FC, useMemo } from 'react'
import moment from 'moment'
import 'moment/locale/en-gb'

import {
  Column,
  Row,
  Text,
  SubscriptionBenefit,
  ICONS,
  Loader,
  getCurrencySymbol,
  getPriceToFixed
} from '../../../components'
import { capitalize } from '../../../misc'
import { Currency } from '../../../services'

import { getFormatedDescription } from '../../views'

import { SubscriptionContainer } from '../subsciption-container'
import { SubscriptionCard } from '../subscription-card'

import { SubscriptionOptionProps } from './subscription-option.types'
import { useStyle } from './subscription-option.styles'

const icons = [ICONS.infinity, ICONS.phonelink, ICONS.localATM, ICONS.bill]

export const SubscriptionOption: FC<SubscriptionOptionProps> = ({
  types,
  selected,
  defaultType,
  nextPayment,
  onChangeSelected,
  onChangeSelectedPlanId
}) => {
  const classes = useStyle()
  const date = capitalize(
    moment(nextPayment).locale('en-US').format('MMMM Do YYYY')
  )
  const SubscriptionTypes = useMemo(() => {
    if (types) {
      return types.map((item) => {
        return { ...item.node }
      })
    }
    return []
  }, [types])

  const CurrentType = useMemo(() => {
    return SubscriptionTypes.find((type) => type.name === defaultType)
  }, [SubscriptionTypes, defaultType])

  const handleOnClick = (name: string, id: string) => () => {
    onChangeSelected(name)
    onChangeSelectedPlanId(id)
  }

  const Benefits = useMemo(() => {
    const type = SubscriptionTypes.find((el) => el.name === selected)

    if (type) {
      return getFormatedDescription(type.description)
    }

    return []
  }, [SubscriptionTypes, selected])

  if (!CurrentType) {
    return <Loader />
  }

  const subcriptionActivePricePer = CurrentType.pricePer
    ? `/${CurrentType.pricePer}`
    : ''

  const subcriptionPrice = `${getCurrencySymbol(Currency.USD)}${getPriceToFixed(
    CurrentType.price
  )}${subcriptionActivePricePer}`

  const isFree = !nextPayment || CurrentType?.name === 'Free'

  return (
    <>
      {CurrentType && (
        <>
          <SubscriptionContainer
            active
            justifyContent="flex-start"
            title={CurrentType.name}
          >
            <Row className={classes.price}>
              <Text color="black" preset="h2" text={subcriptionPrice} />
            </Row>
            <Row className={classes.benefits} justifyContent="flex-start">
              {getFormatedDescription(CurrentType.description).map(
                (benefit, index) => (
                  <SubscriptionBenefit
                    key={`benefit_${index}`}
                    src={ICONS.steeringWheel}
                    text={benefit}
                  />
                )
              )}
            </Row>
          </SubscriptionContainer>
          <SubscriptionContainer
            justifyContent="flex-start"
            title="Next Payment"
            titleTx="subscription.plan.payment.date"
          >
            <Row
              fullWidth
              justifyContent="space-between"
              className={classes.price}
            >
              <Text
                color="inactive"
                preset="body"
                text="Date of invoice:"
                tx="subscription.plan.date.invoice"
              />
              {isFree ? (
                <Text
                  color="black"
                  preset="h3"
                  text="Free User"
                  tx="subscription.plan.free.user"
                />
              ) : (
                <Text color="black" preset="h3" text={date} />
              )}
            </Row>
          </SubscriptionContainer>
        </>
      )}

      <Column
        fullWidth
        className={classes.titleContainer}
        alignItems="flex-start"
      >
        <Text
          color="black"
          preset="h3"
          text="Subscription Options"
          tx="subscription.plan.subscription.options"
        />
        <Row fullWidth className={classes.options}>
          {SubscriptionTypes.filter((type) => type.name !== defaultType).map(
            (type, index) => (
              <SubscriptionCard
                key={`subscription_price_${index}`}
                id={type.id}
                name={type.name}
                price={type.price}
                pricePer={type.pricePer}
                active={selected === type.name}
                selected={selected === type.name}
                onClick={handleOnClick(type.name, type.paypalPlanId)}
              />
            )
          )}
        </Row>
        <Column fullWidth alignItems="flex-start">
          <Column className={classes.benefits} alignItems="flex-start">
            {Benefits.map((benefit, index) => (
              <SubscriptionBenefit
                key={`subscription_benefit_${index}`}
                src={icons[index]}
                text={benefit}
              />
            ))}
          </Column>
        </Column>
      </Column>
    </>
  )
}
