import React, { FC, MouseEvent } from 'react'
import { useTheme } from 'react-jss'

import {
  Dot,
  Row,
  Text,
  LightTheme,
  getCurrencySymbol,
  Checkbox,
  getPriceToFixed
} from '../../../../components'
import { Currency, ProductOptionType } from '../../../../services'

import { OfferAdditionalOptionTableItemProps } from './offer-additional-option-table-item.types'
import { useStyle } from './offer-additional-option-table-item.styles'

export const OfferAdditionalOptionTableItem: FC<OfferAdditionalOptionTableItemProps> =
  ({
    title,
    checked,
    type,
    description,
    extraPrice,
    extraProductionDays,
    isDefault,
    onChangeDefault,
    onChange
  }) => {
    const theme: LightTheme = useTheme()
    const classes = useStyle({ theme })

    const handleOnChange = (
      state: boolean,
      event: MouseEvent<HTMLElement, globalThis.MouseEvent>
    ) => {
      event.preventDefault()
      event.stopPropagation()

      if (onChange) {
        onChange(state, event)
      }
    }

    const extraOptionPrice = getPriceToFixed(extraPrice)
    const extraPriceFormated = `${getCurrencySymbol(
      Currency.USD
    )}${extraOptionPrice}`
    const extraProductionDaysFormated = String(extraProductionDays)

    return (
      <Row
        fullWidth
        className={classes.tableRow}
        justifyContent="space-between"
      >
        {type === ProductOptionType.CHECKBOX ? (
          <Row
            className={`${classes.tableColumn} ${classes.tableColumnMin}`}
            justifyContent="flex-start"
            onClick={onChangeDefault}
          >
            <Checkbox checked={checked} onChange={handleOnChange} />
          </Row>
        ) : (
          <Row
            className={`${classes.tableColumn} ${classes.tableColumnMin}`}
            justifyContent="flex-start"
            onClick={onChangeDefault}
          >
            <Dot active={isDefault} onClick={onChangeDefault} />
          </Row>
        )}
        <Row className={classes.tableColumn} justifyContent="flex-start">
          {title && (
            <Text
              className={classes.text}
              color="black"
              preset="body"
              text={title}
            />
          )}
        </Row>
        <Row className={classes.bigTableColumn} justifyContent="flex-start">
          {description && (
            <Text
              className={classes.text}
              color="black"
              preset="body"
              text={description}
            />
          )}
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          {extraPrice && (
            <Text
              className={classes.text}
              color="black"
              preset="body"
              text={extraPriceFormated}
            />
          )}
        </Row>
        <Row className={classes.bigTableColumn} justifyContent="flex-start">
          {extraProductionDays && (
            <Text
              className={classes.text}
              color="black"
              preset="body"
              text={extraProductionDaysFormated}
            />
          )}
        </Row>
      </Row>
    )
  }
