import { MutationResult } from '@apollo/client'

import {
  BackgroundImage,
  PaginationList,
  ListVariables,
  DateRangeInput,
  FetchMore,
  SortBy
} from '../api.types'
import { Collection } from '../collection'

export enum TypeName {
  MENU = 'Menu',
  MENU_ITEM = 'MenuItem'
}

export enum BannerKind {
  PROMOTION_BANNER = '1',
  PREMIUM_USER_BANNER = '2',
  TOP_PRODUCT_BANNER = '3',
  PROMOTED_STORE_BANNER = '4'
}

export enum BannerButtonType {
  BIG_BUTTON = '1',
  LITTLE_BUTTON = '2'
}

export enum BannerErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  ATTRIBUTE_ALREADY_ASSIGNED = 'ATTRIBUTE_ALREADY_ASSIGNED',
  ATTRIBUTE_CANNOT_BE_ASSIGNED = 'ATTRIBUTE_CANNOT_BE_ASSIGNED',
  ATTRIBUTE_VARIANTS_DISABLED = 'ATTRIBUTE_VARIANTS_DISABLED',
  DUPLICATED_INPUT_ITEM = 'DUPLICATED_INPUT_ITEM',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_PRODUCTS_IMAGE = 'NOT_PRODUCTS_IMAGE',
  NOT_PRODUCTS_VARIANT = 'NOT_PRODUCTS_VARIANT',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  VARIANT_NO_DIGITAL_CONTENT = 'VARIANT_NO_DIGITAL_CONTENT'
}

export enum PromotionBannersSortField {
  NAME = 'NAME',
  IS_VISIBLE = 'IS_VISIBLE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE'
}

export interface BannerError {
  code: BannerErrorCode
  field: string
  message: string
}

export interface MainMenuCategory {
  id: string
  name: string
  description: string
  slug: string | null
  level: number
  backgroundImage: BackgroundImage
}

export type MainMenuItem = {
  id: string
  name: string
  category: MainMenuCategory
  url: null
  page: null
  parent: null
  children: MainMenuItem[]
}

export type SecondaryMenuShop = {
  shop: {
    navigation: {
      secondary: {
        __typename: TypeName
        id: string
        items: MainMenuItem[]
      }
    }
  }
}

export type MainMenuShop = {
  shop: {
    navigation: {
      main: {
        __typename: TypeName
        id: string
        items: MainMenuItem[]
      }
    }
  }
}

export interface Banner {
  id: string
  image: string
  mobileImage?: string
  kind: BannerKind
  isButtonActive?: boolean
  isNotificationActive?: boolean
  buttonType: BannerButtonType
  buttonText?: string
  buttonLink?: string
  mainHeading?: string
  secondaryHeading?: string
  title?: string
  description?: string
  mainDescription?: string
  secondaryDescription?: string
  startDate: string
  endDate?: string
  isVisible: boolean
  notificationMessageText?: string
  notificationButtonText?: string
  notificationButtonLink?: string
  name?: string
}

export interface PromotionBanner {
  id: string
  name: string
  title: string
  subtitle: string
  buttonText: string
  buttonLink?: string
  startDate: string
  endDate: string
  isVisible: boolean
  image: string
  mobileImage: string
  days: number
}

export interface VendorBannerImage extends Node {
  id: string
  url: string
}
export interface VendorBanner {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  title: string
  subTitle: string
  buttonText: string
  buttonLink: string
  isVisible: boolean
  images: VendorBannerImage[]
}

export interface CreateBannerInput {
  image?: File
  mobileImage?: File
  kind: BannerKind
  isButtonActive?: boolean
  isNotificationActive?: boolean
  buttonType: BannerButtonType
  buttonText?: string
  buttonLink?: string
  mainHeading?: string
  secondaryHeading?: string
  title?: string
  description?: string
  mainDescription?: string
  secondaryDescription?: string
  startDate?: string
  endDate?: string
  isVisible: boolean
  notificationMessageText?: string
  notificationButtonText?: string
  notificationButtonLink?: string
  name?: string
}

export interface PromotionBannerCreateInput {
  name?: string
  title?: string
  subtitle?: string
  buttonText?: string
  buttonLink?: string
  startDate?: string
  endDate?: string
  isVisible: boolean
  image?: File
  mobileImage?: File
}

export interface VendorBannerCreateInput {
  name: string
  title: string
  subTitle: string
  isVisible: boolean
  buttonText: string
  buttonLink: string
}

export interface VendorBannerImageCreateInput {
  image: File
  banner: string
}

export type BannerFilter = {
  isVisible?: boolean
  kind?: BannerKind[]
  startDate?: DateRangeInput
  endDate?: DateRangeInput
  search?: string
}

export type PromotionBannerFilter = {
  isVisible?: boolean
  startDate?: DateRangeInput
  endDate?: DateRangeInput
  search?: string
}

export type BannerFilterKeys = keyof BannerFilter

export interface EditBannerInput extends CreateBannerInput {}

export interface EditPromotionBannerInput extends PromotionBannerCreateInput {}

export interface EditVendorBannerInput extends VendorBannerCreateInput {}

export interface BannerEdge {
  cursor: string
  node: Banner
}

export interface VendorBannerEdge {
  cursor: string
  node: VendorBanner
}

export interface BannersVariables extends ListVariables<BannerFilter> {}

export type PromotionBannerSortingInput = SortBy<PromotionBannersSortField>

export interface PromotionBannersVariables
  extends ListVariables<PromotionBannerFilter> {
  sortBy?: PromotionBannerSortingInput
}

export interface VendorBannersVariables extends ListVariables<BannerFilter> {}

export type BannerRemoveVariables = {
  id: string
}

export interface PromotionBannerRemoveVariables extends BannerRemoveVariables {}

export interface BannersData<T> extends PaginationList<T> {
  collection: Collection[]
  activeBannersCount?: number
}

export interface HomeBanners {
  allBanners: BannersData<Banner>
}

export interface PromotionBanners {
  promotionBanners: BannersData<PromotionBanner>
}

export interface HomeVendorBanners {
  vendorBanners: BannersData<VendorBanner>
}

export interface CreateBannerRequest {
  input: CreateBannerInput
}

export interface CreatePromotionBannerRequest {
  input: PromotionBannerCreateInput
}

export interface VendorBannerCreateRequest {
  input: VendorBannerCreateInput
}

export interface UpdateBannerRequest {
  id: string
  input: EditBannerInput
}

export interface UpdatePromotionBannerRequest {
  id: string
  input: EditPromotionBannerInput
}

export interface VendorBannerUpdateRequest {
  id: string
  input: EditVendorBannerInput
}

export interface ImageBannerCreateRequest {
  file: File
  id: string
}

export interface VendorBannerImageCreateRequest {
  input: VendorBannerImageCreateInput
}

export interface CreateBannerResults {
  bannerCreate: {
    bannerErrors: BannerError[]
    banner: Banner
  }
}

export interface CreatePromotionBannerResults {
  promotionBannerCreate: {
    bannerErrors: BannerError[]
    promotionBanner: PromotionBanner
  }
}

export interface VendorBannerCreateResults {
  vendorBannerCreate: {
    bannerErrors: BannerError[]
    vendorBanner: VendorBanner
  }
}

export interface UpdateBannerResults {
  bannerUpdate: {
    bannerErrors: BannerError[]
    banner: Banner
  }
}

export interface UpdatePromotionBannerResults {
  promotionBannerUpdate: {
    bannerErrors: BannerError[]
    promotionBanner: PromotionBanner
  }
}

export interface VendorBannerUpdateResults {
  vendorBannerUpdate: {
    bannerErrors: BannerError[]
    vendorBanner: VendorBanner
  }
}

export interface ImageBannerCreateResults {
  bannerImageCreate: {
    bannerErrors: BannerError[]
    banner: Banner
  }
}

export interface VendorBannerImageCreateResults {
  vendorBannerImageCreate: {
    bannerErrors: BannerError[]
    banner: VendorBanner
    image: VendorBannerImage
  }
}

export type GetBannerRemoveResults = {
  bannerDelete: {
    banner: Banner
    errors: BannerError[]
  }
}

export type GetPromotionBannerRemoveResults = {
  promotionBannerDelete: {
    bannerErrors: BannerError[]
    promotionBanner: PromotionBanner
  }
}

export type GetVendorBannerRemoveResults = {
  vendorBannerRemove: {
    bannerErrors: BannerError[]
    vendorBanner: VendorBanner
  }
}

export interface SecondaryMenu {
  data: SecondaryMenuShop | null
  loading: boolean
}

export type MainMenu = {
  data: MainMenuShop | null
  loading: boolean
  refetch: () => void
}

export type GetHomeBanners = {
  data: HomeBanners | null
  loading: boolean
  refetch?: (variables: Partial<BannersVariables>) => void
}

export type GetPromotionBanners = {
  data: PromotionBanners | null
  loading: boolean
  refetch?: (variables: Partial<PromotionBannersVariables>) => void
  fetchMore?: FetchMore<PromotionBanners, PromotionBannersVariables>
}

export type GetHomeVendorBanners = {
  data: HomeVendorBanners | null
  loading: boolean
  refetch?: (variables: Partial<VendorBannersVariables>) => void
}

export interface CreateBanner {
  onSubmit: (banner: CreateBannerInput) => void
  response: MutationResult<CreateBannerResults>
}

export interface CreatePromotionBanner {
  onSubmit: (promotionBanner: PromotionBannerCreateInput) => void
  response: MutationResult<CreatePromotionBannerResults>
}

export interface VendorBannerCreate {
  onSubmit: (vendorBanner: VendorBannerCreateInput) => void
  response: MutationResult<VendorBannerCreateResults>
}

export interface UpdateBanner {
  onSubmit: (id: string, banner: EditBannerInput) => void
  response: MutationResult<UpdateBannerResults>
}

export interface UpdatePromotionBanner {
  onSubmit: (id: string, promotionBanner: EditPromotionBannerInput) => void
  response: MutationResult<UpdatePromotionBannerResults>
}

export interface VendorBannerUpdate {
  onSubmit: (id: string, vendorBanner: EditVendorBannerInput) => void
  response: MutationResult<VendorBannerUpdateResults>
}

export type BannerRemove = {
  onSubmit: (variables: BannerRemoveVariables) => void
  response: MutationResult<GetBannerRemoveResults>
}

export type PromotionBannerRemove = {
  onSubmit: (variables: BannerRemoveVariables) => void
  response: MutationResult<GetPromotionBannerRemoveResults>
}

export type VendorBannerRemove = {
  onSubmit: (
    variables: BannerRemoveVariables,
    listVariables: Partial<VendorBannersVariables>
  ) => void
  response: MutationResult<GetVendorBannerRemoveResults>
}
export interface ImageBannerCreate {
  onSubmit: (id: string, file: File) => void
  response: MutationResult<ImageBannerCreateResults>
}

export interface VendorBannerImageCreate {
  onSubmit: (variables: VendorBannerImageCreateRequest) => void
  response: MutationResult<VendorBannerImageCreateResults>
}

export interface MenuApi {
  useSecondaryMenu: () => SecondaryMenu
  useMainMenu: () => MainMenu
  useBanners: (variables: BannersVariables) => GetHomeBanners
  useCreateBanner: () => CreateBanner
  useUpdateBanner: () => UpdateBanner
  useImageBannerCreate: () => ImageBannerCreate
  useRemoveBanner: () => BannerRemove
  useVendorBanners: (variables: VendorBannersVariables) => GetHomeVendorBanners
  useVendorBannerCreate: () => VendorBannerCreate
  useVendorBannerUpdate: () => VendorBannerUpdate
  useVendorBannerImageCreate: () => VendorBannerImageCreate
  useVendorBannerRemove: () => VendorBannerRemove
  usePromotionBanners: (
    variables: PromotionBannersVariables
  ) => GetPromotionBanners
  useCreatePromotionBanner: () => CreatePromotionBanner
  useUpdatePromotionBanner: () => UpdatePromotionBanner
  useRemovePromotionBanner: () => PromotionBannerRemove
}
