import React, { ChangeEvent, useState, FC, useRef, useMemo } from 'react'

import { Column, Icon, ICONS, Row, Text, TextField } from '../../../components'
import { useUser } from '../../../hooks'
import { RegisterCredentials } from '../../../services'

import avatar_sample from './avatar.png'
import { useStyle } from './business-info.styles'
import { BusinessInfoProps } from './business-info.types'

export const BusinessInfo: FC<BusinessInfoProps> = ({
  avatar,
  companyName,
  user,
  changeUser,
  onChangeImage,
  onDeleteImage
}) => {
  const { onResyncUser } = useUser()
  const classes = useStyle()
  const input = useRef<HTMLInputElement>(null)
  const [file, changeFile] = useState<File>()
  const [urls, changeUrls] = useState<string>(avatar)
  const [over, changeOver] = useState(false)
  const [error, changeError] = useState(false)

  const Image = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file)
    }
  }, [file])

  const handleOnMouseOver = () => {
    changeOver(!over)
  }

  const handleOnClick = () => {
    if (input.current) {
      input.current.click()
    }
  }

  const handleOnChangeImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (files) {
      changeFile(files[0])
      changeUrls('')
      onDeleteImage(false)

      if (onChangeImage) {
        onChangeImage(files[0])
      }

      if (onResyncUser) {
        onResyncUser()
      }
    }
  }

  const handleOnDelete = () => {
    if (input.current) {
      input.current.value = ''
    }
    if (file) {
      changeFile(undefined)
    } else {
      changeUrls('')
    }
    onDeleteImage(true)
  }

  const handleOnChange =
    (prop: keyof RegisterCredentials) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target

      const isValue = Boolean(value)
      changeError(!isValue)

      changeUser({
        ...user,
        [prop]: value
      })
    }

  return (
    <Column className={classes.infoContainer} alignItems="flex-start" fullWidth>
      <Row fullWidth justifyContent="flex-start" className={classes.infoTitle}>
        <Text color="black" preset="h3" text="Business Information" />
      </Row>
      <Row
        fullWidth
        alignItems="flex-start"
        justifyContent="space-between"
        className={classes.inputRow}
      >
        <Row fullWidth justifyContent="flex-start">
          <Row
            fullWidth
            className={classes.imgContainer}
            onMouseEnter={handleOnMouseOver}
          >
            {Image ? (
              <img src={Image} />
            ) : (
              <img src={urls ? `${urls}` : avatar_sample} alt="avatar" />
            )}
          </Row>

          <Row fullWidth>
            <TextField
              className={classes.inputBusiness}
              label="Business/Company name"
              labelTx="userInfo.companyName"
              preset="border"
              maxLength={30}
              onChange={handleOnChange('companyName')}
              defaultValue={companyName}
            />
            {error && (
              <Row
                fullWidth
                justifyContent="flex-start"
                className={classes.error}
              >
                <Text
                  text="The field can't be empty"
                  tx="general.info.error"
                  preset="secondTitle"
                  color="error"
                />
              </Row>
            )}
          </Row>
        </Row>
      </Row>
      <input
        accept=".png,.jpg,.jpeg,.webp,image/jpeg,image/png,image/webp"
        className={classes.inputHidden}
        type="file"
        ref={input}
        onChange={handleOnChangeImage}
      />
      {over && (
        <Row
          fullWidth
          className={classes.imgContainerHover}
          onMouseLeave={handleOnMouseOver}
        >
          <Row fullWidth justifyContent="space-evenly">
            <Icon
              className={classes.icon}
              src={ICONS.edit}
              onClick={handleOnClick}
            />
            <Icon
              className={classes.icon}
              src={ICONS.deleteIcon}
              onClick={handleOnDelete}
            />
          </Row>
        </Row>
      )}
    </Column>
  )
}
