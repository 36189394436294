import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  info: {},
  vendorInfo: {
    marginLeft: 136,
    marginTop: 16,
    '& > *:not(:first-child)': {
      marginTop: 16
    }
  },
  vendorStatuses: {
    '& > *': {
      whiteSpace: 'nowrap'
    },
    '& > *:not(:first-child)': {
      marginLeft: 8
    }
  }
})
