import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderRadius: 32,
    backgroundColor: theme.colors.white,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    padding: 4
  },
  slider: {
    borderBottom: `1px solid ${theme.colors.shadow}`
  }
}))
