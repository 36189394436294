import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  info: {
    marginLeft: 16
  },
  description: {
    marginTop: 8
  },
  companyName: {
    marginLeft: 4
  }
})
