import React, { FC, useEffect, useState } from 'react'
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js'

import { Currency } from '../../services'

import {
  CreateOrderActions,
  CreateOrderBody,
  Layout,
  OnApproveActions,
  OnApproveData,
  PaypalButtonsStyle,
  PaypalButtonsViewProps,
  ShippingPreference,
  UserAction
} from './paypal-buttons.types'
import { useStyle } from './paypal-buttons.styles'
import { Loader } from '../loader'
import { Content } from '../content'
import { Row } from '../row'
import { Text } from '../text'
import { useApi, useToastify } from '../../providers'
import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { useUser } from '../../hooks'

const style: PaypalButtonsStyle = { layout: Layout.VERTICAL }

export const PaypalButtonsView: FC<PaypalButtonsViewProps> = ({
  orderId = '1',
  amount,
  currency = Currency.USD
}) => {
  const { changeAmount } = useUser()
  const { open } = useToastify()
  const { payment } = useApi()
  const { onSubmit } = payment.useCreatePaypalOrder()

  const [{ options, isPending }, dispatch] = usePayPalScriptReducer()
  const classes = useStyle()
  const [isError, changeIsError] = useState(false)
  const [success, changeSuccess] = useState(false)
  const [loading, changeLoading] = useState(false)

  const toastifyOptions = {
    values: {
      orderNumber: orderId
    }
  }

  const order: CreateOrderBody = {
    purchase_units: [
      {
        amount: {
          currency_code: currency,
          value: amount
        },
        custom_id: orderId
      }
    ],
    application_context: {
      brand_name: 'Ovaflopick',
      shipping_preference: ShippingPreference.NO_SHIPPING,
      user_action: UserAction.CONTINUE
    }
  }

  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        currency
      }
    })
  }, [currency])

  const handleOnCreateOrder = (
    _: Record<string, unknown>,
    actions: CreateOrderActions
  ) => {
    return actions.order.create(order).then((nextPaypalOrderId) => {
      return nextPaypalOrderId
    })
  }

  const handleOnApprove = (data: OnApproveData, actions: OnApproveActions) => {
    if (actions.order) {
      return actions.order.capture().then(() => {
        open({
          text: 'Your payment is complete. Your balance has been topped up.',
          tx: 'add.funds.payment.success',
          ...toastifyOptions
        })
        const amountCost = Number.isNaN(-amount) ? 0 : Number(amount)

        onSubmit({ paypalOrderId: data.orderID, orderId })
        changeSuccess(true)
        changeLoading(true)

        if (changeAmount) {
          changeAmount(amountCost)
        }
      })
    }

    return new Promise(() => {})
  }

  const handleOnError = (error: Record<string, unknown>) => {
    if (error) {
      changeIsError(true)
      open({
        text: 'Sorry, we weren’t able to complete your payment at this time. Please try again later.',
        tx: 'add.funds.payment.error',
        ...toastifyOptions
      })
    }
    // eslint-disable-next-line no-console
    console.error(error)
  }

  if (success) {
    return (
      <Column className={classes.container}>
        <Column alignItems="center">
          <Row alignItems="center">
            <Icon src={ICONS.paymentSuccess} />
            <Text
              className={classes.bottomText}
              text="Your payment is complete. Your balance has been topped up."
              tx="add.funds.payment.success"
              preset="h6"
            />
          </Row>
        </Column>
      </Column>
    )
  }

  if (isError) {
    return (
      <Column className={classes.container}>
        <Row className={classes.error}>
          <Icon src={ICONS.paymentError} />
          <Text
            className={classes.errorText}
            text="Sorry, we weren’t able to complete your payment at this time. Please try again
          later."
            tx="add.funds.payment.error"
            preset="h6"
          />
        </Row>
      </Column>
    )
  }

  if (loading) {
    return <Loader />
  }

  return (
    <Content className={classes.container}>
      {isPending && <Loader />}
      <Row fullWidth>
        <PayPalButtons
          className={classes.paypal}
          style={style}
          disabled={false}
          forceReRender={[amount, currency, style]}
          createOrder={handleOnCreateOrder}
          // @ts-ignore
          onApprove={handleOnApprove}
          onError={handleOnError}
        />
      </Row>
    </Content>
  )
}
