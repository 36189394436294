import React, { FC } from 'react'

import { Row, Text, Color } from '../../../components'

import { FeedbackMonitoringNavItemProps } from './feedback-monitoring-nav-item.types'
import { useStyle } from './feedback-monitoring-nav-item.styles'

export const FeedbackMonitoringNavItem: FC<FeedbackMonitoringNavItemProps> = ({
  active,
  text,
  tx,
  preset,
  value,
  onClick
}) => {
  const activeTextColor: Color = active ? 'white' : 'darkBlue'
  const classes = useStyle({ active })

  return (
    <Row
      className={classes.container}
      justifyContent="center"
      onClick={onClick}
    >
      <Text
        text={text}
        tx={tx}
        preset={preset || 'h4'}
        color={activeTextColor}
        values={value}
      />
    </Row>
  )
}
