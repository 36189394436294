import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Column, Row } from '../../components'
import { useTable } from '../../hooks'
import { useApi } from '../../providers'
import { BannerFilter, VendorBanner } from '../../services'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import {
  VendorBannerTable,
  VendorBannerTableProps
} from './vendor-banner-table'
import { useStyle } from './vendor-banner.styles'

const rowCounts = [5, 10, 20, 30]

export const VendorBannerPage = () => {
  const classes = useStyle()
  const history = useHistory()

  const defaultVariables = {
    first: rowCounts[1]
  }

  const { menu } = useApi()
  const { data, refetch, loading } = menu.useVendorBanners(defaultVariables)
  const { onSubmit: onRemove } = menu.useVendorBannerRemove()
  const { onSubmit: onUpdate } = menu.useVendorBannerUpdate()

  const {
    variables,
    changeVariables,
    Data,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch
  } = useTable({
    refetch,
    data: data?.vendorBanners,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  const handleOnCreate = () => {
    history.push(CabinetNavigatorPathVendor.CREATE_VEDDOR_BANNER)
  }

  const handleOnFilter = (filter: Partial<BannerFilter>) => {
    const nextVariables = { ...variables, filter }
    changeVariables(nextVariables)
    if (refetch) {
      refetch(nextVariables)
    }
  }

  const handleOnRemove = (ids: string[]) => {
    ids.forEach((id) => onRemove({ id }, variables))
  }

  const handleOnUpdate = (publishData: VendorBanner[]) => {
    publishData.forEach((item) => {
      const newState = {
        name: item.name,
        title: item.title,
        subTitle: item.subTitle,
        updatedAt: item.updatedAt,
        isVisible: item.isVisible,
        buttonText: item.buttonText,
        buttonLink: item.buttonLink
      }
      onUpdate(item.id, newState)
    })
  }

  const handleOnGoToTheMarketplace = () => {
    document.location = 'https://marketplace-dev.consultinar.com/'
  }

  const handleOnSearch: VendorBannerTableProps['onSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="flex-end"
      >
        <Button
          className={`${classes.button} ${classes.leftButton}`}
          preset="secondaryBorder"
          text="CREATE BANNER"
          tx="cabinet.banners.create.button"
          textPreset="h5"
          textColor="yellow"
          onClick={handleOnCreate}
        />
        <Button
          className={`${classes.button} ${classes.rightButton}`}
          preset="primary"
          text="GO TO THE MARKETPLACE"
          tx="cabinet.banners.go.to.marketplace"
          textPreset="h5"
          textColor="white"
          onClick={handleOnGoToTheMarketplace}
        />
      </Row>
      <VendorBannerTable
        loading={loading}
        paginationState={paginationState}
        rowCounts={rowCounts}
        className={classes.table}
        data={Data}
        filters={variables.filter}
        onSearch={handleOnSearch}
        onRemove={handleOnRemove}
        onUpdate={handleOnUpdate}
        onFilter={handleOnFilter}
        onChangePage={onChangePage}
        onPrevioslyPage={onPrevioslyPage}
        onNextPage={onNextPage}
        onChangeRowCount={onChangeRowCount}
      />
    </Column>
  )
}
