import React, { ChangeEvent, useState, useEffect } from 'react'
import { useTheme } from 'react-jss'
import { useLocation, useHistory } from 'react-router-dom'

import {
  Button,
  Column,
  getInstruction,
  getListFromList,
  InfoTooltip,
  LoadingOverflow,
  Row,
  Text,
  TextField,
  TextFieldErrorProps
} from '../../components'
import { useUser } from '../../hooks/useUser'
import { AuthRoutes } from '../../auth/urls'
import { LightTheme } from '../../components/Theme'
import { AccountInput, RegisterCredentials } from '../../services'
import background from '../../assets/images/background-pattern.png'
import { useApi } from '../../providers'

import { ConfirmationModal } from '../views/confirmation'
import { validateEmail } from '../helpers'

import { useStyle } from './contact-register-page.styles'
import { ContactRegisterPageState } from './contact-register-page.types'

export const ContactRegisterPage = () => {
  const defaultUserState: AccountInput = {
    contactsPersonName: '',
    contactsPersonPosition: '',
    paypalEmail: ''
  }
  const { instruction: instructionApi } = useApi()

  const location = useLocation<ContactRegisterPageState>()
  const history = useHistory()
  const userContext = useUser()
  const { data: instructions } = instructionApi.useInstructions({ first: 100 })

  const [user, changeUser] = useState<AccountInput>(defaultUserState)
  const [loading, changeLoading] = useState(false)
  const [openConfirm, changeOpenConfirm] = useState(false)

  const instructionsList = getListFromList(instructions?.instructions)

  const payPalEmailInstruction = getInstruction(instructionsList, 'PayPalEmail')

  useEffect(() => {
    if (
      !location.state ||
      (!location.state.user && !location.state.billingAddress)
    ) {
      history.push(AuthRoutes.REGISTER)
    }
  }, [location.state])

  const error = userContext.user?.defaultBillingAddress === null

  const { contactsPersonName, contactsPersonPosition, paypalEmail } = user

  const ValidateEmail = () => {
    if (user.paypalEmail) {
      const isValideEmail = validateEmail(user.paypalEmail)

      return isValideEmail
    }
    return false
  }

  const isDisabled =
    !contactsPersonName ||
    !contactsPersonPosition ||
    !paypalEmail ||
    !ValidateEmail()

  const emailError: TextFieldErrorProps =
    !ValidateEmail() && user.paypalEmail
      ? {
          error: 'Please enter a valid e-mail address.',
          errorTx: 'error.validation.email.register.page'
        }
      : {}

  const theme: LightTheme = useTheme()
  const classes = useStyle({ background, error, theme })

  const handleOnChange =
    (prop: keyof AccountInput) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeUser({
        ...user,
        [prop]: value
      })
    }

  const handleOnOpenConfirm = (state: boolean) => () => {
    changeOpenConfirm(state)
  }

  const handleOnClick = () => {
    const registerData: RegisterCredentials = {
      ...location.state.user,
      ...user
    }

    if (userContext.register) {
      userContext.register(registerData, location.state.billingAddress)
      changeLoading(true)
    }
  }

  return (
    <Column fullWidth className={classes.container}>
      <LoadingOverflow loading={loading} />
      <Column className={classes.loginForm}>
        <Text
          preset="h1"
          text="Tell us a little about you."
          tx="userInfo.title"
        />
        <Text
          color="inactive"
          preset="h7"
          text="Seller Information. Please fill in all the fields to continue the registration."
          tx="userInfo.description"
        />

        <TextField
          className={classes.input}
          label="Contact’s Person Name"
          labelTx="contactRegister.personName"
          preset="border"
          maxLength={30}
          onChange={handleOnChange('contactsPersonName')}
        />
        <TextField
          className={classes.input}
          label="Contact’s Person Position"
          labelTx="contactRegister.personPosition"
          preset="border"
          maxLength={30}
          onChange={handleOnChange('contactsPersonPosition')}
        />
        <Row fullWidth className={classes.input}>
          <TextField
            {...emailError}
            label="PayPal Email Address"
            labelTx="contactRegister.paypal"
            preset="border"
            maxLength={100}
            onChange={handleOnChange('paypalEmail')}
          />
          <Row className={classes.tooltip}>
            <InfoTooltip text={payPalEmailInstruction} />
          </Row>
        </Row>

        <Button
          className={classes.submit}
          disabled={isDisabled}
          text="SAVE"
          tx="contactRegister.save"
          preset="primary"
          textColor="white"
          textPreset="h5"
          onClick={handleOnOpenConfirm(true)}
        />
      </Column>
      <ConfirmationModal
        text="Please check your email and confirm your registration"
        tx="sign.up.confirmation.title"
        buttonText="OK"
        buttonTx="sign.up.confirmation.button.text"
        open={openConfirm}
        onClose={handleOnOpenConfirm(false)}
        onClick={handleOnClick}
      />
    </Column>
  )
}
