import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    height: 56,
    marginLeft: 24,
    width: 204
  },
  success: {
    width: 168,
    height: 40,
    marginLeft: 24
  },
  field: {
    marginTop: 16
  },
  dropdawnField: {
    marginTop: 32
  },
  bottom: {
    padding: '24px 0px'
  },
  dropdown: {
    overflowY: 'hidden !important',
    border: `1px solid ${theme.colors.inactive}`,
    borderRadius: '4px !important',
    '& > div': {
      minWidth: '100%'
    }
  },
  dropdownText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre',
    maxWidth: 456
  },
  error: {
    position: 'absolute',
    padding: '4px 8px',
    top: 112,
    width: 'calc(100% - 32px)',
    borderRadius: 4
  },
  errorIcon: {},
  errorText: {
    marginLeft: 16
  }
}))
