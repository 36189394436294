import React, { FC, MouseEvent } from 'react'
import { useTheme } from 'react-jss'
import moment from 'moment'

import { useStyle } from './vendor-banner-table-item.styles'
import { VendorBannerTableItemProps } from './vendor-banner-table-item.types'
import { LightTheme } from '../../../components/Theme'
import { Checkbox, Row, Text, ColorDot, TextProps } from '../../../components'
import { getDotColor } from './helpers'

export const VendorBannerTableItem: FC<VendorBannerTableItemProps> = ({
  id,
  images,
  name,
  createdAt,
  updatedAt,
  checked,
  isVisible,
  onClick,
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const isActive: TextProps = {
    text: 'Active',
    tx: 'banner.active'
  }
  const isNotActive: TextProps = {
    text: 'Inactive',
    tx: 'banner.inactive'
  }
  const colorVisible = getDotColor(isVisible)
  const visible = isVisible ? isActive : isNotActive

  const formatedCreatedtDate = moment(new Date(createdAt)).format('DD.MM.YYYY')
  const formatedUpdatedDate = moment(new Date(updatedAt)).format('DD.MM.YYYY')

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  const handleOnChange =
    (nextId: string) =>
    (state: boolean, event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      if (onChange) {
        onChange(nextId, state)
      }
    }

  const src =
    images && images.length && typeof images[0].url === 'string'
      ? images[0].url
      : ''

  return (
    <Row
      fullWidth
      key={`banner_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row
        className={`${classes.tableColumn} ${classes.minTableColumn}`}
        justifyContent="flex-start"
      >
        <Checkbox checked={checked} onChange={handleOnChange(id)} />
        <img className={classes.image} height={48} width={48} src={src} />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.maxTableColumn}`}
        justifyContent="flex-start"
      >
        {name && <Text color="black" preset="body" text={name} />}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <ColorDot color={theme.colors[colorVisible]} />
        <Text
          className={classes.statusText}
          color="black"
          preset="body"
          {...visible}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {createdAt && (
          <Text color="black" preset="body" text={formatedCreatedtDate} />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {updatedAt && (
          <Text color="black" preset="body" text={formatedUpdatedDate} />
        )}
      </Row>
    </Row>
  )
}
