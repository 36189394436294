import React, { FC } from 'react'

import {
  ColorDot,
  getPriceToFixed,
  Row,
  Slider,
  Text
} from '../../../components'

import { concatClassNames } from '../../views'

import { useStyle } from '../offer-form.styles'

import { SummaryRowProps } from './summary-row.types'

export const SummaryRow: FC<SummaryRowProps> = ({
  className = '',
  title,
  titleTx,
  price,
  dotColor,
  large = false,
  isDiscount,
  onChangeDiscount,
  currency
}) => {
  const classes = useStyle()
  const titlePreset = large ? 'h5' : 'body'
  const pricePreset = large ? 'h4' : 'h5'
  const color = large ? 'black' : 'inactive'
  const itemPrice = currency
    ? `${currency}${getPriceToFixed(price)}`
    : String(price)

  return (
    <Row
      className={concatClassNames(className, classes.field)}
      fullWidth
      justifyContent="space-between"
    >
      <Row>
        {dotColor && (
          <ColorDot
            className={classes.dot}
            preset="productColor"
            color={dotColor}
          />
        )}

        <Text preset={titlePreset} text={title} color={color} tx={titleTx} />
      </Row>
      {onChangeDiscount ? (
        <Slider defaultValue={isDiscount} onChange={onChangeDiscount} />
      ) : (
        <Text preset={pricePreset} text={itemPrice} />
      )}
    </Row>
  )
}
