import React, { FC, useRef, useState } from 'react'

import {
  Column,
  Row,
  Icon,
  ICONS,
  arrayHasElements
} from '../../../../components'

import { ProductPreviewCarouselProps } from './product-preview-carousel.types'
import { useStyle } from './product-preview-carousel.styles'

export const ProductPreviewCarousel: FC<ProductPreviewCarouselProps> = ({
  className = '',
  data
}) => {
  const scrollContainer = useRef<HTMLDivElement>(null)
  const classes = useStyle()
  const [images, changeImages] = useState(data)

  const mainImage = images[0]

  const handleOnClickImage = (index: number) => () => {
    if (!arrayHasElements(images)) {
      return
    }
    let nextValue = images
    const slicedCount = images.length - index

    const removed = nextValue.splice(index, slicedCount)
    nextValue = [...removed, ...nextValue]

    changeImages(nextValue)
  }

  const handleOnClickRight = () => {
    if (!arrayHasElements(images)) {
      return
    }
    let nextValue = images
    const removed = nextValue.splice(0, 1)
    nextValue = [...nextValue, ...removed]

    changeImages(nextValue)
  }

  const handleOnClickLeft = () => {
    if (!arrayHasElements(images)) {
      return
    }
    let nextValue = images

    const removed = nextValue.splice(images.length - 1, 1)
    nextValue = [...removed, ...nextValue]

    changeImages(nextValue)
  }

  return (
    <Row fullWidth className={className}>
      <Column fullWidth className={classes.imgColumn} alignItems="flex-start">
        <Row fullWidth justifyContent="space-between">
          <Row
            className={`${classes.top} ${classes.arrowContainer}`}
            onClick={handleOnClickLeft}
          >
            <Icon src={ICONS.arrowLeft} />
          </Row>
          <Row>
            <img
              alt={mainImage?.alt}
              className={classes.currentImage}
              src={mainImage?.url}
              width={480}
              height={416}
            />
          </Row>
          <Row
            className={`${classes.bottom} ${classes.arrowContainer}`}
            onClick={handleOnClickRight}
          >
            <Icon src={ICONS.arrowRight} />
          </Row>
        </Row>
        <Row
          className={classes.carouselContainer}
          justifyContent="flex-start"
          ref={scrollContainer}
        >
          {images.map(({ url, alt }, idx) => {
            const isSelected = Number(idx) === 0
            const selectedClass = isSelected ? `${classes.selected}` : ''

            return (
              <img
                key={`image_${url}`}
                alt={alt}
                className={`${classes.image} ${selectedClass}`}
                src={url}
                width={192}
                height={180}
                onClick={handleOnClickImage(idx)}
              />
            )
          })}
        </Row>
      </Column>
    </Row>
  )
}
