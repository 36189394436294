import { defineMessages, IntlShape } from 'react-intl'

export const commonMessages = defineMessages({
  availability: {
    defaultMessage: 'Availability',
    id: 'hOxIeP'
  },
  catalog: {
    defaultMessage: 'Catalog',
    id: 'GOdq5V'
  },
  customApps: {
    defaultMessage: 'Local Apps',
    id: 'w4R/SO'
  },
  dashboard: {
    defaultMessage: 'Dashboard',
    id: 'hzSNj4'
  },
  demo: {
    defaultMessage:
      "Just to let you know... You're in demo mode. You can play around with the dashboard but can't save changes.",
    id: 'i0AcKY',
    description: 'notification message after log in'
  },
  description: {
    defaultMessage: 'Description',
    id: 'Q8Qw5B'
  },
  discounts: {
    defaultMessage: 'Discounts',
    id: 'n+Gwbu'
  },
  drafts: {
    defaultMessage: 'Drafts',
    id: '2atspc'
  },
  email: {
    defaultMessage: 'E-mail Address',
    id: '2YaS7K'
  },
  endDate: {
    defaultMessage: 'End Date',
    id: 'T4GOiX'
  },
  endHour: {
    defaultMessage: 'End Hour',
    id: 'juBV+h'
  },
  firstName: {
    defaultMessage: 'First Name',
    id: 'Q6wcZ5'
  },
  generalInformations: {
    defaultMessage: 'General Information',
    id: 'pkUbrL'
  },
  lastName: {
    defaultMessage: 'Last Name',
    id: 'aheQdn'
  },
  no: {
    defaultMessage: 'No',
    id: 'oUWADl'
  },
  optionalField: {
    defaultMessage: 'Optional',
    id: 'lzdvwp',
    description: 'field is optional'
  },
  properties: {
    defaultMessage: 'Properties',
    id: 'aI80kg'
  },
  readOnly: {
    defaultMessage: 'Saleor runs in read-only mode. Changes not saved.',
    id: 'kFYlu2'
  },
  requiredField: {
    defaultMessage: 'This field is required',
    id: 'TKmub+'
  },
  savedChanges: {
    defaultMessage: 'Saved changes',
    id: 'rqiCWU'
  },
  sessionExpired: {
    defaultMessage:
      'Your session has expired. Please log in again to continue.',
    id: 'Fvvgoi'
  },
  somethingWentWrong: {
    defaultMessage: 'Saleor ran into an unexpected problem',
    id: 'LVa5ew'
  },
  startDate: {
    defaultMessage: 'Start Date',
    id: 'QirE3M'
  },
  startHour: {
    defaultMessage: 'Start Hour',
    id: 'tWbE34'
  },
  status: {
    defaultMessage: 'Status',
    id: 'tzMNF3'
  },
  summary: {
    defaultMessage: 'Summary',
    id: 'RrCui3'
  },
  uploadImage: {
    defaultMessage: 'Upload image',
    id: 'Lx1ima',
    description: 'button'
  },
  yes: {
    defaultMessage: 'Yes',
    id: 'a5msuh'
  }
})

export const buttonMessages = defineMessages({
  accept: {
    defaultMessage: 'Accept',
    id: 'skPoVe',
    description: 'button'
  },
  back: {
    defaultMessage: 'Back',
    id: '0OfZJA',
    description: 'button'
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: '9uNz+T',
    description: 'button'
  },
  clear: {
    defaultMessage: 'Clear',
    id: '2FQsYj',
    description: 'button'
  },
  confirm: {
    defaultMessage: 'Confirm',
    id: 'DJFPzq',
    description: 'button'
  },
  create: {
    defaultMessage: 'Create',
    id: 'H5NKfr',
    description: 'button'
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'ufmuTp',
    description: 'button'
  },
  done: {
    defaultMessage: 'Done',
    id: 'eOrLzG',
    description: 'button'
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'Ja7gHc',
    description: 'button'
  },
  manage: {
    defaultMessage: 'Manage',
    id: 'IbVKSH',
    description: 'button'
  },
  nextStep: {
    defaultMessage: 'Next',
    id: 'wlQTfb',
    description: 'go to next step, button'
  },
  ok: {
    defaultMessage: 'OK',
    id: 's9sOcC',
    description: 'button'
  },
  remove: {
    defaultMessage: 'Remove',
    id: 'bu/fC1',
    description: 'button'
  },
  save: {
    defaultMessage: 'Save',
    id: 'RaycYK',
    description: 'button'
  },
  selectAll: {
    defaultMessage: 'Select All',
    id: 'rfvBAF',
    description: 'select all options, button'
  },
  send: {
    defaultMessage: 'Send',
    id: 'hqVMLQ',
    description: 'button'
  },
  show: {
    defaultMessage: 'Show',
    id: '/8/Ffn',
    description: 'button'
  },
  undo: {
    defaultMessage: 'Undo',
    id: 'vN3qdA',
    description: 'button'
  }
})

export const sectionNames = defineMessages({
  apps: {
    defaultMessage: 'Apps',
    id: '9q562c',
    description: 'apps section name'
  },
  attributes: {
    defaultMessage: 'Attributes',
    id: 'l2oVCF',
    description: 'attributes section name'
  },
  banners: {
    defaultMessage: 'Banners',
    id: 'jej/wM',
    description: 'banners section name'
  },
  categories: {
    defaultMessage: 'Categories',
    id: 'sK1FPw',
    description: 'categories section name'
  },
  collections: {
    defaultMessage: 'Collections',
    id: 'NNT3Lp',
    description: 'collections section name'
  },
  configuration: {
    defaultMessage: 'Configuration',
    id: 'xfGZsi',
    description: 'configuration section name'
  },
  customers: {
    defaultMessage: 'Customers',
    id: 'McN+wq',
    description: 'customers section name'
  },
  draftOrders: {
    defaultMessage: 'Draft Orders',
    id: 'YMBn8d',
    description: 'draft orders section name'
  },
  home: {
    defaultMessage: 'Home',
    id: '4JW9iJ',
    description: 'home section name'
  },
  navigation: {
    defaultMessage: 'Navigation',
    id: '9C7PZE',
    description: 'navigation section name'
  },
  orders: {
    defaultMessage: 'Orders',
    id: 'Ta9j04',
    description: 'orders section name'
  },
  pages: {
    defaultMessage: 'Pages',
    id: 'H6NsC1',
    description: 'pages section name'
  },
  permissionGroups: {
    defaultMessage: 'Permission Groups',
    id: 'DNTxWr',
    description: 'permission groups section name'
  },
  plugins: {
    defaultMessage: 'Plugins',
    id: 'WhvuCb',
    description: 'plugins section name'
  },
  productTypes: {
    defaultMessage: 'Product Types',
    id: 'YQ3EXR',
    description: 'product types section name'
  },
  products: {
    defaultMessage: 'Products',
    id: 'K8xNLe',
    description: 'products section name'
  },
  sales: {
    defaultMessage: 'Sales',
    id: 'kJQczl',
    description: 'sales section name'
  },
  serviceAccounts: {
    defaultMessage: 'Service Accounts',
    id: '8xsKUv',
    description: 'service accounts section name'
  },
  shipping: {
    defaultMessage: 'Shipping Methods',
    id: 'D9ie4n',
    description: 'shipping section name'
  },
  siteSettings: {
    defaultMessage: 'Site Settings',
    id: 'viFkCw',
    description: 'site settings section name'
  },
  staff: {
    defaultMessage: 'Staff Members',
    id: 'AQFMYU',
    description: 'staff section name'
  },
  taxes: {
    defaultMessage: 'Taxes',
    id: '5elC9k',
    description: 'taxes section name'
  },
  translations: {
    defaultMessage: 'Translations',
    id: '5fCMUI',
    description: 'translations section name'
  },
  vouchers: {
    defaultMessage: 'Vouchers',
    id: 'iUy2dx',
    description: 'vouchers section name'
  },
  warehouses: {
    defaultMessage: 'Warehouses',
    id: 'ycMLN9',
    description: 'warehouses section name'
  },
  webhooks: {
    defaultMessage: 'Webhooks',
    id: '6nSTuC',
    description: 'webhooks section name'
  }
})

export function translateBoolean(value: boolean, intl: IntlShape): string {
  return value
    ? intl.formatMessage(commonMessages.yes)
    : intl.formatMessage(commonMessages.no)
}
