import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    marginLeft: 8
  },
  sectionAdditionWithText: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '24px 16px'
  }
}))
