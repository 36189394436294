import { gql } from '@apollo/client'

import { MATERIAL_FRAGMENT } from '../fragments'

export const MATERIALS_QUERY = gql`
  ${MATERIAL_FRAGMENT}
  query Materials($before: String, $after: String, $first: Int, $last: Int) {
    materials(first: $first, after: $after, last: $last, before: $before) {
      edges {
        node {
          ...MaterialFragment
        }
        cursor
      }
      totalCount
    }
  }
`
