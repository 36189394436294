import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  image: {
    marginLeft: 16,
    objectFit: 'cover'
  },
  tableItem: {
    padding: '8px 16px'
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px',
    overflow: 'hidden'
  },
  tableColumnFirst: {
    flex: 2
  },
  tableColumnLast: {
    flex: 0.8
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.lightBlue
    }
  },
  statusText: {
    marginLeft: 8
  },
  iconRow: {
    width: 102
  },
  hiddenText: {
    maxWidth: 250,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  '@media screen and (max-width: 1440px)': {
    hiddenText: {
      maxWidth: 144
    }
  }
}))
