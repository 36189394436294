import React, { FC } from 'react'

import { RadioBox, Section } from '../../../components'
import { ProductStatus } from '../../../services'

import { useStyle } from './visability-section.styles'
import { VisibilityProps } from './visibility-section.types'

export const VisibilitySection: FC<VisibilityProps> = ({
  id,
  value,
  visibilityData,
  instruction,
  onChangeValue
}) => {
  const classes = useStyle()

  const handleOnChangeVisibility = (nextValue: string) => {
    const visibleInListings = Boolean(Number(nextValue))
    const productStatus = visibleInListings
      ? ProductStatus.VISIBLE
      : ProductStatus.INVISIBLE

    if (onChangeValue) {
      onChangeValue({
        ...value,
        visibleInListings,
        status: productStatus
      })
    }
  }

  return (
    <Section
      isInfo
      isBottom
      className={classes.section}
      infoText={instruction}
      title="Visibility"
      titleTx="item.form.visibility.title"
    >
      <RadioBox
        active={id}
        className={classes.sectionAdditionContainer}
        data={visibilityData}
        onChange={handleOnChangeVisibility}
      />
    </Section>
  )
}
