import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  avatar: {
    marginLeft: 16,
    minWidth: 48
  },
  bottomRow: {
    padding: 16,
    width: 'calc(100% - 32px)'
  },
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`
  },
  dropdownRow: {
    marginLeft: 8
  },
  header: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    background: theme.colors.greyFon,
    height: 48
  },
  icon: {
    marginLeft: 8
  },
  search: {
    width: '100%'
  },
  filter: {
    marginLeft: 16,
    height: 56,
    width: 360
  },
  tableTopContainer: {
    padding: 16
  },
  tableColumn: {
    height: '100%',
    padding: '8px 16px'
  },
  secondColumn: {
    flex: 3,
    padding: '8px 20px 8px 66px',
    height: '100%'
  },
  thirdColumn: {
    flex: 1,
    padding: 8,
    height: '100%'
  },
  row: {
    flex: 2.6
  },
  remove: {
    cursor: 'pointer',
    padding: '8px 16px'
  },
  buttonContainer: {
    padding: '4px 0'
  },
  button: {
    marginLeft: 8
  },
  textCheckBox: {
    marginLeft: 24
  }
}))
