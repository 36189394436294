export enum OptionsNavigatorPathes {
  SUBSCRIPTION = '/subscription'
}

export enum CabinetNavigatorPathAdmin {
  YOUR_INFO = '/',
  USER_LIST = '/cabinet/user-list',
  BANNERS = '/cabinet/banners',
  OFFER_CHAT = '/cabinet/admin/customer/offer-chat',
  ORDER_CHAT = '/cabinet/admin/customer/order-chat',
  CREATE_BANNERS = '/cabinet/banners/create',
  EDIT_BANNERS = '/cabinet/banners/edit',
  NEWS = '/cabinet/news',
  PROMOTIONS = '/cabinet/promotions',
  VENDORS = '/cabinet/vendors',
  COMMUNICATION = '/cabinet/communication',
  ORDER_CONTROL = '/cabinet/admin/order-controls',
  STATISTICS = '/cabinet/statistics',
  PAYOUTS = '/cabinet/payouts'
}

export enum CabinetNavigatorPathVendor {
  YOUR_INFO = '/',
  OFFER = '/offer',
  ITEMS = '/cabinet/items',
  OFFERS = '/cabinet/offers',
  OFFER_CREATE = '/cabinet/offer/create',
  OFFER_EDIT = '/cabinet/offer/edit',
  OFFER_CHAT = '/cabinet/offer-chat',
  ORDER_CHAT = '/cabinet/order-chat',
  PROFILE_SETTINGS = '/cabinet/profile-settings',
  PROFILE_SETTINGS_GENERAL = '/cabinet/profile-settings/general',
  PROFILE_SETTINGS_SUBSCRIPTION = '/cabinet/profile-settings/subscription',
  ITEMS_CREATE = '/cabinet/product/create',
  ITEMS_EDIT = '/cabinet/product/edit',
  ORDER_CONTROL = '/cabinet/vendor/order-control',
  ORDERS_ACTIVITY = '/cabinet/orders-activity',
  COLLECTIONS = '/items-collections',
  CREATE_COLLECTIONS = '/cabinet/collection/create-collections',
  EDIT_COLLECTIONS = '/cabinet/collection/edit-collections',
  ACTIVITY = '/cabinet/activity',
  SELLER_PAGE_DESIGN = '/cabinet/seller-page-design',
  CREATE_VEDDOR_BANNER = '/cabinet/vendor-banners/create',
  EDIT_VEDDOR_BANNER = '/cabinet/vendor-banners/edit',
  BILLINGS = '/cabinet/billings',
  FEEDBACK_MONITORING = '/cabinet/feedback-monitoring',
  PROMOTIONS = '/cabinet/promotions',
  CREATE_PROMOTION_BANNER = '/cabinet/promotion/promotion-banner/create',
  EDIT_PROMOTION_BANNER = '/cabinet/promotion/promotion-banner/edit',
  CREATE_ADVRTISING = '/cabinet/promotion/advertising/create',
  EDIT_ADVRTISING = '/cabinet/promotion/advertising/edit'
}

export enum PromotionsTabNavigation {
  PROMOTIONS = '/promotion-banners',
  ADVERTISEMENTS = '/advertisements'
}

export enum ItemsTabNavigation {
  PRODUCTS = '/product',
  COLLECTIONS = '/collection'
}

export enum ActivityTabNavigation {
  ACTIVITY_ORDERS = '/orders',
  ACTIVITY_FEEDBACK = '/feedback',
  ACTIVITY_MESSAGES = '/messages'
}

export enum SalerPageDesignNavigation {
  SALER_PAGE_BANNER = '/vendor-banners',
  SALER_PAGE_TOP_PRODUCT = '/top-product'
}

export enum FeedbackMonitoringPageNavigation {
  FEEDBACK_TO_VENDOR = '/feedback-to-vendor',
  FEEDBACK_TO_PRODUCT = '/feedback-to-product'
}

export enum UserListTabNavigation {
  VENDORS = '/vendors',
  CUSTOMERS = '/customers'
}
