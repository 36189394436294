import { createSvgIcon } from '@material-ui/core'
import React from 'react'

export const ArrowDropdown = createSvgIcon(
  <g style={{ fillRule: 'evenodd' }}>
    <path d="M7 10l5 5 5-5z" />
  </g>,
  'ArrowDropdown'
)

export default ArrowDropdown
