import React, { ChangeEvent, FC, useState } from 'react'

import {
  Button,
  ColorPicker,
  Icon,
  ICONS,
  QuestionModal,
  Text,
  TextArea,
  TextField
} from '../../../components'
import { Color, ProductCreateColorInput } from '../../../services'

import { useStyle } from './color-item-modal.styles'
import { ColorItemModalProps } from './color-item-modal.types'

export const ColorItemModal: FC<ColorItemModalProps> = ({
  colors = [],
  descriptionPlaceholder,
  onSumbit
}) => {
  const classes = useStyle()
  const [open, changeClose] = useState(false)
  const [color, changeColor] = useState<Partial<ProductCreateColorInput>>()

  const isDisabled =
    !color?.color ||
    !color.description ||
    !color.title ||
    !color.extraPrice ||
    color.extraPrice < 0 ||
    !color.extraProductionDays

  const handleOnChange =
    (prop: keyof ProductCreateColorInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target

      changeColor({
        ...color,
        [prop]: value
      })
    }

  const handleOnOpen = (state: boolean) => () => {
    changeClose(state)
  }

  const handleOnChangeColor = (item: Color) => {
    changeColor({
      ...color,
      color: item.id
    })
  }

  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    onSumbit(color as ProductCreateColorInput)
    close()
  }

  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />

  return (
    <>
      <Button
        className={classes.button}
        color="yellow"
        preset="fixedBorderButton"
        textColor="yellow"
        textPreset="h5"
        text="ADD VARIANT"
        tx="item.form.create.variant"
        onClick={handleOnOpen(true)}
      />
      {open && (
        <QuestionModal
          disabled={isDisabled}
          title="Create Color Variant"
          titleTx="item.form.create.color.title"
          successText="Apply"
          successTx="item.form.create.color.apply"
          onClose={handleOnOpen(false)}
          onSubmit={handleOnSubmit}
        >
          <Text
            color="inactive"
            preset="body"
            text="Please fill in all fields."
            tx="item.form.create.color.description"
          />
          <TextField
            className={classes.field}
            maxLength={100}
            label="Title"
            labelTx="item.form.create.color.title.input"
            onChange={handleOnChange('title')}
          />
          <TextArea
            className={classes.area}
            preset="border"
            maxLength={500}
            placeholder={descriptionPlaceholder}
            label="Description"
            labelTx="item.form.create.color.description"
            onChange={handleOnChange('description')}
          />
          <ColorPicker
            className={classes.field}
            data={colors}
            onChange={handleOnChangeColor}
          />
          <TextField
            className={classes.field}
            label="Extra Price"
            labelTx="item.form.create.color.price.input"
            type="number"
            rightElement={dollarIcon}
            onChange={handleOnChange('extraPrice')}
          />
          <TextField
            className={classes.field}
            label="Extra Production Days"
            labelTx="item.form.create.color.days.input"
            onChange={handleOnChange('extraProductionDays')}
          />
        </QuestionModal>
      )}
    </>
  )
}
