import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../../column'
import { Text } from '../../text'
import { Button } from '../../button'
import { LightTheme } from '../../Theme'

import { useStyle } from '../modal-banner-preview.styles'
import { ModalBannerPreviewHeader } from '../modal-banner-preview-header'

import { PreviewDefaultBannerProps } from './default-banner-preview.types'

export const DefaultBanerPreview: FC<PreviewDefaultBannerProps> = ({
  className,
  title,
  image,
  buttonText,
  mainHeading,
  active,
  bannerMobileImage,
  onClose,
  onOpenPreviewMobileBanner,
  onOpenPreviewDesktopBanner
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ theme })

  return (
    <Column className={classes.container}>
      <ModalBannerPreviewHeader
        active={active}
        onClose={onClose}
        onOpenPreviewMobileBanner={onOpenPreviewMobileBanner}
        onOpenPreviewDesktopBanner={onOpenPreviewDesktopBanner}
      />
      {active ? (
        <Column className={classes.mobileContainer}>
          {bannerMobileImage && (
            <img className={classes.image} src={bannerMobileImage}></img>
          )}
          <Column
            className={classes.mobileModal}
            fullWidth
            justifyContent="flex-start"
          >
            {title && <Text text={title} preset="h3" color="blue" />}
            {mainHeading && (
              <Text
                className={`${classes.text} ${classes.mainText}`}
                text={mainHeading}
                preset="h1"
              />
            )}
            {buttonText && (
              <Button
                className={classes.button}
                preset="primary"
                text={buttonText}
                textPreset="h5"
                textColor="white"
              />
            )}
          </Column>
        </Column>
      ) : (
        <Column className={classes.banerContainer}>
          {image && <img className={classes.image} src={image}></img>}
          <Column
            className={`${className} ${classes.modal}`}
            justifyContent="flex-start"
          >
            {title && <Text text={title} preset="h3" color="blue" />}
            {mainHeading && (
              <Text
                className={`${classes.text} ${classes.mainText}`}
                text={mainHeading}
                preset="mainBanner"
              />
            )}
            {buttonText && (
              <Button
                className={classes.button}
                text={buttonText}
                preset="primary"
                textPreset="h5"
                textColor="white"
              />
            )}
          </Column>
        </Column>
      )}
    </Column>
  )
}
