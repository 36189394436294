export const PRODUCT_MATERIAL_FRAGMENT = `
  fragment ProductMaterialFragment on ProductMaterial {
    title
    description
    extraPrice
    extraProductionDays
    isDefault
    id
  }
`

export const MATERIAL_FRAGMENT = `
  fragment MaterialFragment on Material {
    id
    name
  }
`

export const OFFER_MATERIAL_FRAGMENT = `
  fragment OfferMaterialFragment on OfferMaterial {
    title
    description
    extraPrice
    extraProductionDays
    isDefault
    id
  }
`
