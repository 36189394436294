import {
  ApolloClient,
  ApolloQueryResult,
  FetchMoreOptions,
  FetchMoreQueryOptions,
  NormalizedCacheObject
} from '@apollo/client'

import { AuthApi } from './auth'
import { CategoryApi } from './category'
import { CollectionsApi } from './collection'
import { CountryApi } from './country'
import { MenuApi } from './menu'
import { OrderApi } from './order'
import { NewsApi } from './news'
import { PageInfo, ProductApi } from './product'
import { VendorsApi } from './vendor'
import { ColorApi } from './color'
import { AttributeApi } from './attribute'
import { MaterialApi } from './material'
import { PaymentApi } from './payment'
import { FeedbackApi } from './feedback'
import { SubscriptionTypeApi } from './profile-settings'
import { StatisticApi } from './statistic'
import { AdminApi } from './admin'
import { OfferApi } from './offer'
import { AdvertisingApi } from './advertising'
import { DeliveryPriceApi } from './delivery-price'
import { InstructionApi } from './instruction'
import { UserListApi } from './user-list'

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type FetchMore<Data, Variables> = (
  fetchMoreOptions: FetchMoreQueryOptions<Variables, Data> &
    FetchMoreOptions<Data, Variables>
) => Promise<ApolloQueryResult<Data>>

export type SortBy<E> = {
  direction: OrderDirection
  field?: E
}

export type FilterItem = {
  id: string
  name: string
}

export enum RedirectStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}

export type RedirectSubscriptionQueryParams = {
  event: RedirectStatus
  token: string
  ba_token: string
  subscription_id: string
  subscription_platform_id: string
}

export type FilterWithSearch = {
  search?: string
}

export type ListVariablesWithoutFilter = Omit<ListVariables<any>, 'filter'>

export type ListVariablesWithFirst<FilterT extends FilterWithSearch> = {
  first?: number
  last?: number
  after?: string
  before?: string
  filter?: FilterT
}

export type ListVariablesWithLast = {
  first?: number
  last: number
  after?: string
  before?: string
}

export type ListVariables<FilterT extends FilterWithSearch> =
  ListVariablesWithFirst<FilterT>

export type BackgroundImage = {
  id: string
  url: string
  alt: string
}

export type DateRangeInput = {
  gte?: string
  lte?: string
}

export interface Edge<T> {
  node: T
  cursor: string
}

export interface PaginationList<T> {
  pageInfo: PageInfo
  edges: Edge<T>[]
  totalCount: number
  filters: string
}

export type Api = {
  api: ApolloClient<NormalizedCacheObject>
  setHeader: (key: string, prop: string) => void
  admin: AdminApi
  auth: AuthApi
  color: ColorApi
  menu: MenuApi
  product: ProductApi
  collection: CollectionsApi
  category: CategoryApi
  country: CountryApi
  order: OrderApi
  news: NewsApi
  vendor: VendorsApi
  attribute: AttributeApi
  material: MaterialApi
  payment: PaymentApi
  feedback: FeedbackApi
  subscription: SubscriptionTypeApi
  statistic: StatisticApi
  offer: OfferApi
  advertising: AdvertisingApi
  deliveryPrice: DeliveryPriceApi
  instruction: InstructionApi
  userList: UserListApi
}
