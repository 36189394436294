import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { PickerType } from './calendar.types'

export interface StyleProps {
  pickerType: PickerType
}

export const useStyle = createUseStyles((theme: LightTheme) => ({
  buttonContainer: {
    marginTop: 16
  },
  calendar: {
    marginTop: 16
  },
  container: {
    backgroundColor: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    height: 380,
    padding: '8px 0',
    width: 380
  },
  leftButton: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: ({ pickerType }: StyleProps) =>
      pickerType === PickerType.DATE ? theme.boxShadow : 'none',
    height: 24
  },
  rightButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    boxShadow: ({ pickerType }: StyleProps) =>
      pickerType === PickerType.PERIOD ? theme.boxShadow : 'none',
    height: 24
  }
}))
