import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import { LoginCardStyleProps } from './register-page.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  attention: {
    marginTop: 24,
    opacity: ({ error }: LoginCardStyleProps) => (error ? 1 : 0),
    visibility: ({ error }: LoginCardStyleProps) =>
      error ? 'visible' : 'hidden',
    textDecorationColor: theme.colors.error,
    width: '100%'
  },
  container: {
    backgroundImage: ({ background }: LoginCardStyleProps) =>
      `url(${background})`,
    backgroundRepeat: 'space',
    backgroundSize: '100vw',
    paddingBottom: 24,
    zIndex: 1
  },
  forget: {
    flex: 1,
    marginLeft: 4,
    width: 'auto'
  },
  input: {
    marginTop: 24
  },
  loginForm: {
    marginTop: 96,
    width: 536
  },
  panel: {
    '& span': {
      color: theme.colors.error
    },
    borderRadius: 16,
    padding: 24
  },
  radio: {
    marginLeft: 60
  },
  radioContainer: {
    marginTop: 24
  },
  signIn: {
    marginLeft: 4
  },
  signInContainer: {
    marginTop: 24
  },
  submit: {
    marginTop: 56
  }
}))
