import { TextProps } from '../../components'
import { BannerKind } from '../../services'

export const getBannerTypeNameByKind = (kind: BannerKind): TextProps => {
  switch (kind) {
    case BannerKind.PROMOTION_BANNER:
      return {
        text: 'Promotion banner',
        tx: 'banner.type.promotion'
      }
    case BannerKind.PREMIUM_USER_BANNER:
      return {
        text: 'Premium user banner',
        tx: 'banner.type.premium'
      }
    case BannerKind.TOP_PRODUCT_BANNER:
      return {
        text: 'Top Product banner',
        tx: 'banner.type.top'
      }
    case BannerKind.PROMOTED_STORE_BANNER:
      return {
        text: 'Promoted Store banner',
        tx: 'banner.type.promoted'
      }
    default:
      return {
        text: 'Promotion banner',
        tx: 'banner.type.promotion'
      }
  }
}
