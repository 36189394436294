import { IntlShape } from 'react-intl'

import { OrderLine } from '../../services'

export const getFullOriginLink = (link: string) =>
  !__DEV__
    ? `${window.location.origin}${link}`
    : `https://marketplace-dev.consultinar.com${link}`

export const getProductFullOriginLink = (id: string) => {
  const linkOnProduct = `/product/${id}`

  return getFullOriginLink(linkOnProduct)
}

export const getOrderLinesAutoMessage = (intl: IntlShape, lines: OrderLine[]) =>
  lines.reduce((acc, { product }) => {
    const productNameTitle = intl.formatMessage({
      id: 'chat.order.productName.message',
      defaultMessage: 'Product Name:'
    })

    const fullProductLink = getProductFullOriginLink(product.id)
    return `${acc}\n ${productNameTitle} ${product.name}\n ${fullProductLink}`
  }, '')
