import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    maxWidth: 312
  },
  load: {
    marginTop: 16
  },
  container: {
    padding: '24px 16px'
  },
  table: {
    marginTop: 24
  },
  titleContainer: {
    marginTop: 24
  },
  content: {
    marginTop: 24
  },
  search: {
    width: '100%'
  },
  filter: {
    minWidth: 288
  },
  filterText: {
    minWidth: 64
  },
  filterItem: {
    top: 0,
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: `1px solid ${theme.colors.black}`,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  searchItem: {
    padding: 16,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  leftSection: {
    flex: 2
  },
  section: {
    flex: 1,
    marginLeft: 24
  },
  sectionItem: {
    padding: 16,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  rating: {
    marginTop: 8
  }
}))
