import React, { FC, useEffect, useRef } from 'react'

import { useChat } from '../../providers'
import { arrayHasElements } from '../helpers'

import { useStyle } from './inbox.styles'
import { InboxProps } from './inbox.types'

export const Inbox: FC<InboxProps> = ({ className = '' }) => {
  const { session, onDestroyInboxes, onMountConversation } = useChat()
  const classes = useStyle()
  const talkjsContainer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (session) {
      const inboxes = session.getInboxes()
      const hasInboxes = arrayHasElements(inboxes)

      if (!hasInboxes && onMountConversation) {
        onMountConversation(talkjsContainer)
      }

      return () => {
        if (hasInboxes && onDestroyInboxes) {
          onDestroyInboxes()
        }
      }
    }
  }, [])

  return (
    <div
      ref={talkjsContainer}
      className={`${className} ${classes.container}`}
    ></div>
  )
}
