import React from 'react'

import { SalerPageDesignNavigation } from '../cabinet-navigator'
import {
  CategoryNav,
  CategoryNavItem,
  Column,
  ICONS,
  Row,
  Text
} from '../../components'

import { useStyle } from './saller-page-design.styles'
import { VendorBannerPage } from '../vendor-banner'

export const SallersPageDesignPage = () => {
  const classes = useStyle()

  const NAVIGATION_TABS: CategoryNavItem[] = [
    {
      icon: ICONS.localATM,
      text: 'Banners',
      tx: 'saler.page.design.banners',
      link: SalerPageDesignNavigation.SALER_PAGE_BANNER,
      content: <VendorBannerPage />
    },
    {
      icon: ICONS.localOffer,
      text: 'Top Product',
      tx: 'saler.page.design.top.product',
      link: SalerPageDesignNavigation.SALER_PAGE_TOP_PRODUCT,
      content: <Column />
    }
  ]

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="flex-start"
      >
        <Text
          color="black"
          preset="h3"
          text="Main Page Design"
          tx="saler.page.design.title"
        />
      </Row>
      <CategoryNav className={classes.categoryNav} data={NAVIGATION_TABS} />
    </Column>
  )
}
