import React, { FC, useState } from 'react'

import { ProductCreateColorInput } from '../../../services'
import { Column, Row, Text } from '../../../components'

import { OfferColorTableItem } from './offer-color-table-item'
import { useStyle } from './offer-color-table.styles'
import { OfferColorsTableProps } from './offer-color-table.types'

export const OfferColorsTable: FC<OfferColorsTableProps> = ({
  className = '',
  data,
  onChange
}) => {
  const [active, changeActive] = useState<number[]>([])
  const classes = useStyle()

  const handleOnChange = (index: number) => (state: boolean) => {
    if (state) {
      return changeActive([...active, index])
    }

    const prevState = active.filter((prevIndex) => prevIndex !== index)
    return changeActive(prevState)
  }

  const handleOnChangeDefault =
    (defaultIndex: number, nextData?: ProductCreateColorInput[]) => () => {
      const currentDataValue = nextData || data
      const nextDataValue = currentDataValue.map((color, index) => ({
        ...color,
        isDefault: index === defaultIndex
      }))

      if (onChange) {
        onChange(nextDataValue)
      }
    }

  const getActive = (index: number) => {
    const activeColor = active.find((item) => item === index)

    return typeof activeColor === 'number'
  }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Column fullWidth>
        <Row
          fullWidth
          className={classes.header}
          justifyContent="space-between"
        >
          <Column
            className={`${classes.tableColumn} ${classes.tableColumnMin}`}
          ></Column>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              className={classes.text}
              color="black"
              preset="h6"
              text="Title"
            />
          </Row>
          <Row className={classes.bigTableColumn} justifyContent="flex-start">
            <Row
              className={classes.tableColumnDescription}
              justifyContent="flex-start"
            >
              <Text
                className={classes.text}
                color="black"
                preset="h6"
                text="Description"
              />
            </Row>
            <Row
              className={classes.tableColumnColor}
              justifyContent="flex-start"
            >
              <Text
                className={classes.text}
                color="black"
                preset="h6"
                text="Color"
              />
            </Row>
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              className={classes.text}
              color="black"
              preset="h6"
              text="Extra Price"
            />
          </Row>
          <Row className={classes.bigTableColumn} justifyContent="flex-start">
            <Text
              className={classes.text}
              color="black"
              preset="h6"
              text="Extra Production Days"
            />
          </Row>
        </Row>
        {data.map((color, index) => {
          return (
            <OfferColorTableItem
              key={`color_${index}_${color.color}`}
              {...color}
              checked={getActive(index)}
              onChange={handleOnChange(index)}
              onChangeDefault={handleOnChangeDefault(index)}
            />
          )
        })}
      </Column>
    </Column>
  )
}
