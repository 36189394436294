import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  bottomRow: {
    padding: 16,
    width: 'calc(100% - 32px)'
  },
  dropdownRow: {
    marginLeft: 8
  },
  dropdownSelectedText: {
    padding: 4
  },
  dropdownSelectRow: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '100%',
    left: 0,
    backgroundColor: `${theme.colors.white}`
  },
  dropdownItem: {
    width: '100%',
    padding: 4,
    '&:hover': {
      backgroundColor: `${theme.colors.hover}`
    }
  },
  icon: {
    marginLeft: 8,
    cursor: 'pointer'
  },
  dropdownItemText: {
    cursor: 'pointer'
  }
}))
