// @ts-ignore
import { GraphQLError } from 'graphql'
import { findValueInEnum } from '../misc'

export enum JWTError {
  invalid = 'InvalidTokenError',
  invalidSignature = 'InvalidSignatureError',
  expired = 'ExpiredSignatureError'
}

export function isJwtError(error: GraphQLError): boolean {
  try {
    if (error && error.extensions) {
      return Boolean(findValueInEnum(error.extensions.exception.code, JWTError))
    }
  } catch (e) {
    return false
  }

  return false
}

export function isTokenExpired(error: GraphQLError): boolean {
  if (error.extensions) {
    return error.extensions.exception.code === JWTError.expired
  }

  return false
}
