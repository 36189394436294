import moment from 'moment'
import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  BackBreadcrumbs,
  Column,
  DatePicker,
  Icon,
  ICONS,
  LoadPhotoContainer,
  ModalBanerPreview,
  PageForm,
  RadioBox,
  RadioItemWithIdProps,
  Row,
  Section,
  Text
} from '../../components'
import {
  Banner,
  CreateBannerInput,
  BannerKind,
  BannerButtonType
} from '../../services'

import { useStyle } from './banner-form.styles'
import { BannerFormProps } from './banner-form.types'
import { OrganizeBannerSection } from './organize-banner-section'
import { ViewByBannerType } from './view-by-banner-type'

export const defaultValue: CreateBannerInput = {
  buttonType: BannerButtonType.BIG_BUTTON,
  description: '',
  isVisible: false,
  kind: BannerKind.PROMOTION_BANNER,
  mainHeading: '',
  secondaryHeading: ''
}

export const typeRadioData: RadioItemWithIdProps[] = [
  {
    id: BannerKind.PROMOTION_BANNER.toString(),
    name: 'Promotion banner',
    nameTx: 'banner.type.promotion'
  },
  {
    id: BannerKind.PREMIUM_USER_BANNER.toString(),
    name: 'Premium user banner',
    nameTx: 'banner.type.premium'
  },
  // TODO: in bug-1637 there was a requirement to temporarily remove Top Product banner
  // {
  //   id: BannerKind.TOP_PRODUCT_BANNER.toString(),
  //   name: 'Top Product banner',
  //   nameTx: 'banner.type.top'
  // },
  {
    id: BannerKind.PROMOTED_STORE_BANNER.toString(),
    name: 'Promoted Store banner',
    nameTx: 'banner.type.promoted'
  }
]
export const visibilityRadioData: RadioItemWithIdProps[] = [
  { id: '1', name: 'Visible', nameTx: 'banner.visibility.active' },
  { id: '0', name: 'Invisible', nameTx: 'banner.visibility.inactive' }
]

export const BannerForm: FC<BannerFormProps> = ({
  banner,
  title,
  titleTx,
  onSumbit
}) => {
  // TODO: temporary hidden according to ovm-1771
  // const { menu } = useApi()
  // const maxCount = 10
  // const { data } = menu.useBanners({ first: 1 })
  const history = useHistory()
  const classes = useStyle()
  const defaultImagesCount = banner?.image ? 1 : 0
  const defaultBanner = banner
    ? {
        ...banner,
        id: undefined,
        image: undefined,
        mobileImage: undefined
      }
    : undefined

  const [value, changeValue] = useState<CreateBannerInput>(
    defaultBanner || defaultValue
  )
  const [imageCount, changeImageCount] = useState(defaultImagesCount)
  const [mobileImageCount, changeMobileImageCount] =
    useState(defaultImagesCount)

  const [openBannerPreview, changeOpenBannerPreview] = useState(false)
  const [openMobileBanner, changeOpenMobileBanner] = useState(false)

  const handleOnChangeBannerPreview = (state: boolean) => () => {
    changeOpenMobileBanner(state)
  }

  const handleOnMobilePreviewClick = () => {
    const changeView = handleOnChangeBannerPreview(true)

    changeView()
  }

  const handleOnDesktopPreviewClick = () => {
    const changeView = handleOnChangeBannerPreview(false)

    changeView()
  }

  const isDisabled =
    !imageCount ||
    !mobileImageCount ||
    !value.startDate ||
    !value.endDate ||
    !value.name

  const BannerImage = useMemo(() => {
    if (value.image) {
      return URL.createObjectURL(value.image)
    }

    return banner?.image
  }, [value.image])

  const BannerMobileImage = useMemo(() => {
    if (value.mobileImage) {
      return URL.createObjectURL(value.mobileImage)
    }

    return banner?.mobileImage
  }, [value.mobileImage])

  const handleOnOpenBannerPreviev = (state: boolean) => () => {
    changeOpenBannerPreview(state)
  }

  const handleOnBannerPreviewClick = () => {
    const openModal = handleOnOpenBannerPreviev(true)

    openModal()
  }

  const handleOnChangeType = (nextValue: string) => {
    changeValue({
      ...value,
      kind: nextValue as BannerKind
    })
  }

  const handleOnChangeVisibility = (nextValue: string) => {
    const isVisible = Boolean(Number(nextValue))
    changeValue({
      ...value,
      isVisible
    })
  }

  const handleOnChangeDate = (prop: keyof Banner) => (date: Date) => {
    const nextBanner = {
      ...value,
      [prop]: moment(date).format('YYYY-MM-DD')
    }

    changeValue(nextBanner)
  }

  const handleOnChangeBanner = (nextBanner: CreateBannerInput) => {
    changeValue({
      ...value,
      ...nextBanner
    })
  }

  const handleOnChangeInput =
    (prop: keyof CreateBannerInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value: nextValue } = event.target
      changeValue({
        ...value,
        [prop]: nextValue
      })
    }

  const handleOnChangeImage = (images: File[], urls?: string[]) => {
    changeValue({
      ...value,
      image: images.length > 0 ? images[0] : undefined
    })
    const prevImagesCount = urls?.length || 0

    changeImageCount(images.length + prevImagesCount)
  }

  const handleOnChangeMobileImage = (mobileImages: File[], urls?: string[]) => {
    changeValue({
      ...value,
      mobileImage: mobileImages.length > 0 ? mobileImages[0] : undefined
    })
    const prevImagesCount = urls?.length || 0

    changeMobileImageCount(mobileImages.length + prevImagesCount)
  }

  const handleOnSubmit = () => {
    onSumbit(value)
  }

  // TODO: temporary hidden according to ovm-1771
  // const countsValues = {
  //   count: 5,
  //   maxCount
  // }
  // const maxCountsValues = {
  //   maxCount
  // }

  const visibleId = String(Number(value.isVisible))
  const loadPhotoDefaultValue = useMemo(
    () => (banner?.image ? [banner.image] : []),
    [banner?.image]
  )
  const loadBannerMobileImageDefaultValue = useMemo(
    () => (banner?.mobileImage ? [banner.mobileImage] : []),
    [banner?.mobileImage]
  )

  const startDate = value.startDate ? new Date(value.startDate) : undefined
  const endDate = value.endDate ? new Date(value.endDate) : startDate

  const favorite = (
    <Row className={classes.favorite}>
      <Icon src={ICONS.removeRedEye} onClick={handleOnBannerPreviewClick} />
    </Row>
  )

  return (
    <>
      <PageForm
        pannelLeftContent={favorite}
        disabled={isDisabled}
        onBack={history.goBack}
        onSave={handleOnSubmit}
      >
        <Column
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.container}
        >
          <BackBreadcrumbs text="All banners" tx="banner.breadcrumbs.back" />
          <Text
            className={classes.title}
            preset="h3"
            text={title}
            tx={titleTx}
          />
          <Row fullWidth alignItems="flex-start" className={classes.content}>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.leftColumn}
            >
              <Section
                description="(Main banner of the main page of the site)"
                descriptionTx="banner.type.description"
                title="Banner Type"
                titleTx="banner.type.title"
              >
                <RadioBox
                  active={String(value.kind)}
                  className={classes.sectionAdditionContainer}
                  data={typeRadioData}
                  onChange={handleOnChangeType}
                />
              </Section>
              <LoadPhotoContainer
                className={classes.section}
                defaultValue={loadPhotoDefaultValue}
                title="Desktop Banner Image"
                titleTx="banner.image"
                onChange={handleOnChangeImage}
              />
              <ViewByBannerType
                banner={value}
                kind={value.kind}
                onChange={handleOnChangeBanner}
                mobileImages={loadBannerMobileImageDefaultValue}
                onChangeMobileImage={handleOnChangeMobileImage}
              >
                <Section
                  className={classes.section}
                  title="Duration"
                  titleTx="banner.duration.title"
                >
                  <Row
                    alignItems="flex-start"
                    className={classes.sectionAdditionContainer}
                  >
                    <DatePicker
                      defaultValue={startDate}
                      label="Start Date"
                      labelTx="banner.start.date"
                      onChange={handleOnChangeDate('startDate')}
                    />
                    <DatePicker
                      defaultValue={endDate}
                      className={classes.end}
                      label="End Date"
                      labelTx="banner.end.date"
                      onChange={handleOnChangeDate('endDate')}
                    />
                  </Row>
                </Section>
              </ViewByBannerType>
            </Column>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.rightColumn}
            >
              <OrganizeBannerSection
                name={value.name}
                onChangeInputValue={handleOnChangeInput}
              />
              <Section
                className={classes.visible}
                title="Visibility"
                titleTx="banner.visibility.title"
              >
                <RadioBox
                  active={visibleId}
                  className={classes.sectionAdditionContainer}
                  data={visibilityRadioData}
                  onChange={handleOnChangeVisibility}
                />

                {/* TODO: temporary hide the limitation info  ovm-1771 */}
                {/* {value.kind === BannerKind.PROMOTION_BANNER && (
                  <Column
                    className={classes.visibleTitleContainer}
                    alignItems="flex-start"
                    justifyContent="flex-start"
                  >
                    <Text
                      className={classes.visibleTitle}
                      color="black"
                      preset="h5"
                      text={`Now active
                      ${data?.allBanners.activeBannersCount}/${maxCount} Promotion Banners!`}
                      tx="banner.active.counts"
                      values={countsValues}
                    />
                    <Text
                      className={classes.visibleDescription}
                      color="inactive"
                      preset="title"
                      text={`There can be up ${maxCount} Promotion Banners at the same time.`}
                      tx="banner.active.max.count"
                      values={maxCountsValues}
                    />
                  </Column>
                )} */}
              </Section>
            </Column>
          </Row>
        </Column>
      </PageForm>
      <ModalBanerPreview
        kind={value?.kind}
        image={BannerImage}
        mainHeading={value?.mainHeading}
        title={value?.title}
        isButtonActive={value.isButtonActive}
        isNotificationActive={value.isNotificationActive}
        secondaryHeading={value?.secondaryHeading}
        mainDescription={value?.mainDescription}
        secondaryDescription={value?.secondaryDescription}
        button={value?.buttonType}
        buttonText={value.buttonText}
        notificationText={value?.notificationMessageText}
        notificationBtnText={value?.notificationButtonText}
        open={openBannerPreview}
        active={openMobileBanner}
        bannerMobileImage={BannerMobileImage}
        onOpenPreviewDesktopBanner={handleOnDesktopPreviewClick}
        onOpenPreviewMobileBanner={handleOnMobilePreviewClick}
        onClose={handleOnOpenBannerPreviev(false)}
      />
    </>
  )
}
