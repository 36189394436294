import React, { FC } from 'react'

import { Column } from '../../../components'

import { ItemsTableHeaderProps } from './items-table-header.types'

export const ItemsTableHeader: FC<ItemsTableHeaderProps> = ({
  className = '',
  checked,
  header: headerDefault,
  headerChecked: headerCheckedDefault
}) => {
  const headerChecked = headerCheckedDefault || headerDefault
  const header = checked ? headerChecked : headerDefault

  return (
    <Column className={className} fullWidth alignItems="flex-start">
      {header}
    </Column>
  )
}
