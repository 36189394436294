import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  modal: {
    overflow: 'auto',
    minHeight: 448
  },
  success: {
    width: 168,
    marginLeft: 24
  },
  details: {
    alignSelf: 'stretch',
    flex: 1,
    padding: 16
  },
  bottom: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    background: theme.colors.white,
    padding: 24
  }
}))
