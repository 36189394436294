import React, { FC } from 'react'

import { Row } from '../../../components'
import { ProfileInfo, ProfileSummary } from '../../profile-settings-general'

import { useStyle } from './profile-info.styles'
import { ProfileInfoViewProps } from './profile-info.types'

export const ProfileInfoView: FC<ProfileInfoViewProps> = ({
  user,
  userCredentials,
  changeUser,
  onChangeImage,
  onDeleteImage
}) => {
  const classes = useStyle()

  return (
    <Row
      alignItems="flex-start"
      justifyContent="space-between"
      className={classes.content}
    >
      <ProfileInfo
        {...user}
        user={userCredentials}
        changeUser={changeUser}
        onChangeImage={onChangeImage}
        onDeleteImage={onDeleteImage}
      />
      <ProfileSummary {...user} />
    </Row>
  )
}
