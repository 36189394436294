import { stringify as stringifyQs } from 'qs'

export enum AuthRoutes {
  RESET_PASSWORD_SUCCESS = '/reset-password/success',
  FORGOT_PASSWORD = '/forgot-password',
  CHANGE_PASSWORD = '/change-password/:email/:token',
  SIGN_IN = '/sign-in',
  SIGN_IN_ADMIN = '/admin',
  REGISTER = '/sign-up',
  USER_INFO_UPDATE = '/user-info-update',
  CONTACT_INFO_UPDATE = '/contact-info-update',
  SUBSCRIPTION = '/subscription',
  NEW_PASSWORD = '/new-password',
  CHANGE_EMAIL_REQUEST = '/change-email-request'
}

export interface NewPasswordUrlQueryParams {
  email: string
  token: string
}
export const newPasswordUrl = (params?: NewPasswordUrlQueryParams) =>
  `${AuthRoutes.NEW_PASSWORD}?${stringifyQs(params)}`
