import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  title: {
    marginTop: 36
  },
  description: {
    marginTop: 16
  },
  refundTitle: {
    marginTop: 40
  },
  refundPrice: {
    marginTop: 16
  }
})
