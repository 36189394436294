import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  Dot,
  Row,
  Text,
  LightTheme,
  getCurrencySymbol,
  Checkbox,
  getPriceToFixed
} from '../../../../components'

import { Currency, ProductOptionType } from '../../../../services'

import { OfferOptionTableItemProps } from './offer-option-table-item.types'
import { useStyle } from './offer-option-table-item.styles'

export const OfferOptionTableItem: FC<OfferOptionTableItemProps> = ({
  title,
  type,
  description,
  extraPrice,
  extraProductionDays,
  isDefault,
  checked,
  onChangeCheckbox,
  onChangeRadio
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const isExtraPrice = extraPrice || extraPrice === 0
  const extraPriceFormated = `${getCurrencySymbol(
    Currency.USD
  )}${getPriceToFixed(extraPrice)}`
  const extraProductionDaysFormated = String(extraProductionDays)

  return (
    <Row fullWidth className={classes.tableRow} justifyContent="space-between">
      {type === ProductOptionType.CHECKBOX ? (
        <Row
          className={`${classes.tableColumn} ${classes.tableColumnMin}`}
          justifyContent="flex-start"
        >
          <Checkbox
            checked={checked || isDefault}
            onChange={onChangeCheckbox}
          />
        </Row>
      ) : (
        <Row
          className={`${classes.tableColumn} ${classes.tableColumnMin}`}
          justifyContent="flex-start"
          onClick={onChangeRadio}
        >
          <Dot active={isDefault} />
        </Row>
      )}
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {title && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={title}
          />
        )}
      </Row>
      <Row className={classes.bigTableColumn} justifyContent="flex-start">
        {description && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={description}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {isExtraPrice && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={extraPriceFormated}
          />
        )}
      </Row>
      <Row className={classes.bigTableColumn} justifyContent="flex-start">
        {extraProductionDays && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={extraProductionDaysFormated}
          />
        )}
      </Row>
    </Row>
  )
}
