import React, { useMemo, useState } from 'react'

import { Column, CoreItem, Row, Text } from '../../components'
import { useApi } from '../../providers'
import {
  VendorFiltersParseJSON,
  parseFilterItems,
  UserSortField,
  AdminStatisticVariables
} from '../../services'
import { SortVariables, useSort, useTable } from '../../hooks'

import { StatisticFilters } from './statistic-filters'
import { StatisticTable, StatisticTableProps } from './statistic-table'
import { useStyle } from './admin-statistic.styles'

const rowCounts = [5, 10, 20, 30]

export const AdminStatisticPage = () => {
  const classes = useStyle()

  const { statistic } = useApi()

  const defaultVariables = {
    first: rowCounts[1]
  }
  const { data, loading, refetch } =
    statistic.useAdminStatistic(defaultVariables)

  const [variables, changeVariables] =
    useState<AdminStatisticVariables>(defaultVariables)

  const {
    Data,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch,
    onSubmitFilters,
    onResetFilters,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.adminStatistic,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  const Filters = useMemo<
    Record<keyof VendorFiltersParseJSON, CoreItem[]>
  >(() => {
    if (data && data.adminStatistic.filters) {
      const filters = parseFilterItems<VendorFiltersParseJSON>(
        data.adminStatistic.filters
      )

      if (filters) {
        return filters
      }
    }

    return {
      companyNames: [],
      countries: [],
      subscriptionTypes: [],
      vendors: []
    }
  }, [data])

  const handleOnSearch: StatisticTableProps['onChangeSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnSort = (sortVariables: SortVariables<UserSortField>) => {
    refetch({
      ...sortVariables,
      before: undefined,
      after: undefined,
      last: undefined
    })

    onResetPageState()
    changeVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="space-between"
      >
        <Text
          color="black"
          preset="h3"
          text="Statistics"
          tx="cabinet.route.admin.statistics.title"
        />
        <StatisticFilters
          filters={Filters}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
        />
      </Row>
      <StatisticTable
        className={classes.table}
        rowCounts={rowCounts}
        data={Data}
        loading={loading}
        paginationState={paginationState}
        onChangeSearch={handleOnSearch}
        onChangePage={onChangePage}
        onChangeRowCount={onChangeRowCount}
        onNextPage={onNextPage}
        onPrevioslyPage={onPrevioslyPage}
        onSort={onSort}
      />
    </Column>
  )
}
