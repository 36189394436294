import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 20
  },
  titleContainer: {
    marginTop: 24
  },
  content: {
    columnGap: '20px',
    width: '100%'
  },
  leftGroup: {
    flex: 2
  },
  rightGroup: {
    flex: 1
  },
  button: {
    marginLeft: 24
  },
  buttonChange: {
    height: 56,
    width: 320,
    marginTop: 32
  },
  submitContainer: {
    background: theme.colors.white,
    bottom: 0,
    boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
    marginTop: 16,
    padding: 24,
    position: 'sticky'
  },
  icon: {
    marginTop: 30
  },
  paymentText: {
    marginTop: 20
  }
}))
