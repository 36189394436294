import React, { FC } from 'react'
import { getCurrencySymbol, getPriceToFixed } from '../../../components'

import { Column } from '../../../components/column'
import { Text } from '../../../components/text'
import { Currency } from '../../../services'
import { useStyle } from './subscription-card.styles'
import { SubscriptionCardProps } from './subscription-card.types'

export const SubscriptionCard: FC<SubscriptionCardProps> = ({
  id,
  name,
  price,
  pricePer,
  active,
  onClick
}) => {
  const classes = useStyle({ active })

  return (
    <Column
      key={`subscription_price_${id}`}
      fullWidth
      justifyContent="space-between"
      className={classes.plan}
      onClick={onClick}
    >
      <Column fullWidth alignItems="flex-start">
        <Text color="black" preset="h4" text={name} />
        <Text
          color="black"
          preset="h3"
          text={`${getCurrencySymbol(Currency.USD)}${getPriceToFixed(price)}${
            pricePer ? `/${pricePer?.toLowerCase()}` : ''
          }`}
          className={classes.price}
        />
      </Column>
    </Column>
  )
}
