import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { ColorDot, Column, LightTheme, Row, Text } from '../../../components'
import { concatClassNames } from '../../../pages/views/helpers'

import { useStyle } from './order-chat-status.styles'
import { OrderChatStatusProps } from './order-chat-status.types'

export const OrderChatStatus: FC<OrderChatStatusProps> = ({
  className = '',
  color,
  title,
  titleTx,
  titleValues,
  statusText,
  statusTx,
  isStatusDraft
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle()

  const themeColor = theme.colors[color]
  return (
    <Row
      fullWidth
      className={concatClassNames(className, classes.offerTitleContainer)}
      justifyContent="flex-start"
    >
      <ColorDot color={themeColor} preset="big" />
      <Column alignItems="flex-start" className={classes.offerTitle}>
        {!isStatusDraft && (
          <Text preset="h5" text={title} tx={titleTx} values={titleValues} />
        )}
        <Text
          className={classes.offerDescription}
          preset="h7"
          text={statusText}
          tx={statusTx}
        />
      </Column>
    </Row>
  )
}
