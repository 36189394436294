import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Text } from '../text'
import { LightTheme } from '../Theme'
import { useStyle } from './button.style'
import { ButtonProps } from './button.types'

export const Button: FC<ButtonProps> = ({
  color,
  border,
  className = '',
  disabled,
  preset = 'borderButton',
  textColor,
  textPreset = 'h5',
  text,
  tx,
  ...buttonProps
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ border, color, disabled, theme })
  const presetClass = classes[preset]

  return (
    <button
      {...buttonProps}
      className={`${className} ${classes.container} ${presetClass}`}
    >
      <Text
        className={classes.text}
        color={textColor}
        preset={textPreset}
        text={text}
        tx={tx}
      />
    </button>
  )
}
