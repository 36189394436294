import React, {
  ChangeEvent,
  FC,
  useMemo,
  useRef,
  useState,
  useCallback
} from 'react'
import ImageViewer from 'react-simple-image-viewer'

import { Button } from '../button'
import { Column } from '../column'
import { EmptySection } from '../empty-section'
import { arrayHasElements } from '../helpers'
import { Icon, ICONS } from '../icon'
import { InfoTooltip } from '../info-tooltip'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './load-photo-container.style'
import { LoadPhotoContainerProps } from './load-photo-container.types'

export const LoadPhotoContainer: FC<LoadPhotoContainerProps> = ({
  multiple,
  title,
  titleTx,
  instruction,
  defaultValue,
  defaultIdValue,
  className,
  error,
  onChange,
  onRemove
}) => {
  const input = useRef<HTMLInputElement>(null)
  const [file, changeFile] = useState<File[]>([])
  const [urls, changeUrls] = useState<string[]>(defaultValue || [])
  const [imageId, changeImageId] = useState<string[]>(defaultIdValue || [])
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)

  const classes = useStyle()

  const FileUrls = useMemo(
    () =>
      file
        .map((item) => {
          if (item instanceof File) {
            return URL.createObjectURL(item)
          }

          return ''
        })
        .filter(Boolean),
    [String(file.length)]
  )

  const Images = useMemo(
    () => urls.concat(FileUrls),
    [String(FileUrls.length), urls.length]
  )

  const isImageError = !arrayHasElements(Images) && error

  const openImageViewer = useCallback(
    (index) => () => {
      setCurrentImage(index)
      setIsViewerOpen(true)
    },
    []
  )

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  const handleOnChangeImages = (files: File[], url?: string[]) => {
    if (onChange) {
      onChange(files, url)
    }
  }

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (files) {
      if (multiple) {
        const nextValue = [...file, files[0]]
        changeFile(nextValue)

        handleOnChangeImages(nextValue, urls)
      } else {
        const nextValue = [files[0]]

        changeFile([...nextValue])
        changeUrls([])

        handleOnChangeImages(nextValue)
      }
    }
  }

  const handleOnClick = () => {
    if (input.current) {
      input.current.click()
    }
  }

  const handleOnCancel = (index: number) => () => {
    const isFile = index > urls.length - 1

    if (input.current) {
      input.current.value = ''
    }

    if (isFile) {
      const files = file
      files.splice(index - urls.length, 1)
      changeFile([...files])

      handleOnChangeImages(files, urls)
    } else {
      const urlsArray = urls
      const url = urls.splice(index, 1)

      const idArray = imageId
      const ids = imageId.splice(index, 1)

      changeImageId(idArray)
      changeUrls(urlsArray)

      if (onRemove) {
        onRemove(ids[0], url[0])
      }

      handleOnChangeImages(file, urlsArray)
    }
  }

  return (
    <Column fullWidth className={`${className} ${classes.container}`}>
      <Row
        fullWidth
        className={classes.inputContainer}
        justifyContent="space-between"
      >
        <Row justifyContent="flex-end">
          <Text preset="h3" text={title} tx={titleTx} />
          <Row className={classes.tooltipContainer}>
            <InfoTooltip text={instruction} />
          </Row>
        </Row>
        <Button
          className={classes.button}
          preset="fixedBorderButton"
          textColor="yellow"
          textPreset="h5"
          text="ADD PHOTO"
          onClick={handleOnClick}
        />
      </Row>
      <input
        accept=".png,.jpg,.jpeg,.webp,image/jpeg,image/png,image/webp"
        className={classes.input}
        type="file"
        ref={input}
        onChange={handleOnChange}
      />
      <Row
        fullWidth
        wrap
        className={classes.imagesContainer}
        justifyContent="flex-start"
      >
        {Images.map((src: string, index: number) => (
          <Column className={classes.imageContainer} key={`${index}_${src}`}>
            <img
              className={classes.image}
              src={src}
              onClick={openImageViewer(index)}
            />
            <Row className={classes.close} onClick={handleOnCancel(index)}>
              <Icon src={ICONS.close} />
            </Row>
          </Column>
        ))}
      </Row>
      {isImageError && (
        <EmptySection
          className={classes.error}
          color="error"
          text="Add at least one photo of your Item."
          tx="item.form.image.error"
        />
      )}

      {isViewerOpen && (
        <ImageViewer
          closeOnClickOutside
          src={Images}
          currentIndex={currentImage}
          disableScroll={false}
          onClose={closeImageViewer}
        />
      )}
      <Column />
    </Column>
  )
}
