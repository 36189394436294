import { gql } from '@apollo/client'

import {
  BASIC_ADVERTISING_FRAGMENT,
  BASIC_ADVERTISING_LIMITS_FRAGMENT,
  PAGE_INFO_FRAGMENT
} from '../fragments'

export const ADVERTISING_LIST = gql`
  ${PAGE_INFO_FRAGMENT}
  ${BASIC_ADVERTISING_FRAGMENT}
  query AdvertisingList(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $filter: AdvertisingFilterInput
    $sortBy: AdvertisingSortingInput
  ) {
    advertisingList(
      first: $first
      last: $last
      after: $after
      before: $before
      filter: $filter
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          ...BasicAdvertisingFields
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
      activeBannersCount
    }
  }
`

export const CREATE_ADVERTISING = gql`
  ${BASIC_ADVERTISING_FRAGMENT}
  mutation AdvertisingCreate($input: AdvertisingCreateInput!) {
    advertisingCreate(input: $input) {
      advertising {
        ...BasicAdvertisingFields
      }
      errors: advertisingErrors {
        code
        message
        field
      }
    }
  }
`

export const UPDATE_ADVERTISING = gql`
  ${BASIC_ADVERTISING_FRAGMENT}
  mutation AdvertisingUpdate($id: ID!, $input: AdvertisingCreateInput!) {
    advertisingUpdate(id: $id, input: $input) {
      advertising {
        ...BasicAdvertisingFields
      }
      errors: bannerErrors {
        code
        message
        field
      }
    }
  }
`

export const ADVERTISING_REMOVE_MUTATION = gql`
  ${BASIC_ADVERTISING_FRAGMENT}
  mutation AdvertisingDelete($id: ID!) {
    advertisingDelete(id: $id) {
      advertising {
        ...BasicAdvertisingFields
      }
      errors: bannerErrors {
        code
        message
        field
      }
    }
  }
`

export const ADVERTISING_BY_ID = gql`
  ${BASIC_ADVERTISING_FRAGMENT}
  query AdvertisingById($id: ID!) {
    advertising(id: $id) {
      ...BasicAdvertisingFields
    }
  }
`

export const ADVERTISING_LIMITS = gql`
  ${BASIC_ADVERTISING_LIMITS_FRAGMENT}
  query AdvertisingLimits(
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    advertisingLimits(
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          ...BasicAdvertisingLimitsFragment
        }
      }
      totalCount
      activeBannersCount
    }
  }
`
