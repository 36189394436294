import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4
  },
  description: {
    marginLeft: 8
  },
  titleContainer: {
    padding: 16,
    width: '100%'
  },
  tooltipContainer: {
    marginLeft: 16
  }
}))
