import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`
  },
  header: {
    background: theme.colors.greyFon
  },
  tableColumn: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    flex: 1,
    height: '100%',
    padding: '8px 16px',
    width: '6vw'
  },
  bigTableColumn: {
    flex: 2.5,
    width: '12vw',
    borderBottom: `1px solid ${theme.colors.shadow}`,
    height: '100%',
    padding: '8px 16px'
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  remove: {
    cursor: 'pointer',
    padding: '8px 16px',
    borderBottom: `1px solid ${theme.colors.shadow}`
  }
}))
