import React, { FC } from 'react'

import { Button, Column, Row, Text } from '../../../components'

import { ActivityItem } from '../activity-item'

import { useStyle } from './activity-table.styles'
import { ActivityTableProps } from './activity-table.types'

export const ActivityTable: FC<ActivityTableProps> = ({
  activities,
  onNavigateActivity
}) => {
  const classes = useStyle()

  return (
    <Column fullWidth className={classes.activity}>
      <Row
        fullWidth
        justifyContent="flex-start"
        className={classes.activityTitle}
      >
        <Text color="black" preset="h3" text="Activity" />
        <Button
          text="View All"
          color="yellow"
          preset="borderButton"
          textColor="yellow"
          textPreset="maxButton"
          onClick={onNavigateActivity}
        />
      </Row>
      {activities &&
        activities.edges.map(({ node }, index) => {
          const values = { number: node.id }
          const time = node.timePassed || 1

          return (
            <ActivityItem
              key={`activity_item_${node.id}_${index}`}
              title={node.title}
              titleTx="order.number"
              event={node.event}
              time={time}
              values={values}
            />
          )
        })}
    </Column>
  )
}
