import { gql } from '@apollo/client'
import { COLOR_FRAGMENT } from '../fragments'

export const COLORS = gql`
  ${COLOR_FRAGMENT}
  query Colors {
    colors(first: 100) {
      edges {
        cursor
        node {
          ...ColorFragment
        }
      }
      totalCount
    }
  }
`
