import React, { FC } from 'react'

import { Button, QuestionConfirmModal, Row } from '../../../components'

import { useStyle } from '../offer-form.styles'

import { SubmitContainerProps } from './submit-container.types'

export const SubmitContainer: FC<SubmitContainerProps> = ({
  className = '',
  open,
  disabled,
  disabledDraftBtn,
  onContactCustomer,
  onSendToCustomer,
  onSaveAsDraft,
  onDelete,
  onOpenConfirmModal
}) => {
  const classes = useStyle()

  return (
    <>
      <Row fullWidth className={className} justifyContent="space-between">
        <Row>
          <Button
            color="yellow"
            preset="fixedBorderButton"
            textColor="yellow"
            textPreset="h5"
            text="DELETE"
            tx="offer.form.button.delete"
            onClick={onOpenConfirmModal(true)}
          />
          <Button
            disabled={disabledDraftBtn}
            className={classes.button}
            color="yellow"
            preset="fixedBorderButton"
            textColor="yellow"
            textPreset="h5"
            text="Save as a draft"
            tx="offer.form.button.saveAsDraft"
            onClick={onSaveAsDraft}
          />
        </Row>
        <Row>
          <Button
            className={classes.button}
            color="yellow"
            preset="fixedBorderButton"
            textColor="yellow"
            textPreset="h5"
            text="Contact Customer"
            tx="offer.form.button.contactCustomer"
            onClick={onContactCustomer}
          />
          <Button
            disabled={disabled}
            className={classes.button}
            color="yellow"
            preset="fixedPrimaryButton"
            textColor="white"
            textPreset="h5"
            text="Send to Customer"
            tx="offer.form.button.sendToCustomer"
            onClick={onSendToCustomer}
          />
        </Row>
      </Row>
      {open && (
        <QuestionConfirmModal
          cancelText="Back"
          cancelTx="delete.offer.modal.cancel"
          successText="Delete"
          successTx="delete.offer.modal.ok"
          title="Delete Offer"
          titleTx="offer.modal.delete.title"
          description="Are you sure?"
          descriptionTx="offer.modal.delete.description"
          secondaryDescription="Are you sure you want to delete this Offer?"
          secondaryDescriptionTx="offer.modal.delete.secondaryDescription"
          onSubmit={onDelete}
          onClose={onOpenConfirmModal(false)}
        />
      )}
    </>
  )
}
