import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  Column,
  ICONS,
  Loader,
  Row,
  SortItem,
  TablePagginationBar,
  Text,
  TextField
} from '../../../components'
import { LightTheme } from '../../../components/Theme'
import { OrderDirection, OrderSortField } from '../../../services'

import { PayoutTableItem } from '../payout-table-item'
import { useStyle } from './payouts-table.styles'
import { PayoutsTableProps } from './payouts-table.types'

export const PayoutsTable: FC<PayoutsTableProps> = ({
  className = '',
  data,
  loading,
  rowCounts,
  paginationState,
  onChangePage,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount,
  onSort,
  onChangeSearch
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const handleOnSort =
    (feedbackOrderField: OrderSortField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(feedbackOrderField, direction)
      }
    }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="payout.table.header.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
      </Row>
      <Column fullWidth className={classes.table}>
        <Row className={classes.header} justifyContent="space-between">
          <Row
            className={`${classes.firstColumn} ${classes.tableColumn}`}
            justifyContent="flex-start"
          >
            <SortItem
              defaultOpen
              preset="h6"
              name="Order Number"
              nameTx="payout.table.header.orderNumber"
              onClick={handleOnSort(OrderSortField.NUMBER)}
            />
          </Row>
          <Row className={classes.tableColumnBig} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Paypal Order"
              tx="payout.table.header.paypalOrder"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Company"
              tx="payout.table.header.vendor"
            />
          </Row>
          <Row className={classes.tableColumnBig} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Customer"
              tx="payout.table.header.customer"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              preset="h6"
              name="Order Date"
              nameTx="payout.table.header.orderDate"
              onClick={handleOnSort(OrderSortField.CREATION_DATE)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Customer Payment"
              tx="payout.table.header.customerPayment"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Referral Fee"
              tx="payout.table.header.referralFee"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Selling Fee"
              tx="payout.table.header.sellingFee"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Promotional Fee"
              tx="payout.table.header.promotionalFee"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Vendor Payment"
              tx="payout.table.header.vendorPayment"
            />
          </Row>
          <Row className={classes.tableColumnBig} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Order Status"
              tx="payout.table.header.orderStatus"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Payment Status"
              tx="payout.table.header.paymentStatus"
            />
          </Row>
        </Row>
        {loading && <Loader />}
        {!loading &&
          data.map((payout) => (
            <PayoutTableItem key={`payout_${payout.id}`} {...payout} />
          ))}
      </Column>
      {!loading && paginationState && (
        <TablePagginationBar
          rowCounts={rowCounts}
          paginationState={paginationState}
          onChangePage={onChangePage}
          onPrevioslyPage={onPrevioslyPage}
          onNextPage={onNextPage}
          onChangeRowCount={onChangeRowCount}
        />
      )}
    </Column>
  )
}
