import { gql } from '@apollo/client'

import { USER_FRAGMENT, USER_PART_FRAGMENT } from '../fragments'

export const REGISTER_USER = gql`
  ${USER_FRAGMENT}
  mutation RegisterAccount(
    $password: String!
    $email: String!
    $sex: Int!
    $role: Int!
    $redirectUrl: String!
    $firstName: String
    $contactsPersonName: String
    $contactsPersonPosition: String
    $paypalEmail: String
  ) {
    accountRegister(
      input: {
        password: $password
        email: $email
        firstName: $firstName
        sex: $sex
        role: $role
        redirectUrl: $redirectUrl
        contactsPersonName: $contactsPersonName
        contactsPersonPosition: $contactsPersonPosition
        paypalEmail: $paypalEmail
      }
    ) {
      accountErrors {
        field
        message
        code
      }
      jwtToken
      refreshToken
      csrfToken
      talkjsSignature
      user {
        ...UserFragment
      }
    }
  }
`

export const USER_ME = gql`
  ${USER_FRAGMENT}
  query Me {
    me {
      ...UserFragment
    }
  }
`

export const PART_ME_QUERY = gql`
  ${USER_PART_FRAGMENT}
  query Me {
    me {
      ...UserPartFragment
    }
  }
`

export const TOKEN_AUTH = gql`
  ${USER_FRAGMENT}
  mutation TokenAuth($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      accountErrors {
        field
        message
        code
      }
      user {
        ...UserFragment
      }
      token
      refreshToken
      csrfToken
      talkjsSignature
    }
  }
`

export const TOKEN_REFRESH = gql`
  ${USER_FRAGMENT}
  mutation TokenRefresh($token: String!) {
    tokenRefresh(refreshToken: $token) {
      accountErrors {
        field
        message
        code
      }
      user {
        ...UserFragment
      }
      token
      talkjsSignature
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      errors {
        field
        message
      }
    }
  }
`

export const SET_PASSWORD = gql`
  mutation SetPassword($email: String!, $token: String!, $password: String!) {
    setPassword(email: $email, token: $token, password: $password) {
      errors {
        field
        message
      }
      accountErrors {
        field
        message
        code
      }
      token
      refreshToken
    }
  }
`

export const ACCOUNT_ADDRESS_CREATE = gql`
  ${USER_FRAGMENT}
  mutation AccountAddressCreate($input: AddressInput!, $type: AddressTypeEnum) {
    accountAddressCreate(input: $input, type: $type) {
      address {
        ...AddressFragment
      }
      user {
        ...UserFragment
      }
      accountErrors {
        field
        message
        code
      }
    }
  }
`

export const ACCOUNT_ADDRESS_EDIT = gql`
  ${USER_FRAGMENT}
  mutation accountAddressUpdate($input: AddressInput!, $id: ID!) {
    accountAddressUpdate(input: $input, id: $id) {
      address {
        ...AddressFragment
      }
      user {
        ...UserFragment
      }
      accountErrors {
        field
        message
        code
      }
    }
  }
`

export const SET_DEFAULT_ADDRESS = gql`
  ${USER_FRAGMENT}
  mutation AccountSetDefaultAddress($id: ID!, $type: AddressTypeEnum!) {
    accountSetDefaultAddress(id: $id, type: $type) {
      user {
        ...UserFragment
      }
      accountErrors {
        field
        message
        code
      }
    }
  }
`

export const IS_USER_EXIST = gql`
  mutation IsUserExists($email: String!) {
    isUserExists(email: $email) {
      isUserExists
    }
  }
`

export const REQUEST_EMAIL_CHANGE = gql`
  mutation requestEmailChange(
    $password: String!
    $newEmail: String!
    $redirectUrl: String!
    $cancelUrl: String!
    $isDashboard: Boolean!
  ) {
    requestEmailChange(
      password: $password
      newEmail: $newEmail
      redirectUrl: $redirectUrl
      cancelUrl: $cancelUrl
      isDashboard: $isDashboard
    ) {
      user {
        email
      }
      errors {
        field
        message
      }
      accountErrors {
        code
        message
        field
      }
    }
  }
`
