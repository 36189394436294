import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 24
  },
  titleContainer: {
    backgroundColor: theme.colors.greyFon,
    padding: 16,
    borderRadius: 4
  },
  text: {
    marginTop: 8
  },
  button: {
    marginLeft: 24
  },
  checkboxText: {
    marginLeft: 16
  },
  content: {
    marginTop: 24
  },
  field: {
    marginTop: 16
  },
  leftColumn: {
    flex: 2,
    width: '66%'
  },
  rightColumn: {
    flex: 1,
    width: '33%',
    marginLeft: 24
  },
  section: {
    border: `1px solid ${theme.colors.shadow}`,
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  sectionAdditionContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: '100%'
  },
  sectionAdditionWithText: {
    padding: '0 16px 24px'
  },
  submitContainer: {
    background: theme.colors.white,
    bottom: 0,
    boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
    marginTop: 16,
    padding: 24,
    position: 'sticky'
  },
  title: {
    marginTop: 24
  },
  description: {
    marginTop: 8
  },
  optionButton: {
    marginTop: 20,
    maxWidth: 361
  },
  sliderItem: {
    marginLeft: 8
  },
  dot: {
    marginRight: 8
  },
  switcher: {
    width: '100%'
  },
  sectionAdditionItem: {
    marginTop: 0
  },
  sliderContent: {
    padding: 0,
    width: '100%'
  },
  sectionItem: {
    border: 'none',
    borderRadius: 0,
    marginTop: 0,
    '&:first-child': {
      borderTop: `1px solid ${theme.colors.shadow}`
    },
    '&:last-child': {
      borderRadius: '0px 0px 4px 4px'
    }
  },
  titleContainerItem: {
    paddingTop: 24
  }
}))
