import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  description: {
    marginTop: 24,
    textAlign: 'center'
  },
  secondaryDesctiption: {
    marginTop: 16,
    textAlign: 'center'
  }
})
