import React, { FC, useMemo } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../../column'
import { Text } from '../../text'
import { Color, LightTheme } from '../../Theme'
import { getDateWithStartTime } from '../helpers'
import { useStyle } from './calendar-number-item.styles'
import { CalendarNumberItemProps } from './calendar-number-item.types'

export const CalendarNumberItem: FC<CalendarNumberItemProps> = ({
  from,
  to,
  pickerType,
  monthDate,
  className = '',
  text,
  onClick
}) => {
  const month = monthDate.getMonth()
  const year = monthDate.getFullYear()
  const toTimeStamp = to && to.getTime()

  const FromDateTimestamp = useMemo(() => {
    if (from) {
      const fromStartTime = getDateWithStartTime(
        from.getFullYear(),
        from.getMonth(),
        from.getDate()
      )

      return fromStartTime.getTime()
    }

    return new Date().setHours(0, 0, 0)
  }, [from])

  const active = useMemo(() => {
    const currentDateTimestamp = getDateWithStartTime(
      year,
      month,
      Number(text)
    ).getTime()

    if (from) {
      return currentDateTimestamp === FromDateTimestamp
    }

    return false
  }, [from, toTimeStamp, month, year, text, from, to, pickerType])

  const activeColorText: Color = active ? 'white' : 'black'

  const theme = useTheme<LightTheme>()
  const classes = useStyle({ active, theme })

  if (!text) {
    return (
      <Column fullWidth className={`${className} ${classes.container}`}>
        <Text className={classes.text} text={text} />
      </Column>
    )
  }

  return (
    <Column
      fullWidth
      className={`${className} ${classes.container}`}
      onClick={onClick}
    >
      <Text className={classes.text} color={activeColorText} text={text} />
    </Column>
  )
}
