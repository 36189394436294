import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { ColorPickerStyles } from './color-picker.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ focus }: ColorPickerStyles) => ({
    padding: focus ? '3px 23px' : '4px 24px',
    border: focus
      ? `2px solid ${theme.colors.yellow}`
      : `1px solid ${theme.colors.inactive}`,
    borderRadius: 4
  }),
  colorsContainer: {
    borderRadius: 4,
    background: theme.colors.white,
    padding: '8px 12px',
    top: 0,
    left: 0,
    right: 0,
    position: 'absolute',
    border: `1px solid ${theme.colors.inactive}`,
    zIndex: 2
  },
  colorDot: {
    margin: 4
  },
  valueDot: ({ value }: ColorPickerStyles) => ({
    marginTop: 4,
    opacity: value ? 1 : 0
  }),
  valueText: {
    marginLeft: 8
  }
}))
