import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    marginLeft: 24
  },
  container: {
    flex: 1,
    padding: '24px 16px'
  },
  submitContainer: {
    background: theme.colors.white,
    bottom: 0,
    boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
    marginTop: 16,
    padding: 24,
    position: 'sticky',
    zIndex: 3
  }
}))
