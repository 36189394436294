import React, { FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  Button,
  Column,
  getTotalPriceWithCurrency,
  Icon,
  ICONS,
  Row,
  Section,
  Text
} from '../../../components'
import { LightTheme } from '../../../components/Theme'
import { useDebounceInput } from '../../../hooks'
import { useApi } from '../../../providers'
import { getItemsFromEdges, Product } from '../../../services'

import { ProductPickModal } from './product-pick-modal'
import { useStyle } from './advertising-product.styles'
import { AdvertisingProductViewProps } from './advertising-product.types'

const FIRST = 100

export const AdvertisingProductView: FC<AdvertisingProductViewProps> = ({
  children,
  productId: defaultProduct,
  product: productValue,
  onChange,
  onCreateProduct
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const { product: productApi } = useApi()
  const { data, refetch, fetchMore } = productApi.useProducts({
    first: FIRST
  })

  const Data = useMemo<Product[]>(() => {
    if (data) {
      return getItemsFromEdges(data.products)
    }

    return []
  }, [data?.products])

  const TotalCount = useMemo<number>(() => {
    if (data) {
      return data.products.totalCount
    }

    return 0
  }, [data?.products.totalCount])

  const ActiveProduct = useMemo(() => {
    return Data.find((product) => product.id === defaultProduct) || productValue
  }, [defaultProduct, Data])

  const [product, changeProduct] = useState<Product | undefined>(ActiveProduct)
  const [open, changeOpen] = useState(false)

  const defaultSearchValue = {
    ...product,
    first: FIRST,
    filter: {
      search: undefined
    }
  }

  const handleOnSearch = (search: string) => {
    if (refetch) {
      refetch({ first: FIRST, filter: { search } })
    }
  }

  const { onChange: onChangeSearch } = useDebounceInput({
    onChange: handleOnSearch
  })

  const handleOnChangeSearch = (value: string) => {
    onChangeSearch(value)
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnChange = (productNext: Product) => {
    const close = handleOnOpen(false)

    changeProduct(productNext)
    close()

    if (onChange) {
      onChange(productNext.id)
    }
  }

  const handleOnDelete = () => {
    changeProduct(undefined)

    if (onChange) {
      onChange('')
    }
  }

  const handleOnLoadMore = () => {
    if (data && fetchMore) {
      const { edges } = data.products
      const { cursor } = edges[edges.length - 1]

      fetchMore({ variables: { first: FIRST, after: cursor } })
    }
  }

  const handleOnRefresh = () => {
    if (refetch) {
      refetch({ first: FIRST })
    }
  }

  const handleOnCloseVendorPickModal = () => {
    const close = handleOnOpen(false)

    close()
    if (refetch) {
      refetch(defaultSearchValue)
    }
  }

  const totalPrice = product ? getTotalPriceWithCurrency(product) : '-'
  const src = product ? product.thumbnail2x.url : undefined

  const buttonsRow = (
    <Row>
      <Button
        className={classes.button}
        text="CREATE PRODUCT"
        tx="advertising.create.new.product"
        textColor="yellow"
        preset="fixedBorderButtonLong"
        onClick={onCreateProduct}
      />
      <Button
        className={classes.button}
        text="ASSIGN PRODUCT"
        tx="advertising.assign.product"
        textColor="yellow"
        preset="fixedBorderButtonLong"
        onClick={handleOnOpen(true)}
      />
    </Row>
  )

  return (
    <>
      <Section
        className={classes.section}
        descriptionTx="banner.text.fields.description"
        title="Choose from available"
        titleTx="advertising.section.choose.from.avalible"
        rightButton={buttonsRow}
      >
        <Column fullWidth alignItems="flex-start" justifyContent="flex-start">
          <Column fullWidth>
            <Row
              fullWidth
              className={classes.header}
              justifyContent="space-between"
            >
              <Row
                className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
                justifyContent="flex-start"
              >
                <Text
                  color="black"
                  preset="h6"
                  text="Product Name"
                  tx="item.product.name.title"
                />
              </Row>
              <Row className={classes.tableColumn} justifyContent="flex-start">
                <Text
                  color="black"
                  preset="h6"
                  text="Product Type"
                  tx="item.productType.title"
                />
              </Row>
              <Row className={classes.tableColumn} justifyContent="flex-start">
                <Text
                  color="black"
                  preset="h6"
                  text="Price"
                  tx="item.price.title"
                />
              </Row>
            </Row>
            {product ? (
              <Row fullWidth justifyContent="space-between">
                <Row
                  className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
                  justifyContent="flex-start"
                >
                  <img
                    className={classes.image}
                    height={48}
                    width={48}
                    src={src}
                  />
                  <Text
                    className={classes.text}
                    color="black"
                    preset="body"
                    text={product?.name}
                  />
                </Row>
                <Row
                  className={classes.tableColumn}
                  justifyContent="flex-start"
                >
                  <Text
                    color="black"
                    preset="body"
                    text={product?.productType.name}
                  />
                </Row>
                <Row
                  className={classes.tableColumn}
                  justifyContent="space-between"
                >
                  <Text color="black" preset="body" text={totalPrice} />
                  <Icon
                    className={classes.icon}
                    src={ICONS.close}
                    onClick={handleOnDelete}
                  />
                </Row>
              </Row>
            ) : (
              <Row fullWidth justifyContent="flex-start">
                <Text
                  className={classes.emptySectionText}
                  color="black"
                  preset="body"
                  text="No Items found"
                  tx="advertising.no.item.found"
                />
              </Row>
            )}
          </Column>
        </Column>
        {open && (
          <ProductPickModal
            defaultActive={ActiveProduct}
            data={Data}
            title="Assign Product"
            titleTx="advertising.assign.product.modal.title"
            totalCount={TotalCount}
            onLoadMore={handleOnLoadMore}
            onRefresh={handleOnRefresh}
            onClose={handleOnCloseVendorPickModal}
            onSubmit={handleOnChange}
            onSearch={handleOnChangeSearch}
          />
        )}
      </Section>
      {children}
    </>
  )
}
