import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Text, MenuSlider, Row, LightTheme, Column } from '../../../components'

import { AttributeInput } from '../attribute-input'

import { AttributeSliderProps } from './attribute-slider.types'

import { useStyle } from './attribute-slider.styles'

export const AttributeSlider: FC<AttributeSliderProps> = ({
  className = '',
  data,
  item = {},
  title,
  titleTx,
  onOpen,
  onChange
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const numberOfAttributes = `${data.length}`

  const attributeDefaultValue = (id: string) => {
    if (item.attributes) {
      return item.attributes.find((attribute) => attribute.attributeId === id)
    }
  }

  const handleOnChangeAttributeValues =
    (id: string) => (attributeValue: string) => {
      const { attributes: prevAttributes = [] } = item
      if (onChange) {
        const nextValue = {
          ...item,
          attributes: prevAttributes.map((attribute) => {
            const type =
              attribute.attributeId === id ? attributeValue : attribute.type

            return { ...attribute, type }
          })
        }

        onChange(nextValue)
      }
    }

  const header = (
    <Column alignItems="flex-start">
      <Text preset="h5" color="yellow" text={numberOfAttributes} />
      <Text preset="body" color="inactive" text={title} tx={titleTx} />
    </Column>
  )

  return (
    <MenuSlider
      className={`${className} ${classes.header}`}
      defaultOpen={true}
      header={header}
      onOpen={onOpen}
    >
      <Row fullWidth wrap justifyContent="space-between">
        {data.map((attribute) => (
          <AttributeInput
            key={`attribute_${attribute.id}`}
            className={classes.field}
            label={attribute.type}
            type={attribute.type}
            defaultValue={attributeDefaultValue(attribute.id)}
            onChange={handleOnChangeAttributeValues(attribute.id)}
          />
        ))}
      </Row>
    </MenuSlider>
  )
}
