export const DELIVEY_PRICE_FRAGMENT = `
  fragment DeliveryPriceFragment on DeliveryPrice {
    id
    weight
    price
  }
`

export const TOTAL_DELIVERY_PRICE_FRAGMENT = `
  fragment TotalDeliveryPriceFragment on TotalDeliveryPrice {
    id
    weightFrom
    weightTo
    price
  }
`
