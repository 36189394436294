import React, { FC } from 'react'

import { Column } from '../../../../components'

import { ProductPreviewFeaturesDetailsBlockOption } from '../features-details-block-option'

import { useStyle } from './product-preview-attributes.styles'
import { ProductPreviewAttributeProps } from './product-preview-attributes.types'

export const ProductPreviewAttributes: FC<ProductPreviewAttributeProps> = ({
  className = '',
  id,
  attributes
}) => {
  const classes = useStyle()

  return (
    <Column
      alignItems="flex-start"
      justifyContent="flex-start"
      fullWidth
      className={className}
    >
      {attributes &&
        attributes.map((attr) => (
          <ProductPreviewFeaturesDetailsBlockOption
            key={`product_${id}_material_${attr.id}`}
            className={classes.optionsRow}
            label={`${attr.attribute.title}:`}
            text={attr.type || '-'}
          />
        ))}
    </Column>
  )
}
