import { useMutation, useQuery } from '@apollo/client'

import { addFromQueryNewItems, removeFromQueryById } from '../update-cache'

import {
  GetOffer,
  GetOfferBulkDeleteResults,
  GetOfferByIdRequest,
  GetOfferDuplicateResults,
  GetOfferRemoveResults,
  GetOffers,
  GetOffersData,
  GetOfferUpdateResults,
  OfferApi,
  OfferBulkDelete,
  OfferBulkDeleteVariables,
  OfferByIdVariables,
  OfferDuplicate,
  OfferDuplicateVariables,
  OfferRemove,
  OfferRemoveVariables,
  OffersVariables,
  OfferUpdate,
  OfferUpdateVariables
} from './offer.types'
import {
  OFFERS_QUERY,
  OFFER_BULK_DELETE_MUTATION,
  OFFER_BY_ID,
  OFFER_DELETE_MUTATION,
  OFFER_DUPLICATE_MUTATION,
  OFFER_UPDATE_MUTATION
} from './offer.graphql'
import { GetAccountMeData } from '../auth'
import { USER_ME } from '../auth/auth.graphql'

export const offerService = (): OfferApi => {
  const useOffers = (variables: OffersVariables): GetOffers => {
    const { data, loading, error, refetch } = useQuery<
      GetOffersData,
      OffersVariables
    >(OFFERS_QUERY, { variables })

    return { data, error, loading, refetch }
  }

  const useOfferById = (variables: OfferByIdVariables): GetOfferByIdRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetOffer,
      OfferByIdVariables
    >(OFFER_BY_ID, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useOfferUpdate = (): OfferUpdate => {
    const [onOfferUpdate, response] = useMutation<
      GetOfferUpdateResults,
      OfferUpdateVariables
    >(OFFER_UPDATE_MUTATION)

    const handleOnSubmit = (variables: OfferUpdateVariables) => {
      const options = {
        variables
      }
      onOfferUpdate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOfferRemove = (): OfferRemove => {
    const [onRemoveOffer, response] = useMutation<
      GetOfferRemoveResults,
      OfferRemoveVariables
    >(OFFER_DELETE_MUTATION)

    const handleOnSubmit = (variables: OfferRemoveVariables) => {
      const options = {
        variables
      }

      onRemoveOffer({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetOffersData>({
            query: OFFERS_QUERY
          })
          if (queryResult) {
            cache.writeQuery({
              query: OFFERS_QUERY,
              data: removeFromQueryById(
                'offers',
                queryResult,
                result.data?.offerDelete.offer.id
              )
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOfferBulkDelete = (): OfferBulkDelete => {
    const [onOfferBuldDelete, response] = useMutation<
      GetOfferBulkDeleteResults,
      OfferBulkDeleteVariables
    >(OFFER_BULK_DELETE_MUTATION)

    const handleOnSubmit = (variables: OfferBulkDeleteVariables) => {
      const options = {
        variables
      }
      onOfferBuldDelete({
        ...options,
        update(cache) {
          const queryResult = cache.readQuery<GetOffersData>({
            query: OFFERS_QUERY
          })

          const userQueryResult = cache.readQuery<GetAccountMeData>({
            query: USER_ME
          })

          if (userQueryResult) {
            cache.writeQuery({
              query: USER_ME,
              data: {
                ...userQueryResult,
                me: {
                  ...userQueryResult.me,
                  offers: {
                    ...userQueryResult.me.offers,
                    totalCount:
                      userQueryResult.me.offers.totalCount -
                      variables.ids.length
                  }
                }
              }
            })
          }

          if (queryResult) {
            cache.writeQuery({
              query: OFFERS_QUERY,
              data: {
                ...queryResult,
                offers: {
                  ...queryResult.offers,
                  edges: queryResult.offers.edges.filter((offer) => {
                    const current = variables.ids.find(
                      (offerId) => offerId === offer.node.id
                    )

                    return !current
                  })
                }
              }
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useOfferDuplicate = (): OfferDuplicate => {
    const [onDuplicateOffer, response] = useMutation<
      GetOfferDuplicateResults,
      OfferDuplicateVariables
    >(OFFER_DUPLICATE_MUTATION)

    const handleOnSubmit = (variables: OfferDuplicateVariables) => {
      const options = {
        variables
      }
      onDuplicateOffer({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetOffersData>({
            query: OFFERS_QUERY
          })

          if (queryResult) {
            cache.writeQuery({
              query: OFFERS_QUERY,
              data: addFromQueryNewItems(
                'offers',
                queryResult,
                result.data?.offerDuplicate.offers
              )
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useOffers,
    useOfferById,
    useOfferUpdate,
    useOfferRemove,
    useOfferBulkDelete,
    useOfferDuplicate
  }
}
