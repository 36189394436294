import React, { FC, useState } from 'react'

import { ProductOptionVariantInput } from '../../../services'
import { Column, Row, Text } from '../../../components'

import { OfferOptionTableItem } from './offer-option-table-item'
import { useStyle } from './offer-option-table.styles'
import { OfferOptionTableProps } from './offer-option-table.types'

export const OfferOptionTable: FC<OfferOptionTableProps> = ({
  className = '',
  type,
  data,
  onChange
}) => {
  const [active, changeActive] = useState<number[]>([])
  const classes = useStyle()

  const handleOnChangeCheckbox = (index: number) => (state: boolean) => {
    if (onChange) {
      const nextValue = data.map((item, itemIndex) => {
        if (index === itemIndex) {
          return {
            ...item,
            isDefault: state
          }
        }
        return item
      })
      onChange(nextValue)
    }
    if (state) {
      return changeActive([...active, index])
    }

    const prevState = active.filter((prevIndex) => prevIndex !== index)
    return changeActive(prevState)
  }

  const handleOnChangeRadio =
    (defaultIndex: number, nextData?: ProductOptionVariantInput[]) => () => {
      const currentDataValue = nextData || data
      const nextDataValue = currentDataValue.map((material, index) => ({
        ...material,
        isDefault: index === defaultIndex
      }))

      if (onChange) {
        onChange(nextDataValue)
      }
    }

  const getActive = (index: number) => {
    const activeOption = active.find((item) => item === index)

    return typeof activeOption === 'number'
  }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Column fullWidth>
        <Row
          fullWidth
          className={classes.header}
          justifyContent="space-between"
        >
          <>
            <Column
              className={`${classes.tableColumn} ${classes.tableColumnMin}`}
            ></Column>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Text
                className={classes.text}
                color="black"
                preset="h6"
                text="Title"
              />
            </Row>
            <Row className={classes.bigTableColumn} justifyContent="flex-start">
              <Text
                className={classes.text}
                color="black"
                preset="h6"
                text="Description"
              />
            </Row>
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Text
                className={classes.text}
                color="black"
                preset="h6"
                text="Extra Price"
              />
            </Row>
            <Row className={classes.bigTableColumn} justifyContent="flex-start">
              <Text
                className={classes.text}
                color="black"
                preset="h6"
                text="Extra Production Days"
              />
            </Row>
          </>
        </Row>
        {data.map((material, index) => {
          return (
            <OfferOptionTableItem
              key={`material_${index}_${material.title}`}
              {...material}
              type={type}
              checked={getActive(index)}
              onChangeCheckbox={handleOnChangeCheckbox(index)}
              onChangeRadio={handleOnChangeRadio(index)}
            />
          )
        })}
      </Column>
    </Column>
  )
}
