import React, { FC } from 'react'

import { Column, Icon, ICONS, Section, TextField } from '../../../components'

import { useStyle } from '../offer-form.styles'
import { SummaryRow } from '../summary-row'

import { SummarySectionProps } from './summary-section.types'

export const SummarySection: FC<SummarySectionProps> = ({
  weight,
  quantity,
  deliveryPrice,
  defaultOfferPrice,
  totalPriceWithDelivery,
  totalPriceWithDiscount,
  currency,
  discountValue,
  productionDays,
  isDiscount,
  onChange,
  onChangeDiscount,
  onChangeProductionDays
}) => {
  const classes = useStyle()
  const dollarIcon = <Icon height={24} width={24} src={ICONS.attachMoney} />
  const totalPrice =
    Boolean(discountValue) && totalPriceWithDiscount
      ? totalPriceWithDiscount
      : totalPriceWithDelivery

  return (
    <Section
      className={classes.section}
      title="Summary"
      titleTx="offer.form.summary.title"
    >
      <Column
        className={classes.sectionAdditionContainer}
        alignItems="flex-start"
        fullWidth
      >
        <TextField
          label="Production Days"
          labelTx="offer.form.summary.production.days"
          defaultValue={productionDays}
          type="number"
          onChange={onChangeProductionDays}
        />
        <SummaryRow
          large={true}
          title="Item Quantity:"
          titleTx="offer.form.summary.units.quantity"
          price={quantity}
        />
        <SummaryRow
          large={true}
          title="Weight:"
          titleTx="offer.form.summary.weight"
          price={weight}
        />
        <SummaryRow
          large={true}
          title="Price:"
          titleTx="offer.form.summary.price"
          price={defaultOfferPrice}
          currency={currency}
        />
        <SummaryRow
          large={true}
          title="Delivery Price:"
          titleTx="offer.form.delivery.price"
          price={deliveryPrice}
          currency={currency}
        />
      </Column>
      <Column
        className={classes.sectionAdditionContainer}
        alignItems="flex-start"
        fullWidth
      >
        <SummaryRow
          className={classes.sectionAdditionItem}
          large={true}
          title="Discount"
          titleTx="offer.form.summary.discount"
          isDiscount={isDiscount}
          onChangeDiscount={onChangeDiscount}
        />
        {isDiscount && (
          <TextField
            className={classes.field}
            label="Discount amount"
            labelTx="offer.form.summary.discount.amount"
            type="number"
            defaultValue={discountValue}
            rightElement={dollarIcon}
            onChange={onChange}
          />
        )}
      </Column>

      <Column
        className={classes.sectionAdditionContainer}
        alignItems="flex-start"
      >
        <SummaryRow
          className={classes.sectionAdditionItem}
          large={true}
          title="Total Price:"
          titleTx="offer.form.summary.total.price"
          price={totalPrice}
          currency={currency}
        />
      </Column>
    </Section>
  )
}
