import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../Theme'

import { OrderStagingStyle } from './order-staging.types'

const getGradientProcent = (index: number, length: number) =>
  (index / length) * 100

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    paddingBottom: 16
  },
  progress: ({ left, right, active, length }: OrderStagingStyle) => {
    const { yellow, shadow } = theme.colors
    const startIndex = active - 1
    const endIndex = startIndex + 2 === length ? startIndex + 3 : startIndex + 2
    const start = getGradientProcent(startIndex, length)

    const end = getGradientProcent(endIndex, length)

    const backgroundImage = `linear-gradient(90deg, ${yellow} 0%, ${yellow} ${start}%, ${shadow} ${end}%, ${shadow} 100%)`
    return {
      left,
      right,
      background: backgroundImage,
      position: 'absolute',
      height: 4,
      top: 18,
      transition: 'background-position 1s ease-in-out'
    }
  },
  time: {
    cursor: 'default',
    top: 0,
    left: '25%',
    position: 'absolute'
  },
  hint: {
    width: 'auto'
  },
  hintText: {
    width: 304
  }
}))
