import React, { FC } from 'react'

import {
  Column,
  ICONS,
  LoadingContainer,
  Row,
  SortItem,
  TablePagginationBar,
  Text,
  TextField
} from '../../../components'
import { OrderDirection, UserSortField } from '../../../services'

import { StatisticTableItem } from '../statistic-table-item'

import { useStyle } from './statistic-table.styles'
import { StatisticTableProps } from './statistic-table.types'

export const StatisticTable: FC<StatisticTableProps> = ({
  className = '',
  data,
  loading,
  rowCounts,
  paginationState,
  onChangePage,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount,
  onChangeSearch,
  onSort
}) => {
  const classes = useStyle()

  const handleOnSort =
    (feedbackOrderField: UserSortField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(feedbackOrderField, direction)
      }
    }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          maxLength={200}
          label="Search"
          labelTx="statistic.table.header.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
      </Row>
      <Column fullWidth className={classes.table}>
        <Row className={classes.header} justifyContent="space-between">
          <Row
            className={`${classes.firstColumn} ${classes.tableColumn}`}
            justifyContent="flex-start"
          >
            <SortItem
              name="Company"
              nameTx="statistic.table.header.vendor"
              onClick={handleOnSort(UserSortField.COMPANY_NAME)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <Text
              color="black"
              preset="h6"
              text="Subscription Type"
              tx="statistic.table.header.subscriptionType"
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="No. of Items"
              nameTx="statistic.table.header.noItems"
              onClick={handleOnSort(UserSortField.ITEM_COUNT)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Country"
              nameTx="statistic.table.header.country"
              onClick={handleOnSort(UserSortField.COUNTRY)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="No. of Orders"
              nameTx="statistic.table.header.noOrders"
              onClick={handleOnSort(UserSortField.ORDER_COUNT)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="In Progress Orders"
              nameTx="statistic.table.header.inProgress"
              onClick={handleOnSort(UserSortField.IN_PROGRESS_ORDER_COUNT)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Completed Orders"
              nameTx="statistic.table.header.completed"
              onClick={handleOnSort(UserSortField.COMPLETED_ORDER_COUNT)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Canceled Orders"
              nameTx="statistic.table.header.canceled"
              onClick={handleOnSort(UserSortField.CANCELED_ORDER_COUNT)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Registration Date"
              nameTx="statistic.table.header.registrationDate"
              onClick={handleOnSort(UserSortField.DATE_JOINED)}
            />
          </Row>
          <Row className={classes.tableColumn} justifyContent="flex-start">
            <SortItem
              name="Last Login Date"
              nameTx="statistic.table.header.lastLoginDate"
              onClick={handleOnSort(UserSortField.LAST_LOGIN)}
            />
          </Row>
        </Row>
        <LoadingContainer loading={loading}>
          {data.map((statistic) => (
            <StatisticTableItem
              key={`statistic_${statistic.id}`}
              {...statistic}
            />
          ))}
        </LoadingContainer>
      </Column>
      {paginationState && (
        <TablePagginationBar
          rowCounts={rowCounts}
          paginationState={paginationState}
          onChangePage={onChangePage}
          onPrevioslyPage={onPrevioslyPage}
          onNextPage={onNextPage}
          onChangeRowCount={onChangeRowCount}
        />
      )}
    </Column>
  )
}
