export const getDateWithStartTime = (
  year: number,
  month: number,
  day: number
) => {
  const dayWithSetZeroHours = new Date(year, month, Number(day)).setHours(
    0,
    0,
    0
  )
  const nextDate = new Date(dayWithSetZeroHours)

  return nextDate
}
