import { TextProps } from '../../components'
import { PayoutPaymentStatusFilter } from '../../services'

export const getMessageByPayoutPaymentStatusFilter = (
  status: PayoutPaymentStatusFilter
): TextProps => {
  switch (status) {
    case PayoutPaymentStatusFilter.PROCESS:
      return {
        text: 'Process',
        tx: 'paymentStatus.process'
      }
    case PayoutPaymentStatusFilter.PENDING:
      return {
        text: 'Pending',
        tx: 'paymentStatus.pending'
      }
    case PayoutPaymentStatusFilter.BLOCKED:
      return {
        text: 'Blocked',
        tx: 'paymentStatus.blocked'
      }
    case PayoutPaymentStatusFilter.CANCELED:
      return {
        text: 'Canceled',
        tx: 'paymentStatus.canceled'
      }
    case PayoutPaymentStatusFilter.DENIED:
      return {
        text: 'Denied',
        tx: 'paymentStatus.denied'
      }
    case PayoutPaymentStatusFilter.FAILED:
      return {
        text: 'Failed',
        tx: 'paymentStatus.failed'
      }
    case PayoutPaymentStatusFilter.HELD:
      return {
        text: 'Held',
        tx: 'paymentStatus.held'
      }
    case PayoutPaymentStatusFilter.REFUNDED:
      return {
        text: 'Refunded',
        tx: 'paymentStatus.refunded'
      }
    case PayoutPaymentStatusFilter.RETURNED:
      return {
        text: 'Returned',
        tx: 'paymentStatus.returned'
      }
    case PayoutPaymentStatusFilter.SUCCEEDED:
      return {
        text: 'Succeeded',
        tx: 'paymentStatus.succeeded'
      }
    case PayoutPaymentStatusFilter.UNCLAIMED:
      return {
        text: 'Unclaimed',
        tx: 'paymentStatus.unclaimed'
      }
    default:
      return {
        text: 'Not Charged',
        tx: 'paymentStatus.notCharged'
      }
  }
}
