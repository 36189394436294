import React, { FC, useMemo, useState } from 'react'

import {
  Column,
  FilterList,
  FilterListByAlphabet,
  FilterRadioList,
  FiltersSidebar,
  Icon,
  ICONS,
  PermissionContent,
  RadioItemWithIdProps
} from '../../../components'
import { useAuth } from '../../../hooks'
import {
  PermissionEnum,
  ProductFilter,
  ProductFilterKeys
} from '../../../services'

import { useStyle } from './items-product-filters.styles'
import { ItemsProductFiltersProps } from './items-product-filters.types'

export const ItemsProductFilters: FC<ItemsProductFiltersProps> = ({
  className = '',
  filters,
  onSubmit,
  onReset
}) => {
  const { user } = useAuth()
  const [openSlider, changeOpenSlider] = useState<
    Partial<Record<ProductFilterKeys, boolean | undefined>>
  >({})

  const [filter, changeFilter] = useState<Partial<ProductFilter>>({})
  const [open, changeOpen] = useState(false)

  const classes = useStyle()

  const isDisabledFilter = () => {
    const disabled =
      filter.companyNames?.length ||
      filter.isTop?.valueOf ||
      filter.isPublished?.valueOf ||
      filter.productTypes?.length
    if (disabled) {
      return false
    }
    return true
  }

  const Statuses = useMemo<RadioItemWithIdProps[]>(
    () => [
      {
        id: '0',
        name: 'Inactive',
        nameTx: 'cabinet.products.filters.inactive'
      },
      {
        id: '1',
        name: 'Active',
        nameTx: 'cabinet.products.filters.active'
      }
    ],
    []
  )

  const IsTopStatuses = useMemo<RadioItemWithIdProps[]>(
    () => [
      {
        id: '0',
        name: 'Not Top Product',
        nameTx: 'cabinet.products.filters.active'
      },
      {
        id: '1',
        name: 'Top Product',
        nameTx: 'cabinet.products.filters.inactive'
      }
    ],
    []
  )

  const handleOnChange = (prop: ProductFilterKeys) => (value?: string[]) => {
    changeFilter({ ...filter, [prop]: value })
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    if (onSubmit) {
      onSubmit(filter)
    }
    close()
  }

  const handleOnChangeStatus =
    (prop: ProductFilterKeys) => (active?: string) => {
      const value = Boolean(Number(active))
      changeFilter({ ...filter, [prop]: value })
    }

  const handleOnOpenSlider = (prop: ProductFilterKeys) => (state: boolean) => {
    changeOpenSlider({ ...openSlider, [prop]: state })
  }

  const handleOnReset = () => {
    const close = handleOnOpen(false)

    changeFilter({})
    changeOpenSlider({})
    if (onReset) {
      onReset()
    }
    close()
  }

  return (
    <Column
      className={`${className} ${classes.container}`}
      onClick={handleOnOpen(true)}
    >
      <Icon src={ICONS.filterList} height={32} width={32} />
      <FiltersSidebar
        open={open}
        onClose={handleOnOpen(false)}
        onApply={handleOnSubmit}
        onReset={handleOnReset}
        isDisabled={isDisabledFilter()}
      >
        {open && (
          <Column fullWidth>
            <PermissionContent
              permissions={[
                PermissionEnum.VIEW_ITEM,
                PermissionEnum.ADD_CHANGE_REMOVE_ITEMS
              ]}
            >
              {user && (
                <>
                  <FilterListByAlphabet
                    className={classes.slider}
                    defaultValue={filter.companyNames}
                    defaultOpen={openSlider.companyNames}
                    data={filters.companyNames}
                    name="companyNames"
                    title="Company"
                    titleTx="cabinet.products.filters.company"
                    onOpen={handleOnOpenSlider('companyNames')}
                    onChange={handleOnChange('companyNames')}
                  />
                </>
              )}
            </PermissionContent>
            <FilterRadioList
              className={classes.slider}
              active={String(Number(filter.isPublished))}
              defaultOpen={openSlider.isPublished}
              data={Statuses}
              title="Item Status"
              titleTx="cabinet.products.filters.status"
              onOpen={handleOnOpenSlider('isPublished')}
              onChange={handleOnChangeStatus('isPublished')}
            />
            <FilterRadioList
              className={classes.slider}
              active={String(Number(filter.isTop))}
              defaultOpen={openSlider.isTop}
              data={IsTopStatuses}
              title="Top Status"
              titleTx="cabinet.products.filters.topStatus"
              onOpen={handleOnOpenSlider('isTop')}
              onChange={handleOnChangeStatus('isTop')}
            />
            <FilterList
              className={classes.slider}
              defaultValue={filter.productTypes}
              defaultOpen={openSlider.productTypes}
              data={filters.productTypes}
              name="productTypes"
              title="Product Type"
              titleTx="cabinet.products.filters.productType"
              onOpen={handleOnOpenSlider('productTypes')}
              onChange={handleOnChange('productTypes')}
            />
          </Column>
        )}
      </FiltersSidebar>
    </Column>
  )
}
