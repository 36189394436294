import React, { FC, useMemo, useState } from 'react'

import {
  CalendarSlider,
  Column,
  FilterList,
  FilterListByAlphabet,
  FiltersSidebar,
  getGraphDate,
  Icon,
  ICONS
} from '../../../components'
import { VendorFilterInput, VendorFilterInputKeys } from '../../../services'

import { useStyle } from './statistic-filters.styles'
import { StatisticFiltersProps } from './statistic-filters.types'

export const StatisticFilters: FC<StatisticFiltersProps> = ({
  filters,
  onSubmit,
  onReset
}) => {
  const [openSlider, changeOpenSlider] = useState<
    Partial<Record<VendorFilterInputKeys, boolean | undefined>>
  >({})

  const [filter, changeFilter] = useState<Partial<VendorFilterInput>>({})
  const classes = useStyle()
  const [open, changeOpen] = useState(false)

  const dateValue = useMemo(() => {
    if (filter.dateJoined) {
      const { gte, lte } = filter.dateJoined

      if (gte && lte) {
        return [new Date(gte), new Date(lte)]
      }

      if (gte) {
        return [new Date(gte)]
      }
    }

    return undefined
  }, [filter.dateJoined])

  const handleOnChange =
    (prop: VendorFilterInputKeys) => (value?: string[]) => {
      changeFilter({ ...filter, [prop]: value })
    }

  const handleOnChangeDate = (from: string, to?: string) => {
    if (from) {
      changeFilter({
        ...filter,
        dateJoined: {
          gte: getGraphDate(from),
          lte: to ? getGraphDate(to) : undefined
        }
      })
    } else {
      changeFilter({
        ...filter,
        dateJoined: undefined
      })
    }
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    if (onSubmit) {
      onSubmit(filter)
    }
    close()
  }
  const isDisabledFilter = () => {
    const disabled =
      filter.country?.length ||
      filter.companyNames?.length ||
      filter.dateJoined ||
      filter.subscription?.length
    if (disabled) {
      return false
    }
    return true
  }

  const handleOnOpenSlider =
    (prop: VendorFilterInputKeys) => (state: boolean) => {
      changeOpenSlider({ ...openSlider, [prop]: state })
    }

  const handleOnReset = () => {
    const close = handleOnOpen(false)

    changeFilter({})
    changeOpenSlider({})
    if (onReset) {
      onReset()
    }
    close()
  }

  return (
    <Column className={classes.container} onClick={handleOnOpen(true)}>
      <Icon src={ICONS.filterList} height={32} width={32} />
      <FiltersSidebar
        open={open}
        onClose={handleOnOpen(false)}
        onApply={handleOnSubmit}
        onReset={handleOnReset}
        isDisabled={isDisabledFilter()}
      >
        {open && (
          <Column fullWidth>
            <CalendarSlider
              className={classes.slider}
              defaultOpen={openSlider.dateJoined}
              title="Registration Date"
              titleTx="statistic.filter.registrationDate"
              value={dateValue}
              onOpen={handleOnOpenSlider('dateJoined')}
              onChange={handleOnChangeDate}
            />
            <FilterListByAlphabet
              className={classes.slider}
              defaultValue={filter.companyNames}
              defaultOpen={openSlider.companyNames}
              data={filters.companyNames}
              name="companyName"
              title="Company"
              titleTx="orderControl.filters.company"
              onOpen={handleOnOpenSlider('companyNames')}
              onChange={handleOnChange('companyNames')}
            />
            <FilterListByAlphabet
              className={classes.slider}
              defaultValue={filter.country}
              defaultOpen={openSlider.country}
              data={filters.countries}
              name="country"
              title="Country"
              titleTx="orderControl.filters.country"
              onOpen={handleOnOpenSlider('country')}
              onChange={handleOnChange('country')}
            />
            <FilterList
              className={classes.slider}
              defaultValue={filter.subscription}
              defaultOpen={openSlider.subscription}
              data={filters.subscriptionTypes}
              name="subscription"
              title="Subscription Type"
              titleTx="orderControl.filters.subscription"
              onOpen={handleOnOpenSlider('subscription')}
              onChange={handleOnChange('subscription')}
            />
          </Column>
        )}
      </FiltersSidebar>
    </Column>
  )
}
