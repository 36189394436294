import React, { FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import {
  arrayHasElements,
  Button,
  Checkbox,
  Column,
  Icon,
  ICONS,
  LoadingContainer,
  Row,
  TablePagginationBar,
  Text,
  TextField,
  LightTheme,
  QuestionConfirmModal,
  SortItem,
  EmptySection,
  PermissionContent
} from '../../../components'

import { CabinetNavigatorPathVendor } from '../../cabinet-navigator'

import { TableItemsCollection } from '../table-items'

import { useStyle } from './table-collections.styles'
import { TableCollectionsProps } from './table-collections.types'
import { activateCollectionItem } from './helpers'
import {
  CollectionSortField,
  OrderDirection,
  PermissionEnum
} from '../../../services'

export const TableCollections: FC<TableCollectionsProps> = ({
  className = '',
  children,
  loading,
  data,
  rowCounts = [],
  paginationState,
  onNextPage,
  onPrevioslyPage,
  onChangeSearch,
  onChangeRowCount,
  onRemove,
  onUpdate,
  onSort
}) => {
  const history = useHistory()
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })
  const [removeIds, changeRemoveIds] = useState<string[]>([])
  const [openDelete, changeOpenDelete] = useState(false)

  const checkedItemsCount = removeIds.length
  const checkedItemsCountValue = { checkedItemsCount }
  const checkedItemText = `Selected ${checkedItemsCount} items`

  const handleOnSort =
    (colectionsField: CollectionSortField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(colectionsField, direction)
      }
    }

  const handleOnOpenDeleteCollection = (state: boolean) => () => {
    changeOpenDelete(state)
  }

  const handleOnOpenMoldalDelete = () => {
    const openModalDelete = handleOnOpenDeleteCollection(true)

    openModalDelete()
  }

  const handleOnClickItem = (id: string) => {
    history.push(`${CabinetNavigatorPathVendor.EDIT_COLLECTIONS}/${id}`)
  }

  const handleOnRemove = () => {
    const closeModalDelete = handleOnOpenDeleteCollection(false)

    if (onRemove) {
      closeModalDelete()
      changeRemoveIds([])
      onRemove(removeIds)
    }
  }

  const handleOnPublish = (state: boolean) => () => {
    const publishData = data
      .filter((item) => removeIds.includes(item.id))
      .map(activateCollectionItem(state))
    if (onUpdate && publishData.length) {
      onUpdate(publishData)
    }
  }

  const handleOnPick = (id: string, state: boolean) => {
    const filteredIds = removeIds.filter((removeId) => removeId !== id)
    if (state) {
      changeRemoveIds([...filteredIds, id])
    } else {
      changeRemoveIds(filteredIds)
    }
  }

  const handleOnPickAll = (state: boolean) => {
    if (state) {
      const ids = data.map((product) => product.id)
      changeRemoveIds(ids)
    } else {
      changeRemoveIds([])
    }
  }

  const PickedAll = useMemo(() => {
    return removeIds.length === data.length
  }, [removeIds, data])

  const getActive = (id: string) => {
    return Boolean(removeIds.find((removeId) => removeId === id))
  }

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      className={`${className} ${classes.container}`}
      justifyContent="flex-start"
    >
      <Row fullWidth className={classes.tableTopContainer}>
        <TextField
          className={classes.search}
          preset="border"
          label="Search"
          labelTx="cabinet.banners.search"
          leftIcon={ICONS.search}
          onChange={onChangeSearch}
        />
        {children}
      </Row>
      <Column fullWidth>
        {arrayHasElements(removeIds) ? (
          <Row
            fullWidth
            className={classes.header}
            justifyContent="space-between"
          >
            <Row className={classes.tableColumn} justifyContent="flex-start">
              <Checkbox checked={PickedAll} onChange={handleOnPickAll} />
              <Text
                className={classes.textCheckBox}
                preset="body"
                text={checkedItemText}
                tx="item.table.selected.items"
                values={checkedItemsCountValue}
              />
            </Row>
            <Row className={classes.buttonContainer}>
              <Button
                className={classes.button}
                preset="secondaryBorderMini"
                text="Activate"
                textPreset="maxButton"
                textColor="yellow"
                tx="item.table.activate.btn"
                onClick={handleOnPublish(true)}
              />
              <Button
                className={classes.button}
                preset="secondaryBorderMini"
                text="Deactivate"
                textPreset="maxButton"
                textColor="yellow"
                tx="item.table.deactivate.btn"
                onClick={handleOnPublish(false)}
              />
              <Row className={classes.remove}>
                <Row className={classes.icon}>
                  <Icon src={ICONS.trash} onClick={handleOnOpenMoldalDelete} />
                </Row>
                {openDelete && (
                  <QuestionConfirmModal
                    title="Delete Collection"
                    titleTx="collection.modal.delete.title"
                    description="Are you sure?"
                    descriptionTx="collection.modal.delete.description"
                    secondaryDescription="Delete your collection? This cannot be undone."
                    secondaryDescriptionTx="collection.modal.delete.secondaryDescription"
                    onClose={handleOnOpenDeleteCollection(false)}
                    onSubmit={handleOnRemove}
                  />
                )}
              </Row>
            </Row>
          </Row>
        ) : (
          <Row fullWidth className={classes.header} justifyContent="flex-start">
            <Row className={classes.row} justifyContent="flex-start">
              <PermissionContent
                permissions={[
                  PermissionEnum.EDIT_ITEM,
                  PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
                ]}
              >
                <Row
                  className={classes.tableColumn}
                  justifyContent="flex-start"
                >
                  <Checkbox checked={PickedAll} onChange={handleOnPickAll} />
                </Row>
              </PermissionContent>
              <Row className={classes.secondColumn} justifyContent="flex-start">
                <SortItem
                  preset="h6"
                  name="Name"
                  nameTx="item.name.title"
                  onClick={handleOnSort(CollectionSortField.NAME)}
                />
              </Row>
            </Row>

            <Row className={classes.thirdColumn} justifyContent="flex-start">
              <Text
                color="black"
                preset="h6"
                text="Status"
                tx="item.status.title"
              />
            </Row>
            <Row className={classes.thirdColumn} justifyContent="flex-start">
              <Text color="black" preset="h6" text="No of products" />
            </Row>
          </Row>
        )}

        <LoadingContainer loading={loading}>
          {data.map((collection) => (
            <TableItemsCollection
              key={`banner_${collection.id}`}
              {...collection}
              checked={getActive(collection.id)}
              onClick={handleOnClickItem}
              onChange={handleOnPick}
            />
          ))}
          {!arrayHasElements(data) && (
            <EmptySection
              text="No Collections found."
              tx="item.collections.no.collections.found"
            />
          )}
        </LoadingContainer>
      </Column>
      {paginationState && (
        <TablePagginationBar
          rowCounts={rowCounts}
          paginationState={paginationState}
          onPrevioslyPage={onPrevioslyPage}
          onNextPage={onNextPage}
          onChangeRowCount={onChangeRowCount}
        />
      )}
    </Column>
  )
}
