import React, { forwardRef } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../column'
import { Text } from '../text'
import { LightTheme } from '../Theme'
import { useStyle } from './filter-container.styles'
import { FilterContainerProps } from './filter-container.types'

export const FilterContainer = forwardRef<HTMLDivElement, FilterContainerProps>(
  ({ title, titleTx, className = '', children }, ref) => {
    const theme: LightTheme = useTheme()
    const classes = useStyle({ theme })

    return (
      <Column
        alignItems="flex-start"
        className={`${className} ${classes.filters}`}
        justifyContent="flex-start"
        ref={ref}
      >
        {title && <Text text={title} tx={titleTx} preset="h4" />}
        <Column className={classes.content}>{children}</Column>
      </Column>
    )
  }
)
