import moment from 'moment'
import React, { FC, useRef, useState } from 'react'
import { useTheme } from 'react-jss'
import { useOutsideAlerter } from '../../hooks'

import { Calendar } from '../calendar'
import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { Color, LightTheme } from '../Theme'
import { useStyle } from './date-picker.styles'
import { DatePickerProps } from './date-picker.types'

export const DatePicker: FC<DatePickerProps> = ({
  className = '',
  defaultValue,
  label,
  labelTx,
  onChange
}) => {
  const [focus, changeFocus] = useState(false)
  const [value, changeValue] = useState<Date | undefined>(defaultValue)
  const theme: LightTheme = useTheme()
  const classes = useStyle({ focus, theme })

  const calendarRef = useRef<HTMLDivElement>(null)

  const handleOnChangeDate = (day: Date) => {
    changeValue(day)
    if (onChange) {
      onChange(day)
    }
  }

  const handleOnFocus = (state: boolean) => () => {
    changeFocus(state)
  }

  useOutsideAlerter(calendarRef, focus, handleOnFocus(false))

  const valueColor: Color = value ? 'black' : 'shadow'
  const stringValue = value ? moment(value).format('MM/DD/YY') : 'mm/dd/yyy'

  return (
    <Row
      fullWidth
      className={`${className} ${classes.container}`}
      justifyContent="space-between"
      onClick={handleOnFocus(true)}
    >
      <Column alignItems="flex-start" justifyContent="flex-start">
        <Text
          color="yellow"
          preset="subscriptionText"
          text={label}
          tx={labelTx}
        />
        <Text color={valueColor} text={stringValue} />
      </Column>
      <Icon src={ICONS.dateRange} />
      {focus && (
        <Column className={classes.calendar} ref={calendarRef}>
          <Calendar value={value} onChange={handleOnChangeDate} />
        </Column>
      )}
    </Row>
  )
}
