import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'

import { FetchMore } from '../api.types'

import {
  FeedbackCreate,
  FeedbackApi,
  FeedbackCreateInputVariables,
  FeedbackCreateData,
  GetFeedbacks,
  GetFeedbacksData,
  FeedbackUpdate,
  FeedbackUpdateResults,
  FeedbackUpdateInputVariables,
  FeedbackVariables
} from './feedback.types'

import {
  FEEDBACKS_QUERY,
  FEEDBACK_CREATE_MUTATION,
  FEEDBACK_UPDATE_MUTATION
} from './feedback.graphql'

export const feedbackService = (): FeedbackApi => {
  const useCreateFeedback = (): FeedbackCreate => {
    const [RquestCreateFeedbackSubmit, response] = useMutation<
      FeedbackCreateData,
      FeedbackCreateInputVariables
    >(FEEDBACK_CREATE_MUTATION)

    const handleOnSubmit = (variables: FeedbackCreateInputVariables) => {
      if (variables) {
        const options = {
          variables
        }

        RquestCreateFeedbackSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }
  const useFeedbacks = (variables: FeedbackVariables): GetFeedbacks => {
    const [loading, changeLoading] = useState(true)
    const [loadingMore, changeLoadingMore] = useState(true)
    const { data, refetch, fetchMore } = useQuery<
      GetFeedbacksData,
      FeedbackVariables
    >(FEEDBACKS_QUERY, {
      variables,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-and-network',
      onCompleted: () => {
        changeLoading(false)
        changeLoadingMore(false)
      },
      onError: () => {
        changeLoading(false)
        changeLoadingMore(false)
      }
    })

    useEffect(() => {
      if (data?.feedbacks.edges) {
        changeLoading(false)
      }
    }, [data?.feedbacks.edges])

    const handleOnRefetch = (refetchVariables?: Partial<FeedbackVariables>) => {
      changeLoading(true)
      changeLoadingMore(true)

      refetch(refetchVariables)
    }
    const handleOnFetchMore: FetchMore<GetFeedbacksData, FeedbackVariables> = (
      fetchMoreVariables
    ) => {
      changeLoadingMore(true)
      return fetchMore(fetchMoreVariables)
    }

    return {
      data,
      loading,
      loadingMore,
      refetch: handleOnRefetch,
      fetchMore: handleOnFetchMore
    }
  }

  const useUpdateFeedback = (): FeedbackUpdate => {
    const [onUpdateFeedback, response] = useMutation<
      FeedbackUpdateResults,
      FeedbackUpdateInputVariables
    >(FEEDBACK_UPDATE_MUTATION)

    const handleOnSubmit = (variables: FeedbackUpdateInputVariables) => {
      const options = {
        variables
      }
      onUpdateFeedback(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useFeedbacks,
    useCreateFeedback,
    useUpdateFeedback
  }
}
