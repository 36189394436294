import React, { FC } from 'react'

import {
  Dropdown,
  DropdownItemData,
  isError,
  Row,
  Section,
  Slider,
  Text
} from '../../../components'
import { ProductCreateInput } from '../../../services'

import { useStyle } from './delivery-details-section.styles'
import { DeliveryDetailsProps } from './delivery-details-section.types'

export const DeliveryDetailsSection: FC<DeliveryDetailsProps> = ({
  data,
  value,
  error,
  onChangeValue
}) => {
  const classes = useStyle()

  const handleOnChangeDropdown =
    (prop: keyof ProductCreateInput) => (dropdownItem: DropdownItemData) => {
      const { value: nextValue } = dropdownItem

      if (onChangeValue) {
        onChangeValue({
          ...value,
          [prop]: String(nextValue)
        })
      }
    }

  const handleOnIsFragile = (isFragile: boolean) => {
    if (onChangeValue) {
      onChangeValue({
        ...value,
        isFragile
      })
    }
  }

  return (
    <Section
      className={classes.section}
      title="Delivery Details"
      titleTx="item.form.delivery.title"
    >
      <Row className={classes.weightDropdown}>
        <Dropdown
          data={data}
          defaultValue={value.deliveryPrice}
          error={isError(value.deliveryPrice, error)}
          preset="body"
          label="Item Weight"
          labelTx="item.form.delivery.weight"
          onChange={handleOnChangeDropdown('deliveryPrice')}
        />
        <Row>
          <Text text="Fragile" tx="item.form.delivery.fragile" preset="body" />
          <Slider
            className={classes.sliderItem}
            defaultValue={value.isFragile}
            onChange={handleOnIsFragile}
          />
        </Row>
      </Row>
    </Section>
  )
}
