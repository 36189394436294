import React, { FC } from 'react'

import { Button } from '../button/button'

import { ButtonLinkProps } from './button-link.types'

export const ButtonLink: FC<ButtonLinkProps> = ({
  className = '',
  link,
  disabled,
  preset,
  textColor,
  textPreset,
  text,
  tx
}) => {
  const handleOnOpenLink = () => {
    window.open(link, '_blank')
  }

  return (
    <Button
      className={className}
      disabled={disabled}
      preset={preset}
      text={text}
      textPreset={textPreset}
      textColor={textColor}
      tx={tx}
      onClick={handleOnOpenLink}
    />
  )
}
