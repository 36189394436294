import React, { FC } from 'react'

import {
  Checkbox,
  Column,
  Row,
  Text,
  PermissionContent,
  SortItem
} from '../../../components'
import {
  OrderDirection,
  PermissionEnum,
  ProductOrderField
} from '../../../services'

import { useStyle } from './items-table-header-default.styles'
import { ItemsTableHeaderDeafaultProps } from './items-table-header-default.types'

export const ItemsTableHeaderDefault: FC<ItemsTableHeaderDeafaultProps> = ({
  className = '',
  pickedAll,
  onPickAll,
  onSort
}) => {
  const classes = useStyle()

  const handleOnSort =
    (itemsOrderField: ProductOrderField) => (state: boolean) => {
      if (onSort) {
        const direction = state ? OrderDirection.DESC : OrderDirection.ASC
        onSort(itemsOrderField, direction)
      }
    }

  return (
    <Row fullWidth className={className} justifyContent="space-between">
      <Row className={classes.tableItem} justifyContent="flex-start">
        <PermissionContent
          permissions={[
            PermissionEnum.EDIT_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
        >
          <Checkbox checked={pickedAll} onChange={onPickAll} />
        </PermissionContent>
        <Column className={classes.avatar} />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
        justifyContent="flex-start"
      >
        <SortItem
          preset="h6"
          name="Name"
          nameTx="item.name.title"
          onClick={handleOnSort(ProductOrderField.NAME)}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <PermissionContent
          permissions={[
            PermissionEnum.VIEW_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_ITEMS
          ]}
        >
          <Text
            color="black"
            preset="h6"
            text="Company"
            tx="item.company.title"
          />
        </PermissionContent>
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <SortItem
          preset="h6"
          name="Product Type"
          nameTx="item.productType.title"
          onClick={handleOnSort(ProductOrderField.TYPE)}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="h6" text="Status" tx="item.status.title" />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <SortItem
          preset="h6"
          name="Price"
          nameTx="item.price.title"
          onClick={handleOnSort(ProductOrderField.PRICE)}
        />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnLast}`}
        justifyContent="flex-start"
      >
        <Text
          color="black"
          preset="h6"
          text="Top Status"
          tx="item.top.status"
        />
      </Row>
      <Row className={classes.emptyRow}></Row>
    </Row>
  )
}
