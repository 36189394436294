import { GetListHeightProps } from './dropdown-items.types'

export const ITEM_HEIGHT = 48
export const TOP_BOTTOM_PADDINGS = 16

export const getListHeight = ({ data }: GetListHeightProps): number => {
  if (data.length) {
    return data.length
      ? data.length * ITEM_HEIGHT + TOP_BOTTOM_PADDINGS
      : ITEM_HEIGHT
  }
  return 0
}
