import React, { FC } from 'react'

import { Section } from '../../../components'
import { OfferOptionTable } from '../../../pages/views'

import { useStyle } from './offer-material-section.styles'
import { OfferMaterialSectionProps } from './offer-material-section.types'

export const OfferMaterialSection: FC<OfferMaterialSectionProps> = ({
  value,
  onChangeTable
}) => {
  const classes = useStyle()

  return (
    <Section
      className={classes.sectionItem}
      titleContainerClassName={classes.titleContainerItem}
      title="Material"
      titleTx="item.form.material.title"
      titlePreset="h4"
    >
      {value.productMaterials && (
        <OfferOptionTable
          data={value.productMaterials}
          onChange={onChangeTable}
        />
      )}
    </Section>
  )
}
