import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { TextAreaStyleProps } from './text-area.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    resize: 'none'
  },
  icon: {
    cursor: 'pointer',
    marginLeft: 8
  },
  textArea: {
    ...theme.typography.body,
    outline: 'none',
    border: 'none',
    background: theme.colors.transparent,
    color: theme.colors.black,
    fontFamily: 'inherit',
    letterSpacing: ({ password }) => (password ? '8px' : 'normal'),
    marginTop: 4,
    minHeight: 56,
    height: ({ height }) => height,
    padding: 0,
    width: '100%',
    resize: 'none',
    boxSizing: 'border-box',
    overflow: 'hidden',
    '&::placeholder': {
      opacity: 0
    },
    '&:focus::placeholder': {
      opacity: 0.7
    }
  },
  areaLabel: {
    transform: ({ focus, value }: TextAreaStyleProps) =>
      !focus && !value && 'translate(0px, 13px) scale(1.15)',
    transformOrigin: '0%',
    transition:
      'transform-origin 0.25s ease-in-out, transform 0.25s ease-in-out'
  },
  // TextArea presets
  border: {
    border: ({ error }: TextAreaStyleProps) =>
      error
        ? `1px solid ${theme.colors.error}`
        : `1px solid ${theme.colors.inactive}`,
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 23px',
    width: '100%',
    '&:focus-within': {
      border: `2px solid ${theme.colors.yellow}`,
      padding: '3px 22px',
      width: '100%'
    }
  },
  'bottom-border': {
    borderBottom: `2px solid ${theme.colors.black}`,
    cursor: 'text',
    padding: '0px 4px',
    width: '100%',
    '&:focus-within': {
      borderBottom: `2px solid ${theme.colors.yellow}`,
      padding: '0px 4px',
      width: '100%'
    }
  },
  main: {
    borderRadius: 4,
    cursor: 'text',
    padding: '4px 16px',
    width: 'calc(100% - 32px)'
  }
}))
