import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    width: '1216px !important',
    maxHeight: '800px !important',
    height: 'calc(100vh - 144px)'
  },
  content: {
    position: 'relative',
    width: 1216,
    padding: 24,
    rowGap: 16,
    height: 'calc(100vh - 224px)',
    maxHeight: 720,
    overflowY: 'auto',
    '&::-webkit-scrollbar-thumb': {
      borderBottom: `4px solid ${theme.colors.white}`,
      borderRadius: '0 0 4px'
    }
  },
  bottom: {
    width: 'calc(100% - 32px)',
    padding: '24px 16px',
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  loader: {
    top: '40% !important'
  },
  '@media screen and (max-width: 1280px)': {
    container: {
      height: 'calc(100vh - 80px)'
    },
    content: {
      height: 'calc(100vh - 160px)'
    }
  },
  '@media screen and (max-width: 1152px)': {
    container: {
      width: 'calc(100vw - 80px) !important'
    },
    content: {
      width: 'calc(100vw - 80px)'
    }
  }
}))
