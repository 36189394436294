import React, { FC } from 'react'

import { Row } from '../row'
import { Text } from '../text'

import { EmptySectionProps } from './empty-section.types'
import { useStyle } from './empty-section.styles'

export const EmptySection: FC<EmptySectionProps> = ({
  className,
  text,
  color = 'inactive',
  tx
}) => {
  const classes = useStyle()

  return (
    <Row
      fullWidth
      className={`${classes.container} ${className}`}
      justifyContent="flex-start"
    >
      <Text color={color} preset="body" text={text} tx={tx} />
    </Row>
  )
}
