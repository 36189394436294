import { Avatar, Chip, Container, Menu, MenuItem } from '@material-ui/core'
import classNames from 'classnames'
import React, { useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { AuthRoutes } from '../../auth/urls'
import { useOutsideAlerter } from '../../hooks'
import { useUser } from '../../hooks/useUser'
import { ArrowDropdown } from '../../icons/ArrowDropdown'
import { CabinetNavigatorPathVendor } from '../../pages'

import { Column } from '../column'
import { Row } from '../row'

import { useStyle } from './header-menu.styles'

export const appLoaderHeight = 4

export const HeaderMenu = () => {
  const history = useHistory()
  const classes = useStyle()
  const { logout, user } = useUser()
  const menuRef = useRef<HTMLDivElement>(null)
  const anchor = useRef<HTMLDivElement>(null)
  const appHeaderAnchor = React.useRef<HTMLDivElement>(null)
  const [isMenuOpened, setMenuState] = useState(false)

  const avatarClasses = {
    avatar: classes.avatar
  }

  if (!user) {
    return <></>
  }

  const cancelUrl = () => {
    const clearHistory = user.isStaff
      ? history.replace(AuthRoutes.SIGN_IN_ADMIN)
      : history.replace(AuthRoutes.SIGN_IN)
    return clearHistory
  }
  const userAvatar = user.avatar && <Avatar alt="user" src={user.avatar.url} />
  const emailLabel = (
    <>
      {user.email}
      <ArrowDropdown
        className={classNames(classes.arrow, {
          [classes.rotate]: isMenuOpened
        })}
      />
    </>
  )

  const handleOnClickAway = () => {
    setMenuState(false)
  }

  const handleOnOpenMenu = () => setMenuState(!isMenuOpened)

  const handleViewerProfile = () => {
    setMenuState(false)

    const isAdminLink = user.isStaff
      ? history.push(CabinetNavigatorPathVendor.PROFILE_SETTINGS_GENERAL)
      : history.push(CabinetNavigatorPathVendor.PROFILE_SETTINGS)

    return isAdminLink
  }

  const handleLogout = () => {
    setMenuState(false)
    if (logout) {
      logout()
      cancelUrl()
    }
  }

  useOutsideAlerter(menuRef, isMenuOpened, handleOnClickAway)

  const avatar = userAvatar || undefined

  return (
    <Row fullWidth className={classes.viewContainer}>
      <Container>
        <Row
          className={classes.header}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <div ref={appHeaderAnchor} />
          <div className={classes.spacer} />
          <div className={classes.userBar}>
            <div className={classes.userMenuContainer}>
              <Chip
                avatar={avatar}
                classes={avatarClasses}
                className={classes.userChip}
                label={emailLabel}
                onClick={handleOnOpenMenu}
                data-test="userMenu"
                ref={anchor}
              />
              <Menu
                anchorEl={anchor.current}
                open={isMenuOpened}
                variant="menu"
              >
                <Column fullWidth alignItems="flex-start" ref={menuRef}>
                  <MenuItem
                    className={classes.userMenuItem}
                    onClick={handleViewerProfile}
                    data-test="accountSettingsButton"
                  >
                    <FormattedMessage
                      defaultMessage="Account Settings"
                      id="X8+Lpa"
                      description="button"
                    />
                  </MenuItem>
                  <MenuItem
                    className={classes.userMenuItem}
                    onClick={handleLogout}
                    data-test="logOutButton"
                  >
                    <FormattedMessage
                      defaultMessage="Log out"
                      id="qLbse5"
                      description="button"
                    />
                  </MenuItem>
                </Column>
              </Menu>
            </div>
          </div>
        </Row>
      </Container>
    </Row>
  )
}
