import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { ColorDotProps } from './color-dot.types'
import { useStyle } from './color-dot.styles'
import { LightTheme } from '../Theme'

export const ColorDot: FC<ColorDotProps> = ({
  preset,
  className = '',
  color,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ preset, color, theme })

  return (
    <div className={`${classes.container} ${className}`} onClick={onClick} />
  )
}
