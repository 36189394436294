import React, { FC } from 'react'

import { Column, Row, Text } from '../../../../components'

import { ProductPreviewVendorInfoProps } from './product-preview-vendor-info.types'

export const ProductPreviewVendorInfo: FC<ProductPreviewVendorInfoProps> = ({
  className = '',
  countryCode,
  name = ''
}) => {
  return (
    <Column
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      className={className}
    >
      <Text
        text="Company:"
        tx="order.item.vendor.title"
        preset="h6"
        color="inactive"
      />
      <Row fullWidth justifyContent="space-between">
        <Text preset="h5" text={name} />
        {countryCode && <Text text={countryCode} preset="h7" />}
      </Row>
    </Column>
  )
}
