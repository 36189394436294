import React, { FC } from 'react'

import { Text } from '../text'
import { Column } from '../column'
import { Modal } from '../modal'

import background from './background.png'
import { useStyle } from './confirm-modal.styles'
import { ConfirmModalProps } from './confirm-modal.types'

export const ConfirmModal: FC<ConfirmModalProps> = ({
  title,
  titleTx,
  description,
  descriptionTx,
  secondaryDescription,
  secondaryDescriptionTx,
  onClose
}) => {
  const classes = useStyle()

  return (
    <Modal title={title} titleTx={titleTx} onClose={onClose}>
      <Column fullWidth className={classes.bottomContainer}>
        <img width="100%" src={background} />
        <Text
          className={classes.description}
          color="black"
          preset="h4"
          text={description}
          tx={descriptionTx}
        />
        <Text
          className={classes.secondaryDesctiption}
          color="inactive"
          preset="body"
          text={secondaryDescription}
          tx={secondaryDescriptionTx}
        />
      </Column>
    </Modal>
  )
}
