import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { getColorByMainStatus, getMessageByMainStatus } from '../../../utils'
import { LightTheme } from '../../../components/Theme'
import {
  ColorDot,
  Row,
  Text,
  getCurrencySymbol,
  getFullName,
  getPriceToFixed
} from '../../../components'

import { useStyle } from './orders-activity-table-item.styles'
import { OrdersActivityTableItemProps } from './orders-activity-table-item.types'

export const OrdersActivityTableItem: FC<OrdersActivityTableItemProps> = ({
  id,
  number,
  event,
  mainStatus,
  created,
  totalPrice,
  total,
  user,
  onClickItem,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const currencySymbol = getCurrencySymbol(total.currency)
  const price = `${currencySymbol}${getPriceToFixed(totalPrice)}`

  const colorStatus = theme.colors[getColorByMainStatus(mainStatus)]
  const mainStatusText = getMessageByMainStatus(mainStatus)

  const handleOnClick = () => {
    if (onClickItem && onClick) {
      onClick(number, id)
    }
  }

  return (
    <Row
      fullWidth
      key={`order_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row
        className={`${classes.tableColumn} ${classes.maxTableColumn}`}
        justifyContent="flex-start"
      >
        <Text
          color="black"
          preset="body"
          text="Order #"
          tx="orders.table.item.number"
        />
        {number && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={number}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {event && <Text color="black" preset="body" text={event} />}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {user && <Text color="black" preset="body" text={getFullName(user)} />}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <ColorDot color={colorStatus} />
        {mainStatus && (
          <Text
            className={classes.statusText}
            color="black"
            preset="body"
            {...mainStatusText}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {created && <Text color="black" preset="body" text={created} />}
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.minTableColumn}`}
        justifyContent="flex-start"
      >
        {totalPrice && <Text color="black" preset="body" text={price} />}
      </Row>
    </Row>
  )
}
