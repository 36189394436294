import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ContactRegisterPage, UserInfoUpdatePage } from '../pages'
import {
  AccountUpdateRequest,
  RegisterCredentials,
  SubscriptionTypeProfile,
  User
} from '../services'
import { TextProps } from '../components'

import Layout from './components/Layout'
import { AuthRoutes } from './urls'
import { LoginView } from './views/Login'
import { RegisterView } from './views/register'
import { ForgotPasswordView } from './views/ForgotPassword'
import { ResetPasswordSuccessView } from './views/ResetPasswordSuccess'
import { ChangePasswordView } from './views/ChangePassword'
import { EmailChangeConfirmView } from './views/EmailChangeConfirm'
import { LoginAdminView } from './views/LoginAdmin'

interface UserContextType {
  signature?: string
  loadingSession: boolean
  accountErrors: TextProps[]
  login?: (username: string, password: string, page: string) => void
  loginByToken?: (
    auth: string,
    csrf: string,
    refresh: string,
    user: User
  ) => void
  logout?: () => void
  register?: (
    credentials: RegisterCredentials,
    billingAddress: AccountUpdateRequest
  ) => void
  updateInfo?: (user: AccountUpdateRequest, navigatePath?: string) => void
  updateInfoOnRegister?: (user: AccountUpdateRequest) => void
  tokenAuthLoading: boolean
  tokenRefresh?: () => Promise<boolean>
  tokenVerifyLoading: boolean
  user?: User
  unreadMessages?: number
  changeSubscription?: (subscription?: SubscriptionTypeProfile) => void
  changeAmount?: (amount: number) => void
  resetPassword?: (email: string) => void
  setNewPassword?: (email: string, token: string, password: string) => void
  updateAvatar?: (file: File) => void
  deleteAvatar?: () => void
  deleteAccount?: () => void
  changePassword?: (
    oldPassword: string,
    newPassword: string,
    setError: (value: string) => void,
    onClose: () => void
  ) => void
  changeEmail?: (
    password: string,
    newEmail: string,
    redirectUrl: string,
    cancelUrl: string,
    isDashboard: boolean,
    setError: (value: string) => void
  ) => void
  confirmEmailChange?: (token: string) => void
  onClearAccountErrors?: () => void
  onUpdateOfferTotalCount?: (diffCount: number) => void
  onUpdateActiveItemCount?: (diffCount: number) => void
  onResyncUser?: () => void
}

export const UserContext = React.createContext<UserContextType>({
  changeAmount: undefined,
  unreadMessages: 0,
  signature: undefined,
  accountErrors: [],
  loadingSession: true,
  login: undefined,
  loginByToken: undefined,
  logout: undefined,
  register: undefined,
  updateInfo: undefined,
  tokenAuthLoading: false,
  tokenRefresh: undefined,
  tokenVerifyLoading: false,
  resetPassword: undefined,
  setNewPassword: undefined,
  updateAvatar: undefined,
  deleteAvatar: undefined,
  deleteAccount: undefined,
  changePassword: undefined,
  changeEmail: undefined,
  confirmEmailChange: undefined
})

export const AuthRouter: React.FC = () => (
  <Layout>
    <Switch>
      <Route
        path={`${AuthRoutes.CHANGE_EMAIL_REQUEST}/:token`}
        component={EmailChangeConfirmView}
      />
      <Route
        path={AuthRoutes.USER_INFO_UPDATE}
        component={UserInfoUpdatePage}
      />
      <Route
        path={AuthRoutes.CONTACT_INFO_UPDATE}
        component={ContactRegisterPage}
      />
      <Route path={AuthRoutes.REGISTER} component={RegisterView} />
      <Route
        path={AuthRoutes.RESET_PASSWORD_SUCCESS}
        component={ResetPasswordSuccessView}
      />
      <Route path={AuthRoutes.CHANGE_PASSWORD} component={ChangePasswordView} />
      <Route path={AuthRoutes.FORGOT_PASSWORD} component={ForgotPasswordView} />
      <Route path={AuthRoutes.SIGN_IN_ADMIN} component={LoginAdminView} />
      <Route path="*" component={LoginView} />
    </Switch>
  </Layout>
)

AuthRouter.displayName = 'AuthRouter'
export default AuthRouter

export * from './utils'
