import { MutationResult } from '@apollo/client'

import { Product } from '../product'
import { Order, OrderUser, OrderVendor } from '../order'
import { User } from '../auth'

import {
  DateRangeInput,
  FetchMore,
  FilterItem,
  ListVariables,
  PaginationList,
  SortBy
} from '../api.types'

export enum FeedbackErrorCode {
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE'
}

export enum FeedbackOrderField {
  RATING = 'RATING',
  ANSWER = 'ANSWER',
  DATE = 'DATE',
  FEEDBACK_OBJECT = 'FEEDBACK_OBJECT'
}

export type FeedbackError = {
  field?: string
  message?: string
  code?: FeedbackErrorCode
}

export type Feedback = {
  id: string
  vendor?: User
  product?: Product
  comment?: string
  rating: number
  answer?: string
}

export type FeedbackCreateInput = {
  vendor?: string
  product?: string
  rating: number
  comment?: string
}

export type FeedbackCreateInputVariables = {
  input: FeedbackCreateInput
}

export type FeedbackUpdateInput = {
  answer: string
}

export interface FeedbackUpdateInputVariables {
  id: string
  input: FeedbackUpdateInput
}

export type FeedbackFilterInput = {
  isWithComment?: boolean
  isWithoutComment?: boolean
  isWithAnswer?: boolean
  isWithoutAnswer?: boolean
  isToProduct?: boolean
  isToVendor?: boolean
  createdAt?: DateRangeInput
  customer?: string[]
  type?: string[]
  object?: string[]
  search?: string
}

export type FeedbackOrder = SortBy<FeedbackOrderField>

export type FeedbackCreateData = {
  feedbackCreate: {
    feedbackErrors: FeedbackError[]
    feedback: Feedback
  }
}

export type FeedbackUpdateResults = {
  feedbackUpdate: {
    feedbackErrors: FeedbackError[]
    feedback: Feedback
  }
}

export interface FeedbackVariables extends ListVariables<FeedbackFilterInput> {
  sortBy?: FeedbackOrder
}

export type FullFeedback = {
  id: string
  createdAt: Date
  vendor: OrderVendor
  user: OrderUser
  product: Product
  order: Order
  comment: string
  answer: string
  rating: number
  type: string
}

export type FeedbackFiltersParseJSON = {
  averageRating: number
  positiveFeedbackPercentage: number
  total: number
  toVendorTotal: number
  toProductTotal: number
}

export type FeedbacksActivityFiltersParseJSON = {
  feedbackTypes: FilterItem[]
  feedbackObjects: FilterItem[]
  customers: FilterItem[]
}

export type FeedbackFiltersKeys = keyof FeedbackFilterInput

export interface FeedbackWithCount extends FullFeedback {}

export interface FeedbacksData extends PaginationList<FeedbackWithCount> {
  filters: string
  options: string
}

export type GetFeedbacksData = {
  feedbacks: FeedbacksData
}

export type GetFeedbacks = {
  data?: GetFeedbacksData
  loading: boolean
  loadingMore: boolean
  refetch: (variables?: Partial<FeedbackVariables>) => void
  fetchMore?: FetchMore<GetFeedbacksData, FeedbackVariables>
}

export type FeedbackCreate = {
  onSubmit: (variables: FeedbackCreateInputVariables) => void
  response: MutationResult<FeedbackCreateData>
}

export type FeedbackUpdate = {
  onSubmit: (variables: FeedbackUpdateInputVariables) => void
  response: MutationResult<FeedbackUpdateResults>
}

export type FeedbackApi = {
  useFeedbacks: (variables: FeedbackVariables) => GetFeedbacks
  useCreateFeedback: () => FeedbackCreate
  useUpdateFeedback: () => FeedbackUpdate
}
