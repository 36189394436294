import React, { FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { normalizedMonth } from '../helpers'

import { Column } from '../column'
import { ICONS } from '../icon'
import { IconButton } from '../icon-button'
import { Row } from '../row'
import { Text } from '../text'
import { LightTheme } from '../Theme'
import { CalendarNumberItem } from './calendar-number-item'
import { useStyle } from './calendar.styles'
import { CalendarProps, DateOfWeek, PickerType } from './calendar.types'
import { getDateWithStartTime } from './helpers'

const daysOfWeek: DateOfWeek[] = [
  {
    text: 'Su',
    tx: 'datePicker.sunday',
    values: []
  },
  {
    text: 'Mo',
    tx: 'datePicker.monday',
    values: []
  },
  {
    text: 'Tu',
    tx: 'datePicker.tuesday',
    values: []
  },
  {
    text: 'We',
    tx: 'datePicker.wednesday',
    values: []
  },
  {
    text: 'Th',
    tx: 'datePicker.thursday',
    values: []
  },
  {
    text: 'Fr',
    tx: 'datePicker.friday',
    values: []
  },
  {
    text: 'Sa',
    tx: 'datePicker.saturday',
    values: []
  }
]

export const Calendar: FC<CalendarProps> = ({
  onChange,
  value: currentValue = new Date()
}) => {
  // Simple pick date or period pick date state
  const [pickerType] = useState(PickerType.DATE)
  const [monthDate, changeMonthDate] = useState(currentValue)
  const [fromDate, changeFromDate] = useState<Date | undefined>(currentValue)
  const [toDate, changeToDate] = useState<Date | undefined>()

  const theme = useTheme<LightTheme>()
  const classes = useStyle({ pickerType, theme })

  const dateByWeek: DateOfWeek[] = useMemo(() => {
    const lastDayOfMonth = new Date(
      monthDate.getFullYear(),
      monthDate.getMonth() + 1,
      0
    ).getDate()
    const firstWeekdayOfMonth = new Date(
      monthDate.getFullYear(),
      monthDate.getMonth(),
      1
    ).getDay()

    const month = monthDate.getMonth()
    const year = monthDate.getFullYear()

    const daysComponents: DateOfWeek[] = daysOfWeek.map((item) => ({
      ...item,
      values: []
    }))

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < firstWeekdayOfMonth; i++) {
      daysComponents[i].values = [...daysComponents[i].values, '']
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= lastDayOfMonth; i++) {
      const dayOfWeek = new Date(year, month, i).getDay()
      daysComponents[dayOfWeek].values = [
        ...daysComponents[dayOfWeek].values,
        String(i)
      ]
    }

    return daysComponents
  }, [monthDate])

  const handleOnPrevMonth = () => {
    const currentMonth = monthDate.getMonth()

    const nextTime = monthDate.setMonth(currentMonth - 1)

    changeMonthDate(new Date(nextTime))
    changeFromDate(undefined)
    changeToDate(undefined)
  }

  const handleOnNextMonth = () => {
    const currentMonth = monthDate.getMonth()

    const nextTime = monthDate.setMonth(currentMonth + 1)

    changeMonthDate(new Date(nextTime))

    changeFromDate(undefined)
    changeToDate(undefined)
  }

  const handleOnChange = (nextFromDate: Date, nextToDate?: Date) => {
    if (onChange) {
      if (nextToDate) {
        onChange(nextFromDate)
      }

      if (PickerType.DATE === pickerType) {
        onChange(nextFromDate, nextToDate)
      }
    }
  }

  const handleOnPickFromPeriod = (day: string) => {
    const month = monthDate.getMonth()
    const year = monthDate.getFullYear()

    const nowDate = getDateWithStartTime(year, month, Number(day))
    changeFromDate(nowDate)

    if (PickerType.DATE) {
      handleOnChange(nowDate)
    }
  }

  const handleOnPickToPeriod = (day: string) => {
    const month = monthDate.getMonth()
    const year = monthDate.getFullYear()

    const nextToDate = getDateWithStartTime(year, month, Number(day))

    if (fromDate && nextToDate.getTime() <= fromDate.getTime()) {
      changeToDate(fromDate)
      changeFromDate(nextToDate)
      handleOnChange(nextToDate, fromDate)
    } else {
      changeToDate(nextToDate)
      if (fromDate) {
        handleOnChange(fromDate, nextToDate)
      }
    }
  }

  const handleOnPick = (day: string) => () => {
    if (toDate && pickerType === PickerType.PERIOD) {
      changeToDate(undefined)
      return handleOnPickFromPeriod(day)
    }

    if (fromDate && pickerType === PickerType.PERIOD) {
      return handleOnPickToPeriod(day)
    }

    return handleOnPickFromPeriod(day)
  }

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="space-between">
        <IconButton
          iconName={ICONS.chevronLeft}
          preset="transparent"
          onClick={handleOnPrevMonth}
        />
        <Text text={normalizedMonth(monthDate)} color="inactive" preset="h4" />
        <IconButton
          iconName={ICONS.chevronRight}
          preset="transparent"
          onClick={handleOnNextMonth}
        />
      </Row>
      <Row
        fullWidth
        className={classes.calendar}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {dateByWeek.map((dayOfWeek) => (
          <Column fullWidth key={`day_${dayOfWeek.text}`}>
            <Text
              color="inactive"
              preset="body"
              text={dayOfWeek.text}
              tx={dayOfWeek.tx}
            />
            {dayOfWeek.values.map((dateOfMonth, index) => (
              <CalendarNumberItem
                key={`date_month_${index}_${dateOfMonth}`}
                from={fromDate}
                to={toDate}
                monthDate={monthDate}
                pickerType={pickerType}
                text={dateOfMonth}
                onClick={handleOnPick(dateOfMonth)}
              />
            ))}
          </Column>
        ))}
      </Row>
    </Column>
  )
}
