import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {
    marginLeft: 24
  },
  checkboxText: {
    marginLeft: 16
  },
  container: {
    padding: '24px 16px'
  },
  content: {
    marginTop: 24
  },

  leftColumn: {
    flex: 2,
    width: '66%'
  },
  rightColumn: {
    flex: 1,
    width: '33%',
    marginLeft: 24
  },
  section: {
    '&:not(:first-child)': {
      marginTop: 20
    }
  },
  sectionAdditionContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: '100%'
  },
  field: {
    marginTop: 16
  },
  submitContainer: {
    background: theme.colors.white,
    bottom: 0,
    boxShadow: `4px -2px 8px 0px ${theme.colors.shadow}`,
    marginTop: 16,
    padding: 24,
    position: 'sticky'
  },
  title: {
    marginTop: 24
  },
  visibleDescription: {
    marginTop: 12
  },
  visibleTitle: {
    marginTop: 8
  },
  favorite: {
    background: theme.colors.white,
    borderRadius: 40,
    boxShadow: `1px 2px 8px ${theme.colors.shadow}`,
    height: 40,
    width: 40,
    zIndex: 1,
    cursor: 'pointer'
  }
}))
