import {
  arrayHasElements,
  DropdownItemData,
  getItemById,
  RadioItemWithIdProps
} from '../../components'
import {
  AttributeType,
  CategoryWithChildren,
  CategoryWithParent,
  FeedbackWithCount,
  GetCategoriesData,
  getItemsFromEdges,
  ProductCreateAttributeInput,
  ProductCreateColorInput,
  ProductCreateInput,
  ProductCreateMaterialInput,
  ProductCreateOptionInput,
  ProductOptionType,
  ProductOptionVariantInput,
  ProductPurchaseSpecifics
} from '../../services'

export const visibilityRadioData: RadioItemWithIdProps[] = [
  { id: '1', name: 'Visible', nameTx: 'banner.visibility.active' },
  { id: '0', name: 'Invisible', nameTx: 'banner.visibility.inactive' }
]

export const purchaseSpecificsRadioData: RadioItemWithIdProps[] = [
  {
    id: ProductPurchaseSpecifics.ONLY_WITH_OFFER,
    name: 'Оnly with Offer',
    nameTx: 'item.form.only.with.offer'
  }
  // TODO: temporary changes in disabling direct purchase radio button, ovm-1939
  // {
  //   id: ProductPurchaseSpecifics.DIRECT_PURCHASE,
  //   name: 'Direct Purchase',
  //   nameTx: 'item.form.direct.purchase'
  // }
]

export type DropdownItem = {
  id: string
  name?: string
  value?: string
  price?: number
  weight?: number
}

export const getCategoriesFromList = (
  categories: GetCategoriesData | null
): CategoryWithChildren[] => {
  if (categories) {
    return getItemsFromEdges(categories.categories)
  }

  return []
}

export const getDropdownItemsFromData = <T extends DropdownItem>(
  data: T[]
): DropdownItemData[] => {
  return data.map<DropdownItemData>((item) => ({
    id: item.id,
    name: item.name || `Up to ${item.weight} kg`,
    value: item.value || item.id
  }))
}

export const getChildrenCategoryFromCategory = (
  id: string,
  categories: CategoryWithChildren[]
) => {
  const category = getItemById(categories, id)

  if (category) {
    return getItemsFromEdges(category.children)
  }

  return []
}

export const getParentCategoryFromCategory = (
  category?: CategoryWithParent
) => {
  if (category && category.parent) {
    const { parent } = category
    return {
      id: parent.id,
      name: parent.name,
      value: parent.id
    }
  }

  return undefined
}

export const getAttributesStateByIds = (
  attributes: AttributeType[],
  ids: string[]
): AttributeType[] => {
  return attributes.map((attribute) => {
    const state = Boolean(ids.find((item) => item === attribute.id))

    return {
      ...attribute,
      state
    }
  })
}

export const isDisabledByValue = (data: Partial<ProductCreateInput>) => {
  return (
    !arrayHasElements(data.materials) ||
    !arrayHasElements(data.colors) ||
    !data.brandName ||
    !data.countryOfOrigin ||
    !data.productionDaysUpTo ||
    !data.materialCare ||
    !data.materialComposition ||
    !data.name ||
    !data.description ||
    !data.defaultPrice ||
    !data.deliveryPrice ||
    !data.category
  )
}

export const getAttributeInputs = (
  attr: ProductCreateAttributeInput[],
  Attr: AttributeType[]
) => {
  return attr.reduce<AttributeType[]>((acc, attributeInput) => {
    const current = Attr.find(
      (attribute) => attribute.id === attributeInput.attributeId
    )
    if (current) {
      return [...acc, current]
    }
    return acc
  }, [])
}

export const getOptions = (
  valueOptions: ProductCreateOptionInput[],
  optionTitle: string,
  variant: ProductOptionVariantInput
) => {
  return valueOptions.map((optionItem) => {
    if (optionTitle === optionItem.title) {
      if (optionItem.type === ProductOptionType.RADIOBUTTON) {
        const variants =
          !optionItem.variants || !optionItem.variants.length
            ? [{ ...variant, isDefault: true }]
            : [...optionItem.variants, { ...variant, isDefault: false }]

        return { ...optionItem, variants }
      } else {
        const variants = optionItem.variants
          ? [...optionItem.variants, { ...variant, isDefault: false }]
          : [{ ...variant, isDefault: false }]

        return { ...optionItem, variants }
      }
    }
    return optionItem
  })
}

export const getNextOptions = (
  options: ProductCreateOptionInput[],
  optionTitle: string,
  optionsValue: ProductOptionVariantInput[]
) => {
  return options.map((option) => {
    if (option.title === optionTitle) {
      const itemValue = {
        ...option,
        variants: optionsValue
      }

      return itemValue
    }
    return option
  })
}

export const getNextColors = (
  color: ProductCreateColorInput,
  colors?: ProductCreateColorInput[]
) =>
  !colors || !colors.length
    ? [{ ...color, isDefault: true }]
    : [...colors, { ...color, isDefault: false }]

export const getNextMaterials = (
  material: ProductCreateMaterialInput,
  materials?: ProductOptionVariantInput[]
) =>
  !materials || !materials.length
    ? [{ ...material, isDefault: true }]
    : [...materials, { ...material, isDefault: false }]

export const getCompareAttributes = (ids: string[], Attr: AttributeType[]) => {
  return Attr.filter((valueAttribute) => {
    const isActiveAttribute = ids.find(
      (attributeItem) => attributeItem === valueAttribute.id
    )

    return Boolean(isActiveAttribute)
  })
}

export const getAttributeType = (
  Attr: AttributeType[],
  attr?: ProductCreateAttributeInput[]
) => {
  return Attr.map((attribute) => {
    const valueAttribute = attr?.find(
      (prevItem) => prevItem.attributeId === attribute.id
    )

    const type = valueAttribute ? valueAttribute.type : ''

    return {
      attributeId: attribute.id,
      type
    }
  })
}
export const getIsDisabledByImages = (
  defaultImg: any,
  removeImagesIds: string[],
  imgFiles: File[]
) => {
  const currentImages = defaultImg.filter(
    (image: any) => !removeImagesIds.includes(image.id)
  )
  const isDisabled =
    !arrayHasElements(currentImages) && !arrayHasElements(imgFiles)
  return isDisabled
}

export const getFeedbackAmount = (feedbacks: FeedbackWithCount[]) => {
  const amount = feedbacks?.length
  const feedbacksSum = feedbacks.reduce((acc, { rating }) => {
    return acc + rating
  }, 0)

  return Math.round(feedbacksSum / amount)
}
