export enum Currency {
  USD = 'USD',
  EUR = 'EUR'
}

export type Money = {
  currency: Currency
  amount: number
}

export type TaxedMoneyRange = {
  start: TaxedMoney
  stop: TaxedMoney
}

export type TaxedMoney = {
  currency: Currency
  gross: Money
  net: Money
  tax: Money
}

export enum TaxRateType {
  ACCOMMODATION = 'ACCOMMODATION',
  ADMISSION_TO_CULTURAL_EVENTS = 'ADMISSION_TO_CULTURAL_EVENTS',
  ADMISSION_TO_ENTERTAINMENT_EVENTS = 'ADMISSION_TO_ENTERTAINMENT_EVENTS',
  ADMISSION_TO_SPORTING_EVENTS = 'ADMISSION_TO_SPORTING_EVENTS',
  ADVERTISING = 'ADVERTISING',
  AGRICULTURAL_SUPPLIES = 'AGRICULTURAL_SUPPLIES',
  BABY_FOODSTUFFS = 'BABY_FOODSTUFFS',
  BIKES = 'BIKES',
  BOOKS = 'BOOKS',
  CHILDRENS_CLOTHING = 'CHILDRENS_CLOTHING',
  DOMESTIC_FUEL = 'DOMESTIC_FUEL',
  DOMESTIC_SERVICES = 'DOMESTIC_SERVICES',
  E_BOOKS = 'E_BOOKS',
  FOODSTUFFS = 'FOODSTUFFS',
  HOTELS = 'HOTELS',
  MEDICAL = 'MEDICAL',
  NEWSPAPERS = 'NEWSPAPERS',
  PASSENGER_TRANSPORT = 'PASSENGER_TRANSPORT',
  PHARMACEUTICALS = 'PHARMACEUTICALS',
  PROPERTY_RENOVATIONS = 'PROPERTY_RENOVATIONS',
  RESTAURANTS = 'RESTAURANTS',
  SOCIAL_HOUSING = 'SOCIAL_HOUSING',
  STANDARD = 'STANDARD',
  WATER = 'WATER',
  WINE = 'WINE'
}
