import React, { useEffect } from 'react'

import { useNavigator, useUser } from '../../hooks'
import { useApi } from '../../providers'
import { AdvertisingCreateInput } from '../../services/advertising'

import {
  CabinetNavigatorPathVendor,
  PromotionsTabNavigation
} from '../cabinet-navigator'
import { AdvertisingForm } from '../advertising-form'

export const CreateAdvertisingPage = () => {
  const { advertising } = useApi()
  const { user } = useUser()
  const { response, onSubmit } = advertising.useCreateAdvertising()
  const navigate = useNavigator()

  const userActiveItemsCount = user?.vendorActiveProducts || 0

  useEffect(() => {
    if (response.data && response.data.advertisingCreate.advertising) {
      navigate(
        `${CabinetNavigatorPathVendor.PROMOTIONS}${PromotionsTabNavigation.ADVERTISEMENTS}`
      )
    }
  }, [response.data])

  const handleOnSubmit = (nextAdvertising: AdvertisingCreateInput) => {
    if (nextAdvertising) {
      onSubmit(nextAdvertising)
    }
  }

  return (
    <AdvertisingForm
      title="Create Advertising"
      titleTx="advertising.create"
      balance={user?.vendorBalance}
      userActiveItemsCount={userActiveItemsCount}
      id={user?.id}
      onSumbit={handleOnSubmit}
    />
  )
}
