import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  infoContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${theme.colors.shadow}`,

    marginTop: 20,
    justifyContent: 'flex-start'
  },
  infoTitle: {
    padding: 16,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  inputRow: {
    padding: '16px',
    columnGap: 20
  },
  input: {
    flex: 1,

    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  radio: {},
  radioContainer: {
    marginTop: 16
  },
  inputBusiness: {
    marginLeft: 16
  },
  imgContainer: {
    position: 'relative',
    width: 120,
    height: 120,
    borderRadius: '50%',
    margin: '0 auto',
    '& img': {
      width: 120,
      height: 120,
      borderRadius: '50%',
      objectFit: 'cover'
    }
  },
  imgContainerHover: {
    position: 'absolute',
    left: 16,
    top: 96,
    width: 120,
    height: 120,
    borderRadius: '50%',
    margin: '0 auto',
    background: theme.colors.overlay
  },
  icon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
    '& path:hover': {
      fill: theme.colors.yellow
    }
  },
  inputHidden: {
    height: 0,
    visibility: 'hidden'
  },
  error: {
    position: 'absolute',
    bottom: '-16px',
    padding: '4px 0px 0px 24px'
  }
}))
