import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  ColorDot,
  Row,
  Text,
  LightTheme,
  getFullName,
  getCompanyName,
  getCurrencySymbol,
  getPriceToFixed
} from '../../../components'
import { Currency } from '../../../services'
import {
  getColorByMainStatus,
  getMessageByMainStatus,
  getMessageByPaymentStatus
} from '../../../utils'

import { useStyle } from './payout-table-item.styles'
import { PayoutTableItemProps } from './payout-table-item.types'

export const PayoutTableItem: FC<PayoutTableItemProps> = ({
  id,
  number,
  paypalOrder,
  vendor,
  user,
  created,
  customerPayment,
  referralFee,
  promotionalFee,
  sellingFee,
  vendorPayment,
  mainStatus,
  paymentStatus,
  onClick
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const vendorFullName = getCompanyName(vendor)
  const customerFullName = getFullName(user)

  const statusColor = getColorByMainStatus(mainStatus)

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  const currency = getCurrencySymbol(Currency.USD)

  const tablePrices = (price: string) => {
    return `${currency}${getPriceToFixed(Number(price))}`
  }

  return (
    <Row
      fullWidth
      key={`banner_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row
        className={`${classes.firstColumn} ${classes.tableColumn}`}
        justifyContent="flex-start"
      >
        {number && <Text color="black" preset="body" text={number} />}
      </Row>
      <Row className={classes.tableColumnBig} justifyContent="flex-start">
        {paypalOrder && <Text color="black" preset="body" text={paypalOrder} />}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {vendorFullName && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={vendorFullName}
          />
        )}
      </Row>
      <Row className={classes.tableColumnBig} justifyContent="flex-start">
        {customerFullName && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={customerFullName}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {created && <Text color="black" preset="body" text={created} />}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="body" text={tablePrices(customerPayment)} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {referralFee && (
          <Text color="black" preset="body" text={tablePrices(referralFee)} />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {sellingFee && (
          <Text color="black" preset="body" text={tablePrices(sellingFee)} />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {promotionalFee && (
          <Text
            color="black"
            preset="body"
            text={tablePrices(promotionalFee)}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {vendorPayment && (
          <Text color="black" preset="body" text={tablePrices(vendorPayment)} />
        )}
      </Row>
      <Row className={classes.tableColumnBig} justifyContent="flex-start">
        <ColorDot color={theme.colors[statusColor]} />
        <Text
          className={classes.statusText}
          color="black"
          preset="body"
          {...getMessageByMainStatus(mainStatus)}
        />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {paymentStatus ? (
          <Text
            color="black"
            preset="body"
            {...getMessageByPaymentStatus(paymentStatus)}
          />
        ) : (
          <Text
            color="black"
            preset="body"
            text="Not Charged"
            tx="paymentStatus.notCharged"
          />
        )}
      </Row>
    </Row>
  )
}
