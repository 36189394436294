import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import { FeedbackMonitoringProductItemStyleProps } from './feedback-monitiring-product-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    marginTop: 16,
    padding: 16
  },
  tableTopContainer: {
    flexWrap: 'wrap'
  },
  name: {
    marginLeft: 8,
    lineHeight: '24px'
  },
  headerLeftItem: {
    flex: 2,
    minWidth: 184
  },
  headerItemNum: {
    flex: 1.5,
    minWidth: 200
  },
  headerItemDate: {
    flex: 1.2,
    minWidth: 128
  },
  headerItemRate: {
    width: 180
  },
  textRow: {
    marginTop: 16
  },
  item: {
    marginTop: 16
  },
  textField: {
    width: '100%'
  },
  filter: {
    marginLeft: 16,
    height: 56,
    maxWidth: 168
  },
  answer: {
    marginTop: 16,
    paddingTop: 16,
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  answerTitle: {
    minWidth: 80
  },
  answerText: {
    display: ({ open }: FeedbackMonitoringProductItemStyleProps) =>
      open ? 'flex' : '-webkit-box',
    marginLeft: 8,
    cursor: 'pointer',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2
  },
  image: {
    backgroundColor: theme.colors.lightFon,
    width: 64,
    height: 64,
    objectFit: 'cover'
  },
  observeOptionsBtn: {
    marginTop: 6,
    marginLeft: 8,
    padding: '8px 6px',
    textTransform: 'uppercase'
  }
}))
