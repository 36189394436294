import React from 'react'

import {
  CategoryNav,
  CategoryNavItem,
  Column,
  PermissionContent,
  Row,
  Text
} from '../../components'
import { PermissionEnum } from '../../services'

import { ItemsTabNavigation } from '../cabinet-navigator'
import { ItemsProductsPage } from '../items-product'
import { ItemsCollectionsPage } from '../items-collections'

import { useStyle } from './items.styles'

export const ItemsPage = () => {
  const classes = useStyle()

  const NAVIGATION_TABS: CategoryNavItem[] = [
    {
      text: 'All Items',
      tx: 'item.form.back',
      link: ItemsTabNavigation.PRODUCTS,
      content: (
        <PermissionContent
          permissions={[
            PermissionEnum.VIEW_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
        >
          <ItemsProductsPage />
        </PermissionContent>
      )
    },
    {
      text: 'Collections',
      tx: 'item.tab.collections',
      link: ItemsTabNavigation.COLLECTIONS,
      content: (
        <PermissionContent
          permissions={[
            PermissionEnum.VIEW_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_COLLECTIONS
          ]}
        >
          <ItemsCollectionsPage />
        </PermissionContent>
      )
    }
  ]

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row fullWidth justifyContent="flex-start">
        <Text
          color="black"
          preset="h3"
          text="Items Page"
          tx="cabinet.route.vendor.itemsPage.title"
        />
      </Row>
      <CategoryNav className={classes.category} data={NAVIGATION_TABS} />
    </Column>
  )
}
