import { createUseStyles } from 'react-jss'

import { ContactRegisterStyleProps } from './contact-register-page.types'

export const useStyle = createUseStyles({
  container: {
    backgroundImage: ({ background }: ContactRegisterStyleProps) =>
      `url(${background})`,
    backgroundRepeat: 'space',
    backgroundSize: '100vw',
    paddingBottom: 24,
    zIndex: 1
  },
  input: {
    marginTop: 24
  },
  tooltip: {
    position: 'absolute',
    right: -40
  },
  loginForm: {
    margin: '0 auto',
    width: 536
  },
  submit: {
    marginTop: 56
  }
})
