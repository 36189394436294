import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import backgroundUrl from '../../assets/images/background-pattern.png'

import { LayoutStyleProps } from './Layout.types'

const useStyles = makeStyles(
  (theme) => ({
    logo: {
      '& svg': {
        display: 'block',
        height: 40,
        marginBottom: theme.spacing(4)
      }
    },
    mainPanel: {
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2)
      },
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      justifyContent: 'center',
      padding: theme.spacing(6),
      width: '100%'
    },
    mainPanelContent: {
      [theme.breakpoints.up('xs')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        width: 1200
      },
      '@media (min-width: 1440px)': {
        width: 1200
      },
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
      width: '100%'
    },
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      backgroundImage: ({ url }: LayoutStyleProps) => `url(${url})`,
      backgroundRepeat: 'space',
      backgroundSize: '100vw',
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      overflow: 'hidden',
      width: '100vw'
    },
    sidebar: {
      [theme.breakpoints.up('lg')]: {
        display: 'block'
      },
      display: 'none'
    },
    sidebarArt: {
      '& svg': {
        width: '100%'
      }
    }
  }),
  {
    name: 'Layout'
  }
)

const Layout: React.FC = (props) => {
  const { children } = props

  const classes = useStyles({ url: backgroundUrl, ...props })

  return (
    <div className={classes.root}>
      <div className={classes.mainPanel}>
        <div className={classes.mainPanelContent}>{children}</div>
      </div>
    </div>
  )
}

Layout.displayName = 'Layout'
export default Layout
