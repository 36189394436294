import React, { FC } from 'react'
import { useTheme } from 'react-jss'
import { Link } from 'react-router-dom'

import { Text } from '../text'
import { LightTheme } from '../Theme'
import { useStyle } from './nav-link.styles'
import { NavLinkProps } from './nav-link.types'

export const NavLink: FC<NavLinkProps> = ({
  className,
  decoration,
  outerLink,
  name,
  color = 'inactive',
  to,
  ...props
}) => {
  const theme: LightTheme = useTheme()
  const themeColor = theme.colors[color]
  const classes = useStyle({ color: themeColor, decoration })

  if (!to) {
    return <Text className={className} text={name} color={color} {...props} />
  }

  if (outerLink) {
    return (
      <a
        className={classes.decoration}
        href={to}
        rel="noreferrer"
        target="_blank"
        {...props}
      >
        <Text
          className={`${className} ${classes.link}`}
          text={name}
          color={color}
          {...props}
        />
      </a>
    )
  }

  return (
    <Link className={classes.decoration} to={to} {...props}>
      <Text
        className={`${className} ${classes.link}`}
        text={name}
        color={color}
        {...props}
      />
    </Link>
  )
}
