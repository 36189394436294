import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '12px 8px',
    minWidth: 304
  },
  tableColumnmMin: {
    minWidth: 180
  },
  tableColumnBig: {
    minWidth: 352
  },
  tableRow: {
    cursor: 'pointer',
    '&>div': {
      borderBottom: `1px solid ${theme.colors.shadow}`
    },
    '&:hover > div': {
      background: theme.colors.lightBlue
    }
  },
  firstColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    background: theme.colors.white,
    borderRight: `1px solid ${theme.colors.shadow}`
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))
