import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../Theme'

import { CalendarNumberItemStyleProps } from './calendar-number-item.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '12px 0px',
    cursor: 'pointer'
  },
  text: {
    backgroundColor: ({ active }: CalendarNumberItemStyleProps) =>
      active ? theme.colors.hover : 'transparent',
    height: 40,
    width: 40,
    borderRadius: 4,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    cursor: 'pointer'
  },
  leftSide: {
    backgroundColor: `${theme.colors.yellow}33`,
    position: 'absolute',
    top: 12,
    bottom: 12,
    left: 0,
    right: '50%',
    zIndex: 1,
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    }
  },
  rightSide: {
    backgroundColor: `${theme.colors.yellow}33`,
    position: 'absolute',
    top: 12,
    bottom: 12,
    left: '50%',
    right: 0,
    zIndex: 1,
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    }
  }
}))
