import React, { FC } from 'react'

import { Row } from '../row'
import { Text } from '../text'
import { Icon } from '../icon'

import { useStyle } from './subscription-benefit.styles'
import { SubscriptionBenefitProps } from './subscription-benefit.types'

export const SubscriptionBenefit: FC<SubscriptionBenefitProps> = ({
  src,
  text
}) => {
  const classes = useStyle()
  return (
    <Row>
      <Icon src={src} className={classes.icon} />
      <Text
        className={classes.benefits}
        preset="body"
        text={text}
        color="black"
      />
    </Row>
  )
}
