// a library to wrap and simplify api calls
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { relayStylePagination } from '@apollo/client/utilities'
import { setContext } from 'apollo-link-context'

import { ENV, NodeEnv } from '../config'
import { getTokens } from '../auth'

import { authService } from './auth'
import { collectionService } from './collection'
import { productService } from './product'
import { countryService } from './country'
import { cacheMenuConfig, menuService } from './menu'
import { categoryService } from './category'
import { cacheOrderConfig, orderService } from './order'
import { cacheVendorConfig, vendorService } from './vendor'
import { newsService } from './news'
import { colorService } from './color'
import { attributeService } from './attribute'
import { materialService } from './material'
import { feedbackService } from './feedback'
import { paymentService } from './payment'
import { subscriptionService } from './profile-settings'
import { statisticService } from './statistic'
import { adminService } from './admin'
import { offerService } from './offer'
import { advertisingService } from './advertising'
import { deliveryService } from './delivery-price'
import { cacheCustomerConfig, userListService } from './user-list'

import { Api } from './api.types'
import { instructionServise } from './instruction'

export const defaultOptions: any = {
  fetchPolicy: 'no-cache'
}

const queryListPoliciesAggregator = (queryNames: string[]) =>
  queryNames.reduce(
    (acc, query) => ({
      ...acc,
      [query]: relayStylePagination()
    }),
    {}
  )

const queryFields = queryListPoliciesAggregator(['feedbacks'])

// our "constructor"
const create = (baseURL = '/graphql/'): Api => {
  let customHeaders = {}

  const linkOptions = {
    ...defaultOptions,
    credentials: 'include',
    uri: baseURL
  }

  const uploadLink = createUploadLink(linkOptions)

  const setHeader = (key: string, prop: string) => {
    customHeaders = { ...customHeaders, [key]: prop }
  }

  const headerLink = setContext((_, { headers: prevHeader }) => {
    const token = getTokens().auth
    const autorizationToken = `JWT ${token}`
    return {
      headers: {
        ...prevHeader,
        ...customHeaders,
        authorization: token ? autorizationToken : undefined
      }
    }
  })

  const api = new ApolloClient({
    connectToDevTools: ENV !== NodeEnv.PRODUCTION,
    cache: new InMemoryCache({
      typePolicies: {
        ...cacheMenuConfig,
        ...cacheOrderConfig,
        ...cacheVendorConfig,
        ...cacheCustomerConfig,
        Query: {
          fields: {
            ...cacheOrderConfig.Query.fields,
            ...cacheVendorConfig.Query.fields,
            ...cacheCustomerConfig.Query.fields,
            ...queryFields
          }
        }
      }
    }),
    // @ts-ignore
    link: headerLink.concat(uploadLink)
  })

  return {
    // a list of the API functions from step 2
    api,
    setHeader,
    admin: adminService(),
    color: colorService(),
    menu: menuService(),
    product: productService(),
    collection: collectionService(),
    category: categoryService(),
    country: countryService(),
    news: newsService(),
    order: orderService(),
    vendor: vendorService(),
    attribute: attributeService(),
    material: materialService(),
    payment: paymentService(),
    feedback: feedbackService(),
    subscription: subscriptionService(),
    statistic: statisticService(),
    auth: authService(),
    offer: offerService(),
    advertising: advertisingService(),
    deliveryPrice: deliveryService(),
    instruction: instructionServise(),
    userList: userListService()
  }
}

// let's return back our create method as the default.
export const api = {
  create
}
