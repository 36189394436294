import React from 'react'
import { createUseStyles } from 'react-jss'
import { Route, Switch } from 'react-router-dom'

import { AuthRouter } from '../auth'
import { LoginLoading } from '../auth/components/LoginLoading'
import { Column } from '../components'
import { useAuth } from '../hooks'
import { useAppState } from '../hooks/useAppState'
import { NotFound } from '../NotFound'
import {
  CabinetNavigatorPathAdmin,
  BannersPage,
  CreateBannerPage,
  EditBannerPage,
  CabinetNavigatorPathVendor,
  ItemsPage,
  CreateItemPage,
  EditItemPage,
  OrdersActivityPage,
  YourInfoPage,
  ProfileSettingsPage,
  PayoutsPage,
  ProfileSettingsGeneralPage,
  ProfileSettingsSubscriptionPage,
  CreateCollectionPage,
  EditCollectionPage,
  ActivityPage,
  SallersPageDesignPage,
  CreateVendorBannerPage,
  EditVendorBannerPage,
  BillingsPage,
  FeedbackMonitoringPage,
  OptionsNavigatorPathes,
  SubscriptionPage,
  AdminStatisticPage,
  OrderControlPage,
  InboxPage,
  OffersPage,
  PromotionPage,
  CreatePromotionBannerPage,
  EditPromotionBannerPage,
  OfferEditItemPage,
  OfferChat,
  CreateAdvertisingPage,
  EditAdvertisingPage,
  OrderChat,
  OrderAdminCustomerChat,
  UserListPage
} from '../pages'
import { PermissionEnum } from '../services'

import { CabinetRoute } from './cabinet'

export const useStyle = createUseStyles({
  container: {
    background: 'white',
    minHeight: '100vh'
  }
})

export const Routes: React.FC = () => {
  const classes = useStyle()
  const [app] = useAppState()
  const {
    hasToken,
    isAuthenticated,
    loadingSession,
    tokenAuthLoading,
    tokenVerifyLoading
  } = useAuth()

  if (!app) {
    return <></>
  }

  if (!isAuthenticated || tokenAuthLoading || tokenVerifyLoading) {
    if (loadingSession || (hasToken && tokenVerifyLoading)) {
      return <LoginLoading />
    } else {
      return (
        <Column justifyContent="flex-start" className={classes.container}>
          <AuthRouter />
        </Column>
      )
    }
  }

  return (
    <Column justifyContent="flex-start" className={classes.container}>
      <Switch>
        <CabinetRoute
          exact
          path={CabinetNavigatorPathAdmin.YOUR_INFO}
          permissions={[PermissionEnum.YOUR_INFO]}
        >
          <YourInfoPage />
        </CabinetRoute>
        <CabinetRoute
          permissions={[
            PermissionEnum.VIEW_VENDORS,
            PermissionEnum.VIEW_CUSTOMERS
          ]}
          path={CabinetNavigatorPathAdmin.USER_LIST}
        >
          <UserListPage />
        </CabinetRoute>
        <CabinetRoute
          permissions={[PermissionEnum.CREATE_MARKETPLACE_BANNER]}
          path={CabinetNavigatorPathAdmin.CREATE_BANNERS}
        >
          <CreateBannerPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.EDIT_MARKETPLACE_BANNER]}
          path={`${CabinetNavigatorPathAdmin.EDIT_BANNERS}/:id`}
        >
          <EditBannerPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.CREATE_MARKETPLACE_BANNER]}
          path={CabinetNavigatorPathAdmin.BANNERS}
        >
          <BannersPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.VIEW_PAYOUT]}
          path={CabinetNavigatorPathAdmin.PAYOUTS}
        >
          <PayoutsPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          path={CabinetNavigatorPathAdmin.STATISTICS}
          permissions={[PermissionEnum.VIEW_STATISTIC]}
        >
          <AdminStatisticPage />
        </CabinetRoute>
        <CabinetRoute exact path={CabinetNavigatorPathAdmin.COMMUNICATION}>
          <InboxPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.SET_UP_OWN_PROFILE]}
          path={CabinetNavigatorPathVendor.PROFILE_SETTINGS}
        >
          <ProfileSettingsPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          path={CabinetNavigatorPathVendor.PROFILE_SETTINGS_GENERAL}
        >
          <ProfileSettingsGeneralPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.SET_UP_OWN_PROFILE]}
          path={CabinetNavigatorPathVendor.PROFILE_SETTINGS_SUBSCRIPTION}
        >
          <ProfileSettingsSubscriptionPage />
        </CabinetRoute>
        <CabinetRoute
          permissions={[
            PermissionEnum.VIEW_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
          path={CabinetNavigatorPathVendor.ITEMS}
        >
          <ItemsPage />
        </CabinetRoute>
        <CabinetRoute
          permissions={[PermissionEnum.CREATE_OWN_DISCOUNTS]}
          path={CabinetNavigatorPathVendor.PROMOTIONS}
        >
          <PromotionPage />
        </CabinetRoute>
        <CabinetRoute
          permissions={[PermissionEnum.CREATE_OWN_PROMOTION_BANNER]}
          path={CabinetNavigatorPathVendor.CREATE_PROMOTION_BANNER}
        >
          <CreatePromotionBannerPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.CREATE_OWN_PROMOTION_BANNER]}
          path={`${CabinetNavigatorPathVendor.EDIT_PROMOTION_BANNER}/:id`}
        >
          <EditPromotionBannerPage />
        </CabinetRoute>
        <CabinetRoute
          permissions={[PermissionEnum.CREATE_OWN_SALES]}
          path={CabinetNavigatorPathVendor.CREATE_ADVRTISING}
        >
          <CreateAdvertisingPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.CREATE_OWN_SALES]}
          path={`${CabinetNavigatorPathVendor.EDIT_ADVRTISING}/:id`}
        >
          <EditAdvertisingPage />
        </CabinetRoute>
        <CabinetRoute
          permissions={[PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS]}
          path={CabinetNavigatorPathVendor.OFFERS}
        >
          <OffersPage />
        </CabinetRoute>
        <CabinetRoute
          path={`${CabinetNavigatorPathVendor.OFFER_CHAT}/:offerId`}
        >
          <OfferChat />
        </CabinetRoute>
        <CabinetRoute
          path={`${CabinetNavigatorPathVendor.ORDER_CHAT}/:orderId${CabinetNavigatorPathVendor.OFFER}/:offerId`}
        >
          <OrderChat />
        </CabinetRoute>
        <CabinetRoute
          path={`${CabinetNavigatorPathAdmin.OFFER_CHAT}/:orderId${CabinetNavigatorPathVendor.OFFER}/:offerId`}
        >
          <OrderAdminCustomerChat />
        </CabinetRoute>

        <CabinetRoute
          path={`${CabinetNavigatorPathAdmin.ORDER_CHAT}/:orderId${CabinetNavigatorPathVendor.OFFER}/:offerId`}
        >
          <OrderAdminCustomerChat />
        </CabinetRoute>
        <CabinetRoute path={CabinetNavigatorPathVendor.ACTIVITY}>
          <ActivityPage />
        </CabinetRoute>
        <CabinetRoute path={CabinetNavigatorPathVendor.SELLER_PAGE_DESIGN}>
          <SallersPageDesignPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[
            PermissionEnum.EDIT_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
          path={`${CabinetNavigatorPathVendor.ITEMS_EDIT}/:id`}
        >
          <EditItemPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS]}
          path={`${CabinetNavigatorPathVendor.OFFER_EDIT}/:id`}
        >
          <OfferEditItemPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          permissions={[
            PermissionEnum.CREATE_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
          path={CabinetNavigatorPathVendor.ITEMS_CREATE}
        >
          <CreateItemPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          path={CabinetNavigatorPathVendor.CREATE_COLLECTIONS}
          permissions={[
            PermissionEnum.CREATE_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
        >
          <CreateCollectionPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          path={`${CabinetNavigatorPathVendor.EDIT_COLLECTIONS}/:id`}
          permissions={[
            PermissionEnum.EDIT_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
        >
          <EditCollectionPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          path={CabinetNavigatorPathVendor.CREATE_VEDDOR_BANNER}
        >
          <CreateVendorBannerPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          path={`${CabinetNavigatorPathVendor.EDIT_VEDDOR_BANNER}/:id`}
        >
          <EditVendorBannerPage />
        </CabinetRoute>
        <CabinetRoute
          exact
          path={CabinetNavigatorPathVendor.ORDER_CONTROL}
          permissions={[
            PermissionEnum.VIEW_ORDER,
            PermissionEnum.MANAGE_OWN_ORDER_STATUS
          ]}
        >
          <OrderControlPage />
        </CabinetRoute>
        <CabinetRoute exact path={CabinetNavigatorPathVendor.BILLINGS}>
          <BillingsPage />
        </CabinetRoute>
        <CabinetRoute path={CabinetNavigatorPathVendor.ORDERS_ACTIVITY}>
          <OrdersActivityPage />
        </CabinetRoute>
        <CabinetRoute path={CabinetNavigatorPathVendor.FEEDBACK_MONITORING}>
          <FeedbackMonitoringPage />
        </CabinetRoute>
        <Route path={OptionsNavigatorPathes.SUBSCRIPTION}>
          <SubscriptionPage />
        </Route>
        <CabinetRoute exact path="/cabinet"></CabinetRoute>
        <CabinetRoute exact path="/"></CabinetRoute>
        <Route component={NotFound} />
      </Switch>
    </Column>
  )
}
