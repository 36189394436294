import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`
  },
  tableTopContainer: {
    padding: 16
  },
  search: {
    width: '100%'
  },
  header: {
    height: 48,
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px'
  },
  textCheckBox: {
    marginLeft: 24
  },
  button: {
    marginLeft: 8
  },
  duplicateBtnContainer: {
    padding: '4px 16px 4px 40px'
  },
  remove: {
    cursor: 'pointer',
    padding: '8px 16px'
  }
}))
