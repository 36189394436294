import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useApi, useToastify } from '../../providers'
import { CollectionCreateInput } from '../../services'

import {
  CabinetNavigatorPathVendor,
  ItemsTabNavigation
} from '../cabinet-navigator'
import { CollectionForm } from '../collection-form'

export const CreateCollectionPage = () => {
  const { open } = useToastify()
  const history = useHistory()
  const { collection: collectionApi } = useApi()
  const { response: createResponse, onSubmit } =
    collectionApi.useCollectionCreate()

  useEffect(() => {
    if (createResponse && createResponse.data) {
      open({
        text: 'Successful, changes have been saved',
        tx: 'successfull.message'
      })

      history.push(
        `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.COLLECTIONS}`,
        { banner: createResponse.data.createCollection }
      )
    }
  }, [createResponse])

  const handleOnSubmit = (input: CollectionCreateInput) => {
    onSubmit({ input })
  }

  const handleOnCancel = () => {
    history.push(
      `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.COLLECTIONS}`
    )
  }

  return (
    <CollectionForm
      title="Create Collection"
      titleTx="create.collections.create"
      onCancel={handleOnCancel}
      onSubmit={handleOnSubmit}
    />
  )
}
