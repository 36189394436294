import React, { FC } from 'react'

import { Section } from '../../../components'
import { OfferOptionTable } from '../../../pages/views'

import { useStyle } from './offer-option-section.styles'
import { OfferOptionSectionProps } from './offer-option-section.types'

export const OfferOptionSection: FC<OfferOptionSectionProps> = ({
  title,
  type,
  optionVariants,
  onChangeTable
}) => {
  const classes = useStyle()

  return (
    <Section
      className={classes.sectionItem}
      titleContainerClassName={classes.titleContainerItem}
      title={title}
      titlePreset="h4"
    >
      {optionVariants && (
        <OfferOptionTable
          type={type}
          data={optionVariants}
          onChange={onChangeTable}
        />
      )}
    </Section>
  )
}
