import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  dot: {
    margin: 9
  },
  color: {
    border: `1px solid ${theme.colors.greyFon}`,
    '&:not(:first-child)': {
      marginLeft: 8
    }
  },
  text: {
    marginLeft: 8
  },
  extraRow: {
    columnGap: 4
  }
}))
