import React, { FC, MouseEvent, useState } from 'react'
import { useTheme } from 'react-jss'

import { LightTheme } from '../../../components/Theme'
import {
  Button,
  Checkbox,
  ColorDot,
  getCurrencySymbol,
  getFullName,
  getPriceToFixed,
  Row,
  Text
} from '../../../components'
import { getColorByOfferStatus, getMessageByOfferStatus } from '../../../utils'

import { useStyle } from './offers-table-item.styles'
import { OffersTableItemProps } from './offers-table-item.types'

export const OffersTableItem: FC<OffersTableItemProps> = ({
  id,
  offer,
  quantity,
  product,
  customer,
  number,
  status,
  checked,
  onClick,
  onChange,
  onContactCustomer
}) => {
  const theme: LightTheme = useTheme()
  const [hoverButton, changeHoverButton] = useState(false)
  const classes = useStyle({ hoverButton })
  const offerNumber = String(number)
  const offerQuantity = String(quantity)
  const customerName = getFullName(customer)
  const currencySymbol =
    product.currency && getCurrencySymbol(product?.currency)

  const offerStatusColor = getColorByOfferStatus(status)
  const offerProductionDays =
    offer.productionDays !== null ? String(offer.productionDays) : '-'

  const deliveryPrice = `${currencySymbol}${getPriceToFixed(
    offer.deliveryPrice
  )}`

  const offerTotalPrice =
    `${currencySymbol}${getPriceToFixed(offer.totalPrice)}` || '-'

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  const handleOnHover = () => {
    changeHoverButton(true)
  }
  const handleOnMouseLeave = () => {
    changeHoverButton(false)
  }

  const handleOnChange =
    (nextId: string) =>
    (state: boolean, event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      if (onChange) {
        onChange(nextId, state)
      }
    }

  const src = product.thumbnail2x?.url

  return (
    <Row
      fullWidth
      key={`offer_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
      onClick={handleOnClick}
    >
      <Row className={classes.tableItem} justifyContent="flex-start">
        <Checkbox checked={checked} onChange={handleOnChange(id)} />
        <img className={classes.image} height={48} width={48} src={src} />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
        justifyContent="flex-start"
      >
        {product.name && (
          <Text
            className={classes.textHiden}
            color="black"
            preset="body"
            text={product.name}
          />
        )}
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnMin}`}
        justifyContent="flex-start"
      >
        <Text color="black" preset="body" text={offerNumber} />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnMin}`}
        justifyContent="flex-start"
      >
        <Text color="black" preset="body" text={offerProductionDays} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="body" text={deliveryPrice} />
      </Row>
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnMin}`}
        justifyContent="flex-start"
      >
        <Text color="black" preset="body" text={offerQuantity} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Text color="black" preset="body" text={offerTotalPrice} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {customer && (
          <Text
            className={classes.textHiden}
            color="black"
            preset="body"
            text={customerName}
          />
        )}
      </Row>
      <Row className={classes.tableColumnStatus} justifyContent="flex-start">
        <ColorDot color={theme.colors[offerStatusColor]} />
        <Text
          className={classes.statusText}
          color="black"
          preset="body"
          {...getMessageByOfferStatus(status)}
        />
      </Row>
      <Row
        className={classes.button}
        justifyContent="flex-start"
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnMouseLeave}
      >
        <Button
          className={classes.contactVendor}
          preset="secondaryBorderMini"
          text="Contact Customer"
          tx="offer.form.button.contactCustomer"
          textColor="yellow"
          textPreset="maxButton"
          onClick={onContactCustomer}
        />
      </Row>
    </Row>
  )
}
