import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { ModalBannerPreviewProps } from './modal-banner-preview.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: `${theme.colors.black}`,
    position: 'fixed',
    top: 0,
    left: 0,
    height: 'calc(100vh)',
    width: '100vw',
    zIndex: 100
  },
  modal: {
    position: 'absolute',
    maxHeight: 400,
    maxWidth: 600,
    left: '15%',
    zIndex: 99
  },
  modalMaxWidth: {
    maxWidth: 348
  },
  mainText: {
    textAlign: 'center'
  },
  text: {
    marginTop: 8
  },
  secondaryHeadingText: {
    marginTop: 2
  },
  notification: {
    padding: '16px 80px',
    backgroundColor: `${theme.colors.blue}`
  },
  notificationMob: {
    padding: '12px'
  },
  button: {
    maxWidth: 400,
    minWidth: 96,
    minHeight: 40,
    marginTop: 24
  },
  notificationBtn: {
    marginTop: 0
  },
  banerContainer: {
    width: '100%',
    maxHeight: 576
  },
  promotedBannerContainer: {
    maxHeight: 480
  },
  premiumBannerContainer: {
    height: 408,
    width: 1024
  },
  topBannerContainer: {
    maxWidth: 800,
    height: 532
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  header: {
    backgroundColor: `${theme.colors.white}`,
    position: 'absolute',
    top: 0,
    zIndex: 1
  },
  icon: {
    cursor: 'pointer',
    padding: '24px 12px'
  },
  iconWeb: {
    borderBottom: ({ active }: ModalBannerPreviewProps) =>
      active
        ? `4px solid ${theme.colors.white}`
        : `4px solid ${theme.colors.yellow}`
  },
  iconMob: {
    borderBottom: ({ active }: ModalBannerPreviewProps) =>
      active
        ? `4px solid ${theme.colors.yellow}`
        : `4px solid ${theme.colors.white}`
  },
  iconClose: {
    cursor: 'pointer',
    position: 'absolute',
    right: 32
  },
  mobileContainer: {
    backgroundColor: `${theme.colors.white}`,
    width: 375,
    height: 552
  },
  mobileModal: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    position: 'absolute',
    bottom: 0,
    padding: 12
  },
  mobileModalView: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    top: 0
  },
  notificationText: {
    maxWidth: 240
  }
}))
