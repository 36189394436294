import React, { forwardRef } from 'react'

import { useStyle } from './column.styles'
import { ColumnProps } from './column.types'

export const Column = forwardRef<HTMLDivElement, ColumnProps>(
  (
    {
      alignItems,
      children,
      className = '',
      justifyContent,
      fullHeight,
      fullWidth,
      ...props
    },
    ref
  ) => {
    const classes = useStyle({
      alignItems,
      justifyContent,
      fullHeight,
      fullWidth
    })

    return (
      <div
        className={`${className || ''} ${classes.column}`}
        {...props}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)
