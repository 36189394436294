import React, { FC, useState } from 'react'

import { QuestionModal, VendorRadioBox } from '../../../components'
import { User } from '../../../services'

import { VendorPickModalProps } from './vendor-pick-modal.types'

export const VendorPickModal: FC<VendorPickModalProps> = ({
  defaultActive,
  totalCount,
  data,
  onClose,
  onSubmit,
  onLoadMore,
  onRefresh,
  onSearch
}) => {
  const [active, changeActive] = useState<User | undefined>(defaultActive)

  const handleOnChange = (user: User) => {
    changeActive(user)
  }

  const handleOnSubmit = () => {
    if (active && onSubmit) {
      onSubmit(active)
    }
  }

  return (
    <QuestionModal
      title="Assign Vendor"
      onClose={onClose}
      onSubmit={handleOnSubmit}
    >
      <VendorRadioBox
        active={active?.id}
        data={data}
        totalCount={totalCount}
        onFetchMore={onLoadMore}
        onChange={handleOnChange}
        onSearch={onSearch}
        onRefresh={onRefresh}
      />
    </QuestionModal>
  )
}
