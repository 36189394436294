import React from 'react'
import { useHistory } from 'react-router-dom'
import { useStyle } from '../forgot-password/forgot-password.styles'

import { Button, Column, NavLink, Row, Text } from '../../../components'
import { AuthRoutes } from '../../urls'

export const ResetPasswordSuccessPage: React.FC = () => {
  const history = useHistory()
  const classes = useStyle()
  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Column fullWidth className={classes.container}>
      <>
        <Column className={classes.forgotForm}>
          <Text
            preset="h1"
            text="Successful password reset!"
            className={classes.centerText}
          />
          <Text
            className={classes.forgotSecondaryText}
            color="inactive"
            preset="h7"
            text="You can now use your new password to log in to your account!"
          />
          <Button
            className={classes.logIn}
            text="LOG IN"
            preset="primary"
            textColor="white"
            textPreset="h5"
            onClick={handleOnNavigate('/')}
          />
          <Row className={classes.signUpContainer}>
            <Text
              color="black"
              preset="secondTitle"
              text="Don’t have an account?"
            />
            <NavLink
              className={classes.signUp}
              color="blue"
              preset="h6"
              name="Sign Up"
              to={AuthRoutes.REGISTER}
            />
          </Row>
        </Column>
      </>
    </Column>
  )
}

ResetPasswordSuccessPage.displayName = 'ResetPasswordSuccessPage'
export default ResetPasswordSuccessPage
