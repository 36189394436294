import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: 20
  },
  title: {
    marginTop: 24
  },
  delete: {
    display: 'flex',
    width: '100%',
    marginTop: 40,
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: '40px 0 40px 0',
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))
