import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { AttentionStyle } from './attention.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundColor: ({ color = 'error' }: AttentionStyle) =>
      `${theme.colors[color]}20`,
    borderRadius: 4,
    padding: '8px 16px'
  },
  overflow: {
    background: theme.colors.white
  },
  text: {
    marginLeft: 16
  }
}))
