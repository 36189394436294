import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

import { InfoTooltipStyleProps } from './info-tooltip.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  tooltipContainer: {
    position: 'absolute',
    zIndex: 2,
    left: ({ isBottom }: InfoTooltipStyleProps) => (isBottom ? 'auto' : 32),
    top: ({ isBottom }: InfoTooltipStyleProps) => (isBottom ? 32 : 'auto'),
    maxWidth: 312,
    backgroundColor: theme.colors.vendorProfileFon,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    boxShadow: `4px 8px 16px ${theme.colors.shadow}`
  },
  leftIcon: {
    position: 'absolute',
    bottom: -12,
    left: -9,
    top: 'calc(50% - 6px)'
  },
  topIcon: {
    position: 'absolute',
    top: -9
  },
  text: {
    width: 312,
    padding: 8
  }
}))
