import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {},
  firstColumn: {
    flex: 1.2,
    marginLeft: 24
  },
  column: {
    flex: 1
  },
  button: {
    marginLeft: 24
  },
  cancelRow: {
    flex: 1
  }
})
