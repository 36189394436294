import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  offersRow: {
    marginTop: 16
  },
  name: {
    width: 280
  },
  value: {
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  text: {
    minWidth: 100,
    textAlign: 'end'
  }
})
