import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../Theme'

import { DropdownItemsStyleProps } from '../dropdown-items.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  itemsContainer: {
    borderRadius: 4,
    padding: 8,
    position: 'absolute',
    zIndex: 1,
    overflow: 'hidden',
    backgroundColor: theme.colors.white
  },
  left: {
    top: 0,
    right: ({ width }: DropdownItemsStyleProps) => width
  },
  right: {
    top: 0,
    left: ({ width }: DropdownItemsStyleProps) => width
  },
  bottom: {
    top: ({ height }: DropdownItemsStyleProps) => height
  }
}))
