import React, {
  ChangeEvent,
  FC,
  FocusEvent,
  useEffect,
  useRef,
  useState
} from 'react'

import { useDefaultValue } from '../../hooks'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Row } from '../row'
import { Text } from '../text'
import { Color } from '../Theme'

import { useStyle } from './text-area.styles'
import { TextAreaProps } from './text-area.types'

export const TextArea: FC<TextAreaProps> = ({
  preset = 'main',
  className = '',
  isSecure,
  error,
  label,
  labelTx,
  valuesTx,
  defaultValue = '',
  value: outerValue,
  textAreaInputPreset,
  onChange,
  onFocus,
  onBlur,
  ...props
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const [heightValue, changeHeightValue] = useState<number>()
  const [visible, changeVisible] = useState(!isSecure)
  const [focus, changeFocus] = useState(Boolean(defaultValue))

  const Value = useDefaultValue({ value: inputRef.current?.value, outerValue })
  const activeTextArea = !focus && !Value
  const activeLabelColor: Color = activeTextArea ? 'inactive' : 'yellow'
  const secureIcon = visible ? ICONS.removeRedEye : ICONS.visibilityOff

  const classes = useStyle({
    password: isSecure && !visible,
    focus,
    value: Value,
    height: heightValue,
    error
  })

  useEffect(() => {
    changeHeightValue(inputRef.current?.scrollHeight)
  }, [inputRef.current?.value])

  const handleOnVisible = () => {
    changeVisible(!visible)
  }

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value: nextValue } = event.target

    if (nextValue) {
      changeFocus(true)
    }

    if (onChange) {
      onChange(event)
    }
  }

  const handleOnFocus = (event: FocusEvent<HTMLTextAreaElement>) => {
    changeFocus(true)

    if (onFocus) {
      onFocus(event)
    }
  }

  const handleOnBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
    if (!Value) {
      changeFocus(false)
    }

    if (onBlur) {
      onBlur(event)
    }
  }

  const handleOnClickContainer = () => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <Row
      className={`${className} ${classes.container} ${classes[preset]}`}
      justifyContent="space-between"
      onClick={handleOnClickContainer}
    >
      <Column fullWidth alignItems="flex-start" justifyContent="flex-start">
        <Text
          className={classes.areaLabel}
          color={activeLabelColor}
          preset="subscriptionText"
          text={label}
          tx={labelTx}
          values={valuesTx}
        />
        <textarea
          {...props}
          value={Value}
          ref={inputRef}
          defaultValue={defaultValue}
          className={classes.textArea}
          onChange={handleOnChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
      </Column>
      {isSecure && Value && (
        <Icon
          className={classes.icon}
          color="black"
          src={secureIcon}
          onClick={handleOnVisible}
        />
      )}
    </Row>
  )
}
