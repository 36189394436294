import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  tableColumn: {
    flex: 2,
    minHeight: 64,
    padding: '8px 16px'
  },
  minTableColumn: {
    flex: 1.2
  },
  maxTableColumn: {
    flex: 3
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    cursor: 'pointer',
    minHeight: 64,
    '&:hover': {
      background: theme.colors.lightBlue
    }
  },
  statusText: {
    marginLeft: 8
  },
  text: {
    marginLeft: 6
  }
}))
