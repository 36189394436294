import { Edge, PaginationList } from './api.types'

export type ItemWithId = {
  id: string
}

export type QueryPaginationList<T> = Record<string, PaginationList<T>>

export const addNewItems = <T extends ItemWithId>(
  data: Edge<T>[],
  items: T[]
): Edge<T>[] => {
  const paginationItems = items.map((item) => ({ node: item, cursor: '' }))
  return [...paginationItems, ...data]
}

export const addNewItem = <T extends ItemWithId>(
  data: Edge<T>[],
  item: T
): Edge<T>[] => {
  return [{ node: item, cursor: '' }, ...data]
}

export const updateById = <T extends ItemWithId>(
  data: Edge<T>[],
  newItem: T
): Edge<T>[] => {
  return data.map((prevItem) => {
    if (prevItem.node.id === newItem.id) {
      return {
        ...prevItem,
        node: newItem
      }
    }
    return prevItem
  })
}

export const updateItemsByIds = <T extends ItemWithId>(
  data: Edge<T>[],
  newItems: T[]
): Edge<T>[] => {
  return data.map((prevItem) => {
    const active = newItems.find((item) => item.id === prevItem.node.id)

    if (active) {
      return {
        ...prevItem,
        node: active
      }
    }

    return prevItem
  })
}

export const removeById = <T extends ItemWithId>(
  data: Edge<T>[],
  id: string
): Edge<T>[] => {
  return data.filter((prevItem) => prevItem.node.id !== id)
}

export const removeItemsByIds = <T extends ItemWithId>(
  data: Edge<T>[],
  ids: string[]
): Edge<T>[] => {
  return data.filter((prevItem) => {
    const active = ids.find((id) => prevItem.node.id === id)

    return !active
  })
}

export const addFromQueryNewItems = <
  V extends ItemWithId,
  T extends QueryPaginationList<V>
>(
  queryName: keyof T,
  data: T,
  items?: V[]
): T => {
  if (!items) {
    return data
  }

  return {
    ...data,
    [queryName]: {
      ...data[queryName],
      edges: addNewItems(data[queryName].edges, items)
    }
  }
}

export const addFromQueryNewItem = <
  V extends ItemWithId,
  T extends QueryPaginationList<V>
>(
  queryName: keyof T,
  data: T,
  item?: V
): T => {
  if (!item) {
    return data
  }

  return {
    ...data,
    [queryName]: {
      ...data[queryName],
      edges: addNewItem(data[queryName].edges, item)
    }
  }
}

export const updateFromQueryById = <
  V extends ItemWithId,
  T extends QueryPaginationList<V>
>(
  queryName: keyof T,
  data: T,
  item?: V
): T => {
  if (!item) {
    return data
  }

  return {
    ...data,
    [queryName]: {
      ...data[queryName],
      edges: updateById(data[queryName].edges, item)
    }
  }
}

export const updateFromQueryByIds = <
  V extends ItemWithId,
  T extends QueryPaginationList<V>
>(
  queryName: keyof T,
  data: T,
  items?: V[]
): T => {
  if (!items) {
    return data
  }

  return {
    ...data,
    [queryName]: {
      ...data[queryName],
      edges: updateItemsByIds(data[queryName].edges, items)
    }
  }
}

export const removeFromQueryById = <
  V extends ItemWithId,
  T extends QueryPaginationList<V>
>(
  queryName: keyof T,
  data: T,
  id?: string
): T => {
  const nextId = id || ''
  return {
    ...data,
    [queryName]: {
      ...data[queryName],
      edges: removeById(data[queryName].edges, nextId)
    }
  }
}

export const removeFromQueryByIds = <
  V extends ItemWithId,
  T extends QueryPaginationList<V>
>(
  queryName: keyof T,
  data: T,
  ids?: string[]
): T => {
  if (!ids) {
    return data
  }

  return {
    ...data,
    [queryName]: {
      ...data[queryName],
      edges: removeItemsByIds(data[queryName].edges, ids)
    }
  }
}
