import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

import { ColorDotStyle } from './color-dot.types'

const size = {
  productColor: 21,
  big: 16,
  default: 8
}

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: ({ color = 'black', preset = 'default' }: ColorDotStyle) => ({
    background: color,
    border: `1px solid ${theme.colors.greyFon}`,
    borderRadius: '50%',
    minHeight: size[preset],
    minWidth: size[preset]
  })
}))

export type ColorDotSize = keyof typeof size
