import React, { FC, MouseEvent, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useTheme } from 'react-jss'

import { usePortal } from '../../hooks'

import { LightTheme } from '../Theme'
import { Row } from '../row'
import { Column } from '../column'
import { Text } from '../text'
import { Icon, ICONS } from '../icon'

import { useStyle } from './toastify.styles'
import { ToastifyProps } from './toastify.types'
import { getTypeOfToast } from './helpers'

export const ToastifyComponent: FC<ToastifyProps> = ({
  open,
  text,
  type = 'SUCCESS',
  tx,
  values,
  onClose
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const width = containerRef.current?.offsetWidth || 0
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ open, width, theme })
  const target = usePortal('logout')
  const classToastType = getTypeOfToast(classes, type)

  const handleOnPropagationBackground = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()
  }

  return ReactDOM.createPortal(
    <Column
      className={`${classToastType} ${classes.container}`}
      onClick={onClose}
      alignItems="flex-start"
    >
      <Column fullWidth onClick={handleOnPropagationBackground}>
        <Row fullWidth justifyContent="space-between">
          <Text preset="body" text={text} tx={tx} values={values} />
          <Icon src={ICONS.close} onClick={onClose} />
        </Row>
      </Column>
    </Column>,
    target
  )
}
