import React, { FC } from 'react'

import { AdvertisingType } from '../../../services/advertising'

import { AdvertisingBannerView, AdvertisingProductView } from '../../views'

import { ViewByAdvertisingTypeProps } from './view-by-advertising-type.types'

export const ViewByAdvertisingType: FC<ViewByAdvertisingTypeProps> = ({
  children,
  type,
  productId,
  bannerId,
  product,
  banner,
  onChangeProduct,
  onChangeBanner,
  onCreateBanner,
  onCreateProduct
}) => {
  switch (type) {
    case AdvertisingType.TOP_PRODUCT:
      return (
        <AdvertisingProductView
          productId={productId}
          product={product}
          onChange={onChangeProduct}
          onCreateProduct={onCreateProduct}
        >
          {children}
        </AdvertisingProductView>
      )
    case AdvertisingType.BANNER:
      return (
        <AdvertisingBannerView
          bannerId={bannerId}
          banner={banner}
          onChange={onChangeBanner}
          onCreateBanner={onCreateBanner}
        >
          {children}
        </AdvertisingBannerView>
      )
    default:
      return (
        <AdvertisingProductView
          productId={productId}
          product={product}
          onChange={onChangeProduct}
          onCreateProduct={onCreateProduct}
        >
          {children}
        </AdvertisingProductView>
      )
  }
}
