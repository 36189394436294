import { MutationResult } from '@apollo/client'
import { DateRangeInput, ListVariables, PaginationList } from '../api.types'
import {
  OrdersVariables,
  Order,
  OrderSortingInput,
  OrderMainStatus
} from '../order'

export enum PaymentChargeStatus {
  NOT_CHARGED = 'NOT_CHARGED',
  PENDING = 'PENDING',
  PARTIALLY_CHARGED = 'PARTIALLY_CHARGED',
  FULLY_CHARGED = 'FULLY_CHARGED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  FULLY_REFUNDED = 'FULLY_REFUNDED',
  REFUSED = 'REFUSED',
  CANCELLED = 'CANCELLED'
}

export enum PaymentErrorCode {
  BILLING_ADDRESS_NOT_SET = 'BILLING_ADDRESS_NOT_SET',
  GRAPHQL_ERROR = 'GRAPHQL_ERROR',
  INVALID = 'INVALID',
  NOT_FOUND = 'NOT_FOUND',
  REQUIRED = 'REQUIRED',
  UNIQUE = 'UNIQUE',
  PARTIAL_PAYMENT_NOT_ALLOWED = 'PARTIAL_PAYMENT_NOT_ALLOWED',
  SHIPPING_ADDRESS_NOT_SET = 'SHIPPING_ADDRESS_NOT_SET',
  INVALID_SHIPPING_METHOD = 'INVALID_SHIPPING_METHOD',
  SHIPPING_METHOD_NOT_SET = 'SHIPPING_METHOD_NOT_SET',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  NOT_SUPPORTED_GATEWAY = 'NOT_SUPPORTED_GATEWAY'
}

export interface PaymentError {
  code: PaymentErrorCode
  field: string
  message: string
}

export enum PayoutPaymentStatusFilter {
  PROCESS = 'PROCESS',
  PENDING = 'PENDING',
  BLOCKED = 'BLOCKED',
  CANCELED = 'CANCELED',
  DENIED = 'DENIED',
  FAILED = 'FAILED',
  HELD = 'HELD',
  REFUNDED = 'REFUNDED',
  RETURNED = 'RETURNED',
  SUCCEEDED = 'SUCCEEDED',
  UNCLAIMED = 'UNCLAIMED'
}

export type PayoutFilterInput = {
  mainStatus?: OrderMainStatus[]
  paymentStatus?: PayoutPaymentStatusFilter[]
  customer?: string[]
  vendor?: string[]
  created?: DateRangeInput
  search?: string
  companyName?: string[]
}

export type PayoutFiltersKeys = keyof PayoutFilterInput

export interface PayoutsVariables extends ListVariables<PayoutFilterInput> {
  sortBy?: OrderSortingInput
}

export type BillingVariables = OrdersVariables

export interface Payout extends Order {
  paypalOrder: string
  customerPayment: string
  referralFee: string
  sellingFee: string
  promotionalFee: string
  vendorPayment: string
}

export type CreatePaypalOrderVariables = {
  orderId: string
  paypalOrderId: string
}

export interface PayoutsDataWithFilters extends PaginationList<Payout> {
  filters: string
}

export interface BillingDataWithFilters extends PaginationList<Payout> {
  filters: string
}

export type GetPayoutsData = {
  payouts: PayoutsDataWithFilters
}

export type GetBillingData = {
  billing: BillingDataWithFilters
}

export type CreatePaypalOrderData = {
  createPaypalOrder: {
    paymentErrors: PaymentError[]
  }
}

export type GetPayouts = {
  data?: GetPayoutsData | null
  loading: boolean
  refetch: (variables?: Partial<PayoutsVariables>) => void
}

export type GetBilling = {
  data?: GetBillingData | null
  loading: boolean
  refetch: (variables?: Partial<BillingVariables>) => void
}

export type CreatePaypalOrder = {
  onSubmit: (variables: CreatePaypalOrderVariables) => void
  response: MutationResult<CreatePaypalOrderData>
}

export type PaymentApi = {
  useCreatePaypalOrder: () => CreatePaypalOrder
  usePayouts: (variables: PayoutsVariables) => GetPayouts
  useBilling: (variables: BillingVariables) => GetBilling
}
