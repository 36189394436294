import React, { FC } from 'react'

import { Text } from '../text'
import { MenuSlider } from '../menu-slider'
import { RadioBox } from '../radio-box'
import { Row } from '../row'

import { FilterRadioListProps } from './filter-radio-list.types'

export const FilterRadioList: FC<FilterRadioListProps> = ({
  className = '',
  defaultOpen,
  title,
  titleTx,
  onOpen,
  ...checkboxProps
}) => {
  const header = <Text preset="h5" text={title} tx={titleTx} />
  return (
    <MenuSlider
      className={className}
      defaultOpen={defaultOpen}
      header={header}
      onOpen={onOpen}
    >
      <Row fullWidth justifyContent="flex-start" alignItems="flex-start">
        <RadioBox {...checkboxProps} />
      </Row>
    </MenuSlider>
  )
}
