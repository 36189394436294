import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { useStyle } from './activity-item.styles'
import { ActivityItemProps } from './activity-item.types'
import { LightTheme } from '../../../components/Theme'
import { Column, Row, Text, ColorDot } from '../../../components'

export const ActivityItem: FC<ActivityItemProps> = ({
  title,
  titleTx,
  event,
  values,
  time
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Row fullWidth justifyContent="flex-start" className={classes.activityItem}>
      <Column alignItems="flex-start">
        <Row fullWidth justifyContent="flex-start">
          <ColorDot color={theme.colors.yellow} />
          <Text
            color="black"
            text={title}
            tx={titleTx}
            values={values}
            preset="h5"
            className={classes.activityTextTitle}
          />
          <Text color="black" text={event} preset="body" />
        </Row>
        <Row
          justifyContent="flex-start"
          className={classes.activityTextSubtitle}
        >
          <Text
            color="inactive"
            text={String(time)}
            values={{ time }}
            preset="body"
          />
        </Row>
      </Column>
    </Row>
  )
}
