import React, { useMemo, useState } from 'react'

import { Column, Row, Text } from '../../components'
import { useTable } from '../../hooks'
import { SortVariables, useSort } from '../../hooks/useSort'
import { useApi } from '../../providers'
import {
  OrderDirection,
  OrdersFiltersParseJSON,
  OrderSortField,
  parseFilterItems,
  PayoutsVariables
} from '../../services'

import { PayoutsFilters } from './payouts-filters'
import { PayoutsTable, PayoutsTableProps } from './payouts-table'
import { useStyle } from './payouts.styles'

const rowCounts = [5, 10, 20, 30]

export const PayoutsPage = () => {
  const classes = useStyle()
  const [payoutsVariables, changePayoutsVariables] = useState<PayoutsVariables>(
    {
      first: rowCounts[1],
      sortBy: { direction: OrderDirection.DESC, field: OrderSortField.NUMBER }
    }
  )
  const defaultVariables = {
    first: rowCounts[1]
  }

  const { payment } = useApi()
  const { data, refetch, loading } = payment.usePayouts(defaultVariables)
  const {
    Data,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch,
    onSubmitFilters,
    onResetFilters,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.payouts,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  const Filters = useMemo(() => {
    if (data && data.payouts.filters) {
      const list = parseFilterItems<OrdersFiltersParseJSON>(
        data.payouts.filters
      )

      if (list) {
        return list
      }
    }

    return { customers: [], vendors: [], companyNames: [] }
  }, [data])

  const handleOnSearch: PayoutsTableProps['onChangeSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }
  const handleOnSort = (sortVariables: SortVariables<OrderSortField>) => {
    refetch({
      ...sortVariables,
      before: undefined,
      after: undefined,
      last: undefined
    })

    onResetPageState()
    changePayoutsVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: payoutsVariables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="space-between"
      >
        <Text
          color="black"
          preset="h3"
          text="Payouts"
          tx="cabinet.route.admin.payouts.title"
        />
        <PayoutsFilters
          filters={Filters}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
        />
      </Row>
      <PayoutsTable
        className={classes.table}
        rowCounts={rowCounts}
        data={Data}
        loading={loading}
        paginationState={paginationState}
        onChangeSearch={handleOnSearch}
        onChangePage={onChangePage}
        onChangeRowCount={onChangeRowCount}
        onNextPage={onNextPage}
        onPrevioslyPage={onPrevioslyPage}
        onSort={onSort}
      />
    </Column>
  )
}
