import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../../column'
import { Row } from '../../row'

import { LightTheme } from '../../Theme'
import { useStyle } from '../modal-banner-preview.styles'

import { TopProductBannerPreviewProps } from './top-product-banner-preview.types'

export const TopProductBanerPreview: FC<TopProductBannerPreviewProps> = ({
  image,
  onClose
}) => {
  const theme = useTheme<LightTheme>()
  const classes = useStyle({ theme })

  return (
    <Column className={classes.container} onClick={onClose}>
      <Row className={classes.topBannerContainer}>
        {image && <img className={classes.image} src={image}></img>}
      </Row>
    </Column>
  )
}
