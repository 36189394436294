import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  tableItem: {
    padding: '8px 16px'
  },
  avatar: {
    marginLeft: 16,
    minWidth: 48
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px'
  },
  tableColumnFirst: {
    flex: 2
  },
  tableColumnLast: {
    flex: 1
  },
  emptyRow: {
    width: 102
  }
})
