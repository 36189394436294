import { ToastifyTypeStringify } from './toastify.types'

export const getTypeOfToast = (classes: any, state: ToastifyTypeStringify) => {
  switch (state) {
    case 'SUCCESS':
      return classes.success
    case 'ERROR':
      return classes.error
    default:
      return ''
  }
}
