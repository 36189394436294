import { gql } from '@apollo/client'

import {
  CATEGORY_FRAGMENT,
  BASIC_PRODUCT_FRAGMENT,
  PRODUCT_PRICING_FRAGMENT
} from '../fragments'

export const CATEGORY_BY_ID = gql`
  ${CATEGORY_FRAGMENT}
  ${BASIC_PRODUCT_FRAGMENT}
  ${PRODUCT_PRICING_FRAGMENT}
  query CategoryById($id: ID!) {
    category(id: $id) {
      ...CategoryFragment
      children(first: 6) {
        edges {
          node {
            ...CategoryFragment
            children(first: 6) {
              edges {
                node {
                  ...CategoryFragment
                }
              }
            }
          }
        }
      }
      topProducts(first: 20) {
        totalCount
        edges {
          node {
            ...BasicProductFields
            ...ProductPricingField
            category {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const CATEGORIES_QUERY = gql`
  ${CATEGORY_FRAGMENT}
  query Categories(
    $before: String
    $after: String
    $first: Int!
    $last: Int
    $filter: CategoryFilterInput
    $level: Int
  ) {
    categories(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
      level: $level
    ) {
      edges {
        node {
          ...CategoryFragment
          parent {
            ...CategoryFragment
            parent {
              ...CategoryFragment
            }
          }
          children(first: 6) {
            edges {
              node {
                ...CategoryFragment
                children(first: 6) {
                  edges {
                    node {
                      ...CategoryFragment
                    }
                  }
                }
              }
            }
          }
        }
        cursor
      }
      totalCount
    }
  }
`
