import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  CabinetNavigatorPathVendor,
  SalerPageDesignNavigation
} from '../cabinet-navigator'
import { useApi } from '../../providers'
import { VendorBannerCreateInput } from '../../services'

import { VendorBannerForm } from '../vendor-banner-form'
import { VendorBannerState } from '../vendor-banner'

export const CreateVendorBannerPage = () => {
  const { menu } = useApi()
  const { response, onSubmit } = menu.useVendorBannerCreate()
  const { response: responseImages, onSubmit: onSubmitImage } =
    menu.useVendorBannerImageCreate()
  const [image, changeImage] = useState<File>()
  const history = useHistory<VendorBannerState>()

  useEffect(() => {
    if (image) {
      if (response.data && response.data.vendorBannerCreate.vendorBanner) {
        const { id } = response.data.vendorBannerCreate.vendorBanner
        const variables = { input: { banner: id, image } }
        onSubmitImage(variables)
      }
    }
  }, [response.data])

  useEffect(() => {
    if (responseImages.data) {
      const { banner: nextBanner } = responseImages.data.vendorBannerImageCreate
      const tablePath = `${CabinetNavigatorPathVendor.SELLER_PAGE_DESIGN}${SalerPageDesignNavigation.SALER_PAGE_BANNER}`
      history.push(tablePath, {
        vendorBanner: nextBanner
      })
    }
  }, [responseImages])

  const handleOnSubmit = (
    nextBanner: VendorBannerCreateInput,
    nextImage?: File
  ) => {
    if (nextBanner) {
      onSubmit(nextBanner)
      changeImage(nextImage)
    }
  }

  return (
    <VendorBannerForm
      title="Create Banner"
      titleTx="banner.create"
      onSumbit={handleOnSubmit}
    />
  )
}
