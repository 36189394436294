import { createUseStyles } from 'react-jss'
import { LightTheme } from '../../../components/Theme'
import { ContactInfoStyle } from './contact-info.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  infoContainer: {
    backgroundColor: theme.colors.white,
    borderRadius: 4,
    border: `1px solid ${theme.colors.shadow}`,
    marginTop: 20,
    justifyContent: 'flex-start'
  },
  infoTitle: {
    padding: 16,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  inputRow: {
    padding: '16px',
    columnGap: 20
  },
  input: {
    flex: 1,
    height: 58,
    padding: '1px 0'
  },
  inputPhone: {
    flex: 1,
    height: 58,
    marginTop: 16,
    borderRadius: 4,
    border: `1px solid ${theme.colors.inactive} `,
    '&:focus': {
      border: 'none'
    },
    '&:focus-within': {
      border: ({ errorStyle }: ContactInfoStyle) =>
        errorStyle
          ? `1px solid ${theme.colors.error}`
          : `2px solid ${theme.colors.yellow}`,

      borderRadius: 4
    }
  },
  inputCity: {
    flex: 1,
    height: 58,
    marginTop: 16,
    borderRadius: 4,
    border: `1px solid ${theme.colors.inactive} `,
    '&:focus': {
      border: 'none'
    },
    '&:focus-within': {
      border: ({ errorStyle }: ContactInfoStyle) =>
        errorStyle
          ? `1px solid ${theme.colors.error}`
          : `2px solid ${theme.colors.yellow}`,

      borderRadius: 4
    }
  },
  dropdown: {
    top: 0,
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: `1px solid ${theme.colors.black}`,
    maxHeight: '144px !important',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  summaryMail: {
    width: '100%',
    background: theme.colors.white,
    border: `1px solid ${theme.colors.inactive}`,
    borderRadius: 4,
    padding: '4px 24px'
  },
  mailText: {
    marginTop: 4
  },
  error: {
    position: 'absolute',
    bottom: '-16px',
    padding: '4px 0px 0px 24px'
  }
}))
