import { useQuery } from '@apollo/client'

import {
  InstructionApi,
  InstructionsVariables,
  GetInstructions,
  GetInstructionsData
} from './instruction.types'
import { INSTRUCTIONS_QUERY } from './instruction.graphql'

export const instructionServise = (): InstructionApi => {
  const useInstructions = (
    variables: InstructionsVariables
  ): GetInstructions => {
    const { data, loading, error, refetch } = useQuery<
      GetInstructionsData,
      InstructionsVariables
    >(INSTRUCTIONS_QUERY, { variables })

    return { data, error, loading, refetch }
  }

  return {
    useInstructions
  }
}
