import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  productAvatar: {
    objectFit: 'cover'
  },
  title: {
    marginLeft: 16,
    width: 300
  },
  line: {
    padding: '12px 0px',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  observeOptionsBtn: {
    marginTop: 12,
    textTransform: 'uppercase'
  },
  column: {
    maxWidth: 260
  },
  delivery: {
    wordBreak: 'break-word'
  }
})
