import React, { FC } from 'react'

import {
  Column,
  getCurrencySymbol,
  Icon,
  ProductPrice,
  RatingStarsComponent,
  Row,
  Text
} from '../../../../components'

import { getPriceWithSymbol } from '../../observe-options-offer'

import { ProductPreviewBasicProductInfoProps } from './product-preview-basic-product-info.types'
import { useStyle } from './product-preview-basic-product-info.styles'

export const ProductPreviewBasicProductInfo: FC<ProductPreviewBasicProductInfoProps> =
  ({
    favouriteIcon,
    name,
    averageRating,
    currency,
    deliveryPrice = 0,
    totalPrice
  }) => {
    const classes = useStyle()
    const currencySymbol = getCurrencySymbol(currency)
    const productDeliveryPrice = getPriceWithSymbol(
      deliveryPrice,
      currencySymbol
    )

    const isRating = averageRating || 0

    return (
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.productInfo}
      >
        <Row
          fullWidth
          justifyContent="space-between"
          className={classes.titleContainer}
        >
          <Text
            className={classes.title}
            text={name}
            preset="h4"
            color="black"
          />
          <Row className={classes.favorite}>
            <Icon src={favouriteIcon} />
          </Row>
        </Row>
        <RatingStarsComponent
          className={classes.stars}
          rating={isRating}
          edit={false}
        />
        <Row fullWidth justifyContent="space-between">
          <ProductPrice mini currency={currency} totalPrice={totalPrice} />
        </Row>
        <Row>
          <Text
            text="Delivery Price:"
            tx="product.item.card.delivery.price"
            preset="h7"
            color="inactive"
          />

          <Text
            className={classes.price}
            text={productDeliveryPrice}
            preset="h7"
            color="inactive"
          />
        </Row>
      </Column>
    )
  }
