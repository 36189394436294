import { gql } from '@apollo/client'
import {
  DELIVEY_PRICE_FRAGMENT,
  TOTAL_DELIVERY_PRICE_FRAGMENT
} from '../fragments/deliveryPrice'

export const DELIVERY_PRICE_QUERY = gql`
  ${DELIVEY_PRICE_FRAGMENT}
  query deliveryPrice(
    $before: String
    $after: String
    $first: Int
    $last: Int
  ) {
    deliveryPrice(first: $first, after: $after, last: $last, before: $before) {
      edges {
        node {
          ...DeliveryPriceFragment
        }
        cursor
      }
      totalCount
    }
  }
`

export const TOTAL_DELIVERY_PRICE_QUERY = gql`
  ${TOTAL_DELIVERY_PRICE_FRAGMENT}
  query totalDeliveryPrice(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: TotalDeliveryPriceFilterInput
  ) {
    totalDeliveryPrice(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      edges {
        node {
          ...TotalDeliveryPriceFragment
        }
        cursor
      }
      totalCount
    }
  }
`
