import { useContext } from 'react'

import { getTokens, UserContext } from '../auth'

export const useAuth = () => {
  const user = useContext(UserContext)

  const isAuthenticated = !!user.user

  return {
    accountErrors: user.accountErrors,
    signature: user.signature,
    loadingSession: user.loadingSession,
    hasToken: !!getTokens(),
    isAuthenticated,
    tokenAuthLoading: user.tokenAuthLoading,
    tokenVerifyLoading: user.tokenVerifyLoading,
    updateInfo: user.updateInfo,
    user: user.user,
    unreadMessages: user.unreadMessages
  }
}
