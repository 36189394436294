import React, { FC, MouseEvent, useState } from 'react'
import { useTheme } from 'react-jss'

import { PermissionEnum } from '../../../services'
import { LightTheme } from '../../../components/Theme'
import {
  Checkbox,
  ColorDot,
  getTotalPriceWithCurrency,
  Icon,
  ICONS,
  PermissionContent,
  Row,
  Text
} from '../../../components'

import { ProductPreview } from '../../views'

import { getColorByProductStatus, getMessageByProductStatus } from './helpers'
import { useStyle } from './items-table-item.styles'
import { ItemsTableItemProps } from './items-table-item.types'

export const ItemsTableItem: FC<ItemsTableItemProps> = ({
  id,
  category,
  status,
  name,
  product,
  isTop,
  thumbnail2x,
  checked,
  onClick,
  onChange
}) => {
  const getFullCompanyName = product.vendor.companyName
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const [openProductPreview, changeOpenProductPreview] = useState(false)

  const totalPrice = getTotalPriceWithCurrency(product)
  const colorVisible = getColorByProductStatus(status)
  const visible = getMessageByProductStatus(status)
  const src = thumbnail2x?.url

  const handleOnClick = () => {
    if (onClick) {
      onClick(id)
    }
  }

  const handleOnChange =
    (nextId: string, isTopProduct: boolean) =>
    (state: boolean, event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => {
      event.preventDefault()
      event.stopPropagation()

      if (onChange) {
        onChange(nextId, state, isTopProduct)
      }
    }

  const handleOnOpenProductPreview = (state: boolean) => () => {
    changeOpenProductPreview(state)
  }

  return (
    <Row
      fullWidth
      key={`banner_${id}`}
      className={classes.tableRow}
      justifyContent="space-between"
    >
      <Row fullWidth justifyContent="space-between" onClick={handleOnClick}>
        <Row className={classes.tableItem} justifyContent="flex-start">
          <PermissionContent
            permissions={[
              PermissionEnum.EDIT_ITEM,
              PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
            ]}
          >
            <Checkbox checked={checked} onChange={handleOnChange(id, isTop)} />
          </PermissionContent>
          <img className={classes.image} height={48} width={48} src={src} />
        </Row>
        <Row
          fullWidth
          className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
          justifyContent="flex-start"
        >
          {name && (
            <Text
              className={classes.hiddenText}
              color="black"
              preset="body"
              text={name}
            />
          )}
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <PermissionContent
            permissions={[
              PermissionEnum.VIEW_ITEM,
              PermissionEnum.ADD_CHANGE_REMOVE_ITEMS
            ]}
          >
            {getFullCompanyName && (
              <Text
                className={classes.hiddenText}
                color="black"
                preset="body"
                text={getFullCompanyName}
              />
            )}
          </PermissionContent>
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          {category && (
            <Text color="black" preset="body" text={category.name} />
          )}
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <ColorDot color={theme.colors[colorVisible]} />
          <Text
            className={classes.statusText}
            color="black"
            preset="body"
            {...visible}
          />
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          <Text color="black" preset="body" text={totalPrice} />
        </Row>
        <Row className={classes.tableColumn} justifyContent="flex-start">
          {isTop ? (
            <Text
              text="Top Product"
              tx="item.top.product"
              color="black"
              preset="body"
            />
          ) : (
            <Text text="-" tx="item.dash" color="black" preset="body" />
          )}
        </Row>
      </Row>
      <Row
        className={classes.iconRow}
        onClick={handleOnOpenProductPreview(true)}
      >
        <Icon src={ICONS.removeRedEye} />
      </Row>
      {openProductPreview && (
        <ProductPreview
          id={id}
          open={openProductPreview}
          onClose={handleOnOpenProductPreview(false)}
        />
      )}
    </Row>
  )
}
