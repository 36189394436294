import { RadioItemWithIdProps } from '../../components'
import { PromotionBannerCreateInput } from '../../services'

export const defaultValue: PromotionBannerCreateInput = {
  name: '',
  isVisible: false,
  title: '',
  subtitle: ''
}

export const visibilityRadioData: RadioItemWithIdProps[] = [
  { id: '1', name: 'Visible', nameTx: 'banner.visibility.active' },
  { id: '0', name: 'Invisible', nameTx: 'banner.visibility.inactive' }
]

export const REGEX_URL =
  /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i

export const validateUrl = (url: string) => {
  const testUrl = REGEX_URL.test(url)

  return testUrl
}
