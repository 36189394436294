import { useQuery, useMutation } from '@apollo/client'

import {
  addFromQueryNewItems,
  removeFromQueryById,
  removeFromQueryByIds
} from '../update-cache'

import {
  PRODUCT_IMAGE_CREATE_MUTATION,
  PRODUCT_CREATE_MUTATION,
  PRODUCTS_QUERY,
  PREVIOSLY_SEEN_PRODUCTS_QUERY,
  RECOMMENDATION_PRODUCTS,
  TOP_PRODUCTS_QUERY,
  WISHLIST_ADD_PRODUCT,
  FEATURED_PRODUCTS_QUERY,
  PRODUCT_BY_CATEGORY_ID,
  PRODUCT_BY_ID,
  WISHLIST,
  WISHLIST_REMOVE_PRODUCT,
  VENDOR_CATEGORIES,
  PRODUCT_UPDATE_MUTATION,
  PRODUCT_IMAGE_BULK_DELETE_MUTATION,
  PRODUCT_REMOVE_MUTATION,
  SET_TOP_PRODUCTS_MUTATION,
  PRODUCT_DUPLICATE_MUTATION,
  PRODUCT_REMOVE_FROM_VENDOR_LIST_MUTATION,
  PRODUCT_PREVIEW_BY_ID
} from './product.graphql'
import {
  GetProductRemoveResults,
  ProductRemoveVariables,
  ProductImageBulkDelete,
  ProductRemove,
  GetProductImageCreateResults,
  ProductImageCreateVariables,
  ProductImageCreate,
  ProductUpdate,
  ProductUpdateVariables,
  GetProductUpdateResults,
  ProductCreate,
  ProductCreateVariables,
  GetCreateProductResults,
  WishlistVariables,
  GetProductsData,
  GetPrevioslyProductsRequest,
  GetPrevioslyProductsVariables,
  GetPrevioslySeenProducts,
  GetRecommendationProducts,
  GetRecommendationProductsRequest,
  GetTopProducts,
  GetTopProductsRequest,
  GetTopProductsVariables,
  ProductsVariables,
  GetAddProductWishlistRequest,
  GetRemoveProductWishlistRequest,
  GetFeaturedProducts,
  GetFeaturedProductsRequest,
  GetProduct,
  GetProductByIdRequest,
  GetProductsByCategoryId,
  GetProductsByCategoryIdRequest,
  GetWishlistAddResults,
  GetWishlistRemoveResults,
  GetWishlistRequest,
  GetWishlistResults,
  ProductApi,
  ProductByIdVariables,
  ProductsByCategoryIdVariables,
  GetProducts,
  GetVendorCategories,
  VendorCategoriesVariables,
  GetVendorCategoriesRequest,
  GetProductImageBulkDeleteResults,
  ProductImageBulkDeleteVariables,
  SetTopProducts,
  GetSetTopProductsResults,
  SetTopProductsVariables,
  ProductDuplicate,
  GetProductDuplucateResults,
  ProductDuplicateVariables,
  ProductRemoveFromVendorList,
  ProductRemoveFromVendorListVariables,
  GetProductRemoveFromVendorListResults
} from './product.types'

// our "constructor"
export const productService = (): ProductApi => {
  const useProducts = (variables: ProductsVariables): GetProducts => {
    const { data, loading, error, refetch } = useQuery<
      GetProductsData,
      ProductsVariables
    >(PRODUCTS_QUERY, { variables })

    return { data, error, loading, refetch }
  }

  const useFeaturedProducts = (
    variables: ProductsVariables
  ): GetFeaturedProductsRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetFeaturedProducts,
      ProductsVariables
    >(FEATURED_PRODUCTS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useTopProducts = (
    variables: GetTopProductsVariables
  ): GetTopProductsRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetTopProducts,
      GetTopProductsVariables
    >(TOP_PRODUCTS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const usePrevioslySeenProducts = (
    variables: GetPrevioslyProductsVariables
  ): GetPrevioslyProductsRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetPrevioslySeenProducts,
      GetPrevioslyProductsVariables
    >(PREVIOSLY_SEEN_PRODUCTS_QUERY, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useProductsByCategoryId = (
    variables: ProductsByCategoryIdVariables
  ): GetProductsByCategoryIdRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetProductsByCategoryId,
      ProductsByCategoryIdVariables
    >(PRODUCT_BY_CATEGORY_ID, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useRecommendationProducts = (
    variables: ProductsVariables
  ): GetRecommendationProductsRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetRecommendationProducts,
      ProductsVariables
    >(RECOMMENDATION_PRODUCTS, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useVendorCategories = (
    variables: VendorCategoriesVariables
  ): GetVendorCategoriesRequest => {
    const { data, loading, error } = useQuery<
      GetVendorCategories,
      VendorCategoriesVariables
    >(VENDOR_CATEGORIES, { variables })

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useProductById = (
    variables: ProductByIdVariables
  ): GetProductByIdRequest => {
    const { data, loading, error } = useQuery<GetProduct, ProductByIdVariables>(
      PRODUCT_BY_ID,
      { variables, fetchPolicy: 'network-only' }
    )

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useProductPreviewById = (
    variables: ProductByIdVariables
  ): GetProductByIdRequest => {
    const { data, loading, error } = useQuery<GetProduct, ProductByIdVariables>(
      PRODUCT_PREVIEW_BY_ID,
      { variables, fetchPolicy: 'network-only' }
    )

    if (error) {
      return { data: null, loading }
    }

    if (!data) {
      return { data: null, loading }
    }

    return { data, loading }
  }

  const useAddProductToWishlist = (
    id: string
  ): GetAddProductWishlistRequest => {
    const [AddProductToWishlistSubmit, response] = useMutation<
      GetWishlistAddResults,
      { productId: string }
    >(WISHLIST_ADD_PRODUCT)

    const handleOnSubmit = () => {
      if (id) {
        const options = {
          variables: {
            productId: id
          }
        }

        AddProductToWishlistSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useRemoveProductFromWishlist = (
    id: string
  ): GetRemoveProductWishlistRequest => {
    const [AddProductToWishlistSubmit, response] = useMutation<
      GetWishlistRemoveResults,
      { productId: string }
    >(WISHLIST_REMOVE_PRODUCT)

    const handleOnSubmit = () => {
      if (id) {
        const options = {
          variables: {
            productId: id
          }
        }

        AddProductToWishlistSubmit(options)
      }
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useWishlist = (variables: WishlistVariables): GetWishlistRequest => {
    const { data, loading, error, refetch } = useQuery<
      GetWishlistResults,
      WishlistVariables
    >(WISHLIST, { variables })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useProductCreate = (): ProductCreate => {
    const [onProductCreate, response] = useMutation<
      GetCreateProductResults,
      ProductCreateVariables
    >(PRODUCT_CREATE_MUTATION)

    const handleOnSubmit = (variables: ProductCreateVariables) => {
      const options = {
        variables
      }
      onProductCreate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useProductUpdate = (): ProductUpdate => {
    const [onProductUpdate, response] = useMutation<
      GetProductUpdateResults,
      ProductUpdateVariables
    >(PRODUCT_UPDATE_MUTATION)

    const handleOnSubmit = (variables: ProductUpdateVariables) => {
      const options = {
        variables
      }
      onProductUpdate(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useProductRemove = (): ProductRemove => {
    const [onRemoveProduct, response] = useMutation<
      GetProductRemoveResults,
      ProductRemoveVariables
    >(PRODUCT_REMOVE_MUTATION)

    const handleOnSubmit = (variables: ProductRemoveVariables) => {
      const options = {
        variables
      }
      onRemoveProduct({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetProductsData>({
            query: PRODUCTS_QUERY
          })

          if (queryResult) {
            cache.writeQuery({
              query: PRODUCTS_QUERY,
              data: removeFromQueryById(
                'products',
                queryResult,
                result.data?.productRemove.product.id
              )
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useProductImageCreate = (): ProductImageCreate => {
    const [onCreateProduct, response] = useMutation<
      GetProductImageCreateResults,
      ProductImageCreateVariables
    >(PRODUCT_IMAGE_CREATE_MUTATION)

    const handleOnSubmit = (variables: ProductImageCreateVariables) => {
      const options = {
        variables
      }
      onCreateProduct(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useProductImageBulkDelete = (): ProductImageBulkDelete => {
    const [onCreateProduct, response] = useMutation<
      GetProductImageBulkDeleteResults,
      ProductImageBulkDeleteVariables
    >(PRODUCT_IMAGE_BULK_DELETE_MUTATION)

    const handleOnSubmit = (variables: ProductImageBulkDeleteVariables) => {
      const options = {
        variables
      }
      onCreateProduct(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useProductRemoveFromVendorList = (): ProductRemoveFromVendorList => {
    const [onRemoveProductFromList, response] = useMutation<
      GetProductRemoveFromVendorListResults,
      ProductRemoveFromVendorListVariables
    >(PRODUCT_REMOVE_FROM_VENDOR_LIST_MUTATION)

    const handleOnSubmit = (
      variables: ProductRemoveFromVendorListVariables,
      productVariables: ProductsVariables
    ) => {
      const options = {
        variables
      }
      onRemoveProductFromList({
        ...options,
        update(cache) {
          const queryResult = cache.readQuery<GetProductsData>({
            query: PRODUCTS_QUERY,
            variables: productVariables
          })

          if (queryResult) {
            cache.writeQuery({
              query: PRODUCTS_QUERY,
              variables: productVariables,
              data: removeFromQueryByIds(
                'products',
                queryResult,
                variables.products
              )
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useSetTopProducts = (): SetTopProducts => {
    const [onSetTopProduct, response] = useMutation<
      GetSetTopProductsResults,
      SetTopProductsVariables
    >(SET_TOP_PRODUCTS_MUTATION)

    const handleOnSubmit = (variables: SetTopProductsVariables) => {
      const options = {
        variables
      }
      onSetTopProduct(options)
    }

    return { onSubmit: handleOnSubmit, response }
  }

  const useProductDuplicate = (): ProductDuplicate => {
    const [onDuplicateProduct, response] = useMutation<
      GetProductDuplucateResults,
      ProductDuplicateVariables
    >(PRODUCT_DUPLICATE_MUTATION)

    const handleOnSubmit = (variables: ProductDuplicateVariables) => {
      const options = {
        variables
      }
      onDuplicateProduct({
        ...options,
        update(cache, result) {
          const queryResult = cache.readQuery<GetProductsData>({
            query: PRODUCTS_QUERY
          })

          if (queryResult) {
            cache.writeQuery({
              query: PRODUCTS_QUERY,
              data: addFromQueryNewItems(
                'products',
                queryResult,
                result.data?.productDuplicate.products
              )
            })
          }
        }
      })
    }

    return { onSubmit: handleOnSubmit, response }
  }

  return {
    useProducts,
    useFeaturedProducts,
    useRecommendationProducts,
    useTopProducts,
    usePrevioslySeenProducts,
    useProductsByCategoryId,
    useProductById,
    useAddProductToWishlist,
    useRemoveProductFromWishlist,
    useWishlist,
    useVendorCategories,
    useProductCreate,
    useProductUpdate,
    useProductRemove,
    useProductImageCreate,
    useProductImageBulkDelete,
    useSetTopProducts,
    useProductDuplicate,
    useProductRemoveFromVendorList,
    useProductPreviewById
  }
}
