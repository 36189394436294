import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, Column, Text } from '../../../components'
import { useUser } from '../../../hooks'
import { getDefaultAuthorizedRoute } from '../../../utils'

import { useStyle } from './empty-list.styles'
import { EmptyProps } from './empty-list.types'

export const EmptyList: FC<EmptyProps> = ({
  headerText,
  headerTx,
  title,
  titleTx,
  text,
  tx
}) => {
  const { user } = useUser()
  const classes = useStyle()
  const history = useHistory()
  const handleOnNavigate = (path: string) => () => {
    history.push(path)
  }

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Text
        className={classes.title}
        preset="h3"
        text={headerText}
        tx={headerTx}
      />
      <Column
        className={classes.image}
        alignItems="center"
        justifyContent="center"
      />
      <Column className={classes.text}>
        <Text preset="h4" text={title} tx={titleTx} />
        <Text
          className={classes.subText}
          color="inactive"
          preset="body"
          text={text}
          tx={tx}
        />
        <Button
          className={classes.button}
          color="yellow"
          preset="primary"
          textColor="white"
          text="HOME"
          tx="error.404.button.text"
          onClick={handleOnNavigate(getDefaultAuthorizedRoute(user))}
        />
      </Column>
    </Column>
  )
}
