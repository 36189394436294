export const REGEX_PHONE = /^\+(?=(?:\s?\d){7,17}$)\d+(?:\s?\d+){0,3}$/

export const validatePhone = (phone: string) => {
  const testPhone = REGEX_PHONE.test(phone)

  return testPhone
}

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = (email: string) => {
  const testEmail = REGEX_EMAIL.test(email)

  return testEmail
}

export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{5,30}$/

export const validatePassword = (password: string) => {
  const testPassword = REGEX_PASSWORD.test(password)

  return testPassword
}

export const REGEX_FIRSNAME =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*.{0,30}$/

export const validateFirstName = (firstName: string) => {
  const testFirstName = REGEX_FIRSNAME.test(firstName)

  return testFirstName
}
export const REGEX_LASTNAME =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*.{0,30}$/

export const validateLastName = (lastName: string) => {
  const testLastName = REGEX_LASTNAME.test(lastName)

  return testLastName
}

export const REGEX_USERNAME =
  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*.{0,30}$/

export const validateUsername = (username: string) => {
  const testUsername = REGEX_USERNAME.test(username)

  return testUsername
}

export const REGEX_STATE = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*.{0,30}$/

export const validateState = (state: string) => {
  const testState = REGEX_STATE.test(state)

  return testState
}
