import { PRODUCT_ATTRIBUTE_FRAGMENT } from './attribute'
import { PRODUCT_COLOR_FRAGMENT } from './color'
import { DELIVEY_PRICE_FRAGMENT } from './deliveryPrice'
import { PRODUCT_MATERIAL_FRAGMENT } from './material'
import { PRODUCT_OPTION_FRAGMENT } from './option'

export const MONEY_FRAGMENT = `
  fragment MoneyFragment on Money {
    currency
    amount
  }
`

export const PRICE_FRAGMENT = `
  ${MONEY_FRAGMENT}
  fragment Price on TaxedMoney {
    gross {
      ...MoneyFragment
    }
    net {
      ...MoneyFragment
    }
  }
`

export const PRODUCT_VENDOR_FRAGMENT = `
  fragment ProductVendorFragment on User {
    id
    email
    city
    firstName
    lastName
    companyName
  }
`

export const BASIC_PRODUCT_FRAGMENT = `
  ${PRODUCT_MATERIAL_FRAGMENT}
  ${PRODUCT_COLOR_FRAGMENT}
  ${PRODUCT_ATTRIBUTE_FRAGMENT}
  ${PRODUCT_OPTION_FRAGMENT}
  ${PRODUCT_VENDOR_FRAGMENT}
  ${DELIVEY_PRICE_FRAGMENT}
  fragment BasicProductFields on Product {
    id
    name
    description
    defaultPrice
    totalPrice
    isTop
    currency
    visibleInListings
    purchaseSpecifics
    productionDaysUpTo
    brandName
    countryOfOrigin
    materialCare
    materialComposition
    status
    productType {
        id
        name
    }
    materials {
      ...ProductMaterialFragment
    }
    colors {
      ...ProductColorFragment
    }
    attributes {
      ...ProductAttributeFragment
    }
    options {
      ...ProductOptionFragment
    }
    vendor {
      ...ProductVendorFragment
    }
    deliveryPrice {
      ...DeliveryPriceFragment
    }
    isFragile
    isRemovedFromVendorList
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      alt
    }
  }
`

export const PRODUCT_FRAGMENT_FOR_OFFER = `
  ${DELIVEY_PRICE_FRAGMENT}
  fragment ProductFragmentForOffer on Product {
    id
    name
    defaultPrice
    currency
    deliveryPrice {
      ...DeliveryPriceFragment
    }
    thumbnail {
      url
      alt
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      alt
    }
  }
`

export const IMAGES_FRAGMENT = `
  fragment Images on ProductImage {
    id
    sortOrder
    alt
    url
  }
`

export const PRODUCT_PRICING_FRAGMENT = `
  ${PRICE_FRAGMENT}
  fragment ProductPricingField on Product {
    pricing {
      onSale
      priceRangeUndiscounted {
        start {
          ...Price
        }
        stop {
          ...Price
        }
      }
      priceRange {
        start {
          ...Price
        }
        stop {
          ...Price
        }
      }
      discount {
        currency
        gross {
          ...MoneyFragment
        }
        net {
          ...MoneyFragment
        }
        tax {
          ...MoneyFragment
        }
      }
    }
  }
`

export const PRODUCT_FRAGMENT_FOR_PREVIEW = `
  ${PRODUCT_MATERIAL_FRAGMENT}
  ${PRODUCT_COLOR_FRAGMENT}
  ${PRODUCT_ATTRIBUTE_FRAGMENT}
  ${PRODUCT_OPTION_FRAGMENT}
  ${DELIVEY_PRICE_FRAGMENT}
  fragment ProductFragmentForPreview on Product {
    id
    name
    description
    brandName
    productionDaysUpTo
    countryOfOrigin
    materialCare
    materialComposition
    slug
    defaultPrice
    totalPrice
    isFavorite
    deliveryPrice {
      ...DeliveryPriceFragment
    }
    materials {
      ...ProductMaterialFragment
    }
    colors {
      ...ProductColorFragment
    }
    attributes {
      ...ProductAttributeFragment
    }
    options {
      ...ProductOptionFragment
    }
  }
`
