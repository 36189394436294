import React, { FC } from 'react'
import { Route } from 'react-router-dom'

import { hasPermission } from '../../auth/misc'
import { Content, HeaderMenu } from '../../components'
import { useUser } from '../../hooks/useUser'
import { NotFound } from '../../NotFound'
import { CabinetNavigator, CabinetPage } from '../../pages'

import { CabinetRouteProps } from './cabinet.types'

export const CabinetRoute: FC<CabinetRouteProps> = ({
  exact,
  children,
  permissions,
  path
}) => {
  const { user } = useUser()

  const navigator = <CabinetNavigator />

  if (!user) {
    return <NotFound />
  }

  if (permissions) {
    const hasPermissions = permissions
      .map((permission) => hasPermission(permission, user))
      .reduce((prev, curr) => prev || curr)

    if (!hasPermissions) {
      return <NotFound />
    }
  }

  return (
    <Route exact={exact} path={path}>
      <CabinetPage navigator={navigator}>
        <Content justifyContent="flex-start">
          <HeaderMenu />
          {children}
        </Content>
      </CabinetPage>
    </Route>
  )
}
