import React, { FC } from 'react'

import { Column } from '../../../components'
import { Sex } from '../../../services'

import { PersonalInfo } from '../personal-info'
import { BusinessInfo } from '../business-info'
import { ContactInfo } from '../contact-info'

import { ProfileInfoProps } from './profile-info.types'
import { useStyle } from './profile-info.styles'

export const ProfileInfo: FC<ProfileInfoProps> = ({
  firstName,
  lastName,
  username,
  sex,
  companyName,
  avatar,
  email,
  phone,
  state,
  country,
  user,
  changeUser,
  onChangeImage,
  onDeleteImage
}) => {
  const classes = useStyle()

  return (
    <Column className={classes.userInfo}>
      <PersonalInfo
        firstName={firstName}
        lastName={lastName}
        username={username}
        sex={sex ?? Sex.MALE}
        user={user}
        changeUser={changeUser}
      />
      <BusinessInfo
        companyName={companyName ?? ''}
        avatar={avatar?.url ?? ''}
        user={user}
        changeUser={changeUser}
        onChangeImage={onChangeImage}
        onDeleteImage={onDeleteImage}
      />
      <ContactInfo
        email={email ?? ''}
        phone={phone ?? ''}
        state={state ?? ''}
        country={country ?? ''}
        user={user}
        changeUser={changeUser}
      />
    </Column>
  )
}
