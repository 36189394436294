import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

import { FilterSidebarStyles } from './filters-sidebar.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  base: ({ open, width = 408 }: FilterSidebarStyles) => ({
    background: theme.colors.white,
    boxShadow: `-8px 0px 32px ${theme.colors.shadow}`,
    position: 'fixed',
    right: open ? 0 : -width,
    bottom: 0,
    top: 0,
    transition: '0.5s ease-in-out all',
    width,
    zIndex: 2
  }),
  container: {
    flex: 1,
    alignSelf: 'stretch',
    height: '100vh',
    overflowY: 'auto'
  },
  title: {
    padding: 24,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  content: {
    alignSelf: 'stretch',
    flex: 1,
    padding: '16px 24px'
  },
  bottomContainer: {
    position: 'sticky',
    bottom: 0,
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 24,
    backgroundColor: theme.colors.white,
    zIndex: 2
  },
  button: {
    flex: 1,
    alignSelf: 'stretch'
  },
  buttonRight: {
    marginLeft: 16
  }
}))
