import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  OrderDirection,
  parseFilterItems,
  Product,
  ProductCreateInput,
  ProductFilter,
  ProductOrderField,
  ProductsFiltersParseJSON,
  ProductsVariables,
  ProductStatus,
  PermissionEnum
} from '../../services'
import { useApi } from '../../providers'
import {
  Column,
  Row,
  Button,
  SubscriptionErrorModal,
  arrayHasElements,
  PermissionContent
} from '../../components'
import {
  SortVariables,
  useMessageOnUpdateData,
  useSort,
  useTable,
  useUser
} from '../../hooks'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { ItemsTable, ItemsTableProps } from './items-table'
import { useStyle } from './items-product.styles'
import { ItemsProductFilters } from './items-product-filters'

const rowCounts = [5, 10, 20, 30]

export const ItemsProductsPage = () => {
  const { product } = useApi()
  const history = useHistory()
  const classes = useStyle()
  const { user, onUpdateActiveItemCount } = useUser()

  const vendors = user && !user.isStaff ? [user.id] : undefined
  const defaultSortBy = {
    direction: OrderDirection.DESC,
    field: ProductOrderField.DATE
  }
  const userActiveItemsCount = user?.vendorActiveProducts || 0
  const filter: ProductFilter = {
    vendors
  }
  const defaultVariables = {
    first: rowCounts[1],
    filter,
    sortBy: defaultSortBy
  }

  const [productVariables, changeProductVariables] =
    useState<ProductsVariables>(defaultVariables)
  const [openErrorModal, changeOpenErrorModal] = useState(false)
  const [pickedActiveCount, changePickedActiveCount] = useState(0)

  const { data, loading, refetch } = product.useProducts(defaultVariables)

  const {
    Data,
    changeCurrentData,
    currentData,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch,
    onSubmitFilters,
    onResetFilters,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.products,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })
  const { onSubmit: onRemove } = product.useProductRemove()
  const { onSubmit: onSetTopProduct, response: setTopProductsResponse } =
    product.useSetTopProducts()
  const { onSubmit: onRemoveFromList } =
    product.useProductRemoveFromVendorList()
  const { onSubmit: onUpdate, response: productUpdateResponse } =
    product.useProductUpdate()
  const { onSubmit: onProductDuplicate, response: productDuplicateResponse } =
    product.useProductDuplicate()

  const duplicateData = productDuplicateResponse.data
  const updateData = productUpdateResponse.data
  const setTopData = setTopProductsResponse.data

  useMessageOnUpdateData({
    condition: duplicateData,
    deps: [duplicateData?.productDuplicate.products.length]
  })

  useMessageOnUpdateData({
    condition: updateData,
    deps: [updateData?.productUpdate.product]
  })

  useMessageOnUpdateData({
    condition: setTopData,
    deps: [setTopData?.setTopProducts.products.length]
  })

  const isFreeSubscription =
    user?.subscription?.name && user?.subscription?.name === 'Free'

  useEffect(() => {
    if (paginationState.page === 1) {
      refetch({ ...productVariables, before: undefined, last: undefined })
    }
  }, [paginationState.page])

  const handleOnChangeActiveItems = (quantity: number) => {
    changePickedActiveCount(quantity)
  }

  const handleOnOpenErrorModal = () => {
    changeOpenErrorModal(!openErrorModal)
  }

  const handleOnUpdateItemCount = (count: number) => {
    if (onUpdateActiveItemCount) {
      onUpdateActiveItemCount(count)
    }
  }

  const handleOnUpdate = (isActivate: boolean) => (publishData: Product[]) => {
    publishData.forEach(({ id, name, vendor, visibleInListings }) => {
      const isSattusVisible = isActivate
        ? ProductStatus.VISIBLE
        : ProductStatus.INVISIBLE
      const newState = {
        id,
        input: {
          name,
          visibleInListings,
          status: isSattusVisible,
          vendor: vendor.id
        } as ProductCreateInput
      }

      const count = publishData.length

      if (isActivate && userActiveItemsCount + count > 5) {
        return handleOnOpenErrorModal()
      }

      if (isActivate) {
        handleOnUpdateItemCount(count)
        changePickedActiveCount(count)
      } else {
        handleOnUpdateItemCount(-pickedActiveCount)
        changePickedActiveCount(0)
      }

      onUpdate(newState)
    })
  }

  const Filters = useMemo(() => {
    if (data) {
      const list = parseFilterItems<ProductsFiltersParseJSON>(
        data.products.filters
      )

      if (list) {
        return list
      }
    }

    return {
      genders: [],
      materials: [],
      colors: [],
      sizes: [],
      priceRange: [],
      productTypes: [],
      companyNames: [],
      vendors: []
    } as ProductsFiltersParseJSON
  }, [data])

  const handleOnCreate = () => {
    if (isFreeSubscription && userActiveItemsCount >= 5) {
      handleOnOpenErrorModal()
    } else {
      history.push(CabinetNavigatorPathVendor.ITEMS_CREATE)
    }
  }

  const handleOnRemove = (ids: string[]) => {
    ids.forEach((id) => onRemove({ id }))

    const nextProducts = currentData.filter(({ node }) => {
      const currentItem = ids.find((id) => id === node.id)

      return !currentItem
    })

    const nextDiffActive = currentData.filter(
      (item) => item.node.visibleInListings
    )
    handleOnUpdateItemCount(-nextDiffActive.length)

    changeCurrentData(nextProducts)
  }

  const handleOnSetTopProduct = (productsIds: string[], active: boolean) => {
    if (arrayHasElements(productsIds)) {
      onSetTopProduct({ isTop: active, products: productsIds })
    }
  }

  const handleOnRemoveFromList = (productsIds: string[]) => {
    if (arrayHasElements(productsIds)) {
      onRemoveFromList({ products: productsIds }, productVariables)
    }
    changePickedActiveCount(0)
    handleOnUpdateItemCount(-pickedActiveCount)
    refetch()
  }

  const handleOnProductDuplicate = (productIds: string[]) => {
    const nextActiveItemsLength = userActiveItemsCount + pickedActiveCount

    if (isFreeSubscription && nextActiveItemsLength > 5) {
      handleOnOpenErrorModal()
    } else if (arrayHasElements(productIds)) {
      onProductDuplicate({ products: productIds })
      handleOnUpdateItemCount(pickedActiveCount)
      refetch()
    }
  }

  const handleOnSearch: ItemsTableProps['onChangeSearch'] = (event) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnSort = (sortVariables: SortVariables<ProductOrderField>) => {
    if (refetch) {
      refetch({
        ...sortVariables,
        before: undefined,
        after: undefined,
        last: undefined
      })
      onResetPageState()
    }
    changeProductVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: productVariables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="flex-end"
      >
        <PermissionContent
          permissions={[
            PermissionEnum.CREATE_ITEM,
            PermissionEnum.ADD_CHANGE_REMOVE_OWN_ITEMS
          ]}
        >
          <Button
            className={classes.button}
            preset="primary"
            text="CREATE ITEM"
            textPreset="h5"
            textColor="white"
            tx="cabinet.items.create.button"
            onClick={handleOnCreate}
          />
        </PermissionContent>
      </Row>
      <ItemsTable
        className={classes.table}
        data={Data}
        loading={loading}
        paginationState={paginationState}
        rowCounts={rowCounts}
        onChangeSetTopProduct={handleOnSetTopProduct}
        onDuplicateProduct={handleOnProductDuplicate}
        onRemoveProductFromList={handleOnRemoveFromList}
        onChangeSearch={handleOnSearch}
        onRemove={handleOnRemove}
        onActivate={handleOnUpdate(true)}
        onDeactivate={handleOnUpdate(false)}
        onChangePage={onChangePage}
        onPrevioslyPage={onPrevioslyPage}
        onNextPage={onNextPage}
        onChangeRowCount={onChangeRowCount}
        onSort={onSort}
        onChangeActiveItems={handleOnChangeActiveItems}
      >
        <ItemsProductFilters
          className={classes.filterButton}
          filters={Filters}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
        />
      </ItemsTable>
      {openErrorModal && (
        <SubscriptionErrorModal
          title="Ooops..."
          titleTx="subscriptionError.modal.title"
          description="Sorry as an Individual Vendor you can have only 5 active products."
          descriptionTx="item.subscription.error.modal.description"
          secondaryDescription="You can upgrade your subscription to have
          unlimited products and access to the OVTMT application.
          Do you want to change your plan?"
          secondaryDescriptionTx="subscriptionError.modal.secondaryDescription"
          onClose={handleOnOpenErrorModal}
        />
      )}
    </Column>
  )
}
