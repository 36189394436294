import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components/Theme'

import { LoginCardStyleProps } from './user-info-update.types'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  attention: {
    marginTop: 24,
    opacity: ({ error }: LoginCardStyleProps) => (error ? 1 : 0),
    visibility: ({ error }: LoginCardStyleProps) =>
      error ? 'visible' : 'hidden',
    textDecorationColor: theme.colors.error,
    width: 'calc(100% - 52px)'
  },
  container: {
    backgroundImage: ({ background }: LoginCardStyleProps) =>
      `url(${background})`,
    backgroundRepeat: 'space',
    backgroundSize: '100vw',
    paddingBottom: 24,
    zIndex: 1
  },
  input: {
    marginTop: 24
  },
  loginForm: {
    margin: '0 auto',
    width: 536
  },
  submit: {
    marginTop: 56
  },
  dropdawnRow: {
    width: 536,
    marginTop: 24
  },
  dropdown: {
    top: 0,
    width: 'calc(100% - 16px)',
    borderRadius: 4,
    scrollbarWidth: 'none',
    border: `1px solid ${theme.colors.inactive}`,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))
