import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  image: {
    marginLeft: 16,
    objectFit: 'cover',
    height: 48,
    width: 48,
    backgroundColor: theme.colors.yellow
  },
  tableColumn: {
    height: '100%',
    maxWidth: 120,
    padding: '8px 16px'
  },
  secondTableColumn: {
    padding: '8px 16px 8px 0',
    height: '100%'
  },
  thirdTableColumn: {
    flex: 2,
    padding: '8px 16px',
    height: '100%'
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.lightBlue
    }
  },
  statusText: {
    marginLeft: 8
  },
  row: {
    flex: 3
  }
}))
