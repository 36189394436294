import React, { FC } from 'react'

import { concatClassNames } from '../../pages/views'

import { Row } from '../row'
import { Text } from '../text'
import { Icon, ICONS } from '../icon'

import { HintRowProps } from './hint-row.types'
import { useStyle } from './hint-row.styles'

export const HintRow: FC<HintRowProps> = ({
  children,
  className = '',
  textClassName = '',
  color = 'black',
  textPreset = 'title',
  text,
  textTx,
  top,
  topIcon,
  left,
  onClick
}) => {
  const classes = useStyle({ top, left, topIcon })

  return (
    <Row
      fullWidth
      className={concatClassNames(classes.container, className)}
      onClick={onClick}
    >
      <Icon className={classes.icon} src={ICONS.hintArrow} />
      <Text
        className={concatClassNames(classes.text, textClassName)}
        text={text}
        tx={textTx}
        preset={textPreset}
        color={color}
      />
      {children}
    </Row>
  )
}
