import React, { FC } from 'react'

import { Row, Text } from '../../../components'

import { ObserveOfferButton } from '../../../pages/views/observe-offer-button'

import { useStyle } from './offer-chat-item.styles'
import { OfferChatItemProps } from './offer-chat-item.types'

export const OfferChatItem: FC<OfferChatItemProps> = ({
  value,
  count,
  disabled,
  description,
  product,
  discount,
  noSuggestion,
  productTotalPrice,
  totalQuantity,
  isStatusDraft
}) => {
  const classes = useStyle()

  if (isStatusDraft) {
    return <></>
  }

  return (
    <Row
      fullWidth
      className={classes.itemContainer}
      justifyContent="space-between"
    >
      <Row>
        <Text text={count} preset="h5" />
        <Text
          className={classes.itemTitle}
          text="Item"
          tx="offer.item.variant"
          preset="title"
        />
      </Row>
      <ObserveOfferButton
        disabled={disabled}
        quantity={totalQuantity}
        itemQuantity={value.quantity}
        description={description}
        product={product}
        options={value.options}
        discount={discount}
        noSuggestion={noSuggestion}
        productTotalPrice={productTotalPrice}
        totalItemPrice={value.totalItemPrice}
        offerOptions={value.productOptions}
        offerColors={value.productColors}
        offerMaterials={value.productMaterials}
      />
    </Row>
  )
}
