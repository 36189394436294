import { useCallback, useEffect, useState } from 'react'

export type UseDebounceInputProps = {
  timeout?: number
  defaultValue?: string
  onChange?: (value: string) => void
}

export const useDebounceInput = ({
  timeout: timeoutCount = 3000,
  defaultValue,
  onChange
}: UseDebounceInputProps) => {
  const [value, changeValue] = useState(defaultValue)

  useEffect(() => {
    if (typeof value !== 'undefined') {
      const timeout = setTimeout(() => {
        if (onChange) {
          onChange(value)
        }
      }, timeoutCount)

      return () => {
        clearTimeout(timeout)
      }
    }

    return () => {}
  }, [value])

  const handleOnChange = useCallback(
    (nextValue: string) => {
      changeValue(nextValue)
    },
    [value]
  )

  return {
    onChange: handleOnChange
  }
}
