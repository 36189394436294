import React from 'react'

import { AppStateContext } from '../providers'

export function useAppState() {
  const stateAndDispatch = React.useContext(AppStateContext)

  return stateAndDispatch
}

export default useAppState
