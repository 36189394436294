import { ApolloClient, useApolloClient } from '@apollo/client'
import React, { MutableRefObject } from 'react'
import { IntlShape, useIntl } from 'react-intl'

import { IMessageContext } from '../../components/messages'
import { useNotifier } from '../../hooks/useNotifier'

import { BackgroundTasksContext } from './context'
import { checkExportFileStatus, checkOrderInvoicesStatus } from './queries'
import {
  handleTask,
  queueCustom,
  queueExport,
  queueInvoiceGenerate
} from './tasks'
import { QueuedTask, Task, TaskData, TaskStatus } from './types'

export const backgroundTasksRefreshTime = 15 * 1000

const queueTask = async (tasks: MutableRefObject<QueuedTask[]>) => {
  try {
    await Promise.all(
      tasks.current.map(async (task) => {
        if (task.status === TaskStatus.PENDING) {
          const status: TaskStatus = await handleTask(task)

          if (status !== TaskStatus.PENDING) {
            const taskIndex = tasks.current.findIndex(
              (currentTask) => currentTask.id === task.id
            )
            // eslint-disable-next-line no-param-reassign
            tasks.current[taskIndex].status = status
          }
        }
      })
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Queue task: \n', error)
  }
}

export function useBackgroundTasks(
  apolloClient: ApolloClient<any>,
  notify: IMessageContext,
  intl: IntlShape
) {
  const idCounter = React.useRef(0)
  const tasks = React.useRef<QueuedTask[]>([])

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      queueTask(tasks)
    }, backgroundTasksRefreshTime)

    return () => clearInterval(intervalId)
  })

  const cancel = (id: number) => {
    tasks.current = tasks.current.filter((task) => task.id !== id)
  }

  const queue = (type: Task, data: TaskData) => {
    idCounter.current += 1

    const handleOnSuccessQueueInvoice = () => {
      if (data.generateInvoice) {
        return apolloClient.query({
          fetchPolicy: 'network-only',
          query: checkOrderInvoicesStatus,
          variables: {
            id: data.generateInvoice.orderId
          }
        })
      }

      return apolloClient.query({
        fetchPolicy: 'network-only',
        query: checkOrderInvoicesStatus
      })
    }

    const handleOnSuccessExport = () => {
      return apolloClient.query({
        fetchPolicy: 'network-only',
        query: checkExportFileStatus,
        variables: {
          id: data.id
        }
      })
    }

    const handleOnSetTask = (task: QueuedTask) => {
      tasks.current = [...tasks.current, task]
    }

    switch (type) {
      case Task.CUSTOM:
        queueCustom(idCounter.current, data, handleOnSetTask)
        break
      case Task.INVOICE_GENERATE:
        if (!data.generateInvoice) {
          break
        }
        queueInvoiceGenerate(
          idCounter.current,
          data.generateInvoice,
          tasks,
          handleOnSuccessQueueInvoice,
          notify,
          intl
        )

        break
      case Task.EXPORT:
        queueExport(
          idCounter.current,
          tasks,
          handleOnSuccessExport,
          notify,
          intl
        )
        break
      default:
        // eslint-disable-next-line no-console
        console.error('Unknown task type:', type)
    }

    return idCounter.current
  }

  return {
    cancel,
    queue
  }
}

export const BackgroundTasksProvider: React.FC = ({ children }) => {
  const apolloClient = useApolloClient()
  const notify = useNotifier()
  const intl = useIntl()
  const value = useBackgroundTasks(apolloClient, notify, intl)

  return (
    <BackgroundTasksContext.Provider value={value}>
      {children}
    </BackgroundTasksContext.Provider>
  )
}
