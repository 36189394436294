import React, { ChangeEventHandler, FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  CollectionWithProductsCount,
  Product,
  PromotionBanner
} from '../../services'

import { Column } from '../column'
import { TextField } from '../text-field'
import { Loader } from '../loader'
import { ProductRadioItem } from '../product-radio-item'

import { useStyle } from './product-radio-box.styles'
import { ProductRadioBoxProps } from './product-radio-box.types'

import './index.css'

export const ProductRadioBox: FC<ProductRadioBoxProps> = ({
  active,
  className = '',
  data,
  bannerData,
  collectionData,
  itemClassName = '',
  totalCount = 0,
  onChange,
  onChangeBanner,
  onChangeCollection,
  onSearch,
  onFetchMore,
  onRefresh
}) => {
  const classes = useStyle()

  const getActive = (currentValue: string) => currentValue === active

  const handleOnChange = (product: Product) => () => {
    if (onChange) {
      onChange(product)
    }
  }

  const handleOnChangeBanner = (banner: PromotionBanner) => () => {
    if (onChangeBanner) {
      onChangeBanner(banner)
    }
  }

  const handleOnChangeCollection =
    (collection: CollectionWithProductsCount) => () => {
      if (onChangeCollection) {
        onChangeCollection(collection)
      }
    }

  const handleOnSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target

    if (onSearch) {
      onSearch(value)
    }
  }

  const loader = <Loader />
  const dataLength = data ? data.length : 0
  const hasMore = totalCount > dataLength

  return (
    <Column
      fullWidth
      alignItems="flex-start"
      justifyContent="flex-start"
      className={className}
    >
      <TextField
        label="Search"
        labelTx="vendor.radio.search"
        preset="border"
        onChange={handleOnSearch}
      />
      <InfiniteScroll
        pullDownToRefresh
        pullDownToRefreshThreshold={250}
        className={classes.list}
        dataLength={dataLength}
        loader={loader}
        hasMore={hasMore}
        next={onFetchMore}
        refreshFunction={onRefresh}
      >
        {data &&
          data.map((product) => (
            <ProductRadioItem
              key={`${uuidv4()}_${product.id}`}
              src={product.thumbnail2x.url}
              name={product.name}
              active={getActive(product.id)}
              className={itemClassName}
              onClick={handleOnChange(product)}
            />
          ))}
        {bannerData &&
          bannerData.map((banner) => (
            <ProductRadioItem
              key={`${uuidv4()}_${banner.id}`}
              src={banner.image}
              name={banner.name}
              active={getActive(banner.id)}
              className={itemClassName}
              onClick={handleOnChangeBanner(banner)}
            />
          ))}
        {collectionData &&
          collectionData.map((collection) => (
            <ProductRadioItem
              key={`${uuidv4()}_${collection.id}`}
              src={collection.backgroundImage.url}
              name={collection.name}
              active={getActive(collection.id)}
              className={itemClassName}
              onClick={handleOnChangeCollection(collection)}
            />
          ))}
      </InfiniteScroll>
    </Column>
  )
}
