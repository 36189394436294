import { useQuery } from '@apollo/client'
import { CategoriesVariables, GetCategories, GetCategoriesData } from '.'

import { CATEGORIES_QUERY, CATEGORY_BY_ID } from './category.graphql'
import {
  CategoryApi,
  GetCategoryById,
  GetCategoryByIdWithChildren,
  CategoryByIdVariables
} from './category.types'

// our "constructor"
export const categoryService = (): CategoryApi => {
  const useCategoryById = (id: string): GetCategoryById => {
    const { data, loading, error, refetch } = useQuery<
      GetCategoryByIdWithChildren,
      CategoryByIdVariables
    >(CATEGORY_BY_ID, {
      variables: {
        id
      }
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  const useCategories = (variables: CategoriesVariables): GetCategories => {
    const { data, loading, error, refetch } = useQuery<
      GetCategoriesData,
      CategoriesVariables
    >(CATEGORIES_QUERY, {
      variables
    })

    if (error) {
      return { data: null, loading, refetch }
    }

    if (!data) {
      return { data: null, loading, refetch }
    }

    return { data, loading, refetch }
  }

  return {
    useCategoryById,
    useCategories
  }
}
