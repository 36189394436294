import React, { createContext, FC, useContext, useState } from 'react'

import { ToastifyComponent } from '../../components'
import {
  ToastifyModal,
  ToastifyState,
  ToastifyOptionsProps
} from './toastify.types'

const defaultValue = {
  open: () => {},
  close: () => {}
}

const defaultOptionsProps: ToastifyOptionsProps = {
  text: '',
  className: ''
}

export const ToastifyContext = createContext<ToastifyModal>(defaultValue)

export const ToastifyProvider: FC = ({ children }) => {
  const [stateToastify, changeStateToastify] = useState<ToastifyState>({
    open: false,
    ...defaultOptionsProps
  })

  const handleOnClose = () => {
    changeStateToastify({ ...stateToastify, open: false })
  }

  const handleOnOpen = (prevTextProps?: ToastifyOptionsProps) => {
    const textProps = prevTextProps || defaultOptionsProps
    const { timeout = 4000 } = textProps
    changeStateToastify({ ...stateToastify, open: true, ...textProps })

    if (timeout) {
      setTimeout(() => {
        handleOnClose()
      }, timeout)
    }
  }

  const context = {
    open: handleOnOpen,
    close: handleOnClose
  }

  return (
    <ToastifyContext.Provider value={context}>
      {children}
      <ToastifyComponent
        className={stateToastify.className}
        type={stateToastify.type}
        open={stateToastify.open}
        text={stateToastify.text}
        tx={stateToastify.tx}
        values={stateToastify.values}
        onClose={handleOnClose}
      />
    </ToastifyContext.Provider>
  )
}

export const useToastify = () => useContext(ToastifyContext)
