import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    overflowY: 'auto',
    maxHeight: 400
  },
  item: {
    marginTop: 8
  },
  text: {
    marginLeft: 12
  }
})
