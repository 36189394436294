import { createUseStyles } from 'react-jss'

import { SortStyleProps } from '../sort-item.types'

export const useStyle = createUseStyles({
  caretDown: {
    position: 'absolute',
    right: 0,
    transform: ({ open }: SortStyleProps) =>
      open ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.25s ease-in-out'
  }
})
