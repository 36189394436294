import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    transition: 'box-shadow 0.25s ease-in-out'
  },
  imgContainer: {},
  imgLabel: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    padding: 8,
    position: 'absolute',
    right: 0,
    top: 0
  },
  img: {
    objectFit: 'cover',
    width: 248,
    height: 180
  },
  details: {
    height: 52,
    padding: '24px 8px',
    alignItems: 'center',
    width: 'calc(100% - 16px)'
  }
}))
