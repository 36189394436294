import React, { FC } from 'react'

import { Column } from '../column'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './product-item.styles'
import { ProductItemProps } from './product-item.types'

export const ProductItem: FC<ProductItemProps> = ({
  src,
  className = '',
  name,
  onClick
}) => {
  const classes = useStyle()

  return (
    <Row
      fullWidth
      className={className}
      justifyContent="flex-start"
      onClick={onClick}
    >
      <img className={classes.image} height={48} width={48} src={src} />
      <Column className={classes.info} alignItems="flex-start">
        <Text text={name} />
      </Column>
    </Row>
  )
}
