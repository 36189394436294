import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import {
  BackBreadcrumbs,
  Column,
  Row,
  Section,
  Text,
  TextArea,
  Slider,
  getCurrencySymbol,
  getPriceToFixed,
  Button,
  getListFromList,
  getInstruction
} from '../../components'
import { OfferCreateInput, OfferItemInput, OfferStatus } from '../../services'
import {
  useDeliveryPricePerItem,
  useOfferInputDiscount,
  useUser
} from '../../hooks'
import { useApi } from '../../providers'
import { arrayHasElements } from '../../utils/tables'
import { OfferVariantSlider } from '../../views/offer-variant-form/offer-variant-slider'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import {
  getPriceWithQuantity,
  getTotalOfferPrice,
  getTotalOfferQuantity
} from './helpers'
import { useStyle } from './offer-form.styles'
import { OfferFormProps } from './offer-form.types'
import { SummarySection } from './summary-section'
import { SubmitContainer } from './submit-container'
import { SummaryVariantSlider } from './summary-variant-slider'

export const OfferForm: FC<OfferFormProps> = ({
  item,
  title,
  titleTx,
  currency,
  id,
  product,
  number,
  onSubmit,
  onCurrentStatus
}) => {
  const { deliveryPrice: offerDeliveryPrice, instruction: instructionApi } =
    useApi()
  const history = useHistory()
  const classes = useStyle()
  const { onUpdateOfferTotalCount } = useUser()
  const currencySymbol = currency ? getCurrencySymbol(currency) : undefined
  const [openConfirmModal, changeOpenConfirmModal] = useState(false)
  const [value, changeValue] = useState<OfferCreateInput>(item)
  const [prevValue, changePrevValue] = useState<OfferCreateInput>(item)
  const [variantsTotalPrice, changeVariantsTotalPrice] = useState(0)
  const [isDiscount, changeIsDiscount] = useState(Boolean(value.discount))
  const [itemsVariants, changeItemsVariants] = useState<OfferItemInput[]>([])

  const { defaultPrice } = product
  const offerNumber = { number }
  const offerTitle = `Offer #${number}`

  const { data: instructions } = instructionApi.useInstructions({ first: 100 })

  const DeliveryWeightPerItem = useDeliveryPricePerItem(product)
  const fullOfferQuantity = value.items ? getTotalOfferQuantity(value.items) : 0
  const deliveryProductWeight = getPriceWithQuantity(
    DeliveryWeightPerItem,
    fullOfferQuantity
  )

  const defaultTotalDeliveryVariables = {
    first: 100,
    filter: {
      weight: String(deliveryProductWeight)
    }
  }

  const { data: deliveryTotalResponse } =
    offerDeliveryPrice.useTotalDeliveryPrice(defaultTotalDeliveryVariables)

  const Discount = useOfferInputDiscount(value)

  const instructionsList = getListFromList(instructions?.instructions)

  const offerCreateDescribingInfo = getInstruction(
    instructionsList,
    'offerCreateDescribingInfo'
  )
  const offerCreateAdditionalOptionsInfo = getInstruction(
    instructionsList,
    'offerCreateAdditionalOptionsInfo'
  )

  const TotalDeliveryPrice = useMemo(() => {
    if (deliveryTotalResponse !== null) {
      const deliveryPricesList = getListFromList(
        deliveryTotalResponse.totalDeliveryPrice
      )
      if (arrayHasElements(deliveryPricesList)) {
        return deliveryTotalResponse.totalDeliveryPrice.edges[0].node?.price
      }
    }

    return 0
  }, [deliveryTotalResponse?.totalDeliveryPrice.edges])

  const offerPriceWithDelievry = TotalDeliveryPrice
    ? variantsTotalPrice + TotalDeliveryPrice
    : variantsTotalPrice

  const offerPriceWithDiscount = Discount && offerPriceWithDelievry - Discount

  const pricePerItem: number[] = []
  const handleOnGetTotalItemPrice = (price: number) => {
    pricePerItem.push(price)
    const getTotalOfferItemVariantsPrice = getTotalOfferPrice(pricePerItem)
    changeVariantsTotalPrice(getTotalOfferItemVariantsPrice)
  }

  const productUnitPrice = `${currencySymbol}${getPriceToFixed(defaultPrice)}`

  const isStatusDraft =
    value.status === OfferStatus.START_OFFER ||
    value.status === OfferStatus.DRAFT

  const isDisabled = !value.productionDays || value.productionDays < 0
  const isDisabledDraftBtn = isDisabled || !isStatusDraft

  const handleOnOpenConfirmModal = (state: boolean) => () => {
    changeOpenConfirmModal(state)
  }

  useEffect(() => {
    if (value.items) {
      const nextValue = value.items.map((variant) => ({
        ...variant,
        uuid: uuid()
      }))

      changeItemsVariants(nextValue)
    }
  }, [])

  const handleOnDublicateItem = () => {
    if (!value.items || !arrayHasElements(value.items)) {
      return
    }

    const nextValue = {
      ...value,
      items: [...value.items, value.items[0]]
    }

    const nextItemsValue = {
      ...itemsVariants[0],
      uuid: uuid()
    }

    changeValue(nextValue)

    return itemsVariants.push(nextItemsValue)
  }

  const handleOnDeleteItem = (itemId: string) => () => {
    if (!arrayHasElements(itemsVariants)) {
      return
    }

    const nextData = itemsVariants.filter((variant) => variant.uuid !== itemId)

    const nextValue = {
      ...value,
      items: nextData
    }

    changeItemsVariants(nextData)
    changeValue(nextValue)
  }

  const handleOnChange =
    (prop: keyof OfferCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value: nextValue } = event.target
      changeValue({
        ...value,
        [prop]: nextValue
      })
    }

  const handleOnChangeValue = (nextOffer: OfferCreateInput) => {
    changeValue({
      ...value,
      ...nextOffer
    })
  }

  const handleOnDelete = () => {
    if (onSubmit) {
      onSubmit({
        ...value,
        status: OfferStatus.DECLINED_BY_VENDOR
      })
    }
    if (onUpdateOfferTotalCount && value.status === OfferStatus.DRAFT) {
      onUpdateOfferTotalCount(-1)
    }
    changeOpenConfirmModal(false)
  }

  const handleOnChangeNoSuggestion = (noSuggestion: boolean) => {
    const nextOffer: OfferCreateInput = {
      ...value,
      noSuggestion
    }

    const nextPrevOffer: OfferCreateInput = {
      ...prevValue,
      noSuggestion
    }

    changePrevValue(nextOffer)
    changeValue(nextPrevOffer)

    if (handleOnChangeValue) {
      handleOnChangeValue(nextOffer)
    }
  }

  const handleOnChangeDiscount = (active: boolean) => {
    changeIsDiscount(active)
    if (active) {
      const nextOffer: OfferCreateInput = {
        ...value,
        discount: Discount
      }
      const nextPrevOffer: OfferCreateInput = {
        ...prevValue,
        discount: Discount
      }

      changePrevValue(nextOffer)
      changeValue(nextPrevOffer)

      if (handleOnChangeValue) {
        handleOnChangeValue(nextOffer)
      }
    } else {
      const nextValue: OfferCreateInput = {
        ...value,
        discount: 0
      }

      if (handleOnChangeValue) {
        handleOnChangeValue(nextValue)
      }
    }
  }

  const handleOnContactCustomer = () => {
    history.push(`${CabinetNavigatorPathVendor.OFFER_CHAT}/${id}`)
  }

  const handleOnChangeOfferInput =
    (itemId: string) => (offerInput: OfferItemInput) => {
      if (!arrayHasElements(itemsVariants)) {
        return changeValue({ ...value, items: [offerInput] })
      }

      const nextItems = itemsVariants.map((itemInput) => {
        if (itemInput.uuid !== itemId) {
          return itemInput
        }

        return { ...itemInput, ...offerInput }
      })
      const nextValue = { ...value, items: nextItems }

      changeItemsVariants(nextItems)
      changeValue(nextValue)
    }

  const handleOnSaveAsDraft = () => {
    if (onSubmit) {
      onSubmit({
        ...value,
        status: OfferStatus.DRAFT
      })
    }
    if (onUpdateOfferTotalCount && value.status !== OfferStatus.DRAFT) {
      onUpdateOfferTotalCount(+1)
    }
  }

  const handleOnSendToCustomer = () => {
    if (onCurrentStatus && value.status) {
      onCurrentStatus(value.status)
    }

    if (onSubmit) {
      onSubmit({
        ...value,
        discount: Discount,
        status: OfferStatus.NEW
      })
    }
  }

  const noSuggestionSlider = (
    <Row>
      <Text text="No suggestion" tx="offer.no.suggestion.title" preset="body" />
      <Slider
        className={classes.sliderItem}
        defaultValue={value.noSuggestion}
        onChange={handleOnChangeNoSuggestion}
      />
    </Row>
  )

  return (
    <>
      <Column
        fullWidth
        alignItems="flex-start"
        justifyContent="flex-start"
        className={classes.container}
      >
        <BackBreadcrumbs text="All items" tx="item.form.back" />
        <Text
          className={classes.title}
          preset="h3"
          text={offerTitle}
          tx="offer.form.title"
          values={offerNumber}
        />
        <Row fullWidth alignItems="flex-start" className={classes.content}>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.leftColumn}
          >
            <Column
              fullWidth
              className={classes.titleContainer}
              alignItems="flex-start"
            >
              <Text preset="h3" text={title} tx={titleTx} />
              <Text
                className={classes.text}
                preset="body"
                text={productUnitPrice}
              />
            </Column>
            <Section
              isInfo
              className={classes.section}
              infoText={offerCreateDescribingInfo}
              title="Describing and suggestion"
              titleTx="offer.form.describing.title"
              rightButton={noSuggestionSlider}
            >
              {value.noSuggestion && (
                <Column className={classes.sectionAdditionContainer}>
                  <TextArea
                    defaultValue={value?.description}
                    preset="border"
                    maxLength={1000}
                    label="Requirements"
                    labelTx="offer.form.requirements"
                    onChange={handleOnChange('description')}
                  />
                </Column>
              )}
            </Section>
            {itemsVariants.map((optionItem, index) => {
              const titleNum = index + 1
              const titleValue = { titleNum }
              const count = itemsVariants.length > 1
              const variantId = optionItem.uuid || ''

              return (
                <OfferVariantSlider
                  key={`option_${optionItem.uuid}`}
                  title={`Item ${titleNum}`}
                  titleTx="offer.form.item.name.title"
                  instruction={offerCreateAdditionalOptionsInfo}
                  value={optionItem}
                  titleValue={titleValue}
                  count={count}
                  onChangeValue={handleOnChangeOfferInput(variantId)}
                  onDeleteItem={handleOnDeleteItem(variantId)}
                />
              )
            })}
            <Row fullWidth justifyContent="flex-start">
              <Button
                className={classes.optionButton}
                text="ADD ITEM"
                tx="offer.form.add.item.variant"
                color="darkBlue"
                preset="fixedBorderButton"
                textColor="darkBlue"
                textPreset="h5"
                onClick={handleOnDublicateItem}
              />
            </Row>
          </Column>
          <Column
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.rightColumn}
          >
            {itemsVariants.map((optionItem, index) => {
              const titleNum = index + 1
              const titleValue = { titleNum }
              const variantId = optionItem.uuid || ''

              return (
                <SummaryVariantSlider
                  key={`option_summary_${optionItem.uuid}`}
                  title={`Item ${titleNum}`}
                  tittleTx="offer.form.item.name.title"
                  titleValue={titleValue}
                  itemVariant={optionItem}
                  defaultProductPrice={defaultPrice}
                  currency={currencySymbol}
                  onGetVariantPrice={handleOnGetTotalItemPrice}
                  onChangeValue={handleOnChangeOfferInput(variantId)}
                />
              )
            })}
            <SummarySection
              weight={deliveryProductWeight}
              deliveryPrice={TotalDeliveryPrice}
              defaultOfferPrice={variantsTotalPrice}
              totalPriceWithDelivery={offerPriceWithDelievry}
              totalPriceWithDiscount={offerPriceWithDiscount}
              currency={currencySymbol}
              isDiscount={isDiscount}
              discountValue={Discount}
              quantity={fullOfferQuantity}
              productionDays={value.productionDays}
              onChange={handleOnChange('discount')}
              onChangeDiscount={handleOnChangeDiscount}
              onChangeProductionDays={handleOnChange('productionDays')}
            />
          </Column>
        </Row>
      </Column>
      <SubmitContainer
        disabled={isDisabled}
        disabledDraftBtn={isDisabledDraftBtn}
        className={classes.submitContainer}
        open={openConfirmModal}
        onDelete={handleOnDelete}
        onContactCustomer={handleOnContactCustomer}
        onSendToCustomer={handleOnSendToCustomer}
        onSaveAsDraft={handleOnSaveAsDraft}
        onOpenConfirmModal={handleOnOpenConfirmModal}
      />
    </>
  )
}
