import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import camelcaseKeys from 'camelcase-keys'

import {
  Column,
  Row,
  Text,
  Section,
  RatingStarsComponent,
  getListFromList,
  arrayHasElements,
  Button,
  Dropdown,
  DropdownItemData,
  getItemById,
  LoadingContainer
} from '../../components'
import { useApi } from '../../providers'
import {
  FeedbackFiltersParseJSON,
  FeedbackUpdateInput,
  FeedbackVariables,
  FeedbackOrderField,
  OrderDirection,
  FeedbackFilterInput,
  FullFeedback
} from '../../services'
import { useDebounceInput } from '../../hooks'

import { getQueryParams } from '../helpers'
import { FeedbackMonitoringPageNavigation } from '../cabinet-navigator'

import { sortData } from './helpers'
import { FeedbackMonitoringVendorItem } from './feedback-monitiring-vendor-item'
import { FeedbackMonitoringProductItem } from './feedback-monitiring-product-item'
import {
  FeedbackMonitoringNav,
  FeedbackMonitoringNavItem
} from './feedback-monitoring-nav'
import { useStyle } from './feedback.monitoring.styles'

export const FeedbackMonitoringPage = () => {
  const location = useLocation()
  const classes = useStyle()
  const { feedback } = useApi()
  const first = 10
  const SEARCH_QUERY_MIN = 3
  const EQUALS_SYMBOL = '='

  const isToProduct = location.pathname.includes(
    FeedbackMonitoringPageNavigation.FEEDBACK_TO_PRODUCT
  )
  const isToVendor = location.pathname.includes(
    FeedbackMonitoringPageNavigation.FEEDBACK_TO_VENDOR
  )
  const defaultVariables = {
    first,
    filter: {
      isToProduct,
      isToVendor
    }
  }

  const [variables, changeVariables] =
    useState<FeedbackVariables>(defaultVariables)

  const { data, loading, loadingMore, refetch, fetchMore } =
    feedback.useFeedbacks(variables)
  const { onSubmit } = feedback.useUpdateFeedback()

  useEffect(() => {
    refetch(defaultVariables)
    changeVariables(defaultVariables)
  }, [location])

  const QueryParams = useMemo(() => {
    return getQueryParams(location.search) as FeedbackFilterInput
  }, [location.search])

  const QueryParamsFeedbackId = useMemo(() => {
    return getQueryParams(location.search) as FullFeedback
  }, [location.search])

  const getFullFeedbackId = QueryParamsFeedbackId.id
    ? QueryParamsFeedbackId.id + EQUALS_SYMBOL
    : undefined

  const Filters = useMemo<FeedbackFiltersParseJSON>(() => {
    if (data) {
      const list = camelcaseKeys(JSON.parse(data.feedbacks.options), {
        deep: true
      })

      return list
    }

    return {
      averageRating: 0,
      positiveFeedbackPercentage: 0,
      total: 0,
      toVendorTotal: 0,
      toProductTotal: 0
    }
  }, [data])

  const Data = useMemo(() => {
    if (data) {
      return getListFromList(data?.feedbacks)
    }
    return []
  }, [data?.feedbacks])

  const GetFeedbackById = useMemo(() => {
    return getItemById(Data, getFullFeedbackId)
  }, [Data])

  const getSortByValue = (value: string) => {
    if (value) {
      const values = value.split(',')
      return {
        field: values[0] as FeedbackOrderField,
        direction: values[1] as OrderDirection
      }
    }

    return undefined
  }

  const handleOnChangeDropdown = (item: DropdownItemData) => {
    const input = {
      ...variables,
      after: undefined,
      sortBy: getSortByValue(item.value)
    }

    refetch(input)
    changeVariables(input)
  }

  const isNextPage = data?.feedbacks.pageInfo.hasNextPage

  const handleOnSubmit = (
    feedbackId: string,
    nextFeedback: FeedbackUpdateInput
  ) => {
    if (nextFeedback) {
      onSubmit({ id: feedbackId, input: nextFeedback })
    }
  }

  const handleOnLoadNext = () => {
    if (data && arrayHasElements(Data)) {
      const nextVariables = {
        ...variables,
        after: data.feedbacks.pageInfo.endCursor
      }

      if (fetchMore) {
        fetchMore({ variables: nextVariables })
      }
    }
  }

  const handleOnSearch = (value: string) => {
    const search = value.length >= SEARCH_QUERY_MIN ? value : ''
    if (search !== variables.filter?.search) {
      const nextFeedbackVariables = {
        ...variables,
        after: undefined,
        filter: {
          ...variables.filter,
          search
        }
      }

      refetch(nextFeedbackVariables)
    }
  }

  const { onChange: onChangeSearch } = useDebounceInput({
    onChange: handleOnSearch
  })

  const handleOnChangeSearch = (value: string) => {
    onChangeSearch(value)
  }

  const {
    total,
    positiveFeedbackPercentage = 0,
    toVendorTotal = 0,
    toProductTotal = 0
  } = Filters

  const positiveFeedbacksNum = positiveFeedbackPercentage.toFixed(0)

  const totalCountFeedbacks = `${total}`
  const positiveFeedbacks = `${positiveFeedbacksNum}%`

  const valueVendorTabNum = { vendorTabNum: toVendorTotal }
  const valueProductTabNum = { productTabNum: toProductTotal }

  const textVendorTab = `To Vendor · ${toVendorTotal}`
  const textProductTab = `To Product · ${toProductTotal}`

  const checkSearchValue = variables.filter?.search === undefined
  const checkFeedbackById =
    QueryParamsFeedbackId.id && GetFeedbackById && checkSearchValue

  const NAVIGATION_TABS: FeedbackMonitoringNavItem[] = [
    {
      text: textVendorTab,
      tx: 'feedback.monitoring.vendor.tab',
      preset: 'h5',
      value: valueVendorTabNum,
      link: FeedbackMonitoringPageNavigation.FEEDBACK_TO_VENDOR,
      content: checkFeedbackById ? (
        <FeedbackMonitoringVendorItem
          {...GetFeedbackById}
          onSubmit={handleOnSubmit}
        />
      ) : (
        <LoadingContainer loading={loading}>
          {Data.map((vendorFeedback, index) => (
            <FeedbackMonitoringVendorItem
              key={`feedback_vendor_${vendorFeedback.id}${index}`}
              {...vendorFeedback}
              onSubmit={handleOnSubmit}
            />
          ))}
          <LoadingContainer loading={loadingMore}>
            {isNextPage && !loading && (
              <Button
                className={`${classes.load} ${classes.button}`}
                preset="primary"
                text="LOAD MORE"
                textPreset="h5"
                textColor="white"
                tx="cabinet.items.load"
                onClick={handleOnLoadNext}
              />
            )}
          </LoadingContainer>
        </LoadingContainer>
      )
    },
    {
      text: textProductTab,
      tx: 'feedback.monitoring.product.tab',
      preset: 'h5',
      value: valueProductTabNum,
      link: FeedbackMonitoringPageNavigation.FEEDBACK_TO_PRODUCT,
      content: checkFeedbackById ? (
        <FeedbackMonitoringProductItem
          {...GetFeedbackById}
          onSubmit={handleOnSubmit}
        />
      ) : (
        <LoadingContainer loading={loading}>
          {Data.map((productFeedback, index) => (
            <FeedbackMonitoringProductItem
              key={`feedback_product_${productFeedback.id}${index}`}
              {...productFeedback}
              onSubmit={handleOnSubmit}
            />
          ))}
          <LoadingContainer loading={loadingMore}>
            {isNextPage && !loading && (
              <Button
                className={`${classes.load} ${classes.button}`}
                preset="primary"
                text="LOAD MORE"
                textPreset="h5"
                textColor="white"
                tx="cabinet.items.load"
                onClick={handleOnLoadNext}
              />
            )}
          </LoadingContainer>
        </LoadingContainer>
      )
    }
  ]

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <Row
        fullWidth
        className={classes.titleContainer}
        justifyContent="space-between"
      >
        <Text
          color="black"
          preset="h3"
          text="Feedback Monitoring"
          tx="cabinet.feedback.monitoring.title"
        />
        <Row className={classes.filter}>
          <Text
            className={classes.filterText}
            text="Sort by:"
            tx="feedback.monitoring.sort.by"
            preset="h6"
            color="inactive"
          />
          <Dropdown
            className={classes.filterItem}
            label="Clear"
            labelTx="feedback.monitoring.clear.sort"
            preset="body"
            data={sortData}
            onChange={handleOnChangeDropdown}
          />
        </Row>
      </Row>
      <Row className={classes.content} fullWidth alignItems="flex-start">
        <Column className={classes.leftSection}>
          <FeedbackMonitoringNav
            onSearch={handleOnChangeSearch}
            dafaultSearchValue={QueryParams.search}
            data={NAVIGATION_TABS}
          />
        </Column>
        <Section
          className={classes.section}
          title="Summary"
          titleTx="feedback.monitoring.summary.title"
        >
          <Column
            className={classes.sectionItem}
            fullWidth
            alignItems="flex-start"
          >
            <Text
              text="Average Ratings"
              preset="h5"
              color="black"
              tx="feedback.monitoring.summary.average.ratings"
            />
            <Row
              className={classes.rating}
              fullWidth
              justifyContent="flex-start"
            >
              <RatingStarsComponent
                rating={Filters.averageRating}
                edit={false}
              />
            </Row>
          </Column>
          <Column
            className={classes.sectionItem}
            fullWidth
            alignItems="flex-start"
          >
            <Text
              text="Positive Feedback"
              preset="h5"
              color="black"
              tx="feedback.monitoring.summary.positive.feedback"
            />
            <Text
              className={classes.rating}
              text={positiveFeedbacks}
              preset="body"
              color="inactive"
            />
          </Column>
          <Column
            className={classes.sectionItem}
            fullWidth
            alignItems="flex-start"
          >
            <Text
              text="Total Reviews"
              preset="h5"
              color="black"
              tx="feedback.monitoring.summary.average.total.reviews"
            />
            <Text
              className={classes.rating}
              text={totalCountFeedbacks}
              preset="body"
              color="inactive"
            />
          </Column>
        </Section>
      </Row>
    </Column>
  )
}
