import React, { FC } from 'react'

import { Text } from '../../../components/text'
import { Column } from '../../../components/column'

import { useStyle } from './subscription-container.styles'
import { SubscriptionContainerProps } from './subscription-container.types'
import { Row } from '../../../components'

export const SubscriptionContainer: FC<SubscriptionContainerProps> = ({
  title,
  titleTx,
  children,
  active
}) => {
  const classes = useStyle()

  return (
    <Column
      className={`${classes.container} ${active && classes.activeContainer}`}
      alignItems="flex-start"
    >
      <Text color="black" preset="h4" text={title} tx={titleTx} />
      {active && (
        <Row className={classes.active}>
          <Text color="white" preset="h5" text="Active" />
        </Row>
      )}
      {children}
    </Column>
  )
}
