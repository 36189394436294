import { createUseStyles } from 'react-jss'

import { NavLinkStyle } from './nav-link.types'

export const useStyle = createUseStyles({
  decoration: {
    textDecoration: ({ decoration }: NavLinkStyle) =>
      decoration ? 'underline' : 'none'
  },
  link: {
    color: ({ color }: NavLinkStyle) => color,
    cursor: 'pointer',
    textDecorationColor: ({ color, decoration }: NavLinkStyle) =>
      decoration ? color : 'none'
  }
})
