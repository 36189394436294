import React, { useState } from 'react'

import { Column } from '../../components'
import { SortVariables, useSort, useTable } from '../../hooks'
import { useApi } from '../../providers'
import { UserSortField } from '../../services'
import { CustomersListVariables } from '../../services/user-list'

import {
  UserListCustomersTable,
  UserListCustomersTableProps
} from './user-list-customers-table'
import { useStyle } from './user-list-customers.style'

const rowCounts = [5, 10, 20, 30]

export const UserListCustomersPage = () => {
  const classes = useStyle()

  const { userList } = useApi()

  const defaultVariables = {
    first: rowCounts[1]
  }

  const { data, loading, refetch } =
    userList.useCustomersListData(defaultVariables)

  const [variables, changeVariables] =
    useState<CustomersListVariables>(defaultVariables)

  const {
    Data,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.customers,
    defaultRowCounts: defaultVariables.first,
    defaultVariables
  })

  const handleOnSearch: UserListCustomersTableProps['onChangeSearch'] = (
    event
  ) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnSort = (sortVariables: SortVariables<UserSortField>) => {
    refetch({
      ...sortVariables,
      before: undefined,
      after: undefined,
      last: undefined
    })

    onResetPageState()
    changeVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.contsiner} justifyContent="flex-start">
      <UserListCustomersTable
        className={classes.table}
        rowCounts={rowCounts}
        data={Data}
        loading={loading}
        paginationState={paginationState}
        onChangeSearch={handleOnSearch}
        onChangePage={onChangePage}
        onChangeRowCount={onChangeRowCount}
        onNextPage={onNextPage}
        onPrevioslyPage={onPrevioslyPage}
        onSort={onSort}
      />
    </Column>
  )
}
