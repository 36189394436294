import React, { FC, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import Talk from 'talkjs'

import {
  Column,
  Dropdown,
  getCompanyName,
  getCurrencySymbol,
  getFullName,
  LoadingContainer,
  OrderSlider,
  PermissionContent,
  Row,
  TextField,
  OrderSliderProps,
  getPriceToFixed
} from '../../../components'
import { useUser } from '../../../hooks'
import { useChat } from '../../../providers'
import {
  ChatUserRole,
  Order,
  OrderVendor,
  PermissionEnum
} from '../../../services'

import {
  CabinetNavigatorPathAdmin,
  CabinetNavigatorPathVendor
} from '../../cabinet-navigator'
import { EmptyList } from '../../views'

import { useStyle } from './order-control-list.styles'
import { OrderControlListProps } from './order-control-list.types'
import { orderSortDataAdmin, orderSortDataVendor } from './helpers'

const defaultShippingAddress = {
  firstName: '',
  lastName: '',
  streetAddress1: '',
  postalCode: '',
  city: ''
}

export const OrderControlList: FC<OrderControlListProps> = ({
  className = '',
  data,
  dataById,
  checkOrderById,
  loading,
  defaultSearchValue,
  loadingMore,
  totalCount,
  onChange,
  onChangeSearch,
  onChangeDropdown
}) => {
  const history = useHistory()
  const { onCreateConversation } = useChat()
  const userContext = useUser()
  const classes = useStyle()

  const IsStaff = useMemo(() => {
    const { user } = userContext

    if (user) {
      return user.isStaff
    }

    return false
  }, [userContext.user?.isStaff])

  const handleOnContact = (contactUser: OrderVendor, role: ChatUserRole) => {
    const { id, email, firstName, avatar } = contactUser
    const talkUser = new Talk.User({
      id,
      email,
      name: firstName,
      photoUrl: avatar?.url,
      role
    })

    if (onCreateConversation) {
      onCreateConversation(talkUser)
    }

    history.push(CabinetNavigatorPathAdmin.COMMUNICATION)
  }

  const handleOnContactCustomer =
    (order: Order): OrderSliderProps['onContactCustomer'] =>
    (event) => {
      event.preventDefault()
      event.stopPropagation()

      if (!order.offer) {
        return
      }

      const path = `${CabinetNavigatorPathVendor.ORDER_CHAT}/${order.id}${CabinetNavigatorPathVendor.OFFER}/${order.offer.adminCustomerConversationId}`
      const adminPath = `${CabinetNavigatorPathAdmin.ORDER_CHAT}/${order.id}${CabinetNavigatorPathVendor.OFFER}/${order.offer.adminCustomerConversationId}`

      if (IsStaff) {
        history.push(adminPath)
      } else {
        history.push(path)
      }
    }

  const handleOnContactVendor = (vendor?: OrderVendor | null) => () => {
    if (vendor) {
      handleOnContact(vendor, ChatUserRole.VENDOR)
    }
  }

  const currencySymbolItem = dataById
    ? getCurrencySymbol(dataById?.total.currency)
    : undefined
  const priceItem = `${currencySymbolItem}${dataById?.totalPrice}`
  const {
    streetAddress1: streetAddressItem,
    postalCode: postalCodeItem,
    city: cityItem
  } = dataById?.shippingAddress || defaultShippingAddress

  const customerNameItem = getFullName(dataById?.user)
  const companyNameItem = getCompanyName(dataById?.vendor)
  const addressItem = `${streetAddressItem}, ${postalCodeItem} ${cityItem}`

  return (
    <Column fullWidth className={className}>
      <Row fullWidth className={classes.searchContainer}>
        <TextField
          preset="border"
          label="Search"
          labelTx="orderControl.search.title"
          defaultValue={defaultSearchValue}
          onChange={onChangeSearch}
        />
        <PermissionContent
          permissions={[PermissionEnum.ADD_CHANGE_REMOVE_ORDERS]}
        >
          <Row className={classes.sort}>
            <Dropdown
              className={classes.sortDropdown}
              label="Clear"
              labelTx="order.sort.clear"
              preset="body"
              data={orderSortDataAdmin}
              onChange={onChangeDropdown}
            />
          </Row>
        </PermissionContent>
        <PermissionContent
          permissions={[PermissionEnum.ADD_CHANGE_REMOVE_OWN_ORDERS]}
        >
          <Row className={classes.sort}>
            <Dropdown
              className={classes.sortDropdown}
              label="Clear"
              labelTx="order.sort.clear"
              preset="body"
              data={orderSortDataVendor}
              onChange={onChangeDropdown}
            />
          </Row>
        </PermissionContent>
      </Row>

      <LoadingContainer loading={loading}>
        {Boolean(totalCount) &&
          (checkOrderById && dataById ? (
            <OrderSlider
              isStaff={!IsStaff}
              className={classes.order}
              address={addressItem}
              orderId={dataById.id}
              number={dataById.number}
              created={dataById.created}
              startDate={dataById.startDate}
              finishDate={dataById.finishDate}
              trackingNumber={dataById.trackingNumber}
              price={priceItem}
              status={dataById.status}
              mainStatus={dataById.mainStatus}
              paymentStatus={dataById.paymentStatus}
              customerName={customerNameItem}
              vendorName={companyNameItem}
              vendor={dataById.vendor}
              shippingAddress={dataById.shippingAddress}
              lines={dataById.lines}
              remainingTimeProductionStarted={
                dataById.remainingTimeProductionStarted
              }
              offer={dataById.offer}
              totalPrice={dataById.totalPrice}
              deliveryPrice={dataById.deliveryPrice}
              onChange={onChange}
              onContactCustomer={handleOnContactCustomer(dataById)}
              onContactVendor={handleOnContactVendor(dataById.vendor)}
            />
          ) : (
            <>
              {data.map((item) => {
                const {
                  id,
                  created,
                  startDate,
                  finishDate,
                  trackingNumber,
                  number,
                  lines,
                  offer,
                  status,
                  mainStatus,
                  paymentStatus,
                  totalPrice,
                  deliveryPrice,
                  total,
                  shippingAddress,
                  remainingTimeProductionStarted,
                  user,
                  vendor
                } = item
                const currencySymbol = getCurrencySymbol(total.currency)
                const price = `${currencySymbol}${getPriceToFixed(totalPrice)}`
                const { streetAddress1, postalCode, city } =
                  shippingAddress || defaultShippingAddress

                const customerName = getFullName(user)
                const companyName = getCompanyName(vendor)
                const address = `${streetAddress1}, ${postalCode} ${city}`

                return (
                  <OrderSlider
                    key={`order_${id}`}
                    isStaff={!IsStaff}
                    className={classes.order}
                    address={address}
                    orderId={id}
                    number={number}
                    created={created}
                    startDate={startDate}
                    finishDate={finishDate}
                    trackingNumber={trackingNumber}
                    price={price}
                    status={status}
                    mainStatus={mainStatus}
                    paymentStatus={paymentStatus}
                    customerName={customerName}
                    vendorName={companyName}
                    vendor={vendor}
                    shippingAddress={shippingAddress}
                    lines={lines}
                    remainingTimeProductionStarted={
                      remainingTimeProductionStarted
                    }
                    offer={offer}
                    totalPrice={totalPrice}
                    deliveryPrice={deliveryPrice}
                    onChange={onChange}
                    onContactCustomer={handleOnContactCustomer(item)}
                    onContactVendor={handleOnContactVendor(vendor)}
                  />
                )
              })}
            </>
          ))}

        {!totalCount && !loadingMore && (
          <Column fullWidth>
            <EmptyList
              title="Oops! Your Orders Control is empty"
              titleTx="order.empty.list.title"
              text="Your generated orders will appear here after payment"
              tx="order.empty.list.text"
            />
          </Column>
        )}
      </LoadingContainer>
    </Column>
  )
}
