import { TextProps } from '../../../components'
import { Color } from '../../../components/Theme'
import { Pricing } from '../../../services'

export const getDotColor = (isPublished: boolean): Color => {
  if (isPublished) {
    return 'yellow'
  }

  return 'shadow'
}

export const getStatusLabel = (isPublished: boolean): TextProps => {
  if (isPublished) {
    return {
      text: 'Active',
      tx: 'item.active'
    }
  }

  return {
    text: 'Inactive',
    tx: 'item.inactive'
  }
}

export const getPrice = (pricing: Pricing): string => {
  const { start } = pricing.priceRange

  return `${start.gross.amount} ${start.gross.currency}`
}
