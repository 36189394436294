import React, { FC, useState } from 'react'

import { Text } from '../text'
import { Row } from '../row'
import { Icon, ICONS } from '../icon'

import { useStyle } from './table-paggination-bar.styles'
import { TablePagginationBarProps } from './table-paggination-bar.types'

export const TablePagginationBar: FC<TablePagginationBarProps> = ({
  paginationState,
  rowCounts,
  onPrevioslyPage,
  onNextPage,
  onChangeRowCount
}) => {
  const { page, limit } = paginationState
  const [openRow, changeOpenRow] = useState(false)
  const classes = useStyle()

  const handleOnOpenRow = () => {
    changeOpenRow(!openRow)
  }

  const handleOnChangeRowCount = (count: number) => () => {
    if (onChangeRowCount) {
      onChangeRowCount(count)
    }
  }

  const handleOnNextPage = () => {
    if (onNextPage) {
      onNextPage()
    }
  }

  return (
    <Row className={classes.bottomRow} justifyContent="space-between">
      <Row onClick={handleOnOpenRow}>
        <Text color="inactiveSecondary" text="No of Rows:" />
        <Row className={classes.dropdownRow}>
          <Text
            className={classes.dropdownSelectedText}
            color="yellow"
            preset="h5"
            text={String(limit)}
          />
          <Icon className={classes.icon} src={ICONS.arrowDown} />
          {openRow && (
            <Row className={classes.dropdownSelectRow}>
              {rowCounts.map((count) => (
                <Row
                  key={`row_count_${count}`}
                  className={classes.dropdownItem}
                  onClick={handleOnChangeRowCount(count)}
                >
                  <Text
                    preset="h5"
                    text={String(count)}
                    className={classes.dropdownItemText}
                  />
                </Row>
              ))}
            </Row>
          )}
        </Row>
      </Row>
      <Row>
        <Icon
          className={classes.icon}
          src={ICONS.keyboardArrowLeft}
          onClick={onPrevioslyPage}
        />
        <Text
          key={page}
          className={classes.icon}
          color={'yellow'}
          preset="h5"
          text={String(page)}
        />
        <Icon
          className={classes.icon}
          src={ICONS.keyboardArrowRight}
          onClick={handleOnNextPage}
        />
      </Row>
    </Row>
  )
}
