import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  tableColumn: {
    flex: 1.8,
    minHeight: 64,
    padding: '8px 16px',
    overflow: 'hidden'
  },
  minTableColumn: {
    flex: 1
  },
  maxTableColumn: {
    flex: 3
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    cursor: 'pointer',
    minHeight: 64,
    '&:hover': {
      background: theme.colors.lightBlue
    }
  },
  textHiden: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  text: {
    marginLeft: 6
  },
  imageContainer: {
    width: 48
  },
  imageProduct: {
    borderRadius: 0
  },
  name: {
    marginLeft: 16
  }
}))
