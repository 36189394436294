import React, { FC, useRef, useState } from 'react'

import { useOutsideAlerter } from '../../hooks'
import { concatClassNames } from '../../pages/views'

import { Column } from '../column'
import { Icon, ICONS } from '../icon'
import { Text } from '../text'

import { useStyle } from './info-tooltip.styles'
import { InfoTooltipProps } from './info-tooltip.types'

export const InfoTooltip: FC<InfoTooltipProps> = ({
  text,
  className,
  isBottom,
  iconClassName
}) => {
  const classes = useStyle({ isBottom })

  const [openTooltip, changeOpenToolTip] = useState(false)

  const icon = isBottom ? ICONS.hintArrow : ICONS.hintArrowLeft
  const iconsClassName = isBottom ? `${classes.topIcon}` : `${classes.leftIcon}`

  const handleOnClickIcon = (state: boolean) => () => {
    changeOpenToolTip(state)
  }
  const ref = useRef(null)
  useOutsideAlerter(ref, openTooltip, handleOnClickIcon(false))

  return (
    <>
      <Icon
        className={iconClassName}
        src={ICONS.infoFlooded}
        onClick={handleOnClickIcon(true)}
      />

      {openTooltip && (
        <Column
          ref={ref}
          justifyContent="flex-start"
          className={concatClassNames(className, classes.tooltipContainer)}
        >
          <Icon className={iconsClassName} src={icon} />
          <Text
            className={classes.text}
            color="black"
            preset="title"
            text={text}
          />
        </Column>
      )}
    </>
  )
}
