import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import { Content } from '../content'
import { Icon } from '../icon'
import favorite from '../icon/favorite_border.svg'
import person from '../icon/person.svg'
import search from '../icon/search.svg'
import work from '../icon/work_outline.svg'
import { Row } from '../row'
import { Text } from '../text'
import { LightTheme } from '../Theme'
import { HeaderNavMenu } from './header-nav-menu'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '12px 24px',
    width: 'calc(100% - 48px)'
  },
  icon: {
    marginLeft: 16
  },
  user: {
    marginLeft: 40
  }
}))

export const HeaderBottomBar = () => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  return (
    <Row className={classes.container}>
      <Content alignItems="space-between">
        <Row fullWidth justifyContent="space-between">
          <HeaderNavMenu />
          <Text color="black" preset="h4" text="OVAFLOPICK" />
          <Row>
            <Row>
              <Icon className={classes.icon} src={search} />
              <Icon className={classes.icon} src={favorite} />
              <Icon className={classes.icon} src={work} />
            </Row>
            <Row className={classes.user}>
              <Icon src={person} />
              <Text color="black" preset="h6" text="MYOVAFLOPICK" />
            </Row>
          </Row>
        </Row>
      </Content>
    </Row>
  )
}
