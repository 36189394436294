import React, { FC, MouseEvent } from 'react'
import { useTheme } from 'react-jss'

import {
  Checkbox,
  ColorDot,
  Dot,
  Row,
  Text,
  LightTheme,
  getCurrencySymbol,
  getPriceToFixed
} from '../../../../components'

import { Currency } from '../../../../services'

import { ColorTableItemProps } from './color-table-item.types'
import { useStyle } from './color-table-item.styles'

export const ColorTableItem: FC<ColorTableItemProps> = ({
  checked,
  title,
  description,
  extraPrice,
  extraProductionDays,
  code,
  isDefault,
  onChange,
  onChangeDefault
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const handleOnChange = (
    state: boolean,
    event: MouseEvent<HTMLElement, globalThis.MouseEvent>
  ) => {
    event.preventDefault()
    event.stopPropagation()

    if (onChange) {
      onChange(state, event)
    }
  }

  const extraPriceFormated = `${getCurrencySymbol(
    Currency.USD
  )}${getPriceToFixed(extraPrice)}`
  const extraProductionDaysFormated = String(extraProductionDays)

  return (
    <Row fullWidth className={classes.tableRow} justifyContent="space-between">
      <Row className={classes.tableColumn} justifyContent="flex-start">
        <Checkbox checked={checked} onChange={handleOnChange} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {title && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={title}
          />
        )}
      </Row>
      <Row className={classes.bigTableColumn} justifyContent="flex-start">
        {description && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={description}
          />
        )}
      </Row>
      <Row className={classes.tableColumnColor} justifyContent="flex-start">
        <ColorDot preset="productColor" color={code} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {extraPrice && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={extraPriceFormated}
          />
        )}
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {extraProductionDays && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={extraProductionDaysFormated}
          />
        )}
      </Row>
      <Row
        className={classes.tableColumn}
        justifyContent="flex-end"
        onClick={onChangeDefault}
      >
        <Dot active={isDefault} onClick={onChangeDefault} />
      </Row>
    </Row>
  )
}
