import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  bottomRow: {
    padding: 16,
    width: 'calc(100% - 32px)'
  },
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`
  },
  dropdownRow: {
    marginLeft: 8
  },
  header: {
    background: theme.colors.greyFon,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  icon: {
    marginLeft: 8
  },
  search: {
    width: '100%'
  },
  tableTopContainer: {
    padding: 16
  },
  tableColumn: {
    flex: 1.8,
    height: '100%',
    padding: '8px 16px'
  },
  minTableColumn: {
    flex: 1
  },
  maxTableColumn: {
    flex: 3
  },
  dropdownSelectedText: {
    padding: 3
  },
  dropdownSelectRow: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '100%',
    left: 0,
    backgroundColor: `${theme.colors.white}`
  },
  dropdownItem: {
    width: '100%',
    padding: 3,
    '&:hover': {
      backgroundColor: `${theme.colors.hover}`
    }
  },
  dropdownItemText: {
    cursor: 'pointer'
  },
  arowIcon: {
    marginLeft: 12
  }
}))
