import React, { FC, useMemo, useState } from 'react'

import {
  CalendarSlider,
  Column,
  FilterRadioList,
  FiltersSidebar,
  getGraphDate,
  Icon,
  ICONS,
  RadioItemWithIdProps
} from '../../../components'
import { BannerFilter, BannerFilterKeys } from '../../../services'

import { useStyle } from './promotion-banners-filters.styles'
import { PromotionBannersFiltersProps } from './promotion-banners-filters.types'

export const PromotionBannersFilters: FC<PromotionBannersFiltersProps> = ({
  className = '',
  onSubmit,
  onReset
}) => {
  const [openSlider, changeOpenSlider] = useState<
    Partial<Record<BannerFilterKeys, boolean | undefined>>
  >({})

  const [filter, changeFilter] = useState<Partial<BannerFilter>>({})
  const classes = useStyle()
  const [open, changeOpen] = useState(false)

  const isDisabled = () => {
    const disabled =
      filter.startDate || filter.endDate || filter.isVisible?.valueOf
    if (disabled) {
      return false
    }

    return true
  }

  const StartDateValue = useMemo(() => {
    if (filter.startDate) {
      const { gte, lte } = filter.startDate

      if (gte && lte) {
        return [new Date(gte), new Date(lte)]
      }

      if (gte) {
        return [new Date(gte)]
      }
    }

    return undefined
  }, [filter.startDate])

  const EndDateValue = useMemo(() => {
    if (filter.endDate) {
      const { gte, lte } = filter.endDate

      if (gte && lte) {
        return [new Date(gte), new Date(lte)]
      }

      if (gte) {
        return [new Date(gte)]
      }
    }

    return undefined
  }, [filter.endDate])

  const Statuses = useMemo<RadioItemWithIdProps[]>(
    () => [
      { id: '0', name: 'Inactive', nameTx: 'cabinet.banners.filters.inactive' },
      {
        id: '1',
        name: 'Active',
        nameTx: 'cabinet.banners.filters.active'
      }
    ],
    []
  )

  const handleOnChangeStatus = (active?: string) => {
    const nextStatus = Boolean(Number(active))
    changeFilter({ ...filter, isVisible: nextStatus })
  }

  const handleOnChangeDate =
    (prop: BannerFilterKeys) => (from: string, to?: string) => {
      if (from) {
        changeFilter({
          ...filter,
          [prop]: {
            gte: getGraphDate(from),
            lte: to ? getGraphDate(to) : undefined
          }
        })
      }
    }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    if (onSubmit) {
      onSubmit(filter)
    }
    close()
  }

  const handleOnOpenSlider = (prop: BannerFilterKeys) => (state: boolean) => {
    changeOpenSlider({ ...openSlider, [prop]: state })
  }

  const handleOnReset = () => {
    const close = handleOnOpen(false)

    changeFilter({})
    changeOpenSlider({})
    if (onReset) {
      onReset()
    }
    close()
  }

  return (
    <Column
      className={`${className} ${classes.container}`}
      onClick={handleOnOpen(true)}
    >
      <Icon src={ICONS.filterList} height={32} width={32} />
      <FiltersSidebar
        open={open}
        onClose={handleOnOpen(false)}
        onApply={handleOnSubmit}
        onReset={handleOnReset}
        isDisabled={isDisabled()}
      >
        {open && (
          <Column fullWidth>
            <CalendarSlider
              className={classes.slider}
              defaultOpen={openSlider.startDate}
              title="Banner Start Date"
              titleTx="cabinet.banners.filters.startDate"
              value={StartDateValue}
              onOpen={handleOnOpenSlider('startDate')}
              onChange={handleOnChangeDate('startDate')}
            />
            <CalendarSlider
              className={classes.slider}
              defaultOpen={openSlider.endDate}
              title="Banner End Date"
              titleTx="cabinet.banners.filters.endDate"
              value={EndDateValue}
              onOpen={handleOnOpenSlider('endDate')}
              onChange={handleOnChangeDate('endDate')}
            />
            <FilterRadioList
              className={classes.slider}
              active={String(Number(filter.isVisible))}
              defaultOpen={openSlider.isVisible}
              data={Statuses}
              title="Status"
              titleTx="cabinet.banners.filters.status"
              onOpen={handleOnOpenSlider('isVisible')}
              onChange={handleOnChangeStatus}
            />
          </Column>
        )}
      </FiltersSidebar>
    </Column>
  )
}
