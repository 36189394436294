import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'

import {
  Attention,
  Button,
  Column,
  NavLink,
  RadioItem,
  Row,
  Text,
  TextField,
  ICONS,
  Checkbox,
  TextFieldErrorProps
} from '../../../components'
import { RegisterCredentials } from '../../../services'
import { Sex } from '../../../services/auth/auth.types'
import { useUser } from '../../../hooks/useUser'
import { LightTheme } from '../../../components/Theme'
import { AuthRoutes } from '../../urls'

import { useStyle } from './register-page.styles'

import background from '../../../assets/images/background-pattern.png'
import { useApi } from '../../../providers'
import {
  validateEmail,
  validateFirstName,
  validatePassword
} from '../../../pages'

const defaultUserState: RegisterCredentials = {
  email: '',
  password: '',
  sex: Sex.MALE,
  firstName: '',
  lastName: '',
  username: '',
  companyName: '',
  country: '',
  state: '',
  city: '',
  address: '',
  zipcode: '',
  phone: '',
  contactsPersonName: '',
  contactsPersonPosition: '',
  paypalEmail: '',
  role: 1
}

export const RegisterPage = () => {
  const { auth: AuthApi } = useApi()
  const { response, onSubmit } = AuthApi.useIsUserExist()
  const history = useHistory()
  const userContext = useUser()
  const [user, changeUser] = useState<RegisterCredentials>(defaultUserState)
  const [sex, changeSex] = useState<Sex>()
  const [checked, setChacked] = useState(false)

  const userValuesArr = Object.values(user)
  const data = userValuesArr.filter((value) => value !== '')

  const ValidateName = () => {
    if (user.firstName) {
      const isValideName = validateFirstName(user.firstName)

      return isValideName
    }
    return false
  }

  const ValidateLastName = () => {
    if (user.lastName) {
      const isValideName = validateFirstName(user.lastName)

      return isValideName
    }
    return false
  }

  const ValidateUserName = () => {
    if (user.username) {
      const isValideName = validateFirstName(user.username)

      return isValideName
    }
    return false
  }

  const ValidateEmail = () => {
    if (user.email) {
      const isValideEmail = validateEmail(user.email)

      return isValideEmail
    }
    return false
  }

  const ValidatePassword = () => {
    if (user.password) {
      const isValidePassword = validatePassword(user?.password)

      return isValidePassword
    }
    return false
  }

  const isDisabled =
    data.length < 7 ||
    !checked ||
    !user.firstName ||
    !ValidateName() ||
    !user.lastName ||
    !ValidateLastName() ||
    !user.username ||
    !ValidateUserName() ||
    !user.email ||
    !ValidateEmail() ||
    !user.password ||
    !ValidatePassword()

  const nameError: TextFieldErrorProps =
    !ValidateName() && user.firstName
      ? {
          error: 'Please enter a valid name',
          errorTx: 'error.validation.email.register.page'
        }
      : {}
  const lastNameError: TextFieldErrorProps =
    !ValidateLastName() && user.lastName
      ? {
          error: 'Please enter a valid name',
          errorTx: 'error.validation.email.register.page'
        }
      : {}
  const userNameError: TextFieldErrorProps =
    !ValidateUserName() && user.username
      ? {
          error: 'Please enter a valid name',
          errorTx: 'error.validation.email.register.page'
        }
      : {}

  const emailError: TextFieldErrorProps =
    !ValidateEmail() && user.email
      ? {
          error: 'Please enter a valid e-mail address.',
          errorTx: 'error.validation.email.register.page'
        }
      : {}

  const passwordError: TextFieldErrorProps =
    !ValidatePassword() && user.password
      ? {
          error:
            'Password must contain from 5 to 30 characters, upper and lower case letters.',
          errorTx: 'error.validation.password.register.page'
        }
      : {}

  const handleOnCheck = () => setChacked(!checked)

  const IsUserExists = useMemo(() => {
    if (response.data) {
      return response.data.isUserExists.isUserExists
    }

    return undefined
  }, [response.data])

  useEffect(() => {
    if (response.data && !response.data.isUserExists.isUserExists) {
      if (userContext.register) {
        history.push(AuthRoutes.USER_INFO_UPDATE, { user })
      }
    }
  }, [response])

  const theme: LightTheme = useTheme()
  const classes = useStyle({ background, error: IsUserExists, theme })

  const handleOnChange =
    (prop: keyof RegisterCredentials) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      changeUser({
        ...user,
        [prop]: value
      })
    }

  const handleOnChangeSex = (value: Sex) => () => {
    changeSex(value)
    changeUser({
      ...user,
      sex: value
    })
  }

  const handleOnClick = () => {
    onSubmit({ email: user.email })
  }

  return (
    <Column fullWidth className={classes.container}>
      <Column className={classes.loginForm}>
        <Text preset="h1" text="Sign Up" tx="register.title" />
        <Text
          color="inactive"
          preset="h7"
          text="Please sign up to continue."
          tx="register.description"
        />

        <TextField
          {...emailError}
          className={classes.input}
          label="E-mail"
          maxLength={100}
          labelTx="register.email"
          preset="border"
          onChange={handleOnChange('email')}
          value={user.email}
        />
        <TextField
          isSecure
          {...passwordError}
          className={classes.input}
          labelTx="register.password"
          label="Password"
          maxLength={30}
          preset="border"
          onChange={handleOnChange('password')}
          value={user.password}
        />
        <TextField
          {...nameError}
          className={classes.input}
          label="First Name"
          labelTx="register.firstName"
          preset="border"
          maxLength={50}
          onChange={handleOnChange('firstName')}
        />
        <TextField
          {...lastNameError}
          className={classes.input}
          label="Last Name"
          labelTx="register.lastName"
          preset="border"
          maxLength={50}
          onChange={handleOnChange('lastName')}
        />
        <Row className={classes.radioContainer}>
          <RadioItem
            active={Sex.FEMALE === sex}
            src={ICONS.female}
            name="Female"
            nameTx="register.female"
            onClick={handleOnChangeSex(Sex.FEMALE)}
          />
          <RadioItem
            className={classes.radio}
            active={Sex.MALE === user.sex}
            src={ICONS.male}
            name="Male"
            nameTx="register.male"
            onClick={handleOnChangeSex(Sex.MALE)}
          />
        </Row>
        <TextField
          {...userNameError}
          className={classes.input}
          label="Username"
          labelTx="register.username"
          preset="border"
          maxLength={50}
          onChange={handleOnChange('username')}
        />

        {IsUserExists && (
          <Attention
            className={classes.attention}
            color="error"
            icon={ICONS.attention}
            text="An account with that email exists already! Try again."
          />
        )}
        <Row
          fullWidth
          className={classes.signInContainer}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Checkbox checked={checked} onChange={handleOnCheck} />
          <Text
            className={classes.signIn}
            color="darkBlue"
            preset="secondTitle"
            text="I have read and agreed to your"
            tx="register.text.vendor"
          />
          <NavLink
            className={classes.signIn}
            color="blue"
            preset="h6"
            name="Privacy Policy"
            to="/privacy-policy"
            tx="register.privacy.policy"
          />
          <Text
            className={classes.signIn}
            color="darkBlue"
            preset="secondTitle"
            text="and"
            tx="register.secondary.text"
          />
          <NavLink
            className={classes.signIn}
            color="blue"
            preset="h6"
            name="Terms of conditions"
            to="/Terms"
            tx="register.terms.of.conditions"
          />
        </Row>
        <Button
          className={classes.submit}
          disabled={isDisabled}
          text="SIGN UP"
          tx="register.signUp"
          preset="primary"
          textColor="white"
          textPreset="h5"
          onClick={handleOnClick}
        />
        <Row className={classes.signInContainer}>
          <Text
            color="black"
            preset="secondTitle"
            text="You already have an account?"
            tx="register.haveAccount"
          />
          <NavLink
            className={classes.signIn}
            color="blue"
            preset="h6"
            name="Sign In"
            tx="register.signIn"
            to="/"
          />
        </Row>
      </Column>
    </Column>
  )
}
