import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../components'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    padding: '24px 0'
  },
  back: {
    marginLeft: 24
  },
  content: {
    marginTop: 24,
    background: theme.colors.white
  },

  offerView: {
    alignSelf: 'stretch',
    width: 476,
    border: `1px solid ${theme.colors.shadow}`
  },
  chatboxContainer: {
    border: `1px solid ${theme.colors.shadow}`,
    flex: 1,
    alignSelf: 'stretch'
  },
  chatbox: {
    flex: 1,
    alignSelf: 'stretch'
  }
}))
