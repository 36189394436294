import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'
import { TextPropsWithColor } from './text.types'

const colorByProps =
  (theme: LightTheme, defaultColor?: string) => (props: TextPropsWithColor) =>
    theme.colors[props.color] || defaultColor || theme.colors.black

export const useStyle = createUseStyles((theme: LightTheme) => ({
  body: {
    ...theme.typography.body,
    color: colorByProps(theme),
    cursor: 'default'
  },
  button: {
    ...theme.typography.button,
    color: colorByProps(theme, theme.colors.white)
  },
  cardText: {
    ...theme.typography.cardText,
    color: colorByProps(theme, theme.colors.white)
  },
  h1: {
    ...theme.typography.h1,
    color: colorByProps(theme)
  },
  h2: {
    ...theme.typography.h2,
    color: colorByProps(theme)
  },
  h3: {
    ...theme.typography.h3,
    color: colorByProps(theme)
  },
  h4: {
    ...theme.typography.h4,
    color: colorByProps(theme)
  },
  h5: {
    ...theme.typography.h5,
    color: colorByProps(theme)
  },
  h6: {
    ...theme.typography.h6,
    color: colorByProps(theme)
  },
  h7: {
    ...theme.typography.h7,
    color: colorByProps(theme)
  },
  mainBanner: {
    ...theme.typography.mainBanner,
    color: colorByProps(theme)
  },
  maxButton: {
    ...theme.typography.maxButton,
    color: colorByProps(theme)
  },
  rightButton: {
    ...theme.typography.rightButton,
    color: colorByProps(theme)
  },
  secondBanner: {
    ...theme.typography.secondBanner,
    color: colorByProps(theme)
  },
  secondTitle: {
    ...theme.typography.secondTitle,
    color: colorByProps(theme)
  },
  subscriptionText: {
    ...theme.typography.subscriptionText,
    color: colorByProps(theme)
  },
  title: {
    ...theme.typography.title,
    color: colorByProps(theme)
  }
}))
