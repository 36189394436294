import React, { FC } from 'react'

import { ObserveOfferButton } from '../../pages/views'

import { getCurrencySymbol, getPriceToFixed } from '../helpers'
import { Column } from '../column'
import { Text } from '../text'
import { Row } from '../row'
import { Tooltip } from '../tooltip'

import { OfferLineVariantProps } from './offer-line-variant.types'
import { useStyle } from './offer-line-variant.styles'

export const OfferLineVariant: FC<OfferLineVariantProps> = ({
  className = '',
  product,
  offerItem,
  offer,
  count,
  isTooltip
}) => {
  const classes = useStyle()

  if (!product) {
    return <></>
  }

  const { pricing } = product
  const { currency } = pricing.priceRange.start.gross
  const symbolCurrency = getCurrencySymbol(currency)

  const totalPriceWithSymbol =
    symbolCurrency + getPriceToFixed(offerItem.totalItemPrice)

  return (
    <Column fullWidth className={className}>
      <Row
        fullWidth
        className={classes.line}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Row alignItems="center">
          <Row className={classes.itemNumber}>
            <Text preset="h2" text={count} />
          </Row>
          <Row
            className={classes.title}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Text text="Item" tx="offer.item.variant" preset="h5" />
            {offer && offerItem && (
              <ObserveOfferButton
                className={classes.observeOptionsBtn}
                itemQuantity={offerItem.quantity}
                description={offer.description}
                product={product}
                options={offerItem.options}
                discount={offer.discount}
                noSuggestion={offer.noSuggestion}
                productTotalPrice={offer.productTotalPrice}
                totalItemPrice={offerItem.totalItemPrice}
                offerOptions={offerItem.productOptions}
                offerColors={offerItem.productColors}
                offerMaterials={offerItem.productMaterials}
                unitIds={offerItem.unitIds}
              />
            )}
          </Row>
        </Row>
        <Row
          fullWidth
          justifyContent="space-between"
          alignItems="flex-start"
          className={classes.infoRow}
        >
          <Column alignItems="flex-start" className={classes.column} />
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Item Quantity"
              tx="offer.line.units.quantity"
            />
            <Row>
              <Text
                className={classes.delivery}
                color="black"
                preset="h7"
                text={String(offerItem.quantity)}
              />
              <Row className={classes.tooltip}>
                {isTooltip && <Tooltip unitIds={offerItem.unitIds} />}
              </Row>
            </Row>
          </Column>
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Item Price"
              tx="offer.line.units.price"
            />
            <Text
              className={classes.delivery}
              color="black"
              preset="h7"
              text={totalPriceWithSymbol}
            />
          </Column>
          <Column alignItems="flex-start" className={classes.column} />
        </Row>
      </Row>
    </Column>
  )
}
