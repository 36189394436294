import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    padding: '24px 16px'
  }
}))
