import { IAppState } from '.'
import { AppStateReducerAction } from './reducer.types'
import { AppError } from './state.types'

export function displayError(prevState: IAppState, error: AppError): IAppState {
  return {
    ...prevState,
    error,
    loading: false
  }
}

export function displayLoader(prevState: IAppState, value: boolean): IAppState {
  return {
    ...prevState,
    loading: value
  }
}

export function reduceAppState(
  prevState: IAppState,
  action: AppStateReducerAction
): IAppState {
  if (!action.payload.value || !action.payload.error) {
    return prevState
  }

  switch (action.type) {
    case 'displayError':
      return displayError(prevState, action.payload.error)
    case 'displayLoader':
      return displayLoader(prevState, action.payload.value)
    default:
      return prevState
  }
}

export default reduceAppState
