import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  image: {
    objectFit: 'cover',
    marginLeft: 16
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px'
  },
  tableRow: {
    borderBottom: `1px solid ${theme.colors.shadow}`,
    cursor: 'pointer',
    '&:hover': {
      background: theme.colors.lightBlue
    }
  },
  statusText: {
    marginLeft: 8
  },
  tableColumnFirst: {
    height: '100%',
    padding: '8px 16px',
    width: 56
  },
  tableColumnMin: {
    flex: 0.5
  },
  tableColumnMax: {
    flex: 1.5,
    paddingLeft: 0
  }
}))
