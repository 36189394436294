export const getQueryParams = (params: string) => {
  const objectParams = params
    .replace('?', '')
    .split('&')
    .reduce((acc, param) => {
      const paramByEntry = param.split('=')

      return {
        ...acc,
        [paramByEntry[0]]: paramByEntry[1]
      }
    }, {})

  return objectParams
}
