import React, { FC, Suspense } from 'react'
import { Loader } from '../../../components'

import { BannerKind } from '../../../services'
import {
  PremiumBannerView,
  PromotedBannerView,
  PromotedStoreBannerView
} from '../../views'

import { ViewByBannerTypeProps } from './view-by-banner-type.types'

export const ViewByBannerType: FC<ViewByBannerTypeProps> = ({
  children,
  banner,
  kind,
  mobileImages,
  onChange,
  onChangeMobileImage
}) => {
  const fallback = <Loader />

  switch (kind) {
    case BannerKind.PROMOTION_BANNER:
      return (
        <Suspense fallback={fallback}>
          <PromotedBannerView
            banner={banner}
            mobileImages={mobileImages}
            onChange={onChange}
            onChangeMobileImage={onChangeMobileImage}
          >
            {children}
          </PromotedBannerView>
        </Suspense>
      )
    case BannerKind.PREMIUM_USER_BANNER:
      return (
        <Suspense fallback={fallback}>
          <PremiumBannerView
            banner={banner}
            mobileImages={mobileImages}
            onChange={onChange}
            onChangeMobileImage={onChangeMobileImage}
          >
            {children}
          </PremiumBannerView>
        </Suspense>
      )
    case BannerKind.TOP_PRODUCT_BANNER:
      return <>{children}</>
    case BannerKind.PROMOTED_STORE_BANNER:
      return (
        <Suspense fallback={fallback}>
          <PromotedStoreBannerView
            banner={banner}
            mobileImages={mobileImages}
            onChange={onChange}
            onChangeMobileImage={onChangeMobileImage}
          >
            {children}
          </PromotedStoreBannerView>
        </Suspense>
      )
    default:
      return (
        <Suspense fallback={fallback}>
          <PromotedBannerView banner={banner} onChange={onChange}>
            {children}
          </PromotedBannerView>
        </Suspense>
      )
  }
}
