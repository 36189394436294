import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTheme } from 'react-jss'

import {
  OrderMainStatus,
  OrderUpdateVariables,
  PermissionEnum
} from '../../../services'
import { hasPermission } from '../../../auth/misc'
import { useAuth } from '../../../hooks'
import { useApi } from '../../../providers'

import { ConfirmModal } from '../../confirm-modal'
import { LightTheme } from '../../Theme'
import { Row } from '../../row'
import { Text } from '../../text'
import { QuestionConfirmModal } from '../../question-confirm-modal'
import { HintRow } from '../../hint-row'
import { getInstruction, getListFromList } from '../../helpers'

import { OrderShippingModal } from '../order-shipping'

import { mainStatusToIndex } from './helpers'
import { useStyle } from './order-staging.styles'
import { OrderStagingProps } from './order-staging.types'
import { OrderStagingItem } from './order-staging-item'

export const OrderStaging: FC<OrderStagingProps> = ({
  id,
  className = '',
  mainStatus,
  remainingTimeProductionStarted,
  onChange
}) => {
  const { instruction: instructionApi } = useApi()
  const { user } = useAuth()
  const defaultIndex = mainStatusToIndex(mainStatus)

  const { data: instructions } = instructionApi.useInstructions({ first: 100 })

  const [openConfirmModal, changeOpenConfirmModal] = useState(false)
  const [openProductionStartModal, changeOpenProductionStartModal] =
    useState(false)
  const [openProductionFinishedModal, changeOpenProductionFinishedModal] =
    useState(false)
  const [openProductShippedModal, changeOpenProductShippedModal] =
    useState(false)
  const [
    openProductionFinishedConfirmModal,
    changeOpenProductionFinishedConfirmModal
  ] = useState(false)
  const [currentIndex, changeCurrentIndex] = useState(defaultIndex)
  const [progressPosition, changeProgressPosition] = useState({
    left: 0,
    right: 0
  })

  const instructionsList = getListFromList(instructions?.instructions)

  const orderControlProductionStartedInfo = getInstruction(
    instructionsList,
    'orderControlProductionStartedInfo'
  )
  const orderControlProductFinishedInfo = getInstruction(
    instructionsList,
    'orderControlProductFinishedInfo'
  )

  const firstRef = useRef<HTMLDivElement>(null)
  const lastRef = useRef<HTMLDivElement>(null)

  const getIsActive = (index: number) => currentIndex >= index
  const getIsDisabled = (active: number) =>
    defaultIndex < 0 || active <= defaultIndex

  useEffect(() => {
    if (firstRef.current !== null && lastRef.current !== null) {
      const left = firstRef.current.offsetWidth / 2
      const right = lastRef.current.offsetWidth / 2

      changeProgressPosition({ left, right })
    } else {
      changeProgressPosition({ left: 0, right: 0 })
    }
  }, [firstRef, lastRef])

  const theme: LightTheme = useTheme()
  const classes = useStyle({
    theme,
    active: currentIndex,
    left: progressPosition.left,
    right: progressPosition.right,
    length: 6
  })

  const handleOnChange = (
    status: OrderMainStatus,
    variables: OrderUpdateVariables
  ) => {
    const index = mainStatusToIndex(status)
    if (index > currentIndex && status !== OrderMainStatus.PARCEL_DELIVERED) {
      changeCurrentIndex(index)
      if (onChange) {
        onChange(variables)
      }
    }
  }

  const handleOnOpenProductionFinishedConfirmModal = () => {
    changeOpenProductionFinishedConfirmModal(
      !openProductionFinishedConfirmModal
    )
  }

  const handleOnOpenConfirmModal =
    (
      callback: Dispatch<SetStateAction<boolean>>,
      state: boolean,
      status: OrderMainStatus
    ) =>
    () => {
      const index = mainStatusToIndex(status)
      if (state) {
        if (currentIndex < index) {
          return callback(state)
        }
      }

      return callback(state)
    }

  const handleOnChangeStatus = (
    callback: Dispatch<SetStateAction<boolean>>,
    variables: OrderUpdateVariables,
    status: OrderMainStatus
  ) => {
    const activeIndex = mainStatusToIndex(status)
    const close = handleOnOpenConfirmModal(callback, false, status)

    close()

    if (currentIndex < activeIndex && currentIndex !== -1) {
      handleOnChange(status, variables)
    }
  }

  const handleOnSuccessConfirm = () => {
    const status = OrderMainStatus.CONFIRMED
    const variables: OrderUpdateVariables = {
      id,
      input: {
        isConfirmed: true
      }
    }

    handleOnChangeStatus(changeOpenConfirmModal, variables, status)
  }

  const handleOnSuccessProductionStart = () => {
    const status = OrderMainStatus.PRODUCTION_STARTED
    const variables: OrderUpdateVariables = {
      id,
      input: {
        isProductionStarted: true
      }
    }

    handleOnChangeStatus(changeOpenProductionStartModal, variables, status)
  }

  const handleOnSuccessProductionFinish = () => {
    const status = OrderMainStatus.PRODUCTION_FINISHED
    const variables: OrderUpdateVariables = {
      id,
      input: {
        isProductionFinished: true
      }
    }

    handleOnChangeStatus(changeOpenProductionFinishedModal, variables, status)
    changeOpenProductionFinishedConfirmModal(true)
  }

  const handleOnSuccessShipped = (trackingNumber: string) => {
    const status = OrderMainStatus.PRODUCT_SHIPPED
    const variables: OrderUpdateVariables = {
      id,
      input: {
        isProductionShipped: true,
        trackingNumber
      }
    }

    handleOnChangeStatus(changeOpenProductShippedModal, variables, status)
  }

  const IsPermissionDisabled = (disabled: boolean) => {
    if (!disabled) {
      if (user) {
        const hasPermissions = hasPermission(
          PermissionEnum.ADD_CHANGE_REMOVE_OWN_ORDERS,
          user
        )

        return hasPermissions
      }
    }

    return disabled
  }

  return (
    <Row
      fullWidth
      justifyContent="space-between"
      className={`${className} ${classes.container}`}
    >
      <div className={classes.progress} />
      {getIsActive(1) && (
        <Text
          className={classes.time}
          color="blue"
          preset="maxButton"
          text={remainingTimeProductionStarted}
        />
      )}
      <OrderStagingItem
        disabled
        active={getIsActive(0)}
        ref={firstRef}
        title="Order paid"
        titleTx="order.item.staging.paid"
      />
      <OrderStagingItem
        active={getIsActive(1)}
        disabled={getIsDisabled(1)}
        title="Order confirmed"
        titleTx="order.item.staging.confirmed"
        onClick={handleOnOpenConfirmModal(
          changeOpenConfirmModal,
          true,
          OrderMainStatus.CONFIRMED
        )}
      />
      <HintRow
        className={classes.hint}
        textClassName={classes.hintText}
        text={orderControlProductionStartedInfo}
        topIcon={40}
        top={48}
      >
        <OrderStagingItem
          active={getIsActive(2)}
          disabled={getIsDisabled(2)}
          title="Production started"
          titleTx="order.item.staging.started"
          onClick={handleOnOpenConfirmModal(
            changeOpenProductionStartModal,
            true,
            OrderMainStatus.PRODUCTION_STARTED
          )}
        />
      </HintRow>
      <HintRow
        className={classes.hint}
        textClassName={classes.hintText}
        text={orderControlProductFinishedInfo}
        topIcon={40}
        top={48}
      >
        <OrderStagingItem
          active={getIsActive(3)}
          disabled={IsPermissionDisabled(getIsDisabled(3))}
          title="Product finished"
          titleTx="order.item.staging.finished"
          onClick={handleOnOpenConfirmModal(
            changeOpenProductionFinishedModal,
            true,
            OrderMainStatus.PRODUCTION_FINISHED
          )}
        />
      </HintRow>
      <OrderStagingItem
        active={getIsActive(4)}
        disabled={IsPermissionDisabled(getIsDisabled(4))}
        title="Product shipped"
        titleTx="order.item.staging.shipped"
        onClick={handleOnOpenConfirmModal(
          changeOpenProductShippedModal,
          true,
          OrderMainStatus.PRODUCT_SHIPPED
        )}
      />
      <OrderStagingItem
        disabled
        active={getIsActive(5)}
        title="Parcel delivered"
        titleTx="order.item.staging.delivered"
        ref={lastRef}
      />
      {openConfirmModal && (
        <QuestionConfirmModal
          title="Order Confirm"
          titleTx="orderConfirm.modal.title"
          description="Are you sure you want to confirm this Order?"
          descriptionTx="orderConfirm.modal.description"
          secondaryDescription='Аfter confirmation, the next step "Product started" will be activated automatically in 48 hours.'
          secondaryDescriptionTx="orderConfirm.modal.secondaryDescription"
          onSubmit={handleOnSuccessConfirm}
          onClose={handleOnOpenConfirmModal(
            changeOpenConfirmModal,
            false,
            OrderMainStatus.CONFIRMED
          )}
        />
      )}
      {openProductionStartModal && (
        <QuestionConfirmModal
          title="Production Started"
          titleTx="orderProductionStarted.modal.title"
          description="Are you sure you want to start production for this Order?"
          descriptionTx="orderProductionStarted.modal.description"
          secondaryDescription='After confirmation, the "Start Date" will be indicated in the Open Order.'
          secondaryDescriptionTx="orderProductionStarted.modal.secondaryDescription"
          onSubmit={handleOnSuccessProductionStart}
          onClose={handleOnOpenConfirmModal(
            changeOpenProductionStartModal,
            false,
            OrderMainStatus.PRODUCTION_STARTED
          )}
        />
      )}
      {openProductionFinishedModal && (
        <QuestionConfirmModal
          title="Production Finished"
          titleTx="orderProductionFinished.modal.title"
          description="Are you sure to finished production for this order?"
          descriptionTx="orderProductionFinished.modal.description"
          secondaryDescription='After confirmation, the "Finish Date" will be indicated in the Open Order.'
          secondaryDescriptionTx="orderProductionFinished.modal.secondaryDescription"
          onSubmit={handleOnSuccessProductionFinish}
          onClose={handleOnOpenConfirmModal(
            changeOpenProductionFinishedModal,
            false,
            OrderMainStatus.PRODUCTION_FINISHED
          )}
        />
      )}
      {openProductShippedModal && (
        <OrderShippingModal
          onSubmit={handleOnSuccessShipped}
          onClose={handleOnOpenConfirmModal(
            changeOpenProductShippedModal,
            false,
            OrderMainStatus.PRODUCT_SHIPPED
          )}
        />
      )}
      {openProductionFinishedConfirmModal && (
        <ConfirmModal
          title="Confirmation"
          titleTx="confirm.modal.title"
          description="Production finished successfully!"
          descriptionTx="orderProductionFinishedConfirm.modal.description"
          secondaryDescription="Order status changed."
          secondaryDescriptionTx="orderProductionFinishedConfirm.modal.secondaryDescription"
          onClose={handleOnOpenProductionFinishedConfirmModal}
        />
      )}
    </Row>
  )
}
