import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    background: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  header: {
    background: theme.colors.greyFon,
    borderTop: `1px solid ${theme.colors.shadow}`,
    borderBottom: `1px solid ${theme.colors.shadow}`
  },
  tableColumn: {
    flex: 1,
    height: '100%',
    padding: '8px 16px',
    width: '6vw'
  },
  tableColumnColor: {
    width: '6vw',
    flex: 0.5,
    height: '100%',
    marginLeft: 16
  },
  tableColumnDescription: {
    flex: 1
  },
  bigTableColumn: {
    flex: 2.5,
    width: '12vw',
    height: '100%',
    padding: '8px 16px'
  },
  tableColumnMin: {
    flex: 'none',
    maxWidth: 56
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}))
