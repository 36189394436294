import moment, { LocaleSpecifier } from 'moment'
import { MouseEvent } from 'react'

import {
  Currency,
  Instruction,
  PaginationList,
  Product,
  ProductWithOptions
} from '../services'

export type ItemWithId = {
  id: string
}

const CURRENCY_SYMBOL = {
  USD: '$',
  EUR: '€'
}

export const getCurrencySymbol = (currency: Currency) => {
  return CURRENCY_SYMBOL[currency]
}

export const getCurrencyByProduct = (product: ProductWithOptions | Product) =>
  getCurrencySymbol(product.pricing?.priceRange.stop.gross.currency) ||
  CURRENCY_SYMBOL.USD

export type GetTotalPrice = {
  totalPrice: number
}

export type GetTotalPriceByQuantity = {
  totalPrice: number
  quantity: number
}

export type GetDefaultPrice = {
  defaultPrice: number
}

export const getPriceToFixed = (price: number | undefined) => {
  if (price) {
    return Number(price).toFixed(2)
  }

  return Number('').toFixed(2)
}

export const getTotalPriceWithCurrency = <T extends GetTotalPrice>(
  object: T | null
) => {
  if (object) {
    const { totalPrice } = object

    return `${getCurrencySymbol(Currency.USD)}${getPriceToFixed(totalPrice)}`
  }

  return ''
}

export const getTotalPriceWithCurrencyWithVerification = <
  T extends GetTotalPrice
>(
  object: T | null
) => {
  if (object) {
    const { totalPrice } = object
    const price = () => {
      if (totalPrice === null) {
        return 0
      } else {
        return getPriceToFixed(totalPrice)
      }
    }

    return `${getCurrencySymbol(Currency.USD)}${price()}`
  }

  return ''
}

export const getTotalPriceWithOutQuantity = <T extends GetTotalPriceByQuantity>(
  object: T | null
) => {
  if (object) {
    const { totalPrice } = object
    const { quantity } = object

    if (quantity === 0) {
      return `0 ${getCurrencySymbol(Currency.USD)}`
    }
    return `${getCurrencySymbol(Currency.USD)}${getPriceToFixed(
      totalPrice / quantity
    )}`
  }

  return ''
}

export const getDefaultPriceWithCurrency = <T extends GetDefaultPrice>(
  object: T | null
) => {
  if (object) {
    const { defaultPrice } = object

    return `${getCurrencySymbol(Currency.USD)}${getPriceToFixed(defaultPrice)}`
  }

  return ''
}

export const arrayHasElements = <T>(data?: T[]) => {
  if (!data) {
    return false
  }

  return data.length > 0
}

export const getListFromList = <T>(data?: PaginationList<T> | null): T[] => {
  if (data) {
    return data.edges.map((item) => item.node)
  }

  return []
}

export const getItemById = <T extends ItemWithId>(data: T[], id?: string) => {
  const itemById = data.find((item) => item.id === id)

  return itemById
}

export type GetFullNameObject = {
  firstName: string
  lastName: string
}

export const getFullName = <T extends GetFullNameObject>(object?: T) => {
  if (object) {
    const { firstName = '', lastName = '' } = object

    return `${firstName} ${lastName}`
  }

  return ''
}

export type GetCompanyNamesObject = {
  companyName?: string
}

export const getCompanyName = <T extends GetCompanyNamesObject>(object?: T) => {
  if (object) {
    const { companyName = '' } = object

    return `${companyName}`
  }
  return ''
}

export type CoreItem = {
  id: string
  name: string
}

export const getCoreItemsByAlphabet = (
  data: CoreItem[]
): CoreItemByAlphabet => {
  return data.reduce<CoreItemByAlphabet>((acc, countryItem) => {
    const firstChar = countryItem.name[0].toUpperCase()
    const currentDataByChar = acc[firstChar] || []
    return {
      ...acc,
      [firstChar]: [...currentDataByChar, countryItem]
    }
  }, {})
}

export type CoreItemByAlphabet = Record<string, CoreItem[]>

export const normalizedMonth = (
  date: Date | string,
  locale?: LocaleSpecifier
) =>
  moment(date)
    .locale(locale || 'en-US')
    .format('MMMM, YYYY')

export const ScrollToTop = () => {
  window.scrollTo(0, 0)
}

export const isError = (itemValue?: string | number, error?: boolean) => {
  return !itemValue && error
}

export const onStopEvent = (
  event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
) => {
  event.preventDefault()
  event.stopPropagation()
}

export const getInstruction = (data: Instruction[], name: string) => {
  const instruction = data.find((item) => item.name === name)

  if (instruction) {
    return instruction.text
  }

  return '-'
}

export const isTableItem = (itemValue?: string) => {
  if (itemValue) {
    return itemValue
  }

  return '-'
}
