import React, { FC, useMemo } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../../column'
import { Text } from '../../text'
import { Color, LightTheme } from '../../Theme'

import {
  getDateTimestampWithStartTime,
  getDateByDateTimestampWithStartTime
} from '../helpers'
import { CalendarPickerType } from '../calendar-picker.types'

import { CalendarNumberItemProps } from './calendar-number-item.types'
import { useStyle } from './calendar-number-item.styles'

export const CalendarNumberItem: FC<CalendarNumberItemProps> = ({
  from,
  to,
  pickerType,
  monthDate,
  className = '',
  text,
  onClick
}) => {
  const month = monthDate.getMonth()
  const year = monthDate.getFullYear()
  const fromTimeStamp = getDateByDateTimestampWithStartTime(from)
  const toTimeStamp = getDateByDateTimestampWithStartTime(to)

  const active = useMemo(() => {
    const currentDateTimestamp = getDateTimestampWithStartTime(
      year,
      month,
      Number(text)
    )

    if (to && from && pickerType === CalendarPickerType.PERIOD) {
      return (
        currentDateTimestamp === fromTimeStamp ||
        currentDateTimestamp === toTimeStamp
      )
    }

    if (from) {
      return currentDateTimestamp === fromTimeStamp
    }

    return false
  }, [fromTimeStamp, toTimeStamp, month, year, text, from, to, pickerType])

  const currentDate = getDateTimestampWithStartTime(year, month, Number(text))

  const isPeriodType = pickerType === CalendarPickerType.PERIOD

  const rightSide =
    isPeriodType &&
    fromTimeStamp &&
    toTimeStamp &&
    fromTimeStamp <= currentDate &&
    toTimeStamp > currentDate

  const leftSide =
    isPeriodType &&
    fromTimeStamp &&
    toTimeStamp &&
    toTimeStamp >= currentDate &&
    fromTimeStamp < currentDate

  const activeColorText: Color = active ? 'white' : 'black'

  const theme = useTheme<LightTheme>()
  const classes = useStyle({ active, theme })

  if (!text) {
    return (
      <Column fullWidth className={`${className} ${classes.container}`}>
        <Text className={classes.text} text={text} />
      </Column>
    )
  }

  return (
    <Column
      fullWidth
      className={`${className} ${classes.container}`}
      onClick={onClick}
    >
      {rightSide && <Column className={classes.rightSide} />}
      <Text className={classes.text} color={activeColorText} text={text} />
      {leftSide && <Column className={classes.leftSide} />}
    </Column>
  )
}
