import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  line: {
    width: 16,
    height: 2,
    background: theme.colors.shadow
  }
}))
