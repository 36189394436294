import React, { ChangeEvent, FC, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Button,
  Column,
  NavLink,
  Row,
  Text,
  TextField,
  ICONS,
  arrayHasElements
} from '../../../components'
import { GetTokenCreateRequest } from '../../../services'
import { useUser } from '../../../hooks/useUser'
import { Attention } from '../../../components/attention/attention'
import background from '../../../assets/images/background-pattern.png'

import { AuthRoutes } from '../../urls'

import { useStyle } from './login-page.styles'
import { LoginPageProps } from './login-page.types'

const defaultUserState: GetTokenCreateRequest = {
  email: '',
  password: '',
  page: ''
}

export const LoginPage: FC<LoginPageProps> = ({ isAdmin = false }) => {
  const { login, accountErrors, onClearAccountErrors } = useUser()
  const location = useLocation()

  const [user, changeUser] = useState<GetTokenCreateRequest>(defaultUserState)

  const url = `${location.pathname}`
  const lastSymbol = url.slice(-1)
  const link = lastSymbol === '/' ? `dashboard${url}` : `dashboard${url}/`

  const isAdminTitle = isAdmin ? 'Ovaflopick Admin User!' : 'Welcome back!'
  const isAdminTitleTx = isAdmin
    ? 'login.admin.welcome.title'
    : 'login.welcome.title'

  const isDisabled = !user.email || !user.password

  const classes = useStyle({ background, isAdmin })

  const handleOnChange =
    (prop: keyof GetTokenCreateRequest) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      if (arrayHasElements(accountErrors) && onClearAccountErrors) {
        onClearAccountErrors()
      }

      changeUser({
        ...user,
        [prop]: value
      })
    }

  const handleOnClick = () => {
    if (login) {
      login(user.email, user.password, link)
    }
  }

  return (
    <Column className={classes.loginForm}>
      <Text preset="h1" text={isAdminTitle} tx={isAdminTitleTx} />
      <Text
        className={classes.text}
        color="inactive"
        preset="h7"
        text="Please sign in to continue."
      />

      <TextField
        className={classes.input}
        label="E-mail"
        labelTx="login.email"
        preset="border"
        onChange={handleOnChange('email')}
      />
      <TextField
        isSecure
        className={classes.input}
        label="Password"
        labelTx="login.password"
        preset="border"
        onChange={handleOnChange('password')}
      />
      {!isAdmin && (
        <NavLink
          className={classes.forget}
          color="blue"
          preset="h6"
          name="Forgot Password?"
          to={AuthRoutes.FORGOT_PASSWORD}
        />
      )}
      {accountErrors.map((error) => (
        <Attention
          key={error.text}
          className={classes.attention}
          color="error"
          icon={ICONS.attention}
          text={error.text}
          tx={error.tx}
        />
      ))}
      <Button
        className={classes.submit}
        disabled={isDisabled}
        text="SIGN IN"
        preset="primary"
        textColor="white"
        textPreset="h5"
        onClick={handleOnClick}
      />
      {!isAdmin && (
        <Row className={classes.signUpContainer}>
          <Text
            color="black"
            preset="secondTitle"
            text="Don’t have an account?"
          />
          <NavLink
            className={classes.signUp}
            color="blue"
            preset="h6"
            name="Sign Up as a Vendor"
            to={AuthRoutes.REGISTER}
          />
        </Row>
      )}
    </Column>
  )
}
