import { createUseStyles } from 'react-jss'

import { LightTheme } from '../Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  button: {},
  close: {
    opacity: 0,
    padding: 8,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'opacity 0.25s ease-in-out'
  },
  container: {
    background: theme.colors.white,
    border: `1px solid ${theme.colors.shadow}`,
    borderRadius: 4,
    position: 'relative'
  },
  image: {
    borderRadius: 8,
    height: 216,
    margin: 8,
    objectFit: 'cover',
    transition: 'filter 0.25s ease-in-out',
    width: 216
  },
  imageContainer: {
    '&:hover > div': {
      opacity: 1
    },
    '&:hover > img': {
      filter: 'blur(5px) grayscale(1)'
    },
    borderRadius: 8
  },
  imagesContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`
  },
  input: {
    height: 0,
    visibility: 'hidden'
  },
  inputContainer: {
    padding: 16
  },
  text: {
    marginLeft: 8
  },
  error: {
    padding: '24px 16px'
  },
  tooltipContainer: {
    marginLeft: 16
  }
}))
