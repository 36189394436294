import React, { ChangeEvent, FC, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

import {
  BackBreadcrumbs,
  Column,
  LoadPhotoContainer,
  Row,
  Text,
  PermissionContent,
  PageForm,
  Loader,
  ICONS,
  Attention,
  ScrollToTop,
  getListFromList,
  getInstruction,
  Icon,
  arrayHasElements,
  Button
} from '../../components'
import {
  getItemsFromEdges,
  PermissionEnum,
  ProductCreateInput,
  ProductCreateOptionInput,
  ProductPurchaseSpecifics,
  ProductStatus
} from '../../services'
import { useApi } from '../../providers'
import { useHasPermission } from '../../hooks'

import {
  CabinetNavigatorPathVendor,
  ItemsTabNavigation
} from '../cabinet-navigator'
import { CreateOptionButton } from '../views'

import {
  getAttributeInputs,
  getDropdownItemsFromData,
  getIsDisabledByImages,
  isDisabledByValue,
  visibilityRadioData
} from './helpers'
import { VendorInput } from './vendor-input'
import { MaterialSection } from './material-section'
import { ColorSection } from './color-section'
import { OptionSection } from './option-section'
import { OrganizeInput } from './organize-input'
import { GeneralInfoSection } from './general-info-section'
import { DefaultPriceSection } from './default-price-section'
import { AttributeSection } from './attribute-section'
import { VisibilitySection } from './visibility-section'
import { ProductFeaturesSection } from './features-section'
import { DeliveryDetailsSection } from './delivery-details-section'
import { useStyle } from './item-form.styles'
import { ItemFormProps } from './item-form.types'

export const ItemForm: FC<ItemFormProps> = ({
  item = {
    visibleInListings: true,
    purchaseSpecifics: ProductPurchaseSpecifics.ONLY_WITH_OFFER
  },
  vendor,
  images: defaultImages,
  title,
  titleTx,
  onSubmit,
  onRemoveImages,
  onSubmitEditPreview,
  onSubmitCreatePreview
}) => {
  const {
    category: categoryApi,
    collection: collectionApi,
    attribute: attributeApi,
    material: materialApi,
    deliveryPrice: deliveryPriceApi,
    instruction: instructionApi
  } = useApi()
  const history = useHistory()
  const classes = useStyle()

  const { data: categories } = categoryApi.useCategories({
    first: 100,
    level: 0
  })
  const { data: categoriesLevel2 } = categoryApi.useCategories({
    first: 100,
    level: 2
  })

  const { data: instructions } = instructionApi.useInstructions({ first: 100 })

  const instructionsList = getListFromList(instructions?.instructions)

  const getInstructionByTitle = (instructionTitle: string) => {
    const instruction = getInstruction(instructionsList, instructionTitle)

    return instruction
  }

  const [value, changeValue] = useState<Partial<ProductCreateInput>>(item)
  const [images, changeImages] = useState<File[]>([])
  const [imagesRemove, changeImagesRemove] = useState<string[]>([])

  const [error, changeError] = useState(false)

  const handleOnChangeError = (state: boolean) => {
    changeError(state)
  }

  const priceFrom = value?.defaultPrice && Number(item.defaultPrice)
  const vendorChangeAccess = useHasPermission([
    PermissionEnum.ADD_CHANGE_REMOVE_ITEMS
  ])

  const vendorIds = value.vendor || ''
  const defaultCollections = { first: 100 }
  const assignedVendorCollection = {
    first: 100,
    filter: { vendors: [vendorIds] }
  }

  const vendorCollections = vendorChangeAccess
    ? assignedVendorCollection
    : defaultCollections

  const { data: collections } =
    collectionApi.useVendorCollections(vendorCollections)

  const visibleId = String(Number(value?.visibleInListings || false))

  const goBackPath = `${CabinetNavigatorPathVendor.ITEMS}${ItemsTabNavigation.PRODUCTS}`

  /* TODO: temporary hide upon customers request ovm-2042 */

  // const purchaseSpecificId = String(
  //   value.purchaseSpecifics || ProductPurchaseSpecifics.ONLY_WITH_OFFER
  // )

  const { data: productAttributes, refetch: refetchAttributes } =
    attributeApi.useAttributes({ first: 100 })

  const { data: productMaterials } = materialApi.useMaterials({ first: 100 })
  const { data: deliveryPrice } = deliveryPriceApi.useDeliveryPrice({
    first: 100
  })

  const isDisabledByVendor = useMemo(
    () => vendorChangeAccess && !value.vendor,
    [vendorChangeAccess, value.vendor]
  )

  const isDisabledByImages = useMemo(() => {
    return getIsDisabledByImages(defaultImages, imagesRemove, images)
  }, [defaultImages, imagesRemove, images.length])

  const isDisabled = useMemo(
    () => isDisabledByValue(value) || isDisabledByImages || isDisabledByVendor,
    [value, isDisabledByVendor, isDisabledByImages]
  )

  const Options = useMemo(() => {
    if (value.options) {
      return value.options.map((option) => ({
        ...option,
        uuid: uuid()
      }))
    }

    return []
  }, [JSON.stringify(value.options)])

  const Attributes = useMemo(() => {
    if (productAttributes) {
      return getItemsFromEdges(productAttributes.productAttributes)
    }

    return []
  }, [productAttributes])

  const Materials = useMemo(() => {
    if (productMaterials) {
      return getItemsFromEdges(productMaterials.materials)
    }

    return []
  }, [productMaterials])

  const MaterialsDropdown = useMemo(() => {
    return getDropdownItemsFromData(Materials)
  }, [Materials])

  const DeliveryPrice = useMemo(() => {
    if (deliveryPrice) {
      return getItemsFromEdges(deliveryPrice.deliveryPrice)
    }

    return []
  }, [deliveryPrice])

  const DeliveryPriceDropdown = useMemo(() => {
    return getDropdownItemsFromData(DeliveryPrice)
  }, [DeliveryPrice])

  const AttributeInputs = useMemo(() => {
    if (value.attributes) {
      return getAttributeInputs(value.attributes, Attributes)
    }
    return []
  }, [value.attributes, Attributes])

  const AttributesIds = useMemo(() => {
    if (value.attributes) {
      return value.attributes.map((attribute) => attribute.attributeId)
    }
  }, [value.attributes])

  const Images = useMemo(() => {
    return defaultImages.map((image) => image.url)
  }, [])

  const ImagesId = useMemo(() => {
    return defaultImages.map((image) => image.id)
  }, [])

  const removeImages = imagesRemove.reduce<string[]>((acc, id) => {
    const currentImage = defaultImages.find((image) => image.id === id)
    if (currentImage) {
      return [...acc, currentImage.id]
    }

    return acc
  }, [])

  const handleOnRemoveImages = () => {
    if (onRemoveImages) {
      onRemoveImages(removeImages)
    }
  }

  const handleOnChange =
    (prop: keyof ProductCreateInput) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value: nextValue } = event.target
      changeValue({
        ...value,
        [prop]: nextValue
      })
    }

  const handleOnChangeVendor = (vendorId: string) => {
    changeValue({
      ...value,
      vendor: vendorId
    })
  }

  const handleOnChangeImage = (nextImages: File[]) => {
    changeImages([...nextImages])
  }

  const handleOnRemoveImage = (nextImage: string) => {
    changeImagesRemove([...imagesRemove, nextImage])
  }

  const handleOnChangeValue = (data: Partial<ProductCreateInput>) => {
    changeValue(data)
  }

  const handleOnDeleteAdditionalOptions = (itemId: string) => () => {
    if (!arrayHasElements(Options)) {
      return
    }

    const nextData = Options.filter((option) => option.uuid !== itemId)

    const nextValue = {
      ...value,
      options: nextData
    }

    changeValue(nextValue)
  }

  const handleOnSubmit = () => {
    handleOnChangeError(isDisabled)

    const nextValue = value as ProductCreateInput

    if (!isDisabled) {
      onSubmit(nextValue, images)
    } else {
      ScrollToTop()
    }

    handleOnRemoveImages()
  }

  const handleOnSaveAsDraft = () => {
    handleOnChangeError(isDisabled)

    const nextValue = value as ProductCreateInput

    if (!isDisabled) {
      const draftValue = {
        ...nextValue,
        status: ProductStatus.DRAFT,
        visibleInListings: false
      }
      onSubmit(draftValue, images)
    } else {
      ScrollToTop()
    }

    handleOnRemoveImages()
  }

  const handleOnSubmitPreview = () => {
    handleOnChangeError(isDisabled)

    const nextValue = value as ProductCreateInput

    if (!isDisabled) {
      const createValue = {
        ...nextValue,
        status: ProductStatus.DRAFT,
        visibleInListings: false
      }

      if (onSubmitEditPreview) {
        onSubmitEditPreview(nextValue, images)
      }
      if (onSubmitCreatePreview) {
        onSubmitCreatePreview(createValue, images)
      }
    } else {
      ScrollToTop()
    }

    handleOnRemoveImages()
  }

  const handleOnSubmitOptionModal = (option: ProductCreateOptionInput) => {
    const options = value.options ? [...value.options, option] : [option]

    changeValue({
      ...value,
      options
    })
  }

  const leftIcon = (
    <Row>
      <Row
        fullWidth
        className={classes.leftIcon}
        onClick={handleOnSubmitPreview}
      >
        <Icon src={ICONS.removeRedEye} />
      </Row>
      <Button
        className={classes.button}
        color="darkBlue"
        preset="fixedBorderButton"
        textColor="darkBlue"
        textPreset="h5"
        text="SAVE AS DRAFT"
        tx="item.form.save.as.draft.button"
        onClick={handleOnSaveAsDraft}
      />
    </Row>
  )

  if (!value) {
    return <Loader />
  }

  return (
    <>
      <PageForm
        pannelLeftContent={leftIcon}
        onBack={history.goBack}
        onSave={handleOnSubmit}
      >
        <Column
          fullWidth
          alignItems="flex-start"
          justifyContent="flex-start"
          className={classes.container}
        >
          <BackBreadcrumbs
            path={goBackPath}
            text="All items"
            tx="item.form.back"
          />
          <Row alignItems="baseline" className={classes.title}>
            <Text preset="h3" text={title} tx={titleTx} />
          </Row>
          {error && (
            <Attention
              className={classes.error}
              icon={ICONS.attention}
              color="error"
              text="Submission failed! No data has been entered in a required field(s)."
              tx="item.form.error"
            />
          )}

          <Row fullWidth alignItems="flex-start" className={classes.content}>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.leftColumn}
            >
              <GeneralInfoSection
                name={value?.name}
                instruction={getInstructionByTitle('itemCreationGeneralInfo')}
                description={value?.description}
                error={error}
                onChangeInputValue={handleOnChange}
              />
              <LoadPhotoContainer
                multiple
                className={classes.section}
                defaultValue={Images}
                defaultIdValue={ImagesId}
                instruction={getInstructionByTitle('itemCreationImageInfo')}
                error={error}
                title="Photos"
                titleTx="item.form.photos.title"
                onChange={handleOnChangeImage}
                onRemove={handleOnRemoveImage}
              />
              <DefaultPriceSection
                priceFrom={priceFrom}
                instruction={getInstructionByTitle(
                  'itemCreationDefaultPriceInfo'
                )}
                error={error}
                onChangeInputValue={handleOnChange}
              />
              <DeliveryDetailsSection
                data={DeliveryPriceDropdown}
                value={value}
                error={error}
                onChangeValue={handleOnChangeValue}
              />
              <ProductFeaturesSection
                productionDaysUpTo={value?.productionDaysUpTo}
                brandName={value?.brandName}
                countryOfOrigin={value?.countryOfOrigin}
                materialCare={value?.materialCare}
                materialComposition={value?.materialComposition}
                onChangeInputValue={handleOnChange}
                error={error}
              />
              <AttributeSection
                data={AttributeInputs}
                attributes={Attributes}
                ids={AttributesIds}
                value={value}
                instruction={getInstructionByTitle(
                  'itemCreationAttributesInfo'
                )}
                onChangeValue={handleOnChangeValue}
                onRefetchAttributes={refetchAttributes}
              />

              <MaterialSection
                value={value}
                dropdownData={MaterialsDropdown}
                instruction={getInstructionByTitle('itemCreationMaterialInfo')}
                descriptionPlaceholder={getInstructionByTitle(
                  'materialDescription'
                )}
                error={error}
                onChangeValue={handleOnChangeValue}
              />
              <ColorSection
                value={value}
                descriptionPlaceholder={getInstructionByTitle(
                  'colorDescription'
                )}
                instruction={getInstructionByTitle('itemCreationColorInfo')}
                error={error}
                onChangeValue={handleOnChangeValue}
              />
              {Options.map((optionItem) => {
                return (
                  <OptionSection
                    key={`option_${title}_${optionItem.title}`}
                    title={optionItem.title}
                    type={optionItem.type}
                    optionVariants={optionItem.variants}
                    value={value}
                    onChangeValue={handleOnChangeValue}
                    onDeleteOption={handleOnDeleteAdditionalOptions(
                      optionItem.uuid
                    )}
                  />
                )
              })}
              <CreateOptionButton
                className={classes.optionButton}
                hintClassName={classes.hintButton}
                btnInstruction={getInstructionByTitle('createOptionBtnHover')}
                onSumbit={handleOnSubmitOptionModal}
              />
            </Column>
            <Column
              alignItems="flex-start"
              justifyContent="flex-start"
              className={classes.rightColumn}
            >
              <PermissionContent
                permissions={[
                  PermissionEnum.EDIT_ITEM,
                  PermissionEnum.ADD_CHANGE_REMOVE_ITEMS
                ]}
              >
                <VendorInput
                  vendorId={value.vendor}
                  vendor={vendor}
                  onChange={handleOnChangeVendor}
                />
              </PermissionContent>
              <OrganizeInput
                categoriesLevel2={categoriesLevel2}
                categories={categories}
                collections={collections}
                value={value}
                error={error}
                onChangeValue={handleOnChangeValue}
              />
              <VisibilitySection
                id={visibleId}
                visibilityData={visibilityRadioData}
                value={value}
                instruction={getInstructionByTitle(
                  'itemCreationVisibilityInfo'
                )}
                onChangeValue={handleOnChangeValue}
              />
              {/* TODO: temporary hide upon customers request ovm-2042 */}
              {/* <SpecificsSection
                id={purchaseSpecificId}
                specificsData={purchaseSpecificsRadioData}
                value={value}
                onChangeValue={handleOnChangeValue}
              /> */}
            </Column>
          </Row>
        </Column>
      </PageForm>
    </>
  )
}
