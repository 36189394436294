import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    '& > :not(:first-child)': {
      marginLeft: 20
    }
  },
  imageContainer: {},
  infoContainer: {},
  vendorInfo: {
    marginTop: 24,
    '& > a': {
      marginTop: 4
    },
    '& > div': {
      marginTop: 8
    }
  },
  policyRow: {
    marginTop: 40,
    '& > a:last-child': {
      marginTop: 16
    }
  },
  sliderContainer: {
    marginTop: 24
  },
  '@media screen and (max-width: 1152px)': {
    infoContainer: {
      width: 384
    }
  }
})
