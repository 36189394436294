import React, { FC } from 'react'

import { getCurrencySymbol, getPriceToFixed } from '../helpers'
import { Column } from '../column'
import { Text } from '../text'
import { Row } from '../row'

import { OrderLineOfferProps } from './order-line-offer.types'
import { useStyle } from './order-line-offer.styles'

export const OrderLineOffer: FC<OrderLineOfferProps> = ({
  className = '',
  offer,
  product,
  totalPrice,
  deliveryPrice,
  quantity
}) => {
  const classes = useStyle()

  if (!product) {
    return <></>
  }

  const { name, pricing, thumbnail2x } = product
  const { currency } = pricing.priceRange.start.gross
  const symbolCurrency = getCurrencySymbol(currency)
  const priceWithOutDelivery = totalPrice - deliveryPrice
  const totalPriceWithSymbol =
    symbolCurrency + getPriceToFixed(priceWithOutDelivery)
  const deliveryPriceWithSymbol =
    symbolCurrency + getPriceToFixed(deliveryPrice)
  const productionDays = offer ? String(offer.productionDays) : '-'

  return (
    <Column fullWidth className={className}>
      <Row
        fullWidth
        className={classes.line}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Row alignItems="flex-start">
          <img
            className={classes.productAvatar}
            height="64px"
            width="64px"
            src={thumbnail2x?.url}
          />
          <Row fullWidth alignItems="flex-start" justifyContent="space-between">
            <Column alignItems="flex-start" className={classes.title}>
              <Text
                className={classes.text}
                color="black"
                preset="h5"
                text={name}
              />
            </Column>
          </Row>
        </Row>
        <Row
          fullWidth
          justifyContent="space-between"
          alignItems="flex-start"
          className={classes.infoRow}
        >
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Production Days"
              tx="offer.line.production.days"
            />
            <Text color="black" preset="h7" text={productionDays} />
          </Column>
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Item Quantity"
              tx="offer.line.units.quantity"
            />
            <Text
              className={classes.delivery}
              color="black"
              preset="h7"
              text={String(quantity)}
            />
          </Column>
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Order Price"
              tx="offer.line.order.price"
            />
            <Text
              className={classes.delivery}
              color="black"
              preset="h7"
              text={totalPriceWithSymbol}
            />
          </Column>
          <Column alignItems="flex-start" className={classes.column}>
            <Text
              color="inactive"
              preset="body"
              text="Delivery Price"
              tx="order.line.delivery.price"
            />
            <Text color="black" preset="h7" text={deliveryPriceWithSymbol} />
          </Column>
        </Row>
      </Row>
    </Column>
  )
}
