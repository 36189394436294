import React, { FC } from 'react'

import { getPriceToFixed } from '../helpers'
import { Row } from '../row'
import { Text } from '../text'

import { useStyle } from './option-extra-badge.styles'
import { OptionExtraBadgeProps } from './option-extra-badge.types'

export const OptionExtraBadge: FC<OptionExtraBadgeProps> = ({
  days,
  price,
  currencySymbol
}) => {
  const classes = useStyle()
  const text = days
    ? `+ ${days} days`
    : `+ ${currencySymbol}${getPriceToFixed(price)}`

  return (
    <Row className={classes.extra}>
      <Text color="inactive" preset="secondTitle" text={text} />
    </Row>
  )
}
