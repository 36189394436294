import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  sectionAdditionContainer: {
    borderTop: `1px solid ${theme.colors.shadow}`,
    padding: 16,
    width: '100%'
  },
  end: {
    marginLeft: 20
  }
}))
