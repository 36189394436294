import React, { FC, useMemo, useState } from 'react'

import {
  CalendarSlider,
  CheckboxListItem,
  Column,
  FilterList,
  FilterRadioList,
  FiltersSidebar,
  getGraphDate,
  Icon,
  ICONS,
  RadioItemWithIdProps
} from '../../../components'
import { BannerFilter, BannerFilterKeys, BannerKind } from '../../../services'
import { getBannerTypeNameByKind } from '../../helpers'

import { useStyle } from './banners-filters.styles'
import { BannersFiltersProps } from './banners-filters.types'

export const BannersFilters: FC<BannersFiltersProps> = ({
  onSubmit,
  onReset
}) => {
  const [openSlider, changeOpenSlider] = useState<
    Partial<Record<BannerFilterKeys, boolean | undefined>>
  >({})

  const [filter, changeFilter] = useState<Partial<BannerFilter>>({})
  const [onlyToEndDate, changeOnlyToEndDate] = useState('')
  const classes = useStyle()
  const [open, changeOpen] = useState(false)

  const StartDateValue = useMemo(() => {
    if (filter.startDate) {
      const { gte, lte } = filter.startDate

      if (gte && lte) {
        return [new Date(gte), new Date(lte)]
      }

      if (gte) {
        return [new Date(gte)]
      }
    }

    return undefined
  }, [filter.startDate])

  const EndDateValue = useMemo(() => {
    if (filter.endDate) {
      const { gte, lte } = filter.endDate

      if (gte && lte) {
        return [new Date(gte), new Date(lte)]
      }

      if (gte) {
        return [new Date(gte)]
      }
    }

    return undefined
  }, [filter.endDate])

  const isOnlyEndDate = EndDateValue?.length === 1

  const isDisabledFilter = () => {
    const disabled =
      filter.startDate ||
      filter.endDate ||
      filter.kind?.length ||
      filter.isVisible?.valueOf
    if (disabled) {
      return false
    }
    return true
  }

  const Kindes = useMemo<CheckboxListItem[]>(() => {
    if (BannerKind) {
      return Object.values(BannerKind).map((status) => {
        const props = getBannerTypeNameByKind(status)
        return {
          name: props.text || status,
          nameTx: props.tx,
          id: status
        }
      })
    }

    return []
  }, [BannerKind])

  const Statuses = useMemo<RadioItemWithIdProps[]>(
    () => [
      { id: '0', name: 'Inactive', nameTx: 'cabinet.banners.filters.inactive' },
      {
        id: '1',
        name: 'Active',
        nameTx: 'cabinet.banners.filters.active'
      }
    ],
    []
  )

  const handleOnChangeStatus = (active?: string) => {
    const nextStatus = Boolean(Number(active))
    changeFilter({ ...filter, isVisible: nextStatus })
  }

  const handleOnChangeKind = (status?: string[]) => {
    const nextStatus = status as BannerKind[]
    changeFilter({ ...filter, kind: nextStatus })
  }

  const handleOnChangeDate =
    (prop: BannerFilterKeys) => (from: string, to?: string) => {
      if (from) {
        changeOnlyToEndDate(from)
        changeFilter({
          ...filter,
          [prop]: {
            gte: getGraphDate(from),
            lte: to ? getGraphDate(to) : undefined
          }
        })
      }
    }

  if (isOnlyEndDate) {
    changeFilter({
      ...filter,
      endDate: {
        gte: undefined,
        lte: onlyToEndDate && getGraphDate(onlyToEndDate)
      }
    })
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnSubmit = () => {
    const close = handleOnOpen(false)

    if (onSubmit) {
      onSubmit(filter)
    }
    close()
  }

  const handleOnOpenSlider = (prop: BannerFilterKeys) => (state: boolean) => {
    changeOpenSlider({ ...openSlider, [prop]: state })
  }

  const handleOnReset = () => {
    const close = handleOnOpen(false)

    changeFilter({})
    changeOpenSlider({})
    if (onReset) {
      onReset()
    }
    close()
  }

  return (
    <Column className={classes.container} onClick={handleOnOpen(true)}>
      <Icon src={ICONS.filterList} height={32} width={32} />
      <FiltersSidebar
        open={open}
        onClose={handleOnOpen(false)}
        onApply={handleOnSubmit}
        onReset={handleOnReset}
        isDisabled={isDisabledFilter()}
      >
        {open && (
          <Column fullWidth>
            <CalendarSlider
              className={classes.slider}
              defaultOpen={openSlider.startDate}
              title="Banner Start Date"
              titleTx="cabinet.banners.filters.startDate"
              value={StartDateValue}
              onOpen={handleOnOpenSlider('startDate')}
              onChange={handleOnChangeDate('startDate')}
            />
            <CalendarSlider
              className={classes.slider}
              defaultOpen={openSlider.endDate}
              title="Banner End Date"
              titleTx="cabinet.banners.filters.endDate"
              value={EndDateValue}
              onOpen={handleOnOpenSlider('endDate')}
              onChange={handleOnChangeDate('endDate')}
            />
            <FilterList
              className={classes.slider}
              defaultValue={filter.kind}
              defaultOpen={openSlider.kind}
              data={Kindes}
              name="kind"
              title="Type"
              titleTx="cabinet.banners.filters.type"
              onOpen={handleOnOpenSlider('isVisible')}
              onChange={handleOnChangeKind}
            />
            <FilterRadioList
              className={classes.slider}
              active={String(Number(filter.isVisible))}
              defaultOpen={openSlider.isVisible}
              data={Statuses}
              title="Status"
              titleTx="cabinet.banners.filters.status"
              onOpen={handleOnOpenSlider('isVisible')}
              onChange={handleOnChangeStatus}
            />
          </Column>
        )}
      </FiltersSidebar>
    </Column>
  )
}
