import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  container: {
    padding: '24px 16px'
  },
  categoryNav: {
    marginTop: 16
  },
  table: {
    marginTop: 24
  },
  titleContainer: {
    marginTop: 24
  }
})
