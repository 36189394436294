import React, { FC } from 'react'

import { CalendarPicker } from '../calendar-picker'
import { MenuSlider } from '../menu-slider'

import { CalendarSliderHeader } from './calendar-slider-header'
import { CalendarSliderProps } from './calendar-slider.types'

export const CalendarSlider: FC<CalendarSliderProps> = ({
  className = '',
  defaultOpen,
  title,
  titleTx,
  onOpen,
  ...props
}) => {
  const header = <CalendarSliderHeader text={title} tx={titleTx} />

  return (
    <MenuSlider
      className={className}
      defaultOpen={defaultOpen}
      header={header}
      onOpen={onOpen}
    >
      <CalendarPicker {...props} />
    </MenuSlider>
  )
}
