import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

import background from '../../../assets/images/background-pattern.png'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  container: {
    backgroundSize: '100vw',
    backgroundRepeat: 'space',
    backgroundImage: `url(${background})`,
    paddingBottom: 24,
    zIndex: 1
  },
  forgotForm: {
    marginTop: 96,
    width: 592
  },
  centerText: {
    textAlign: 'center'
  },
  forgotSecondaryText: {
    marginTop: 8,
    textAlign: 'center',
    maxWidth: 536
  },
  email: {},
  input: {
    marginTop: 56,
    maxWidth: 536
  },
  submit: {
    marginTop: 123,
    maxWidth: 536
  },
  logIn: {
    marginTop: 235,
    maxWidth: 536
  },
  signUp: {
    marginLeft: 4
  },
  signUpContainer: {
    marginTop: 24
  },
  error: {
    position: 'absolute',
    backgroundColor: theme.colors.errorBackground,
    maxWidth: 536,
    padding: '8px 0',
    marginTop: 80,
    borderRadius: 4
  },
  errorIcon: {
    marginLeft: 19
  },
  errorText: {
    marginLeft: 16
  }
}))
