import React, { FC } from 'react'

import { Text } from '../text'
import { QuestionModal } from '../question-modal'

import background from './background.png'

import { useStyle } from './question-confirm-modal.styles'
import { QuestionConfirmModalProps } from './question-confirm-modal.types'

export const QuestionConfirmModal: FC<QuestionConfirmModalProps> = ({
  description,
  descriptionTx,
  secondaryDescription,
  secondaryDescriptionTx,
  ...questionModalProps
}) => {
  const classes = useStyle()

  return (
    <QuestionModal {...questionModalProps}>
      <img width="100%" src={background} />
      <Text
        className={classes.description}
        color="black"
        preset="h4"
        text={description}
        tx={descriptionTx}
      />
      <Text
        className={classes.secondaryDesctiption}
        color="inactive"
        preset="body"
        text={secondaryDescription}
        tx={secondaryDescriptionTx}
      />
    </QuestionModal>
  )
}
