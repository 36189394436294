export const OPTION_VARIANT_FRAGMENT = `
  fragment OptionVariantFragment on ProductOptionVariant {
    id
    title
    description
    extraPrice
    extraProductionDays
    isDefault
  }
`

export const PRODUCT_OPTION_FRAGMENT = `
${OPTION_VARIANT_FRAGMENT}
  fragment ProductOptionFragment on ProductOption {
    id
    title
    type
    variants {
      ...OptionVariantFragment
    }
  }
`

export const OFFER_OPTION_FRAGMENT = `
${OPTION_VARIANT_FRAGMENT}
  fragment OfferOptionFragment on OfferOption {
    id
    title
    type
    variants {
      ...OptionVariantFragment
    }
  }
`
