import { createContext } from 'react'

import { BackgroundTasksContextType } from './types'

const initialState = {
  cancel: () => {},
  queue: () => {}
}

export const BackgroundTasksContext =
  createContext<BackgroundTasksContextType>(initialState)

export default BackgroundTasksContext
