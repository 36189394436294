import { gql } from '@apollo/client'

import { ATTRIBUTE_FRAGMENT } from '../fragments'

export const PRODUCT_ATTRIBUTES_QUERY = gql`
  ${ATTRIBUTE_FRAGMENT}
  query ProductAttributes(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $filter: ProductAttributeFilterInput
  ) {
    productAttributes(
      first: $first
      after: $after
      last: $last
      before: $before
      filter: $filter
    ) {
      edges {
        node {
          ...AttributeFragment
        }
        cursor
      }
      totalCount
    }
  }
`
