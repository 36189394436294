import React, { FC, useMemo, useState } from 'react'
import { useTheme } from 'react-jss'

import {
  Button,
  Column,
  Icon,
  ICONS,
  Row,
  Section,
  Text
} from '../../../components'
import { LightTheme } from '../../../components/Theme'
import { useDebounceInput } from '../../../hooks'
import { useApi } from '../../../providers'
import { getItemsFromEdges, PromotionBanner } from '../../../services'

import { useStyle } from './advertising-banner.styles'
import { AdvertisingBannerViewProps } from './advertising-banner.types'
import { BannerPickModal } from './banner-pick-modal'

const FIRST = 100

export const AdvertisingBannerView: FC<AdvertisingBannerViewProps> = ({
  children,
  bannerId: defaultBanner,
  banner: bannerValue,
  onChange,
  onCreateBanner
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const { menu: menuApi } = useApi()
  const { data, refetch, fetchMore } = menuApi.usePromotionBanners({
    first: FIRST
  })

  const Data = useMemo<PromotionBanner[]>(() => {
    if (data) {
      return getItemsFromEdges(data.promotionBanners)
    }

    return []
  }, [data?.promotionBanners])

  const TotalCount = useMemo<number>(() => {
    if (data) {
      return data.promotionBanners.totalCount
    }

    return 0
  }, [data?.promotionBanners.totalCount])

  const Banner = useMemo(() => {
    return Data.find((banner) => banner.id === defaultBanner) || bannerValue
  }, [defaultBanner, Data])

  const [banner, changeBanner] = useState<PromotionBanner | undefined>(Banner)
  const [open, changeOpen] = useState(false)

  const defaultSearchValue = {
    ...banner,
    first: FIRST,
    filter: {
      search: undefined
    }
  }

  const handleOnSearch = (search: string) => {
    if (refetch) {
      refetch({ first: FIRST, filter: { search } })
    }
  }

  const { onChange: onChangeSearch } = useDebounceInput({
    onChange: handleOnSearch
  })

  const handleOnChangeSearch = (value: string) => {
    onChangeSearch(value)
  }

  const handleOnOpen = (state: boolean) => () => {
    changeOpen(state)
  }

  const handleOnChange = (bannerNext: PromotionBanner) => {
    const close = handleOnOpen(false)

    changeBanner(bannerNext)
    close()

    if (onChange) {
      onChange(bannerNext.id)
    }
  }

  const handleOnDelete = () => {
    changeBanner(undefined)

    if (onChange) {
      onChange('')
    }
  }

  const handleOnLoadMore = () => {
    if (data && fetchMore) {
      const { edges } = data.promotionBanners
      const { cursor } = edges[edges.length - 1]

      fetchMore({ variables: { first: FIRST, after: cursor } })
    }
  }

  const handleOnRefresh = () => {
    if (refetch) {
      refetch({ first: FIRST })
    }
  }

  const handleOnCloseVendorPickModal = () => {
    const close = handleOnOpen(false)

    close()
    if (refetch) {
      refetch(defaultSearchValue)
    }
  }

  const src = banner ? banner.image : undefined
  const bannerDays = `${banner?.days}`

  const buttonsRow = (
    <Row>
      <Button
        className={classes.button}
        text="CREATE BANNER"
        tx="advertising.create.new.banner"
        textColor="yellow"
        preset="fixedBorderButtonLong"
        onClick={onCreateBanner}
      />
      <Button
        className={classes.button}
        text="ASSIGN BANNER"
        tx="advertising.assign.banner"
        textColor="yellow"
        preset="fixedBorderButtonLong"
        onClick={handleOnOpen(true)}
      />
    </Row>
  )

  return (
    <>
      <Section
        className={classes.section}
        descriptionTx="banner.text.fields.description"
        title="Choose from available"
        titleTx="advertising.section.choose.from.avalible"
        rightButton={buttonsRow}
      >
        <Column fullWidth alignItems="flex-start" justifyContent="flex-start">
          <Column fullWidth>
            <Row
              fullWidth
              className={classes.header}
              justifyContent="space-between"
            >
              <Row
                className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
                justifyContent="flex-start"
              >
                <Text
                  color="black"
                  preset="h6"
                  text="Banner Name"
                  tx="advertising.item.banner.name.title"
                />
              </Row>
              <Row
                className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
                justifyContent="flex-start"
              >
                <Text
                  color="black"
                  preset="h6"
                  text="Days"
                  tx="advertising.item.banner.days"
                />
              </Row>
            </Row>
            {banner ? (
              <Row fullWidth justifyContent="space-between">
                <Row
                  className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
                  justifyContent="flex-start"
                >
                  <img
                    className={classes.image}
                    height={48}
                    width={48}
                    src={src}
                  />
                  <Text
                    className={classes.text}
                    color="black"
                    preset="body"
                    text={banner.name}
                  />
                </Row>
                <Row
                  className={`${classes.tableColumn} ${classes.tableColumnFirst}`}
                  justifyContent="space-between"
                >
                  <Text color="black" preset="body" text={bannerDays} />
                  <Icon
                    className={classes.icon}
                    src={ICONS.close}
                    onClick={handleOnDelete}
                  />
                </Row>
              </Row>
            ) : (
              <Row fullWidth justifyContent="flex-start">
                <Text
                  className={classes.emptySectionText}
                  color="black"
                  preset="body"
                  text="No Items found"
                  tx="advertising.no.item.found"
                />
              </Row>
            )}
          </Column>
        </Column>
        {open && (
          <BannerPickModal
            defaultActive={Banner}
            data={Data}
            title="Assign Banner"
            titleTx="advertising.assign.banner.modal.title"
            totalCount={TotalCount}
            onLoadMore={handleOnLoadMore}
            onRefresh={handleOnRefresh}
            onClose={handleOnCloseVendorPickModal}
            onSubmit={handleOnChange}
            onSearch={handleOnChangeSearch}
          />
        )}
      </Section>
      {children}
    </>
  )
}
