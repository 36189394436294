import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import {
  ColorDot,
  Dot,
  Row,
  Text,
  LightTheme,
  getCurrencySymbol,
  getPriceToFixed
} from '../../../../components'

import { Currency } from '../../../../services'

import { OfferColorTableItemProps } from './offer-color-table-item.types'
import { useStyle } from './offer-color-table-item.styles'

export const OfferColorTableItem: FC<OfferColorTableItemProps> = ({
  title,
  description,
  extraPrice,
  extraProductionDays,
  code,
  isDefault,
  onChangeDefault
}) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  const isExtraPrice = extraPrice || extraPrice === 0
  const extraPriceFormated = `${getCurrencySymbol(
    Currency.USD
  )}${getPriceToFixed(extraPrice)}`
  const extraProductionDaysFormated = String(extraProductionDays)

  return (
    <Row fullWidth className={classes.tableRow} justifyContent="space-between">
      <Row
        className={`${classes.tableColumn} ${classes.tableColumnMin}`}
        justifyContent="flex-end"
        onClick={onChangeDefault}
      >
        <Dot active={isDefault} onClick={onChangeDefault} />
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {title && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={title}
          />
        )}
      </Row>
      <Row className={classes.bigTableColumn} justifyContent="flex-start">
        <Row
          className={classes.tableColumnDescription}
          justifyContent="flex-start"
        >
          {description && (
            <Text
              className={classes.text}
              color="black"
              preset="body"
              text={description}
            />
          )}
        </Row>
        <Row className={classes.tableColumnColor} justifyContent="flex-start">
          <ColorDot preset="productColor" color={code} />
        </Row>
      </Row>
      <Row className={classes.tableColumn} justifyContent="flex-start">
        {isExtraPrice && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={extraPriceFormated}
          />
        )}
      </Row>
      <Row className={classes.bigTableColumn} justifyContent="flex-start">
        {extraProductionDays && (
          <Text
            className={classes.text}
            color="black"
            preset="body"
            text={extraProductionDaysFormated}
          />
        )}
      </Row>
    </Row>
  )
}
