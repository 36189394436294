import { createUseStyles } from 'react-jss'

import { LightTheme } from '../../../components/Theme'

export const useStyle = createUseStyles((theme: LightTheme) => ({
  topItemsItem: {
    padding: 16,
    borderTop: `1px solid ${theme.colors.shadow}`,
    '&:hover': {
      backgroundColor: `${theme.colors.lightBlue}`
    }
  },
  topItemsItemInfo: {
    flex: '1',
    height: 92,
    paddingLeft: 16
  },
  orderedText: {
    alignSelf: 'end',
    marginLeft: 4
  },
  title: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  }
}))
