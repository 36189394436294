export const BANNER_FRAGMENT = `
fragment BannerFragment on Banner {
  id
  image
  mobileImage
  kind
  buttonType
  buttonText
  buttonLink
  isButtonActive
  isNotificationActive
  mainHeading
  secondaryHeading
  title
  description
  mainDescription
  secondaryDescription
  startDate
  endDate
  isVisible
  notificationMessageText
  notificationButtonText
  notificationButtonLink
  name
}
`

export const PROMOTION_BANNER_FRAGMENT = `
fragment PromotionBammerFrafment on PromotionBanner {
  id
  name
  title
  subtitle
  buttonText
  buttonLink
  startDate
  endDate
  isVisible
  image
  mobileImage
  days
}
`

export const VENDOR_BANNER_IMAGE_FRAGMENT = `
fragment VendorBannerImageFragment on VendorBannerImage {
  id
  url
}
`

export const VENDOR_BANNER_FRAGMENT = `
${VENDOR_BANNER_IMAGE_FRAGMENT}
fragment VendorBannerFragment on VendorBanner {
  id
  createdAt
  updatedAt
  name
  title
  subTitle
  buttonText
  buttonLink
  isVisible
  images {
    ...VendorBannerImageFragment
  }
}
`
