import React, { FC } from 'react'
import { useTheme } from 'react-jss'

import { Column } from '../column'
import { Loader } from '../loader'
import { LightTheme } from '../Theme'

import { useStyle } from './loading-overflow.styles'
import { LoadingOverflowProps } from './loading-overflow.types'

export const LoadingOverflow: FC<LoadingOverflowProps> = ({ loading }) => {
  const theme: LightTheme = useTheme()
  const classes = useStyle({ theme })

  if (!loading) {
    return <></>
  }

  return (
    <Column className={classes.container}>
      <Loader />
    </Column>
  )
}
