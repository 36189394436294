import React, { FC } from 'react'

import { arrayHasElements, Section } from '../../../components'
import { ProductCreateMaterialInput } from '../../../services'

import { OptionItemModal, OptionTable } from '../../views'

import { EmptySection } from '../empty-section'
import { getNextMaterials } from '../helpers'

import { useStyle } from './material-section.styles'
import { MaterialSectionProps } from './material-section.types'

export const MaterialSection: FC<MaterialSectionProps> = ({
  value,
  dropdownData,
  descriptionPlaceholder,
  error,
  instruction,
  onChangeValue
}) => {
  const classes = useStyle({})

  const isMaterials = !arrayHasElements(value.materials) && error
  const emptySectionTextColor = isMaterials ? 'error' : 'inactive'

  const handleOnSubmitMaterial = (material: ProductCreateMaterialInput) => {
    const materials = getNextMaterials(material, value.materials)
    if (onChangeValue) {
      onChangeValue({
        ...value,
        materials
      })
    }
  }
  const materialItemModal = dropdownData && (
    <OptionItemModal
      onSubmit={handleOnSubmitMaterial}
      data={dropdownData}
      descriptionPlaceholder={descriptionPlaceholder}
    />
  )

  const handleOnChangeMaterialTable = (
    materialsValue: ProductCreateMaterialInput[]
  ) => {
    if (onChangeValue) {
      onChangeValue({
        ...value,
        materials: materialsValue
      })
    }
  }

  return (
    <Section
      isInfo
      className={classes.section}
      rightButton={materialItemModal}
      title="Material"
      titleTx="item.form.material.title"
      infoText={instruction}
    >
      {value.materials && arrayHasElements(value.materials) ? (
        <OptionTable
          data={value.materials}
          dropdownData={dropdownData}
          onChange={handleOnChangeMaterialTable}
        />
      ) : (
        <EmptySection
          className={classes.sectionAdditionWithText}
          color={emptySectionTextColor}
          text="Add at least one option."
          tx="item.form.no.variant"
        />
      )}
    </Section>
  )
}
