import { createUseStyles } from 'react-jss'

export const useStyle = createUseStyles({
  productAvatar: {
    objectFit: 'cover'
  },
  title: {
    marginLeft: 16,
    width: 300
  },
  text: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  },
  line: {
    padding: '12px 0px',
    '&:not(:first-child)': {
      marginTop: 16
    }
  },
  observeOptionsBtn: {
    marginTop: 12,
    padding: '8px 6px',
    textTransform: 'uppercase'
  },
  column: {
    minWidth: 120,
    maxWidth: 260
  },
  infoRow: {},
  delivery: {
    wordBreak: 'break-word'
  },

  '@media screen and (max-width: 480px)': {
    title: {
      width: '100%'
    },
    line: {
      padding: 0
    },
    infoRow: {
      marginTop: 24,
      justifyContent: 'space-around'
    }
  }
})
