import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Column } from '../../components'
import { SortVariables, useSort, useTable } from '../../hooks'
import { useApi } from '../../providers'
import {
  OrdersFiltersParseJSON,
  OrderSortField,
  OrdersVariables,
  parseFilterItems
} from '../../services'

import { CabinetNavigatorPathVendor } from '../cabinet-navigator'

import { OrdersActivityFilters } from './orders-activity-filters'
import {
  OrdersActivityTable,
  OrdersActivityTableProps
} from './orders-activity-table'
import { useStyle } from './orders-activity.styles'

const rowCounts = [5, 10, 20, 30]

export const OrdersActivityPage = () => {
  const history = useHistory()
  const [ordersVariables, changeOrderVariables] = useState<OrdersVariables>({
    first: rowCounts[2]
  })

  const defaultRowCountsValue = rowCounts[2]

  const classes = useStyle()

  const { order: orderApi } = useApi()
  const { data, refetch } = orderApi.useOrders(ordersVariables)

  const {
    Data,
    paginationState,
    onChangePage,
    onChangeRowCount,
    onPrevioslyPage,
    onNextPage,
    onSearch,
    onSubmitFilters,
    onResetFilters,
    onResetPageState
  } = useTable({
    refetch,
    data: data?.orders,
    defaultRowCounts: defaultRowCountsValue,
    defaultVariables: ordersVariables
  })

  const toOrderLink = CabinetNavigatorPathVendor.ORDER_CONTROL

  const handleOnClickItem = () => {
    history.push(toOrderLink)
  }

  const handleOnClick = (number: string, id: string) => {
    history.push(`${toOrderLink}?search=${number}&id=${id}`)
  }

  const Filters = useMemo(() => {
    if (data) {
      const list = parseFilterItems<OrdersFiltersParseJSON>(data.orders.filters)

      if (list) {
        return list
      }
    }

    return { customers: [], vendors: [], companyNames: [] }
  }, [data])

  const handleOnSearch: OrdersActivityTableProps['onChangeSearch'] = (
    event
  ) => {
    const { value: search } = event.target

    if (onSearch) {
      onSearch(search)
    }
  }

  const handleOnSort = (sortVariables: SortVariables<OrderSortField>) => {
    refetch({
      ...sortVariables,
      before: undefined,
      after: undefined,
      last: undefined
    })

    onResetPageState()
    changeOrderVariables(sortVariables)
  }

  const { onSort } = useSort({
    variables: ordersVariables,
    onSort: handleOnSort
  })

  return (
    <Column fullWidth className={classes.container} justifyContent="flex-start">
      <OrdersActivityTable
        className={classes.table}
        data={Data}
        rowCounts={rowCounts}
        paginationState={paginationState}
        onChangeSearch={handleOnSearch}
        onChangePage={onChangePage}
        onChangeRowCount={onChangeRowCount}
        onNextPage={onNextPage}
        onPrevioslyPage={onPrevioslyPage}
        onClickItem={handleOnClickItem}
        onClick={handleOnClick}
        onSort={onSort}
      >
        <OrdersActivityFilters
          className={classes.filterButton}
          filters={Filters}
          onSubmit={onSubmitFilters}
          onReset={onResetFilters}
        />
      </OrdersActivityTable>
    </Column>
  )
}
