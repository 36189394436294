import { useMemo } from 'react'

import { OfferCreateInput, OfferItemInput, Product } from '../../services'
import {
  getDeliveryPriceFromProductPerItem,
  getFindByDefaultWithProp,
  getOfferInputDiscount
} from '../../utils'

export const useOfferInputDiscount = (offerInput: OfferCreateInput) => {
  return useMemo(() => getOfferInputDiscount(offerInput), [offerInput.discount])
}

export const useDeliveryPricePerItem = (product: Product) => {
  const { deliveryPrice } = product

  return useMemo(
    () => getDeliveryPriceFromProductPerItem(deliveryPrice),
    [deliveryPrice]
  )
}

export const useDefaultProductMaterial = (offerInput: OfferCreateInput) => {
  return useMemo(
    () => getFindByDefaultWithProp(offerInput.productMaterials),
    [offerInput.productMaterials]
  )
}

export const useDefaultProductColor = (offerInput: OfferCreateInput) => {
  return useMemo(
    () => getFindByDefaultWithProp(offerInput.productColors),
    [offerInput.productColors]
  )
}

export const useDefaultOfferItemMaterial = (offerInput: OfferItemInput) => {
  return useMemo(
    () => getFindByDefaultWithProp(offerInput.productMaterials),
    [offerInput.productMaterials]
  )
}

export const useDefaultOfferItemColor = (offerInput: OfferItemInput) => {
  return useMemo(
    () => getFindByDefaultWithProp(offerInput.productColors),
    [offerInput.productColors]
  )
}
