import React, { FC, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Column } from '../column'
import { RadioItem } from '../radio-item'
import { useStyle } from './radio-box.styles'
import { RadioBoxProps } from './radio-box.types'

export const RadioBox: FC<RadioBoxProps> = ({
  active: defaultActive,
  className = '',
  data,
  itemClassName = '',
  onChange
}) => {
  const classes = useStyle()
  const [active, changeActive] = useState(defaultActive || data[0].id)

  const getActive = (currentValue: string) => currentValue === active

  const handleOnChange = (id: string) => () => {
    changeActive(id)
    if (onChange) {
      onChange(id)
    }
  }

  return (
    <Column alignItems="flex-start" className={className}>
      {data.map((item) => (
        <RadioItem
          {...item}
          key={`${uuidv4()}_${item.id}`}
          active={getActive(item.id)}
          className={`${itemClassName} ${classes.item}`}
          onClick={handleOnChange(item.id)}
        />
      ))}
    </Column>
  )
}
